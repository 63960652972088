import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import translator from '../../assets/json/translator.json';
import TextField from '@mui/material/TextField';
import { Autocomplete, Button, createFilterOptions } from '@mui/material';
import cropDivWarning from '../../assets/images/cropDivWarning.svg';
import { Get } from '../../services/apiCall';
import roundOff from '../../services/round';
import { useParams } from 'react-router-dom';
import { GeneralContext } from '../../services/context/context';
import { useContext } from 'react';
import Styles from './CropDiversification.module.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import QMarkHover from '../../assets/images/QMarkHover.svg';

const CropDiversification = (props) => {
  const { villageId, clusterId } = useParams();

  const { programId, language, villageArea } = useContext(GeneralContext);

  useEffect(() => {
    props.getBaseConditionCropDiversification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // getting base condition for demand

  const {
    arrOfCropLists,
    setArrayOfCropLists,
    newCropsAddedCount,
    setNewCropsAddedCount,
    backupArrayForOldCropArea,
    predefinedCropsDetail,
    setTotalWaterSaved,
  } = props;

  const {
    // currentDemand,
    setCurrentDemand,
    // currentWaterAvailable,
    // setCurrentWaterAvailable,
    // currentWaterBalance,
    // setCurrentWaterBalance,
    // arrOfCropLists,
    // setArrayOfCropLists
  } = props;
  // const { isEditing, setIsEditing } = props;

  const [addNewCrop, setAddNewCrop] = useState(1);
  // const [isNewCropAdded, setIsNewCropAdded] = useState(false);

  const [currentCrop, setCurrentCrop] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  // const [dummyEditValue, setDummyEditValue] = useState();

  const [addNewDripArea, setAddNewDripArea] = useState(0);

  const [enableErrorOne, setEnableErrorOne] = useState(false);
  const [enableErrorTwo, setEnableErrorTwo] = useState(false);
  // const [enableErrorThreeForUpdate, setEnableEnableErrorThreeForUpdate] =
  //   useState(false);
  const [enableErrorForNoCrop, setEnableErrorForNoCrop] = useState(false);

  const [cropDiversificationArray, setCropDiversificationArray] = useState([]);

  // console.log(enableErrorThreeForUpdate);
  // const [editedValues, setEditedValues] = useState([{}]);

  // BASE STARTING FROM HERE
  const [checkGlobalErrorUpdate, setCheckGlobalErrorUpdate] = useState(false);
  const [
    checkGlobalErrorUpdateExceedCultivation,
    setCheckGlobalErrorUpdateExceedCultivation,
  ] = useState(false);

  const [arraylelo, setaraylelo] = useState([]);

  useEffect(() => {
    const final = cropDiversificationArray.filter((elem) => {
      if (elem.cropDripArea < elem.dripOnCrop) {
        return true;
      } else {
        return false;
      }
    });
    setCheckGlobalErrorUpdate(final.length > 0 ? true : false);
    setaraylelo(final.map((item) => item.id));

    //CHECKING IF GLOBAL CROP AREA IS EXCEEDING THE CULTIVATION AREA:-

    const selectedResults = cropDiversificationArray;
    const UNselectedResults = arrOfCropLists.filter((item) => {
      return !cropDiversificationArray.some((elem) => {
        return item.uuid === elem.id;
      });
    });

    const mergedFinalArraysForTotalDripCheck = [
      ...selectedResults,
      ...UNselectedResults,
    ];

    const totalArea = mergedFinalArraysForTotalDripCheck.reduce(
      (initial, vals) => {
        return initial + vals.cropDripArea;
      },
      0,
    );
    // if (totalArea > predefinedCropsDetail.total_cultivation_area) {
    if (totalArea > villageArea) {
      setCheckGlobalErrorUpdateExceedCultivation(true);
    } else {
      setCheckGlobalErrorUpdateExceedCultivation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropDiversificationArray]);
  // BASE STARTING FROM HERE

  const checkedFunc = (id, cropDripArea, dripOnCrop) => {
    const mappedVals = cropDiversificationArray.some((vals) => {
      if (vals.id === id) {
        return true;
      } else {
        return false;
      }
    });

    if (mappedVals) {
      const filtered = cropDiversificationArray.filter(
        (value) => value.id !== id,
      );
      setCropDiversificationArray(filtered);
    } else {
      setCropDiversificationArray([
        ...cropDiversificationArray,
        { id: id, cropDripArea: cropDripArea, dripOnCrop: dripOnCrop },
      ]);
    }
  };

  const updateClicked = (expecting) => {
    // CHECK PERCENTAGE ONE PART:- STARTS---------------------------------

    // const checkPerc = copyOfCropLists.map((item) => {
    let masakadza = expecting ? expecting : arrOfCropLists;

    // LOGIC FOR MAINTAINING OLD CROP FIXED AREA ----------------------------------------
    // if (masakadza) {
    const results = backupArrayForOldCropArea.filter(
      ({ uuid: id1 }) => !masakadza.some(({ uuid: id2 }) => id2 === id1),
    );

    // COPY
    const finalizingArrayPre = results.map((values) => {
      return {
        ...values,

        dripOnCrop: 0, //c
        fixedDripOnCrop: values.drip_area, //new
        fixedCropDripArea: values.crop_area,

        drip_area: 0,
        crop_area: 0,
        oldCropCwrAfterReduction: 0,

        // NEW
        cropId: values.uuid,
        cropName: values.name,
        cropDripArea: 0, //c
      };
    });

    const finalizingArray = finalizingArrayPre.map((values) => {
      const oldCropWaterRequirement =
        values.per_ha_cwr * values.fixedCropDripArea -
        values.per_ha_cwr * values.fixedDripOnCrop * values.drip_efficiency;

      const newCropCropWaterRequirement = values.oldCropCwrAfterReduction
        ? values.oldCropCwrAfterReduction
        : values.per_ha_cwr * values.crop_area -
          values.per_ha_cwr * values.drip_area * values.drip_efficiency;

      return {
        ...values,

        oldCropWaterRequirement: oldCropWaterRequirement,
        newCropCropWaterRequirement: newCropCropWaterRequirement,
      };
    });

    // COPY
    // }
    // LOGIC FOR MAINTAINING OLD CROP FIXED AREA-------------------------------

    // let checkPerc = arrOfCropLists.map((item) => {
    let checkPerc = masakadza.map((item) => {
      const item2 = cropDiversificationArray.find(
        (i2) => i2.id === item.cropId,
      );

      return item2
        ? {
            ...item2,

            ...item,
            percentageReduction:
              // ((item.fixedCropDripArea - item2.cropDripArea) * 100) /
              //   item.fixedCropDripArea >
              // 0
              //   ?
              ((item.fixedCropDripArea - item2.cropDripArea) * 100) /
              item.fixedCropDripArea,
            // : 0,

            // oldCropCwrAfterReduction:
            //   item.oldCropWaterRequirement *
            //   (1 -
            //     ((item.fixedCropDripArea - item2.cropDripArea) * 100) /
            //       item.fixedCropDripArea /
            //       100),

            // NEW
            oldCropCwrAfterReduction:
              item.per_ha_cwr * item2.cropDripArea -
              item.per_ha_cwr * item2.dripOnCrop * item.drip_efficiency,

            cropDripArea: item2.cropDripArea,
            dripOnCrop: item2.dripOnCrop,
            // NEW
          }
        : item;
    });

    // YHA ALSO ADDED
    let mergedArrays = [...finalizingArray, ...checkPerc];

    // YHA ALSO ADDED

    // CHECKING IF ANY CROPDRIP AREA IS 0
    const isCropDripAreaZero = checkPerc.some((values) => {
      if (values.cropDripArea === 0) {
        return true;
      } else {
        return false;
      }
    });

    if (isCropDripAreaZero) {
      // alert("Crop Area should not be zero");

      // deleteFunc();
      alert(
        'Crop Area should not be 0 while updating, you can delete the crop for that!',
      );

      return;
    }

    //CHECKING IF ANY CROP AREA OR DRIP AREA WHILE EDITING IS NAN
    const isNanAnyValue = checkPerc.some((values) => {
      if (isNaN(values.cropDripArea) || isNaN(values.dripOnCrop)) {
        return true;
      } else {
        return false;
      }
    });

    if (isNanAnyValue) {
      alert('Crop Area or Drip Area field is missing!');
      return;
    }

    //CHECKING IF ANY CROP AREA OR DRIP AREA WHILE EDITING IS NAN

    // CHECKING IF ANY CROPDRIP AREA IS 0

    // SETTTING UP ALL THE IMPORTANT DEMAND CALCS FINALLY HERE-----------------------STARTS

    //const ah20 = ah4 + ap17 + ay12 + ah21 + ah22 (FINAL INITIAL DEMAND)

    const ah4 = predefinedCropsDetail.total_domestic_requirement; //domestic requirement

    // now everything for ap17

    // ADDED THAT CONTAINS OLD CWR AFTER REDUCTION  STARTS
    // const preCheckNew = checkPerc.filter((vals) => {
    const preCheckNew = mergedArrays.filter((vals) => {
      return vals.hasOwnProperty('oldCropCwrAfterReduction');
    });

    // ADDED THAT [DOES NOT ]CONTAINS OLD CWR AFTER REDUCTION  STARTS
    // const postCheckWhichDoesNotContainsOldReductionNew = checkPerc.filter(
    const postCheckWhichDoesNotContainsOldReductionNew = mergedArrays.filter(
      (vals) => {
        return !vals.hasOwnProperty('oldCropCwrAfterReduction');
      },
    );
    // ADDED THAT [DOES NOT ]CONTAINS OLD CWR AFTER REDUCTION  ENDS

    const finalCalculatedOldCropWaterRequirementAfterReductionNew =
      preCheckNew.reduce((initialValue, value) => {
        return initialValue + value.oldCropCwrAfterReduction;
      }, 0);

    const veryFinalNew =
      finalCalculatedOldCropWaterRequirementAfterReductionNew +
      postCheckWhichDoesNotContainsOldReductionNew.reduce(
        (initialValue, value) => {
          return initialValue + value.oldCropWaterRequirement;
        },
        0,
      );

    const ap17 = veryFinalNew;

    // hERE COMES A CASE FOR AJ17 => EXCLUDING AVERAGE DRIP of (Crop area = 0 & drip area = 0)
    const getAverage = mergedArrays.filter((vals) => {
      return vals.cropDripArea !== 0;
    });
    // hERE COMES A CASE FOR AJ17 => EXCLUDING AVERAGE DRIP of (Crop area = 0 & drip area = 0)

    // const aj17 = checkPerc.reduce((initialValue, value) => {
    const aj17 = getAverage.reduce((initialValue, value) => {
      return (
        // initialValue + value.dripOnCrop / value.cropDripArea / checkPerc.length
        initialValue + value.dripOnCrop / value.cropDripArea / getAverage.length
      );
    }, 0);

    const ax9 = predefinedCropsDetail.cultivation_land_evaporation;

    // const ah17 = 32; //it will be a  sum of area (will calculate abhi)
    // const ah17 = checkPerc.reduce((initialValue, value) => {
    const PREah17 = mergedArrays.reduce((initialValue, value) => {
      return initialValue + value.cropDripArea;
    }, 0);
    const ah17 = PREah17 === 0 ? 1 : PREah17;

    // const an17 = 32; // old crop initial sum area ---doubtfull
    // const an17 = checkPerc.reduce((initialValue, value) => {

    const PREan17 = mergedArrays.reduce((initialValue, value) => {
      return initialValue + value.fixedCropDripArea;
    }, 0);
    const an17 = PREan17 === 0 ? 1 : PREan17;

    // const an17 = mergedArrays.reduce((initialValue, value) => {
    //   return initialValue + value.fixedCropDripArea;
    // }, 0);

    const newEvaporationForVegetation = (1 - 0.3 * aj17) * ax9 * (ah17 / an17);

    //Calculating az12 (Total water saved) => ax12-ay12
    const ax12 =
      predefinedCropsDetail.barren_land_evaporation +
      predefinedCropsDetail.cultivation_land_evaporation +
      predefinedCropsDetail.forest_land_evaporation +
      predefinedCropsDetail.water_body_evaporation;

    const ay12 =
      predefinedCropsDetail.barren_land_evaporation +
      newEvaporationForVegetation +
      predefinedCropsDetail.forest_land_evaporation +
      predefinedCropsDetail.water_body_evaporation;
    // now everything for ay12

    // now everything for ah21
    const ah21 = 0;
    // now everything for ah21

    // now everything for ah22
    const ah22 = 0;
    // now everything for ah22

    const ah20 = ah4 + ap17 + ay12 + ah21 + ah22;

    // const checkCultivationExceedsOrNotNew = checkPerc.reduce(
    //   (initialValue, value) => {
    //     return initialValue + value.cropDripArea;
    //   },
    //   0
    // );

    // // console.log(checkCultivationExceedsOrNot, "UTAS");
    // if (checkCultivationExceedsOrNotNew > 150) {
    //   // alert("Cultivation area exceeds 150 Ha");
    //   setEnableErrorTwo(true);
    // } else {
    //   setEnableErrorOne(false);
    //   setEnableErrorTwo(false);

    //   setArrayOfCropLists(checkPerc);

    //   setCurrentDemand(Math.round(ah20));
    // }

    //Final output for update function
    setEnableErrorOne(false);
    setEnableErrorTwo(false);
    setArrayOfCropLists(checkPerc);
    setTotalWaterSaved(Math.round(ax12 - ay12));
    setCurrentDemand(Math.round(ah20));

    // SETTTING UP ALL THE IMPORTANT DEMAND CALCS FINALLY HERE----------------------------ENDS

    //FOR SETTING CROPDIVERSIFICATION ARRAY BACK TO []
    setCropDiversificationArray([]);
  };

  const changeFunc = (e, id, getCase) => {
    // IMP COMMENT -> DO NOT REMOVE:-
    //  (setCropDiversificationArray, cropDiversificationArray was mapped first )

    if (getCase === 'add') {
      setCropDiversificationArray(
        cropDiversificationArray.map((val) => {
          if (val.id === id) {
            if (
              // getFromCropDivArray > getFromCropDivArrayCDA + 1 ||
              checkGlobalErrorUpdate
            ) {
              //set error two message here!
              // setEnableEnableErrorThreeForUpdate(true);
            } else {
              // setEnableEnableErrorThreeForUpdate(false);
            }

            return {
              ...val,
              id: id,
              cropDripArea: val.cropDripArea + 1,
              // percentageReduction: cropDripAreaOriginal - 10
            };
          }
          return val;
        }),
      );
    } else if (getCase === 'sub') {
      setCropDiversificationArray(
        cropDiversificationArray.map((val) => {
          if (val.id === id) {
            if (
              // getFromCropDivArray > getFromCropDivArrayCDA - 1 ||
              checkGlobalErrorUpdate
            ) {
              //set error two message here!
              // setEnableEnableErrorThreeForUpdate(true);
            } else {
              // setEnableEnableErrorThreeForUpdate(false);
            }

            return {
              ...val,
              id: id,
              cropDripArea: val.cropDripArea > 0 && val.cropDripArea - 1,

              // percentageReduction: cropDripAreaOriginal - 10
            };
          }
          return val;
        }),
      );
    } else {
      setCropDiversificationArray(
        cropDiversificationArray.map((val) => {
          if (val.id === id) {
            if (
              // getFromCropDivArray > e.target.valueAsNumber ||
              checkGlobalErrorUpdate
            ) {
              //set error two message here!
              // setEnableEnableErrorThreeForUpdate(true);
            } else {
              // setEnableEnableErrorThreeForUpdate(false);
            }

            return {
              ...val,
              id: id,
              cropDripArea: e.target.valueAsNumber,
            };
          }
          return val;
        }),
      );
    }
  };

  // ADDED NEW FOR DRIP

  const changeFuncForDrip = (e, id, currCase) => {
    if (currCase === 'add') {
      setCropDiversificationArray(
        cropDiversificationArray.map((val) => {
          if (val.id === id) {
            if (
              // getFromCropDivArray + 1 > getFromCropDivArrayCDA ||
              checkGlobalErrorUpdate
            ) {
              //set error two message here!
              // setEnableEnableErrorThreeForUpdate(true);
            } else {
              // setEnableEnableErrorThreeForUpdate(false);
            }
            return {
              ...val,
              id: id,
              dripOnCrop: val.dripOnCrop + 1,
            };
          }
          return val;
        }),
      );
    } else if (currCase === 'sub') {
      setCropDiversificationArray(
        cropDiversificationArray.map((val) => {
          if (val.id === id) {
            if (
              // getFromCropDivArray - 1 > getFromCropDivArrayCDA ||
              checkGlobalErrorUpdate
            ) {
              //set error two message here!
              // setEnableEnableErrorThreeForUpdate(true);
            } else {
              // setEnableEnableErrorThreeForUpdate(false);
            }

            return {
              ...val,
              id: id,
              dripOnCrop: val.dripOnCrop > 0 && val.dripOnCrop - 1,
            };
          }
          return val;
        }),
      );
    } else {
      setCropDiversificationArray(
        cropDiversificationArray.map((val) => {
          if (val.id === id) {
            if (
              // e.target.valueAsNumber > getFromCropDivArrayCDA ||
              checkGlobalErrorUpdate
            ) {
              //set error two message here!
              // setEnableEnableErrorThreeForUpdate(true);
            } else {
              // setEnableEnableErrorThreeForUpdate(false);
            }

            return {
              ...val,
              id: id,
              dripOnCrop: e.target.valueAsNumber,
            };
          }
          return val;
        }),
      );
    }
  };

  // ADDED NEW FOR DRIP

  const deleteFunc = () => {
    // console.log(cropDiversificationArray, "DELETING");
    const finaale = arrOfCropLists.filter((item) => {
      const item2 = cropDiversificationArray.find(
        (i2) => i2.id === item.cropId,
      );

      return item2;
    });

    const baad = finaale.map((vals) => {
      return vals.cropId;
    });

    const ekdum = arrOfCropLists.filter((vals) => {
      return baad.indexOf(vals.cropId) < 0;
    });
    // console.log(baad, "RABS", finaale, ekdum);

    setArrayOfCropLists(ekdum);
    updateClicked(ekdum);

    let filterDeletedNumberOfCrops = ekdum.filter((items) => {
      return items.hasOwnProperty('isNewCropAddedInArray');
    });
    const finalFilter = filterDeletedNumberOfCrops.filter((vals) => {
      return vals.isNewCropAddedInArray === true;
    });

    // newCropsAddedCount > 0 &&
    setNewCropsAddedCount(finalFilter.length);

    //FOR SETTING CROPDIVERSIFICATION ARRAY BACK TO []
    setCropDiversificationArray([]);
  };

  // console.log(cropDiversificationArray, "HRJFS");

  const addingNewCrop = (dataFromApi) => {
    const checkIfCropAlreadyExists = arrOfCropLists.some((vals) => {
      if (vals.name === currentCrop) {
        return true;
      } else {
        return false;
      }
    });

    if (checkIfCropAlreadyExists) {
      alert('This crop is already added');
      return;
    }

    // CHECK IF THE USER HAS DELETED THE CROP FROM EXISTING ONES, AND ADDING THE SAME CROP AGAIN STARTS
    const getCropName = dataFromApi.name;
    // const oldCropDeletedAndAddedBack = backupArrayForOldCropArea.some(
    //   (values) => {
    //     if (values.name === getCropName) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // );

    const findOutTheOldCropAdded = backupArrayForOldCropArea.filter((vals) => {
      return vals.name === getCropName;
    });

    // YE CODE PICK KIYA HAI MOUNTING SE STARTS
    const arrayPre = findOutTheOldCropAdded.map((values) => {
      return {
        ...values,
        // dripOnCrop: values.dripOnCrop ? values.dripOnCrop : values.drip_area, //c
        dripOnCrop: addNewDripArea, //c
        fixedDripOnCrop: values.drip_area, //new
        fixedCropDripArea: values.crop_area,

        // NEW
        cropId: values.uuid,
        cropName: values.name,
        // cropDripArea: values.cropDripArea
        //   ? values.cropDripArea
        //   : values.crop_area, //c
        cropDripArea: addNewCrop,
      };
    });

    const fArray = arrayPre.map((values) => {
      const oldCropWaterRequirement =
        values.per_ha_cwr * values.fixedCropDripArea -
        values.per_ha_cwr * values.fixedDripOnCrop * values.drip_efficiency;

      const newCropCropWaterRequirement = values.oldCropCwrAfterReduction
        ? values.oldCropCwrAfterReduction
        : values.per_ha_cwr * values.crop_area -
          values.per_ha_cwr * values.drip_area * values.drip_efficiency;

      return {
        ...values,

        oldCropWaterRequirement: oldCropWaterRequirement,
        newCropCropWaterRequirement: newCropCropWaterRequirement,
      };
    });
    // YE CODE PICK KIYA HAI MOUNTING SE ENDS

    const ejigul =
      fArray.length > 0
        ? fArray.map((vals) => {
            return {
              ...vals,
              id: vals.uuid,
              isNewCropAddedInArray: false,
              oldCropCwrAfterReduction:
                vals.per_ha_cwr * vals.cropDripArea -
                vals.per_ha_cwr * vals.dripOnCrop * vals.drip_efficiency,
            };
          })
        : null;

    // CHECK IF THE USER HAS DELETED THE CROP FROM EXISTING ONES, AND ADDING THE SAME CROP AGAIN ENDS

    if (addNewCrop === 0) {
      alert('You cannot add a crop with Area of 0');
      return;
    }

    if (addNewCrop < addNewDripArea) {
      setEnableErrorOne(true);
      setAddNewCrop(1);
      setAddNewDripArea(0);
      setCurrentCrop('');
      return;
    }

    setEnableErrorOne(false);
    setEnableErrorTwo(false);

    // CALCULATIONS

    // WORK ON THIS NOWWWWW STARTS
    // ap13 = (as43 * ah13) - (as43*ai13*al13);
    const as43 = dataFromApi.perHaCwr;
    const ah13 = addNewCrop;
    const ai13 = addNewDripArea;
    const al13 = dataFromApi.dripEfficiency;

    const ap13 = as43 * ah13 - as43 * ai13 * al13;

    const newCropAdded = [
      ...arrOfCropLists,
      fArray.length > 0
        ? ejigul[0]
        : {
            // TEMPORARILY ADDED
            // uuid: arrOfCropLists.length + 1,
            uuid: dataFromApi.uuid,
            name: currentCrop,
            // type: 'Summer',
            type: seasonForPlan,

            per_ha_cwr: dataFromApi.perHaCwr,
            drip_area: addNewDripArea,
            // crop_area: addNewCrop,
            crop_area: 0,
            img: '',
            // drip_efficiency: 0.3,
            drip_efficiency: dataFromApi.dripEfficiency,
            // TEMPORARILY ADDED

            // cropId: arrOfCropLists.length + 1,
            // cropId: fetchedCrop.find((item) => item.name === currentCrop).id,
            // id: fetchedCrop.find((item) => item.name === currentCrop).id,
            cropId: dataFromApi.id,
            id: dataFromApi.id,
            cropName: currentCrop,
            cropDripArea: addNewCrop,
            isNewCropAddedInArray: true,
            oldCropWaterRequirement: 0,
            newCropCropWaterRequirement: ap13,
            oldCropCwrAfterReduction: ap13,
            fixedCropDripArea: 0,
            // fixedCropDripArea: addNewCrop,
            dripOnCrop: addNewDripArea,
          },
    ];

    // STARTING THE LOGIC OF MERGIN OLD CROPS (OCT 29 ) --------------------------- STARTS

    const results = backupArrayForOldCropArea.filter(
      ({ uuid: id1 }) => !newCropAdded.some(({ uuid: id2 }) => id2 === id1),
    );

    // COPY
    const finalizingArrayPreTwo = results.map((values) => {
      return {
        ...values,

        dripOnCrop: 0, //c
        fixedDripOnCrop: values.drip_area, //new
        fixedCropDripArea: values.crop_area,

        drip_area: 0,
        crop_area: 0,
        oldCropCwrAfterReduction: 0,

        // NEW
        cropId: values.uuid,
        cropName: values.name,
        cropDripArea: 0, //c
      };
    });

    const finalizingArrayTwo = finalizingArrayPreTwo.map((values) => {
      const oldCropWaterRequirement =
        values.per_ha_cwr * values.fixedCropDripArea -
        values.per_ha_cwr * values.fixedDripOnCrop * values.drip_efficiency;

      const newCropCropWaterRequirement = values.oldCropCwrAfterReduction
        ? values.oldCropCwrAfterReduction
        : values.per_ha_cwr * values.crop_area -
          values.per_ha_cwr * values.drip_area * values.drip_efficiency;

      return {
        ...values,

        oldCropWaterRequirement: oldCropWaterRequirement,
        newCropCropWaterRequirement: newCropCropWaterRequirement,
      };
    });

    const merging = [...finalizingArrayTwo, ...newCropAdded];

    // STARTING THE LOGIC OF MERGIN OLD CROPS (OCT 29 ) --------------------------------- ENDS

    // COPY OF UPDATECLICKED------------------------------------------------STARTS

    //const ah20 = ah4 + ap17 + ay12 + ah21 + ah22 (FINAL INITIAL DEMAND)

    const ah4 = predefinedCropsDetail.total_domestic_requirement; //domestic requirement

    // now everything for ap17

    // ADDED THAT CONTAINS OLD CWR AFTER REDUCTION  STARTS
    // const preCheckNew = newCropAdded.filter((vals) => {
    const preCheckNew = merging.filter((vals) => {
      return vals.hasOwnProperty('oldCropCwrAfterReduction');
    });

    // ADDED THAT [DOES NOT ]CONTAINS OLD CWR AFTER REDUCTION  STARTS
    // const postCheckWhichDoesNotContainsOldReductionNew = newCropAdded.filter(
    const postCheckWhichDoesNotContainsOldReductionNew = merging.filter(
      (vals) => {
        return !vals.hasOwnProperty('oldCropCwrAfterReduction');
      },
    );
    // ADDED THAT [DOES NOT ]CONTAINS OLD CWR AFTER REDUCTION  ENDS

    const finalCalculatedOldCropWaterRequirementAfterReductionNew =
      preCheckNew.reduce((initialValue, value) => {
        return initialValue + value.oldCropCwrAfterReduction;
      }, 0);

    const veryFinalNew =
      finalCalculatedOldCropWaterRequirementAfterReductionNew +
      postCheckWhichDoesNotContainsOldReductionNew.reduce(
        (initialValue, value) => {
          return initialValue + value.oldCropWaterRequirement;
        },
        0,
      );

    const ap17 = veryFinalNew;

    // now everything for ap17

    // now everything for ay12

    // hERE COMES A CASE FOR AJ17 => EXCLUDING AVERAGE DRIP of (Crop area = 0 & drip area = 0)
    const getAverage = merging.filter((vals) => {
      return vals.cropDripArea !== 0;
    });
    // hERE COMES A CASE FOR AJ17 => EXCLUDING AVERAGE DRIP of (Crop area = 0 & drip area = 0)

    // const aj17 = newCropAdded.reduce((initialValue, value) => {
    const aj17 = getAverage.reduce((initialValue, value) => {
      return (
        initialValue +
        // value.dripOnCrop / value.cropDripArea / newCropAdded.length
        value.dripOnCrop / value.cropDripArea / getAverage.length
      );
    }, 0);

    const ax9 = predefinedCropsDetail.cultivation_land_evaporation;

    // const ah17 = 32; //it will be a  sum of area (will calculate abhi)
    // const ah17 = newCropAdded.reduce((initialValue, value) => {

    const PREah17 = merging.reduce((initialValue, value) => {
      return initialValue + value.cropDripArea;
    }, 0);
    const ah17 = PREah17 === 0 ? 1 : PREah17;

    // const an17 = 32; // old crop initial sum area ---doubtfull
    // const an17 = newCropAdded.reduce((initialValue, value) => {

    const PREan17 = merging.reduce((initialValue, value) => {
      return initialValue + value.fixedCropDripArea;
    }, 0);
    const an17 = PREan17 === 0 ? 1 : PREan17;

    // const an17 = merging.reduce((initialValue, value) => {
    //   return initialValue + value.fixedCropDripArea;
    // }, 0);

    const newEvaporationForVegetation = (1 - 0.3 * aj17) * ax9 * (ah17 / an17);

    //Calculating az12 (Total water saved) => ax12-ay12
    const ax12 =
      predefinedCropsDetail.barren_land_evaporation +
      predefinedCropsDetail.cultivation_land_evaporation +
      predefinedCropsDetail.forest_land_evaporation +
      predefinedCropsDetail.water_body_evaporation;

    const ay12 =
      predefinedCropsDetail.barren_land_evaporation +
      newEvaporationForVegetation +
      predefinedCropsDetail.forest_land_evaporation +
      predefinedCropsDetail.water_body_evaporation;
    // now everything for ay12

    // now everything for ah21
    const ah21 = 0;
    // now everything for ah21

    // now everything for ah22
    const ah22 = 0;
    // now everything for ah22

    const ah20 = ah4 + ap17 + ay12 + ah21 + ah22;

    const calc = newCropAdded.reduce((initialValue, value) => {
      return initialValue + value.cropDripArea;
    }, 0);

    if (calc > villageArea) {
      setEnableErrorTwo(true);
    } else {
      const newCropAddCount = merging.filter((vals) => {
        return vals.isNewCropAddedInArray;
      });
      const newCropAddCountTrue = newCropAddCount.filter((vals) => {
        return vals.isNewCropAddedInArray === true;
      });

      // setNewCropsAddedCount((prev) => prev + 1);
      setNewCropsAddedCount(newCropAddCountTrue.length);
      setArrayOfCropLists(newCropAdded);
      setTotalWaterSaved(Math.round(ax12 - ay12));
      props.setCurrentDemand(Math.round(ah20));
    }

    // COPY OF UPDATECLICKED--------------------------------------------ENDS

    // WORK ON THIS NOWWWWW ENDS

    setAddNewCrop(1);
    setAddNewDripArea(0);
    setCurrentCrop('');
    setSeasonForPlan('');
  };

  const calculateAreaReduced = () => {
    // const filterOldCropsPre = arrOfCropLists.filter((values) => {
    //   return !values.hasOwnProperty("isNewCropAddedInArray");
    // });

    const filterOldCrops = arrOfCropLists.filter((values) => {
      return (
        !values.isNewCropAddedInArray === true ||
        !values.hasOwnProperty('isNewCropAddedInArray')
      );
    });

    // const finalCalculationOfArrayOfOldCropsFixedTotal = filterOldCrops.reduce(
    const finalCalculationOfArrayOfOldCropsFixedTotal =
      backupArrayForOldCropArea.reduce((initialValue, value) => {
        // return initialValue + value.fixedCropDripArea;
        return initialValue + value.crop_area;
      }, 0);

    const finalCalculationOfArrayOfOldCrops = filterOldCrops.reduce(
      (initialValue, value) => {
        return initialValue + value.cropDripArea;
      },
      0,
    );

    return finalCalculationOfArrayOfOldCropsFixedTotal -
      finalCalculationOfArrayOfOldCrops >
      0
      ? (
          finalCalculationOfArrayOfOldCropsFixedTotal -
          finalCalculationOfArrayOfOldCrops
        ).toFixed(2)
      : 0;
  };

  const [top100Films, setTop100Films] = useState([]);
  // TRYING MY LOGIVVVVVV

  const [fetchedCrop, setFetchedCrop] = useState([]);

  const fetchFunc = async (newValue, ff) => {
    if (newValue !== '') {
      setEnableErrorForNoCrop(false);
    }
    setCurrentCrop(newValue);

    if (newValue.length < 3) {
      setTop100Films([]);
      // setCurrentCrop(newValue);
    } else {
      //NEW
      if (newValue.length >= 3) {
        const postReq = await Get(
          `/info/crops?term=${newValue}&language=${language}`,
        );

        setFetchedCrop(postReq.data);

        let arr = postReq.data.map((item) => item.name);
        setTop100Films(arr);
      }
    }
  };

  // TRYING MY LOGIVVVVVV

  const OPTIONS_LIMIT = 6;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const addCropChangeFunction = (e, type) => {
    const getTotalDripAreaInitially = arrOfCropLists.reduce((initial, val) => {
      return initial + val.cropDripArea;
    }, 0);

    if (type === 'subtract') {
      getTotalDripAreaInitially + (addNewCrop - 1) > villageArea
        ? setEnableErrorTwo(true)
        : setEnableErrorTwo(false);

      (addNewCrop > 0 && addNewCrop >= addNewDripArea + 1) ||
      isNaN(addNewDripArea)
        ? setEnableErrorOne(false)
        : setEnableErrorOne(true);

      addNewCrop > 1 &&
        setAddNewCrop((prev) => {
          return prev - 1;
        });
    } else if (type === 'add') {
      getTotalDripAreaInitially + (addNewCrop + 1) > villageArea
        ? setEnableErrorTwo(true)
        : setEnableErrorTwo(false);

      addNewCrop + 1 >= addNewDripArea || isNaN(addNewDripArea)
        ? setEnableErrorOne(false)
        : setEnableErrorOne(true);

      setAddNewCrop((prev) => {
        return prev + 1;
      });
    } else {
      e.target.valueAsNumber >= addNewDripArea || isNaN(addNewDripArea)
        ? setEnableErrorOne(false)
        : setEnableErrorOne(true);
      setAddNewCrop(e.target.valueAsNumber);
    }

    const getTotalDripArea = arrOfCropLists.reduce((initial, val) => {
      return initial + val.cropDripArea;
    }, e.target.valueAsNumber);

    if (getTotalDripArea > villageArea) {
      setEnableErrorTwo(true);
    } else {
      setEnableErrorTwo(false);
    }
  };

  const addNewCropFunction = async () => {
    if (currentCrop !== '') {
      const getIdOfCrop = fetchedCrop.find(
        (item) => item.name === currentCrop,
      ).id;

      let finalSeason;
      if (seasonForPlan === 'Annual') {
        if (props.season === 'Rabi') {
          finalSeason = 'Annual (Rabi)';
        } else if (props.season === 'Summer') {
          finalSeason = 'Annual (Summer)';
        }
      } else {
        finalSeason = seasonForPlan;
      }

      const response = await Get(
        `/simulation/plan/new-crop?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&yearSession=${props.yearSession}&season=${finalSeason}&cropId=${getIdOfCrop}&language=${language}`,
      );
      if (response.status === 'success') {
        addingNewCrop(response.data);
      }
    } else {
      setEnableErrorForNoCrop(true);
    }
  };

  const [seasonForPlan, setSeasonForPlan] = useState('');
  const handlePlanSeason = (e) => {
    setSeasonForPlan(e.target.value);
  };

  const menuItems =
    props.season === 'Rabi'
      ? ['Rabi', 'Summer', 'Annual']
      : ['Summer', 'Annual'];

  const testUniqueIds = new Set();

  const uniqueCropsList = arrOfCropLists?.filter((crop) => {
    // If the id is not in the Set, add it and return true (keep the crop)
    if (!testUniqueIds.has(crop.id)) {
      testUniqueIds.add(crop.id);
      return true;
    }
    // If the id is already in the Set, return false (skip the crop)
    return false;
  });

  return (
    <>
      <ReactTooltip
        id="my-tooltip-2"
        place="top"
        style={{
          background: 'black',
          color: 'white',
          fontSize: window.innerWidth < 768 ? '10px' : '12px',
          zIndex: 888,
        }}
        content={'Micro Irrigation'}
      />

      <div className={Styles['crop-diversification-wrapper']}>
        <div
          className={Styles['crop-diversification-header']}
          style={{ marginTop: '10px' }}
        >
          <p className={Styles['main-crop-heading']}>
            {translator[language]['Crop Diversification']}
          </p>
        </div>

        <div className={Styles['crop-slightly-left-portion']}>
          {/* <button onClick={() => props.onReset(getBaseCondition)}>
            {" "}
            CLICKME
          </button> */}

          <div className={Styles['crop-table']} style={{ marginTop: '0px' }}>
            {/* <p className={Styles.crop-table-heading} style={{ fontWeight: "bold" }}>
              Crop Table:
            </p> */}
            <div className={Styles['crop-table-content']}>
              <div className={Styles['crop-upper-content-container']}>
                {/* ADDING A SELECTBOX FOR THE SEASON */}

                <div
                  style={{
                    // background: "red",
                    display: 'flex',
                    alignItems: 'flex-end',
                    marginBottom: '12px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    className={Styles['table-upper-crop']}
                    style={{ marginTop: '10px', marginRight: '0px' }}
                  >
                    <p style={{ marginBottom: '5px' }}>
                      {translator[language]['Add New Crop']}
                    </p>

                    <FormControl sx={{ m: 0, minWidth: 120 }} size="small">
                      <Autocomplete
                        onMouseDownCapture={(e) => e.stopPropagation()}
                        filterOptions={filterOptions}
                        value={currentCrop}
                        onKeyUp={(e, newVal) =>
                          fetchFunc(e.target.value, newVal)
                        }
                        onChange={(e, newVal) => setCurrentCrop(newVal)}
                        freeSolo
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        options={top100Films}
                        renderOption={(props, option) => (
                          <li
                            style={{
                              minHeight: window.innerWidth < 768 && '22px',
                              fontSize: window.innerWidth < 768 && '12px',
                            }}
                            {...props}
                          >
                            {option}
                          </li>
                        )}
                        sx={{
                          width: 140,
                          '& .MuiInputBase-root': {
                            padding: '0px 6px',
                          },
                          '@media (max-width:1300px)': {
                            width: 110,
                          },

                          // background: 'red',
                        }}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              '& .MuiInputBase-root': {
                                // paddingTop: "6px",
                                fontSize: '15px',

                                '@media (max-width:1300px)': {
                                  fontSize: '13px',
                                },
                                '@media (max-width:600px)': {
                                  fontSize: '.75rem',
                                },
                              },
                            }}
                            size="small"
                            {...params}
                            placeholder={translator[language]['Search Crop']}
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  <div>
                    <p
                      className={Styles.cropDivSeasonHeading}
                      style={{ marginBottom: '5px' }}
                    >
                      {translator[language]['Season']}
                    </p>

                    <FormControl
                      sx={{
                        minWidth: 120,
                        '@media (max-width:1300px)': {
                          minWidth: 110,
                        },
                      }}
                      size="small"
                    >
                      <Select
                        sx={{
                          fontSize: '15px',
                          '@media (max-width:1300px)': {
                            fontSize: '13px',
                          },
                          '@media (max-width:600px)': {
                            fontSize: '.75rem',
                          },
                        }}
                        placeholder="Select"
                        labelId="Season Plan"
                        id="language"
                        value={seasonForPlan}
                        onChange={(e) => {
                          handlePlanSeason(e);
                        }}
                        className={Styles['languageOptions']}
                        displayEmpty
                        renderValue={
                          seasonForPlan !== ''
                            ? undefined
                            : () => translator[language]['Select']
                        }
                      >
                        {menuItems.map((vals) => {
                          return (
                            <MenuItem
                              key={vals}
                              value={vals}
                              style={{
                                minHeight: window.innerWidth < 768 && '22px',
                                fontSize: window.innerWidth < 768 && '12px',
                              }}
                            >
                              {translator[language][vals]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ width: '170px', background: '' }}></div>
                </div>

                {/* ADDING A SELECTBOX FOR THE SEASON */}

                <div
                  className={Styles['crop-upper-content']}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className={Styles.cropDivAreasDiv}>
                    <div
                      className={`Styles['table-upper-area'] ${Styles['table-upper-area-one']}`}
                    >
                      <p style={{ marginBottom: '4px' }}>
                        {translator[language]['Area']}
                      </p>
                      <div
                        className={Styles.number}
                        style={{ display: 'inline' }}
                      >
                        <Button
                          className={Styles.commonMuiButton}
                          onClick={() =>
                            // addNewCrop > 0 &&
                            // (addNewCrop >= addNewDripArea + 1
                            //   ? setEnableErrorOne(false)
                            //   : setEnableErrorOne(true),
                            // setAddNewCrop((prev) => {
                            //   return prev - 1;
                            // }))
                            addCropChangeFunction('', 'subtract')
                          }
                          size="small"
                          variant="outlined"
                          style={{
                            backgroundColor: '#f2f2f2',
                            color: '#000',
                            // width: '28px',
                            minWidth: '28px',
                            // height: '28px',
                            border: 'none',
                            marginRight: '2px',
                            fontWeight: 'bold',
                          }}
                        >
                          -
                        </Button>
                        <input
                          min={1}
                          className={Styles['common-input']}
                          type="number"
                          onChange={(e) => {
                            addCropChangeFunction(e);
                          }}
                          value={addNewCrop}
                        />

                        <Button
                          onClick={() => {
                            // addNewCrop + 1 >= addNewDripArea
                            //   ? setEnableErrorOne(false)
                            //   : setEnableErrorOne(true);

                            // setAddNewCrop((prev) => {
                            //   return prev + 1;
                            // });
                            addCropChangeFunction('', 'add');
                          }}
                          className={Styles.commonMuiButton}
                          size="small"
                          variant="outlined"
                          style={{
                            backgroundColor: '#f2f2f2',
                            color: '#000',
                            // width: '28px',
                            minWidth: '28px',
                            // height: '28px',
                            border: 'none',
                            marginLeft: '2px',
                            fontWeight: 'bold',
                          }}
                        >
                          +
                        </Button>
                      </div>
                    </div>

                    {/* ANOTHER DRIP AREA  */}
                    <div
                      className={Styles['table-upper-area']}
                      style={{
                        // background: "red",
                        textAlign: 'left',
                        // width: "30%",
                        width: 'unset',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '4px',
                          // marginLeft: '4px',
                          // justifyContent: 'center',
                        }}
                      >
                        <p>MI</p>
                        <img
                          data-tooltip-id="my-tooltip-2"
                          onClick={() => navigator.vibrate(100)}
                          src={QMarkHover}
                          height="12px"
                          width="12px"
                          alt="tooltip"
                          style={{
                            cursor: 'pointer',
                            marginLeft: '3px',
                            // marginTop: '3px',
                          }}
                        />
                      </div>

                      <div
                        className={Styles.number}
                        style={{ display: 'inline' }}
                      >
                        <Button
                          className={Styles.commonMuiButton}
                          onClick={() => {
                            addNewDripArea > 0 &&
                              setAddNewDripArea((prev) => {
                                if (addNewCrop < prev - 1) {
                                  setEnableErrorOne(true);
                                } else {
                                  setEnableErrorOne(false);
                                }
                                return prev - 1;
                              });
                          }}
                          size="small"
                          variant="outlined"
                          style={{
                            backgroundColor: '#f2f2f2',
                            color: '#000',
                            // width: '28px',
                            minWidth: '28px',
                            // height: '28px',
                            border: 'none',
                            marginRight: '2px',
                            fontWeight: 'bold',
                          }}
                        >
                          -
                        </Button>

                        <input
                          min={0}
                          className={Styles['common-input']}
                          type="number"
                          onChange={(e) => {
                            addNewCrop < e.target.value
                              ? setEnableErrorOne(true)
                              : setEnableErrorOne(false);
                            setAddNewDripArea(e.target.valueAsNumber);
                          }}
                          value={addNewDripArea}
                        />

                        <Button
                          className={Styles.commonMuiButton}
                          onClick={() => {
                            addNewCrop <= addNewDripArea
                              ? setEnableErrorOne(true)
                              : setEnableErrorOne(false);
                            setAddNewDripArea((prev) => prev + 1);
                          }}
                          size="small"
                          variant="outlined"
                          style={{
                            backgroundColor: '#f2f2f2',
                            color: '#000',
                            // width: '28px',
                            minWidth: '28px',
                            // height: '28px',
                            border: 'none',
                            marginLeft: '2px',
                            fontWeight: 'bold',
                          }}
                        >
                          +
                        </Button>
                      </div>
                    </div>
                    {/* ANOTHER DRIP AREA  */}
                  </div>

                  <div
                    // className={Styles.table-upper-buttons}
                    style={{
                      // background: "green",
                      display: 'flex',
                      marginTop: '25px',
                      width: 'unset',
                    }}
                  >
                    <p style={{ marginBottom: '25px' }}></p>

                    <Button
                      className={Styles.addButtonCropDiv}
                      disabled={
                        enableErrorOne ||
                        enableErrorTwo ||
                        isNaN(addNewCrop) ||
                        isNaN(addNewDripArea) ||
                        addNewCrop === 0 ||
                        top100Films.every((item) => item !== currentCrop) ||
                        seasonForPlan === ''
                          ? true
                          : false
                      }
                      style={{
                        // padding: "6px",
                        background: '#0EC959',
                        textTransform: 'capitalize',
                      }}
                      size="small"
                      variant="contained"
                      onClick={() => {
                        // currentCrop !== ""
                        //   ? addingNewCrop()
                        //   : setEnableErrorForNoCrop(true);
                        addNewCropFunction();
                      }}
                    >
                      {translator[language]['Add']}
                    </Button>

                    <Button
                      className={Styles.addButtonCropDiv}
                      style={{
                        textTransform: 'capitalize',
                        marginLeft: '10px',
                        background: '#ffffff',
                        color: '#000000',
                      }}
                      size="small"
                      variant="contained"
                      // color="blue"
                      onClick={() => {
                        setAddNewCrop(1);
                        setAddNewDripArea(0);
                        setCurrentCrop('');
                        setEnableErrorOne(false);
                        setEnableErrorTwo(false);
                        setEnableErrorForNoCrop(false);
                        setSeasonForPlan('');
                      }}
                    >
                      {translator[language]['Cancel']}
                    </Button>
                  </div>
                </div>

                {/* crop upper content ends here (above)  */}
              </div>
              {/* crop upper content container ends here (above)  */}

              {/* MAIN TABLE CONTENT STARTS HERE  */}

              <div style={{ position: 'relative' }}>
                {enableErrorOne && (
                  <div
                    style={{
                      fontSize: '12px',
                      marginTop: '8px',
                      fontStyle: 'italic',
                    }}
                  >
                    <span
                      className={Styles.cultivationExceedDripAdd}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <img
                        src={cropDivWarning}
                        alt="Warn"
                        style={{
                          height: '14px',
                          width: '14px',
                          marginRight: '5px',
                        }}
                      />
                      {
                        translator[language][
                          'Drip area Exceeding the Crop area'
                        ]
                      }
                    </span>
                  </div>
                )}

                {enableErrorTwo && (
                  <div
                    style={{
                      fontSize: '12px',
                      marginTop: '8px',
                      fontStyle: 'italic',
                    }}
                  >
                    <span className={Styles.cultivationExceedAdd}>
                      <img
                        src={cropDivWarning}
                        alt="Warn"
                        style={{
                          height: '14px',
                          width: '14px',
                          marginRight: '5px',
                        }}
                      />
                      {
                        translator[language][
                          'You cannot add a new crop because the cultivation area exceeds'
                        ]
                      }{' '}
                      {villageArea} {translator[language]['Ha']}
                    </span>
                  </div>
                )}

                {enableErrorForNoCrop && (
                  <div
                    style={{
                      fontSize: '12px',
                      marginTop: '8px',
                      fontStyle: 'italic',
                    }}
                  >
                    <span>
                      <img
                        src={cropDivWarning}
                        alt="Warn"
                        style={{
                          height: '14px',
                          width: '14px',
                          marginRight: '5px',
                        }}
                      />
                      Please select any crop
                    </span>
                  </div>
                )}

                <div className={Styles['complete-table-wrapper-line-needed']}>
                  <div className={Styles['main-table-content']}>
                    <div className={Styles['main-table-content-headings']}>
                      <div style={{ background: '' }}>
                        <p style={{ fontWeight: 'bold' }}>
                          {' '}
                          <span>
                            <input
                              className={Styles['common-input']}
                              type="checkbox"
                              style={{ height: '16px', width: '16px' }}
                              onChange={(e) => {}}
                              checked={
                                arrOfCropLists.length ===
                                cropDiversificationArray.length
                              }
                              // defaultChecked={
                              //   arrOfCropLists.length ===
                              //   cropDiversificationArray.length
                              // }
                              onClick={() => {
                                arrOfCropLists.length !==
                                cropDiversificationArray.length
                                  ? setCropDiversificationArray(
                                      // console.log(
                                      arrOfCropLists.map((values) => {
                                        return {
                                          ...values,
                                          id: values.cropId,
                                        };
                                      }),
                                      // )
                                    )
                                  : setCropDiversificationArray([]);
                              }}
                            />
                          </span>{' '}
                          {translator[language]['Crop Name']}
                        </p>
                      </div>
                      <div>
                        <p style={{ fontWeight: 'bold', background: '' }}>
                          {translator[language]['Crop Area']} (
                          {translator[language]['Ha']})
                        </p>
                      </div>

                      <div className={Styles.thirdDivCd}>
                        <div
                          className={Styles.justifyMi}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '4px',
                            // justifyContent: 'center',
                          }}
                        >
                          <p style={{ fontWeight: 'bold', marginLeft: '16px' }}>
                            MI(
                            {translator[language]['Ha']})
                          </p>
                          <img
                            data-tooltip-id="my-tooltip-2"
                            onClick={() => navigator.vibrate(100)}
                            src={QMarkHover}
                            height="12px"
                            width="12px"
                            alt="tooltip"
                            style={{
                              cursor: 'pointer',
                              marginLeft: '3px',
                              // marginTop: '3px',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* REPETETIVE CONTEN STARTS HERE (WILL REMOVE THIS COMMENT LATER )   */}

                  <div
                    style={{
                      height: '150px',
                      overflow: 'scroll',
                    }}
                  >
                    {checkGlobalErrorUpdate && (
                      <span
                        className={Styles.globalErrorUpdate}
                        style={{
                          fontSize: '9px',
                          marginTop: '8px',
                          fontStyle: 'italic',
                          position: 'absolute',
                          right: '15px',
                          width: '100px',
                          // background: "red",
                        }}
                      >
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={cropDivWarning}
                            alt="Warn"
                            style={{
                              height: '12px',
                              width: '12px',
                              marginRight: '5px',
                            }}
                          />

                          {
                            translator[language][
                              'Drip area Exceeding the Crop area'
                            ]
                          }
                        </span>
                      </span>
                    )}

                    {checkGlobalErrorUpdateExceedCultivation && (
                      <span
                        className={Styles.errorExceedCultivation}
                        style={{
                          fontSize: '9px',
                          marginTop: '8px',
                          fontStyle: 'italic',
                          position: 'absolute',
                          right: '3px',
                          width: '115px',
                          top: enableErrorTwo ? '120px' : '120px',
                        }}
                      >
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={cropDivWarning}
                            alt="Warn"
                            style={{
                              height: '12px',
                              width: '12px',
                              marginRight: '5px',
                            }}
                          />
                          {
                            translator[language][
                              'Total Crop Area is exceeding the total cultivation area'
                            ]
                          }
                        </span>
                      </span>
                    )}

                    {/* {arrOfCropLists.map((vals, index) => { */}
                    {uniqueCropsList.map((vals, index) => {
                      const isOddIndex = index % 2 === 1;
                      const backgroundColor = !isOddIndex ? '#f3f3f3' : '';

                      return (
                        <div
                          className={Styles['main-table-content-details']}
                          key={index}
                          style={{ backgroundColor }}
                        >
                          <div
                            style={{
                              background: '',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {' '}
                            <div style={{ width: 'unset', marginRight: '4px' }}>
                              <input
                                className={Styles['common-input']}
                                type="checkbox"
                                style={{ height: '16px', width: '16px' }}
                                // checked={cropDiversificationArray.includes(
                                //   vals.cropId
                                // )}

                                // COMMENTED STARTS

                                // checked={cropDiversificationArray.some(
                                //   (item) => {
                                //     return item.id === vals.cropId;
                                //   }
                                // )}
                                onChange={(e) => {}}
                                checked={cropDiversificationArray.some(
                                  (item) => {
                                    return item.id === vals.cropId;
                                  },
                                )}
                                // defaultChecked={cropDiversificationArray.some(
                                //   (item) => {
                                //     return item.id === vals.cropId;
                                //   }
                                // )}

                                onClick={() => {
                                  checkedFunc(
                                    vals.cropId,
                                    vals.cropDripArea,
                                    vals.dripOnCrop,
                                  );
                                }}

                                //COMMENTED ENDS
                              />
                            </div>{' '}
                            <div
                              style={{
                                width: 'unset',
                                textAlign: 'left',
                                fontSize: '16px',
                                // height: "37px",
                                // overflow: "scroll",
                                // background: "red",
                                // overflow: "hidden",
                              }}
                            >
                              <p
                                style={{
                                  color: vals.isNewCropAddedInArray
                                    ? '#3883E6'
                                    : '',
                                }}
                              >
                                {/* {translator[language][vals.cropName]} */}
                                {vals.cropName}
                                <span
                                  className={Styles.shortCropNames}
                                  style={{
                                    cursor: 'unset',
                                  }}
                                >
                                  {' '}
                                  (
                                  {vals?.type.substr(0, 3) === 'Lon'
                                    ? translator[language]['L.khf']
                                    : translator[language][
                                        vals?.type.substr(0, 3)
                                      ]}
                                  )
                                </span>
                              </p>
                            </div>
                          </div>
                          <div>
                            {/* cropDiversificationArray.includes(vals.cropId)  */}
                            {isEditing &&
                            cropDiversificationArray.some((item) => {
                              return item.id === vals.cropId;
                            }) ? (
                              <div
                                className={Styles.number}
                                style={{
                                  // background: "pink",
                                  display: 'flex',
                                  width: 'unset',
                                }}
                              >
                                <Button
                                  onClick={(e) =>
                                    changeFunc(e, vals.cropId, 'sub')
                                  }
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    backgroundColor: '#ffffff',
                                    color: '#000',
                                    width: '25px',
                                    minWidth: '25px',
                                    height: '25px',
                                    border: '1px solid #f2f2f2',
                                  }}
                                >
                                  -
                                </Button>

                                <input
                                  min={0}
                                  className={Styles['common-input']}
                                  type="number"
                                  onChange={(e) => {
                                    changeFunc(
                                      e,
                                      vals.cropId,
                                      null,
                                      // vals.cropDripArea
                                    );
                                  }}
                                  // value={dummyEditValue}
                                  value={
                                    cropDiversificationArray.filter(
                                      (ele) => ele.id === vals.cropId,
                                    )[0].cropDripArea
                                  }
                                />

                                <Button
                                  onClick={(e) =>
                                    changeFunc(e, vals.cropId, 'add')
                                  }
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    backgroundColor: '#ffffff',
                                    color: '#000',
                                    width: '25px',
                                    minWidth: '25px',
                                    height: '25px',
                                    border: '1px solid #f2f2f2',
                                  }}
                                >
                                  +
                                </Button>
                              </div>
                            ) : (
                              <p
                                style={{
                                  color: vals.isNewCropAddedInArray
                                    ? '#3883E6'
                                    : '',
                                }}
                              >
                                {roundOff(vals.cropDripArea)}
                              </p>
                            )}
                          </div>

                          {/* ADDING ANOTHER ----------------------------STARTS*/}

                          <div
                            style={{
                              // width: "21%",
                              textAlign: 'center',
                              // background: "pink",
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            {/* cropDiversificationArray.includes(vals.cropId)  */}
                            {isEditing &&
                            cropDiversificationArray.some((item) => {
                              return item.id === vals.cropId;
                            }) ? (
                              <div
                                className={Styles.number}
                                style={{
                                  // background: "pink",
                                  display: 'flex',
                                  width: 'unset',
                                }}
                              >
                                <Button
                                  onClick={(e) =>
                                    changeFuncForDrip(e, vals.cropId, 'sub')
                                  }
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    backgroundColor: '#ffffff',
                                    color: '#000',
                                    width: '25px',
                                    minWidth: '25px',
                                    height: '25px',
                                    border: '1px solid #f2f2f2',
                                  }}
                                >
                                  -
                                </Button>

                                <input
                                  style={{
                                    border: arraylelo.some(
                                      (val) =>
                                        val === (vals.uuid || vals.cropId),
                                    )
                                      ? '2px solid red'
                                      : 'unset',
                                  }}
                                  min={0}
                                  className={Styles['common-input']}
                                  type="number"
                                  onChange={(e) => {
                                    changeFuncForDrip(e, vals.cropId, null);
                                  }}
                                  // defaultValue={vals.dripOnCrop}
                                  value={
                                    cropDiversificationArray.filter(
                                      (ele) => ele.id === vals.cropId,
                                    )[0].dripOnCrop
                                  }
                                />

                                <Button
                                  onClick={(e) =>
                                    changeFuncForDrip(e, vals.cropId, 'add')
                                  }
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    backgroundColor: '#ffffff',
                                    color: '#000',
                                    width: '25px',
                                    minWidth: '25px',
                                    height: '25px',
                                    border: '1px solid #f2f2f2',
                                  }}
                                >
                                  +
                                </Button>
                              </div>
                            ) : (
                              <p
                                style={{
                                  color: vals.isNewCropAddedInArray
                                    ? '#3883E6'
                                    : '',
                                }}
                              >
                                {roundOff(vals.dripOnCrop)}
                              </p>
                            )}
                          </div>

                          {/* ADDING ANOTHER -----------------------------------ENDS*/}
                        </div>
                      );
                    })}

                    {/* REPEAT ONE ENDS  */}

                    {/* REPEAT TWO ENDS  */}

                    {/* REPEAT THREE ENDS  */}
                  </div>

                  <div className={Styles['main-table-content-headings']}>
                    <div style={{ width: '36%', background: '' }}>
                      <p style={{ marginLeft: '15px', fontWeight: 'bold' }}>
                        {/* {filterUnique(arrOfCropLists)?.length} */}
                        {uniqueCropsList.length}
                      </p>
                    </div>
                    <div style={{ width: '30%', background: '' }}>
                      <p style={{ fontWeight: 'bold' }}>
                        {arrOfCropLists
                          .reduce((initialValue, value) => {
                            return initialValue + value.cropDripArea;
                          }, 0)
                          .toFixed(2)}{' '}
                        {''}
                        {translator[language]['Ha']}
                      </p>
                    </div>

                    <div className={Styles.mobileLastRowTable}>
                      <p
                        style={{
                          marginLeft: '40px',
                          fontWeight: 'bold',
                        }}
                      >
                        {/* 72 Ha */}
                        {arrOfCropLists
                          .reduce((initialValue, value) => {
                            return initialValue + value.dripOnCrop;
                          }, 0)
                          .toFixed(1)}{' '}
                        {translator[language]['Ha']}
                      </p>
                    </div>

                    {!isEditing ? (
                      <div
                        className={Styles.ButtonParent}
                        style={{
                          width: '37%',
                          justifyContent: 'space-evenly',
                        }}
                      >
                        <Button
                          className={Styles['ed-up-ca-del-buttons']}
                          style={{
                            background: '#ffffff',

                            color: '#000000',
                            textTransform: 'capitalize',
                            width: '55px',
                          }}
                          size="small"
                          variant="contained"
                          onClick={() => {
                            setIsEditing(true);
                          }}
                        >
                          {translator[language]['Edit']}
                        </Button>

                        <Button
                          className={Styles['ed-up-ca-del-buttons']}
                          disabled={
                            cropDiversificationArray.length > 0 ? false : true
                          }
                          style={{
                            background: 'rgba(216, 54, 54, 1)',
                            // color: "white",

                            marginLeft: '10px',
                            textTransform: 'capitalize',
                            width: '55px',
                          }}
                          size="small"
                          variant="contained"
                          onClick={() => {
                            deleteFunc();
                          }}
                        >
                          {translator[language]['Delete']}
                        </Button>
                      </div>
                    ) : (
                      <div
                        // className={Styles.table-upper-buttons isEditing}
                        style={{
                          width: '37%',
                          justifyContent: 'space-evenly',
                        }}
                        className={Styles.ButtonParent}
                      >
                        <Button
                          className={Styles['ed-up-ca-del-buttons']}
                          disabled={
                            // enableErrorOne ||
                            enableErrorTwo ||
                            checkGlobalErrorUpdate ||
                            checkGlobalErrorUpdateExceedCultivation
                              ? true
                              : false
                          }
                          style={{
                            background: 'rgba(56, 131, 230, 1)',
                            // color: "#ffffff",

                            textTransform: 'capitalize',
                            width: '55px',

                            '@media (max-width:1300px)': {
                              fontSize: '12px',
                              padding: '1px',
                            },
                          }}
                          size="small"
                          variant="contained"
                          onClick={() => {
                            setIsEditing(false);
                            setCropDiversificationArray([]);
                            updateClicked();
                          }}
                        >
                          {translator[language]['Update']}
                        </Button>

                        <Button
                          className={Styles['ed-up-ca-del-buttons']}
                          style={{
                            background: '#ffffff',
                            color: '#000000',

                            marginLeft: '10px',
                            textTransform: 'capitalize',
                            width: '55px',

                            '@media (max-width:1300px)': {
                              fontSize: '12px',
                              padding: '1px',
                            },
                          }}
                          size="small"
                          variant="contained"
                          onClick={() => {
                            setEnableErrorOne(false);
                            setEnableErrorTwo(false);
                            // setEnableEnableErrorThreeForUpdate(false);
                            setIsEditing(false);
                            setCropDiversificationArray([]);
                          }}
                        >
                          {translator[language]['Cancel']}
                        </Button>
                      </div>
                    )}
                  </div>

                  {/* REPETETIVE CONTEN STARTS HERE (WILL REMOVE THIS COMMENT LATER )   */}

                  {/* MAIN TABLE CONTENT STARTS HERE  */}
                </div>
              </div>
            </div>

            {/* table upper content container ends (above) */}
          </div>

          {/* NEW      */}

          {/* ADDED   */}
          <div
            className={Styles['crop-details-bottom']}
            style={{
              // marginTop: "20px",
              display: 'flex',
              // width: "192px",
              // background: "purple",
              marginLeft: '10px',
              paddingTop: '8px',
            }}
          >
            <div
              className={Styles['old-crops']}
              style={{
                display: 'flex',
                alignItems: 'center',
                width: 'unset',
                marginLeft: '10px',
                // background: "red",
              }}
            >
              <div
                style={{
                  height: '15px',
                  width: '15px',
                  backgroundColor: 'rgba(141, 141, 141, 1)',
                  marginRight: '5px',
                }}
              />
              <p style={{ marginRight: '12px', fontSize: '11px' }}>
                {translator[language]['Old Crops']}
              </p>
            </div>

            <div
              className={Styles['new-added-crops']}
              style={{
                marginLeft: '10px',
                display: 'flex',
                alignItems: 'center',
                width: 'unset',

                // background: "green",
              }}
            >
              <div
                style={{
                  height: '15px',
                  width: '15px',
                  backgroundColor: 'rgba(56, 131, 230, 1)',
                  marginRight: '5px',
                }}
              />
              <p style={{ marginRight: '12px', fontSize: '11px' }}>
                {translator[language]['New Crops']}
              </p>
            </div>
          </div>
          {/* ADDED   */}

          {/* MOBILE BUTTONS STARTS  */}
          <div className={Styles.mobileUpdateButtons}>
            {!isEditing ? (
              <div
                className={Styles.ButtonParent}
                style={{
                  // width: '37%',
                  display: 'flex',
                  justifyContent: 'space-evenly',
                }}
              >
                <Button
                  className={Styles['ed-up-ca-del-buttons']}
                  style={{
                    background: '#ffffff',

                    color: '#000000',
                    textTransform: 'capitalize',
                    width: '55px',
                  }}
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  {translator[language]['Edit']}
                </Button>

                <Button
                  className={Styles['ed-up-ca-del-buttons']}
                  disabled={cropDiversificationArray.length > 0 ? false : true}
                  style={{
                    background: 'rgba(216, 54, 54, 1)',
                    // color: "white",

                    marginLeft: '10px',
                    textTransform: 'capitalize',
                    width: '55px',
                  }}
                  size="small"
                  variant="contained"
                  onClick={() => {
                    deleteFunc();
                  }}
                >
                  {translator[language]['Delete']}
                </Button>
              </div>
            ) : (
              <div
                // className={Styles.table-upper-buttons isEditing}
                style={{
                  // width: '37%',
                  display: 'flex',
                  justifyContent: 'space-evenly',
                }}
                className={Styles.ButtonParent}
              >
                <Button
                  className={Styles['ed-up-ca-del-buttons']}
                  disabled={
                    // enableErrorOne ||
                    enableErrorTwo ||
                    checkGlobalErrorUpdate ||
                    checkGlobalErrorUpdateExceedCultivation
                      ? true
                      : false
                  }
                  style={{
                    background: 'rgba(56, 131, 230, 1)',
                    // color: "#ffffff",

                    textTransform: 'capitalize',
                    width: '55px',

                    '@media (max-width:1300px)': {
                      fontSize: '12px',
                      padding: '1px',
                    },
                  }}
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setIsEditing(false);
                    setCropDiversificationArray([]);
                    updateClicked();
                  }}
                >
                  {translator[language]['Update']}
                </Button>

                <Button
                  className={Styles['ed-up-ca-del-buttons']}
                  style={{
                    background: '#ffffff',
                    color: '#000000',

                    marginLeft: '10px',
                    textTransform: 'capitalize',
                    width: '55px',

                    '@media (max-width:1300px)': {
                      fontSize: '12px',
                      padding: '1px',
                    },
                  }}
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setEnableErrorOne(false);
                    setEnableErrorTwo(false);
                    // setEnableEnableErrorThreeForUpdate(false);
                    setIsEditing(false);
                    setCropDiversificationArray([]);
                  }}
                >
                  {translator[language]['Cancel']}
                </Button>
              </div>
            )}
          </div>

          {/* MOBILE BUTTONS ENDS  */}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',

              // marginRight: "80px",
            }}
            className={Styles['cultivation-container']}
          >
            <p
              className={Styles['total-cultivation-area']}
              style={{
                marginBottom: '5px',
              }}
            >
              {translator[language]['Total cultivation area']}:{' '}
              <br className={Styles.addBrCropDiv} />
              <span style={{ color: 'rgba(56, 131, 230, 1)' }}>
                {arrOfCropLists
                  .reduce((initialValue, value) => {
                    return initialValue + value.cropDripArea;
                  }, 0)
                  .toFixed(2)}{' '}
                {''}
              </span>{' '}
              {/* / {predefinedCropsDetail.total_cultivation_area.toFixed(2)}{' '} */}
              / {villageArea?.toFixed(2)} {translator[language]['Ha']}{' '}
            </p>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '4px',
                // marginLeft: '4px',
                // justifyContent: 'center',
              }}
            >
              <p
                className={Styles['total-drip-area']}
                style={{
                  marginLeft: '40px',
                  marginBottom: '5px',
                }}
              >
                MI: {''} <br className={Styles.addBrCropDiv} />
                <span style={{ color: 'rgb(56, 131, 230)' }}>
                  {arrOfCropLists
                    .reduce((initialValue, value) => {
                      return initialValue + value.dripOnCrop;
                    }, 0)
                    .toFixed(0)}{' '}
                </span>
                {translator[language]['Ha']}{' '}
              </p>
              <img
                data-tooltip-id="my-tooltip-2"
                onClick={() => navigator.vibrate(100)}
                src={QMarkHover}
                height="12px"
                width="12px"
                alt="tooltip"
                style={{
                  cursor: 'pointer',
                  marginLeft: '3px',
                  marginTop: '39px',
                  // marginTop: '3px',
                }}
              />
            </div>
          </div>

          <div
            className={Styles['crop-area-reduced-section']}
            style={{
              marginLeft: '14px',
              marginBottom: '0',
            }}
          >
            <p>
              {/* {translator[language]["Total Area Reduced"]} */}
              {translator[language]['Area reduced under old crops']} :{' '}
              <br className={Styles.addBrCropDiv} />
              <span style={{ color: 'rgba(56, 131, 230, 1)' }}>
                {calculateAreaReduced()}
              </span>{' '}
              {translator[language]['Ha']}
            </p>
            <p className={Styles.newCropsAddedLastDiv}>
              {translator[language]['New Crops Added']}:{' '}
              <br className={Styles.addBrCropDiv} />
              <span
                style={{
                  color: `${
                    newCropsAddedCount === 0 ? '#000' : 'rgb(56, 131, 230)'
                  }`,
                }}
              >
                {newCropsAddedCount}
              </span>
            </p>
          </div>
          {/* NEW      */}
        </div>
      </div>
    </>
  );
};

export default CropDiversification;
