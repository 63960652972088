import React, { useState } from 'react';
import QMarkHover from '../../../assets/images/QMarkHover.svg';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  Cell,
  ResponsiveContainer,
  BarChart,
  Tooltip as RechartsToolTip,
} from 'recharts';
import roundOff from '../../../services/round';
import translator from '../../../assets/json/translator.json';
import { Link, useParams } from 'react-router-dom';
import { VscTriangleDown, VscTriangleUp } from 'react-icons/vsc';
import Loader from '../../Loader/ring';
import { useContext } from 'react';
import {
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';
import Styles from './Evapotranspiration.module.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import getUnitValue from '../../../services/getUnitValueFunction';
import useProcessedChartData from '../../../services/hooks/useProcessedChartData';

const CustomTooltip = ({ active, payload, message, activeTab }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          boxShadow: '0px 3px 6px #00000029',
          width: '115%',
          padding: '5%',
          fontSize: '11px',
        }}
      >
        {activeTab === 'Vegetative' &&
          (payload.length > 0 && payload[0].payload['Vegetative'] ? (
            <p className="label" style={{ margin: '5%', color: '#000000' }}>{`${
              translator[message.language]['Vegetative']
            } : ${roundOff(payload[0].payload['Vegetative'])} ${
              translator[message.language][message.unit]
            }`}</p>
          ) : (
            <p className="label" style={{ margin: '5%', color: '#000000' }}>{`${
              translator[message.language]['Vegetative']
            } : 0 ${translator[message.language][message.unit]}`}</p>
          ))}
        {activeTab === 'Others' &&
          (payload.length > 0 && payload[0].payload['Others'] ? (
            <p className="label" style={{ margin: '5%', color: '#000000' }}>{`${
              translator[message.language]['Others']
            } : ${roundOff(payload[0].payload['Others'])} ${
              translator[message.language][message.unit]
            }`}</p>
          ) : (
            <p
              className="label"
              style={{ margin: '5%', color: '#35C9000000CE' }}
            >{`${translator[message.language]['Others']} : 0 ${
              translator[message.language][message.unit]
            }`}</p>
          ))}
      </div>
    );
  }

  return null;
};

const Evapotranspiration = (props) => {
  const { villageId, clusterId } = useParams();
  const { handlePageHead, month, year } = useContext(TimelineContext);
  const { language, programId, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);

  const [activeTab, setActiveTab] = useState('Vegetative');
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  /**
   *
   * @param {*} props - props of the recharts graph
   * @returns legend for the prop
   */

  // Adding percentage here
  const colorGWPrecentage = () => {
    if (props.previousGWLevel === 0) return { color: '#b1b0b7' };
    let val =
      ((props.currentGWLevel - props.previousGWLevel) / props.previousGWLevel) *
      100;
    if (val > 0) return { color: '#ec561b' };
    if (val < 0) return { color: '#12cb30' };
    return { color: '#b1b0b7' };
  };

  const calculateGWPercentage = () => {
    if (props.previousGWLevel === 0) return 0.0;
    return (
      ((props.currentGWLevel - props.previousGWLevel) / props.previousGWLevel) *
      100
    );
  };

  let message = {
    language: language,
    unit: selectedUnit,
  };

  let chartValues = JSON.stringify(['Vegetative', 'Others']);

  const processedData = useProcessedChartData(
    props.data,
    selectedUnit,
    selectedVillageTankerValue,
    month,
    year,
    villageId,
    chartValues,
  );

  const getColorBasedOnPercentage = (value, maxValue) => {
    const finalMaxValue = getUnitValue(
      maxValue,
      selectedUnit,
      selectedVillageTankerValue,
    );
    const percentage = (value / finalMaxValue) * 100;

    if (percentage <= 30) {
      return 'url(#greenGradient)';
    } else if (percentage <= 60) {
      return 'url(#orangeGradient)';
    } else {
      return 'url(#redGradient)';
    }
  };

  return (
    <div
      className={`${Styles.waterConsumptionContent} waterConsumptionContent`}
    >
      <div className={Styles.analyticsRainfall}>
        <>
          <div
            className={Styles.analyticsRainfallHead}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                background: '#000000',
                height: '8px',
                width: '8px',
                borderRadius: '100%',
                marginRight: '6px',
                position: 'absolute',
              }}
            ></div>
            <div style={{ marginLeft: '15px' }}>
              {
                translator[language][
                  'Evapotranspiration from various land types'
                ]
              }
            </div>

            <div
              className={Styles.evapotranspirationTabs}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginLeft: '30px',
              }}
            >
              <button
                className={`
                      ${activeTab === 'Vegetative' ? Styles.activeTab : ''}
                      ${Styles['evapotranspirationLeftButton']}
                    `}
                onClick={() => handleTabChange('Vegetative')}
                style={
                  activeTab === 'Vegetative'
                    ? { backgroundColor: '#3883e6', color: '#fff' }
                    : { backgroundColor: '#fff', color: '#3883e6' }
                }
              >
                {translator[language]['Vegetative']}
              </button>
              <button
                className={`${activeTab === 'Others' ? Styles.activeTab : ''} ${
                  Styles['evapotranspirationRightButton']
                }`}
                onClick={() => handleTabChange('Others')}
                style={
                  activeTab === 'Others'
                    ? { backgroundColor: '#3883e6', color: '#fff' }
                    : { backgroundColor: '#fff', color: '#3883e6' }
                }
              >
                {translator[language]['Others']}
              </button>
            </div>
          </div>
          <div className={Styles.analyticsRainfallValue}>
            {getUnitValue(
              roundOff(props.totalVegetation),
              selectedUnit,
              selectedVillageTankerValue,
            )}{' '}
            {translator[language][selectedUnit]}
            {/* ADDING SPAN FOR PERCENTAGE HERE  */}
            <span style={colorGWPrecentage()}>
              {calculateGWPercentage() >= 0 ? (
                calculateGWPercentage() > 0 ? (
                  <VscTriangleUp
                    style={{
                      marginRight: '4px',
                      fontSize: '0.8rem',
                      marginLeft: '8px',
                    }}
                  />
                ) : (
                  <>
                    <VscTriangleDown
                      style={{
                        marginRight: '4px',
                        marginLeft: '8px',
                        fontSize: '0.8rem',
                      }}
                    />
                  </>
                )
              ) : (
                <VscTriangleDown
                  style={{
                    marginRight: '4px',
                    marginLeft: '8px',
                    fontSize: '0.8rem',
                  }}
                />
              )}
              <span style={{ fontSize: '12px' }}>
                {roundOff(Math.abs(calculateGWPercentage()))} %
              </span>
            </span>
            {/* ADDING SPAN FOR PERCENTAGE HERE  */}
          </div>

          <div
            className={Styles.evapotranspirationFlexParent}
            style={{ display: 'flex' }}
          >
            <div className={Styles.thirty} style={{ marginLeft: '15px' }}>
              <div className={Styles.mobileFlexEvapotranspiration}>
                <div className={Styles.mobileFlexEvapotranspirationLeft}>
                  <div className={Styles['main-heading-rainfall']}>
                    {translator[language]['From Vegetation']}:
                    <div style={{ marginLeft: '3px', marginTop: '3px' }}>
                      <img
                        data-tooltip-id="my-tooltip-1"
                        onClick={() => navigator.vibrate(100)}
                        src={QMarkHover}
                        height="12px"
                        width="12px"
                        alt="tooltip"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </div>
                  <div className={Styles['main-content-rainfall']}>
                    {getUnitValue(
                      roundOff(props.fromVegetation),
                      selectedUnit,
                      selectedVillageTankerValue,
                    )}{' '}
                    {translator[language][selectedUnit]}
                  </div>
                </div>
                <div>
                  <div className={Styles['main-heading-rainfall']}>
                    {translator[language]['From other Land types']}:
                    <div style={{ marginLeft: '3px', marginTop: '3px' }}>
                      <img
                        onClick={() => navigator.vibrate(100)}
                        data-tooltip-id="my-tooltip-2"
                        src={QMarkHover}
                        height="12px"
                        width="12px"
                        alt="tooltip"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </div>
                  <div className={Styles['main-content-rainfall']}>
                    {getUnitValue(
                      roundOff(props.fromOtherLandTypes),
                      selectedUnit,
                      selectedVillageTankerValue,
                    )}{' '}
                    {translator[language][selectedUnit]}
                  </div>
                </div>
              </div>

              <div className={Styles.landuseAlertButtonParentDiv}>
                <Link
                  onClick={() => handlePageHead('Analytics Landuse')}
                  className={Styles.fullReportButton}
                  style={{ marginTop: '20px' }}
                  to={`/analytics-landuse/programId/${programId}/clusterId/${clusterId}/villageId/${villageId}`}
                >
                  {translator[language]['Full report']}
                  {' >'}
                </Link>
              </div>
            </div>

            <div className={Styles.seventy}>
              <div
                style={{
                  background: '',
                  height: '175px',
                }}
              >
                {!props.isLoading ? (
                  <ResponsiveContainer
                    width={window.innerWidth < 768 ? '94%' : '100%'}
                    height="100%"
                  >
                    <BarChart
                      margin={{
                        left: -10,
                        right: 5,
                      }}
                      width={500}
                      height={350}
                      data={processedData}
                      barSize={20}
                      barGap={-10}
                    >
                      <defs>
                        <linearGradient
                          id="redGradient"
                          x1="0%"
                          y1="0%"
                          x2="0%"
                          y2="100%"
                        >
                          <stop
                            offset="0%"
                            style={{ stopColor: '#992626', stopOpacity: 1 }}
                          />
                          <stop
                            offset="100%"
                            style={{
                              stopColor: 'rgba(237, 163, 163, 0.48)',
                              stopOpacity: 0,
                            }}
                          />
                        </linearGradient>
                        <linearGradient
                          id="orangeGradient"
                          x1="0%"
                          y1="0%"
                          x2="0%"
                          y2="100%"
                        >
                          <stop
                            offset="0%"
                            style={{ stopColor: '#B5911A', stopOpacity: 1 }}
                          />
                          <stop
                            offset="100%"
                            style={{
                              stopColor: 'rgba(255, 232, 154, 0.48)',
                              stopOpacity: 0,
                            }}
                          />
                        </linearGradient>
                        <linearGradient
                          id="greenGradient"
                          x1="0%"
                          y1="0%"
                          x2="0%"
                          y2="100%"
                        >
                          <stop
                            offset="0%"
                            style={{ stopColor: '#057137', stopOpacity: 1 }}
                          />
                          <stop
                            offset="100%"
                            style={{
                              stopColor: 'rgba(141, 211, 174, 0.48)',
                              stopOpacity: 0,
                            }}
                          />
                        </linearGradient>
                      </defs>

                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" fontSize={10} />
                      <YAxis
                        domain={[
                          0,
                          getUnitValue(
                            props?.maxValue,
                            selectedUnit,
                            selectedVillageTankerValue,
                          ),
                        ]}
                        fontSize={10}
                        label={{
                          value: translator[language][selectedUnit],
                          angle: -90,
                          position: 'insideLeft',
                          dx: window.innerWidth < 768 ? 20 : 10,
                          dy: window.innerWidth < 768 ? 15 : 12,
                          scaleToFit: true,
                          fontSize: 10,
                          fill: '#707070',
                        }}
                      />

                      <RechartsToolTip
                        formatter={(value) =>
                          new Intl.NumberFormat('en').format(value) +
                          ' ' +
                          translator[language][selectedUnit]
                        }
                        content={
                          <CustomTooltip
                            message={message}
                            activeTab={activeTab}
                          />
                        }
                      />

                      {/* <Legend
                        verticalAlign="top"
                        height={6}
                        align="center"
                        content={renderLegend}
                      /> */}

                      {activeTab === 'Vegetative' && (
                        <Bar dataKey="Vegetative" fill="#7A797E">
                          {processedData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={getColorBasedOnPercentage(
                                entry['Vegetative'],
                                props?.maxValue,
                              )}
                            />
                          ))}
                        </Bar>
                      )}

                      {activeTab === 'Others' && (
                        <Bar dataKey="Others" fill="#AEAEAE">
                          {processedData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={getColorBasedOnPercentage(
                                entry['Others'],
                                props?.maxValue,
                              )}
                            />
                          ))}
                        </Bar>
                      )}

                      {/* 
                      <Bar dataKey="Vegetative" fill="#7A797E">
                        {processedData?.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            // fill={
                            //   index === props.data.length - 1
                            //     ? '#3883E6'
                            //     : '#7A797E'
                            // }
                            fill={getColorBasedOnPercentage(
                              entry['Vegetative'],
                              props?.maxValue,
                            )}
                          />
                        ))}
                      </Bar>
                      <Bar dataKey="Others" fill="#AEAEAE">
                        {processedData?.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            // fill={
                            //   index === props.data.length - 1
                            //     ? '#35C9CE'
                            //     : '#AEAEAE'
                            // }
                            fill={getColorBasedOnPercentage(
                              entry['Others'],
                              props?.maxValue,
                            )}
                          />
                        ))}
                      </Bar> */}
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <Loader
                    style={{
                      position: 'relative',
                      top: '50%',
                      left: '50%',
                    }}
                  />
                )}
              </div>
            </div>

            {/* ADDING THE CHART HERE ENDS */}
          </div>
        </>
      </div>

      <ReactTooltip
        id="my-tooltip-1"
        place="top"
        style={{
          background: 'black',
          color: 'white',
          fontSize: window.innerWidth < 768 ? '10px' : '12px',
        }}
        content={translator[language]['Crops, Shrubs, etc.']}
      />
      <ReactTooltip
        id="my-tooltip-2"
        place="top"
        style={{
          background: 'black',
          color: 'white',
          fontSize: window.innerWidth < 768 ? '10px' : '12px',
        }}
        content={
          translator[language]['Tree-cover, Waterbodies Barren Land, etc.']
        }
      />
    </div>
  );
};

export default Evapotranspiration;
