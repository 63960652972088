import React, { useState, useEffect, useContext } from 'react';
import { Get } from '../../services/apiCall';
import SidebarLoader from '../Loader/bounce';
import SidebarTaluka from './Taluka';
import { GoIssueOpened } from 'react-icons/go';
import { AiFillHome } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import translator from '../../assets/json/translator.json';
import Program from './Program';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../services/context/context';

// MATERIAL UI INCLUDE
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Styles from './index.module.css';
import CloseIcon from '@mui/icons-material/Close';
import getEndDate from '../../services/getEndDate';

/**
 *
 * @component Sidebar
 * @description This components shows the list of talukas under the programs and
 * villages which comes under those talukas and also acts as a navigation panel
 */

const Sidebar = (props) => {
  /**
   *
   * @states
   * isLoading --> If true show the loading animation
   * hierarchy --> Stores the heirarchy of the program program -> taluka -> village
   */
  const [isLoading, setIsLoading] = useState(true);
  const [hierarchy, setHierarchy] = useState([]);

  const activeBar = () => {
    if (
      window.location.pathname.split('/')[1].startsWith('program') ||
      window.location.pathname === '/'
    )
      return 'home';
    if (window.location.pathname.split('/')[1].startsWith('cluster'))
      return `clusterId/${window.location.pathname.split('/')[5]}`;
    if (window.location.pathname.split('/')[1].startsWith('analytics'))
      return `villageId/${window.location.pathname.split('/')[7]}`;
    if (window.location.pathname.split('/')[1].startsWith('plan'))
      return `villageId/${window.location.pathname.split('/')[7]}`;
    if (window.location.pathname.split('/')[1].startsWith('advisory'))
      return `villageId/${window.location.pathname.split('/')[7]}`;
    if (window.location.pathname.split('/')[1].startsWith('web-gis'))
      return `villageId/${window.location.pathname.split('/')[7]}`;
  };

  // added for mapping programs on sidebar
  const [programs, setPrograms] = useState([]);

  /**
   * @description fires a get api call to the backend to get the heirarchy of the program
   * and store it in the state
   */

  const { language, programId, unmountNS } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);
  const {
    year,
    month,
    handlePageHead,
    handleOrganizationOnboardedDate,
    handlePlanYear,
  } = useContext(TimelineContext);

  const handleCloseClick = () => {
    props.setIsSidebarOpen(false);
    setTimeout(() => {
      props.enableScroll();
    }, [600]);
  };

  useEffect(() => {
    const initialFunction = async () => {
      const programParams = {
        language: language,
      };
      const getPrograms = await Get(
        '/info/programs-under-user',
        token,
        programParams,
      );
      if (getPrograms.status === 'success') {
        let programs = [];

        getPrograms.data.map((program) => {
          return programs.push({
            label: program.name,
            value: program.id,
          });
        });

        setPrograms(programs);

        const filterCurrentOrganizationId = getPrograms.data.filter(
          (program) => {
            return Number(program.id) === Number(programId);
          },
        );

        let currentSelectedOrganizationStartDate =
          filterCurrentOrganizationId[0].hasOwnProperty('Organizations')
            ? filterCurrentOrganizationId[0]?.Organizations[0]
                ?.organization_program?.start_date
            : '';

        let duration = filterCurrentOrganizationId[0].hasOwnProperty(
          'Organizations',
        )
          ? filterCurrentOrganizationId[0]?.Organizations[0]
              ?.organization_program?.duration
          : '';

        let endDate = getEndDate(
          currentSelectedOrganizationStartDate,
          duration,
        );
        // let endDate = getEndDate('2021-01-01', 24);

        handleOrganizationOnboardedDate(
          currentSelectedOrganizationStartDate,
          endDate,
        );

        // getting the month of organization end date, to set the initial plan session
        let endDateMonth = Number(endDate.split('-')[1]);
        let endDateYear = Number(endDate.split('-')[0]);

        let currentYear = new Date().getFullYear();

        let currentMonth = new Date().getMonth() + 1;

        if (currentYear < endDateYear) {
          if (currentMonth >= 6) {
            handlePlanYear(`${currentYear}-${currentYear + 1}`);
          } else {
            handlePlanYear(`${currentYear - 1}-${currentYear}`);
          }
        } else if (currentYear > endDateYear) {
          if (endDateMonth >= 6) {
            handlePlanYear(`${endDateYear}-${endDateYear + 1}`);
          } else {
            handlePlanYear(`${endDateYear - 1}-${endDateYear}`);
          }
        } else if (currentYear === endDateYear) {
          if (currentMonth >= 6) {
            handlePlanYear(`${currentYear}-${currentYear + 1}`);
          } else {
            handlePlanYear(`${currentYear - 1}-${currentYear}`);
          }
        }
      }
      const params = {
        programId: programId,
        year: year,
        month: month + 1,
        language: language,
      };
      const result = await Get('/info/hierarchy', token, params);

      setIsLoading(false);
      setHierarchy(result.data.Clusters);
    };

    initialFunction();

    return () => {
      setHierarchy([]);
      setPrograms([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, programId, year, month]);

  /**
   *
   * @condition if prop unmount is true show nothing
   * */

  return unmountNS ? (
    ''
  ) : (
    <Box className={Styles['sidebar-container']}>
      <Drawer
        ref={props.sidebarRef}
        sx={{
          width: '16%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '16%',
            boxSizing: 'border-box',
            backgroundColor: '#2c303b',
            overflowX: 'hidden',

            '@media (max-width:950px)': {
              width: '20%',
            },
            '@media (max-width:768px)': {
              width: '40vw',
              transition: '0.5s ease',
              transform: `translateX(${props.isSidebarOpen ? '0' : '-100%'})`,
            },
            '@media (max-width:480px)': {
              width: '60vw',
              transition: '0.5s ease',
              transform: `translateX(${props.isSidebarOpen ? '0' : '-100%'})`,
            },
          },
        }}
        variant="permanent"
        anchor="left"
      >
        {/* <Toolbar /> */}
        {window.innerWidth <= 768 && (
          <CloseIcon id={Styles.closeIcon} onClick={handleCloseClick} />
        )}

        <img
          src="https://assets.ekatvam-midas.com/images/logo-side.svg"
          alt="company logo"
          className={Styles.companyLogoSide}
        />
        <div className={Styles['tagLine-nav']}>
          An Ekatvam Innovations Product
        </div>

        {/* <Divider /> */}
        <div className={Styles.sidenavList}>
          <div className={Styles['sidenav-programs']}>
            <Program programs={programs} />
          </div>
          <div className={Styles.sidenavHome}>
            <AiFillHome className={Styles.homeIcon} />
            <Link
              onClick={() => {
                handlePageHead('Home');
                handleCloseClick();
              }}
              to="/"
              className={`${Styles.link} ${
                activeBar() === 'home' ? Styles.activeLink : ''
              }`}
            >
              {translator[language]['Home']}
            </Link>
          </div>
          <div className={Styles.sidenavTalukas}>
            {translator[language]['Clusters']}
          </div>
          <div className={Styles.sidenavTalukasList}>
            {isLoading ? (
              <SidebarLoader
                style={{
                  position: 'relative',
                  top: '50%',
                  left: '50%',
                  height: '100%',
                  with: '100%',
                }}
              />
            ) : (
              hierarchy.map((taluka) => (
                <SidebarTaluka
                  key={taluka.id}
                  taluka={taluka}
                  activeBar={activeBar}
                  location={window.location.pathname}
                  handleCloseClick={handleCloseClick}
                />
              ))
            )}
          </div>
          <div
            style={{
              fontSize: '0.8rem',
              padding: '8px',
              marginTop: '60px',
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <GoIssueOpened className={Styles.issueIcon} />
            <a
              href="https://forms.gle/PtJzxWM6iStivVb38"
              target="_blank"
              rel="noopener noreferrer"
              className={Styles.link}
              onClick={handleCloseClick}
            >
              {translator[language]['Report an Issue']}
            </a>
          </div>
        </div>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
