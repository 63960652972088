import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Get } from '../../../services/apiCall';
import roundOff from '../../../services/round';
import translator from '../../../assets/json/translator.json';
import { VscTriangleDown, VscTriangleUp } from 'react-icons/vsc';
import months from '../../../shared/constants/months';
import { sortDatesByYearAndMonth } from '../../../services/genericSortMonthYear';
import Loader from '../../Loader/ring';
import { useContext } from 'react';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';
import Styles from './SurfacewaterAlert.module.css';
import getUnitValue from '../../../services/getUnitValueFunction';
import useProcessedChartData from '../../../services/hooks/useProcessedChartData';

const CustomTooltip = ({
  active,
  payload,
  message,
  selectedUnit,
  selectedVillageTankerValue,
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          boxShadow: '0px 3px 6px #00000029',
          width: '130%',
          padding: '5%',
        }}
      >
        {payload.length > 0 && payload[0].payload.name ? (
          <p
            className="label"
            style={{ margin: '5%', fontWeight: '600' }}
          >{`${payload[0].payload.name}`}</p>
        ) : (
          <></>
        )}
        {payload.length > 0 && payload[0].payload.Value ? (
          <p className="label" style={{ margin: '5%', color: '#5e97f1' }}>{`${
            translator[message.language]['Value']
          } : ${payload[0].payload.Value} ${
            translator[message.language][selectedUnit]
          }`}</p>
        ) : (
          <p className="label" style={{ margin: '5%', color: '#5e97f1' }}>{`${
            translator[message.language]['Value']
          } : 0 ${translator[message.language][selectedUnit]}`}</p>
        )}
      </div>
    );
  }

  return null;
};

/**
 *
 * @component SurfacewaterAlert
 * @states
 * data --> An array that stores the Surface water bodies detected for previous 6 months(max)
 * @description
 * This component represents the expanded version of the AlertHeader on the Analytic Overview
 * page and it contains an overview of the Surfacewater data for a particular village
 */

const SurfacewaterAlert = (props) => {
  const { villageId, clusterId } = useParams();
  const { month, year, pageHead, handlePageHead } = useContext(TimelineContext);
  const { language, programId, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [surfacewaterBodiesSum, setSurfacewaterBodiesSum] = useState(0);
  const [data, setData] = useState([]);
  const [previousGWLevel, setPreviousGWLevel] = useState(0);
  const [currentGWLevel, setCurrentGWLevel] = useState(0);

  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    const initialFunction = async () => {
      setIsLoading(true);
      const params = {
        villageId: villageId,
        month: month + 1,
        year: year,
        programId: programId,
        clusterId: clusterId,
      };
      const response = await Get('/datapoint/surfacewater', token, params);
      let payload = response.data;
      const sortedData = sortDatesByYearAndMonth(payload);
      payload = sortedData;

      const finalData = [];
      if (payload && payload.length > 0) {
        payload.sort((a, b) => {
          if (a.year === b.year) return a.month - b.month;
          return a.year - b.year;
        });
        payload.map((ele) => {
          return finalData.push({
            name: `${months[ele.month - 1].substring(0, 3)} ${String(
              ele.year,
            ).slice(-2)}`,
            Value: roundOff(ele.total_surface_water_storage),
          });
        });
      }

      // Setting the currentSwLevel and previousSwLevel
      if (payload.length >= 2) {
        setCurrentGWLevel(
          payload[payload.length - 1].total_surface_water_storage
            ? payload[payload.length - 1].total_surface_water_storage
            : 0,
        );
        setPreviousGWLevel(
          payload[payload.length - 2].total_surface_water_storage
            ? payload[payload.length - 2].total_surface_water_storage
            : 0,
        );
      } else if (payload.length > 0) {
        setCurrentGWLevel(
          payload[payload.length - 1].total_surface_water_storage
            ? payload[payload.length - 1].total_surface_water_storage
            : 0,
        );
      }

      let currentMonthPayload =
        payload?.length &&
        payload?.filter((vals) => {
          return vals.month === month + 1 && vals.year === Number(year);
        });

      setSurfacewaterBodiesSum(
        currentMonthPayload[0]?.total_water_structures ?? 0,
      );

      setData(finalData);

      //calling surfacewater api

      const paramsS = {
        villageId: villageId,
        year: year,
        month: month + 1,
        language: language,
        programId: programId,
        clusterId: clusterId,
      };
      const surfacewaterResponse = await Get(
        '/analytics/surfacewater',
        token,
        paramsS,
      );
      const surfacewaterData = surfacewaterResponse?.data;

      if (
        surfacewaterData &&
        surfacewaterData.VillageWaterStructureYearlyStats.length > 0
      ) {
        // const data = surfacewaterData.VillageWaterStructureYearlyStats;
        // const max = data?.reduce((initial, d) => {
        //   return initial + parseFloat(d.depth) * parseFloat(d.area) * 10;
        // }, 0);
        // const finalMax = Number(max.toFixed(2));
        // setMaxValue(finalMax);

        const data = surfacewaterData.VillageWaterStructureYearlyStats;
        const max = Math.max(
          ...data.map((d) => parseFloat(d.depth) * parseFloat(d.area) * 10),
        );
        const finalMax = Number(max.toFixed(2));
        setMaxValue(finalMax);
      }

      setIsLoading(false);
    };

    initialFunction();

    return () => {
      setSurfacewaterBodiesSum(0);
      setData([]);
      setPreviousGWLevel(0);
      setCurrentGWLevel(0);
      setMaxValue(0);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, pageHead, villageId, programId, clusterId, token]);

  /**
   *
   * @param {*} runoff - runoff generate that month
   * @param {*} surfacewater - total surfacewater of that month
   * @returns style as per the runoff percentage
   */

  // Adding percentage here
  const colorGWPrecentage = () => {
    if (previousGWLevel === 0) return { color: '#b1b0b7' };
    let val = ((currentGWLevel - previousGWLevel) / previousGWLevel) * 100;
    if (val > 0) return { color: '#12cb30' };
    if (val < 0) return { color: '#ec561b' };
    return { color: '#b1b0b7' };
  };

  const calculateGWPercentage = () => {
    if (previousGWLevel === 0) return 0.0;
    return ((currentGWLevel - previousGWLevel) / previousGWLevel) * 100;
  };

  let message = {
    language: language,
  };

  let chartValues = JSON.stringify(['Value']);

  const processedData = useProcessedChartData(
    data,
    selectedUnit,
    selectedVillageTankerValue,
    month,
    year,
    villageId,
    chartValues,
  );

  const getColorBasedOnPercentage = (value, maxValue) => {
    // const percentage = (value / maxValue) * 100;

    const finalMaxValue = getUnitValue(
      maxValue,
      selectedUnit,
      selectedVillageTankerValue,
    );
    const percentage = (value / finalMaxValue) * 100;

    if (percentage <= 30) {
      return 'url(#redGradient)';
    } else if (percentage <= 60) {
      return 'url(#orangeGradient)';
    } else {
      return 'url(#greenGradient)';
    }
  };

  return (
    <div className={`${Styles.surfaceAlertContent} surfaceAlertContent`}>
      <div className={Styles.surfaceAlertContentKey}>
        <div className={Styles.surfaceAlertDotStyle}></div>

        <div className={Styles.totalSurfaceWaterHeading}>
          {translator[language]['Total']} {translator[language]['Surfacewater']}
          :
        </div>
      </div>
      <div className={Styles.surfaceAlertContentValue}>
        {getUnitValue(
          props.totalSurfaceWater,
          selectedUnit,
          selectedVillageTankerValue,
        )}{' '}
        {translator[language][selectedUnit]}
        {/* ADDING SPAN FOR PERCENTAGE HERE  */}
        <span style={colorGWPrecentage()}>
          {calculateGWPercentage() >= 0 ? (
            calculateGWPercentage() > 0 ? (
              <VscTriangleUp
                style={{
                  marginRight: '4px',
                  fontSize: '0.8rem',
                  marginLeft: '8px',
                }}
              />
            ) : (
              <>
                <VscTriangleDown
                  style={{
                    marginRight: '4px',
                    marginLeft: '8px',
                    fontSize: '0.8rem',
                  }}
                />
              </>
            )
          ) : (
            <VscTriangleDown
              style={{
                marginRight: '4px',
                marginLeft: '8px',
                fontSize: '0.8rem',
              }}
            />
          )}
          <span style={{ fontSize: '12px' }}>
            {roundOff(Math.abs(calculateGWPercentage()))} %
          </span>
        </span>
        {/* ADDING SPAN FOR PERCENTAGE HERE  */}
      </div>
      <div className={Styles.surfaceAlertStatus}>
        <div className={Styles.left}>
          <div className={Styles.surfacewaterlevel}>
            {translator[language]['Your surfacewater level has']}{' '}
            <span
              style={{
                fontWeight: 'bold',
                color: 'unset',
                fontSize: 'unset',
              }}
            >
              {calculateGWPercentage() > 0
                ? translator[language]['Increased']
                : translator[language]['Reduced']}
            </span>{' '}
            {translator[language]['compared to Previous month.']}
          </div>
          <div className={Styles.surfacewaterbodiesParentDiv}>
            <span
              className={Styles.surfacewaterbodies}
              style={{ color: 'unset' }}
            >
              {translator[language]['Surfacewater bodies']}:
            </span>
            <br />
            <span
              className={Styles.surfacewaterbodiessum}
              style={{
                display: 'inline-block',
                fontWeight: 'bold',
                marginTop: '5px',
                color: 'unset',
              }}
            >
              {surfacewaterBodiesSum}
            </span>
          </div>

          <Link
            onClick={() => handlePageHead('Analytics Surfacewater')}
            to={`/analytics-surfacewater/programId/${programId}/clusterId/${clusterId}/villageId/${villageId}`}
            className={Styles.surfaceAlertButton}
            style={{ marginTop: '20px' }}
          >
            {translator[language]['Full report']}
            {' >'}
          </Link>
          <br />
          <br />
        </div>
        <div className={Styles.right}>
          {!isLoading ? (
            <ResponsiveContainer
              width={window.innerWidth < 768 ? '98%' : '100%'}
              height="100%"
            >
              <BarChart
                width={250}
                height={150}
                barSize={30}
                data={processedData}
                margin={{ top: -8 }}
                className={Styles.surfaceAlertStatusGraph}
              >
                <defs>
                  <linearGradient
                    id="redGradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: '#992626', stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{
                        stopColor: 'rgba(237, 163, 163, 0.48)',
                        stopOpacity: 0,
                      }}
                    />
                  </linearGradient>
                  <linearGradient
                    id="orangeGradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: '#B5911A', stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{
                        stopColor: 'rgba(255, 232, 154, 0.48)',
                        stopOpacity: 0,
                      }}
                    />
                  </linearGradient>
                  <linearGradient
                    id="greenGradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: '#057137', stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{
                        stopColor: 'rgba(141, 211, 174, 0.48)',
                        stopOpacity: 0,
                      }}
                    />
                  </linearGradient>
                </defs>

                <XAxis
                  dataKey="name"
                  tick={{ fontSize: 9, fill: '#707070' }}
                  // orientation="top"
                />
                <YAxis
                  dataKey="Value"
                  // domain={[() => 0, (dataMax) => (dataMax * 1.5).toFixed(2)]}
                  domain={[
                    0,
                    getUnitValue(
                      maxValue,
                      selectedUnit,
                      selectedVillageTankerValue,
                    ),
                  ]}
                  tick={{ fontSize: 9, fill: '#707070' }}
                  label={{
                    value: translator[language][selectedUnit],
                    angle: -90,
                    position: 'insideLeft',
                    dx: 10,
                    dy: 15,
                    scaleToFit: true,
                    fontSize: 10,
                    fill: '#707070',
                  }}
                />
                <Tooltip
                  wrapperStyle={{ fontSize: '12px' }}
                  content={
                    <CustomTooltip
                      message={message}
                      selectedUnit={selectedUnit}
                      selectedVillageTankerValue={selectedVillageTankerValue}
                    />
                  }
                />

                <Bar
                  dataKey="Value"
                  background={{ fill: '#EFDFC0' }}
                  // radius={[15, 15, 0, 0]}
                >
                  {processedData.map((d, index) => (
                    <Cell
                      key={d.name}
                      fill={getColorBasedOnPercentage(d.Value, maxValue)}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Loader
              style={{
                position: 'relative',
                top: '50%',
                left: '50%',
              }}
            />
          )}
        </div>
      </div>
      <div className={Styles.surfaceAlertButtonMobileDiv}>
        <Link
          onClick={() => handlePageHead('Analytics Surfacewater')}
          to={`/analytics-surfacewater/programId/${programId}/clusterId/${clusterId}/villageId/${villageId}`}
          className={Styles.surfaceAlertButtonMobile}
          style={{ marginTop: '20px' }}
        >
          {translator[language]['Full report']}
          {' >'}
        </Link>
      </div>
    </div>
  );
};
export default SurfacewaterAlert;
