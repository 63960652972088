import React, { useContext } from 'react';
import RsuiteBar from './RsuiteBar';

import RsuiteBarSupply from './RsuiteBarSupply';

import TickGreen from '../../assets/images/TickGreen.svg';
import AlertIcon from '../../assets/images/AlertIcon.svg';
import warning from '../../assets/images/warning.png';

import paginatePrevious from '../../assets/images/paginatePrevious.svg';
import paginateCurrent from '../../assets/images/paginateCurrent.svg';
import translator from '../../assets/json/translator.json';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Get } from '../../services/apiCall';
import roundOff from '../../services/round';
import { AuthContext, GeneralContext } from '../../services/context/context';
import Styles from './RightSide.module.css';
import getUnitValue from '../../services/getUnitValueFunction';
import QMarkHover from '../../assets/images/QMarkHover.svg';

const RightSide = (props) => {
  const { language, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);
  const { accountType, token } = useContext(AuthContext);

  const changeRange = (val, getCropWaterRequirementValue) => {
    props.setCurrentCropWaterRequirementValue(getCropWaterRequirementValue);

    props.setCurrentRange(val);
  };

  // FOR SUPPLY ONLY
  const changeSupplyRange = (val) => {
    props.setSupplyCurrentRange(val);
  };
  // FOR SUPPLY ONLY

  const setRecommendedFunctionCall = async () => {
    const isRecommendedAvailable = await Get(
      `/simulation/scenario?id=${props.recommendedScenarioId}`,
      // `/simulation/scenario?id=48e00934-c841-47ba-8ae9-3689b8ba8fcc`,
      token,
    );
    if (isRecommendedAvailable.status === 'success') {
      props.setRecommendedCrops(isRecommendedAvailable.data.data.crops);
      props.setRecommendedSupplyCurrentRange(
        isRecommendedAvailable.data.data.supplyCurrentRange,
      );
      props.setCropListMode('Recommended');
    } else {
      alert('No recommendation is available for this village!');
    }
  };

  const uniqueIds = new Set();

  return (
    <>
      <div className={Styles['third-item-top']}>
        <div className={Styles['curr-situation']}>
          {/* <p>{translator[language][props.title]}</p> */}
          <p>{props.title}</p>
        </div>

        {accountType !== 'admin' && (
          <div className={Styles['right-buttons']}>
            {props.currentCrop === 'Demand Side' ||
            props.currentCrop === 'Supply Side' ? (
              <>
                <div
                  className={`
                    ${Styles.btn1} ${
                    props.cropListMode === 'Manual'
                      ? Styles['active-crop-mode1']
                      : ''
                  }
                      `}
                  onClick={() => {
                    props.setCropListMode('Manual');
                    //  props.setCurrentRange(10);
                  }}
                >
                  <p>{translator[language]['Manual']}</p>
                </div>
                <div
                  className={`
                    ${Styles.btn2} ${
                    props.cropListMode === 'Recommended'
                      ? Styles['active-crop-mode2']
                      : ''
                  }
                  `}
                  onClick={() => {
                    // props.setCropListMode("Recommended");

                    setRecommendedFunctionCall();
                    // props.setCurrentRange(50);
                    // props.setSupplyCurrentRange(5);
                  }}
                >
                  <p>{translator[language]['Recommended']}</p>
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${Styles.btn1} ${Styles['active-crop-mode1']}`}
                >
                  <p>{translator[language]['Most']}</p>
                </div>
                <div className={Styles.btn2}>
                  <p>{translator[language]['Least']}</p>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {/* ends  */}

      <div className={`${Styles['third-item-top-two']}`}>
        <div className={Styles['third-two-left']} style={{ marginTop: '10px' }}>
          {props.currentCrop === 'Demand Side' && (
            <>
              <p>{translator[language]['Crop List']}</p>
              {props.currentCrop === 'Demand Side' && (
                <p
                  className={Styles['order-most-least']}
                  style={{ color: '#A4A4A4' }}
                >
                  {
                    translator[language][
                      'In order of MOST -> LEAST water intensive'
                    ]
                  }
                </p>
              )}
            </>
          )}
        </div>
        <div className={Styles['third-two-right']}>
          {props.currentCrop === 'Current Situation' ? (
            <p>Order of Water Intensive Crops</p>
          ) : props.cropListMode === 'Manual' ? (
            <></>
          ) : (
            <p>{translator[language]['Suggested By Ekatvam']}</p>
          )}
        </div>
      </div>
      {/* ends  */}

      {/* start here   */}
      {props.currentCrop === 'Demand Side' ? (
        <div className={Styles['third-item-three']}>
          <div className={Styles['third-less-padding']}>
            <div className={Styles['less-padding-top-left']}>
              <p style={{ fontWeight: '600' }}>
                {translator[language]['Crop Name']}
              </p>
            </div>

            <div
              className={Styles['less-padding-top-right']}
              style={{
                justifyContent: 'space-between',
              }}
            >
              <div className={Styles['less-padding-right-one']}>
                <div style={{ fontWeight: '600', marginLeft: '4px' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    MI
                    <img
                      data-tooltip-id="my-tooltip-2"
                      onClick={() => navigator.vibrate(100)}
                      src={QMarkHover}
                      height="12px"
                      width="12px"
                      alt="tooltip"
                      style={{
                        cursor: 'pointer',
                        marginLeft: '3px',
                        // marginTop: '3px',
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={Styles['less-padding-right-two']}>
                <p style={{ fontWeight: '600' }}>
                  {translator[language]['Sown Area']}
                </p>
              </div>
            </div>
          </div>

          {/* LOOPING THROUGH RANGE BAR */}
          <div
            className={Styles['supply-demand-right-scroller']}
            style={{
              // maxHeight: "350px",

              overflowY: 'scroll',
              padding: '2px 15px',
            }}
          >
            {props.cropListMode === 'Manual' ||
            props.cropListMode === 'Recommended'
              ? props.allCrops?.map((elem, index) => {
                  if (!uniqueIds.has(elem.id)) {
                    // If not, add it to the Set and render the element
                    uniqueIds.add(elem.id);

                    return (
                      <div
                        key={elem.uuid}
                        className={Styles['bar-div-container']}
                      >
                        <div
                          className={Styles['rsuitebar-header-div']}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              className={Styles.cropImages}
                              // src={`https://assets.ekatvam-midas.com/crops/icons-v2/${elem.id}.svg`}
                              src={`https://assets.ekatvam-midas.com/crops/icons-contrast/${elem.id}.svg`}
                              alt="crop images"
                              height={24}
                              width={24}
                              style={{
                                marginRight: '7px',
                                fill: 'red',
                              }}
                            />

                            <p
                              className={Styles['crop-name-map']}
                              style={{ marginLeft: '3px' }}
                            >
                              {/* {translator[language][elem.name]} */}
                              {elem.name}

                              <span className={Styles.cropTypePlan}>
                                {' '}
                                ({translator[language][elem.type]})
                              </span>
                            </p>
                          </div>

                          <div
                            className={Styles.percentageValuePlan}
                            style={{
                              display: 'flex',
                              alignItems: 'flex-end',
                            }}
                          >
                            <span
                              style={{
                                color: 'rgba(56, 131, 230, 1)',
                                fontWeight: 'bold',
                                // fontSize: "14px",
                                marginRight: '2px',
                              }}
                            >
                              {(
                                (elem.drip_area / elem.crop_area) *
                                100
                              ).toFixed(0)}
                            </span>
                            %
                            {elem.drip_area < elem.fixed_drip_area && (
                              <>
                                <img
                                  data-tooltip-id="my-tooltip-1"
                                  onClick={() => navigator.vibrate(100)}
                                  src={AlertIcon}
                                  alt="Alert"
                                  width={16}
                                  height={16}
                                  style={{
                                    marginLeft: '4px',
                                    cursor: 'pointer',
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className={Styles['bar-div']}>
                          <div className={Styles['bar-div-suitebar']}>
                            <RsuiteBar
                              currentRange={props.currentRange}
                              changeRange={changeRange}
                              cropListMode={props.cropListMode}
                              currentCrop={props.currentCrop}
                              id={elem}
                              value={
                                // Math.round(
                                (elem.drip_area / elem.crop_area) * 100
                                // )
                              }
                              allCrops={props.allCrops}
                              setAllCrops={props.setAllCrops}
                              predefinedCropsDetail={
                                props.predefinedCropsDetail
                              }
                              // CWR of the crop = [(CWR of the crop for 1 Hectare) * (total area of the crop)]
                              //  - [(CWR of the crop for 1 Hectare) * (drip area of the crop)* (drip efficiency of the crop)]

                              cropWaterRequirement={
                                elem.per_ha_cwr * elem.crop_area -
                                elem.per_ha_cwr *
                                  elem.drip_area *
                                  elem.drip_efficiency
                              }
                            />
                          </div>
                          <div className={Styles['right-two-ones']}>
                            <div className={Styles['twelve']}>
                              <div
                                className={Styles['mobile-mi-tooltip-parent']}
                                style={{ fontWeight: '600', marginLeft: '4px' }}
                              >
                                <div
                                  className={Styles['mobile-mi-tooltip']}
                                  style={{
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <p className={Styles.mobileTableHeadingsPlan}>
                                    MI
                                  </p>
                                  <img
                                    data-tooltip-id="my-tooltip-2"
                                    onClick={() => navigator.vibrate(100)}
                                    src={QMarkHover}
                                    height="12px"
                                    width="12px"
                                    alt="tooltip"
                                    style={{
                                      cursor: 'pointer',
                                      marginLeft: '3px',
                                      // marginTop: '3px',
                                    }}
                                  />
                                </div>
                              </div>

                              <p>
                                {' '}
                                <span
                                  style={{
                                    color: 'rgba(56, 131, 230, 1',
                                    marginRight: '10px',
                                  }}
                                >
                                  {elem.drip_area.toFixed(2)}
                                </span>
                                {''}
                                {translator[language]['Ha']}
                              </p>
                            </div>
                            <div
                              className={Styles['twelve-two']}
                              style={{ fontWeight: 'bold' }}
                            >
                              <p className={Styles.mobileTableHeadingsPlan}>
                                {translator[language]['Sown Area']}
                              </p>
                              <p>
                                {' '}
                                <span
                                  style={{
                                    marginRight: '10px',
                                  }}
                                >
                                  {roundOff(elem?.crop_area)}
                                </span>
                                {''}
                                {translator[language]['Ha']}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })
              : null}
          </div>
          <div style={{ borderTop: '1px solid rgba(216,216,216,1)' }}></div>
          {/* LOOPING THROUGH RANGE BAR       */}

          <div className={Styles['bottom-last']}>
            <div className={Styles.tableLastdivMobile}>
              <p className={Styles['total-text']}>
                {translator[language]['Total']}
              </p>
            </div>
            <div className={Styles['total-items-calculation-text']}>
              <p
                style={{
                  fontWeight: 'bold',
                  width: '50%',
                }}
              >
                <span className={Styles.mobileTotalHeadingsTable}>
                  {' '}
                  {translator[language]['Drip Area']} -
                </span>
                <span
                  style={{
                    color: 'rgba(56, 131, 230, 1',
                    marginRight: '10px',
                  }}
                >
                  {props.allCrops
                    .reduce((initialValue, val) => {
                      return initialValue + val.drip_area;
                    }, 0)
                    .toFixed(1)}
                </span>
                {translator[language]['Ha']}
              </p>
              <p
                style={{
                  fontWeight: 'bold',
                  width: '50%',
                  textAlign: 'center',
                }}
              >
                <span className={Styles.mobileTotalHeadingsTable}>
                  {translator[language]['Sown Area']}
                </span>
                <span
                  style={{
                    marginRight: '10px',
                  }}
                >
                  {props.allCrops
                    .reduce((initialValue, val) => {
                      return initialValue + val.crop_area;
                    }, 0)
                    .toFixed(1)}
                  {/* ---- */}
                </span>
                {translator[language]['Ha']}
              </p>
            </div>
          </div>
        </div>
      ) : props.currentCrop === 'Supply Side' ? (
        <div className={Styles['supply-side-container']}>
          <p className={Styles['monsoon']} style={{ fontWeight: '' }}>
            {translator[language]['Water Available after Monsoon']} :{' '}
            <span style={{ fontWeight: 'bold' }}>
              {getUnitValue(
                props.staticCurrentWaterAvailable.toFixed(2),
                selectedUnit,
                selectedVillageTankerValue,
              )}
              &nbsp; {translator[language][selectedUnit]}
            </span>
          </p>

          <p className={Styles['runoff-available']}>
            {translator[language]['Runoff Available']} :{' '}
            <span style={{ fontWeight: 'bold' }}>
              {getUnitValue(
                props.checkRunoffAvailable.toFixed(2),
                selectedUnit,
                selectedVillageTankerValue,
              )}{' '}
              &nbsp; {translator[language][selectedUnit]}
            </span>
          </p>

          <div className={Styles['water-increase-and-available']}>
            <div
              style={{
                fontWeight: '',

                width: 'fit-content',
              }}
            >
              {translator[language]['% Increase in Water']}
              : <br />
              <p
                style={{
                  color: 'rgba(56, 131, 230, 1)',
                  fontWeight: '',
                  textAlign: 'right',
                  marginTop: '5px',
                }}
              >
                {props.supplyCurrentRange} %
              </p>
            </div>

            <div
              style={{
                fontWeight: '',

                width: 'fit-content',
              }}
            >
              {translator[language]['Water Made Available']}
              : <br />
              <p
                style={{
                  color: 'rgba(56, 131, 230, 1)',
                  textAlign: 'right',
                  marginTop: '5px',
                }}
              >
                {getUnitValue(
                  props.waterMadeAvailable,
                  selectedUnit,
                  selectedVillageTankerValue,
                )}
                &nbsp; {translator[language][selectedUnit]}
              </p>
            </div>
          </div>

          <div
            className={Styles['single-slider']}
            style={{ marginLeft: '30px', marginTop: '35px' }}
          >
            <div className={Styles['slider-and-warning']}>
              <RsuiteBarSupply
                supplyCurrentRange={props.supplyCurrentRange}
                setSupplyCurrentRange={props.setSupplyCurrentRange}
                changeSupplyRange={changeSupplyRange}
                cropListMode={props.cropListMode}
                recommendedSupplyCurrentRange={
                  props.recommendedSupplyCurrentRange
                }
              />

              {props.cropListMode === 'Manual' ? (
                props.supplyCurrentRange > 0 && !props.isRunoffAvailable ? (
                  <p className={Styles['warning-or-ok-war']}>
                    <span style={{ marginRight: '7px' }}>
                      <img src={warning} alt="warning" width={22} height={22} />
                    </span>
                    {
                      translator[language][
                        'Runoff is not available, maximum Runoff Available is'
                      ]
                    }{' '}
                    {props.maxPercentageOfSuppy} %
                  </p>
                ) : (
                  props.supplyCurrentRange > 0 && (
                    <p className={Styles['warning-or-ok']}>
                      <span style={{ marginRight: '7px' }}>
                        <img
                          src={TickGreen}
                          alt="TickGreen"
                          width={22}
                          height={22}
                        />
                      </span>
                      {translator[language]['Okay']}
                    </p>
                  )
                )
              ) : (
                <p
                  style={{
                    marginLeft: '42px',
                    width: '160px',
                    color: 'rgba(18, 203, 48, 1)',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '15px',
                  }}
                >
                  <span style={{ marginRight: '7px' }}>
                    <img
                      src={TickGreen}
                      alt="TickGreen"
                      width={22}
                      height={22}
                    />
                  </span>
                  {translator[language]['Okay']}
                </p>
              )}
            </div>
          </div>

          <div
            style={{
              marginTop: '25px',
              borderTop: '1px solid rgba(216,216,216,1)',
              width: '90%',
            }}
          ></div>

          <p className={Styles['runoff-to-be-arrested']}>
            {translator[language]['Runoff to be arrested']} :{' '}
            <span
              style={{ color: 'rgba(56, 131, 230, 1)', fontWeight: 'bold' }}
            >
              {getUnitValue(
                props.runoffToBeArrestedStagant.toFixed(2),
                selectedUnit,
                selectedVillageTankerValue,
              )}
              &nbsp; {translator[language][selectedUnit]}
            </span>
          </p>
        </div>
      ) : (
        ''
      )}
      {/* end here  */}

      <div
        className={Styles['bottom-dots']}
        style={{
          justifyContent: 'center',
          marginTop: '15px',
          position: 'absolute',
          bottom: '10px',
          left: '50%',
        }}
      >
        <div style={{ marginRight: '4px' }}>
          <img
            alt="navigators"
            src={
              props.currentCrop === 'Demand Side'
                ? paginateCurrent
                : paginatePrevious
            }
            width={9}
            height={9}
          />
        </div>
        <div style={{ marginRight: '' }}>
          <img
            alt="navigators"
            src={
              props.currentCrop === 'Supply Side'
                ? paginateCurrent
                : paginatePrevious
            }
            width={9}
            height={9}
          />
        </div>
      </div>

      <ReactTooltip
        id="my-tooltip-1"
        place="bottom"
        style={{
          background: 'black',
          color: 'white',
          fontSize: window.innerWidth < 768 ? '10px' : '12px',
        }}
        content={
          translator[language]['Current Drip is less than Previous Drip']
        }
      />
      <ReactTooltip
        id="my-tooltip-2"
        place="top"
        style={{
          background: 'black',
          color: 'white',
          fontSize: window.innerWidth < 768 ? '10px' : '12px',
        }}
        content={'Micro Irrigation'}
      />
    </>
  );
};

export default RightSide;
