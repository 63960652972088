import React from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import translator from '../../../assets/json/translator.json';
import roundOff from '../../../services/round';
import { useContext } from 'react';
import { GeneralContext } from '../../../services/context/context';
import HomeStyles from './TalukaChart.module.css';

/**
 *
 * @param {*} active - True if mouse is on that section of the bar chart
 * @param {*} payload - Object which stores the data we want to display
 * @param {*} message - Object which stores the language we want to display the parameters
 * @returns Custom tooltip and style on hovering over the bar chart
 */
const CustomTooltip = ({ active, payload, label, message }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className={HomeStyles['custom-tooltip']}
        style={{
          backgroundColor: '#f3f3f3',
          padding: '15px',
          borderRadius: '5px',
        }}
      >
        <p className="label" style={{ fontSize: '0.7rem', fontWeight: 600 }}>
          {message.Condition}
        </p>
        <p className="desc" style={{ fontSize: '0.7rem', fontWeight: 600 }}>
          {`${payload[0].value} ${message.Villages}`}
          <br /> {`${label} ${message.Stage}`}
        </p>
      </div>
    );
  }

  return null;
};
/**
 *
 * @component TalukaChart
 * @description
 * This component represents the stages bar chart on the bottom component of the
 * Home page
 */

const TalukaChart = (props) => {
  const { language } = useContext(GeneralContext);

  let message = {
    Condition: translator[language]['Condition'],
    Villages: translator[language]['Villages'],
    Stage: translator[language]['Stage'],
  };
  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      className={HomeStyles.villageChart}
    >
      <BarChart
        width={50}
        height={50}
        barSize={15}
        data={[
          {
            name: translator[language]['Safe'],
            value: props.safeVillage.length,
          },
          {
            name: translator[language]['Critical'],
            value: props.criticalVillage.length,
          },
          {
            name: translator[language]['Exploited'],
            value: props.exploitedVillage.length,
          },
        ]}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis
          dataKey="name"
          tick={{ fontSize: 10, fill: '#707070' }}
          label={{
            value: translator[language]['Stage'],
            dx: 0,
            dy: 12,
            scaleToFit: true,
            fontSize: 10,
            fill: '#707070',
          }}
          interval={0}
        />
        <YAxis
          dataKey="value"
          domain={[(dataMin) => 0, (dataMax) => roundOff(dataMax * 1.2)]}
          tick={{ fontSize: 10, fill: '#707070' }}
          label={{
            value: translator[language]['Number of villages'],
            angle: -90,
            position: 'insideLeft',
            dx: 20,
            dy: 40,
            scaleToFit: true,
            fontSize: 10,
            fill: '#707070',
          }}
        />
        <Tooltip content={<CustomTooltip message={message} />} />
        <Bar dataKey="value" fill="#8884d8" radius={[15, 15, 0, 0]}>
          <Cell key={`Safe`} fill={'#50B432'} />
          <Cell key={`Critical`} fill={'#DDDF00'} />
          <Cell key={`Exploited`} fill={'#ED561B'} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
export default TalukaChart;
