import React, { useContext, useEffect, useState } from 'react';
import TalukaStats from '../components/Taluka/TalukaStats/index';
import VillageStats from '../components/Taluka/VillagesUnderTaluka/index';
import { Get } from '../services/apiCall';
// import mixpanel from "mixpanel-browser";
import { useParams } from 'react-router-dom';
import Styles from './Taluka.module.css';

import Mixpanel from '../services/mixpanel.service';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../services/context/context';

/**
 *
 * @component Taluka
 * @states
 * isLoading --> If true show animation
 * totalArea --> Stores sum of area of villages that comes under that taluka
 * totalWaterSaved --> Stores the sum of water saved in each village that comes under that taluka
 * totalCropArea --> Stores the sum of crop area in each village that comes under that taluka
 * totalCropDripArea --> Stores the sum of crop's drip area in each village that comes under that taluka
 * totalWaterStructure --> Stores the sum of total water structure governed or found in each village that comes under that taluka
 * @description Program head's taluka page which contains analytics of that taluka
 */

const Taluka = () => {
  const { month, year, showSeason, handleSeason, pageHead } =
    useContext(TimelineContext);
  const { unmount, unmountNS, language, programId } =
    useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [totalArea, setTotalArea] = useState(0.0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalWaterSaved, setTotalWaterSaved] = useState(0.0);
  const [totalCropArea, setTotalCropArea] = useState(0.0);
  const [totalCropDripArea, setTotalCropDripArea] = useState(0.0);
  const [totalWaterStructure, setTotalWaterStructure] = useState(0);

  const { clusterId } = useParams();

  useEffect(() => {
    Mixpanel.time_event('TalukaPage');
  }, []);

  useEffect(() => {
    if (unmountNS) unmount(false);
    /**
     *
     * @reason
     * when ever this page is rendering we want month calendar on Navbar instead of
     * season calendar, therefore we call the prop function handleSeason(false) to
     * make the state showSeason false in App component
     */
    if (showSeason) handleSeason(false);

    const initialFunction = async () => {
      setIsLoading(true);
      /**
       *
       * @reason
       * when ever this page is rendered we have want to have that taluka name on the web
       * dashboard navbar, to achieve this we call the prop function handleActiveVillage and
       * pass the name of the taluka from the url by the help of react router. As we have
       * used path="/taluka/:id/:name/analytics" in react router, it will automatically pass
       * variable id and name as a props.match.params to this component
       * @example
       * if the url is this https://ekatvam-midas.com/taluka/3849/Rajula/analytics we extract
       * 'Rajula' from the url and send it to the App component through the handleActiveVillage prop
       * function
       */
      // const { id } = props.match.params;
      // everywhere we have passed - month: props.month + 1
      // because frontend works on 0-11 months and backend works on 1-12 months
      const params = {
        year: year,
        month: month + 1,
        language: language,
        programId: programId,
        clusterId: clusterId,
      };
      // const result = await Get("/analytics/taluka", props.token, params);
      const result = await Get('/analytics/cluster', token, params);
      let totalArea = 0.0;
      let totalWaterSaved = 0.0;
      let totalCropArea = 0.0;
      let totalCropDripArea = 0.0;
      let totalWaterStructure = 0;
      if (result && result.data && result.data.ClusterVillageStats.length > 0) {
        totalArea = result.data.ClusterVillageStats.reduce(
          (a, b) =>
            b.area ? parseFloat(a) + parseFloat(b.area) : parseFloat(a),
          0,
        );
        totalArea = totalArea.toFixed(2);
        for (let i = 0; i < result.data.ClusterVillageStats.length; i++) {
          totalWaterSaved +=
            result.data.ClusterVillageStats[i] &&
            result.data.ClusterVillageStats[i].VillageMonthlyStats1[0] &&
            result.data.ClusterVillageStats[i].VillageMonthlyStats1[0]
              .water_saved_cummulative
              ? result.data.ClusterVillageStats[i].VillageMonthlyStats1[0]
                  .water_saved_cummulative
              : 0;
        }

        if (
          result.data &&
          result.data.ClusterVillageStats &&
          result.data.ClusterVillageStats.length > 0 &&
          result.data.ClusterVillageStats[0].VillageCropMonthlyStats.length > 0
        ) {
          var n = result.data.ClusterVillageStats.length;
          for (var i = 0; i < n; i++) {
            var m =
              result.data.ClusterVillageStats[i].VillageCropMonthlyStats.length;
            for (var j = 0; j < m; j++) {
              const crop_area =
                result.data.ClusterVillageStats[i].VillageCropMonthlyStats[j]
                  .crop_area;
              totalCropArea += parseFloat(crop_area);
              const crop_drip_area =
                result.data.ClusterVillageStats[i].VillageCropMonthlyStats[j]
                  .crop_drip_area;
              totalCropDripArea += parseFloat(crop_drip_area);
            }
          }
        }
        totalWaterStructure = result.data.WaterStructureMonitored
          ? result.data.WaterStructureMonitored
          : 0;

        setTotalArea(totalArea);
        setTotalWaterSaved(totalWaterSaved);
        setTotalCropArea(totalCropArea);
        setTotalCropDripArea(totalCropDripArea);
        setTotalWaterStructure(totalWaterStructure);
      }
      setIsLoading(false);
    };
    initialFunction();

    return () => {
      Mixpanel.track('TalukaPage', {
        month: month + 1,
        year: year,
        programId: programId,
        clusterId: clusterId,
      });

      setTotalArea(0.0);
      setTotalWaterSaved(0.0);
      setTotalCropArea(0.0);
      setTotalCropDripArea(0.0);
      setTotalWaterStructure(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, pageHead]);

  return (
    <>
      <div className={Styles.talukaPage}>
        <TalukaStats
          isLoading={isLoading}
          totalArea={totalArea}
          totalWaterSaved={totalWaterSaved}
          totalCropArea={totalCropArea}
          totalCropDripArea={totalCropDripArea}
          totalWaterStructure={totalWaterStructure}
        />
        <VillageStats />
      </div>
    </>
  );
};

export default Taluka;
