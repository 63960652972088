import styles from './index.module.css';
import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FaChevronLeft, FaChevronRight, FaLayerGroup } from 'react-icons/fa';
import { ANALYTICS_VIEW } from '../../../shared/enums/analytics-view';
import GroundwaterLayerIcon from '../../../assets/images/layers-icons/groundwater.svg';
import SurfacewaterLayerIcon from '../../../assets/images/layers-icons/surfacewater.svg';
import WaterDistributionNetworkLayerIcon from '../../../assets/images/layers-icons/water-distribution-network.svg';
import WaterBudgetLayerIcon from '../../../assets/images/layers-icons/water-budget.svg';
import PopulationDensityLayerIcon from '../../../assets/images/layers-icons/population-density.svg';
import { GiWaterDrop } from 'react-icons/gi';

const Layers = ({
  layers,
  selectedItems,
  handleCheckboxChange,
  isLayersCollapsed,
  toggleIsLayersCollapsed,
}) => {
  const [expandedItems, setExpandedItems] = useState({});

  const toggleExpand = (id) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const getLayerIcon = (id) => {
    switch (id) {
      case 2:
        return GroundwaterLayerIcon;
      case 5:
        return SurfacewaterLayerIcon;
      case 11:
        return WaterDistributionNetworkLayerIcon;
      case 16:
        return WaterBudgetLayerIcon;
      case 20:
        return PopulationDensityLayerIcon;
      default:
        return GiWaterDrop;
    }
  };

  const renderList = (items, level = 1) => {
    return items.map((item) => (
      <div
        key={item.id}
        style={{
          backgroundColor: isLayersCollapsed
            ? '#fff'
            : !expandedItems[item.id]
            ? item.darkThemeHexCode
            : item.lightThemeHexCode,
          borderRadius: '10px',
          color: item.isDisabled ? '#747474' : undefined,
        }}
      >
        <div
          className={styles.listItem}
          style={
            level === 1
              ? {
                  backgroundColor: isLayersCollapsed
                    ? '#fff'
                    : expandedItems[item.id]
                    ? item.darkThemeHexCode
                    : item.lightThemeHexCode,
                  padding: !isLayersCollapsed ? '0 10px 0 0' : undefined,
                  margin: '15px 0 0 0',
                  borderRadius: '10px',
                  fontWeight: 500,
                  border: isLayersCollapsed ? 'none' : undefined,
                  display: isLayersCollapsed ? 'flex' : undefined,
                  justifyContent: isLayersCollapsed ? 'center' : undefined,
                  color: item.isDisabled ? '#747474' : undefined,
                }
              : {}
          }
        >
          {level === 1 ? (
            <>
              <img
                src={getLayerIcon(item.id)}
                alt="Groundwater layer icon"
                style={{
                  width: '15%',
                  display: isLayersCollapsed ? 'none' : undefined,
                  alignItems: 'center',
                }}
              />
              {!isLayersCollapsed && <span style={{ width: '10px' }} />}
              <label
                style={{
                  flex: 1,
                  display: isLayersCollapsed ? 'none' : undefined,
                }}
                onClick={() => toggleExpand(item.id)}
              >
                {item.name}
              </label>
              {item.children &&
                item.children.length > 0 && ( // Check if item has children
                  <button
                    className={styles.toggleListView}
                    onClick={() => toggleExpand(item.id)}
                    style={{
                      marginRight: '10px',
                      backgroundColor: expandedItems[item.id]
                        ? item.dropdownHexCode
                        : '#fff',
                      display: isLayersCollapsed ? 'none' : undefined,
                    }}
                  >
                    {expandedItems[item.id] ? (
                      <IoIosArrowUp />
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </button>
                )}
              <input
                type="checkbox"
                style={{ accentColor: item.checkboxHexCode }}
                className={styles.listItemCheckBox}
                checked={selectedItems[item.id] || false}
                onChange={() =>
                  handleCheckboxChange(item.id, ANALYTICS_VIEW.TOTAL)
                }
                disabled={item.isDisabled}
              />
            </>
          ) : (
            <>
              <input
                type="checkbox"
                style={{
                  accentColor: item.checkboxHexCode,
                  display: isLayersCollapsed ? 'none' : undefined,
                }}
                className={styles.listItemCheckBox}
                checked={selectedItems[item.id] || false}
                onChange={() =>
                  handleCheckboxChange(item.id, ANALYTICS_VIEW.SOURCE)
                }
                disabled={item.isDisabled}
              />
              {!isLayersCollapsed && <span style={{ width: '5px' }} />}
              <label
                style={{
                  flex: 1,
                  display: isLayersCollapsed ? 'none' : undefined,
                }}
                onClick={() => toggleExpand(item.id)}
              >
                {item.name}
              </label>
              {item.children &&
                item.children.length > 0 && ( // Check if item has children
                  <button
                    className={styles.toggleListView}
                    onClick={() => toggleExpand(item.id)}
                    style={{
                      marginRight: '10px',
                      backgroundColor: expandedItems[item.id]
                        ? item.dropdownHexCode
                        : '#fff',
                      display: isLayersCollapsed ? 'none' : undefined,
                    }}
                  >
                    {expandedItems[item.id] ? (
                      <IoIosArrowUp />
                    ) : (
                      <IoIosArrowDown />
                    )}{' '}
                  </button>
                )}
            </>
          )}
        </div>
        {item.children &&
          item.children.length > 0 &&
          expandedItems[item.id] && (
            <div style={{ paddingLeft: '20px', paddingBottom: '5px' }}>
              {renderList(item.children, level + 1)}{' '}
            </div>
          )}
      </div>
    ));
  };

  return (
    <div
      className={styles.layers}
      style={{
        width: isLayersCollapsed ? '3%' : '30%',
        minWidth: isLayersCollapsed ? '30px' : '250px',
        maxWidth: isLayersCollapsed ? '42px' : '350px',
      }}
    >
      <div
        className={styles.list}
        style={{
          padding: isLayersCollapsed ? 0 : undefined,
        }}
      >
        {' '}
        <div
          className={styles.listHead}
          style={{
            display: isLayersCollapsed ? 'flex' : undefined,
            justifyContent: isLayersCollapsed ? 'center' : undefined,
          }}
        >
          <FaLayerGroup
            style={{ display: isLayersCollapsed ? 'none' : undefined }}
          />
          {!isLayersCollapsed && <span style={{ width: '15px' }} />}
          <label
            style={{
              flex: 1,
              fontWeight: 700,
              display: isLayersCollapsed ? 'none' : undefined,
            }}
            onClick={() => toggleExpand(layers[0].id)}
          >
            {layers[0].name}
          </label>
          <input
            type="checkbox"
            className={styles.listHeadCheckBox}
            checked={selectedItems[layers[0].id] || false}
            onChange={() =>
              handleCheckboxChange(layers[0].id, ANALYTICS_VIEW.TOTAL)
            }
          />
        </div>
        <div style={{ height: '10px' }}></div>
        <>{renderList(layers[0].children)}</>
      </div>
      <button
        className={styles.toggleLayersView}
        onClick={toggleIsLayersCollapsed}
      >
        {isLayersCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
      </button>
    </div>
  );
};

export default Layers;
