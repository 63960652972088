import { CD_ID, FP_ID, PT_ID, VP_ID } from '../../../../../shared/utils/tree';
import styles from './index.module.css';
import SegmentChart from './SegmentChart';

const SurfacewaterSource = ({ gis, selectedItems }) => {
  const isVPActive = selectedItems[VP_ID];
  const isFPActive = selectedItems[FP_ID];
  const isPTActive = selectedItems[PT_ID];
  const isCDActive = selectedItems[CD_ID];

  const actives = [isVPActive, isFPActive, isPTActive, isCDActive];
  const lastActive = actives.lastIndexOf(true);

  const structureIndexes = {
    VP: 'Village Ponds',
    FP: 'Farm Ponds',
    PT: 'Percolation Tanks',
    CD: 'Check Dams',
  };

  const structureColorMapping = {
    [structureIndexes.VP]: '#EFD07F',
    [structureIndexes.FP]: '#FFD86E',
    [structureIndexes.PT]: '#C8B277',
    [structureIndexes.CD]: '#F0BE39',
  };

  return (
    <div>
      <div className={styles.header}>Surfacewater Sources</div>
      <div className={styles.content}>
        {isVPActive && (
          <div className={styles.villageSection}>
            <div className={styles.title}>Village Ponds:</div>
            <div className={styles.chartContainer}>
              <SegmentChart
                name={structureIndexes.VP}
                filled={gis?.village_ponds}
                color={structureColorMapping[structureIndexes.VP]}
                total={Number(gis?.total_village_pond)}
              />
            </div>
            <div className={styles.chartIndex}>
              <div className={styles.indexItem}>
                <div
                  className={`${styles.indexColor} ${styles.villagePondColor}`}
                ></div>
                <div>
                  {`Filled ${structureIndexes.VP}: `}
                  <b>{gis?.village_ponds}</b>
                </div>
              </div>
              <div className={styles.indexItem}>
                <div
                  className={`${styles.indexColor}`}
                  style={{ backgroundColor: '#fff' }}
                ></div>
                <div>
                  {`Total ${structureIndexes.VP}: `}
                  <b>{Number(gis?.total_village_pond)}</b>
                </div>
              </div>
            </div>
          </div>
        )}
        {isVPActive && lastActive !== 0 && <hr className={styles.divider} />}
        {isFPActive && (
          <div className={styles.villageSection}>
            <div className={styles.title}>Farm Ponds:</div>
            <div className={styles.chartContainer}>
              <SegmentChart
                name={structureIndexes.FP}
                filled={gis?.farm_ponds}
                color={structureColorMapping[structureIndexes.FP]}
                total={Number(gis?.total_farm_pond)}
              />
            </div>
            <div className={styles.chartIndex}>
              <div className={styles.indexItem}>
                <div
                  className={`${styles.indexColor} ${styles.farmPondColor}`}
                ></div>
                <div>
                  {`Filled ${structureIndexes.FP}: `} <b>{gis?.farm_ponds}</b>
                </div>
              </div>
              <div className={styles.indexItem}>
                <div
                  className={`${styles.indexColor}`}
                  style={{ backgroundColor: '#fff' }}
                ></div>
                <div>
                  {`Total ${structureIndexes.FP}: `}
                  <b>{Number(gis?.total_farm_pond)}</b>
                </div>
              </div>
            </div>
          </div>
        )}
        {isFPActive && lastActive !== 1 && <hr className={styles.divider} />}
        {isPTActive && (
          <div className={styles.villageSection}>
            <div className={styles.title}>Percolation Tanks:</div>
            <div className={styles.chartContainer}>
              <SegmentChart
                name={structureIndexes.PT}
                filled={gis?.percolation_tanks}
                color={structureColorMapping[structureIndexes.PT]}
                total={Number(gis?.total_percolation_tank)}
              />
            </div>
            <div className={styles.chartIndex}>
              <div className={styles.indexItem}>
                <div
                  className={`${styles.indexColor} ${styles.percolationTankColor}`}
                ></div>
                <div>
                  {`Filled ${structureIndexes.PT}: `}
                  <b>{gis?.percolation_tanks}</b>
                </div>
              </div>
              <div className={styles.indexItem}>
                <div
                  className={`${styles.indexColor}`}
                  style={{ backgroundColor: '#fff' }}
                ></div>
                <div>
                  {`Total ${structureIndexes.PT}: `}
                  <b>{Number(gis?.total_percolation_tank)}</b>
                </div>
              </div>
            </div>
          </div>
        )}
        {isPTActive && lastActive !== 2 && <hr className={styles.divider} />}
        {isCDActive && (
          <div className={styles.villageSection}>
            <div className={styles.title}>Check Dams:</div>
            <div className={styles.chartContainer}>
              <SegmentChart
                name={structureIndexes.CD}
                filled={gis?.check_dams}
                color={structureColorMapping[structureIndexes.CD]}
                total={Number(gis?.total_check_dam)}
              />
            </div>
            <div className={styles.chartIndex}>
              <div className={styles.indexItem}>
                <div
                  className={`${styles.indexColor} ${styles.checkDamColor}`}
                ></div>
                <div>
                  {`Filled ${structureIndexes.CD}: `} <b>{gis?.check_dams}</b>
                </div>
              </div>
              <div className={styles.indexItem}>
                <div
                  className={`${styles.indexColor}`}
                  style={{ backgroundColor: '#fff' }}
                ></div>
                <div>
                  {`Total ${structureIndexes.CD}: `}
                  <b>{Number(gis?.total_check_dam)}</b>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SurfacewaterSource;
