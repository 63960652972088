import React from 'react';
import { VscTriangleDown } from 'react-icons/vsc';
import roundOff from '../../../services/round';
import translator from '../../../assets/json/translator.json';
import { useContext } from 'react';
import { GeneralContext } from '../../../services/context/context';
import Styles from './AlertHeader.module.css';
import getUnitValue from '../../../services/getUnitValueFunction';

/**
 * @component AlertHeader
 * @description
 * This component represents the header of the alert bar on the Analytics overview page
 */

const AlertHeader = (props) => {
  const { language, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);
  return (
    <div
      id={props.id}
      className={Styles.alertGroundwaterHead}
      onClick={() => props.showContent(props.id)}
    >
      <div className={Styles.alertGroundwaterHeadContent}>
        <div className={Styles.alertHeadImageWrapper}>
          {/* <span
            style={{
              display: 'grid',
              placeItems: 'center',
              background: '#EBF3FD',
              borderRadius: '100%',
              width: '30px',
              height: '30px',
            }}
          > */}
          <img
            alt="groundwater icon"
            src={props?.icon}
            style={{
              marginRight: '12px',
              height: '20px',
              width: '20px',
            }}
          />
          {/* </span> */}
          {props.headKey}
          <span className={Styles.alertGroundwaterHeadContentValue}>
            {getUnitValue(
              roundOff(props.headValue),
              selectedUnit,
              selectedVillageTankerValue,
            )}{' '}
            {translator[language][selectedUnit]}
          </span>
        </div>
        <div>
          <VscTriangleDown className={Styles.alertGroundwaterHeadContentIcon} />
        </div>
      </div>
    </div>
  );
};
export default AlertHeader;
