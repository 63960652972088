import React from 'react';
import TalukaStatBox from './StatBox';
import TalukaPieBox from './PieBox';
// import "../../../css/Taluka.css";
import translator from '../../../assets/json/translator.json';
import Grid from '@mui/material/Grid';
import { useContext } from 'react';
import { GeneralContext } from '../../../services/context/context';
import Styles from './index.module.css';

/**
 *
 * @component TalukaStats
 * @description
 * This component represents the upper analytics part of the taluka page
 * which has four analytics box of that taluka
 */

const TalukaStats = (props) => {
  const { language } =
    useContext(GeneralContext);

  return (
    <>
      <div className={Styles.talukaPageProgramHead}>
        {translator[language]['Cluster Status']}
      </div>

      <Grid
        container
        justify="center"
        // spacing={6}
        spacing={{ xs: 2, sm: 4, md: 6 }}
        className={Styles['grid-program-section']}
      >
        <Grid item xs={6} sm={4} md={3}>
          <TalukaStatBox
            head={translator[language]['Area being managed'] + ' :'}
            value={props.totalArea}
            isLoading={props.isLoading}
            unit={translator[language]['Ha']}
          />
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <TalukaStatBox
            head={translator[language]['Water saved'] + ' :'}
            value={props.totalWaterSaved}
            isLoading={props.isLoading}
            unit={translator[language]['TCM']}
          />
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <TalukaPieBox
            head={translator[language]['Drip implemented'] + ' :'}
            cropArea={props.totalCropArea}
            cropDripArea={props.totalCropDripArea}
            isLoading={props.isLoading}
          />
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <TalukaStatBox
            head={translator[language]['Water structure monitored'] + ' :'}
            value={props.totalWaterStructure}
            isLoading={props.isLoading}
          />
        </Grid>
      </Grid>
      {/* </div> */}
    </>
  );
};
export default TalukaStats;
