import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
  BarChart,
} from 'recharts';
import roundOff from '../../../../services/round';
import translator from '../../../../assets/json/translator.json';
import { useContext } from 'react';
import {
  GeneralContext,
  TimelineContext,
} from '../../../../services/context/context';
import Styles from './RainfallChart.module.css';
import getUnitValue from '../../../../services/getUnitValueFunction';
import useProcessedChartData from '../../../../services/hooks/useProcessedChartData';

const RainfallChart = (props) => {
  const { language, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);
  const { month, year, pageHead } = useContext(TimelineContext);

  const {
    activeParameter,
    mmDataComparison,
    daysDataComparison,
    tcmDataComparison,
    handleActiveParameter,
    villageTotalRainfall,
  } = props;

  const CustomTooltip = ({ active, payload, message }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: '#fff',
            boxShadow: '0px 3px 6px #00000029',
            width: '115%',
            padding: '5%',
          }}
        >
          {payload.length > 0 &&
          payload[0].payload[
            activeParameter === 'Days'
              ? 'Current Rainy Days'
              : 'Current Rainfall'
          ] ? (
            <p
              className={Styles.label}
              style={{ margin: '5%', color: '#84b5f7' }}
            >
              {`${
                activeParameter === 'Days'
                  ? translator[language]['Current Rainy Days']
                  : translator[language]['Current Rainfall']
              } : ${roundOff(
                payload[0].payload[
                  activeParameter === 'Days'
                    ? 'Current Rainy Days'
                    : 'Current Rainfall'
                ],
              )} ${translator[language][message.unit]}`}
            </p>
          ) : (
            <p
              className={Styles.label}
              style={{ margin: '5%', color: '#84b5f7' }}
            >{`${
              activeParameter === 'Days'
                ? translator[language]['Current Rainy Days']
                : translator[language]['Current Rainfall']
            } : 0 ${translator[language][message.unit]}`}</p>
          )}

          {payload.length > 0 &&
          payload[0].payload[
            activeParameter === 'Days'
              ? 'Average 5 Yr. Rainy Days'
              : 'Average 5 Yr. Rainfall'
          ] ? (
            <p
              className={Styles.label}
              style={{ margin: '5%', color: '#12cb30' }}
            >
              {`${
                activeParameter === 'Days'
                  ? translator[language]['Average 5 Yr. Rainy Days']
                  : translator[language]['Average 5 Yr. Rainfall']
              } : ${roundOff(
                payload[0].payload[
                  activeParameter === 'Days'
                    ? 'Average 5 Yr. Rainy Days'
                    : 'Average 5 Yr. Rainfall'
                ],
              )}
            ${translator[language][message.unit]}`}
            </p>
          ) : (
            <p
              className={Styles.label}
              style={{ margin: '5%', color: '#12cb30' }}
            >{`${
              activeParameter === 'Days'
                ? translator[language]['Average 5 Yr. Rainy Days']
                : translator[language]['Average 5 Yr. Rainfall']
            } : 0 ${translator[language][message.unit]}`}</p>
          )}

          {payload.length > 0 &&
          payload[0].payload[
            activeParameter === 'Days'
              ? 'Prev. year Rainy Days'
              : 'Prev. year Rainfall'
          ] ? (
            <p
              className={Styles.label}
              style={{ margin: '5%', color: '#FFCC24' }}
            >{`${
              activeParameter === 'Days'
                ? translator[language]['Prev. year Rainy Days']
                : translator[language]['Prev. year Rainfall']
            } : ${roundOff(
              payload[0].payload[
                activeParameter === 'Days'
                  ? 'Prev. year Rainy Days'
                  : 'Prev. year Rainfall'
              ],
            )} ${translator[language][message.unit]}`}</p>
          ) : (
            <p
              className={Styles.label}
              style={{ margin: '5%', color: '#FFCC24' }}
            >{`${
              activeParameter === 'Days'
                ? translator[language]['Prev. year Rainy Days']
                : translator[language]['Prev. year Rainfall']
            } : 0 ${translator[message.language][message.unit]}`}</p>
          )}
        </div>
      );
    }

    return null;
  };

  let message = {
    language: language,
    unit: activeParameter,
  };

  const chartValues = JSON.stringify([
    'Current Rainfall',
    'Prev. year Rainfall',
    'Average 5 Yr. Rainfall',
  ]);

  const processedData = useProcessedChartData(
    tcmDataComparison,
    selectedUnit,
    selectedVillageTankerValue,
    month,
    year,
    pageHead,
    chartValues,
  );

  return (
    <>
      <div
        className={Styles['heading-area-metdata']}
        style={{
          // margin: '15px 0',
          display: 'flex',
        }}
      >
        <div className={Styles['flex-media']} style={{ marginRight: '30px' }}>
          <div className={Styles.currMonthMobileMargin}>
            {activeParameter === 'Days'
              ? translator[language]['Current Month Rainy Days']
              : activeParameter === 'mm' || activeParameter === selectedUnit
              ? translator[language]['Current month rainfall']
              : ''}
          </div>
          <div
            className={`${Styles.mt4} ${Styles.currMonthMobileMargin}`}
            style={{ color: '#3883E6', fontWeight: 'bold' }}
          >
            {activeParameter === 'mm'
              ? roundOff(
                  mmDataComparison[mmDataComparison.length - 1][
                    'Current Rainfall'
                  ],
                )
              : activeParameter === selectedUnit
              ? getUnitValue(
                  roundOff(
                    tcmDataComparison[tcmDataComparison.length - 1][
                      'Current Rainfall'
                    ],
                  ),
                  selectedUnit,
                  selectedVillageTankerValue,
                )
              : daysDataComparison[daysDataComparison.length - 1][
                  'Current Rainy Days'
                ]}{' '}
            {activeParameter === selectedUnit
              ? translator[language][selectedUnit]
              : activeParameter === 'mm'
              ? translator[language]['mm']
              : ''}
          </div>
        </div>

        <div className={Styles['flex-media']}>
          <div>
            {activeParameter === 'Days'
              ? translator[language]['Total Rainy Days']
              : activeParameter === 'mm' || activeParameter === selectedUnit
              ? translator[language]['Rainfall till now']
              : ''}
          </div>
          <div
            className={Styles.mt4}
            style={{ color: '#000000', fontWeight: 'bold' }}
          >
            {activeParameter === selectedUnit
              ? villageTotalRainfall?.total_rainfall_tcm !== null
                ? getUnitValue(
                    roundOff(villageTotalRainfall?.total_rainfall_tcm),
                    selectedUnit,
                    selectedVillageTankerValue,
                  )
                : 'NA'
              : activeParameter === 'mm'
              ? villageTotalRainfall?.total_rainfall_mm !== null
                ? roundOff(villageTotalRainfall?.total_rainfall_mm)
                : 'NA'
              : villageTotalRainfall.total_rainy_days !== undefined
              ? villageTotalRainfall?.total_rainy_days
              : 'NA'}{' '}
            {activeParameter === selectedUnit
              ? villageTotalRainfall?.total_rainfall_tcm !== null
                ? translator[language][selectedUnit]
                : ''
              : activeParameter === 'mm'
              ? villageTotalRainfall?.total_rainfall_mm !== null
                ? translator[language]['mm']
                : ''
              : ''}
          </div>
        </div>
      </div>
      <div
        className={Styles.analyticsRainfallButton}
        style={{ background: '', fontSize: '9px' }}
      >
        <button
          className={Styles.analyticsRainfallButtonLeft}
          style={
            activeParameter === selectedUnit
              ? { backgroundColor: '#3883e6', color: '#fff' }
              : { backgroundColor: '#fff', color: '#3883e6' }
          }
          onClick={() => handleActiveParameter(selectedUnit)}
        >
          {translator[language][selectedUnit]}
        </button>

        <button
          className={Styles.analyticsRainfallButtonRight}
          style={
            activeParameter === 'Days'
              ? {
                  backgroundColor: '#3883e6',
                  color: '#fff',
                  borderRadius: 'unset',
                }
              : {
                  backgroundColor: '#fff',
                  color: '#3883e6',
                  borderRadius: 'unset',
                }
          }
          onClick={() => handleActiveParameter('Days')}
        >
          {translator[language]['Days']}
        </button>

        <button
          className={Styles.analyticsRainfallButtonRight}
          style={
            activeParameter === 'mm'
              ? { backgroundColor: '#3883e6', color: '#fff' }
              : { backgroundColor: '#fff', color: '#3883e6' }
          }
          onClick={() => handleActiveParameter('mm')}
        >
          {translator[language]['mm']}
        </button>
      </div>

      <div className={Styles['chart-wrapper']}>
        <ResponsiveContainer width="100%" height="100%" debounce={1}>
          <BarChart
            className={Styles.chartFont}
            margin={{
              left: 0,
              right: 5,
            }}
            width={500}
            height={350}
            data={
              activeParameter === 'mm'
                ? mmDataComparison.length > 0
                  ? mmDataComparison
                  : null
                : activeParameter === selectedUnit
                ? processedData.length > 0
                  ? processedData
                  : // ? tcmDataComparison.length > 0
                    //   ? tcmDataComparison
                    null
                : daysDataComparison.length > 0
                ? daysDataComparison
                : null
            }
            barSize={20}
            barGap={-10}
          >
            <Tooltip
              formatter={(value) =>
                new Intl.NumberFormat('en').format(value) +
                ' ' +
                activeParameter
              }
              content={<CustomTooltip message={message} />}
            />
            <Legend
              verticalAlign="top"
              height={26}
              align="right"
              iconSize={8}
              content={props.renderLegend}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis
              label={{
                value: translator[language][activeParameter],
                angle: -90,
                position: 'insideLeft',
                dx: 0,
                dy: 12,
                scaleToFit: true,
                fontSize: window.innerWidth < 768 ? 10 : 12,
                fill: '#707070',
              }}
            />

            <Bar
              dataKey={
                activeParameter === 'Days'
                  ? 'Average 5 Yr. Rainy Days'
                  : 'Average 5 Yr. Rainfall'
              }
              fill="#12B82D"
            />
            <Bar
              dataKey={
                activeParameter === 'Days'
                  ? 'Prev. year Rainy Days'
                  : 'Prev. year Rainfall'
              }
              fill="#FFCC24"
            />

            <Bar
              dataKey={
                activeParameter === 'Days'
                  ? 'Current Rainy Days'
                  : 'Current Rainfall'
              }
              fill="#3883E6"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default RainfallChart;
