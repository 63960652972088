import React, { useEffect, useState, useContext } from 'react';
import GroundwaterTableLoader from '../../Loader/hash';
import { Get } from '../../../services/apiCall';
import roundOff from '../../../services/round';
import translator from '../../../assets/json/translator.json';
import { useParams } from 'react-router-dom';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';
import Styles from './Table.module.css';

/**
 *
 * @component GroundwaterTable
 * @states
 * isLoading --> If true show animation
 * wells --> An array which stores information of all the wells in the village for that month
 * @description
 * This component represents the well table on the lower right side of the groundwater page
 */

const GroundwaterTable = () => {
  const { clusterId, villageId } = useParams();
  const { month, year } = useContext(TimelineContext);
  const { language, programId } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [wells, setWells] = useState([]);

  useEffect(() => {
    const initialFunction = async () => {
      const params = {
        villageId: villageId,
        year: year,
        month: month + 1,
        programId: programId,
        clusterId: clusterId,
      };
      const response = await Get('/analytics/wells', token, params);
      const payload = response.data;
      if (payload) {
        setWells(payload);
      }
      setIsLoading(false);
    };
    initialFunction();

    return () => {
      setWells([]);
    };
  }, [villageId, month, year, programId, clusterId, token]);

  /**
   *
   * @returns the average water level for all the wells
   */
  const averageWaterLevel = () => {
    let totalWaterLevel = 0;
    if (wells) {
      wells.map((well) => {
        return (totalWaterLevel += well.water_level);
      });
      return wells.length === 0 ? 0 : totalWaterLevel / wells.length;
    }
    return 0;
  };
  /**
   *
   * @param {*} value - water level of a particular well
   * @returns styling as per the water level of the well
   */
  const colorFluctuation = (value) => {
    if (value >= 0) return { color: '#50B432' };
    return { color: '#ED561B' };
  };

  return !isLoading ? (
    <>
      <>
        <div className={Styles.groundwaterContentRightBottomTop}>
          <div className={Styles.groundwaterContentRightBottomTopUp}>
            {translator[language]['Average Water Level as of this month']}:
          </div>
          <div className={Styles.groundwaterContentRightBottomTopDown}>
            {roundOff(averageWaterLevel())} {translator[language]['m']}{' '}
            {translator[language]['bgl']}
          </div>
        </div>
        <div className={Styles.groundwaterContentRightBottomBottom}>
          <div className={Styles.groundwaterContentRightBottomBottomTop}>
            {translator[language]['Observation well details']}
          </div>
          <div className={Styles.groundwaterContentRightBottomBottomTopBottom}>
            <table>
              <tbody>
                <tr>
                  <th style={{ borderRight: '2px solid #86b8f6' }}>
                    {translator[language]['Well']}
                  </th>
                  <th>
                    {translator[language]['Fluctuations']}(
                    {translator[language]['m']})
                  </th>
                  <th>
                    {translator[language]['Depth of well']}(
                    {translator[language]['m']})
                  </th>
                  <th>
                    {translator[language]['Water level']}(
                    {translator[language]['m']} {translator[language]['bgl']})
                  </th>
                </tr>
                {wells &&
                  wells.map((well) => {
                    return (
                      <tr key={well.well_id}>
                        <td style={{ borderRight: '2px solid #86b8f6' }}>
                          {well.Well.name}
                        </td>
                        <td style={colorFluctuation(well.fluctuation)}>
                          {well.fluctuation === null
                            ? '--'
                            : well.fluctuation >= 0
                            ? '+' + roundOff(well.fluctuation)
                            : roundOff(well.fluctuation)}
                        </td>
                        <td>
                          {well.Well.depth === null
                            ? '--'
                            : roundOff(well.Well.depth)}
                        </td>
                        <td>
                          {well.water_level === null
                            ? '--'
                            : roundOff(well.water_level)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </>
  ) : (
    <GroundwaterTableLoader
      style={{
        position: 'relative',
        marginTop: '21%',
        marginLeft: '50%',
      }}
    />
  );
};
export default GroundwaterTable;
