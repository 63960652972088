import React, { useState, useEffect, useContext } from 'react';
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';
// import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';
import { Get } from '../../../services/apiCall';
import 'leaflet/dist/leaflet.css';
// import "../../../css/LandUse.css";
import LanduseMapLoader from '../../Loader/hash';
import translator from '../../../assets/json/translator.json';
import Styles from './Map.module.css';
import { useParams } from 'react-router-dom';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';

// leaflet-simple-map-screenshoter options
// const snapshotOptions = {
//   hideElementsWithSelectors: [
//     '.leaflet-control-container',
//     '.leaflet-dont-include-pane',
//     '#snapshot-button',
//   ],
//   hidden: true,
// };

// const screenshotter = new SimpleMapScreenshoter(snapshotOptions);

/**
 *
 * @class LanduseMap
 * @states
 * isLoading --> If true show animation
 * villageGis --> An array containing coordinates to the boundary of the village
 * bareLand --> An array of arrays which has coordinates of boundaries of various bare lands inside that village
 * vegetation --> An array of arrays which has coordinates of boundaries of various vegetations inside that village
 * denseVegetation --> An array of arrays which has coordinates of boundaries of various dense vegetations inside that village
 * habitation --> An array of arrays which has coordinates of boundaries of various habitations inside that village
 * water --> An array of arrays which has coordinates of boundaries of various water bodies inside that village
 * center --> Stores the coordinate of center of the map
 */

const LanduseMap = () => {
  const { villageId, clusterId } = useParams();
  const { month, year } = useContext(TimelineContext);
  const { language, programId } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [villageGis, setVillageGis] = useState([]);
  const [bareLand, setBareLand] = useState([]);
  const [vegetation, setVegetation] = useState([]);
  const [denseVegetation, setDenseVegetation] = useState([]);
  const [habitation, setHabitation] = useState([]);
  const [water, setWater] = useState([]);
  const [center, setCenter] = useState([20.5937, 78.9629]);
  const [bareLandColor, setBareLandColor] = useState('#f9b29c');
  const [vegetationColor, setVegetationColor] = useState('#d3debc');
  const [denseVegetationColor, setDenseVegetationColor] = useState('#acd19e');
  const [habitationColor, setHabitationColor] = useState('#fcd6a5');
  const [waterColor, setWaterColor] = useState('#85b8f7');
  // const [mapRef,setMapRef] = useState(null);

  useEffect(() => {
    const initialFunction = async () => {
      setIsLoading(true);
      const params1 = {
        villageId: villageId,
        programId: programId,
        clusterId: clusterId,
      };
      // getting the village-gis data
      const response1 = await Get('/geo/village-gis', token, params1);
      const payload1 = response1.data;
      if (
        payload1 &&
        payload1.length > 0 &&
        payload1[0].geom &&
        payload1[0].geom.coordinates &&
        payload1[0].geom.coordinates.length > 0
      ) {
        let villageGisData = payload1[0].geom.coordinates[0][0];
        /**
         *
         * @reason
         * PostGIS is a spatial database extender for PostgreSQL object-relational database. Our gis
         * team renders the gis data and store it in the database but there the format of a coordinate is
         * (longitute, latitude) but on the frontend side the react-leaflet renders the coordinate in the
         * format (latitude, longitute). So, we map the villageGIS array to reverse the coordinates
         */
        villageGisData = villageGisData.map((d) => {
          return [d[1], d[0]];
        });
        // making first coordinate of the village as the center of the map
        const center = [
          parseFloat(payload1[0].latitude),
          parseFloat(payload1[0].longitude),
        ];
        setVillageGis(villageGisData);
        setCenter(center);
      }
      // getting the landuse-gis data i.e, barelands, water bodies, dense vegetations, etc
      const params2 = {
        villageId: villageId,
        year: year,
        month: month + 1,
        programId: programId,
        clusterId: clusterId,
      };
      const response2 = await Get('/geo/landuse', token, params2);
      const payload2 = response2.data;
      const bareLand = [],
        vegetation = [],
        denseVegetation = [],
        habitation = [],
        water = [];
      /**
       *
       * @reason
       * To understand this loop function, one must see the data which comes from the '/geo/landuse'
       * api call. Here the coordinates are nested inside array which is nested inside another array
       * which is nested inside an another array. And we need to reverse those coordinates. So we apply
       * this function.
       */
      if (payload2 && payload2.length > 0) {
        payload2.map((payload) => {
          if (payload.type === 'Bare Land') {
            let co = [];
            if (payload.geom.coordinates.length > 0) {
              co = payload.geom.coordinates.map((f) => {
                return f.map((s) => {
                  return s.map((t) => {
                    return [t[1], t[0]];
                  });
                });
              });
            }
            bareLand.push(co);
          }
          if (payload.type === 'Vegetation') {
            let co = [];
            if (payload.geom.coordinates.length > 0) {
              co = payload.geom.coordinates.map((f) => {
                return f.map((s) => {
                  return s.map((t) => {
                    return [t[1], t[0]];
                  });
                });
              });
            }
            vegetation.push(co);
          }
          if (payload.type === 'Dense Vegetation') {
            let co = [];
            if (payload.geom.coordinates.length > 0) {
              co = payload.geom.coordinates.map((f) => {
                return f.map((s) => {
                  return s.map((t) => {
                    return [t[1], t[0]];
                  });
                });
              });
            }
            denseVegetation.push(co);
          }
          if (payload.type === 'Habitation') {
            let co = [];
            if (payload.geom.coordinates.length > 0) {
              co = payload.geom.coordinates.map((f) => {
                return f.map((s) => {
                  return s.map((t) => {
                    return [t[1], t[0]];
                  });
                });
              });
            }
            habitation.push(co);
          }
          if (payload.type === 'Water') {
            let co = [];
            if (payload.geom.coordinates.length > 0) {
              co = payload.geom.coordinates.map((f) => {
                return f.map((s) => {
                  return s.map((t) => {
                    return [t[1], t[0]];
                  });
                });
              });
            }
            water.push(co);
          }
          return '';
        });
        setBareLand(bareLand);
        setVegetation(vegetation);
        setDenseVegetation(denseVegetation);
        setHabitation(habitation);
        setWater(water);
      }

      setIsLoading(false);
    };
    initialFunction();

    return () => {
      setVillageGis([]);
      setBareLand([]);
      setVegetation([]);
      setDenseVegetation([]);
      setHabitation([]);
      setWater([]);
      setCenter([20.5937, 78.9629]);
      setBareLandColor('#f9b29c');
      setVegetationColor('#d3debc');
      setDenseVegetationColor('#acd19e');
      setHabitationColor('#fcd6a5');
      setWaterColor('#85b8f7');
    };
  }, [month, year, villageId, programId, clusterId, token]);

  /**
   *
   * @description
   * These functions provides an hover effect on the map when ever we hover over the legends
   * of the map
   */
  const bareLandEnter = () => {
    setBareLandColor('#ff6738');
  };
  const bareLandLeave = () => {
    setBareLandColor('#f9b29c');
  };
  const vegetationEnter = () => {
    setVegetationColor('#8bc34a');
  };
  const vegetationLeave = () => {
    setVegetationColor('#d3debc');
  };
  const denseVegetationEnter = () => {
    setDenseVegetationColor('#4caf50');
  };
  const denseVegetationLeave = () => {
    setDenseVegetationColor('#acd19e');
  };
  const habitationEnter = () => {
    setHabitationColor('#ffc107');
  };
  const habitationLeave = () => {
    setHabitationColor('#fcd6a5');
  };
  const waterEnter = () => {
    setWaterColor('#2196f3');
  };
  const waterLeave = () => {
    setWaterColor('#85b8f7');
  };

  // const setMapRefFunction = (e) => {
  //   setMapRef(e)
  // };

  return (
    <div className={Styles.landuseContentMapWrapper}>
      <div className={Styles.landuseContentLeft}>
        {!isLoading ? (
          <>
            <div className={Styles.landuseContentLeftHead}>
              {translator[language]['Land Usage Map']}
            </div>
            <div className={Styles.landuseContentLeftMap}>
              <MapContainer
                center={center}
                zoom={window.innerWidth < 768 ? 13 : 14}
                scrollWheelZoom={false}
                className={Styles.landuseContentLeftMapMap}
                // whenCreated={setMapRefFunction}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Polygon
                  // ref={villageBoundaryRef}
                  color={'#b2b2b2'}
                  positions={villageGis}
                />
                <Polygon
                  pathOptions={{ color: bareLandColor }}
                  positions={bareLand}
                  stroke={false}
                  fillOpacity={0.8}
                />
                <Polygon
                  pathOptions={{ color: vegetationColor }}
                  positions={vegetation}
                  stroke={false}
                  fillOpacity={0.8}
                />
                <Polygon
                  pathOptions={{ color: denseVegetationColor }}
                  positions={denseVegetation}
                  stroke={false}
                  fillOpacity={0.8}
                />
                <Polygon
                  pathOptions={{ color: habitationColor }}
                  positions={habitation}
                  stroke={false}
                  fillOpacity={0.8}
                />
                <Polygon
                  pathOptions={{ color: waterColor }}
                  positions={water}
                  stroke={false}
                  fillOpacity={0.8}
                />
              </MapContainer>
              <div className={Styles.landuseMapLegend}>
                <span
                  onMouseEnter={() => bareLandEnter()}
                  onMouseLeave={() => bareLandLeave()}
                >
                  <span
                    className={Styles.dotArea}
                    style={{ backgroundColor: '#f9b29c' }}
                  />
                  &nbsp; {translator[language]['Bare Land']} &nbsp; &nbsp;
                  &nbsp;
                </span>
                <span
                  onMouseEnter={() => vegetationEnter()}
                  onMouseLeave={() => vegetationLeave()}
                >
                  <span
                    className={Styles.dotArea}
                    style={{ backgroundColor: '#d3debc' }}
                  />
                  &nbsp; {translator[language]['Vegetation']} &nbsp; &nbsp;
                  &nbsp;
                </span>
                <span
                  onMouseEnter={() => denseVegetationEnter()}
                  onMouseLeave={() => denseVegetationLeave()}
                >
                  <span
                    className={Styles.dotArea}
                    style={{ backgroundColor: '#acd19e' }}
                  />
                  &nbsp; {translator[language]['Dense Vegetation']} <br />
                </span>{' '}
                <span
                  onMouseEnter={() => habitationEnter()}
                  onMouseLeave={() => habitationLeave()}
                >
                  <span
                    className={Styles.dotArea}
                    style={{ backgroundColor: '#fcd6a5' }}
                  />
                  &nbsp; {translator[language]['Habitation']}
                  &nbsp; &nbsp; &nbsp;
                </span>
                <span
                  onMouseEnter={() => waterEnter()}
                  onMouseLeave={() => waterLeave()}
                >
                  <span
                    className={Styles.dotArea}
                    style={{ backgroundColor: '#85b8f7' }}
                  />
                  &nbsp; {translator[language]['Water body']}
                </span>
              </div>
            </div>
          </>
        ) : (
          <LanduseMapLoader
            style={{
              position: 'relative',
              marginTop: '45%',
              marginLeft: '50%',
            }}
          />
        )}
      </div>
    </div>
  );
};
export default LanduseMap;
