import React from 'react';
import ProgramStatBox from './StatBox';
import ProgramPieBox from './PieBox';
// import "../../../css/Home.css";
import translator from '../../../assets/json/translator.json';
import HomeStyles from './index.module.css';

// MATERIAL UI
import Grid from '@mui/material/Grid';
import { useContext } from 'react';
import { GeneralContext } from '../../../services/context/context';
import roundOff from '../../../services/round';

/**
 *
 * @component ProgramStats
 * @description
 * This component represents the upper part of the Home page which has
 * those four analytics boxes
 */

const ProgramStats = (props) => {
  const { language, programId } =
    useContext(GeneralContext);
  return (
    <>
      <>
        <div className={HomeStyles.homePageProgramHead}>
          {translator[language]['Program Status']}
        </div>
        <Grid
          container
          justify="center"
          // spacing={6}
          spacing={{ xs: 2, sm: 4, md: 6 }}
          className={HomeStyles['grid-program-section']}
        >
          <Grid item xs={6} sm={4} md={3}>
            <ProgramStatBox
              head={translator[language]['Area being managed'] + ' :'}
              value={props.totalArea}
              isLoading={props.isLoading}
              unit={translator[language]['Ha']}
              programId={programId}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <ProgramStatBox
              head={translator[language]['Water saved'] + ' :'}
              value={roundOff(props.totalWaterSaved)}
              isLoading={props.isLoading}
              unit={translator[language]['TCM']}
              programId={programId}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <ProgramPieBox
              head={translator[language]['Drip implemented'] + ' :'}
              cropArea={props.totalCropArea}
              cropDripArea={props.totalCropDripArea}
              isLoading={props.isLoading}
              language={language}
              programId={programId}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <ProgramStatBox
              head={translator[language]['Water structure monitored'] + ' :'}
              value={props.totalWaterStructure}
              isLoading={props.isLoading}
              programId={programId}
            />
          </Grid>
        </Grid>
      </>
    </>
  );
};
export default ProgramStats;
