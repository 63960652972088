import DemandChart from './DemandChart';
import styles from './index.module.css';
import YieldChart from './YieldChart';

const DataRow = ({ village, value, unit = '', isBold = true }) => {
  const displayValue =
    typeof value === 'boolean' ? (value ? 'YES' : 'NO') : `${value} ${unit}`;

  return (
    <div className={styles.dataRow}>
      <div className={styles.sectionSpacer} />
      <div className={styles.dataRowVillage}>{village}</div>
      <div className={`${styles.dataRowValue} ${isBold && styles.bold}`}>
        {displayValue}
      </div>
    </div>
  );
};

const DataSection = ({ title, data, unit = '' }) => (
  <>
    <div className={styles.sectionHeader}>
      <div className={styles.sectionIcon} />
      <div>{title}</div>
    </div>
    <div className={styles.sectionContent}>
      {data &&
        Object.keys(data).map((village) => (
          <DataRow
            key={village}
            village={village}
            value={data[village]}
            unit={unit}
          />
        ))}
    </div>
  </>
);

const VillageWaterBudget = ({ gis, selectedItems }) => {
  const COLOR_PALLETTE = [
    '#C0A0C3',
    '#D3A9D1',
    '#D8BFD8',
    '#DDA0DD',
    '#DA70D6',
    '#C8A2C8',
    '#9966CC',
    '#7D5BA6',
    '#8B6FAF',
    '#B19CD9',
    '#C9A0DC',
    '#9370DB',
    '#BA55D3',
    '#AB92B3',
    '#B57EDC',
    '#9B59B6',
    '#A569BD',
    '#AF7AC5',
    '#B39EB5',
  ];

  const villageColorMapping = (() => {
    const villageSet = new Set();
    for (const key in gis) {
      if (gis.hasOwnProperty(key)) {
        Object.keys(gis[key]).forEach((village) => {
          villageSet.add(village);
        });
      }
    }

    const mapping = {};
    let idx = 0;
    villageSet.forEach((village) => {
      mapping[village] = COLOR_PALLETTE[idx % COLOR_PALLETTE.length];
      idx++;
    });
    return mapping;
  })();

  const getDemandChartData = () => ({
    total_current_water_demand: Object.keys(
      gis.total_current_water_demand,
    ).reduce((acc, village) => {
      acc[village] = {
        quantity: gis.total_current_water_demand[village],
        colorCode: villageColorMapping[village],
      };
      return acc;
    }, {}),
    total_future_water_demand: Object.keys(
      gis.total_future_water_demand,
    ).reduce((acc, village) => {
      acc[village] = {
        quantity: gis.total_future_water_demand[village],
        colorCode: villageColorMapping[village],
      };
      return acc;
    }, {}),
  });

  const getYieldChartData = () => ({
    current_source_well_yield: Object.keys(
      gis.current_source_well_yield,
    ).reduce((acc, village) => {
      acc[village] = {
        quantity: gis.current_source_well_yield[village],
        colorCode: villageColorMapping[village],
      };
      return acc;
    }, {}),
    required_source_well_yield_for_current_demand: Object.keys(
      gis.required_source_well_yield_for_current_demand,
    ).reduce((acc, village) => {
      acc[village] = {
        quantity: gis.required_source_well_yield_for_current_demand[village],
        colorCode: villageColorMapping[village],
      };
      return acc;
    }, {}),
    required_source_well_yield_for_future_demand: Object.keys(
      gis.required_source_well_yield_for_future_demand,
    ).reduce((acc, village) => {
      acc[village] = {
        quantity: gis.required_source_well_yield_for_future_demand[village],
        colorCode: villageColorMapping[village],
      };
      return acc;
    }, {}),
  });

  return (
    <div>
      <div className={styles.header}>Village Water Budget</div>
      <div className={styles.content}>
        <div className={styles.contentWrapper}>
          <DataSection
            title="Current Source Sustainability"
            data={gis.current_source_availability}
          />
          <DataSection
            title="Future Source Sustainability "
            data={gis.future_source_sustaining}
          />
          <hr className={styles.sectionDivider} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '40%' }}>
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Total Current Water Demand in LPD:
                </div>
                {Object.keys(gis.total_current_water_demand).map((village) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '9px',
                        marginTop: '5%',
                        alignItems: 'center',
                      }}
                      key={village}
                    >
                      <div
                        style={{
                          width: '6.5px',
                          height: '15px',
                          backgroundColor: villageColorMapping[village],
                          marginRight: '6%',
                        }}
                      />
                      <div style={{ marginRight: '3%' }}>{village}</div>
                      {/* <div style={{ fontWeight: 700 }}>
                        {gis.total_current_water_demand[village]}
                        {' LPD'}
                      </div> */}
                    </div>
                  );
                })}
              </div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Total Future Water Demand in LPD:
                </div>
                {Object.keys(gis.total_future_water_demand).map((village) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '9px',
                        marginTop: '5%',
                        alignItems: 'center',
                      }}
                      key={village}
                    >
                      <div
                        style={{
                          width: '6.5px',
                          height: '15px',
                          backgroundColor: villageColorMapping[village],
                          marginRight: '6%',
                        }}
                      />
                      <div style={{ marginRight: '3%' }}>{village}</div>
                      {/* <div style={{ fontWeight: 700 }}>
                        {gis.total_future_water_demand[village]}
                        {' LPD'}
                      </div> */}
                    </div>
                  );
                })}
              </div>
            </div>
            <div style={{ width: '60%', height: '22vh', marginTop: 'auto' }}>
              <DemandChart
                data={getDemandChartData()}
                villageColorMapping={villageColorMapping}
              />
            </div>
          </div>
          <hr className={styles.sectionDivider} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '40%' }}>
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Current Yield of the Source Well in LPM:
                </div>
                {Object.keys(gis.current_source_well_yield).map((village) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '9px',
                        marginTop: '5%',
                        alignItems: 'center',
                      }}
                      key={village}
                    >
                      <div
                        style={{
                          width: '6.5px',
                          height: '15px',
                          backgroundColor: villageColorMapping[village],
                          marginRight: '6%',
                        }}
                      />
                      <div style={{ marginRight: '3%' }}>{village}</div>
                      {/* <div style={{ fontWeight: 700 }}>
                        {gis.current_source_well_yield[village]}
                        {' LPD'}
                      </div> */}
                    </div>
                  );
                })}
              </div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Required Yield of Source to meet Current Demand in LPM:
                </div>
                {Object.keys(
                  gis.required_source_well_yield_for_current_demand,
                ).map((village) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '9px',
                        marginTop: '5%',
                        alignItems: 'center',
                      }}
                      key={village}
                    >
                      <div
                        style={{
                          width: '6.5px',
                          height: '15px',
                          backgroundColor: villageColorMapping[village],
                          marginRight: '6%',
                        }}
                      />
                      <div style={{ marginRight: '3%' }}>{village}</div>
                      {/* <div style={{ fontWeight: 700 }}>
                        {
                          gis.required_source_well_yield_for_current_demand[
                            village
                          ]
                        }
                        {' LPD'}
                      </div> */}
                    </div>
                  );
                })}
              </div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Required Yield of Source to meet Future Demand in LPM:
                </div>
                {Object.keys(
                  gis.required_source_well_yield_for_future_demand,
                ).map((village) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '9px',
                        marginTop: '5%',
                        alignItems: 'center',
                      }}
                      key={village}
                    >
                      <div
                        style={{
                          width: '6.5px',
                          height: '15px',
                          backgroundColor: villageColorMapping[village],
                          marginRight: '6%',
                        }}
                      />
                      <div style={{ marginRight: '3%' }}>{village}</div>
                      {/* <div style={{ fontWeight: 700 }}>
                        {
                          gis.required_source_well_yield_for_future_demand[
                            village
                          ]
                        }
                        {' LPD'}
                      </div> */}
                    </div>
                  );
                })}
              </div>
            </div>
            <div style={{ width: '60%', height: '35vh', marginTop: 'auto' }}>
              <YieldChart
                data={getYieldChartData()}
                villageColorMapping={villageColorMapping}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VillageWaterBudget;
