import React from 'react';
import roundOff from '../../../services/round';
import translator from '../../../assets/json/translator.json';
import { useContext } from 'react';
import { GeneralContext } from '../../../services/context/context';
import Styles from './Table.module.css';
import getUnitValue from '../../../services/getUnitValueFunction';

/**
 *
 * @component SurfacewaterTable
 * @description
 * This component represents the table of the right bottom of the surfacewater page
 */

const SurfacewaterTable = (props) => {
  const { language, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);
  return (
    <>
      <div className={Styles.surfacewaterTableHead}>
        {translator[language]['Structure Details']}
      </div>
      <table className={Styles.surfacewaterTable}>
        <tbody>
          <tr>
            <th style={{ color: '#000', borderRight: '1.5px solid #e8e8e8' }}>
              {translator[language]['Structure']}
            </th>
            <th style={{ backgroundColor: '#5776a2' }}>
              {translator[language]['Farm Pond']}
            </th>
            <th style={{ backgroundColor: '#84b5f7' }}>
              {translator[language]['Village Pond']}
            </th>
            <th style={{ backgroundColor: '#0d68e3' }}>
              {translator[language]['Percolation Tank']}
            </th>
            <th style={{ backgroundColor: '#579df7' }}>
              {translator[language]['Check Dam']}
            </th>
          </tr>
          <tr>
            <td style={{ borderRight: '1.5px solid #e8e8e8' }}>
              {translator[language]['Total']} {translator[language]['Number']}
            </td>
            <td>{props.totalFarmPondsFilled}</td>
            <td>{props.totalVillagePondsFilled}</td>
            <td>{props.totalPercolationTanksFilled}</td>
            <td>{props.totalCheckDamsFilled}</td>
          </tr>
          <tr>
            <td style={{ borderRight: '1.5px solid #e8e8e8' }}>
              {translator[language]['Volume of water present']}
            </td>
            <td>
              {getUnitValue(
                roundOff(
                  parseFloat(props.grossCapacityFarmPond),
                  selectedUnit,
                  selectedVillageTankerValue,
                ),
              )}{' '}
              {translator[language][selectedUnit]}
            </td>
            <td>
              {getUnitValue(
                roundOff(parseFloat(props.grossCapacityVillagePond)),
                selectedUnit,
                selectedVillageTankerValue,
              )}{' '}
              {translator[language][selectedUnit]}
            </td>
            <td>
              {getUnitValue(
                roundOff(parseFloat(props.grossCapacityPercolationTank)),
                selectedUnit,
                selectedVillageTankerValue,
              )}{' '}
              {translator[language][selectedUnit]}
            </td>
            <td>
              {getUnitValue(
                roundOff(parseFloat(props.grossCapacityCheckDam)),
                selectedUnit,
                selectedVillageTankerValue,
              )}{' '}
              {translator[language][selectedUnit]}
            </td>
          </tr>
          <tr>
            <td style={{ borderRight: '1.5px solid #e8e8e8' }}>
              {translator[language]['Total']} {translator[language]['Capacity']}
            </td>
            <td>
              {getUnitValue(
                parseFloat(
                  props.totalStorageFarmPond ? props.totalStorageFarmPond : 0,
                ).toFixed(2),
                selectedUnit,
                selectedVillageTankerValue,
              )}{' '}
              {translator[language][selectedUnit]}
            </td>
            <td>
              {getUnitValue(
                roundOff(parseFloat(props.totalStorageVillagePond)),
                selectedUnit,
                selectedVillageTankerValue,
              )}{' '}
              {translator[language][selectedUnit]}
            </td>
            <td>
              {getUnitValue(
                roundOff(parseFloat(props.totalStoragePercolationTank)),
                selectedUnit,
                selectedVillageTankerValue,
              )}{' '}
              {translator[language][selectedUnit]}
            </td>
            <td>
              {getUnitValue(
                roundOff(parseFloat(props.totalStorageCheckDam)),
                selectedUnit,
                selectedVillageTankerValue,
              )}{' '}
              {translator[language][selectedUnit]}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default SurfacewaterTable;
