import mixpanel from 'mixpanel-browser';

let mixpanelObj;

mixpanel.init('355254e19dd71f94ff489439d069be23', {
  loaded: function (mixpanel) {
    mixpanelObj = mixpanel;
  },
});

export default mixpanelObj;
