import React, { useEffect, useState } from 'react';

import RainfallLoader from '../../Loader/hash';
import { Get } from '../../../services/apiCall';
import translator from '../../../assets/json/translator.json';
import RainfallChart from './metDataCharts/RainfallChart';
import TemperatureChart from './metDataCharts/TemperatureChart';
import HumidityChart from './metDataCharts/HumidityChart';
import months from '../../../shared/constants/months';
import { sortDatesByYearAndMonth } from '../../../services/genericSortMonthYear';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';
import Styles from './Rainfall.module.css';

/**
 *
 * @component Rainfall
 * @states
 * isLoading --> If true show animation
 * mmData --> An array that stores the previous 6 months(max) rainfall data in mm
 * rainyDaysData --> An array that stores the previous 6 months(max) rainfall data in rainy days
 * totalRainfall --> Stores the cummulative rainfall of the village of till that month
 * @description
 * This component represents the cummulative rainfall and a rainfall
 * graph of that village
 */

const Rainfall = () => {
  const { villageId, clusterId } = useParams();
  const { month, year, pageHead } = useContext(TimelineContext);
  const { language, programId, selectedUnit } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [rainyDaysData, setRainyDaysData] = useState([]);
  const [activeParameter, setActiveParameter] = useState(selectedUnit);
  const [metDataApi, setMetDataApi] = useState([]);
  const [villageTotalRainfall, setVillageTotalRainfall] = useState([]);

  const [comparisonData, setComparisonData] = useState({
    mmDataComparison: [],
    tcmDataComparison: [],
    daysDataComparison: [],
  });

  // Mode of graph data to be rendered
  const [currentMode, setCurrentMode] = useState('Rainfall');

  useEffect(() => {
    setActiveParameter(selectedUnit);
  }, [selectedUnit]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const params = {
        year: year,
        month: month + 1,
        villageId: villageId,
        language: language,
        programId: programId,
        clusterId: clusterId,
      };

      try {
        const villageDetailedResponse = await Get(
          '/analytics/village-detailed',
          token,
          params,
        );
        if (villageDetailedResponse && villageDetailedResponse.data) {
          const villageDetailedPayload = villageDetailedResponse.data;
          if (
            villageDetailedPayload &&
            villageDetailedPayload.VillageMonthlyStats1 &&
            villageDetailedPayload.VillageMonthlyStats1.length > 0
          ) {
            setVillageTotalRainfall(
              villageDetailedPayload.VillageTotalRainfall,
            );
          }
        }

        const datapointResponse = await Get(
          '/datapoint/rainfall',
          token,
          params,
        );
        if (datapointResponse) {
          let datapointPayload = datapointResponse.data;
          const sortedData = sortDatesByYearAndMonth(datapointPayload);
          datapointPayload = sortedData;

          let mmData = [],
            rainyDaysData = [];
          let mmDataComparison = [];
          let daysDataComparison = [];
          let tcmDataComparison = [];

          if (datapointPayload && datapointPayload.length > 0) {
            datapointPayload.sort((a, b) => {
              if (a.year === b.year) return a.month - b.month;
              return a.year - b.year;
            });
            datapointPayload.map((idx) => {
              // NEW
              mmDataComparison.push({
                month: `${months[idx.month - 1].substring(0, 3)} ${String(
                  idx.year,
                ).slice(-2)}`,
                'Current Rainfall': idx.current_rainfall_mm,
                'Prev. year Rainfall': idx.previous_year_rainfall_mm,
                'Average 5 Yr. Rainfall': idx.average_rainfall_mm,
              });
              daysDataComparison.push({
                month: `${months[idx.month - 1].substring(0, 3)} ${String(
                  idx.year,
                ).slice(-2)}`,
                'Current Rainy Days': idx.current_rainy_day,
                'Prev. year Rainy Days': idx.previous_year_rainy_day,
                'Average 5 Yr. Rainy Days': idx.average_rainy_day,
              });
              tcmDataComparison.push({
                month: `${months[idx.month - 1].substring(0, 3)} ${String(
                  idx.year,
                ).slice(-2)}`,
                'Current Rainfall': idx.current_rainfall_tcm,
                'Prev. year Rainfall':
                  (idx.previous_year_rainfall_mm *
                    villageDetailedResponse.data?.area) /
                  100,
                // idx.previous_year_rainfall_tcm,
                'Average 5 Yr. Rainfall': idx.average_rainfall_tcm,
              });

              // NEW
              mmData.push({
                name: `${months[idx.month - 1].substring(0, 3)} ${String(
                  idx.year,
                ).slice(-2)}`,
                'Current month rainfall': idx.current_rainfall_mm,
                'Average 5yr rainfall': idx.average_rainfall_mm,
                'Lowest 5yr rainfall': idx.lowest_rainfall_mm,
                'Previous year rainfall': idx.previous_year_rainfall_mm,
              });
              rainyDaysData.push({
                name: `${months[idx.month - 1].substring(0, 3)} ${String(
                  idx.year,
                ).slice(-2)}`,
                'Current month rainfall': idx.current_rainy_day,
                'Average 5yr rainfall': idx.average_rainy_day,
                'Lowest 5yr rainfall': idx.lowest_rainy_day,
                'Previous year rainfall': idx.previous_year_rainy_day,
              });
              return '';
            });
            setComparisonData({
              mmDataComparison: mmDataComparison,
              daysDataComparison: daysDataComparison,
              tcmDataComparison: tcmDataComparison,
            });

            setRainyDaysData(rainyDaysData);
          } else {
            setComparisonData({
              mmDataComparison: [],
              daysDataComparison: [],
              tcmDataComparison: [],
            });

            setRainyDaysData([]);
          }
        }

        // CALLING MET DATA API HERE
        let metDataPayload = {
          year: year,
          month: month + 1,
          villageId: villageId,
          programId: programId,
          clusterId: clusterId,
        };

        const metDataAPi = await Get(
          '/datapoint/met-data',
          token,
          metDataPayload,
        );
        if (metDataAPi) {
          setMetDataApi(metDataAPi);
        }

        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();

    return () => {
      setVillageTotalRainfall([]);

      setMetDataApi([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, pageHead]);

  // param - choosen parameter(mm or rainy days)
  // It changes the parameter for the graph
  const handleActiveParameter = (param) => {
    setActiveParameter(param);
  };

  //  @returns legend for the prop
  //  props - props of the recharts graph

  const renderLegend = (props) => {
    const { payload } = props;
    return payload.map((entry, index) => (
      <span
        className={Styles['legend-font']}
        style={{
          float: 'right',
          marginLeft: '5%',
          color: entry.color,
        }}
        key={index}
      >
        <svg
          className="recharts-surface"
          width="14"
          height="14"
          viewBox="0 0 32 32"
          version="1.1"
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginRight: '4px',
          }}
        >
          <path
            strokeWidth="4"
            fill="none"
            stroke={entry.color}
            d="M0,16h10.666666666666666
            A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
            H32M21.333333333333332,16
            A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
            className="recharts-legend-icon"
          ></path>
        </svg>
        {translator[language][entry.value]}
      </span>
    ));
  };

  // NEW FUNCTIONS
  const activeMonthStyle = (mode) => {
    if (mode === currentMode) {
      return {
        cursor: 'pointer',
        color: '#3883E6',
      };
    } else {
      return {
        cursor: 'pointer',
        color: '#000000',
      };
    }
  };

  const handleCurrentMode = (mode) => {
    setCurrentMode(mode);
  };

  return (
    <div className={Styles.analyticsRainfall}>
      {!isLoading ? (
        <>
          <div
            className={Styles['calendar-month']}
            style={{ marginBottom: '12px' }}
          >
            <div
              id={Styles.month}
              style={{ display: 'flex', fontSize: '13px' }}
            >
              <div
                className={Styles['month-cell']}
                style={activeMonthStyle('Rainfall')}
                onClick={() => handleCurrentMode('Rainfall')}
              >
                {translator[language]['Rainfall']}
              </div>

              <div
                className={Styles['month-cell']}
                style={activeMonthStyle('Temperature')}
                onClick={() => handleCurrentMode('Temperature')}
              >
                {translator[language]['Temperature']}
              </div>
              <div
                className={Styles['month-cell']}
                style={activeMonthStyle('Humidity')}
                onClick={() => handleCurrentMode('Humidity')}
              >
                {translator[language]['Humidity']}
              </div>
            </div>
          </div>

          {currentMode === 'Rainfall' ? (
            <RainfallChart
              activeParameter={activeParameter}
              mmDataComparison={comparisonData.mmDataComparison}
              daysDataComparison={comparisonData.daysDataComparison}
              tcmDataComparison={comparisonData.tcmDataComparison}
              handleActiveParameter={handleActiveParameter}
              renderLegend={renderLegend}
              rainyDaysData={rainyDaysData}
              villageTotalRainfall={villageTotalRainfall}
            />
          ) : currentMode === 'Temperature' ? (
            <TemperatureChart metDataApi={metDataApi} />
          ) : currentMode === 'Humidity' ? (
            <HumidityChart metDataApi={metDataApi} />
          ) : currentMode === 'Evapotranspiration' ? null : null}
        </>
      ) : (
        <RainfallLoader
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        />
      )}
    </div>
  );
};

export default Rainfall;
