export function sortDatesByYearAndMonth(data){
    return data ? data.sort((a, b) => {
        if (a.year < b.year) {
          return -1;
        } else if (a.year > b.year) {
          return 1;
        } else {
          if (a.month < b.month) {
            return -1;
          } else if (a.month > b.month) {
            return 1;
          } else {
            return 0;
          }
        }
      }) : [];
}