import React, { useContext } from 'react';
import Month from './Month';
import Year from './Year';
import YearSession from './YearSession';
import {
  GeneralContext,
  TimelineContext,
} from '../../services/context/context';
import months from '../../shared/constants/months';
import Styles from './Calendar.module.css';
import translator from '../../assets/json/translator.json';

// @description
// This component on the navbar shows component Month
// if the props showSeason is false else it show component Season

const Calendar = () => {
  const { showSeason, baseSelectedPlanMonth } = useContext(TimelineContext);

  const { language } = useContext(GeneralContext);

  return (
    <>
      {!showSeason && !window.location.pathname.startsWith('/advisory') && (
        <Month />
      )}

      {!window.location.pathname.startsWith('/plan') &&
      !window.location.pathname.startsWith('/advisory') ? (
        <Year />
      ) : (
        <>
          <div className={Styles.scheduledPlanningMonth}>
            {' '}
            {baseSelectedPlanMonth !== '' && (
              <>
                {window.innerWidth > 768 && (
                  <>
                    <span style={{ color: '#000' }}>
                      {translator[language]['Scheduled planning month']} -{' '}
                    </span>{' '}
                  </>
                )}

                {translator[language][months[baseSelectedPlanMonth]]}
              </>
            )}
          </div>
          <YearSession />
        </>
      )}
    </>
  );
};

export default Calendar;
