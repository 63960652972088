import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import LanduseChartLoader from '../../Loader/hash';
// import "../../../css/LandUse.css";
import roundOff from '../../../services/round';
import translator from '../../../assets/json/translator.json';
import { useContext } from 'react';
import Styles from './Chart.module.css';
import {
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';
import getUnitValue from '../../../services/getUnitValueFunction';
import useProcessedChartData from '../../../services/hooks/useProcessedChartData';

/**
 *
 * @param {*} active - true if mouse is on the certain part of the bar chart
 * @param {*} payload - data which we want to show on the tooltip
 * @param {*} message - object which contains the language in which we want to show the data
 * @returns a custom div tooltip which certain styling
 */
const CustomTooltip = ({ active, payload, message, selectedUnit }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          boxShadow: '0px 3px 6px #00000029',
          width: '130%',
          padding: '5%',
        }}
      >
        {payload.length > 0 && payload[0].payload.name ? (
          <p
            className="label"
            style={{ margin: '5%', fontWeight: '600' }}
          >{`${payload[0].payload.name}`}</p>
        ) : (
          <></>
        )}
        {payload.length > 0 && payload[0].payload.Area ? (
          <p className="label" style={{ margin: '5%', color: '#2f65b5' }}>{`${
            translator[message.language]['Area']
          } : ${roundOff(payload[0].payload.Area)} ${
            translator[message.language]['Ha']
          }`}</p>
        ) : (
          <p className="label" style={{ margin: '5%', color: '#7d7d7d' }}>{`${
            translator[message.language]['Area']
          } : 0 ${translator[message.language][selectedUnit]}`}</p>
        )}
        {payload.length > 0 && payload[0].payload['Water lost'] ? (
          <p className="label" style={{ margin: '5%', color: '#7d7d7d' }}>{`${
            translator[message.language]['Water lost']
          } : ${roundOff(payload[0].payload['Water lost'])} ${
            translator[message.language][selectedUnit]
          }`}</p>
        ) : (
          <p className="label" style={{ margin: '5%', color: '#7d7d7d' }}>{`${
            translator[message.language]['Water lost']
          } : 0 ${translator[message.language][selectedUnit]}`}</p>
        )}
      </div>
    );
  }

  return null;
};

/**
 *
 * @component LanduseChart
 * @description
 * This component represents the Bar chart on the upper right side of the groundwater page
 */

const LanduseChart = (props) => {
  /**
   *
   * @returns sum of total landuse water consumption
   */

  const landuseConsumption = () => {
    let consumption = 0;
    consumption += props.barrenWaterLostTcm;
    consumption += props.vegetationWaterLostTcm;
    consumption += props.denseVegetationWaterLostTcm;
    consumption += props.habitationWaterLostTcm;
    consumption += props.surfacewaterLostTcm;

    return consumption;
  };
  /**
   *
   * @returns sum of area under landuse
   */
  const landuseArea = () => {
    let area = 0;
    area += parseFloat(props.barrenArea ? props.barrenArea : 0);
    area += parseFloat(props.vegetationArea ? props.vegetationArea : 0);
    area += parseFloat(
      props.denseVegetationArea ? props.denseVegetationArea : 0,
    );
    area += parseFloat(props.habitationArea ? props.habitationArea : 0);
    area += parseFloat(props.surfacewaterArea ? props.surfacewaterArea : 0);
    return area.toFixed(2);
  };
  /**
   *
   * @returns sum of water lost in landuse
   */
  const waterLost = () => {
    let consumption = 0;
    consumption += parseFloat(
      props.barrenWaterLostTcm ? props.barrenWaterLostTcm : 0,
    );
    consumption += parseFloat(
      props.vegetationWaterLostTcm ? props.vegetationWaterLostTcm : 0,
    );
    consumption += parseFloat(
      props.denseVegetationWaterLostTcm ? props.denseVegetationWaterLostTcm : 0,
    );
    consumption += parseFloat(
      props.habitationWaterLostTcm ? props.habitationWaterLostTcm : 0,
    );
    consumption += parseFloat(
      props.surfacewaterLostTcm ? props.surfacewaterLostTcm : 0,
    );
    return consumption;
  };

  const { language, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);
  const { month, year, pageHead } = useContext(TimelineContext);

  let message = {
    language: language,
  };

  // let chartValues = JSON.stringify(['Area', 'Water lost']);
  let chartValues = JSON.stringify(['Water lost']);

  const processedData = useProcessedChartData(
    props.data,
    selectedUnit,
    selectedVillageTankerValue,
    month,
    year,
    pageHead,
    chartValues,
  );

  return (
    <>
      <div className={Styles.landuseContentRightTop}>
        {!props.isLoading ? (
          <>
            <div className={Styles.landuseContentRightTopHead}>
              <div className={Styles.landuseContentRightTopHeadKey}>
                {
                  translator[language][
                    'Evapotranspiration from various land types'
                  ]
                }
                :
              </div>
              <div className={Styles.landuseContentRightTopHeadValue}>
                {getUnitValue(
                  roundOff(landuseConsumption()),
                  selectedUnit,
                  selectedVillageTankerValue,
                )}{' '}
                {translator[language][selectedUnit]}
              </div>
            </div>
            <div className={Styles.landuseContentRightTopChart}>
              <div className={Styles.landuseContentRightTopChartLeft}>
                <div className={Styles.landuseContentRightTopChartLeftTop}>
                  <div>
                    <span className={Styles.dotArea} />{' '}
                    {translator[language]['Total Area Under Village']}
                  </div>
                  <div className={Styles.value}>
                    {roundOff(landuseArea())} {translator[language]['Ha']}
                  </div>
                </div>
                <div className={Styles.landuseContentRightTopChartLeftBottom}>
                  <div>
                    <span className={Styles.dotRequirement} />{' '}
                    {translator[language]['Water evaporated']}
                  </div>
                  <div className={Styles.value}>
                    {getUnitValue(
                      roundOff(waterLost()),
                      selectedUnit,
                      selectedVillageTankerValue,
                    )}{' '}
                    {translator[language][selectedUnit]}
                  </div>
                </div>
              </div>
              <div className={Styles.landuseContentRightTopChartRight}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={80}
                    height={80}
                    data={processedData}
                    margin={{ left: -30 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dx={-25}
                      dy={-60}
                      dataKey="name"
                      angle={-90}
                      tick={{ fontSize: 10 }}
                      interval={0}
                    />
                    <YAxis tick={{ fontSize: 10 }} />
                    <Tooltip
                      wrapperStyle={{ fontSize: '10px' }}
                      content={
                        <CustomTooltip
                          message={message}
                          selectedUnit={selectedUnit}
                        />
                      }
                    />
                    <Bar dataKey="Area" fill="#33644e" barSize={10} />
                    <Bar dataKey="Water lost" fill="#72c8d7" barSize={10} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </>
        ) : (
          <LanduseChartLoader
            style={{
              position: 'relative',
              marginTop: '20%',
              marginLeft: '50%',
            }}
          />
        )}
      </div>
    </>
  );
};
export default LanduseChart;
