import {
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
} from 'recharts';
import Styles from './YieldChart.module.css';

const YieldChart = ({ data, villageColorMapping, entity }) => {
  const currKey = `${entity}_population`;
  const in15YearsKey = `${entity}_population_in_next_15_years`;
  const in30YearsKey = `${entity}_population_in_next_30_years`;

  const current = [...new Set(Object.keys(data[currKey]))];
  const in15Years = [...new Set(Object.keys(data[in15YearsKey]))];
  const in30Years = [...new Set(Object.keys(data[in30YearsKey]))];

  let villageNames = new Set();
  current.forEach((village) => villageNames.add(village));
  in15Years.forEach((village) => villageNames.add(village));
  in30Years.forEach((village) => villageNames.add(village));
  villageNames = [...villageNames];

  const processedData = [
    {
      category: 'Current',
      ...current.reduce((acc, village) => {
        acc[village] = data[currKey][village].quantity;
        return acc;
      }, {}),
    },
    {
      category: '15 years',
      ...in15Years.reduce((acc, village) => {
        acc[village] = data[in15YearsKey][village].quantity;
        return acc;
      }, {}),
    },
    {
      category: '30 years',
      ...in30Years.reduce((acc, village) => {
        acc[village] = data[in30YearsKey][village].quantity;
        return acc;
      }, {}),
    },
  ];

  const CustomTick = ({ x, y, payload }) => {
    const lines = payload.value.split(' ');
    return (
      <g transform={`translate(${x},${y})`}>
        {lines.map((line, index) => (
          <text
            key={index}
            x={0}
            y={index * 10}
            dy={16}
            textAnchor="middle"
            fill="#707070"
            fontSize={10}
          >
            {line}
          </text>
        ))}
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={processedData}
        className={Styles.surfaceAlertStatusGraph}
        barGap={0}
        barCategoryGap={10}
      >
        <XAxis dataKey="category" tick={<CustomTick />} interval={0} />
        <YAxis
          width={1}
          tick={false}
          domain={[0, (dataMax) => dataMax * 1.5]}
        />
        <Tooltip wrapperStyle={{ fontSize: '12px' }} />
        {villageNames.map((village) => (
          <Bar
            key={village}
            dataKey={village}
            name={village}
            fill={villageColorMapping[village]}
            barSize={15}
          >
            <LabelList
              dataKey={village}
              position="top"
              style={{ fontSize: '11px', fill: '#555555', fontWeight: 700 }}
            />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default YieldChart;
