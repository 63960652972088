import React from 'react';
import { PieChart, Pie, Cell, Label, ResponsiveContainer } from 'recharts';

function CustomLabel({ viewBox, value1, value2, color }) {
  const { cx, cy } = viewBox;
  return (
    <text
      x={cx}
      y={cy}
      fill="#3d405c"
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
    >
      <tspan
        alignmentBaseline="middle"
        fontSize={window.innerWidth < 480 ? '1.4vw' : '0.8vw'}
        fontWeight="bold"
      >
        {value1} {'/'} {value2} Filled
      </tspan>
    </text>
  );
}

const SegmentChart = ({ name, color, filled, total }) => {
  return total !== 0 ? (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={85} height={85}>
        <Pie
          data={[
            {
              name: 'Unfilled ' + name,
              value: total - filled,
            },
            {
              name: 'Filled ' + name,
              value: filled,
            },
          ]}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={450}
          innerRadius="75%"
          outerRadius="95%"
          fill="#82ca9d"
        >
          <Cell key={`cell-1`} fill="#fff" />
          <Cell key={`cell-2`} fill={color} />
          <Label
            width={30}
            position="center"
            content={
              <CustomLabel value1={filled} value2={total} color={color} />
            }
          ></Label>
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  ) : (
    ''
  );
};
export default SegmentChart;
