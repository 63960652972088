import { FaArrowsAltV } from 'react-icons/fa';
import styles from './GreyWaterInfrastructure.module.css';
import DrainageImg from '../../../../../assets/images/drainage.png';

const GreyWaterInfrastructure = ({ data }) => {
  return (
    <div>
      <div className={styles.header}>Grey water Infrastructure</div>
      <div className={styles.content}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 10px',
          }}
        >
          <div style={{ width: '45%' }}>
            <div style={{ fontSize: '12px' }}>
              Length of each Drainage line:
            </div>
            <div style={{ fontSize: '22px', marginTop: '4%' }}>
              <FaArrowsAltV color="#75A6B1" />
              &nbsp;<b>{data?.length} m</b>
            </div>
          </div>
          <img src={DrainageImg} alt="Drainage" width="55%" />
        </div>
      </div>
    </div>
  );
};

export default GreyWaterInfrastructure;
