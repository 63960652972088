import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Get } from '../../../services/apiCall';
import roundOff from '../../../services/round';
import translator from '../../../assets/json/translator.json';
import { VscTriangleDown, VscTriangleUp } from 'react-icons/vsc';
import months from '../../../shared/constants/months';
import { sortDatesByYearAndMonth } from '../../../services/genericSortMonthYear';
import Loader from '../../Loader/ring';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';
import Styles from './SoilMoisture.module.css';
import getUnitValue from '../../../services/getUnitValueFunction';
import useProcessedChartData from '../../../services/hooks/useProcessedChartData';
import SoilTree from '../../../assets/images/soil_tree.png';

const CustomTooltip = ({ active, payload, message, selectedUnit }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          boxShadow: '0px 3px 6px #00000029',
          width: '130%',
          padding: '5%',
        }}
      >
        {payload.length > 0 && payload[0].payload.name ? (
          <p
            className="label"
            style={{ margin: '5%', fontWeight: '600' }}
          >{`${payload[0].payload.name}`}</p>
        ) : (
          <></>
        )}
        {payload.length > 0 && payload[0].payload.Value ? (
          <p className="label" style={{ margin: '5%', color: '#5e97f1' }}>{`${
            translator[message.language]['Value']
          } : ${payload[0].payload.Value} ${
            translator[message.language][selectedUnit]
          }`}</p>
        ) : (
          <p className="label" style={{ margin: '5%', color: '#5e97f1' }}>{`${
            translator[message.language]['Value']
          } : 0 ${translator[message.language][selectedUnit]}`}</p>
        )}
      </div>
    );
  }

  return null;
};

const SoilMoisture = (props) => {
  const { villageId, clusterId } = useParams();
  const { month, year, pageHead } = useContext(TimelineContext);
  const {
    language,
    programId,
    selectedUnit,
    selectedVillageTankerValue,
    villageArea,
  } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [previousGWLevel, setPreviousGWLevel] = useState(0);
  const [currentGWLevel, setCurrentGWLevel] = useState(0);

  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    const initialFunction = async () => {
      setIsLoading(true);
      const soil_moisture = await Get(
        `/datapoint/soil-moisture?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&year=${year}&month=${
          month + 1
        }`,
        token,
      );
      if (soil_moisture.status === 'success') {
        let payload = soil_moisture.data;

        const sortedData = sortDatesByYearAndMonth(payload);
        payload = sortedData;

        const finalData = [];
        if (payload && payload.length > 0) {
          payload.sort((a, b) => {
            if (a.year === b.year) return a.month - b.month;
            return a.year - b.year;
          });
          payload.map((ele) => {
            return finalData.push({
              name: `${months[ele.month - 1].substring(0, 3)} ${String(
                ele.year,
              ).slice(-2)}`,
              Value: roundOff(ele.total_soil_moisture),
            });
          });
        }

        // Setting the currentSwLevel and previousSwLevel
        if (payload.length >= 2) {
          setCurrentGWLevel(
            payload[payload.length - 1].total_soil_moisture
              ? payload[payload.length - 1].total_soil_moisture
              : 0,
          );
          setPreviousGWLevel(
            payload[payload.length - 2].total_soil_moisture
              ? payload[payload.length - 2].total_soil_moisture
              : 0,
          );
        } else {
          setCurrentGWLevel(
            payload[payload.length - 1].total_soil_moisture
              ? payload[payload.length - 1].total_soil_moisture
              : 0,
          );
        }

        const params = {
          villageId: villageId,
          month: month + 1,
          year: year,
          language: language,
          programId: programId,
          clusterId: clusterId,
        };
        const response = await Get(
          '/analytics/village-detailed',
          token,
          params,
        );

        if (response && response.data) {
          const getTotalWaterAvailableFt =
            soil_moisture?.data?.filter((values) => {
              return values.soil_type === response.data.VillageGis[0].soil_type;
            })?.[0]?.total_water_available_ft || [];

          // const max = waterholdingcapacity * area / 10
          // waterholdingcapacity = totalwatervailableft * soil depth * 3.28 * 25.4

          const water_holding_capacity =
            getTotalWaterAvailableFt *
            response.data.VillageGis[0].soil_depth *
            3.28 *
            25.4;
          const max = (water_holding_capacity * villageArea) / 100;
          const finalMaxValue = Number(max)?.toFixed(2);

          setMaxValue(Number(finalMaxValue));

          setData(finalData);
        }
      }

      setIsLoading(false);
    };

    initialFunction();

    return () => {
      setData([]);
      setCurrentGWLevel(0);
      setPreviousGWLevel(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, pageHead, programId, clusterId, villageId, token]);

  // Adding percentage here
  const colorGWPrecentage = () => {
    if (previousGWLevel === 0) return { color: '#b1b0b7' };
    let val = ((currentGWLevel - previousGWLevel) / previousGWLevel) * 100;
    if (val > 0) return { color: '#12cb30' };
    if (val < 0) return { color: '#ec561b' };
    return { color: '#b1b0b7' };
  };

  const calculateGWPercentage = () => {
    if (previousGWLevel === 0) return 0.0;
    return ((currentGWLevel - previousGWLevel) / previousGWLevel) * 100;
  };

  let message = {
    language: language,
  };

  let chartValues = JSON.stringify(['Value']);

  const processedData = useProcessedChartData(
    data,
    selectedUnit,
    selectedVillageTankerValue,
    month,
    year,
    villageId,
    chartValues,
  );

  const CustomLabel = ({ x, y, value }) => (
    <g transform={`translate(${x - 5},${0})`} style={{ zIndex: 999 }}>
      {/* You can customize the image URL, width, and height here */}
      <image href={SoilTree} width={40} height={60} />
      {/* You can also include a text label if needed */}
      {/* <text x={30} y={10} fontSize={12} fill="#000">
        {value}
      </text> */}
    </g>
  );

  const getColorBasedOnPercentage = (value, maxValue) => {
    // const percentage = (value / maxValue) * 100;

    const finalMaxValue = getUnitValue(
      maxValue,
      selectedUnit,
      selectedVillageTankerValue,
    );
    const percentage = (value / finalMaxValue) * 100;

    if (percentage <= 30) {
      return 'url(#redGradient)';
    } else if (percentage <= 60) {
      return 'url(#orangeGradient)';
    } else {
      return 'url(#greenGradient)';
    }
  };

  return (
    <div className={`${Styles.soilMoistureContent} soilMoistureContent`}>
      {/* HEADING  */}
      <div
        className={Styles.soilMoistureAlertContentKey}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            background: '#000000',
            height: '8px',
            width: '8px',
            borderRadius: '100%',
            marginRight: '6px',
            position: 'absolute',
          }}
        ></div>

        <div className={Styles['main-heading-runoff']}>
          {translator[language]['Total Soil Moisture']}
        </div>
      </div>
      <div className={Styles['main-content-runoff']}>
        {props.total_soil_moisture !== null
          ? getUnitValue(
              roundOff(props.total_soil_moisture),
              selectedUnit,
              selectedVillageTankerValue,
            )
          : ''}{' '}
        {translator[language][selectedUnit]}
        {/* ADDING SPAN FOR PERCENTAGE HERE  */}
        <span
          // className="groundAlertStatusValue"
          style={colorGWPrecentage()}
        >
          {calculateGWPercentage() >= 0 ? (
            calculateGWPercentage() > 0 ? (
              <VscTriangleUp
                style={{
                  marginRight: '4px',
                  fontSize: '0.8rem',
                  marginLeft: '8px',
                }}
              />
            ) : (
              <>
                <VscTriangleDown
                  style={{
                    marginRight: '4px',
                    marginLeft: '8px',
                    fontSize: '0.8rem',
                  }}
                />
              </>
            )
          ) : (
            <VscTriangleDown
              style={{
                marginRight: '4px',
                marginLeft: '8px',
                fontSize: '0.8rem',
              }}
            />
          )}
          <span style={{ fontSize: '12px' }}>
            {roundOff(Math.abs(calculateGWPercentage()))} %
          </span>
        </span>
        {/* ADDING SPAN FOR PERCENTAGE HERE  */}
      </div>

      {/* HEADING  */}

      <div
        className={Styles.soilMoistureFlexContentContainer}
        style={{ display: 'flex' }}
      >
        <div className={Styles.soilMoistureFlexContentLeft}>
          <div className={Styles['reduced-increased']}>
            {translator[language]['Your Soil Moisture level has']}{' '}
            <span style={{ fontWeight: 'bold' }}>
              {calculateGWPercentage() > 0
                ? translator[language]['Increased']
                : translator[language]['Reduced']}
            </span>{' '}
            {translator[language]['compared to Previous month.']}
          </div>

          <div className={Styles['heading-runoff']}>
            {translator[language]['Type of Soil']}:
          </div>
          <div className={Styles['content-runoff']}>
            {props.soil_type !== null && props.soil_type !== undefined
              ? props.soil_type
              : 'NA'}
          </div>

          <div
            className={Styles.soilMoistureFullReport}
            style={{ marginLeft: '15px', marginTop: '20px' }}
          >
            <button to={''} className={Styles.groundwaterAlertButton}>
              {translator[language]['Full report']}
              {' >'}
            </button>
          </div>
        </div>

        <div className={Styles.soilMoistureFlexContentRight}>
          <div className={Styles.soilMoistureGraphHeight}>
            {!isLoading ? (
              <ResponsiveContainer
                width={window.innerWidth < 768 ? '98%' : '100%'}
                height="100%"
              >
                <BarChart
                  barSize={30}
                  className={Styles.soilMoistureAlertStatusGraph}
                  data={processedData}
                  margin={{
                    top: 20,
                  }}
                >
                  <defs>
                    <linearGradient
                      id="redGradient"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: '#992626', stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{
                          stopColor: 'rgba(237, 163, 163, 0.48)',
                          stopOpacity: 0,
                        }}
                      />
                    </linearGradient>
                    <linearGradient
                      id="orangeGradient"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: '#B5911A', stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{
                          stopColor: 'rgba(255, 232, 154, 0.48)',
                          stopOpacity: 0,
                        }}
                      />
                    </linearGradient>
                    <linearGradient
                      id="greenGradient"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: '#057137', stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{
                          stopColor: 'rgba(141, 211, 174, 0.48)',
                          stopOpacity: 0,
                        }}
                      />
                    </linearGradient>
                  </defs>

                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis
                    dataKey="name"
                    tick={{ fontSize: 9, fill: '#707070' }}
                  />
                  <YAxis
                    dataKey="Value"
                    // domain={[
                    //   () => 0,
                    //   (dataMax) => {
                    //     return (dataMax * 1.5).toFixed(2);
                    //   },
                    // ]}
                    domain={[
                      0,
                      getUnitValue(
                        maxValue,
                        selectedUnit,
                        selectedVillageTankerValue,
                      ),
                    ]}
                    tick={{ fontSize: 9, fill: '#707070' }}
                    label={{
                      value: translator[language][selectedUnit],
                      angle: -90,
                      position: 'insideLeft',
                      dx: 5,
                      dy: 15,
                      scaleToFit: true,
                      fontSize: 10,
                      fill: '#707070',
                    }}
                  />
                  <Tooltip
                    wrapperStyle={{ fontSize: '12px' }}
                    content={
                      <CustomTooltip
                        message={message}
                        selectedUnit={selectedUnit}
                      />
                    }
                  />

                  <Bar
                    dataKey="Value"
                    background={{ fill: '#EFDFC0' }}
                    // radius={[15, 15, 0, 0]}
                    label={<CustomLabel />}
                  >
                    {processedData?.map((d, index) => (
                      <Cell
                        key={d.name}
                        fill={getColorBasedOnPercentage(d.Value, maxValue)}
                      />
                    ))}
                  </Bar>

                  {/* <Bar dataKey="Value" fill="#8884d8" label={<CustomLabel />}>
                    {data.map((d) => {
                      if (
                        `${months[month].substring(0, 3)} ${String(year).slice(
                          -2,
                        )}` === d.name
                      )
                        return <Cell key={d.name} fill={'#3883E6'} />;
                      return <Cell key={d.name} fill={'#C2C1C1'} />;
                    })}
                  </Bar> */}
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <Loader
                style={{
                  position: 'relative',
                  top: '50%',
                  left: '50%',
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={Styles.soilMoistureFullReportMobile}
        style={{ marginTop: '20px' }}
      >
        <button
          to={''}
          className={`${Styles.groundwaterAlertButton} ${Styles.groundAlertMobileButtonS}`}
        >
          {translator[language]['Full report']}
          {' >'}
        </button>
      </div>
    </div>
  );
};
export default SoilMoisture;
