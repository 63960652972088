const getNonDuplicateArray = (currentArray) => {
  return currentArray.filter((elem, index) => {
    return (
      currentArray.findIndex((obj) => {
        return obj.id === elem.id;
      }) === index
    );
  });
};

export default getNonDuplicateArray;
