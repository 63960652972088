import { POINT_TYPES } from '../../../../../shared/utils/tree';
import WaterStructure from './WaterStructure';

const SurfacewaterSource = ({ data, type }) => {
  return type === POINT_TYPES.WATER_STRUCTURE ? (
    <WaterStructure data={data} />
  ) : (
    <></>
  );
};

export default SurfacewaterSource;
