import { ESR_ID, GWI_ID, PDN_ID } from '../../../../../shared/utils/tree';
import styles from './index.module.css';

const DataRow = ({ village, value, unit = '', isBold = true }) => {
  const displayValue =
    typeof value === 'boolean' ? (value ? 'YES' : 'NO') : `${value} ${unit}`;

  return (
    <div className={styles.dataRow}>
      <div className={styles.sectionSpacer} />
      <div className={styles.dataRowVillage}>{village}</div>
      <div className={`${styles.dataRowValue} ${isBold && styles.bold}`}>
        {displayValue}
      </div>
    </div>
  );
};

const DataSection = ({ title, data, unit = '', isLastSection = false }) => (
  <>
    <div className={styles.sectionHeader}>
      <div className={styles.sectionIcon} />
      <div>{title}</div>
    </div>
    <div className={styles.sectionContent}>
      {data &&
        Object.keys(data).map((village) => (
          <DataRow
            key={village}
            village={village}
            value={data[village]}
            unit={unit}
          />
        ))}
    </div>
    {!isLastSection && <hr className={styles.sectionDivider} />}
  </>
);

const WaterDistributionNetwork = ({ gis, selectedItems }) => {
  const isESRActive = selectedItems[ESR_ID];
  const isPDNActive = selectedItems[PDN_ID];
  const isGWIActive = selectedItems[GWI_ID];

  return (
    <div>
      <div className={styles.header}>Water Distribution Network</div>
      <div className={styles.content}>
        {isESRActive && (
          <div className={styles.contentWrapper}>
            <div className={styles.title}>Elevated Storage Reservoir</div>
            <DataSection
              title="Number of Households being provided"
              data={gis?.elevated_surface_reservoir.households}
            />
            <DataSection
              title="Presence of leakages"
              data={gis?.elevated_surface_reservoir.any_leakages}
            />
            <DataSection
              title="Flow meter installed"
              data={gis?.elevated_surface_reservoir.is_flow_meter_installed}
            />
            <DataSection
              title="Number of flow meters installed"
              data={gis?.elevated_surface_reservoir.installed_flow_meters}
              isLastSection={true}
            />
          </div>
        )}
        {isESRActive && (isPDNActive || isGWIActive) && (
          <div className={styles.verticalSpacer} />
        )}
        {isPDNActive && (
          <div className={styles.contentWrapper}>
            <div className={styles.title}>
              Piped Distribution Network to Households
            </div>
            <DataSection
              title="Number of Households metered"
              data={gis?.pipe_network.households}
              isLastSection={true}
            />
          </div>
        )}
        {(isESRActive || isPDNActive) &&
          isGWIActive &&
          !(isESRActive && (isPDNActive || isGWIActive) && !isPDNActive) && (
            <div className={styles.verticalSpacer} />
          )}
        {isGWIActive && (
          <div className={styles.contentWrapper}>
            <div className={styles.title}>Grey Water Infrastructure</div>
            <DataSection
              title="Location of drainage"
              data={gis?.drainage_network.location}
              isLastSection={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WaterDistributionNetwork;
