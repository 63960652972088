import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import translator from '../../assets/json/translator.json';
import { GeneralContext } from '../../services/context/context';
import Styles from './Program.module.css';

const DialogSelectProgram = (props) => {
  const [open, setOpen] = useState(false);

  const { handleProgramIdChange, programId, language } =
    useContext(GeneralContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleProgramClick = (programId, programName) => {
    handleProgramIdChange(programId, programName);
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') setOpen(false);
  };

  return (
    props.programs &&
    props.programs.length > 0 && (
      <div className={Styles.parent}>
        <Button
          onClick={handleClickOpen}
          style={{ color: '#a9aaaa' }}
          className={`${Styles.btn} ${Styles.programTitle} ${Styles['btn-border-1']}`}
        >
          {
            props.programs.find(
              (program) => program.value === Number(programId),
            )?.label
          }
        </Button>
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
          <DialogTitle>
            <div style={{ fontSize: window.innerWidth < 768 && '15px' }}>
              {translator[language]['Choose your program']}
            </div>
          </DialogTitle>
          <DialogContent>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <FormControl
                sx={{ m: 2, minWidth: window.innerWidth < 480 ? 200 : 300 }}
              >
                <InputLabel id={Styles['demo-dialog-select-label']}>
                  <div>{translator[language]['Program']}</div>
                </InputLabel>

                <Select
                  labelId="demo-dialog-select-label"
                  id={Styles['demo-dialog-select']}
                  value={Number(programId)}
                  input={
                    <OutlinedInput label={translator[language]['Program']} />
                  }
                >
                  {props.programs.map((program) => {
                    return (
                      <MenuItem
                        value={program.value}
                        key={program.value}
                        onClick={() =>
                          handleProgramClick(program.value, program.label)
                        }
                      >
                        <Link
                          to={{
                            pathname: `/programId/${program.value}`,
                          }}
                          style={{
                            textDecoration: 'none',
                            color: '#000',
                            fontSize: window.innerWidth < 768 && '14px',
                          }}
                        >
                          {program.label}
                        </Link>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    )
  );
};
export default DialogSelectProgram;
