import React, { useContext, useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { Get } from '../../../services/apiCall';
import roundOff from '../../../services/round';
import translator from '../../../assets/json/translator.json';
import months from '../../../shared/constants/months';
import { sortDatesByYearAndMonth } from '../../../services/genericSortMonthYear';
import Loader from '../../Loader/ring';
import { useParams } from 'react-router-dom';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';
import Styles from './RunoffGenerated.module.css';
import getUnitValue from '../../../services/getUnitValueFunction';
import useProcessedChartData from '../../../services/hooks/useProcessedChartData';

const CustomTooltip = ({ active, payload, message }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          boxShadow: '0px 3px 6px #00000029',
          width: '115%',
          padding: '5%',
          fontSize: '11px',
        }}
      >
        {payload.length > 0 && payload[0].payload['Runoff'] ? (
          <p className="label" style={{ margin: '5%', color: '' }}>
            {`${translator[message.language]['Runoff']} : ${roundOff(
              payload[0].payload['Runoff'],
            )} ${message.unit}`}
          </p>
        ) : (
          <p className="label" style={{ margin: '5%', color: '' }}>{`${
            translator[message.language]['Runoff']
          } : 0 ${translator[message.language][message.unit]}`}</p>
        )}

        {payload.length > 0 && payload[0].payload['Rainfall'] ? (
          <p className="label" style={{ margin: '5%', color: '#29E1E8' }}>{`${
            translator[message.language]['Rainfall']
          } : ${roundOff(payload[0].payload['Rainfall'])} ${
            translator[message.language][message.unit]
          }`}</p>
        ) : (
          <p className="label" style={{ margin: '5%', color: '#29E1E8' }}>{`${
            translator[message.language]['Rainfall']
          } : 0 ${translator[message.language][message.unit]}`}</p>
        )}
      </div>
    );
  }

  return null;
};

const RunoffGenerated = (props) => {
  const { villageId, clusterId } = useParams();
  const { month, year, pageHead } = useContext(TimelineContext);
  const { language, programId, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    const initialFunction = async () => {
      setIsLoading(true);
      const params = {
        year: year,
        month: month + 1,
        villageId: villageId,
        language: language,
        programId: programId,
        clusterId: clusterId,
      };

      const runoff = await Get(`/datapoint/runoff`, token, params);
      const rainfall = await Get('/datapoint/rainfall', token, params);

      let data = [];

      if (runoff.status === 'success') {
        runoff.data.map((vals) =>
          data.push({
            year: vals.year,
            month: vals.month,
            Runoff: vals.runoff_tcm,
          }),
        );
      }

      let newData = [];
      if (rainfall.status === 'success') {
        // Extract the current_rainfall_tcm values into an array
        const rainfallValues = rainfall?.data.map(
          (item) => item.current_rainfall_tcm,
        );

        // Filter out undefined values
        let filteredArray = rainfallValues?.filter(
          (value) => typeof value === 'number',
        );

        // Find the maximum value
        let maxRainfall = Math.max(...filteredArray);

        // Find the maximum value using Math.max
        // const maxRainfall = Math.max(...rainfallValues);

        // const curr =
        //   rainfall?.data[rainfall.data.length - 1]?.current_rainfall_tcm;
        const finalMax = Number(maxRainfall.toFixed(2));

        setMaxValue(finalMax);

        rainfall.data.map((vals) =>
          newData.push({
            year: vals.year,
            month: vals.month,
            Rainfall: vals.current_rainfall_tcm,
          }),
        );
      }

      // Final state setup
      let mergeRunoffRainfall = data.map((item) => {
        let matchingItem = newData.find((elem) => elem.month === item.month);
        return {
          ...item,
          ...matchingItem,
          name: `${months[item.month - 1].substring(0, 3)} ${String(
            item.year,
          ).slice(-2)}`,
        };
      });

      let sortedData = sortDatesByYearAndMonth(mergeRunoffRainfall);

      setData(sortedData);
      setIsLoading(false);
    };

    initialFunction();

    return () => {
      setData([]);
      setMaxValue(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, pageHead]);

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <div
        style={{
          backgroundColor: '',
          marginTop: '-20px',
        }}
      >
        {payload
          .slice(0)
          .reverse()
          .map((entry, index) => (
            <span
              className="legend-font"
              style={{
                float: 'right',
                marginLeft: '5%',
                color: entry.value === 'Runoff' ? 'black' : '#29E1E8',
                fontSize: '9.5px',
              }}
              key={index}
            >
              <span
                style={{
                  display: 'inline-block',
                  alignItems: 'center',
                  background: entry.value === 'Runoff' ? 'black' : '#29E1E8',
                  width: '9px',
                  height: '9px',
                  borderRadius: '100%',
                  marginRight: '6px',
                  marginTop: '4px',
                }}
              />

              {translator[language][entry.value]}
            </span>
          ))}
      </div>
    );
  };

  let message = {
    language: language,
    unit: selectedUnit,
  };

  let chartValues = JSON.stringify(['Runoff', 'Rainfall']);

  const processedData = useProcessedChartData(
    data,
    selectedUnit,
    selectedVillageTankerValue,
    month,
    year,
    villageId,
    chartValues,
  );

  const getColorBasedOnPercentage = (value, maxValue) => {
    const finalMaxValue = getUnitValue(
      maxValue,
      selectedUnit,
      selectedVillageTankerValue,
    );
    const percentage = (value / finalMaxValue) * 90;

    if (percentage <= 40) {
      return 'url(#greenGradient)';
    } else if (percentage <= 70) {
      return 'url(#orangeGradient)';
    } else {
      return 'url(#redGradient)';
    }
  };

  return (
    <div className={`${Styles.runoffGenerated} runoffGenerated`}>
      {/* HEADING  */}
      <div
        className={Styles.runoffGenHeadingTop}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            background: '#000000',
            height: '8px',
            width: '8px',
            borderRadius: '100%',
            marginRight: '6px',
            position: 'absolute',
          }}
        ></div>

        <div className={Styles['main-heading-runoff']}>
          {translator[language]['Runoff Generated']}{' '}
          <span className={Styles['current-month']}>
            ({translator[language]['Current Month']}):
          </span>
        </div>
      </div>
      <div className={Styles['main-content-runoff']}>
        {getUnitValue(
          roundOff(props.runoffTcm),
          selectedUnit,
          selectedVillageTankerValue,
        )}{' '}
        {translator[language][selectedUnit]}
      </div>

      {/* HEADING  */}

      <div
        className={Styles.runoffGeneratedFlexParent}
        style={{ display: 'flex' }}
      >
        <div className={Styles.runoffGeneratedFlexLeftSide}>
          <div>
            <div className={Styles['heading-runoff']}>
              {translator[language]['Runoff till now']}:
            </div>
            <div className={Styles['content-runoff']}>
              {props.runoffTillNow !== null && props.runoffTillNow !== ''
                ? getUnitValue(
                    roundOff(props.runoffTillNow),
                    selectedUnit,
                    selectedVillageTankerValue,
                  ) +
                  ' ' +
                  translator[language][selectedUnit]
                : 'NA'}{' '}
            </div>
          </div>

          <div>
            <div className={Styles['heading-runoff']}>
              {translator[language]['% Runoff arrested']}:
            </div>
            <div className={Styles['content-runoff']}>
              <span>
                {props.runoffTillNow && props.runoffTillNow !== 0
                  ? roundOff(
                      (props.totalSurfaceWater / props.runoffTillNow) * 100,
                    )
                  : 0.0}
                {' %'}
              </span>
            </div>
          </div>

          <div
            className={Styles.webButtonRunoffGenerated}
            style={{ marginLeft: '15px', marginTop: '20px' }}
          >
            <button to={''} className={Styles.groundwaterAlertButton}>
              {translator[language]['Full report']}
              {' >'}
            </button>
          </div>
        </div>

        <div className={Styles.runoffGeneratedFlexRightSide}>
          <div className={Styles.runoffGeneratedGraphHeight}>
            {!isLoading ? (
              <ResponsiveContainer
                width={window.innerWidth < 768 ? '94%' : '100%'}
                height="100%"
              >
                <BarChart
                  margin={{
                    left: -5,
                    right: 5,
                  }}
                  width={500}
                  height={350}
                  data={processedData}
                  barSize={20}
                  barGap={-10}
                >
                  <CartesianGrid strokeDasharray="3 3" />

                  <Legend
                    verticalAlign="top"
                    height={16}
                    align="right"
                    iconSize={8}
                    content={renderLegend}
                  />

                  <defs>
                    <linearGradient
                      id="redGradient"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: '#992626', stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{
                          stopColor: 'rgba(237, 163, 163, 0.48)',
                          stopOpacity: 0,
                        }}
                      />
                    </linearGradient>
                    <linearGradient
                      id="orangeGradient"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: '#B5911A', stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{
                          stopColor: 'rgba(255, 232, 154, 0.48)',
                          stopOpacity: 0,
                        }}
                      />
                    </linearGradient>
                    <linearGradient
                      id="greenGradient"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: '#057137', stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{
                          stopColor: 'rgba(141, 211, 174, 0.48)',
                          stopOpacity: 0,
                        }}
                      />
                    </linearGradient>
                  </defs>

                  <XAxis dataKey="name" fontSize={10} />
                  <YAxis
                    dataKey="Runoff"
                    domain={[
                      0,
                      getUnitValue(
                        maxValue,
                        selectedUnit,
                        selectedVillageTankerValue,
                      ),
                    ]}
                    fontSize={10}
                    label={{
                      value: translator[language][selectedUnit],
                      angle: -90,
                      position: 'insideLeft',
                      dx: window.innerWidth < 768 ? 20 : 5,
                      dy: window.innerWidth < 768 ? 15 : 12,
                      scaleToFit: true,
                      fontSize: 10,
                      fill: '#707070',
                    }}
                  />
                  {/* <Legend /> */}
                  <Tooltip
                    wrapperStyle={{ fontSize: '12px' }}
                    content={<CustomTooltip message={message} />}
                  />

                  <Bar dataKey="Runoff" fill="#7A797E">
                    {processedData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={getColorBasedOnPercentage(
                          entry['Runoff'],
                          entry['Rainfall'],
                          // maxValue,
                        )}
                        // fill={index === data.length - 1 ? '#3883E6' : '#7A797E'}
                      />
                    ))}
                  </Bar>
                  <Bar dataKey="Rainfall" fill="#AEAEAE">
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        // fill={index === data.length - 1 ? '#29E1E8' : '#AEAEAE'}
                        fill={'#29E1E8'}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <Loader
                style={{
                  position: 'relative',
                  top: '50%',
                  left: '50%',
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={Styles.mobileButtonRunoffGenerated}
        style={{ marginLeft: '15px', marginTop: '20px' }}
      >
        <button to={''} className={Styles.groundwaterAlertButton}>
          {translator[language]['Full report']}
          {' >'}
        </button>
      </div>
    </div>
  );
};
export default RunoffGenerated;
