import React, { useEffect, useState } from 'react';
import translator from '../assets/json/translator.json';
import GenericPlanNav from '../components/Plan/GenericPlanNav';
import { Get } from '../services/apiCall';
import LoginLoader from '../components/Loader/climb';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext, GeneralContext } from '../services/context/context';
import Styles from './SavedScenarioIrrigation.module.css';
import getUnitValue from '../services/getUnitValueFunction';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import AdvisoryIrrigation from '../components/Plan/AdvisoryIrrigation';

const SavedScenarioIrrigation = (props) => {
  const { villageId, clusterId } = useParams();

  const { programId, language, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [previewAdvisory, setPreviewAdvisory] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [scenarioData, setScenarioData] = useState([]);
  const [ownerData, setOwnerData] = useState([]);

  const [ownerName, setOwnerName] = useState('');

  const getScenarioById = async () => {
    const getData = await Get(
      `/simulation/scenario?id=${props.scenarioId}`,
      token,
    );
    if (getData.status === 'success') {
      setScenarioData(getData.data.data);
      setOwnerData(getData.data);
      setIsLoading(false);
    }

    const emailId = getData.data.creator.id;
    // const role = getData.data.creator.role;

    const role =
      getData.data.creator.role === 'cluster_coordinator'
        ? 'clusterCoordinator'
        : getData.data.creator.role === 'admin'
        ? 'admin'
        : getData.data.creator.role === 'field_facilitator'
        ? 'fieldFacilitator'
        : null;

    const getNameOfScenarioUser = await Get(
      `/info/user/search?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&role=${role}&term=${emailId}`,
      token,
    );

    if (getNameOfScenarioUser.status === 'success') {
      setOwnerName(getNameOfScenarioUser.data[0]?.name);
    }
  };

  useEffect(() => {
    getScenarioById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
          }}
        >
          <LoginLoader />
        </div>
      ) : (
        <>
          {previewAdvisory ? (
            <AdvisoryIrrigation
              isFromSavedScenario={true}
              setCurrentCrop={props.setCurrentCrop}
              setPreviewAdvisory={setPreviewAdvisory}
              scenarioData={scenarioData}
              scenarioId={props.scenarioId}
              season={props.season}
              globalPublishedScenarioId={props.globalPublishedScenarioId}
              checkPlanIsOpen={props.checkPlanIsOpen}
              ownerData={ownerData}
            />
          ) : (
            <div className={Styles['savedScenarioWrapper']}>
              <GenericPlanNav
                setCurrentCrop={props.setCurrentCrop}
                setPreviewAdvisory={setPreviewAdvisory}
                isFromIrrigation={true}
                setRenderScenarioSummary={props.setRenderScenarioSummary}
                ownerData={ownerData}
                scenarioId={props.scenarioId}
                callBaseConditionForEdit={props.callBaseConditionForEdit}
                season={props.season}
                isUpdateModeActiveScenarioType={
                  props.isUpdateModeActiveScenarioType
                }
                ownerName={ownerName}
                checkPlanIsOpen={props.checkPlanIsOpen}
                scenarioData={scenarioData}
                // FILTER ARRAYS
                scenarios={props.scenarios}
                setScenarios={props.setScenarios}
                trackFilteredValues={props.trackFilteredValues}
                setTrackFilteredValues={props.setTrackFilteredValues}
                referAnotherArray={props.referAnotherArray}
                setReferAnotherArray={props.setReferAnotherArray}
                currentCountOfOwnPlansSaved={props.currentCountOfOwnPlansSaved}
                setCurrentCountOfOwnPlansSaved={
                  props.setCurrentCountOfOwnPlansSaved
                }
                duplicateArray={props.duplicateArray}
                setDuplicateArray={props.setDuplicateArray}
              />

              <>
                <div className={Styles['grid-container']}>
                  <div className={`${Styles['item']} ${Styles['item1']}`}>
                    <div
                      className={Styles['item-one-div']}
                      style={{
                        height: '100%',
                      }}
                    >
                      <div
                        className={Styles['first-box']}
                        style={{
                          display: 'flex',
                          height: '45%',
                        }}
                      >
                        <div
                          style={{
                            width: '50%',
                            background: '#D1D1D1',
                            padding: '18px 0 0 12px',
                            borderTopLeftRadius: '16px',
                          }}
                        >
                          <div
                            className={Styles['first-box-demand']}
                            style={{ marginBottom: '5px' }}
                          >
                            {translator[language]['Demand']}
                          </div>
                          <div
                            className={Styles['first-box-demand-value']}
                            style={{ fontWeight: 'bold' }}
                          >
                            {getUnitValue(
                              scenarioData.waterDemand,
                              selectedUnit,
                              selectedVillageTankerValue,
                            )}{' '}
                            {translator[language][selectedUnit]}
                          </div>
                        </div>
                        <div
                          style={{
                            width: '50%',
                            background: '#C1D8F7',
                            padding: '18px 0 0 12px',
                            borderTopRightRadius: '16px',
                          }}
                        >
                          <div
                            className={Styles['first-box-available']}
                            style={{ marginBottom: '5px' }}
                          >
                            {translator[language]['Available']}
                          </div>
                          <div
                            className={Styles['first-box-available-value']}
                            style={{ fontWeight: 'bold' }}
                          >
                            {getUnitValue(
                              scenarioData.waterAvailable.toFixed(2),
                              selectedUnit,
                              selectedVillageTankerValue,
                            )}{' '}
                            {translator[language][selectedUnit]}
                          </div>
                        </div>
                      </div>
                      {/* ENDS  */}

                      <div
                        style={{
                          marginTop: '',
                          height: '55%',
                          background:
                            scenarioData.waterBalance > 0
                              ? '#B1E9BA'
                              : 'rgba(216, 54, 54, 0.3)',
                          borderBottomLeftRadius: '16px',
                          borderBottomRightRadius: '16px',
                        }}
                      >
                        <div
                          className={Styles['first-box-balance']}
                          style={{
                            padding: '12px 0 0 12px',
                            fontWeight: '500',
                          }}
                        >
                          {translator[language]['Water Balance']}
                        </div>
                        <div
                          className={Styles['first-box-balance-text']}
                          style={{ padding: '0px 0 0 12px' }}
                        >
                          ({translator[language]['Availability - Demand']})
                        </div>

                        <div
                          className={Styles['first-box-balance-value']}
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginTop: '10px',

                            color:
                              scenarioData.waterBalance > 0
                                ? '#12CB30'
                                : 'rgb(216, 54, 54)',
                          }}
                        >
                          {getUnitValue(
                            scenarioData.waterBalance,
                            selectedUnit,
                            selectedVillageTankerValue,
                          )}{' '}
                          {translator[language][selectedUnit]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${Styles['item']} ${Styles['item2']}`}>
                    <div className={Styles.groundwaterLastText}>
                      {translator[language]['With the current situation']}
                      <br />
                      {translator[language]['Groundwater will last']}:
                    </div>
                    <div
                      className={Styles['months-remaining']}
                      style={{ fontWeight: 'bold' }}
                    >
                      {Math.round(scenarioData.groundwaterLastDuration).toFixed(
                        1,
                      )}{' '}
                      {translator[language]['Months']}
                    </div>
                  </div>
                  <div className={`${Styles['item']} ${Styles['item3']}`}>
                    <div
                      className={Styles.totalWaterSavedText}
                      style={{ marginBottom: '5px' }}
                    >
                      {translator[language]['Total Water Saved']}
                    </div>
                    <div
                      className={Styles['total-water-saved']}
                      style={{ fontWeight: 'bold' }}
                    >
                      {getUnitValue(
                        scenarioData.totalWaterSaved,
                        selectedUnit,
                        selectedVillageTankerValue,
                      )}{' '}
                      {translator[language][selectedUnit]}
                    </div>
                  </div>

                  <div className={`${Styles['item']} ${Styles['item5']}`}>
                    {/* COPIED STYLING OF RIGHT SIDE STARTS ------------------------------------   */}
                    <div className={Styles['third-item-three']}>
                      <div
                        className={Styles['demand-side-heading']}
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {translator[language]['Demand Side']}
                      </div>

                      <p>
                        {translator[language]['Demand before simulation']}-{' '}
                        {getUnitValue(
                          scenarioData.staticCurrentDemand?.toFixed(0),
                          selectedUnit,
                          selectedVillageTankerValue,
                        )}{' '}
                        {translator[language][selectedUnit]}
                      </p>
                      <p>
                        {translator[language]['Demand after simulation']}-{' '}
                        {getUnitValue(
                          scenarioData.waterDemand,
                          selectedUnit,
                          selectedVillageTankerValue,
                        )}{' '}
                        {translator[language][selectedUnit]}
                      </p>

                      <div className={Styles['crop-list-heading']}>
                        {translator[language]['Crop List']}:
                      </div>
                      <div
                        className={Styles['order-most-least-heading']}
                        style={{ marginBottom: '15px' }}
                      >
                        (
                        {
                          translator[language][
                            'In order of MOST -> LEAST water intensive'
                          ]
                        }
                        )
                      </div>
                      <div
                        className={Styles['third-less-padding']}
                        style={{ padding: '0 10px' }}
                      >
                        <div
                          className={Styles['less-padding-top-left']}
                          style={{
                            width: '25%',
                          }}
                        >
                          <p style={{ fontWeight: '600' }}>
                            {translator[language]['Crop Name']}
                          </p>
                        </div>

                        <div
                          className={Styles['less-padding-top-right']}
                          style={{
                            width: '25%',
                            display: 'unset',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              // justifyContent: 'flex-end',
                            }}
                          >
                            <p style={{ fontWeight: '600' }}>
                              {translator[language]['Ideal Irrigation Cycle']}
                            </p>
                          </div>
                        </div>

                        <div
                          className={Styles['less-padding-top-right']}
                          style={{
                            width: '25%',
                            display: 'unset',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              // justifyContent: 'flex-end',
                            }}
                          >
                            <p style={{ fontWeight: '600' }}>
                              {translator[language]['Actual Irrigation Cycle']}
                            </p>
                          </div>
                        </div>

                        <div
                          style={{ width: '25%' }}
                          className={Styles['top-rightest']}
                        >
                          <p style={{ fontWeight: '600' }}>
                            {translator[language]['Simulated Irrigation Cycle']}
                          </p>
                        </div>
                      </div>

                      {/* LOOPING THROUGH RANGE BAR */}
                      <div
                        style={{
                          // maxHeight: "350px",
                          maxHeight: '300px',
                          overflowY: 'scroll',
                          padding: '2px 10px',
                        }}
                      >
                        {scenarioData.crops.map((elem, index) => {
                          return (
                            <div
                              key={index}
                              className={Styles['bar-div-container']}
                              style={{ marginTop: '22px' }}
                            >
                              <div
                                className={Styles['rsuitebar-header-div']}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  position: 'relative',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '25%',
                                    position: 'relative',
                                  }}
                                >
                                  <img
                                    // src={`https://assets.ekatvam-midas.com/crops/icons-v2/${elem.id}.svg`}
                                    src={`https://assets.ekatvam-midas.com/crops/icons-contrast/${elem.id}.svg`}
                                    alt="crop images"
                                    height={22}
                                    width={22}
                                    style={{ marginRight: '7px' }}
                                  />
                                  <div
                                    className={Styles['crop-name-mapped']}
                                    style={{ marginLeft: '3px' }}
                                  >
                                    {elem.name}
                                  </div>
                                </div>
                                <div
                                  className={Styles['twelve']}
                                  style={{ width: '25%' }}
                                >
                                  <p style={{ marginLeft: '5px' }}>
                                    {' '}
                                    <span
                                      style={{
                                        color: 'rgba(56, 131, 230, 1',
                                        marginRight: '10px',
                                      }}
                                    >
                                      {(
                                        (elem?.per_ha_cwr *
                                          elem?.crop_area *
                                          1000 -
                                          elem?.per_ha_cwr *
                                            (elem?.drip_area *
                                              elem?.drip_efficiency *
                                              1000)) /
                                        (elem?.hours_per_irrigation_cycle_per_hectare *
                                          elem?.water_pumped_per_hour_in_litres *
                                          0.001 *
                                          elem?.crop_area)
                                      ).toFixed(0)}
                                      {/* {elem.drip_area.toFixed(2)}    */}
                                    </span>
                                    {''}
                                  </p>
                                </div>
                                <div
                                  style={{ width: '25%' }}
                                  className={Styles['twelve-two']}
                                >
                                  <p style={{ marginLeft: '5px' }}>
                                    {' '}
                                    <span
                                      style={{
                                        marginRight: '10px',
                                      }}
                                    >
                                      {elem?.irrigation_cycle?.toFixed(0)}
                                    </span>
                                    {''}
                                  </p>
                                </div>{' '}
                                {/* new row*/}
                                <div
                                  style={{
                                    width: '25%',
                                  }}
                                  className={Styles['twelve-two']}
                                >
                                  <p style={{ marginLeft: '6px' }}>
                                    {' '}
                                    <span
                                      style={{
                                        color: 'rgba(56, 131, 230, 1',
                                        marginRight: '10px',
                                      }}
                                    >
                                      {elem?.hasOwnProperty('newIdealValue')
                                        ? Number(elem?.newIdealValue)?.toFixed(
                                            0,
                                          )
                                        : (
                                            (elem?.per_ha_cwr *
                                              elem?.crop_area *
                                              1000 -
                                              elem?.per_ha_cwr *
                                                (elem?.drip_area *
                                                  elem?.drip_efficiency *
                                                  1000)) /
                                            (elem?.hours_per_irrigation_cycle_per_hectare *
                                              elem?.water_pumped_per_hour_in_litres *
                                              0.001 *
                                              elem?.crop_area)
                                          ).toFixed(0)}
                                    </span>
                                    {''}
                                  </p>
                                </div>
                              </div>

                              <div className={Styles['bar-div']}>
                                <div
                                  className={Styles['bar-div-suitebar']}
                                ></div>
                                <div className={Styles['right-two-ones']}></div>
                                {/* EDNS  */}

                                <div
                                  className={Styles['right-two-ones-rightest']}
                                ></div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{ borderTop: '1px solid rgba(216,216,216,1)' }}
                      ></div>
                      {/* LOOPING THROUGH RANGE BAR       */}

                      <div
                        className={Styles['bottom-last']}
                        style={{
                          display: 'flex',
                          marginTop: '20px',
                          marginRight: '15px',
                          marginLeft: '15px',
                        }}
                      >
                        <div
                          style={{ width: '25%' }}
                          className={Styles['total-stat']}
                        >
                          <p
                            style={{
                              width: '00px',
                              textAlign: 'right',
                            }}
                          >
                            {translator[language]['Total']}
                          </p>
                        </div>
                        <div
                          className={Styles['total-values-bottom']}
                          style={{
                            display: 'flex',
                            width: '25%',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'bold',
                              width: '48%',
                            }}
                          >
                            <span
                              style={{
                                color: 'rgba(56, 131, 230, 1',
                                marginRight: '10px',
                              }}
                            >
                              {scenarioData.crops
                                .reduce((initialValue, val) => {
                                  const currentIdeal =
                                    (val?.per_ha_cwr * val?.crop_area * 1000 -
                                      val?.per_ha_cwr *
                                        (val?.drip_area *
                                          val?.drip_efficiency *
                                          1000)) /
                                    (val?.hours_per_irrigation_cycle_per_hectare *
                                      val?.water_pumped_per_hour_in_litres *
                                      0.001 *
                                      val?.crop_area);

                                  return initialValue + currentIdeal;
                                }, 0)
                                ?.toFixed(0)}
                              {/* ---- */}
                            </span>
                          </p>
                        </div>
                        <div
                          className={Styles['total-values-bottom']}
                          style={{
                            display: 'flex',
                            width: '25%',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'bold',
                              width: '50%',
                              // textAlign: 'center',
                            }}
                          >
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              {scenarioData.crops
                                .reduce((initialValue, val) => {
                                  return initialValue + val.irrigation_cycle;
                                }, 0)
                                ?.toFixed(0)}
                              {/* ---- */}
                            </span>
                          </p>
                        </div>
                        <div
                          className={Styles['total-values-bottom']}
                          style={{
                            display: 'flex',
                            width: '25%',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'bold',
                              width: '50%',
                              // textAlign: 'center',
                              marginLeft: '8px',
                            }}
                          >
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              {Number(
                                scenarioData.crops.reduce(
                                  (initialValue, val) => {
                                    const currentIdeal = val?.hasOwnProperty(
                                      'newIdealValue',
                                    )
                                      ? val?.newIdealValue
                                      : (val?.per_ha_cwr *
                                          val?.crop_area *
                                          1000 -
                                          val?.per_ha_cwr *
                                            (val?.drip_area *
                                              val?.drip_efficiency *
                                              1000)) /
                                        (val?.hours_per_irrigation_cycle_per_hectare *
                                          val?.water_pumped_per_hour_in_litres *
                                          0.001 *
                                          val?.crop_area);

                                    return initialValue + currentIdeal;
                                  },
                                  0,
                                ),
                              )?.toFixed(0)}
                              {/* ---- */}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* COPIED STYLING OF RIGHT SIDE ENDS  ------------------------------------    */}
                  </div>
                </div>
              </>
            </div>
          )}
        </>
      )}

      <ReactTooltip
        id="my-tooltip-2"
        place="top"
        style={{
          background: 'black',
          color: 'white',
          fontSize: window.innerWidth < 768 ? '10px' : '12px',
        }}
        content={'Micro Irrigation'}
      />
    </>
  );
};

export default SavedScenarioIrrigation;
