import { POINT_TYPES } from '../../../../../shared/utils/tree';
import ElevatedStorageReservoir from './ElevatedStorageReservoir';
import GreyWaterInfrastructure from './GreyWaterInfrastructure';
import PipeDistributionNetwork from './PipedNetworkDistribution';
import WaterTreatmentPlant from './WaterTreatmentPlant';

const WaterDistributionNetwork = ({ data, type }) => {
  switch (type) {
    case POINT_TYPES.WTP:
      return <WaterTreatmentPlant data={data} />;
    case POINT_TYPES.ESR:
      return <ElevatedStorageReservoir data={data} />;
    case POINT_TYPES.PDN:
      return <PipeDistributionNetwork data={data} />;
    case POINT_TYPES.GWI:
      return <GreyWaterInfrastructure data={data} />;
    default:
      return <></>;
  }
};

export default WaterDistributionNetwork;
