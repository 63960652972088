import React, { useState, useEffect } from 'react';
import greenDown from '../../assets/images/greenDown.svg';
import translator from '../../assets/json/translator.json';
import base64 from 'base-64';
import AnalyticsNavbar from '../../components/Navbar/PageNav';
import greenUp from '../../assets/images/greenUp.svg';
import red from '../../assets/images/Red.svg';
import redDown from '../../assets/images/redDown.svg';
import { styled as stt } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Dialog from '@mui/material/Dialog';
import Styles from './AdvisoryCropDiversification.module.css';

import cropDivWarning from '../../assets/images/cropDivWarning.svg';
import savedScenario from '../../assets/images/savedScenario.png';

import ToolTipIcon from '../../assets/images/tooltipIcon.png';
import QMarkHover from '../../assets/images/QMarkHover.svg';

import { Link, useParams } from 'react-router-dom';
import { Get, Patch } from '../../services/apiCall';
import LoginLoader from '../../components/Loader/climb';
import roundTillOne from '../../services/roundTillOne';
import { useContext } from 'react';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../services/context/context';
import getUnitValue from '../../services/getUnitValueFunction';

const StyledTableCell = stt(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#DBDBDB',
    padding: '4px 16px 4px 16px',
    color: '#000000',

    '@media screen and (max-width:768px)': {
      fontSize: 11,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '8px 16px 8px 16px',

    '@media screen and (max-width:1250px)': {
      fontSize: 12,
    },
  },
}));

const StyledTableRow = stt(TableRow)(({ theme, isSecondTable }) => ({
  '&:nth-of-type(1)': {
    borderTop: '1px solid #707070',
  },

  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    backgroundColor: isSecondTable ? '#f3c2c2' : '#B6CFF0',
    fontWeight: 'bold',
  },
}));

const AdvisoryCropDiversification = (props) => {
  const { villageId, clusterId } = useParams();

  const {
    programId,
    language,
    selectedUnit,
    selectedVillageTankerValue,
    villageArea,
  } = useContext(GeneralContext);
  const { currentPlanYear } = useContext(TimelineContext);
  const { token, accountType } = useContext(AuthContext);

  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [isAdvisoryPublished, setIsAdvisoryPublished] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const checkIsAdvisoryPublished = async () => {
    if (!isAdvisoryPublished) {
      const payload = {
        plan: {
          published_scenario: props.scenarioId,
        },
      };
      const saveAdvisory = await Patch(
        `/simulation/plan?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&yearSession=${currentPlanYear}&season=${props.season}`,
        token,
        payload,
      );
      if (saveAdvisory.status === 'success') {
        setIsAdvisoryPublished(true);
      }
    } else {
      setIsAdvisoryPublished(false);
      setOpenSaveModal(false);
    }
    setIsSaved(true);
  };

  const [advisoryData, setAdvisoryData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  // const [advisoryType, setAdvisoryType] = useState("");

  //only if getting props:-
  const [ownerName, setOwnerName] = useState('');

  const showAdvisory = async () => {
    if (props.scenarioId) {
      //TO GET THE NAME OF ADVISORY OWNER

      const role =
        props.ownerDetails.role === 'cluster_coordinator'
          ? 'clusterCoordinator'
          : props.ownerDetails.role === 'admin'
          ? 'admin'
          : props.ownerDetails.role === 'field_facilitator'
          ? 'fieldFacilitator'
          : null;

      const emailId = props.ownerDetails.id;
      const getNameOfScenarioUser = await Get(
        `/info/user/search?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&role=${role}&term=${emailId}`,
        token,
      );

      if (getNameOfScenarioUser.status === 'success') {
        setOwnerName(getNameOfScenarioUser.data[0]?.name);
      }
      //TO GET THE NAME OF ADVISORY OWNER
    }

    const getData = await Get(
      `/simulation/scenario?id=${props.scenarioId}`,
      token,
    );

    if (getData.status === 'success') {
      // setAdvisoryType(getData.data.Type.name);

      const data = getData.data.data;

      const oldTotalCultivationArea =
        // getData.data.data.cropDiversificationCrops.reduce((initial, val) => {
        getData.data.data.backupArrayForOldCropArea.reduce((initial, val) => {
          return initial + val.crop_area;
        }, 0);

      const previousCultivationAreaPercentage =
        (oldTotalCultivationArea / getData.data.data.total_cultivation_area) *
        100;

      const newCultivationAreaPercentage =
        getData.data.data.cropCultivationArea -
        previousCultivationAreaPercentage;

      setAdvisoryData((prev) => {
        return {
          staticCurrentDemand: data.staticCurrentDemand,
          staticCurrentWaterAvailable: data.staticCurrentWaterAvailable,
          staticCurrentWaterBalance: data.staticCurrentWaterBalance,

          crops: data.cropDiversificationCrops,
          backupArrayForOldCropArea: data.backupArrayForOldCropArea,

          changeInTCM: data.staticCurrentDemand - data.waterDemand,
          changeInPercentage:
            ((data.staticCurrentDemand - data.waterDemand) /
              data.staticCurrentDemand) *
            100,
          waterDemand: data.waterDemand,
          waterAvailable: data.waterAvailable,
          waterBalance: data.waterBalance,
          totalWaterSaved: data.totalWaterSaved,
          supplyChangeTCM:
            data.staticCurrentWaterAvailable - data.waterAvailable,
          supplyChangeInPercentage:
            ((data.staticCurrentWaterAvailable - data.waterAvailable) /
              data.staticCurrentWaterAvailable) *
            100,
          waterBalanceChangeTCM:
            data.staticCurrentWaterBalance - data.waterBalance,
          waterBalanceChangeInPercentage:
            ((data.staticCurrentWaterBalance - data.waterBalance) /
              data.staticCurrentWaterBalance) *
            100,

          // overallChangeInDripAreainHA: total_drip_area - total_fixed_drip_area,

          //supply
          percIncreaseInRunoff: data.supplyCurrentRange,
          currentRunoffArrested: data.runoffAvailable,
          moreRunoffNeedsToBeArrested: data.runoffToBeArrested,

          getDeletedCropList: data.getDeletedCropList,
          totalNewCropsAdded: data.cropDiversificationCrops.filter((val) => {
            return (
              val.hasOwnProperty('isNewCropAddedInArray') &&
              val.isNewCropAddedInArray === true
            );
          }),

          total_cultivation_area: data.total_cultivation_area,
          cropCultivationArea: data.cropCultivationArea,
          groundwaterLastDuration: data.groundwaterLastDuration,
          staticGroundWaterLast: data.staticGroundWaterLast,

          aquiferChangeTCM:
            data.groundwaterLastDuration - data.staticGroundWaterLast,
          aquiferChangeInPercentage:
            ((data.staticGroundWaterLast - data.groundwaterLastDuration) /
              data.staticGroundWaterLast) *
            100,

          overAllChangeCultivation:
            ((data.cropCultivationArea - oldTotalCultivationArea) /
              data.total_cultivation_area) *
            100,

          //FOR PIE
          oldCultivationArea: previousCultivationAreaPercentage, //blue
          newCultivationArea: newCultivationAreaPercentage, //green
          middleCultivationArea:
            previousCultivationAreaPercentage + newCultivationAreaPercentage, //middle
        };
      });

      setIsLoading(false);
    }
  };

  useEffect(() => {
    showAdvisory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const advisoryOnClose = () => {
    if (isAdvisoryPublished) {
      props.setCurrentCrop('Demand Side');
      props.checkPlanIsOpen();
    }

    setOpenSaveModal(false);
    setIsAdvisoryPublished(false);
  };

  const backToAnalytics = () => {
    setOpenSaveModal(false);
    setIsAdvisoryPublished(false);
    if (isAdvisoryPublished) {
      props.setCurrentCrop('Demand Side');
      props.checkPlanIsOpen();
    }
  };

  return isLoading ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
      }}
    >
      <LoginLoader />
    </div>
  ) : (
    <div className={Styles['advisoryCropDiversification-wrapper']}>
      <ReactTooltip
        id="my-tooltip-1"
        place="bottom"
        style={{
          background: 'black',
          color: 'white',
          fontSize: window.innerWidth < 768 ? '10px' : '12px',
        }}
        content={
          <div>
            {translator[language]['Published']} -{' '}
            {props.ownerName ? props.ownerName : ownerName}
            <br />
          </div>
        }
      />

      <ReactTooltip
        id="my-tooltip-2"
        place="top"
        style={{
          background: 'black',
          color: 'white',
          fontSize: window.innerWidth < 768 ? '10px' : '12px',
        }}
        content={'Micro Irrigation'}
      />

      {/* FOR THE MODAL (WILL MAKE IT GENERIC) */}
      {openSaveModal && (
        <div style={{ width: '' }}>
          <Dialog
            Paper
            PaperProps={{
              sx: { width: window.innerWidth < 768 ? '100%' : '28%' },
            }}
            disableEscapeKeyDown
            open={openSaveModal}
            onClose={() => {
              advisoryOnClose();
            }}
          >
            <div
              style={{
                margin: '25px 0 10px 0',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '18px',
              }}
            >
              {!isAdvisoryPublished && translator[language]['Alert']}
            </div>
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <img
                src={!isAdvisoryPublished ? cropDivWarning : savedScenario}
                alt="Warn"
                width={50}
                style={{ textAlign: 'center' }}
              />
            </div>
            <div
              style={{
                marginTop: '15px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <span
                className={Styles.advisoryPublished}
                style={{ width: '180px' }}
              >
                {!isAdvisoryPublished
                  ? translator[language][
                      'You cannot edit the Advisory once published'
                    ]
                  : translator[language]['Advisory published Successfully']}
                !
              </span>
            </div>{' '}
            <div
              style={{
                display: 'flex',
                marginTop: '20px',
                justifyContent: 'center',
                marginBottom: '20px',
              }}
            >
              <button
                onClick={() => {
                  backToAnalytics();
                }}
                style={{
                  background: '#FFFFFF',
                  border: '1px solid #5F5F5F',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderRadius: '10px',
                  padding: '6px 20px',
                  marginRight: '15px',
                  cursor: 'pointer',
                }}
              >
                {!isAdvisoryPublished
                  ? translator[language]['Cancel']
                  : translator[language]['Back to Plan']}
              </button>
              <button
                onClick={() => {
                  checkIsAdvisoryPublished();
                }}
                style={{
                  background: '#448AE7',

                  color: '#ffffff',
                  border: '1px solid #448AE7',

                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderRadius: '10px',
                  padding: '6px 20px',
                  cursor: 'pointer',
                }}
              >
                {!isAdvisoryPublished ? (
                  translator[language]['Continue to Publish']
                ) : (
                  <Link
                    style={{ textDecoration: 'none', color: '#fff' }}
                    to={`/advisory/programId/${programId}/clusterId/${clusterId}/villageId/${villageId}`}
                  >
                    {translator[language]['View Advisory']}
                  </Link>
                )}
              </button>
            </div>
          </Dialog>
        </div>
      )}
      {/* FOR THE MODAL (WILL MAKE IT GENERIC) */}

      {/* SAME GOES TO THIS NAVBAR  */}

      {props.isFromAdvisoryPage === true ? (
        <AnalyticsNavbar
          {...props}
          active3={true}
          setCurrentCrop={props.setCurrentCrop}
          isAdvisory={true}
          ownerName={props.ownerName}
        />
      ) : (
        <div className={Styles.backToScenario}>
          <div
            className={Styles.backToScenarioText}
            style={{ textDecoration: 'none', color: 'unset' }}
            onClick={() => {
              props.setPreviewAdvisoryCropDiversification &&
                props.setPreviewAdvisoryCropDiversification(false);
            }}
          >
            {'<'} {translator[language]['Back to Scenario']}
          </div>

          {!isSaved ? (
            <div
              style={{
                position: 'absolute',
                background: '',
                right: '125px',
                display: 'flex',
                top: -7,
              }}
            ></div>
          ) : null}

          {props?.ownerData?.creator.id ===
          localStorage.getItem(base64.encode('emailId')) ? (
            accountType === 'clusterCoordinator' &&
            props.scenarioId !== props.globalPublishedScenarioId &&
            !isSaved ? (
              <div className={Styles.publishCropDiv}>
                <div
                  className={Styles['resetsave']}
                  onClick={() => setOpenSaveModal(true)}
                >
                  <button>{translator[language]['Publish']}</button>
                </div>
              </div>
            ) : (
              props.scenarioId === props.globalPublishedScenarioId && (
                <div className={Styles.advisoryCropDivTooltip}>
                  <img
                    data-tooltip-id="my-tooltip-1"
                    onClick={() => navigator.vibrate(100)}
                    className={Styles.toolTipIconAd}
                    src={ToolTipIcon}
                    alt="tooltip"
                  />
                </div>
              )
            )
          ) : null}
        </div>
      )}
      {/* SAME GOES TO THIS NAVBAR  */}

      <div className={Styles['advisory-preview-container']}>
        <div
          className={`${Styles['advisoryitems']} ${Styles['advisory-item-2']}`}
        >
          <div>
            <p className={Styles.advisoryOn}>
              {translator[language]['Advisory On']} -
            </p>

            <div style={{ display: 'flex' }}>
              <div
                className={`${Styles['top-section-demand']} ${Styles.mrTopSectionDemand}`}
              >
                <div
                  className={`${Styles['demand-heading']} ${Styles['supply']}`}
                >
                  <p>{translator[language]['Demand']}</p>
                </div>
                <div className={`${Styles['demand-tcm']} ${Styles['supply']}`}>
                  <div>
                    {' '}
                    <p className={Styles['tcm-amount']}>
                      <span>
                        {' '}
                        <img
                          alt="increase"
                          src={
                            advisoryData?.waterDemand >
                            advisoryData?.staticCurrentDemand
                              ? red
                              : greenDown
                          }
                        />
                      </span>
                      {getUnitValue(
                        roundTillOne(advisoryData?.waterDemand),
                        selectedUnit,
                        selectedVillageTankerValue,
                      )}{' '}
                      {translator[language][selectedUnit]}
                    </p>
                    <p
                      className={Styles['tcm-amount-percent']}
                      style={{
                        color:
                          advisoryData?.waterDemand >
                          advisoryData?.staticCurrentDemand
                            ? '#D83636'
                            : '#079F4E',
                      }}
                    >
                      {getUnitValue(
                        advisoryData?.changeInTCM?.toFixed(1),
                        selectedUnit,
                        selectedVillageTankerValue,
                      )}{' '}
                      {translator[language][selectedUnit]} ({' '}
                      {advisoryData?.changeInPercentage?.toFixed(1)}%)
                    </p>
                  </div>
                </div>
              </div>

              <div className={Styles['top-section-demand']}>
                <div
                  className={`${Styles['demand-heading']} ${Styles['supply']}`}
                >
                  <p>{translator[language]['Supply']}</p>
                </div>
                <div className={`${Styles['demand-tcm']} ${Styles['supply']}`}>
                  <div>
                    {' '}
                    <p className={Styles['tcm-amount']}>
                      <span>
                        {' '}
                        <img
                          alt="increase"
                          src={
                            advisoryData?.waterAvailable >=
                            advisoryData?.staticCurrentWaterAvailable
                              ? greenUp
                              : redDown
                          }
                        />
                      </span>
                      {getUnitValue(
                        roundTillOne(advisoryData?.waterAvailable),
                        selectedUnit,
                        selectedVillageTankerValue,
                      )}{' '}
                      {translator[language][selectedUnit]}
                    </p>
                    <p
                      className={Styles['tcm-amount-percent']}
                      style={{
                        color:
                          advisoryData?.waterAvailable >=
                          advisoryData?.staticCurrentWaterAvailable
                            ? '#079F4E'
                            : '#D83636',
                      }}
                    >
                      {getUnitValue(
                        advisoryData?.supplyChangeTCM?.toFixed(1),
                        selectedUnit,
                        selectedVillageTankerValue,
                      )}{' '}
                      {translator[language][selectedUnit]} (
                      {advisoryData?.supplyChangeInPercentage?.toFixed(1)}%)
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* TABLES ARE ADDED  */}

            <div
              className={Styles.tablesFlexDesign}
              style={{ background: '', display: 'flex' }}
            >
              <div className={Styles.tableOneAdCrDv}>
                <div className={Styles.cropListHeading}>
                  {translator[language]['Crop List']}

                  <br />
                </div>

                <div style={{ width: '', position: 'relative' }}>
                  <div
                    style={{
                      position: 'absolute',
                      top: '-25px',
                      right: '20px',
                      fontWeight: 'lighter',
                      fontSize: '14px',
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        height: '15px',
                        width: '15px',
                        backgroundColor: 'rgba(56, 131, 230, 1)',
                        marginRight: '5px',
                      }}
                    />
                    <p style={{ fontSize: '12px' }}>
                      {translator[language]['New Crops']}
                    </p>
                  </div>
                  <TableContainer component={Paper} sx={{ maxHeight: '200px' }}>
                    <Table
                      stickyHeader
                      sx={{ minWidth: 200 }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            align="left"
                            style={{
                              fontWeight: 'bold',
                              //   borderRight: "1px solid #707070",
                              // background: "red",
                              //   minWidth: "109px",
                            }}
                          >
                            {translator[language]['Crop Name']}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ fontWeight: 'bold' }}
                          >
                            {translator[language]['Crop Area']}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            style={{ fontWeight: 'bold' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                              }}
                            >
                              MI
                              <img
                                data-tooltip-id="my-tooltip-2"
                                onClick={() => navigator.vibrate(100)}
                                src={QMarkHover}
                                height="12px"
                                width="12px"
                                alt="tooltip"
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '3px',
                                  // marginTop: '3px',
                                }}
                              />
                            </div>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {/* <div> */}
                      <TableBody>
                        {advisoryData.crops.map((row) => (
                          <StyledTableRow key={row.uuid}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={{
                                color:
                                  row.hasOwnProperty('isNewCropAddedInArray') &&
                                  row.isNewCropAddedInArray === true
                                    ? '#3883E6'
                                    : 'unset',
                              }}
                            >
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{
                                color:
                                  row.hasOwnProperty('isNewCropAddedInArray') &&
                                  row.isNewCropAddedInArray === true
                                    ? '#3883E6'
                                    : 'unset',
                              }}
                            >
                              {row.cropDripArea?.toFixed(1)}
                            </StyledTableCell>
                            <StyledTableCell
                              align="right"
                              style={{
                                color:
                                  row.hasOwnProperty('isNewCropAddedInArray') &&
                                  row.isNewCropAddedInArray === true
                                    ? '#3883E6'
                                    : 'unset',
                              }}
                            >
                              {row.dripOnCrop?.toFixed(1)}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}

                        <StyledTableRow
                          style={{ position: 'sticky', bottom: -1, top: 0 }}
                        >
                          <StyledTableCell>
                            {advisoryData.crops.length}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {advisoryData.crops
                              .reduce((initial, val) => {
                                return initial + val.cropDripArea;
                              }, 0)
                              .toFixed(1)}{' '}
                            {translator[language]['Ha']}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {advisoryData.crops
                              .reduce((initial, val) => {
                                return initial + val.dripOnCrop;
                              }, 0)
                              .toFixed(1)}{' '}
                            {translator[language]['Ha']}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                      {/* </div> */}
                    </Table>
                  </TableContainer>
                </div>
              </div>

              {/* REPEAT STARTS/ */}

              <div className={Styles.tableTwoAdCrDv}>
                <div className={Styles.cropsRemoved}>
                  {translator[language]['Crops Removed']}
                  <br />
                </div>

                <div style={{ width: '' }}>
                  <TableContainer component={Paper} sx={{ maxHeight: '200px' }}>
                    <Table
                      stickyHeader
                      sx={{ minWidth: 200 }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {translator[language]['Crop Name']}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontWeight: 'bold' }}
                          >
                            {translator[language]['Crop Area']}
                            {/* Crop Area */}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            style={{ fontWeight: 'bold' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                              }}
                            >
                              MI
                              <img
                                data-tooltip-id="my-tooltip-2"
                                onClick={() => navigator.vibrate(100)}
                                src={QMarkHover}
                                height="12px"
                                width="12px"
                                alt="tooltip"
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '3px',
                                  // marginTop: '3px',
                                }}
                              />
                            </div>

                            {/* Drip Area */}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {/* <div> */}
                      <TableBody className={Styles.specificTableBody}>
                        {advisoryData.getDeletedCropList.map((row) => (
                          <StyledTableRow
                            key={row.uuid}
                            className={Styles['second-styletable']}
                          >
                            <StyledTableCell component="th" scope="row">
                              {row.name}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {row.crop_area}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.drip_area}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}

                        <StyledTableRow
                          className={Styles['second-styletable']}
                          style={{ position: 'sticky', bottom: 0, top: 0 }}
                          isSecondTable={true}
                        >
                          <StyledTableCell>
                            {advisoryData.getDeletedCropList.length}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {advisoryData.getDeletedCropList
                              .reduce((initial, val) => {
                                return initial + val.crop_area;
                              }, 0)
                              .toFixed(1)}{' '}
                            {translator[language]['Ha']}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {advisoryData.getDeletedCropList
                              .reduce((initial, val) => {
                                return initial + val.drip_area;
                              }, 0)
                              .toFixed(1)}{' '}
                            {translator[language]['Ha']}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                      {/* </div> */}
                    </Table>
                  </TableContainer>
                </div>
              </div>

              {/* REPEAT ENDS/ */}
            </div>
            {/* TABLES ARE ADDED  */}

            <div className={Styles['last-section']}>
              <div>
                <p className={Styles.newCropsAddedAdCd}>
                  {translator[language]['New Crops Added']}
                </p>
                <p className={Styles.newCropsAddedAdCdValue}>
                  {advisoryData.totalNewCropsAdded.length}
                </p>
              </div>
              <div>
                <p className={Styles.newCultivationAreaAdcd}>
                  {translator[language]['New Cultivation Area']}
                </p>
                <p className={Styles.newCultivationAreaValue}>
                  <span style={{ color: '#3883E6' }}>
                    {advisoryData.cropCultivationArea.toFixed(1)}
                  </span>{' '}
                  /
                  <span style={{ color: '' }}>
                    {roundTillOne(villageArea)}
                    {/* {advisoryData.total_cultivation_area?.toFixed(1)} */}
                  </span>{' '}
                  {translator[language]['Ha']}
                </p>
              </div>

              {/* ADDED DRIP AREA AS WELL */}
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    // justifyContent: 'flex-end',
                  }}
                >
                  <p className={Styles.dripAreaAdcd}> MI</p>
                  <img
                    data-tooltip-id="my-tooltip-2"
                    onClick={() => navigator.vibrate(100)}
                    src={QMarkHover}
                    height="12px"
                    width="12px"
                    alt="tooltip"
                    style={{
                      cursor: 'pointer',
                      marginLeft: '3px',
                      // marginTop: '3px',
                    }}
                  />
                </div>

                <p className={Styles.dripAreaAdcdValue}>
                  <span style={{ color: '#3883E6' }}>
                    {advisoryData.crops
                      .reduce((initial, val) => {
                        return initial + val.dripOnCrop;
                      }, 0)
                      .toFixed(1)}{' '}
                  </span>{' '}
                  {translator[language]['Ha']}
                </p>
              </div>
              {/* ADDED DRIP AREA AS WELL */}

              <div>
                <p className={Styles.overAllChangeCultivationAdcd}>
                  {translator[language]['Overall Change in Cultivation %']}
                </p>
                <div className={Styles.overAllChangeCultivationAdcdValue}>
                  {/* <GenericAdvisoryChart
                    name1={"Older Cultivation Area"}
                    name2={"New Cultivation Area"}
                    blue={advisoryData?.oldCultivationArea}
                    green={advisoryData?.newCultivationArea}
                    middle={advisoryData?.middleCultivationArea}
                  /> */}
                  <p
                    // className={Styles.overAllChangeCultivationAdcdValue}
                    style={{
                      color:
                        advisoryData.backupArrayForOldCropArea.reduce(
                          (initial, val) => {
                            return initial + val.crop_area;
                          },
                          0,
                        ) <= advisoryData.cropCultivationArea
                          ? '#487BD4'
                          : '#D83636',
                    }}
                  >
                    {advisoryData.overAllChangeCultivation?.toFixed(1)}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${Styles['advisoryitems']} ${Styles['advisory-item-3']}`}
        >
          <div>
            <p className={Styles.resWatBal}>
              {translator[language]['Resulting Water Balance']}
            </p>
            <div className={Styles['top-section-demand']}>
              <div
                className={`${Styles['demand-tcm']} ${Styles['centered']} ${Styles['']}`}
              >
                <div>
                  {' '}
                  <p className={Styles['tcm-amount']}>
                    <span>
                      {' '}
                      <img
                        alt="increase"
                        src={
                          advisoryData?.waterBalance >=
                          advisoryData?.staticCurrentWaterBalance
                            ? greenUp
                            : redDown
                        }
                      />
                    </span>
                    {getUnitValue(
                      roundTillOne(advisoryData?.waterBalance),
                      selectedUnit,
                      selectedVillageTankerValue,
                    )}{' '}
                    {translator[language][selectedUnit]}
                  </p>
                  <p
                    className={Styles['tcm-amount-percent']}
                    style={{
                      color:
                        advisoryData?.waterBalance >=
                        advisoryData?.staticCurrentWaterBalance
                          ? '#079F4E'
                          : '#D83636',
                    }}
                  >
                    {getUnitValue(
                      roundTillOne(advisoryData?.waterBalanceChangeTCM),
                      selectedUnit,
                      selectedVillageTankerValue,
                    )}{' '}
                    {translator[language][selectedUnit]} (
                    {roundTillOne(advisoryData?.waterBalanceChangeInPercentage)}
                    %)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${Styles['advisoryitems']} ${Styles['advisory-item-4']}`}
        >
          <div>
            <p className={Styles.grouAqLa}>
              {translator[language]['Groundwater Aquifer will Last']}
            </p>
            <div className={Styles['top-section-demand']}>
              <div className={`${Styles['demand-tcm']} ${Styles['centered']}`}>
                <div>
                  {' '}
                  <p className={Styles['tcm-amount']}>
                    <span>
                      {' '}
                      <img
                        alt="increase"
                        src={
                          advisoryData?.groundwaterLastDuration >=
                          advisoryData?.staticGroundWaterLast
                            ? greenUp
                            : redDown
                        }
                      />
                    </span>
                    {advisoryData.groundwaterLastDuration.toFixed(1)}{' '}
                    {translator[language]['Months']}
                  </p>
                  <p
                    className={Styles['tcm-amount-percent']}
                    style={{
                      color:
                        advisoryData?.aquiferChangeTCM < 0
                          ? '#D83636'
                          : '#079F4E',
                    }}
                  >
                    {roundTillOne(advisoryData?.aquiferChangeTCM)}{' '}
                    {translator[language]['Months']}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${Styles['advisoryitems']} ${Styles['advisory-item-5']}`}
        >
          <div>
            <p className={Styles.watSavePla}>
              {translator[language]['Water Saved From the Plan']}
            </p>
            <div className={Styles['top-section-demand']}>
              <div className={`${Styles['demand-tcm']} ${Styles['centered']}`}>
                <div>
                  {' '}
                  <p
                    className={Styles['tcm-amount']}
                    style={{
                      color: advisoryData?.totalWaterSaved < 0 ? '#D83636' : '',
                    }}
                  >
                    <span> </span>
                    {getUnitValue(
                      advisoryData?.totalWaterSaved,
                      selectedUnit,
                      selectedVillageTankerValue,
                    )}{' '}
                    {translator[language][selectedUnit]}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvisoryCropDiversification;
