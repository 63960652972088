import React, { useState } from 'react';
import { useEffect } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import styled from 'styled-components';
import translator from '../../../../assets/json/translator.json';
import { sortDatesByYearAndMonth } from '../../../../services/genericSortMonthYear';
import roundOff from '../../../../services/round';
import months from '../../../../shared/constants/months';
import { useContext } from 'react';
import {
  GeneralContext,
  TimelineContext,
} from '../../../../services/context/context';

const CustomTooltip = ({ active, payload, message }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          boxShadow: '0px 3px 6px #00000029',
          width: '130%',
          padding: '5%',
        }}
      >
        {payload.length > 0 && payload[0].payload.name ? (
          <p
            className="label"
            style={{ margin: '5%', fontWeight: '600' }}
          >{`${payload[0].payload.name}`}</p>
        ) : (
          <></>
        )}
        {payload.length > 0 && payload[0].payload.Value ? (
          <p className="label" style={{ margin: '5%', color: '#5e97f1' }}>{`${
            translator[message.language]['Value']
          } : ${payload[0].payload.Value}`}</p>
        ) : (
          <p className="label" style={{ margin: '5%', color: '#5e97f1' }}>{`${
            translator[message.language]['Value']
          } : 0`}</p>
        )}
      </div>
    );
  }

  return null;
};

const HumidityChart = (props) => {
  const { language } = useContext(GeneralContext);
  const { month, year } = useContext(TimelineContext);

  const [dataState, setDataState] = useState([]);

  const { data } = props?.metDataApi;

  let payload = data;
  const sortedData = sortDatesByYearAndMonth(payload);
  payload = sortedData;

  let currentMonthHumidity = payload[payload.length - 1];

  useEffect(() => {
    let tempData = [];
    payload &&
      payload.map((ele) => {
        return tempData.push({
          name: `${months[ele.month - 1].substring(0, 3)} ${String(
            ele.year,
          ).slice(-2)}`,
          Value: roundOff(ele.humidity),
        });
      });

    setDataState(tempData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let message = {
    language: language,
  };
  return (
    <>
      <Wrapper>
        <div
          className="heading-area-metdata"
          style={{
            display: 'flex',
          }}
        >
          <div style={{ marginRight: '30px', marginBottom: '0px' }}>
            <div>{translator[language]['Current Month Humidity']}</div>
            <div style={{ color: '#3883E6', fontWeight: 'bold' }}>
              {currentMonthHumidity?.humidity?.toFixed(0)}%
            </div>
          </div>
        </div>

        <div
          className="chart-wrapper"
          style={{
            background: '',
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              barSize={20}
              className="chartFont"
              margin={{
                left: -10,
                right: 5,
                top: 26,
              }}
              data={dataState}
            >
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis dataKey="name" tick={{ fill: '#707070' }} />
              <YAxis
                dataKey="Value"
                domain={[
                  0,
                  Math.ceil(Math.max(...dataState.map((d) => d.Value)) / 10) *
                    10,
                ]}
                tick={{ fill: '#707070' }}
                label={{
                  value: translator[language]['Percentage'] + '(%)',
                  angle: -90,
                  position: 'insideLeft',
                  dx: 20,
                  dy: 15,
                  scaleToFit: true,
                  fontSize: 10,
                  fill: '#707070',
                }}
              />

              {/* <Legend
                verticalAlign="top"
                height={26}
                align="right"
                iconSize={8}
                content={null}
              /> */}
              <Tooltip
                wrapperStyle={{ fontSize: '12px' }}
                content={<CustomTooltip message={message} />}
              />
              <Bar dataKey="Value" fill="#8884d8" radius={[15, 15, 0, 0]}>
                {dataState.map((d) => {
                  if (
                    `${months[month].substring(0, 3)} ${String(year).slice(
                      -2,
                    )}` === d.name
                  )
                    return <Cell key={d.name} fill={'#3883E6'} />;
                  return <Cell key={d.name} fill={'#9A9A9A'} />;
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Wrapper>
    </>
  );
};

export default HumidityChart;

const Wrapper = styled.div`
  .heading-area-metdata {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 18px;
    font-size: 0.75rem;
  }
  .chart-wrapper {
    height: 205px;
  }

  .chartFont {
    font-size: 0.65rem;
  }

  @media screen and (max-width: 1250px) {
    .heading-area-metdata {
      margin-left: 23px;
      font-size: 0.6rem;
    }

    .chart-wrapper {
      height: 160px;
    }

    .chartFont {
      font-size: 0.55rem;
    }
  }
  @media screen and (max-width: 1150px) {
    .heading-area-metdata {
      font-size: 0.6rem;
    }

    .chart-wrapper {
      height: 155px;
    }
  }
`;
