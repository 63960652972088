import React, { useEffect } from 'react';
import LanduseTableLoader from '../../Loader/hash';
import roundOff from '../../../services/round';
import translator from '../../../assets/json/translator.json';
import { useContext } from 'react';
import { GeneralContext } from '../../../services/context/context';
import Styles from './Table.module.css';
import getUnitValue from '../../../services/getUnitValueFunction';

/**
 *
 * @reason
 * We need to show the summation of area, water requirements and drip for all the crops
 * at the bottom of the table
 */
let area = 0,
  waterRequirement = 0,
  drip = 0;

/**
 *
 * @class LanduseTable
 * @description
 * This component represents the table on the Landuse page
 */

const LanduseTable = (props) => {
  const { language, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);

  useEffect(() => {
    area = 0;
    waterRequirement = 0;
    drip = 0;
  }, [props]);

  return !props.isLoading ? (
    <>
      <>
        <div className={Styles.landuseContentRightBottomHead}>
          {translator[language]['Ideal Crop water requirement']}{' '}
          <span style={{ fontSize: '12px', fontWeight: 'unset' }}>
            ({translator[language]['As per FAO guidelines']})
          </span>
        </div>
        <div className={Styles.landuseTable}>
          <table>
            <tbody>
              <tr>
                <th>{translator[language]['Crops']}</th>
                <th>{translator[language]['Season']}</th>
                <th>{translator[language]['Area under the Crop']}</th>
                <th>{translator[language]['Water Requirement']}</th>
                <th>{translator[language]['Drip']}</th>
              </tr>
              {props.crops.map((crop, index) => {
                area += parseFloat(crop.cropArea);
                waterRequirement += crop.cropWaterRequirement;
                drip += crop.cropDripArea ? crop.cropDripArea : 0;
                return (
                  <tr key={index}>
                    <td>{crop.cropName}</td>
                    <td>{translator[language][crop.cropSeason]}</td>
                    <td>
                      {crop.cropArea ? roundOff(crop.cropArea) : 0.0}{' '}
                      {translator[language]['Ha']}
                    </td>
                    <td>
                      {getUnitValue(
                        roundOff(crop.cropWaterRequirement),
                        selectedUnit,
                        selectedVillageTankerValue,
                      )}{' '}
                      {translator[language][selectedUnit]}
                    </td>
                    <td>
                      {roundOff(crop.cropDripArea)} {translator[language]['Ha']}
                    </td>
                  </tr>
                );
              })}
              <tr style={{ fontWeight: 600 }}>
                <td>{props.crops.length}</td>
                <td>{'--'}</td>
                <td>
                  {roundOff(area)} {translator[language]['Ha']}
                </td>
                <td>
                  {getUnitValue(
                    roundOff(waterRequirement),
                    selectedUnit,
                    selectedVillageTankerValue,
                  )}{' '}
                  {translator[language][selectedUnit]}
                </td>
                <td>
                  {roundOff(drip)} {translator[language]['Ha']}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    </>
  ) : (
    <LanduseTableLoader
      style={{
        position: 'relative',
        marginTop: '20%',
        marginLeft: '50%',
      }}
    />
  );
};
export default LanduseTable;
