const getEndDate = (organizationStartDate, durationInMonths) => {
  // const durationInMonths = 12; // duration in months
  // const startDate = new Date("2021-08-05"); // start_date as a Date object
  let startDate = new Date(organizationStartDate);
  startDate = new Date(startDate.setDate(startDate.getDate() - 1));

  // Calculate the end date
  const endDate = new Date(startDate);
  endDate.setMonth(startDate.getMonth() + durationInMonths);

  // Handle cases where the end month exceeds December
  while (
    endDate.getMonth() !==
    (startDate.getMonth() + durationInMonths) % 12
  ) {
    endDate.setDate(endDate.getDate() - 1);
  }

  // Format the end date as a string (e.g., "YYYY-MM-DD")
  const formattedEndDate = endDate.toISOString().split('T')[0];

  return formattedEndDate;
};

export default getEndDate;
