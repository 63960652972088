// import React, { useContext } from 'react';
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   ReferenceLine,
//   ResponsiveContainer,
//   Cell,
// } from 'recharts';
// import roundTillOne from '../../services/roundTillOne';
// import {
//   GeneralContext,
//   TimelineContext,
// } from '../../services/context/context';
// import getUnitValue from '../../services/getUnitValueFunction';
// import useProcessedChartData from '../../services/hooks/useProcessedChartData';

// const PlanChart = (props) => {
//   const { selectedUnit, selectedVillageTankerValue } = useContext(GeneralContext);
//   const { month, year, pageHead } = useContext(TimelineContext);
//   const colors = [
//     `${
//       props.currentWaterBalance >= 0
//         ? 'rgba(18, 203, 48, 1)'
//         : 'rgba(216, 54, 54, 1)'
//     }`,
//     'rgba(56, 131, 230, 1)',
//     'rgba(141, 141, 141, 1)',
//   ];

//   const data = [
//     {
//       name: 'Water Balance',
//       'Balance Water': props.currentWaterBalance,
//       pv: +1,
//     },
//     {
//       name: 'Water Available',
//       'Available Water': props.currentWaterAvailable,
//       pv: 0,
//     },
//     {
//       name: 'Water Demand',
//       'Demand Water': props.currentDemand,
//       pv: -1,
//     },
//     // {
//     //   name: 'Water Balance',
//     //   uv: props.currentWaterBalance,
//     //   pv: +1,
//     // },
//     // {
//     //   name: 'Water Available',
//     //   uv: props.currentWaterAvailable,
//     //   pv: 0,
//     // },
//     // {
//     //   name: 'Water Demand',
//     //   uv: props.currentDemand,
//     //   pv: -1,
//     // },
//   ];

//   const renderCustomBarLabel = ({
//     // payload,
//     x,
//     y,
//     width,
//     // height,
//     value,
//     // domain,
//   }) => {
//     return (
//       !isNaN(width) && (
//         <text
//           style={{ fontSize: '10px' }}
//           x={x + width / 2}
//           y={y + 25}
//           fill="rgba(255, 255, 255, 1)"
//           textAnchor="middle"
//           dy={-6}
//           // dx={value < -40 ? -40 : 0}
//           // dx={0}
//         >{`${roundTillOne(value)}`}</text>
//       )
//     );
//   };

//   // let chartValues = JSON.stringify([
//   //   'Balance Water',
//   //   'Available Water',
//   //   'Demand Water',
//   // ]);

//   // const processedData = useProcessedChartData(
//   //   data,
//   //   selectedUnit,
//   //   selectedVillageTankerValue,
//   //   month,
//   //   year,
//   //   pageHead,
//   //   chartValues,
//   // );

//   // console.log(processedData, 'DATA=>', data);

//   return (
//     <ResponsiveContainer width="100%" height="100%">
//       <BarChart
//         width={500}
//         height={300}
//         data={data}
//         margin={{
//           top: 10,
//           right: 10,
//           left: 10,
//           bottom: 10,
//         }}
//         layout={'vertical'}
//         barGap={16}
//       >
//         <XAxis
//           type="number"
//           interval="preserveStartEnd"
//           domain={[-4700, 4700]}
//           // domain={[-2500, 2500]}
//           tickFormatter={(value) => value.toFixed(0)}
//           style={{ fontSize: window.innerWidth < 600 ? '9px' : '16px' }}
//         />
//         <YAxis type="number" dataKey={'pv'} hide domain={[-2, +2]} />

//         <ReferenceLine x={0} stroke="#000" />

//         {/* CHANGE STARTS  */}

//         <Bar
//           dataKey="waterBalance" // Change dataKey to the property you want to display
//           barSize={30}
//           label={renderCustomBarLabel}
//           isAnimationActive={false}
//         >
//           {colors.map((entry, index) => (
//             <Cell key={`cell-${index}`} fill={colors[index % 20]} />
//           ))}
//         </Bar>
//         <Bar
//           dataKey="waterAvailable" // Change dataKey to the property you want to display
//           barSize={30}
//           label={renderCustomBarLabel}
//           isAnimationActive={false}
//         >
//           {colors.map((entry, index) => (
//             <Cell key={`cell-${index}`} fill={colors[index % 20]} />
//           ))}
//         </Bar>
//         <Bar
//           dataKey="waterDemand" // Change dataKey to the property you want to display
//           barSize={30}
//           label={renderCustomBarLabel}
//           isAnimationActive={false}
//         >
//           {colors.map((entry, index) => (
//             <Cell key={`cell-${index}`} fill={colors[index % 20]} />
//           ))}
//         </Bar>

//         {/* CHANGE ENDS  */}

//         {/* <Bar
//           dataKey="uv"
//           barSize={30}
//           label={renderCustomBarLabel}
//           isAnimationActive={false}
//         >
//           {colors.map((entry, index) => (
//             <Cell key={`cell-${index}`} fill={colors[index % 20]} />
//           ))}
//         </Bar> */}
//       </BarChart>
//     </ResponsiveContainer>
//   );
// };

// export default PlanChart;

import React, { useContext } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ReferenceLine,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import roundTillOne from '../../services/roundTillOne';
import {
  GeneralContext,
  TimelineContext,
} from '../../services/context/context';
import useProcessedChartData from '../../services/hooks/useProcessedChartData';

const PlanChart = (props) => {
  const { selectedUnit, selectedVillageTankerValue } = useContext(GeneralContext);
  const { month, year, pageHead } = useContext(TimelineContext);
  const colors = [
    `${
      props.currentWaterBalance >= 0
        ? 'rgba(18, 203, 48, 1)'
        : 'rgba(216, 54, 54, 1)'
    }`,
    'rgba(56, 131, 230, 1)',
    'rgba(141, 141, 141, 1)',
  ];

  const renderCustomBarLabel = ({
    // payload,
    x,
    y,
    width,
    // height,
    value,
    // domain,
  }) => {
    return (
      !isNaN(width) && (
        <text
          style={{ fontSize: '10px' }}
          x={x + width / 2}
          y={y + 25}
          fill="rgba(255, 255, 255, 1)"
          textAnchor="middle"
          dy={-6}
          // dx={value < -40 ? -40 : 0}
          // dx={0}
        >{`${roundTillOne(value)}`}</text>
      )
    );
  };

  let chartValues = JSON.stringify(['uv']);

  const processedData = useProcessedChartData(
    props.data,
    selectedUnit,
    selectedVillageTankerValue,
    month,
    year,
    pageHead,
    chartValues,
  );
  
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={processedData}
        margin={{
          top: 10,
          right: 10,
          left: 10,
          bottom: 10,
        }}
        layout={'vertical'}
        barGap={16}
      >
        <XAxis
          type="number"
          interval="preserveStartEnd"
          domain={[-2700, 2700]}
          // domain={[-4700, 4700]}
          tickFormatter={(value) => value.toFixed(0)}
          style={{ fontSize: window.innerWidth < 600 ? '9px' : '16px' }}
        />
        <YAxis type="number" dataKey={'pv'} hide domain={[-2, +2]} />

        <ReferenceLine x={0} stroke="#000" />

        <Bar
          dataKey="uv"
          barSize={30}
          label={renderCustomBarLabel}
          isAnimationActive={false}
        >
          {colors.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PlanChart;
