import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Label,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import translator from '../../../assets/json/translator.json';
import Styles from './VillageChart.module.css';
import { useContext } from 'react';
import { GeneralContext } from '../../../services/context/context';

/**
 *
 * @param {*} viewBox - represents the box inside pie chart resides
 * @param {*} value1 - Stores the number which we want to display in the middle(inside) of pie chart
 * @returns text tag with the styled format
 */
function CustomLabel({ viewBox, value1 }) {
  const { cx, cy } = viewBox;
  return (
    <text
      x={cx}
      y={cy}
      fill="#3d405c"
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
    >
      <tspan alignmentBaseline="middle" fontSize="1.5vw" fill="#3883E6">
        {value1}
      </tspan>
    </text>
  );
}
/**
 *
 * @param {*} active - True if mouse is on that section of the pie chart
 * @param {*} payload - Object which stores the data we want to display
 * @param {*} message - Object which stores the language we want to display the payload
 * @returns Custom tooltip and style on hovering over the pie chart
 */
function CustomTooltip({ active, payload, message }) {
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#ffff',
          padding: '5px',
          border: '1px solid #cccc',
        }}
      >
        <label style={{ fontSize: '0.7rem', fontWeight: '600' }}>{`${
          translator[message.language][payload[0].name]
        } : ${payload[0].value}`}</label>
      </div>
    );
  }

  return null;
}

/**
 *
 * @component VillageChart
 * @description
 * This component represents the popluation pie chart on the bottom component of the
 * Taluka page
 */

const VillageChart = (props) => {
  const { language } = useContext(GeneralContext);

  let message = {
    language: language,
  };
  return (
    <ResponsiveContainer
      className={Styles.piechartVillageTalukaContainer}
      width="100%"
      height="100%"
    >
      <PieChart className={Styles.piechartVillageTaluka}>
        <Pie
          data={[
            { name: 'Humans', value: props.humanPopulation },
            {
              name: 'Sheeps',
              value: props.sheepPopulation,
            },
            {
              name: 'Cattles',
              value: props.cattlePopulation,
            },
            {
              name: 'Poultries',
              value: props.poultryPopulation,
            },
          ]}
          dataKey="value"
          nameKey="name"
          animationBegin={0}
          startAngle={90}
          endAngle={450}
          innerRadius="80%"
          outerRadius="100%"
          fill="#82ca9d"
        >
          <Cell key={`cell-1`} fill="#84b5f7" />
          <Cell key={`cell-2`} fill="#0c69e3" />
          <Cell key={`cell-3`} fill="#073f88" />
          <Cell key={`cell-4`} fill="#5776a2" />
          <Label
            width={30}
            position="center"
            content={
              <CustomLabel
                value1={
                  props.humanPopulation +
                  props.sheepPopulation +
                  props.cattlePopulation +
                  props.poultryPopulation
                }
              />
            }
          ></Label>
        </Pie>
        <Tooltip content={<CustomTooltip message={message} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};
export default VillageChart;
