import React from 'react';
import ProgramStatBoxLoader from '../../Loader/hash';
import roundOff from '../../../services/round';
import HomeStyles from './StatBox.module.css';
/**
 *
 * @component ProgramStatBox
 * @description
 * This component represent the numbered stats box on the Home page
 * Area being managed, Water saved, Water structure monitored
 */

const ProgramStatBox = (props) => {
  return (
    <>
      <div
        className={HomeStyles['program-stats-container']}
        style={{
          backgroundColor: '#fff',
          borderRadius: '10px',
          boxShadow: '0px 3px 6px #00000029',
        }}
      >
        <div className={HomeStyles.homeProgramStatsBoxHead}>{props.head}</div>
        <div className={HomeStyles.homeProgramStatsBoxContent}>
          {!props.isLoading ? (
            <>
              <span className={HomeStyles.homeProgramStatsBoxNumber}>
                {props.unit === 'Tankers' ? props.value : roundOff(props.value)}
              </span>{' '}
              <span className={HomeStyles.homeProgramStatsBoxUnit}>
                {props.unit}
              </span>
            </>
          ) : (
            <ProgramStatBoxLoader
              size={window.innerWidth < 480 ? 20 : 30}
              className={HomeStyles.statBoxLoader}
              style={{
                position: 'relative',
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProgramStatBox;
