import React, { useContext } from 'react';
import { GiHut } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import {
  GeneralContext,
  TimelineContext,
} from '../../services/context/context';
import Styles from './Village.module.css';

/**
 *
 * @component SidebarVillage
 * @description This component represents the village on the sidebar
 */

const SidebarVillage = (props) => {
  const { handlePageHead } = useContext(TimelineContext);
  const { programId } = useContext(GeneralContext);

  const getLocation = () => {
    const location = window.location.pathname;
    const locSplit = location.split('/')[1];

    if (
      locSplit === 'analytics-groundwater' ||
      locSplit === 'analytics-surfacewater' ||
      locSplit === 'analytics-landuse' ||
      locSplit === 'plan' ||
      locSplit === 'web-gis'
    )
      return locSplit;
    return 'analytics-overview';
  };

  return (
    <div
      className={Styles.sidenavVillageList}
      style={props.isDropdownOpen ? { display: 'block' } : { display: 'none' }}
    >
      {props.taluka.Villages &&
        props.taluka.Villages.map((Village) => {
          return !Village.is_controlled ? (
            <div
              key={`${Village.id} container`}
              className={Styles.sidenavVillage}
              style={
                props.isDropdownOpen ? { display: 'flex' } : { display: 'none' }
              }
            >
              <div key={`${Village.id} icon`}>
                <GiHut className={Styles.villageIcon} />
              </div>
              <div key={`${Village.id} content`}>
                <Link
                  onClick={() => {
                    handlePageHead(`${Village.name} Village`);
                    props.handleCloseClick();
                  }}
                  to={{
                    pathname: `/${getLocation()}/programId/${programId}/clusterId/${
                      Village.cluster_village.cluster_id
                    }/villageId/${Village.id}`,
                  }}
                  className={`${Styles.link} ${
                    props.activeBar() === `villageId/${Village.id}`
                      ? Styles.activeLink
                      : ''
                  }`}
                >
                  {Village.name}
                </Link>
              </div>
            </div>
          ) : (
            <div key={`${Village.id} container`}></div>
          );
        })}
    </div>
  );
};
export default SidebarVillage;
