import React from 'react';
import ProgramStatBoxLoader from '../../Loader/hash';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
  Tooltip,
} from 'recharts';
import translator from '../../../assets/json/translator.json';
import roundOff from '../../../services/round';
import { GoIssueOpened } from 'react-icons/go';
import HomeStyles from './PieBox.module.css';

/**
 *
 * @param {*} viewBox - represents the box inside pie chart resides
 * @param {*} value1 - Stores the number which we want to display in the middle(inside) of pie chart
 * @returns text tag with the styled format
 */
function CustomLabel({ viewBox, value1 }) {
  const { cx, cy } = viewBox;
  return (
    <text
      x={cx}
      y={cy}
      fill="#3d405c"
      className={HomeStyles['recharts-text recharts-label']}
      textAnchor="middle"
      dominantBaseline="central"
    >
      <tspan
        alignmentBaseline="middle"
        fontSize={window.innerWidth < 768 ? '1.9vw' : '1.4vw'}
        fill="#3883E6"
      >
        {value1}%
      </tspan>
    </text>
  );
}
/**
 *
 * @param {*} active - True if mouse is on that section of the pie chart
 * @param {*} payload - Object which stores the data we want to display
 * @param {*} message - Object which stores the language we want to display the payload
 * @returns Custom tooltip and style on hovering over the pie chart
 */
function CustomTooltip({ active, payload, message }) {
  if (active) {
    return (
      <div
        className={HomeStyles['custom-tooltip']}
        style={{
          backgroundColor: '#ffff',
          padding: '5px',
          border: '1px solid #cccc',
        }}
      >
        <label style={{ fontSize: '0.7rem', fontWeight: '600' }}>{`${
          translator[message.language][payload[0].name]
        } : ${roundOff(payload[0].value)} ${
          translator[message.language]['Ha']
        }`}</label>
      </div>
    );
  }

  return null;
}

/**
 *
 * @component ProgramPieBox
 * @description
 * This component represent the stats box which contains the pie chart on the Home page
 * Drip implemented
 */

const ProgramPieBox = (props) => {
  let message = {
    language: props.language,
  };
  return (
    <>
      <div
        className={HomeStyles['program-stats-container']}
        style={{
          backgroundColor: '#fff',
          borderRadius: '10px',
          boxShadow: '0px 3px 6px #00000029',
        }}
      >
        <div
          className={HomeStyles.homeProgramPieBoxContent}
          style={{ background: '', height: '100%' }}
        >
          {!props.isLoading ? (
            <>
              <div
                className={HomeStyles.homeDripImplement}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  // backgroundColor: 'pink',
                }}
              >
                <div>
                  {/* ADDING THE TOP CONTENT IN THIS DIV  */}
                  <div
                    className={HomeStyles.homeProgramStatsBoxHead}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',

                      // backgroundColor: 'yellow',
                    }}
                  >
                    {props.head} <br />
                    <span
                      className={HomeStyles.cropDripArea}
                      style={{
                        display: 'block',
                      }}
                    >
                      {props.cropDripArea?.toFixed(2)} Ha
                    </span>
                  </div>
                  {/* ADDING THE TOP CONTENT IN THIS DIV  */}
                  <div className={HomeStyles['area-under-cultivation']}>
                    {' '}
                    {translator[props.language]['Area under cultivation']}{' '}
                  </div>{' '}
                  <span className={HomeStyles.value}>
                    {roundOff(props.cropArea)}
                  </span>
                  <span className={HomeStyles.unit}>
                    {' '}
                    {translator[props.language]['Ha']}
                  </span>
                </div>
              </div>
              <div className={HomeStyles.homeDripGraph}>
                {props.cropArea !== 0 || props.cropDripArea !== 0 ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart className={HomeStyles.homeDripGraphPie}>
                      <Pie
                        data={[
                          { name: 'Crop area', value: props.cropArea },
                          {
                            name: 'Crop drip area',
                            value: props.cropDripArea,
                          },
                        ]}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        startAngle={90}
                        endAngle={450}
                        innerRadius="75%"
                        outerRadius="100%"
                        fill="#82ca9d"
                      >
                        <Cell key={`cell-1`} fill="#f3f3f3" />
                        <Cell key={`cell-2`} fill="#0d69e4" />
                        <Label
                          width={30}
                          position="center"
                          content={
                            <CustomLabel
                              value1={parseInt(
                                (props.cropDripArea / props.cropArea) * 100,
                              )}
                            />
                          }
                        ></Label>
                      </Pie>
                      <Tooltip content={<CustomTooltip message={message} />} />
                    </PieChart>
                  </ResponsiveContainer>
                ) : (
                  <div className={HomeStyles.noStats}>
                    <div
                      style={{
                        fontSize: '0.6rem',
                        display: 'flex',
                        color: '#5b5e61',
                        alignItems: 'center',
                      }}
                    >
                      <GoIssueOpened className={HomeStyles.issueIcon} />
                      {translator[props.language]['No data available']}
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <ProgramStatBoxLoader
              size={window.innerWidth < 480 ? 20 : 30}
              style={{
                position: 'relative',
                marginTop: '20%',
                marginLeft: '50%',
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default ProgramPieBox;
