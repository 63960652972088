import { INSTITUTION } from '../../../../../shared/enums/institution';
import { GIS_LAND_USE } from '../../../../../shared/enums/gis-land-use';
import {
  PA_ID,
  CS_ID,
  RA_ID,
  HO_ID,
  SC_ID,
  TE_ID,
  AA_ID,
  AMA_ID,
} from '../../../../../shared/utils/tree';
import styles from './index.module.css';
import SegmentChart from './SegmentChart';

export const landUseColorMapping = {
  [GIS_LAND_USE.RESIDENTIAL_AREA]: '#FF8888',
  [GIS_LAND_USE.AGRICULTURE_AREA]: '#94E957',
  [GIS_LAND_USE.PUBLIC_AREA]: '#FFD966',
  [GIS_LAND_USE.NO_MAN_AREA]: '#8A6B58',
};

const PopulationDensity = ({ gis, selectedItems }) => {
  const isCSActive = selectedItems[CS_ID];
  const isSCActive = selectedItems[SC_ID];
  const isTEActive = selectedItems[TE_ID];
  const isHOActive = selectedItems[HO_ID];

  const isRAActive = selectedItems[RA_ID];
  const isAAActive = selectedItems[AA_ID];
  const isPActive = selectedItems[PA_ID];
  const isAMAActive = selectedItems[AMA_ID];

  const institutionColorMapping = {
    [INSTITUTION.COMMUNITY_STANDPOST]: '#CEF29A',
    [INSTITUTION.SCHOOL]: '#C3D7A8',
    [INSTITUTION.TEMPLE]: '#8BA660',
    [INSTITUTION.HOSPITAL]: '#A1D153',
  };

  const filteredInstitution = Object.keys(gis?.institution).reduce(
    (acc, village) => {
      const institutionData = {
        ...(isCSActive && {
          [INSTITUTION.COMMUNITY_STANDPOST]:
            gis?.institution[village][INSTITUTION.COMMUNITY_STANDPOST] ?? 0,
        }),
        ...(isSCActive && {
          [INSTITUTION.SCHOOL]:
            gis?.institution[village][INSTITUTION.SCHOOL] ?? 0,
        }),
        ...(isTEActive && {
          [INSTITUTION.TEMPLE]:
            gis?.institution[village][INSTITUTION.TEMPLE] ?? 0,
        }),
        ...(isHOActive && {
          [INSTITUTION.HOSPITAL]:
            gis?.institution[village][INSTITUTION.HOSPITAL] ?? 0,
        }),
      };

      if (Object.keys(institutionData).length > 0) {
        acc[village] = institutionData; // Maintain the structure
      }
      return acc;
    },
    {},
  );

  const filteredLandUse = {
    ...(isRAActive && {
      [GIS_LAND_USE.RESIDENTIAL_AREA]: (
        gis?.area[GIS_LAND_USE.RESIDENTIAL_AREA] ?? 0
      ).toFixed(1),
    }),
    ...(isAAActive && {
      [GIS_LAND_USE.AGRICULTURE_AREA]: (
        gis?.area[GIS_LAND_USE.AGRICULTURE_AREA] ?? 0
      ).toFixed(1),
    }),
    ...(isPActive && {
      [GIS_LAND_USE.PUBLIC_AREA]: (
        gis?.area[GIS_LAND_USE.PUBLIC_AREA] ?? 0
      ).toFixed(1),
    }),
    ...(isAMAActive && {
      [GIS_LAND_USE.NO_MAN_AREA]: (
        gis?.area[GIS_LAND_USE.NO_MAN_AREA] ?? 0
      ).toFixed(1),
    }),
  };

  return (
    <div>
      <div className={styles.header}>Population Density</div>
      <div className={styles.content}>
        <div className={styles.villageSection}>
          <div className={styles.villageName}>Area under Land Use Patterns</div>
          <div className={styles.chartContainer}>
            <SegmentChart
              data={filteredLandUse}
              colorMapping={landUseColorMapping}
            />
          </div>
          <div className={styles.chartIndex}>
            {Object.keys(filteredLandUse).map((landUse) => {
              return (
                <div key={landUse} className={styles.indexItem}>
                  <div
                    className={styles.indexColor}
                    style={{ backgroundColor: landUseColorMapping[landUse] }}
                  ></div>
                  <div>
                    {[landUse]}: <b>{filteredLandUse[landUse]}</b>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {(isCSActive || isSCActive || isTEActive || isHOActive) && (
          <hr className={styles.sectionDivider} />
        )}
        {Object.keys(filteredInstitution).map((village, index) => (
          <div key={village} className={styles.villageSection}>
            <div className={styles.villageName}>
              <b>{village}</b>: Types of Institutions
            </div>
            <div className={styles.chartContainer}>
              <SegmentChart
                data={filteredInstitution[village]}
                colorMapping={institutionColorMapping}
              />
            </div>
            <div className={styles.chartIndex}>
              {Object.keys(filteredInstitution[village]).map((institution) => {
                return (
                  <div key={institution} className={styles.indexItem}>
                    <div
                      className={styles.indexColor}
                      style={{
                        backgroundColor: institutionColorMapping[institution],
                      }}
                    ></div>
                    <div>
                      {[institution]}:{' '}
                      <b>{filteredInstitution[village][institution]}</b>
                    </div>
                  </div>
                );
              })}
            </div>
            {index < Object.keys(gis).length - 1 && (
              <hr className={styles.villageDivider} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopulationDensity;
