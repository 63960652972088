import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import damImg from '../../../assets/images/damImg.png';
import villagePondImg from '../../../assets/images/mapIcons/Village Pond 1.png';
import farmPondImg from '../../../assets/images/mapIcons/farm pond 1.png';
import miTankImg from '../../../assets/images/mapIcons/MI tank 1.png';
import gabionImg from '../../../assets/images/mapIcons/Gabion 1.png';
import lbsImg from '../../../assets/images/mapIcons/LBS 1.png';
import rechargeShaftImg from '../../../assets/images/mapIcons/Recharge Shaft 1.png';
import rechargeTrenchImg from '../../../assets/images/mapIcons/Recharge trench 1.png';

import { Get } from '../../../services/apiCall';
import MapLoader from '../../Loader/hash';
// import "../../../css/Surfacewater.css";
import roundOff from '../../../services/round';
import translator from '../../../assets/json/translator.json';
import WaterStructure from '../../../shared/enums/water-structure';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';
import Styles from './Map.module.css';
import getUnitValue from '../../../services/getUnitValueFunction';
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  Polygon,
} from '@react-google-maps/api';

let i;

/**
 *
 * @component SurfaceWaterMap
 * @states
 * isLoading --> If true show animation
 * villageGis --> An array containing coordinates to the boundary of the village
 * nalas --> (Used as an alias for check dam)An array of arrays which has coordinates of boundaries of various bodies which are not farm ponds, perculation tanks and village ponds
 * percolationTanks --> An array of arrays which has coordinates of boundaries of various percolation tanks inside that village
 * villagePonds --> An array of arrays which has coordinates of boundaries of various village ponds inside that village
 * farmPonds --> An array of arrays which has coordinates of boundaries of various farm ponds inside that village
 * nalasInfo --> An array which stores all the info about each of the check dams(same for percolationTanksInfo, villagePondsInfo, farmPondsInfo)
 * center --> Stores the coordinates of center of the map
 */

const SurfaceWaterMap = () => {
  const { villageId, clusterId } = useParams();
  const { month, year } = useContext(TimelineContext);
  const { language, programId, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [villageGis, setVillageGis] = useState([]);
  const [nalas, setNalas] = useState([]);
  const [percolationTanks, setPercolationTanks] = useState([]);
  const [villagePonds, setVillagePonds] = useState([]);
  const [farmPonds, setFarmPonds] = useState([]);
  const [lbs, setLbs] = useState([]);
  const [gabion, setGabion] = useState([]);
  const [rechargeShaft, setRechargeShaft] = useState([]);
  const [rechargeTrench, setRechargeTrench] = useState([]);

  const [nalasInfo, setNalasInfo] = useState([]);
  const [percolationTanksInfo, setPercolationTanksInfo] = useState([]);
  const [villagePondsInfo, setVillagePondsInfo] = useState([]);
  const [farmPondsInfo, setFarmPondsInfo] = useState([]);
  const [lbsInfo, setLbsInfo] = useState([]);
  const [gabionInfo, setGabionInfo] = useState([]);
  const [rechargeShaftInfo, setRechargeShaftInfo] = useState([]);
  const [rechargeTrenchInfo, setRechargeTrenchInfo] = useState([]);

  const [center, setCenter] = useState([20.5937, 78.9629]);
  const [activeMarkerIndex, setActiveMarkerIndex] = useState({
    nala: '',
    villagePond: '',
    farmPond: '',
    percolationTank: '',
    gabion: '',
    lbs: '',
    rechargeShaft: '',
    rechargeTrench: '',
  });

  let nalaColor = '#579cf7';
  let percolationTankColor = '#0c68e3';
  let villagePondColor = '#83b6f6';
  let farmPondColor = '#5776a2';

  useEffect(() => {
    const initialFunction = async () => {
      setIsLoading(true);
      i = 0;
      const params1 = {
        villageId: villageId,
        programId: programId,
        clusterId: clusterId,
      };
      // getting the village-gis data
      const response1 = await Get('/geo/village-gis', token, params1);
      const payload1 = response1.data;
      if (
        payload1 &&
        payload1.length > 0 &&
        payload1[0].geom &&
        payload1[0].geom.coordinates &&
        payload1[0].geom.coordinates.length > 0
      ) {
        let villageGisData = payload1[0].geom.coordinates[0][0];
        /**
         *
         * @reason
         * PostGIS is a spatial database extender for PostgreSQL object-relational database. Our gis
         * team renders the gis data and store it in the database but there the format of a coordinate is
         * (longitute, latitude) but on the frontend side the react-leaflet renders the coordinate in the
         * format (latitude, longitute). So, we map the villageGIS array to reverse the coordinates
         */
        villageGisData = villageGisData.map((d) => {
          return [d[1], d[0]];
        });
        // making first coordinate of the village as the center of the map
        const centerCoordinate = [
          parseFloat(payload1[0].latitude),
          parseFloat(payload1[0].longitude),
        ];
        setVillageGis(villageGisData);
        setCenter(centerCoordinate);
      } else {
        setVillageGis([]);
        setCenter([20.5937, 78.9629]);
      }
      // getting the water bodies-gis data
      const params2 = {
        villageId: villageId,
        year: year,
        month: month + 1,
        programId: programId,
        clusterId: clusterId,
      };
      const response2 = await Get('/geo/water-structure', token, params2);
      const payload2 = response2.data;
      const totalNalas = [],
        totalPercolationTanks = [],
        totalVillagePonds = [],
        totalFarmPonds = [],
        totalLbs = [],
        totalGabion = [],
        totalRechargeShaft = [],
        totalRechargeTrench = [],
        totalNalasInfo = [],
        totalPercolationTanksInfo = [],
        totalFarmPondsInfo = [],
        totalVillagePondsInfo = [],
        totalLbsInfo = [],
        totalGabionInfo = [],
        totalRechargeShaftInfo = [],
        totalRechargeTrenchInfo = [];

      /**
       *
       * @reason
       * To understand this loop function, one must see the data which comes from the '/geo/landuse'
       * api call. Here the coordinates are nested inside array which is nested inside another array
       * which is nested inside an another array. And we need to reverse those coordinates. So we apply
       * this function.
       */
      if (payload2 && payload2.length > 0) {
        payload2.map((payload) => {
          const structure = payload.structure
            ? payload.structure.toLowerCase()
            : '';

          if (
            structure === WaterStructure.CHECK_DAM.toLowerCase() ||
            structure === WaterStructure.CNB.toLowerCase() ||
            structure === WaterStructure.ENB.toLowerCase() ||
            structure === WaterStructure.KT_WEIR.toLowerCase() ||
            structure === WaterStructure.NALA.toLowerCase()
          ) {
            let co = [];
            if (payload.geom.coordinates.length > 0) {
              co = payload.geom.coordinates.map((f) => {
                return f.map((s) => {
                  return s.map((t) => {
                    return [t[1], t[0]];
                  });
                });
              });
            }
            totalNalas.push(co);
            totalNalasInfo.push([
              payload.area,
              payload.depth,
              payload.gid,
              payload.latitude,
              payload.longitude,
            ]);
          }
          if (
            structure === WaterStructure.PERCOLATION_TANK.toLowerCase() ||
            structure === WaterStructure.MI_TANK.toLowerCase()
          ) {
            let co = [];
            if (payload.geom.coordinates.length > 0) {
              co = payload.geom.coordinates.map((f) => {
                return f.map((s) => {
                  return s.map((t) => {
                    return [t[1], t[0]];
                  });
                });
              });
            }
            totalPercolationTanks.push(co);
            totalPercolationTanksInfo.push([
              payload.area,
              payload.depth,
              payload.gid,
              payload.latitude,
              payload.longitude,
            ]);
          }
          if (structure === WaterStructure.VILLAGE_POND.toLowerCase()) {
            let co = [];
            if (payload.geom.coordinates.length > 0) {
              co = payload.geom.coordinates.map((f) => {
                return f.map((s) => {
                  return s.map((t) => {
                    return [t[1], t[0]];
                  });
                });
              });
            }
            totalVillagePonds.push(co);
            totalVillagePondsInfo.push([
              payload.area,
              payload.depth,
              payload.gid,
              payload.latitude,
              payload.longitude,
            ]);
          }
          if (structure === WaterStructure.FARM_POND.toLowerCase()) {
            let co = [];
            if (payload.geom.coordinates.length > 0) {
              co = payload.geom.coordinates.map((f) => {
                return f.map((s) => {
                  return s.map((t) => {
                    return [t[1], t[0]];
                  });
                });
              });
            }
            totalFarmPonds.push(co);
            totalFarmPondsInfo.push([
              payload.area,
              payload.depth,
              payload.gid,
              payload.latitude,
              payload.longitude,
            ]);
          }
          // OTHER STRUCTURES
          if (structure === WaterStructure.LBS.toLowerCase()) {
            let co = [];
            if (payload.geom.coordinates.length > 0) {
              co = payload.geom.coordinates.map((f) => {
                return f.map((s) => {
                  return s.map((t) => {
                    return [t[1], t[0]];
                  });
                });
              });
            }
            totalLbs.push(co);
            totalLbsInfo.push([
              payload.area,
              payload.depth,
              payload.gid,
              payload.latitude,
              payload.longitude,
            ]);
          }
          if (structure === WaterStructure.GABION.toLowerCase()) {
            let co = [];
            if (payload.geom.coordinates.length > 0) {
              co = payload.geom.coordinates.map((f) => {
                return f.map((s) => {
                  return s.map((t) => {
                    return [t[1], t[0]];
                  });
                });
              });
            }
            totalGabion.push(co);
            totalGabionInfo.push([
              payload.area,
              payload.depth,
              payload.gid,
              payload.latitude,
              payload.longitude,
            ]);
          }
          if (structure === WaterStructure.RECHARGE_SHAFT.toLowerCase()) {
            let co = [];
            if (payload.geom.coordinates.length > 0) {
              co = payload.geom.coordinates.map((f) => {
                return f.map((s) => {
                  return s.map((t) => {
                    return [t[1], t[0]];
                  });
                });
              });
            }
            totalRechargeShaft.push(co);
            totalRechargeShaftInfo.push([
              payload.area,
              payload.depth,
              payload.gid,
              payload.latitude,
              payload.longitude,
            ]);
          }
          if (structure === WaterStructure.RECHARGE_TRENCH.toLowerCase()) {
            let co = [];
            if (payload.geom.coordinates.length > 0) {
              co = payload.geom.coordinates.map((f) => {
                return f.map((s) => {
                  return s.map((t) => {
                    return [t[1], t[0]];
                  });
                });
              });
            }
            totalRechargeTrench.push(co);
            totalRechargeTrenchInfo.push([
              payload.area,
              payload.depth,
              payload.gid,
              payload.latitude,
              payload.longitude,
            ]);
          }
          return '';
        });

        setNalas(totalNalas);
        setPercolationTanks(totalPercolationTanks);
        setVillagePonds(totalVillagePonds);
        setFarmPonds(totalFarmPonds);
        setLbs(totalLbs);
        setGabion(totalGabion);
        setRechargeShaft(totalRechargeShaft);
        setRechargeTrench(totalRechargeTrench);

        setNalasInfo(totalNalasInfo);
        setPercolationTanksInfo(totalPercolationTanksInfo);
        setVillagePondsInfo(totalVillagePondsInfo);
        setFarmPondsInfo(totalFarmPondsInfo);
        setLbsInfo(totalLbsInfo);
        setGabionInfo(totalGabionInfo);
        setRechargeShaftInfo(totalRechargeShaftInfo);
        setRechargeTrenchInfo(totalRechargeTrenchInfo);
      }

      setIsLoading(false);
    };

    initialFunction();

    return () => {
      setVillageGis([]);
      setNalas([]);
      setPercolationTanks([]);
      setVillagePonds([]);
      setFarmPonds([]);
      setLbs([]);
      setGabion([]);
      setRechargeShaft([]);
      setRechargeTrench([]);

      setNalasInfo([]);
      setPercolationTanksInfo([]);
      setVillagePondsInfo([]);
      setFarmPondsInfo([]);
      setLbsInfo([]);
      setGabionInfo([]);
      setRechargeShaftInfo([]);
      setRechargeTrenchInfo([]);
      setActiveMarkerIndex({
        nala: '',
        villagePond: '',
        farmPond: '',
        percolationTank: '',
        gabion: '',
        lbs: '',
        rechargeShaft: '',
        rechargeTrench: '',
      });

      setCenter([20.5937, 78.9629]);
    };
  }, [month, year, villageId, programId, clusterId, token]);

  const polygonCoordinates = villageGis.map((coord) => {
    return { lat: coord[0], lng: coord[1] };
  });

  const onMarkerClicks = (index, type) => {
    setActiveMarkerIndex({
      [type]: index,
    });
  };
  return (
    <div className={Styles.surfacewaterContentMapWrapper}>
      <div className={Styles.surfacewaterContentLeft}>
        {!isLoading ? (
          <>
            <div className={Styles.surfacewaterContentLeftHead}>
              {translator[language]['Surfacewater Bodies Map']}
            </div>
            <div className={Styles.surfacewaterContentLeftMap}>
              <LoadScript
                googleMapsApiKey="AIzaSyCaaigbj0c7yOaM_k3EuaUY3zIOkcwrEq4" // Replace with your Google Maps API key
              >
                <GoogleMap
                  center={{
                    lat: center[0],
                    lng: center[1],
                  }}
                  zoom={window.innerWidth < 768 ? 13 : 14}
                  options={{
                    scrollwheel: false,
                  }}
                  mapContainerClassName={Styles.surfacewaterContentLeftMapMap}
                >
                  <Polygon
                    paths={polygonCoordinates}
                    options={{
                      strokeColor: '#b2b2b2',
                      fillColor: '#b2b2b2',
                      fillOpacity: 0.4,
                    }}
                  />

                  {nalas.map((nala, index) => (
                    <React.Fragment key={index}>
                      <Polygon
                        path={nala}
                        options={{
                          strokeColor: nalaColor,
                          fillOpacity: 0.8,
                          strokeWeight: 0,
                        }}
                      />
                      <Marker
                        position={{
                          lat: parseFloat(
                            nalasInfo[index][3] ? nalasInfo[index][3] : 0,
                          ),
                          lng: parseFloat(
                            nalasInfo[index][4] ? nalasInfo[index][4] : 0,
                          ),
                        }}
                        icon={{
                          url: damImg,
                        }}
                        onClick={() => onMarkerClicks(index, 'nala')}
                      >
                        {index === activeMarkerIndex?.nala && (
                          <InfoWindow
                            key={i++}
                            position={{
                              lat: parseFloat(
                                nalasInfo[index][3] ? nalasInfo[index][3] : 0,
                              ),
                              lng: parseFloat(
                                nalasInfo[index][4] ? nalasInfo[index][4] : 0,
                              ),
                            }}
                          >
                            <div>
                              <b>{translator[language]['Check Dam']}</b>
                              <br />
                              {translator[language]['Depth']}:{' '}
                              {roundOff(parseFloat(nalasInfo[index][1]))}{' '}
                              {translator[language]['m']}
                              <br />
                              {translator[language]['Volume']}:{' '}
                              {getUnitValue(
                                roundOff(
                                  parseFloat(nalasInfo[index][0]) *
                                    parseFloat(nalasInfo[index][1]) *
                                    10,
                                ),
                                selectedUnit,
                                selectedVillageTankerValue,
                              )}{' '}
                              {translator[language][selectedUnit]}
                            </div>
                          </InfoWindow>
                        )}
                      </Marker>
                    </React.Fragment>
                  ))}

                  {percolationTanks.map((percolationTank, index) => {
                    return (
                      <React.Fragment key={i++}>
                        <Polygon
                          color={percolationTankColor}
                          positions={percolationTank}
                          stroke={false}
                          fillOpacity={0.8}
                        />
                        <Marker
                          position={{
                            lat: parseFloat(
                              percolationTanksInfo[index][3]
                                ? percolationTanksInfo[index][3]
                                : 0,
                            ),
                            lng: parseFloat(
                              percolationTanksInfo[index][4]
                                ? percolationTanksInfo[index][4]
                                : 0,
                            ),
                          }}
                          icon={{
                            url: miTankImg,
                          }}
                          onClick={() =>
                            onMarkerClicks(index, 'percolationTank')
                          }
                        >
                          {index === activeMarkerIndex?.percolationTank && (
                            <InfoWindow
                              key={i++}
                              position={{
                                lat: parseFloat(
                                  percolationTanksInfo[index][3]
                                    ? percolationTanksInfo[index][3]
                                    : 0,
                                ),
                                lng: parseFloat(
                                  percolationTanksInfo[index][4]
                                    ? percolationTanksInfo[index][4]
                                    : 0,
                                ),
                              }}
                            >
                              <div>
                                <b>
                                  {translator[language]['Percolation Tank']}
                                </b>
                                <br />
                                {translator[language]['Depth']}:{' '}
                                {roundOff(
                                  parseFloat(percolationTanksInfo[index][1]),
                                )}{' '}
                                {translator[language]['m']}
                                <br />
                                {translator[language]['Volume']}:{' '}
                                {getUnitValue(
                                  roundOff(
                                    parseFloat(percolationTanksInfo[index][0]) *
                                      parseFloat(
                                        percolationTanksInfo[index][1],
                                      ) *
                                      10,
                                  ),
                                  selectedUnit,
                                  selectedVillageTankerValue,
                                )}{' '}
                                {translator[language][selectedUnit]}
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      </React.Fragment>
                    );
                  })}

                  {/* Village pond */}
                  {villagePonds.map((villagePond, index) => {
                    return (
                      <React.Fragment key={i++}>
                        <Polygon
                          color={villagePondColor}
                          positions={villagePond}
                          stroke={false}
                          fillOpacity={0.8}
                        />

                        <Marker
                          position={{
                            lat: parseFloat(
                              villagePondsInfo[index][3]
                                ? villagePondsInfo[index][3]
                                : 0,
                            ),
                            lng: parseFloat(
                              villagePondsInfo[index][4]
                                ? villagePondsInfo[index][4]
                                : 0,
                            ),
                          }}
                          icon={{
                            url: villagePondImg,
                          }}
                          onClick={() => onMarkerClicks(index, 'villagePond')}
                        >
                          {index === activeMarkerIndex?.villagePond && (
                            <InfoWindow
                              key={i++}
                              position={{
                                lat: parseFloat(
                                  villagePondsInfo[index][3]
                                    ? villagePondsInfo[index][3]
                                    : 0,
                                ),
                                lng: parseFloat(
                                  villagePondsInfo[index][4]
                                    ? villagePondsInfo[index][4]
                                    : 0,
                                ),
                              }}
                            >
                              <div>
                                <b>{translator[language]['Village Pond']}</b>
                                <br />
                                {translator[language]['Depth']}:{' '}
                                {roundOff(
                                  parseFloat(villagePondsInfo[index][1]),
                                )}{' '}
                                {translator[language]['m']}
                                <br />
                                {translator[language]['Volume']}:{' '}
                                {getUnitValue(
                                  roundOff(
                                    parseFloat(villagePondsInfo[index][0]) *
                                      parseFloat(villagePondsInfo[index][1]) *
                                      10,
                                  ),
                                  selectedUnit,
                                  selectedVillageTankerValue,
                                )}{' '}
                                {translator[language][selectedUnit]}
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      </React.Fragment>
                    );
                  })}
                  {/* Farm pond */}
                  {farmPonds.map((farmPond, index) => {
                    return (
                      <React.Fragment key={i++}>
                        <Polygon
                          color={farmPondColor}
                          positions={farmPond}
                          stroke={false}
                          fillOpacity={0.8}
                        />

                        <Marker
                          position={{
                            lat: parseFloat(
                              farmPondsInfo[index][3]
                                ? farmPondsInfo[index][3]
                                : 0,
                            ),
                            lng: parseFloat(
                              farmPondsInfo[index][4]
                                ? farmPondsInfo[index][4]
                                : 0,
                            ),
                          }}
                          icon={{
                            url: farmPondImg,
                          }}
                          onClick={() => onMarkerClicks(index, 'farmPond')}
                        >
                          {index === activeMarkerIndex?.farmPond && (
                            <InfoWindow
                              key={i++}
                              position={{
                                lat: parseFloat(
                                  farmPondsInfo[index][3]
                                    ? farmPondsInfo[index][3]
                                    : 0,
                                ),
                                lng: parseFloat(
                                  farmPondsInfo[index][4]
                                    ? farmPondsInfo[index][4]
                                    : 0,
                                ),
                              }}
                            >
                              <div>
                                <b>{translator[language]['Farm Pond']}</b>
                                <br />
                                {translator[language]['Depth']}:{' '}
                                {roundOff(parseFloat(farmPondsInfo[index][1]))}{' '}
                                {translator[language]['m']}
                                <br />
                                {translator[language]['Volume']}:{' '}
                                {getUnitValue(
                                  roundOff(
                                    parseFloat(farmPondsInfo[index][0]) *
                                      parseFloat(farmPondsInfo[index][1]) *
                                      10,
                                  ),
                                  selectedUnit,
                                  selectedVillageTankerValue,
                                )}{' '}
                                {translator[language][selectedUnit]}
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      </React.Fragment>
                    );
                  })}
                  {/* LBS  */}
                  {lbs.map((lbs, index) => {
                    return (
                      <React.Fragment key={i++}>
                        <Polygon
                          color={null}
                          positions={lbs}
                          stroke={false}
                          fillOpacity={0.8}
                        />

                        <Marker
                          position={{
                            lat: parseFloat(
                              lbsInfo[index][3] ? lbsInfo[index][3] : 0,
                            ),
                            lng: parseFloat(
                              lbsInfo[index][4] ? lbsInfo[index][4] : 0,
                            ),
                          }}
                          icon={{
                            url: lbsImg,
                          }}
                          onClick={() => onMarkerClicks(index, 'lbs')}
                        >
                          {index === activeMarkerIndex?.lbs && (
                            <InfoWindow
                              key={i++}
                              position={{
                                lat: parseFloat(
                                  lbsInfo[index][3] ? lbsInfo[index][3] : 0,
                                ),
                                lng: parseFloat(
                                  lbsInfo[index][4] ? lbsInfo[index][4] : 0,
                                ),
                              }}
                            >
                              <div>
                                <b>{translator[language]['LBS']}</b>
                                <br />
                                {translator[language]['Depth']}:{' '}
                                {roundOff(parseFloat(lbsInfo[index][1]))}{' '}
                                {translator[language]['m']}
                                <br />
                                {translator[language]['Volume']}:{' '}
                                {getUnitValue(
                                  roundOff(
                                    parseFloat(lbsInfo[index][0]) *
                                      parseFloat(lbsInfo[index][1]) *
                                      10,
                                  ),
                                  selectedUnit,
                                  selectedVillageTankerValue,
                                )}{' '}
                                {translator[language][selectedUnit]}
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      </React.Fragment>
                    );
                  })}
                  {/* Gabion */}
                  {gabion.map((gabion, index) => {
                    return (
                      <React.Fragment key={i++}>
                        <Polygon
                          color={null}
                          positions={gabion}
                          stroke={false}
                          fillOpacity={0.8}
                        />

                        <Marker
                          position={{
                            lat: parseFloat(
                              gabionInfo[index][3] ? gabionInfo[index][3] : 0,
                            ),
                            lng: parseFloat(
                              gabionInfo[index][4] ? gabionInfo[index][4] : 0,
                            ),
                          }}
                          icon={{
                            url: gabionImg,
                          }}
                          onClick={() => onMarkerClicks(index, 'gabion')}
                        >
                          {index === activeMarkerIndex?.gabion && (
                            <InfoWindow
                              key={i++}
                              position={{
                                lat: parseFloat(
                                  gabionInfo[index][3]
                                    ? gabionInfo[index][3]
                                    : 0,
                                ),
                                lng: parseFloat(
                                  gabionInfo[index][4]
                                    ? gabionInfo[index][4]
                                    : 0,
                                ),
                              }}
                            >
                              <div>
                                <b>{translator[language]['Gabion']}</b>
                                <br />
                                {translator[language]['Depth']}:{' '}
                                {roundOff(parseFloat(gabionInfo[index][1]))}{' '}
                                {translator[language]['m']}
                                <br />
                                {translator[language]['Volume']}:{' '}
                                {getUnitValue(
                                  roundOff(
                                    parseFloat(gabionInfo[index][0]) *
                                      parseFloat(gabionInfo[index][1]) *
                                      10,
                                  ),
                                  selectedUnit,
                                  selectedVillageTankerValue,
                                )}{' '}
                                {translator[language][selectedUnit]}
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      </React.Fragment>
                    );
                  })}
                  {/* Recharge Shaft  */}
                  {rechargeShaft.map((rechargeShaft, index) => {
                    return (
                      <React.Fragment key={i++}>
                        <Polygon
                          color={null}
                          positions={rechargeShaft}
                          stroke={false}
                          fillOpacity={0.8}
                        />

                        <Marker
                          position={{
                            lat: parseFloat(
                              rechargeShaftInfo[index][3]
                                ? rechargeShaftInfo[index][3]
                                : 0,
                            ),
                            lng: parseFloat(
                              rechargeShaftInfo[index][4]
                                ? rechargeShaftInfo[index][4]
                                : 0,
                            ),
                          }}
                          icon={{
                            url: rechargeShaftImg,
                          }}
                          onClick={() => onMarkerClicks(index, 'rechargeShaft')}
                        >
                          {index === activeMarkerIndex?.rechargeShaft && (
                            <InfoWindow
                              key={i++}
                              position={{
                                lat: parseFloat(
                                  rechargeShaftInfo[index][3]
                                    ? rechargeShaftInfo[index][3]
                                    : 0,
                                ),
                                lng: parseFloat(
                                  rechargeShaftInfo[index][4]
                                    ? rechargeShaftInfo[index][4]
                                    : 0,
                                ),
                              }}
                            >
                              <div>
                                <b>{translator[language]['Recharge Shaft']}</b>
                                <br />
                                {translator[language]['Depth']}:{' '}
                                {roundOff(
                                  parseFloat(rechargeShaftInfo[index][1]),
                                )}{' '}
                                {translator[language]['m']}
                                <br />
                                {translator[language]['Volume']}:{' '}
                                {getUnitValue(
                                  roundOff(
                                    parseFloat(rechargeShaftInfo[index][0]) *
                                      parseFloat(rechargeShaftInfo[index][1]) *
                                      10,
                                  ),
                                  selectedUnit,
                                  selectedVillageTankerValue,
                                )}{' '}
                                {translator[language][selectedUnit]}
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      </React.Fragment>
                    );
                  })}
                  {/* Recharge Trench  */}
                  {rechargeTrench.map((rechargeTrench, index) => {
                    return (
                      <React.Fragment key={i++}>
                        <Polygon
                          color={null}
                          positions={rechargeTrench}
                          stroke={false}
                          fillOpacity={0.8}
                        />

                        <Marker
                          position={{
                            lat: parseFloat(
                              rechargeTrenchInfo[index][3]
                                ? rechargeTrenchInfo[index][3]
                                : 0,
                            ),
                            lng: parseFloat(
                              rechargeTrenchInfo[index][4]
                                ? rechargeTrenchInfo[index][4]
                                : 0,
                            ),
                          }}
                          icon={{
                            url: rechargeTrenchImg,
                          }}
                          onClick={() =>
                            onMarkerClicks(index, 'rechargeTrench')
                          }
                        >
                          {index === activeMarkerIndex?.rechargeTrench && (
                            <InfoWindow
                              key={i++}
                              position={{
                                lat: parseFloat(
                                  rechargeTrenchInfo[index][3]
                                    ? rechargeTrenchInfo[index][3]
                                    : 0,
                                ),
                                lng: parseFloat(
                                  rechargeTrenchInfo[index][4]
                                    ? rechargeTrenchInfo[index][4]
                                    : 0,
                                ),
                              }}
                            >
                              <div>
                                <b>{translator[language]['Recharge Trench']}</b>
                                <br />
                                {translator[language]['Depth']}:{' '}
                                {roundOff(
                                  parseFloat(rechargeTrenchInfo[index][1]),
                                )}{' '}
                                {translator[language]['m']}
                                <br />
                                {translator[language]['Volume']}:{' '}
                                {getUnitValue(
                                  roundOff(
                                    parseFloat(rechargeTrenchInfo[index][0]) *
                                      parseFloat(rechargeTrenchInfo[index][1]) *
                                      10,
                                  ),
                                  selectedUnit,
                                  selectedVillageTankerValue,
                                )}{' '}
                                {translator[language][selectedUnit]}
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      </React.Fragment>
                    );
                  })}
                </GoogleMap>
              </LoadScript>
            </div>
          </>
        ) : (
          <MapLoader
            size={window.innerWidth < 768 && 20}
            style={{
              position: 'relative',
              marginTop: '45%',
              marginLeft: '50%',
            }}
          />
        )}
      </div>
    </div>
  );
};
export default SurfaceWaterMap;
