import React, { useContext } from 'react';

import translator from '../../../../assets/json/translator.json';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts';
import roundOff from '../../../../services/round';
import styled from 'styled-components';
import months from '../../../../shared/constants/months';
import { sortDatesByYearAndMonth } from '../../../../services/genericSortMonthYear';
import { GeneralContext } from '../../../../services/context/context';

const CustomTooltip = ({ active, payload, message }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          boxShadow: '0px 3px 6px #00000029',
          width: '130%',
          padding: '5%',
        }}
      >
        {payload.length > 0 && payload[0].payload.name ? (
          <p
            className="label"
            style={{ margin: '5%', fontWeight: '600' }}
          >{`${payload[0].payload.name}`}</p>
        ) : (
          <></>
        )}
        {payload.length > 0 && payload[0].payload.Maximum ? (
          <p className="label" style={{ margin: '5%', color: '#FFB22F' }}>{`${
            translator[message.language]['Maximum Temperature']
          } : ${roundOff(payload[0].payload.Maximum)} ${
            translator[message.language]['Degree']
          }`}</p>
        ) : (
          <p className="label" style={{ margin: '5%', color: '#11cb30' }}>{`${
            translator[message.language]['Maximum Temperature']
          } : 0 ${translator[message.language]['Degree']}`}</p>
        )}
        {payload.length > 0 && payload[0].payload.Minimum ? (
          <p className="label" style={{ margin: '5%', color: '#3E86E6' }}>{`${
            translator[message.language]['Minimum Temperature']
          } : ${roundOff(payload[0].payload.Minimum)} ${
            translator[message.language]['Degree']
          }`}</p>
        ) : (
          <p className="label" style={{ margin: '5%', color: '#ED561B' }}>{`${
            translator[message.language]['Minimum Temperature']
          } : 0 ${translator[message.language]['Degree']}`}</p>
        )}
      </div>
    );
  }

  return null;
};

const TemperatureChart = (props) => {
  const { data } = props?.metDataApi;
  const { language } = useContext(GeneralContext);

  let payload = data;
  const sortedData = sortDatesByYearAndMonth(payload);
  payload = sortedData;

  let currentMonthTemperature = payload[payload.length - 1];

  let message = {
    language: language,
  };

  let graphData = [];

  payload &&
    payload.map((vals) => {
      return graphData.push({
        name: `${months[vals.month - 1].substring(0, 3)} ${String(
          vals.year,
        ).slice(-2)}`,
        Minimum: vals.temperature_min,
        Maximum: vals.temperature_max,
      });
    });

  const renderLegend = (props) => {
    const { payload } = props;
    return payload.map((entry, index) => (
      <span
        style={{
          float: 'right',
          marginLeft: '5%',
          // marginTop: '-12px',
          color: entry.color,
        }}
        key={index}
      >
        <svg
          className="recharts-surface"
          width="14"
          height="14"
          viewBox="0 0 32 32"
          version="1.1"
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginRight: '4px',
          }}
        >
          <path
            strokeWidth="4"
            fill="none"
            stroke={entry.color}
            d="M0,16h10.666666666666666
          A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
          H32M21.333333333333332,16
          A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
            className="recharts-legend-icon"
          ></path>
        </svg>
        {translator[language][entry.value]}
      </span>
    ));
  };

  return (
    <>
      <Wrapper>
        <div
          className="heading-area-metdata"
          style={{
            display: 'flex',
          }}
        >
          <div style={{ marginRight: '26px' }}>
            <div>{translator[language]['Max Temp.']}</div>
            <div style={{ color: '#FFB22F', fontWeight: 'bold' }}>
              {currentMonthTemperature &&
                currentMonthTemperature?.temperature_max}
            </div>
          </div>
          <div>
            <div>{translator[language]['Min Temp.']}</div>
            <div style={{ color: '#3E86E6', fontWeight: 'bold' }}>
              {' '}
              {currentMonthTemperature &&
                currentMonthTemperature?.temperature_min}
            </div>
          </div>
        </div>

        <div className="chart-wrapper">
          <ResponsiveContainer width="100%" height="100%" debounce={1}>
            <ComposedChart
              width={500}
              height={350}
              data={graphData.length > 0 ? graphData : null}
              margin={{
                left: -10,
                right: 5,
              }}
              className="chartFont"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" padding={{ left: 40, right: 40 }} />
              <YAxis
                label={{
                  // value: translator[language]['TCM'],
                  value: translator[language]['Degree (C)'],
                  angle: -90,
                  position: 'insideLeft',
                  dx: 10,
                  dy: 12,
                  scaleToFit: true,
                  fontSize: window.innerWidth < 768 ? 10 : 11,
                  fill: '#707070',
                }}
              />
              <Tooltip
                formatter={(value) =>
                  new Intl.NumberFormat('en').format(value) +
                  ' ' +
                  translator[language]['mm']
                }
                content={<CustomTooltip message={message} />}
              />
              <Legend
                verticalAlign="top"
                height={26}
                align="right"
                content={renderLegend}
              />
              <Line
                type="linear"
                dataKey="Maximum"
                stroke="#FFB22F"
                strokeWidth={2}
                activeDot={{ r: 8 }}
              />
              <Line
                type="linear"
                dataKey="Minimum"
                stroke="#3E86E6"
                strokeWidth={2}
                activeDot={{ r: 8 }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </Wrapper>
    </>
  );
};

export default TemperatureChart;

const Wrapper = styled.div`
  .heading-area-metdata {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 18px;
    font-size: 0.75rem;
  }
  .chart-wrapper {
    height: 205px;
  }

  .chartFont {
    font-size: 0.65rem;
  }

  @media screen and (max-width: 1250px) {
    .heading-area-metdata {
      margin-left: 23px;
      font-size: 0.6rem;
    }

    .chart-wrapper {
      height: 160px;
    }

    .chartFont {
      font-size: 0.55rem;
    }
  }
  @media screen and (max-width: 1150px) {
    .heading-area-metdata {
      font-size: 0.6rem;
    }

    .chart-wrapper {
      height: 155px;
    }
  }
`;
