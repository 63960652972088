import {
  GW_ID,
  PD_ID,
  SW_ID,
  VWB_ID,
  WDN_ID,
} from '../../../../shared/utils/tree';
import GroundwaterSource from './GW';
import Label from './Label';
import PopulationDensity from './PD';
import SurfacewaterSource from './SW';
import VillageWaterBudget from './VWB';
import WaterDistributionNetwork from './WDN';
import styles from './index.module.css';

const Total = ({ gis, selectedItems, activeViews }) => {
  const isGWActive = selectedItems[GW_ID];
  const isSWActive = selectedItems[SW_ID];
  const isWDNActive = selectedItems[WDN_ID];
  const isVWBActive = selectedItems[VWB_ID];
  const isPDActive = selectedItems[PD_ID];

  return (
    <>
      {isGWActive && (
        <GroundwaterSource
          gis={gis?.groundwater_source?.aggregates?.structure_distribution}
        />
      )}
      {isSWActive && (
        <SurfacewaterSource gis={gis?.surfacewater_source.aggregates} />
      )}
      {isWDNActive && (
        <WaterDistributionNetwork
          gis={gis?.water_distribution_network?.aggregates?.pipe_network}
        />
      )}
      {isVWBActive && (
        <VillageWaterBudget gis={gis?.drinking_water_budget?.aggregates} />
      )}
      {isPDActive && (
        <PopulationDensity gis={gis?.population_density?.aggregates} />
      )}
      {activeViews?.size > 0 && <Label activeViews={activeViews} />}
      {!isGWActive &&
        !isSWActive &&
        !isWDNActive &&
        !isVWBActive &&
        !isPDActive &&
        !activeViews?.size > 0 && (
          <div className={styles.total}>Please select a layer category</div>
        )}
    </>
  );
};

export default Total;
