// Create a Set to store unique crop ids
const uniqueIds = new Set();

// Use the filter function to create a new array with unique crops
const filterUnique = (crops = []) => {
  const uniqueCrops = crops?.filter((crop) => {
    // If the id is not in the Set, add it and return true (keep the crop)
    if (!uniqueIds.has(crop.id)) {
      uniqueIds.add(crop.id);
      return true;
    }
    // If the id is already in the Set, return false (skip the crop)
    return false;
  });
  return uniqueCrops;
};

export default filterUnique;
