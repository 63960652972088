import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { totalVolume, capacity } = payload[0].payload;

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          fontSize: '12px',
        }}
      >
        <p className="label">
          Total capacity:
          <b>{` ${capacity} TCM`}</b>
        </p>
        <p>
          Total water volume:<b>{` ${totalVolume} TCM`}</b>
        </p>
      </div>
    );
  }

  return null;
};

const WaterLevelStackedBarChart = ({ type, capacity, totalVolume }) => {
  const data = [
    {
      name: type,
      totalVolume: totalVolume,
      capacity: capacity,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} margin={{ top: 0, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorSmaller" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#F1B833" />
            <stop offset="100%" stopColor="#EEE0B0" />
          </linearGradient>
        </defs>

        <XAxis dataKey="name" hide />
        <YAxis domain={[0, totalVolume]} hide />
        <Tooltip content={<CustomTooltip />} />
        <Bar
          dataKey="totalVolume"
          stackId="a"
          fill="url(#colorSmaller)"
          barSize={30}
        />
        <Bar dataKey="capacity" stackId="a" fill="#FFFDFD" barSize={30} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default WaterLevelStackedBarChart;
