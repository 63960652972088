import { HashLoader } from 'react-spinners';

const hash = (props) => {
  return (
    <div style={props.style} className={props.className}>
      <HashLoader color={'#a7b9ed'} size={props.size || 30} />
    </div>
  );
};

export default hash;
