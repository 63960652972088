import React, { useState, useEffect, useContext } from 'react';
import AnalyticsNavbar from '../components/Navbar/PageNav';
import translator from '../assets/json/translator.json';
import ScenarioSummaryChart from '../components/Plan/ScenarioSummaryChart';
import ScenarioSummaryFilter from '../components/Plan/ScenarioSummaryFilter';
import { Get } from '../services/apiCall';
import NoPlanAvailable from '../components/Plan/NoPlanAvailable';

import LoginLoader from '../components/Loader/climb';
import { useParams } from 'react-router-dom';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../services/context/context';
import Styles from './SavedScenarioSummary.module.css';

const SavedScenarioSummary = (props) => {
  const { villageId, clusterId } = useParams();
  const { programId, language } = useContext(GeneralContext);
  const { selectedSeason, currentPlanYear } = useContext(TimelineContext);
  const { token } = useContext(AuthContext);

  //DATA FOR THE CHART TO RENDER
  const {
    scenarios,
    setScenarios,
    trackFilteredValues,
    setTrackFilteredValues,
    currentCountOfOwnPlansSaved,
    setCurrentCountOfOwnPlansSaved,
  } = props;

  //COMPLETE PAGE LOADING
  const [isLoading, setIsLoading] = useState(true);

  const [isPlanPageOpen, setIsPlanPageOpen] = useState(false);

  const checkPlanIsOpen = async () => {
    // setIsLoading(true);
    const payload = {
      programId: programId,
      clusterId: clusterId,
      villageId: villageId,
      yearSession: currentPlanYear,
      season: selectedSeason,
    };

    const response = await Get('/simulation/plan/is-open', token, payload);

    if (response.data === true) {
      setIsPlanPageOpen(true);
    } else {
      setIsPlanPageOpen(false);
    }

    setIsLoading(false);
  };

  const fetchSavedScenarios = async () => {
    const fetchScenarios = await Get(
      `/simulation/scenario/search?own=true&page=1&limit=5&programVillagePlanId=${props.globalPlanId}`,
      token,
    );

    if (fetchScenarios.status === 'success') {
      setCurrentCountOfOwnPlansSaved(fetchScenarios.data.totalCount);

      let arrOfObj = fetchScenarios.data.scenarios.map((values) => {
        let finalVals = {
          id: values.id,
          name: values.name,
          owner: values.creator.name,
          'Water saved': values.data.totalWaterSaved,
          Aquifer: values.data.groundwaterLastDuration.toFixed(2),
          'Water balance': values.data.waterBalance,
          Type: values.Type.name,
        };

        return finalVals;
      });

      setScenarios(arrOfObj);
    }
  };

  useEffect(() => {
    checkPlanIsOpen();
    if (props.globalArrayCount.length === 0) {
      fetchSavedScenarios();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setScenarioId = (id, scenarioType) => {
    setIsLoading(true);
    props.setScenarioId(id);
    if (scenarioType === 'demand-and-supply') {
      props.setCurrentCrop('savedScenario');
    } else if (scenarioType === 'irrigation-cycle') {
      props.setCurrentCrop('savedScenarioIrrigation');
    } else {
      props.setCurrentCrop('savedScenarioCropDiversification');
    }

    setIsLoading(false);
  };

  //  FOR SETTING UP THE SCENARIO ID WHEN USER CLICK ON SOME ID(name) FROM GRAPH ENDS

  const [openSaveModal, setOpenSaveModal] = useState(false);
  // const [value, setValue] = useState("");

  const getFilteredValues = (filteredItems, arrOfObj) => {
    let checkPerc = arrOfObj.map((item) => {
      const item2 = filteredItems.find((i2) => i2.id === item.id);

      return (
        item2 && {
          ...item2,

          ...item,
        }
      );
    });

    const finall = checkPerc.filter((values) => {
      return values !== undefined;
    });

    // FOR REMOVING DUPLICATES
    const finalFilteredCase = finall.filter((elem, index) => {
      return (
        finall.findIndex((obj) => {
          return obj.id === elem.id;
        }) === index
      );
    });
    // FOR REMOVING DUPLICATES

    setTrackFilteredValues(finalFilteredCase);
    setScenarios(finalFilteredCase);
  };

  // STATE FOR SUBMITTING THE DATE SET BY ADMIN
  const [dateByAdmin, setDateByAdmin] = useState(
    selectedSeason === 'Rabi' ? 'June 2022' : 'January 2023',
  );

  //STATE FOR CHECKING IS PLAN SUBMITTED
  const [isPlanScheduleSubmitted, setIsPlanScheduleSubmitted] = useState(false);

  //FORM SUBMISSION BY ADMIN
  const submitFormByAdmin = async () => {
    setIsPlanScheduleSubmitted(true);

    setTimeout(() => {
      setIsPlanScheduleSubmitted(false);
    }, 4000);
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
          }}
        >
          <LoginLoader />
        </div>
      ) : (
        <>
          <div
            className={
              props.currentCrop === 'SavedScenarioSummary'
                ? Styles['main-wrapper-here']
                : ''
            }
            style={{
              position: 'relative',
            }}
          >
            {/* FILTER WAS HERE  */}
            <ScenarioSummaryFilter
              openSaveModal={openSaveModal}
              setOpenSaveModal={setOpenSaveModal}
              scenarios={
                trackFilteredValues?.length > 0
                  ? trackFilteredValues
                  : scenarios
              }
              globalPlanId={props.globalPlanId}
              getFilteredValues={getFilteredValues}
              setScenarios={setScenarios}
              // FOR FILTER STATES
              globalArrayCount={props.globalArrayCount}
              setGlobalArrayCount={props.setGlobalArrayCount}
              currentMappedData={props.currentMappedData}
              setCurrentMappedData={props.setCurrentMappedData}
              referAnotherArray={props.referAnotherArray}
              setReferAnotherArray={props.setReferAnotherArray}
              duplicateArray={props.duplicateArray}
              setDuplicateArray={props.setDuplicateArray}
              currentFilterType={props.currentFilterType}
              setCurrentFilterType={props.setCurrentFilterType}
              recommendedFilterArray={props.recommendedFilterArray}
              setRecommendedFilterArray={props.setRecommendedFilterArray}
            />

            {/* {accountType !== "programManager" && ( */}
            {props.currentCrop === 'SavedScenarioSummary' && (
              <AnalyticsNavbar
                {...props}
                active2={true}
                setCurrentCrop={props.setCurrentCrop}
                savedScenarioSummary={true}
                isPlanPageOpen={isPlanPageOpen}
                setCropListMode={props.setCropListMode}
              />
            )}
          </div>

          {isPlanPageOpen ? (
            <div
              className={
                props.currentCrop === 'SavedScenarioSummary'
                  ? Styles['graph-main-wrapper']
                  : ''
              }
              style={{
                marginRight:
                  props.currentCrop === 'SavedScenarioSummary'
                    ? window.innerWidth < 768
                      ? '18px'
                      : '40px'
                    : '',
                background: '#ffffff',
                marginTop: '20px',
                position: 'relative',
                borderRadius: '15px',
                boxShadow: '0px 3px 6px #00000029',
                padding: '20px 0',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  className={Styles.filtersPosition}
                  onClick={() => setOpenSaveModal(true)}
                >
                  {translator[language]['Filters']}{' '}
                </div>

                <div className={Styles.scenarioFilterWarning}>
                  {' '}
                  {scenarios.length === 0 &&
                    trackFilteredValues.length === 0 &&
                    translator[language][
                      'Please select any scenario from filters'
                    ]}{' '}
                </div>
              </div>

              <div
                className={`${Styles.plansSavedText} ${Styles.noOfPlansSaved}`}
              >
                {translator[language]['Plans Saved']}{' '}
                {currentCountOfOwnPlansSaved ? currentCountOfOwnPlansSaved : 0}{' '}
                {translator[language]['of']} 3{' '}
              </div>

              <div
                className={Styles['chartLeftMargin']}
                style={{
                  position: 'relative',
                }}
              >
                <ScenarioSummaryChart
                  scenarios={
                    trackFilteredValues?.length > 0
                      ? trackFilteredValues
                      : scenarios
                  }
                  setScenarioId={setScenarioId}
                />

                <div
                  className={Styles.incDec}
                  style={{
                    position: 'absolute',
                    background: '',
                    right: '50px',
                    top: '60px',
                  }}
                >
                  <p>{translator[language]['Increase']}</p>

                  <div
                    style={{
                      width: 0,
                      height: 0,
                      borderLeft: '0px solid transparent',
                      borderRight: '25px solid transparent',
                      borderTop: '300px solid #A5C68B',
                    }}
                  ></div>

                  <p style={{ marginTop: '2px' }}>
                    {translator[language]['Decrease']}
                  </p>
                </div>
              </div>

              <div className={Styles.bottomValuesContainer}>
                <div
                  className={Styles['scenario-bottom-values']}
                  style={{ display: 'flex' }}
                >
                  <div
                    className={`${Styles.plansSavedText} ${Styles.plansSavedDsk}`}
                  >
                    {translator[language]['Plans Saved']}{' '}
                    {currentCountOfOwnPlansSaved
                      ? currentCountOfOwnPlansSaved
                      : 0}{' '}
                    {translator[language]['of']} 3{' '}
                  </div>
                  <div className={Styles.cropDivBottom}>
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        marginRight: '8px',
                        background: '#008960',
                      }}
                    />
                    {translator[language]['Crop Diversification']}
                  </div>
                  <div className={Styles.supplyDemandBottom}>
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        marginRight: '8px',
                        background: '#1EB250',
                      }}
                    />
                    {translator[language]['Supply']}
                    {translator[language]['Demand']}
                  </div>
                  <div className={Styles.supplyDemandBottom}>
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        marginRight: '8px',
                        background: '#1EB250',
                      }}
                    />
                    {translator[language]['Demand-Irrigation Cycle']}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        marginRight: '8px',
                        background: '#3883E6',
                      }}
                    />
                    {translator[language]['By Ekatvam']}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ marginLeft: '18%' }}>
              <NoPlanAvailable
                submitFormByAdmin={submitFormByAdmin}
                isPlanScheduleSubmitted={isPlanScheduleSubmitted}
                dateByAdmin={dateByAdmin}
                setDateByAdmin={setDateByAdmin}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SavedScenarioSummary;
