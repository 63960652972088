import styles from './index.module.css';
import Point from './Point';
import Source from './Source';
import Total from './Total';
import { ANALYTICS_VIEW } from '../../../shared/enums/analytics-view';
import { SyncLoader } from 'react-spinners';

const Analytics = ({
  selectedItems,
  gis,
  analyticsView,
  handleAnalyticsViewChange,
  selectedPoint,
  isLoading,
  activeViews,
}) => {
  const activeButton = {
    color: '#fff',
    backgroundColor: '#000',
  };

  return (
    <div className={styles.analytics}>
      <div className={styles.control}>
        <button
          onClick={() => handleAnalyticsViewChange(ANALYTICS_VIEW.TOTAL)}
          style={analyticsView === ANALYTICS_VIEW.TOTAL ? activeButton : {}}
        >
          {ANALYTICS_VIEW.TOTAL}
        </button>
        <button
          onClick={() => handleAnalyticsViewChange(ANALYTICS_VIEW.SOURCE)}
          style={analyticsView === ANALYTICS_VIEW.SOURCE ? activeButton : {}}
        >
          {ANALYTICS_VIEW.SOURCE}
        </button>
        <button
          onClick={() => handleAnalyticsViewChange(ANALYTICS_VIEW.POINT)}
          style={analyticsView === ANALYTICS_VIEW.POINT ? activeButton : {}}
        >
          {ANALYTICS_VIEW.POINT}
        </button>
      </div>
      <div className={styles.spacer}></div>
      <div className={styles.canvas}>
        {isLoading && (
          <div className={styles.loader}>
            <SyncLoader color="#9B9B9B" size={8} speedMultiplier={0.8} />
          </div>
        )}
        {!isLoading && analyticsView === ANALYTICS_VIEW.TOTAL && (
          <Total
            selectedItems={selectedItems}
            gis={gis}
            activeViews={activeViews}
          />
        )}
        {!isLoading && analyticsView === ANALYTICS_VIEW.SOURCE && (
          <Source selectedItems={selectedItems} gis={gis} />
        )}
        {!isLoading && analyticsView === ANALYTICS_VIEW.POINT && (
          <Point gis={gis} data={selectedPoint} />
        )}
      </div>
    </div>
  );
};

export default Analytics;
