import React, { useContext } from 'react';
import translator from '../../assets/json/translator.json';
import { GeneralContext } from '../../services/context/context';
import Styles from './NoAdvisoryAvailable.module.css';

const NoAdvisoryAvailable = () => {
  const { language } = useContext(GeneralContext);
  return (
    <>
      <div className={Styles.noAdvisoryContainer}>
        <div className={Styles.noAdvisoryBox}>
          <h3>
            {translator[language]['Advisory is not available for this village']}
          </h3>
        </div>
      </div>
    </>
  );
};

export default NoAdvisoryAvailable;
