import { GW_ID, PD_ID, SW_ID, WDN_ID } from '../../../../shared/utils/tree';
import GroundwaterSource from './GW';
import PopulationDensity from './PD';
import SurfacewaterSource from './SW';
import WaterDistributionNetwork from './WDN';
import styles from './index.module.css';

const Source = ({ gis, selectedItems = [] }) => {
  const isGWActive = selectedItems[GW_ID];
  const isSWActive = selectedItems[SW_ID];
  const isWDNActive = selectedItems[WDN_ID];
  const isPDActive = selectedItems[PD_ID];

  return (
    <>
      {isGWActive && (
        <GroundwaterSource
          gis={gis?.groundwater_source.sources}
          selectedItems={selectedItems}
        />
      )}
      {isSWActive && (
        <SurfacewaterSource
          gis={gis?.surfacewater_source.aggregates.structure_distribution}
          selectedItems={selectedItems}
        />
      )}
      {isWDNActive && (
        <WaterDistributionNetwork
          gis={gis?.water_distribution_network.sources}
          selectedItems={selectedItems}
        />
      )}
      {isPDActive && (
        <PopulationDensity
          gis={gis?.population_density.sources}
          selectedItems={selectedItems}
        />
      )}
      {!isGWActive && !isSWActive && !isWDNActive && !isPDActive && (
        <div className={styles.source}>Please select a layer sub category</div>
      )}
    </>
  );
};

export default Source;
