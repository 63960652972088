import styles from './index.module.css';
import SegmentChart from './SegmentChart';

const SurfacewaterSource = ({ gis }) => {
  return (
    <div>
      <div className={styles.header}>Surfacewater Source</div>
      <div className={styles.content}>
        <div className={styles.villageSection}>
          <div className={styles.title}>
            Surface water structure distribution:
          </div>
          <div className={styles.chartContainer}>
            <SegmentChart
              village_ponds={gis?.structure_distribution?.village_ponds}
              farm_ponds={gis?.structure_distribution?.farm_ponds}
              percolation_tanks={gis?.structure_distribution?.percolation_tanks}
              check_dams={gis?.structure_distribution?.check_dams}
            />
          </div>
          <div className={styles.chartIndex}>
            <div className={styles.indexItem}>
              <div
                className={`${styles.indexColor} ${styles.villagePondColor}`}
              ></div>
              <div>
                Village Pond:{' '}
                <b>{gis?.structure_distribution?.village_ponds}</b>
              </div>
            </div>
            <div className={styles.indexItem}>
              <div
                className={`${styles.indexColor} ${styles.farmPondColor}`}
              ></div>
              <div>
                Farm Pond: <b>{gis?.structure_distribution?.farm_ponds}</b>
              </div>
            </div>
            <div className={styles.indexItem}>
              <div
                className={`${styles.indexColor} ${styles.percolationTankColor}`}
              ></div>
              <div>
                Percolation Tank:{' '}
                <b>{gis?.structure_distribution?.percolation_tanks}</b>
              </div>
            </div>
            <div className={styles.indexItem}>
              <div
                className={`${styles.indexColor} ${styles.checkDamColor}`}
              ></div>
              <div>
                Check Dam: <b>{gis?.structure_distribution?.check_dams}</b>
              </div>
            </div>
          </div>
          <hr className={styles.divider} />
          <div className={styles.rechargeContainer}>
            <div className={styles.rechargeLabel}>
              <div className={styles.rechargeIcon}>
                <svg
                  width="24"
                  height="27"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 24.5C9.78333 24.5 7.89583 23.7333 6.3375 22.2C4.77917 20.6667 4 18.8 4 16.6C4 15.55 4.20417 14.5458 4.6125 13.5875C5.02083 12.6292 5.6 11.7833 6.35 11.05L12 5.5L17.65 11.05C18.4 11.7833 18.9792 12.6292 19.3875 13.5875C19.7958 14.5458 20 15.55 20 16.6C20 18.8 19.2208 20.6667 17.6625 22.2C16.1042 23.7333 14.2167 24.5 12 24.5Z"
                    fill="#F0BE39"
                  />
                  <path
                    d="M1.98462 2.23047C8.66147 2.23047 22.0151 2.23047 22.0151 2.23047"
                    stroke="#F0BE39"
                    strokeWidth="3"
                  />
                </svg>
              </div>
              <div>Groundwater Recharge</div>
            </div>
            <div className={styles.rechargeValue}>
              {gis?.groundwater_recharge} TCM
            </div>
          </div>
          <hr className={styles.divider} />
          <div className={styles.rechargeContainer}>
            <div className={styles.rechargeLabel}>
              <div className={styles.rechargeIcon}>
                <svg
                  width="24"
                  height="27"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ transform: 'rotate(180deg)' }} // Rotating the entire SVG
                >
                  <path
                    d="M12 24.5C9.78333 24.5 7.89583 23.7333 6.3375 22.2C4.77917 20.6667 4 18.8 4 16.6C4 15.55 4.20417 14.5458 4.6125 13.5875C5.02083 12.6292 5.6 11.7833 6.35 11.05L12 5.5L17.65 11.05C18.4 11.7833 18.9792 12.6292 19.3875 13.5875C19.7958 14.5458 20 15.55 20 16.6C20 18.8 19.2208 20.6667 17.6625 22.2C16.1042 23.7333 14.2167 24.5 12 24.5Z"
                    fill="#F0BE39"
                    transform="rotate(180 12 16)" // Rotating the path 180 degrees around the center of the SVG (12,12)
                  />
                  <path
                    d="M1.98462 2.23047C8.66147 2.23047 22.0151 2.23047 22.0151 2.23047"
                    stroke="#F0BE39"
                    strokeWidth="3"
                  />
                </svg>
              </div>
              <div>Surfacewater storage</div>
            </div>
            <div className={styles.rechargeValue}>
              {gis?.total_surfacewater_storage} TCM
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurfacewaterSource;
