import styles from './index.module.css';
import YieldChart from './YieldChart';

const PopulationDensity = ({ gis }) => {
  const DataRow = ({ value, unit = '', village, isBold = true }) => {
    const displayValue =
      typeof value === 'boolean' ? (value ? 'YES' : 'NO') : `${value} ${unit}`;

    return (
      <div className={styles.dataRow}>
        <div className={styles.sectionSpacer} />
        {village && <div className={styles.dataRowVillage}>{village}</div>}
        <div className={`${styles.dataRowValue} ${isBold && styles.bold}`}>
          {displayValue}
        </div>
      </div>
    );
  };

  const DataSection = ({ title, data, unit = '' }) => (
    <>
      <div className={styles.sectionHeader}>
        <div className={styles.sectionIcon} />
        <div>{title}</div>
      </div>
      <div className={styles.sectionContent}>
        {typeof data === 'object' ? (
          <>
            {data &&
              Object.keys(data).map((village) => (
                <DataRow
                  key={village}
                  village={village}
                  value={data[village]}
                  unit={unit}
                />
              ))}
          </>
        ) : (
          <DataRow value={data} unit={unit} />
        )}
      </div>
    </>
  );

  const COLOR_PALLETTE = [
    '#CEF29B',
    '#6E9829',
    '#A2D154',
    '#42443F',
    '#C2C9B6',
    '#818C72',
    '#252624',
    '#99B76F',
    '#5D6158',
    '#B5BFA6',
    '#E5F0D5',
    '#ACC080',
    '#D6E0C7',
    '#C3D7A8',
    '#74796D',
    '#A4AB9A',
    '#0E0F0D',
  ];

  const villageColorMapping = (() => {
    const villageSet = new Set();
    for (const key in gis) {
      if (gis.hasOwnProperty(key)) {
        Object.keys(gis[key]).forEach((village) => {
          villageSet.add(village);
        });
      }
    }

    const mapping = {};
    let idx = 0;
    villageSet.forEach((village) => {
      mapping[village] = COLOR_PALLETTE[idx % COLOR_PALLETTE.length];
      idx++;
    });
    return mapping;
  })();

  const getYieldChartData = (entity) => {
    const currKey = `${entity}_population`;
    const in15YearsKey = `${entity}_population_in_next_15_years`;
    const in30YearsKey = `${entity}_population_in_next_30_years`;

    return {
      [currKey]: Object.keys(gis[currKey]).reduce((acc, village) => {
        acc[village] = {
          quantity: gis[currKey][village],
          colorCode: villageColorMapping[village],
        };
        return acc;
      }, {}),
      [in15YearsKey]: Object.keys(gis[in15YearsKey]).reduce((acc, village) => {
        acc[village] = {
          quantity: gis[in15YearsKey][village],
          colorCode: villageColorMapping[village],
        };
        return acc;
      }, {}),
      [in30YearsKey]: Object.keys(gis[in30YearsKey]).reduce((acc, village) => {
        acc[village] = {
          quantity: gis[in30YearsKey][village],
          colorCode: villageColorMapping[village],
        };
        return acc;
      }, {}),
    };
  };

  return (
    <div>
      <div className={styles.header}>Population Density</div>
      <div className={styles.content}>
        <div className={styles.contentWrapper}>
          <div className={styles.title} style={{ marginBottom: '10%' }}>
            Piped Distribution Network to Households
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '40%' }}>
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Total Human Population:
                </div>
                {Object.keys(gis.human_population).map((village) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '9px',
                        marginTop: '5%',
                        alignItems: 'center',
                      }}
                      key={village}
                    >
                      <div
                        style={{
                          width: '6.5px',
                          height: '15px',
                          backgroundColor: villageColorMapping[village],
                          marginRight: '6%',
                        }}
                      />
                      <div style={{ marginRight: '3%' }}>{village}</div>
                      {/* <div style={{ fontWeight: 700 }}>
                        {gis.human_population[village]}
                      </div> */}
                    </div>
                  );
                })}
              </div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Projected Human Population in 15 yrs:
                </div>
                {Object.keys(gis.human_population_in_next_15_years).map(
                  (village) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          fontSize: '9px',
                          marginTop: '5%',
                          alignItems: 'center',
                        }}
                        key={village}
                      >
                        <div
                          style={{
                            width: '6.5px',
                            height: '15px',
                            backgroundColor: villageColorMapping[village],
                            marginRight: '6%',
                          }}
                        />
                        <div style={{ marginRight: '3%' }}>{village}</div>
                        {/* <div style={{ fontWeight: 700 }}>
                          {gis.human_population_in_next_15_years[village]}
                        </div> */}
                      </div>
                    );
                  },
                )}
              </div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Projected Human Population in 30 yrs:
                </div>
                {Object.keys(gis.human_population_in_next_30_years).map(
                  (village) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          fontSize: '9px',
                          marginTop: '5%',
                          alignItems: 'center',
                        }}
                        key={village}
                      >
                        <div
                          style={{
                            width: '6.5px',
                            height: '15px',
                            backgroundColor: villageColorMapping[village],
                            marginRight: '6%',
                          }}
                        />
                        <div style={{ marginRight: '3%' }}>{village}</div>
                        {/* <div style={{ fontWeight: 700 }}>
                          {gis.human_population_in_next_30_years[village]}
                        </div> */}
                      </div>
                    );
                  },
                )}
              </div>
            </div>
            <div style={{ width: '60%', height: '35vh', marginTop: 'auto' }}>
              <YieldChart
                data={getYieldChartData('human')}
                villageColorMapping={villageColorMapping}
                entity={'human'}
              />
            </div>
          </div>
          <DataSection
            title="Standard water requirement"
            unit="LPCD"
            data={gis.standard_human_water_requirement}
          />
          <DataSection
            title="Total water requirement"
            unit="LPD"
            data={gis.total_human_water_requirement}
          />
          <hr className={styles.sectionDivider} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '40%' }}>
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Total Sheep Population:
                </div>
                {Object.keys(gis.sheep_population).map((village) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '9px',
                        marginTop: '5%',
                        alignItems: 'center',
                      }}
                      key={village}
                    >
                      <div
                        style={{
                          width: '6.5px',
                          height: '15px',
                          backgroundColor: villageColorMapping[village],
                          marginRight: '6%',
                        }}
                      />
                      <div style={{ marginRight: '3%' }}>{village}</div>
                      {/* <div style={{ fontWeight: 700 }}>
                        {gis.sheep_population[village]}
                      </div> */}
                    </div>
                  );
                })}
              </div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Projected Sheep Population in 15 yrs:
                </div>
                {Object.keys(gis.sheep_population_in_next_15_years).map(
                  (village) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          fontSize: '9px',
                          marginTop: '5%',
                          alignItems: 'center',
                        }}
                        key={village}
                      >
                        <div
                          style={{
                            width: '6.5px',
                            height: '15px',
                            backgroundColor: villageColorMapping[village],
                            marginRight: '6%',
                          }}
                        />
                        <div style={{ marginRight: '3%' }}>{village}</div>
                        {/* <div style={{ fontWeight: 700 }}>
                          {gis.sheep_population_in_next_15_years[village]}
                        </div> */}
                      </div>
                    );
                  },
                )}
              </div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Projected Sheep Population in 30 yrs:
                </div>
                {Object.keys(gis.sheep_population_in_next_30_years).map(
                  (village) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          fontSize: '9px',
                          marginTop: '5%',
                          alignItems: 'center',
                        }}
                        key={village}
                      >
                        <div
                          style={{
                            width: '6.5px',
                            height: '15px',
                            backgroundColor: villageColorMapping[village],
                            marginRight: '6%',
                          }}
                        />
                        <div style={{ marginRight: '3%' }}>{village}</div>
                        {/* <div style={{ fontWeight: 700 }}>
                          {gis.sheep_population_in_next_30_years[village]}
                        </div> */}
                      </div>
                    );
                  },
                )}
              </div>
            </div>
            <div style={{ width: '60%', height: '35vh', marginTop: 'auto' }}>
              <YieldChart
                data={getYieldChartData('sheep')}
                villageColorMapping={villageColorMapping}
                entity={'sheep'}
              />
            </div>
          </div>
          <DataSection
            title="Standard water requirement"
            unit="LPCD"
            data={gis.standard_sheep_water_requirement}
          />
          <DataSection
            title="Total water requirement"
            unit="LPD"
            data={gis.total_sheep_water_requirement}
          />
          <hr className={styles.sectionDivider} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '40%' }}>
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Total Cattle Population:
                </div>
                {Object.keys(gis.cattle_population).map((village) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '9px',
                        marginTop: '5%',
                        alignItems: 'center',
                      }}
                      key={village}
                    >
                      <div
                        style={{
                          width: '6.5px',
                          height: '15px',
                          backgroundColor: villageColorMapping[village],
                          marginRight: '6%',
                        }}
                      />
                      <div style={{ marginRight: '3%' }}>{village}</div>
                      {/* <div style={{ fontWeight: 700 }}>
                        {gis.cattle_population[village]}
                      </div> */}
                    </div>
                  );
                })}
              </div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Projected Cattle Population in 15 yrs:
                </div>
                {Object.keys(gis.cattle_population_in_next_15_years).map(
                  (village) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          fontSize: '9px',
                          marginTop: '5%',
                          alignItems: 'center',
                        }}
                        key={village}
                      >
                        <div
                          style={{
                            width: '6.5px',
                            height: '15px',
                            backgroundColor: villageColorMapping[village],
                            marginRight: '6%',
                          }}
                        />
                        <div style={{ marginRight: '3%' }}>{village}</div>
                        {/* <div style={{ fontWeight: 700 }}>
                          {gis.cattle_population_in_next_15_years[village]}
                        </div> */}
                      </div>
                    );
                  },
                )}
              </div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Projected Cattle Population in 30 yrs:
                </div>
                {Object.keys(gis.cattle_population_in_next_30_years).map(
                  (village) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          fontSize: '9px',
                          marginTop: '5%',
                          alignItems: 'center',
                        }}
                        key={village}
                      >
                        <div
                          style={{
                            width: '6.5px',
                            height: '15px',
                            backgroundColor: villageColorMapping[village],
                            marginRight: '6%',
                          }}
                        />
                        <div style={{ marginRight: '3%' }}>{village}</div>
                        {/* <div style={{ fontWeight: 700 }}>
                          {gis.cattle_population_in_next_30_years[village]}
                        </div> */}
                      </div>
                    );
                  },
                )}
              </div>
            </div>
            <div style={{ width: '60%', height: '35vh', marginTop: 'auto' }}>
              <YieldChart
                data={getYieldChartData('cattle')}
                villageColorMapping={villageColorMapping}
                entity={'cattle'}
              />
            </div>
          </div>
          <DataSection
            title="Standard water requirement"
            unit="LPCD"
            data={gis.standard_cattle_water_requirement}
          />
          <DataSection
            title="Total water requirement"
            unit="LPD"
            data={gis.total_cattle_water_requirement}
          />
          <hr className={styles.sectionDivider} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '40%' }}>
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Total Poultry Population:
                </div>
                {Object.keys(gis.poultry_population).map((village) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '9px',
                        marginTop: '5%',
                        alignItems: 'center',
                      }}
                      key={village}
                    >
                      <div
                        style={{
                          width: '6.5px',
                          height: '15px',
                          backgroundColor: villageColorMapping[village],
                          marginRight: '6%',
                        }}
                      />
                      <div style={{ marginRight: '3%' }}>{village}</div>
                      {/* <div style={{ fontWeight: 700 }}>
                        {gis.poultry_population[village]}
                      </div> */}
                    </div>
                  );
                })}
              </div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Projected Poultry Population in 15 yrs:
                </div>
                {Object.keys(gis.poultry_population_in_next_15_years).map(
                  (village) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          fontSize: '9px',
                          marginTop: '5%',
                          alignItems: 'center',
                        }}
                        key={village}
                      >
                        <div
                          style={{
                            width: '6.5px',
                            height: '15px',
                            backgroundColor: villageColorMapping[village],
                            marginRight: '6%',
                          }}
                        />
                        <div style={{ marginRight: '3%' }}>{village}</div>
                        {/* <div style={{ fontWeight: 700 }}>
                          {gis.poultry_population_in_next_15_years[village]}
                        </div> */}
                      </div>
                    );
                  },
                )}
              </div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Projected Poultry Population in 30 yrs:
                </div>
                {Object.keys(gis.poultry_population_in_next_30_years).map(
                  (village) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          fontSize: '9px',
                          marginTop: '5%',
                          alignItems: 'center',
                        }}
                        key={village}
                      >
                        <div
                          style={{
                            width: '6.5px',
                            height: '15px',
                            backgroundColor: villageColorMapping[village],
                            marginRight: '6%',
                          }}
                        />
                        <div style={{ marginRight: '3%' }}>{village}</div>
                        {/* <div style={{ fontWeight: 700 }}>
                          {gis.poultry_population_in_next_30_years[village]}
                        </div> */}
                      </div>
                    );
                  },
                )}
              </div>
            </div>
            <div style={{ width: '60%', height: '35vh', marginTop: 'auto' }}>
              <YieldChart
                data={getYieldChartData('poultry')}
                villageColorMapping={villageColorMapping}
                entity={'poultry'}
              />
            </div>
          </div>
          <DataSection
            title="Standard water requirement"
            unit="LPCD"
            data={gis.standard_poultry_water_requirement}
          />
          <DataSection
            title="Total water requirement"
            unit="LPD"
            data={gis.total_poultry_water_requirement}
          />
          <div className={styles.title} style={{ margin: '10% auto' }}>
            Water Demand from Institutions
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '40%' }}>
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Total Served Population:
                </div>
                {Object.keys(gis?.served_population).map((village) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '9px',
                        marginTop: '5%',
                        alignItems: 'center',
                      }}
                      key={village}
                    >
                      <div
                        style={{
                          width: '6.5px',
                          height: '15px',
                          backgroundColor: villageColorMapping[village],
                          marginRight: '6%',
                        }}
                      />
                      <div style={{ marginRight: '3%' }}>{village}</div>
                      {/* <div style={{ fontWeight: 700 }}>
                        {gis?.served_population[village]}
                      </div> */}
                    </div>
                  );
                })}
              </div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Projected Served Population in 15 yrs:
                </div>
                {Object.keys(gis?.served_population_in_next_15_years).map(
                  (village) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          fontSize: '9px',
                          marginTop: '5%',
                          alignItems: 'center',
                        }}
                        key={village}
                      >
                        <div
                          style={{
                            width: '6.5px',
                            height: '15px',
                            backgroundColor: villageColorMapping[village],
                            marginRight: '6%',
                          }}
                        />
                        <div style={{ marginRight: '3%' }}>{village}</div>
                        {/* <div style={{ fontWeight: 700 }}>
                          {gis?.served_population_in_next_15_years[village]}
                        </div> */}
                      </div>
                    );
                  },
                )}
              </div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '10px' }}>
                <div style={{ marginBottom: '10%' }}>
                  Projected Served Population in 30 yrs:
                </div>
                {Object.keys(gis?.served_population_in_next_30_years).map(
                  (village) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          fontSize: '9px',
                          marginTop: '5%',
                          alignItems: 'center',
                        }}
                        key={village}
                      >
                        <div
                          style={{
                            width: '6.5px',
                            height: '15px',
                            backgroundColor: villageColorMapping[village],
                            marginRight: '6%',
                          }}
                        />
                        <div style={{ marginRight: '3%' }}>{village}</div>
                        {/* <div style={{ fontWeight: 700 }}>
                          {gis?.served_population_in_next_30_years[village]}
                        </div> */}
                      </div>
                    );
                  },
                )}
              </div>
            </div>
            <div style={{ width: '60%', height: '35vh', marginTop: 'auto' }}>
              <YieldChart
                data={getYieldChartData('served')}
                villageColorMapping={villageColorMapping}
                entity={'served'}
              />
            </div>
          </div>
          <DataSection
            title="Water supplied by distribution network"
            data={gis.water_supplied_by_network}
          />
        </div>
      </div>
    </div>
  );
};

export default PopulationDensity;
