import React from 'react';
import { PieChart, Pie, Cell, Label, ResponsiveContainer } from 'recharts';
import translator from '../../../assets/json/translator.json';
import { useContext } from 'react';
import { GeneralContext } from '../../../services/context/context';
import Styles from './Chart.module.css';
/**
 *
 * @description
 * To show label in the center of the pie chart
 */
function CustomLabel({ viewBox, value1, value2, color, message }) {
  const { cx, cy } = viewBox;
  return (
    <text
      x={cx}
      y={cy}
      fill="#3d405c"
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
    >
      <tspan
        alignmentBaseline="middle"
        fontSize={window.innerWidth < 480 ? '1.4vw' : '0.8vw'}
        fontWeight="bold"
        fill={color}
      >
        {value1} {'/'} {value2} {translator[message.language]['Filled']}
      </tspan>
    </text>
  );
}

/**
 *
 * @component SurfacewaterPieChart
 * @description
 * This component represents the pie chart on the right hand side of thr surfacewater
 * page
 */

const SurfacewaterPieChart = (props) => {
  const { language } = useContext(GeneralContext);
  let message = {
    language: language,
  };
  return props.total !== 0 ? (
    <div style={{ position: 'relative' }}>
      <ResponsiveContainer width="100%" height="80%">
        <PieChart width={85} height={85}>
          <Pie
            data={[
              {
                name: 'Unfilled ' + props.head,
                value: props.total - props.filled,
              },
              {
                name: 'Filled ' + props.head,
                value: props.filled,
              },
            ]}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            startAngle={90}
            endAngle={450}
            innerRadius="75%"
            outerRadius="95%"
            fill="#82ca9d"
          >
            <Cell key={`cell-1`} fill="#f3f3f3" />
            <Cell key={`cell-2`} fill={props.color} />
            <Label
              width={30}
              position="center"
              content={
                <CustomLabel
                  value1={props.filled}
                  value2={props.total}
                  color={props.color}
                  message={message}
                />
              }
            ></Label>
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          textAlign: 'center',
          fontWeight: 600,
        }}
        className={Styles.surfacewaterPieLegend}
      >
        {translator[language][props.head]}
      </div>
    </div>
  ) : (
    ''
  );
};
export default SurfacewaterPieChart;
