import { POINT_TYPES } from '../../../../shared/utils/tree';
import GroundwaterSource from './GW';
import styles from './index.module.css';
import PopulationDensity from './PD';
import SurfacewaterSource from './SW';
import WaterDistributionNetwork from './WDN';

const Point = ({ data }) => {
  return (
    <>
      {(() => {
        switch (data?.point_type) {
          case POINT_TYPES.WELL:
            return <GroundwaterSource data={data} type={data?.point_type} />;
          case POINT_TYPES.WATER_STRUCTURE:
            return <SurfacewaterSource data={data} type={data?.point_type} />;
          case POINT_TYPES.WTP:
          case POINT_TYPES.ESR:
          case POINT_TYPES.PDN:
          case POINT_TYPES.GWI:
            return (
              <WaterDistributionNetwork data={data} type={data?.point_type} />
            );
          case POINT_TYPES.INSTITUTION:
            return <PopulationDensity data={data} type={data?.point_type} />;
          default:
            return (
              <div className={styles.point}>
                Please select a marker on the map
              </div>
            );
        }
      })()}
    </>
  );
};

export default Point;
