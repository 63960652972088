import { useEffect, useState } from 'react';
import GroundwaterMap from '../components/Analytics/Groundwater/Map';
import GroundwaterPieChart from '../components/Analytics/Groundwater/Chart';
import GroundwaterPieLoader from '../components/Loader/hash';
import GroundwaterTable from '../components/Analytics/Groundwater/Table';
import { Get } from '../services/apiCall';
import { Link, useParams } from 'react-router-dom';
import translator from '../assets/json/translator.json';
import roundOff from '../services/round';
import Mixpanel from '../services/mixpanel.service';
import { useContext } from 'react';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../services/context/context';
import Styles from './AnalyticsGroundwater.module.css';
import getUnitValue from '../services/getUnitValueFunction';

/**
 *
 * @component Groundwater
 * @states
 * isLoading --> If true show animation
 * groundWaterLevel --> groundwater level in tcm for that village in that month
 * groundWaterRecharge --> groundwater recharge through water structure for that village in that month
 * @description
 * This component represents the groundwater stats page for the given village
 */

const Groundwater = () => {
  const { clusterId, villageId } = useParams();
  const { month, year, showSeason, handleSeason, handlePageHead } =
    useContext(TimelineContext);
  const {
    unmount,
    unmountNS,
    language,
    programId,
    selectedUnit,
    selectedVillageTankerValue,
  } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [groundWaterData, setGroundWaterData] = useState({
    groundWaterLevel: 0,
    groundWaterRecharge: 0,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    Mixpanel.time_event('AnalyticsGroundwaterPage');
  }, []);

  useEffect(() => {
    if (unmountNS) unmount(false);
    /**
     *
     * @reason
     * when ever this page is rendering we want month calendar on Navbar instead of
     * season calendar, therefore we call the prop function handleSeason(false) to
     * make the state showSeason false in App component
     */
    if (showSeason) handleSeason(false);
    /**
     *
     * @reason
     * when ever this page is rendered we have want to have that vilage_name : Groundwater
     * to be shown on the web dashboard navbar, to achieve this we call the prop function
     * handleNavbarHead and pass the name of the name of village and ': Groundwater' from the url
     * by the help of react router. As we have used path="/village/:id/:name/analytics/groundwater"
     * in react router, it will automatically pass variable id and name as a props.match.params
     * to this component
     */

    const initialFunction = async () => {
      setIsLoading(true);
      const params = {
        villageId: villageId,
        year: year,
        month: month + 1,
        programId: programId,
        clusterId: clusterId,
      };

      try {
        const response = await Get('/analytics/groundwater', token, params);
        const payload = response.data;

        if (payload) {
          setGroundWaterData({
            groundWaterLevel: payload.groundwater_level_tcm,
            groundWaterRecharge: payload.groundwater_recharge,
          });
        }
      } catch (err) {
        console.error(err);
      }

      setIsLoading(false);
    };

    initialFunction();

    return () => {
      Mixpanel.track('AnalyticsGroundwaterPage', {
        month: month + 1,
        year: year,
        villageId: villageId,
        programId: programId,
        clusterId: clusterId,
      });

      setGroundWaterData({
        groundWaterLevel: 0,
        groundWaterRecharge: 0,
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [villageId, month, year]);

  return (
    <>
      <div className={Styles.groundwater}>
        <div className={Styles.groundwaterNavbar}>
          <div className={Styles.groundwaterNavbarBack}>
            <Link
              onClick={() => handlePageHead('Analytics Overview')}
              to={{
                pathname: `/analytics-overview/programId/${programId}/clusterId/${clusterId}/villageId/${villageId}`,

                state: {
                  selectedMonth: month,
                  selectedYear: year,
                  pageHead: 'Analytics Overview',
                  language: language,
                },
              }}
              className={Styles.groundwaterNavbarBack}
            >
              &#60;&nbsp;&nbsp;&nbsp;&nbsp;
              {translator[language]['Back to Analytics']}
            </Link>
          </div>
          {/* <Download
            language={language}
            map={childMapRef.current}
          /> */}
        </div>
        <div className={Styles.groundwaterContent}>
          <GroundwaterMap />

          <div className={Styles.groundwaterContentRight}>
            <div className={Styles.groundwaterContentRightTop}>
              {!isLoading ? (
                <>
                  <div className={Styles.groundwaterContentRightTopHead}>
                    <div className={Styles.groundwaterContentRightTopHeadKey}>
                      {translator[language]['Total']}{' '}
                      {translator[language]['Groundwater']}:
                    </div>
                    <div
                      className={Styles.groundwaterContentRightTopHeadValue1}
                    >
                      {getUnitValue(
                        roundOff(groundWaterData.groundWaterLevel),
                        selectedUnit,
                        selectedVillageTankerValue,
                      )}{' '}
                      {translator[language][selectedUnit]}
                    </div>
                  </div>
                  <div className={Styles.groundwaterContentRightTopBottom}>
                    <div className={Styles.groundwaterPieChart}>
                      <GroundwaterPieChart
                        groundWaterLevel={getUnitValue(
                          roundOff(groundWaterData.groundWaterLevel),
                          selectedUnit,
                          selectedVillageTankerValue,
                        )}
                        groundWaterRecharge={getUnitValue(
                          roundOff(groundWaterData.groundWaterRecharge),
                          selectedUnit,
                          selectedVillageTankerValue,
                        )}
                      />
                    </div>
                    <div className={Styles.groundwaterContentLegend}>
                      <div className={Styles.groundwaterContentLegendTop}>
                        <div className={Styles.left1}></div>
                        <div
                          className={Styles.middle1}
                          style={{ width: '45%' }}
                        >
                          {
                            translator[language][
                              'Direct Recharge due to rainfall'
                            ]
                          }
                        </div>
                        <div className={Styles.right1}>
                          {getUnitValue(
                            roundOff(
                              groundWaterData.groundWaterLevel -
                                groundWaterData.groundWaterRecharge,
                            ),
                            selectedUnit,
                            selectedVillageTankerValue,
                          )}{' '}
                          {translator[language][selectedUnit]}
                        </div>
                      </div>
                      <div className={Styles.groundwaterContentLegendBottom}>
                        <div className={Styles.left2}></div>
                        <div
                          className={Styles.middle2}
                          style={{ width: '45%' }}
                        >
                          {
                            translator[language][
                              'Seepage through water structures'
                            ]
                          }
                        </div>
                        <div className={Styles.right2}>
                          {getUnitValue(
                            roundOff(groundWaterData.groundWaterRecharge),
                            selectedUnit,
                            selectedVillageTankerValue,
                          )}{' '}
                          {translator[language][selectedUnit]}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <GroundwaterPieLoader
                  style={{
                    position: 'relative',
                    marginTop: '18%',
                    marginLeft: '50%',
                  }}
                />
              )}
            </div>
            <div className={Styles.groundwaterContentRightBottom}>
              <GroundwaterTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Groundwater;
