const WaterStructure = {
  FARM_POND: 'Farm Pond',
  PERCOLATION_TANK: 'Percolation Tank',
  CHECK_DAM: 'Check Dam',
  VILLAGE_POND: 'Village Pond',
  MI_TANK: 'MI Tank',
  CNB: 'CNB',
  ENB: 'ENB',
  KT_WEIR: 'KT weir',
  NALA: 'Nala',
  LBS: 'LBS',
  GABION: 'Gabion',
  RECHARGE_SHAFT: 'Recharge shaft',
  RECHARGE_TRENCH: 'Recharge trench',
};

export default WaterStructure;
