import React from 'react';
import { GoTriangleLeft, GoTriangleRight } from 'react-icons/go';
import TalukaBarLoader from '../../Loader/propagate';
import $ from 'jquery';
import HomeStyles from './Bar.module.css';

/**
 *
 * @component Bar
 * @description
 * This component represent the navigation bar on the bottom box component of the Home
 * page. It has list of all the talukas under the program, which help us to navigate between
 * different taluka stats
 */

const Bar = (props) => {
  const bar = React.createRef();

  /**
   *
   * @description
   * animates the left and right arrow on the navbar
   */
  const handleRightArrow = () => {
    const width = bar.current.offsetWidth;
    $('#talukaBar').animate(
      { scrollLeft: '+=70'`+=${Math.abs(width)}vw` },
      1000,
    );
  };

  const handleLeftArrow = () => {
    const width = bar.current.offsetWidth;
    $('#talukaBar').animate({ scrollLeft: `-=${Math.abs(width)}vw` }, 1000);
  };
  /**
   *
   * @param {*} id - id of the active taluka
   * @returns styling for the active taluka
   */
  const styling = (id) => {
    if (id === props.activeTalukaId) return { color: '#3883E6' };
  };

  return (
    <>
      <div className={HomeStyles.talukaBarContainer}>
        <div className={HomeStyles.talukaTriangleLeftIcon}>
          <GoTriangleLeft
            onClick={() => {
              handleLeftArrow();
            }}
          />
        </div>
        <div ref={bar} className={HomeStyles.talukaBar} id="talukaBar">
          {!props.isLoading ? (
            props.talukas.map((taluka) => {
              return (
                <div
                  className={HomeStyles.talukaBarCell}
                  key={taluka.id}
                  style={styling(taluka.id)}
                  onClick={() => props.handleBarCell(taluka.id, taluka.name)}
                >
                  {taluka.name}
                </div>
              );
            })
          ) : (
            <TalukaBarLoader
              style={{
                height: '10px',
                marginTop: '2.5%',
                marginRight: '4%',
              }}
            />
          )}
        </div>
        <div className={HomeStyles.talukaTriangleRightIcon}>
          <GoTriangleRight
            onClick={() => {
              handleRightArrow();
            }}
          />
        </div>
      </div>
    </>
  );
};
export default Bar;
