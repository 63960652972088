import React, { useContext, useEffect, useState } from 'react';
import { BsBell, BsBellFill, BsFileEarmarkCheckFill } from 'react-icons/bs';
// import { BiX } from 'react-icons/bi';
import { Get } from '../../services/apiCall';
import Clip from '../Loader/clip';
import { AuthContext } from '../../services/context/context';
import Styles from './Notification.module.css'

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [isPdfOpening, setIsPdfOpening] = useState(false);
  const [openedNotificationId, setOpenedNotificationId] = useState(null);

  const { emailId, token } = useContext(AuthContext);

  const getNotifications = async (startTime, endTime) => {
    try {
      let params = {};
      if (startTime) {
        params = { startTime, endTime };
      } else {
        params = { endTime };
      }
      const response = await Get('/info/notification', token, params);

      const { data } = response;
      if (data) {
        let newNotifications = [];
        data.forEach((notification) => {
          const found = notifications.some((el) => el.id === notification.id);
          if (!found) newNotifications.push(notification);
        });
        if (newNotifications.length > 0) {
          const notificationsArr = [...notifications, ...newNotifications];
          notificationsArr.sort((x, y) => {
            return new Date(y.timestamp) - new Date(x.timestamp);
          });
          setNotifications(notificationsArr);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleClick = () => {
    setIsShown(!isShown);
  };

  const openPdf = async (id, url) => {
    setIsPdfOpening(true);
    setOpenedNotificationId(id);

    window.open(url);

    setIsPdfOpening(false);
    setOpenedNotificationId(null);
  };

  // const deleteNotification = async (email, id) => {
  //   const params = {
  //     email_id: email,
  //     notification_id: id,
  //   };

  //   await Patch('/info/notification', props.token, params);

  //   const notificationsArr = notifications.filter(
  //     (notification) => notification.id !== id,
  //   );

  //   setNotifications(notificationsArr);
  // };

  useEffect(() => {
    if (emailId) {
      getNotifications(null, new Date().toISOString());
      const currentIntervalId = setInterval(() => {
        const time = new Date();
        const startTime = new Date(time.getTime() - 10000).toISOString();
        const endTime = time.toISOString();
        getNotifications(startTime, endTime);
      }, 10000);
      setIntervalId(currentIntervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + ' years';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + ' months';
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + ' days';
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + ' hours';
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' minutes';
    }
    return Math.floor(seconds) + ' seconds';
  };

  return (
    <>
      {isShown ? (
        <BsBellFill className={Styles.bellIcon} onClick={handleClick} />
      ) : (
        <BsBell className={Styles.bellIcon} onClick={handleClick} />
      )}
      {notifications.length > 0 ? (
        <div className={Styles.notificationBadge} onClick={handleClick}>
          {notifications.length}
        </div>
      ) : (
        <></>
      )}
      {isShown ? (
        <div className={Styles.notificationTimeline}>
          {notifications.length === 0 ? (
            <div className={Styles.noNotificationMsg}>No new notifications</div>
          ) : (
            <div>
              <div className={Styles.notificationTimeHead}>Notifications</div>
              <hr className={Styles.notificationDivider} />
              {notifications.map((notification) => {
                return (
                  <div key={notification.id} className={Styles['notification']}>
                    <div className={Styles.notificationContent}>
                      <div className={Styles.notificationIcon}>
                        {isPdfOpening &&
                          openedNotificationId === notification.id ? (
                          <Clip />
                        ) : (
                          <BsFileEarmarkCheckFill />
                        )}
                      </div>
                      <div
                        className={
                          notification.type === 'pdfGeneration'
                            ? 'notificationMsg pdgGeneratorMsg'
                            : 'notificationMsg'
                        }
                        onClick={() =>
                          openPdf(notification.id, notification.url)
                        }
                      >
                        {notification.message}
                        <div className={Styles.notificationTime}>
                          {timeSince(new Date(notification.timestamp))} ago
                        </div>
                      </div>
                    </div>
                    {/* <BiX
                          className={Styles.deleteNotification}
                          onClick={() =>
                            deleteNotification(
                              props.email,
                              notification.id
                            )
                          }
                        /> */}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Notification;
