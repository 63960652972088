import React, { useState, useEffect } from 'react';
import Bar from './Bar';
import Taluka from './Taluka';
import { Get } from '../../../services/apiCall';
import translator from '../../../assets/json/translator.json';
import { useContext } from 'react';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';
import HomeStyles from './index.module.css';

/**
 *
 * @component TalukasUnderProgram
 * @states
 * talukas --> An array that stores all the talukas with their stats to show
 * activeTalukaId --> Stores the id of the taluka which is being shown on this component
 * activeTalukaName --> Stores the name of the taluka which is being shown on this component
 * @description
 * This component represents the bottom part of the Home page which has groundwater stage overview
 * of all the talukas that comes under that program head
 */

const TalukasUnderProgram = (props) => {
  const { token } = useContext(AuthContext);
  const { language, programId } = useContext(GeneralContext);
  const { month, year } = useContext(TimelineContext);

  const [isLoading, setIsLoading] = useState(true);
  const [talukas, setTalukas] = useState([]);
  const [activeTalukaId, setActiveTalukaId] = useState('');
  const [activeTalukaName, setActiveTalukaName] = useState('');

  useEffect(() => {
    setIsLoading(true);
    const initialFunction = async () => {
      const params = {
        language: language,
        programId: programId,
      };
      const talukaList = await Get(
        '/info/clusters-under-program',
        token,
        params,
      );
      if (talukaList.data && talukaList.data.length > 0) {
        let currentActiveTalukaId =
          activeTalukaId === '' ? talukaList.data[0].id : activeTalukaId;
        let currentActiveTalukaName =
          activeTalukaName === '' ? talukaList.data[0].name : activeTalukaName;

        setTalukas(talukaList.data);
        setActiveTalukaId(currentActiveTalukaId);
        setActiveTalukaName(currentActiveTalukaName);
      }

      setIsLoading(false);
    };

    initialFunction();

    return () => {
      setTalukas([]);
      setActiveTalukaId('');
      setActiveTalukaName('');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId, month, year, language]);

  /**
   *
   * @param {*} id - id of the taluka which we clicked on
   * @param {*} name - id of the taluka which we clicked on
   * @description
   * Changes the active taluka for which we have to show stats on this component
   */
  const handleBarCell = (id, name) => {
    setActiveTalukaId(id);
    setActiveTalukaName(name);
  };

  return (
    <>
      <div className={HomeStyles.homePageTalukaHead}>
        {translator[language]['Clusters under Program']}
      </div>
      <div className={HomeStyles.homeTalukaStats}>
        {!isLoading && (
          <>
            <Bar
              talukas={talukas}
              activeTalukaId={activeTalukaId}
              activeTalukaName={activeTalukaName}
              isLoading={isLoading}
              handleBarCell={handleBarCell}
            />
            <Taluka
              activeTalukaId={activeTalukaId}
              activeTalukaName={activeTalukaName}
            />
          </>
        )}
      </div>
    </>
  );
};
export default TalukasUnderProgram;
