import React from 'react';
import TalukaStatBoxLoader from '../../Loader/hash';
import roundOff from '../../../services/round';
// import "../../../css/Taluka.css";
import Styles from './StatBox.module.css';

/**
 *
 * @component TalukaStatBox
 * @description
 * This component represent the numbered stats box on the Taluka page
 * Area being managed, Water saved, Water structure monitored
 */

const TalukaStatBox = (props) => {
  return (
    <div>
      <div
        className={Styles['talukaProgramStatsBox']}
        style={{
          backgroundColor: '#fff',
          borderRadius: '10px',
          boxShadow: '0px 3px 6px #00000029',
        }}
      >
        <div className={Styles['talukaProgramStatsBoxHead']}>{props.head}</div>
        <div className={Styles['talukaProgramStatsBoxContent']}>
          {!props.isLoading ? (
            <>
              <span className={Styles['talukaProgramStatsBoxNumber']}>
                {roundOff(props.value)}
              </span>{' '}
              <span className={Styles['talukaProgramStatsBoxUnit']}>
                {props.unit}
              </span>
            </>
          ) : (
            <TalukaStatBoxLoader
              size={window.innerWidth < 480 ? 20 : 30}
              className={Styles.statBoxLoader}
              style={{
                position: 'relative',
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default TalukaStatBox;
