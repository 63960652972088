import styles from './index.module.css';

const DataRow = ({ village, value, unit = '', isBold = true }) => {
  const displayValue =
    typeof value === 'boolean' ? (value ? 'YES' : 'NO') : `${value} ${unit}`;

  return (
    <div className={styles.dataRow}>
      <div className={styles.sectionSpacer} />
      <div className={styles.dataRowVillage}>{village}</div>
      <div className={`${styles.dataRowValue} ${isBold && styles.bold}`}>
        {displayValue}
      </div>
    </div>
  );
};

const DataSection = ({ title, data, unit = '', isLastSection = false }) => (
  <>
    <div className={styles.sectionHeader}>
      <div className={styles.sectionIcon}>
        <svg
          width="21"
          height="24"
          viewBox="0 0 21 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.7123 15.735C9.78838 15.735 9.00168 15.4154 8.35218 14.7763C7.70268 14.1373 7.37793 13.3593 7.37793 12.4423C7.37793 12.0047 7.46302 11.5861 7.63321 11.1867C7.8034 10.7873 8.0448 10.4348 8.35739 10.1291L10.7123 7.81592L13.0671 10.1291C13.3797 10.4348 13.6211 10.7873 13.7913 11.1867C13.9615 11.5861 14.0466 12.0047 14.0466 12.4423C14.0466 13.3593 13.7219 14.1373 13.0724 14.7763C12.4229 15.4154 11.6362 15.735 10.7123 15.735Z"
            fill="#10CDE6"
          />
          <path
            d="M4.66667 23.5L0 18.9L4.66667 14.3L6.3 15.9675L4.49167 17.75H16.3333V13.15H18.6667V20.05H4.49167L6.3 21.8325L4.66667 23.5ZM2.33333 10.85V3.95H16.5083L14.7 2.1675L16.3333 0.5L21 5.1L16.3333 9.7L14.7 8.0325L16.5083 6.25H4.66667V10.85H2.33333Z"
            fill="#10CDE6"
          />
        </svg>
      </div>
      <div>{title}</div>
    </div>
    <div className={styles.sectionContent}>
      {data &&
        Object.keys(data).map((village) => (
          <DataRow
            key={village}
            village={village}
            value={data[village]}
            unit={unit}
          />
        ))}
    </div>
    {!isLastSection && <hr className={styles.sectionDivider} />}
  </>
);

const WaterDistributionNetwork = ({ gis }) => {
  return (
    <div>
      <div className={styles.header}>Water Distribution Network</div>
      <div className={styles.content}>
        <div className={styles.contentWrapper}>
          <div className={styles.title}>
            Piped Distribution Network to Households
          </div>
          <DataSection title="Daily water supply" data={gis.is_daily_supply} />
          <DataSection
            title="Number of hours water is supplied"
            data={gis.hours_of_supply_per_day}
            unit="Hrs."
          />
          <DataSection
            title="Water litres pumped into the village"
            data={gis.water_pumped_per_day_litres}
            unit="LPD"
          />
          <DataSection
            title="Actual water supplied per capita"
            data={gis.per_capita_supply_litres}
            unit="LPCD"
            isLastSection={true}
          />
        </div>
      </div>
    </div>
  );
};

export default WaterDistributionNetwork;
