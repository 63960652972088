import React, { useEffect, useState } from 'react';
import translator from '../assets/json/translator.json';
import AdvisoryPreview from '../components/Plan/AdvisoryPreview';
import GenericPlanNav from '../components/Plan/GenericPlanNav';
import { Get } from '../services/apiCall';
import LoginLoader from '../components/Loader/climb';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext, GeneralContext } from '../services/context/context';
import Styles from './SavedScenario.module.css';
import getUnitValue from '../services/getUnitValueFunction';
import QMarkHover from '../assets/images/QMarkHover.svg';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const SavedScenario = (props) => {
  const { villageId, clusterId } = useParams();

  const { programId, language, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [previewAdvisory, setPreviewAdvisory] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [scenarioData, setScenarioData] = useState([]);
  const [ownerData, setOwnerData] = useState([]);

  const [ownerName, setOwnerName] = useState('');

  const getScenarioById = async () => {
    const getData = await Get(
      `/simulation/scenario?id=${props.scenarioId}`,
      token,
    );
    if (getData.status === 'success') {
      setScenarioData(getData.data.data);
      setOwnerData(getData.data);
      setIsLoading(false);
    }

    const emailId = getData.data.creator.id;
    // const role = getData.data.creator.role;

    const role =
      getData.data.creator.role === 'cluster_coordinator'
        ? 'clusterCoordinator'
        : getData.data.creator.role === 'admin'
        ? 'admin'
        : getData.data.creator.role === 'field_facilitator'
        ? 'fieldFacilitator'
        : null;

    const getNameOfScenarioUser = await Get(
      `/info/user/search?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&role=${role}&term=${emailId}`,
      token,
    );

    if (getNameOfScenarioUser.status === 'success') {
      setOwnerName(getNameOfScenarioUser.data[0]?.name);
    }
  };

  useEffect(() => {
    getScenarioById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
          }}
        >
          <LoginLoader />
        </div>
      ) : (
        <>
          {previewAdvisory ? (
            <AdvisoryPreview
              isFromSavedScenario={true}
              setCurrentCrop={props.setCurrentCrop}
              setPreviewAdvisory={setPreviewAdvisory}
              scenarioData={scenarioData}
              scenarioId={props.scenarioId}
              season={props.season}
              globalPublishedScenarioId={props.globalPublishedScenarioId}
              checkPlanIsOpen={props.checkPlanIsOpen}
              ownerData={ownerData}
            />
          ) : (
            <div className={Styles['savedScenarioWrapper']}>
              <GenericPlanNav
                setCurrentCrop={props.setCurrentCrop}
                setPreviewAdvisory={setPreviewAdvisory}
                setRenderScenarioSummary={props.setRenderScenarioSummary}
                ownerData={ownerData}
                scenarioId={props.scenarioId}
                callBaseConditionForEdit={props.callBaseConditionForEdit}
                season={props.season}
                isUpdateModeActiveScenarioType={
                  props.isUpdateModeActiveScenarioType
                }
                ownerName={ownerName}
                checkPlanIsOpen={props.checkPlanIsOpen}
                scenarioData={scenarioData}
                // FILTER ARRAYS
                scenarios={props.scenarios}
                setScenarios={props.setScenarios}
                trackFilteredValues={props.trackFilteredValues}
                setTrackFilteredValues={props.setTrackFilteredValues}
                referAnotherArray={props.referAnotherArray}
                setReferAnotherArray={props.setReferAnotherArray}
                currentCountOfOwnPlansSaved={props.currentCountOfOwnPlansSaved}
                setCurrentCountOfOwnPlansSaved={
                  props.setCurrentCountOfOwnPlansSaved
                }
                duplicateArray={props.duplicateArray}
                setDuplicateArray={props.setDuplicateArray}
              />

              <>
                <div className={Styles['grid-container']}>
                  <div className={`${Styles['item']} ${Styles['item1']}`}>
                    <div
                      className={Styles['item-one-div']}
                      style={{
                        height: '100%',
                      }}
                    >
                      <div
                        className={Styles['first-box']}
                        style={{
                          display: 'flex',
                          height: '45%',
                        }}
                      >
                        <div
                          style={{
                            width: '50%',
                            background: '#D1D1D1',
                            padding: '18px 0 0 12px',
                            borderTopLeftRadius: '16px',
                          }}
                        >
                          <div
                            className={Styles['first-box-demand']}
                            style={{ marginBottom: '5px' }}
                          >
                            {translator[language]['Demand']}
                          </div>
                          <div
                            className={Styles['first-box-demand-value']}
                            style={{ fontWeight: 'bold' }}
                          >
                            {getUnitValue(
                              scenarioData.waterDemand,
                              selectedUnit,
                              selectedVillageTankerValue,
                            )}{' '}
                            {translator[language][selectedUnit]}
                          </div>
                        </div>
                        <div
                          style={{
                            width: '50%',
                            background: '#C1D8F7',
                            padding: '18px 0 0 12px',
                            borderTopRightRadius: '16px',
                          }}
                        >
                          <div
                            className={Styles['first-box-available']}
                            style={{ marginBottom: '5px' }}
                          >
                            {translator[language]['Available']}
                          </div>
                          <div
                            className={Styles['first-box-available-value']}
                            style={{ fontWeight: 'bold' }}
                          >
                            {getUnitValue(
                              scenarioData.waterAvailable.toFixed(2),
                              selectedUnit,
                              selectedVillageTankerValue,
                            )}{' '}
                            {translator[language][selectedUnit]}
                          </div>
                        </div>
                      </div>
                      {/* ENDS  */}

                      <div
                        style={{
                          marginTop: '',
                          height: '55%',
                          background:
                            scenarioData.waterBalance > 0
                              ? '#B1E9BA'
                              : 'rgba(216, 54, 54, 0.3)',
                          borderBottomLeftRadius: '16px',
                          borderBottomRightRadius: '16px',
                        }}
                      >
                        <div
                          className={Styles['first-box-balance']}
                          style={{
                            padding: '12px 0 0 12px',
                            fontWeight: '500',
                          }}
                        >
                          {translator[language]['Water Balance']}
                        </div>
                        <div
                          className={Styles['first-box-balance-text']}
                          style={{ padding: '0px 0 0 12px' }}
                        >
                          ({translator[language]['Availability - Demand']})
                        </div>

                        <div
                          className={Styles['first-box-balance-value']}
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginTop: '10px',

                            color:
                              scenarioData.waterBalance > 0
                                ? '#12CB30'
                                : 'rgb(216, 54, 54)',
                          }}
                        >
                          {getUnitValue(
                            scenarioData.waterBalance,
                            selectedUnit,
                            selectedVillageTankerValue,
                          )}{' '}
                          {translator[language][selectedUnit]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${Styles['item']} ${Styles['item2']}`}>
                    <div className={Styles.groundwaterLastText}>
                      {translator[language]['With the current situation']}
                      <br />
                      {translator[language]['Groundwater will last']}:
                    </div>
                    <div
                      className={Styles['months-remaining']}
                      style={{ fontWeight: 'bold' }}
                    >
                      {Math.round(scenarioData.groundwaterLastDuration).toFixed(
                        1,
                      )}{' '}
                      {translator[language]['Months']}
                    </div>
                  </div>
                  <div className={`${Styles['item']} ${Styles['item3']}`}>
                    <div
                      className={Styles.totalWaterSavedText}
                      style={{ marginBottom: '5px' }}
                    >
                      {translator[language]['Total Water Saved']}
                    </div>
                    <div
                      className={Styles['total-water-saved']}
                      style={{ fontWeight: 'bold' }}
                    >
                      {getUnitValue(
                        scenarioData.totalWaterSaved,
                        selectedUnit,
                        selectedVillageTankerValue,
                      )}{' '}
                      {translator[language][selectedUnit]}
                    </div>
                  </div>
                  <div className={`${Styles['item']} ${Styles['item4']}`}>
                    <div className={Styles['supply-advisory-box']}>
                      <div
                        className={Styles.supplySideHeading}
                        style={{ fontWeight: 'bold' }}
                      >
                        {translator[language]['Supply Side']}
                      </div>

                      <div className={Styles.supplySideSavedScenarioBox}>
                        <div>
                          {translator[language]['Water Available']}
                          <br />
                          {translator[language]['After Monsoon']} : &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          <span style={{ fontWeight: 'bold' }}>
                            {getUnitValue(
                              scenarioData.staticCurrentWaterAvailable.toFixed(
                                2,
                              ),
                              selectedUnit,
                              selectedVillageTankerValue,
                            )}{' '}
                            {translator[language][selectedUnit]}{' '}
                          </span>{' '}
                        </div>

                        <div style={{ marginTop: '35px' }}>
                          {translator[language]['Runoff Available']} : &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          <span style={{ fontWeight: 'bold' }}>
                            {getUnitValue(
                              scenarioData.runoffAvailable.toFixed(2),
                              selectedUnit,
                              selectedVillageTankerValue,
                            )}{' '}
                            {translator[language][selectedUnit]}{' '}
                          </span>
                        </div>

                        <div className={Styles.percIncreaseWater}>
                          {translator[language]['% Increase in Water']}: &nbsp;
                          &nbsp; &nbsp;{' '}
                          <span
                            style={{ color: '#3883E6', fontWeight: 'bold' }}
                          >
                            {scenarioData.supplyCurrentRange}{' '}
                          </span>
                          %{' '}
                        </div>

                        <div
                          style={{
                            marginTop: '35px',
                            borderBottom: '1px solid rgb(216, 216, 216)',
                            paddingBottom: '40px',
                          }}
                        >
                          {translator[language]['Water Made Available']}: &nbsp;
                          &nbsp;
                          <span style={{ fontWeight: 'bold' }}>
                            <span style={{ color: '#3883E6' }}>
                              {getUnitValue(
                                scenarioData.waterMadeAvailable,
                                selectedUnit,
                                selectedVillageTankerValue,
                              )}{' '}
                            </span>
                            {''}
                            {translator[language][selectedUnit]}{' '}
                          </span>
                        </div>

                        <div className={Styles['runoff-to-be-arrested']}>
                          {translator[language]['Runoff to be arrested']}:
                          &nbsp; &nbsp;
                          <span style={{ fontWeight: 'bold' }}>
                            <span style={{ color: '#3883E6' }}>
                              {getUnitValue(
                                scenarioData.runoffToBeArrested.toFixed(2),
                                selectedUnit,
                                selectedVillageTankerValue,
                              )}
                              {''}
                            </span>
                            {''}
                            &nbsp; {translator[language][selectedUnit]}
                            {''}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`${Styles['item']} ${Styles['item5']}`}>
                    {/* COPIED STYLING OF RIGHT SIDE STARTS ------------------------------------   */}
                    <div className={Styles['third-item-three']}>
                      <div
                        className={Styles['demand-side-heading']}
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {translator[language]['Demand Side']}
                      </div>
                      <div className={Styles['crop-list-heading']}>
                        {translator[language]['Crop List']}:
                      </div>
                      <div
                        className={Styles['order-most-least-heading']}
                        style={{ marginBottom: '15px' }}
                      >
                        (
                        {
                          translator[language][
                            'In order of MOST -> LEAST water intensive'
                          ]
                        }
                        )
                      </div>
                      <div
                        className={Styles['third-less-padding']}
                        style={{ padding: '0 10px' }}
                      >
                        <div
                          className={Styles['less-padding-top-left']}
                          style={{
                            width: '40.33%',
                          }}
                        >
                          <p style={{ fontWeight: '600' }}>
                            {translator[language]['Crop Name']}
                          </p>
                        </div>

                        <div
                          className={Styles['less-padding-top-right']}
                          style={{
                            //  background: "red",
                            width: '33.33%',
                            display: 'unset',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              // justifyContent: 'flex-end',
                            }}
                          >
                            <p style={{ fontWeight: '600' }}>MI</p>
                            <img
                              data-tooltip-id="my-tooltip-2"
                              onClick={() => navigator.vibrate(100)}
                              src={QMarkHover}
                              height="12px"
                              width="12px"
                              alt="tooltip"
                              style={{
                                cursor: 'pointer',
                                marginLeft: '3px',
                                // marginTop: '3px',
                              }}
                            />
                          </div>
                        </div>

                        <div
                          className={Styles['top-rightest']}
                          style={
                            {
                              //  background: "red",
                              // width: "33.33%",
                            }
                          }
                        >
                          <p style={{ fontWeight: '600' }}>
                            {translator[language]['Sown Area']}
                          </p>
                        </div>
                      </div>

                      {/* LOOPING THROUGH RANGE BAR */}
                      <div
                        style={{
                          // maxHeight: "350px",
                          maxHeight: '300px',
                          overflowY: 'scroll',
                          padding: '2px 10px',
                        }}
                      >
                        {scenarioData.crops.map((elem, index) => {
                          return (
                            <div
                              key={index}
                              className={Styles['bar-div-container']}
                              style={{ marginTop: '22px' }}
                            >
                              <div
                                className={Styles['rsuitebar-header-div']}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  position: 'relative',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    background: '',
                                    width: '41.33%',
                                    position: 'relative',
                                  }}
                                >
                                  <img
                                    // src={`https://assets.ekatvam-midas.com/crops/icons-v2/${elem.id}.svg`}
                                    src={`https://assets.ekatvam-midas.com/crops/icons-contrast/${elem.id}.svg`}
                                    alt="crop images"
                                    height={22}
                                    width={22}
                                    style={{ marginRight: '7px' }}
                                  />
                                  <div
                                    className={Styles['crop-name-mapped']}
                                    style={{ marginLeft: '3px' }}
                                  >
                                    {elem.name}
                                  </div>

                                  <div
                                    className={Styles['mapped-absolute-drip']}
                                    style={{
                                      position: 'absolute',
                                      left: '35px',
                                      top: '35px',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        // justifyContent: 'flex-end',
                                      }}
                                    >
                                      MI{' '}
                                      <img
                                        data-tooltip-id="my-tooltip-2"
                                        onClick={() => navigator.vibrate(100)}
                                        src={QMarkHover}
                                        height="12px"
                                        width="12px"
                                        alt="tooltip"
                                        style={{
                                          cursor: 'pointer',
                                          marginLeft: '3px',
                                          marginRight: '3px',
                                          // marginTop: '3px',
                                        }}
                                      />{' '}
                                      -{' '}
                                      <span
                                        className={Styles.dripPercentage}
                                        style={{
                                          color: '#3883E6',
                                        }}
                                      >
                                        {(
                                          (elem.drip_area / elem.crop_area) *
                                          100
                                        ).toFixed(0)}
                                      </span>{' '}
                                      %
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={Styles['twelve']}
                                  style={{ background: '', width: '33.33%' }}
                                >
                                  <p>
                                    {' '}
                                    <span
                                      style={{
                                        color: 'rgba(56, 131, 230, 1',
                                        marginRight: '10px',
                                      }}
                                    >
                                      {elem.drip_area.toFixed(2)}
                                    </span>
                                    {''}
                                    {translator[language]['Ha']}
                                  </p>
                                </div>
                                <div className={Styles['twelve-two']}>
                                  <p>
                                    {' '}
                                    <span
                                      style={{
                                        marginRight: '10px',
                                      }}
                                    >
                                      {elem.crop_area?.toFixed(2)}
                                    </span>
                                    {''}
                                    {translator[language]['Ha']}
                                  </p>
                                </div>{' '}
                              </div>

                              <div className={Styles['bar-div']}>
                                <div
                                  className={Styles['bar-div-suitebar']}
                                ></div>
                                <div className={Styles['right-two-ones']}></div>
                                {/* EDNS  */}

                                <div
                                  className={Styles['right-two-ones-rightest']}
                                ></div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{ borderTop: '1px solid rgba(216,216,216,1)' }}
                      ></div>
                      {/* LOOPING THROUGH RANGE BAR       */}

                      <div
                        className={Styles['bottom-last']}
                        style={{
                          display: 'flex',
                          marginTop: '20px',
                          marginRight: '15px',
                          marginLeft: '15px',
                        }}
                      >
                        <div
                          style={{ width: '65%' }}
                          className={Styles['total-stat']}
                        >
                          <p
                            style={{
                              width: '00px',
                              textAlign: 'right',
                            }}
                          >
                            {translator[language]['Total']}
                          </p>
                        </div>
                        <div
                          className={Styles['total-values-bottom']}
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'bold',
                              width: '48%',
                            }}
                          >
                            <span
                              style={{
                                color: 'rgba(56, 131, 230, 1',
                                marginRight: '10px',
                              }}
                            >
                              {scenarioData.crops
                                .reduce((initialValue, val) => {
                                  return initialValue + val.drip_area;
                                }, 0)
                                .toFixed(2)}
                              {/* ---- */}
                            </span>
                            {translator[language]['Ha']}
                          </p>
                          <p
                            style={{
                              fontWeight: 'bold',
                              width: '50%',
                              textAlign: 'center',
                            }}
                          >
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              {scenarioData.crops
                                .reduce((initialValue, val) => {
                                  return initialValue + val.crop_area;
                                }, 0)
                                .toFixed(2)}
                              {/* ---- */}
                            </span>
                            {translator[language]['Ha']}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* COPIED STYLING OF RIGHT SIDE ENDS  ------------------------------------    */}
                  </div>
                </div>
              </>
            </div>
          )}
        </>
      )}

      <ReactTooltip
        id="my-tooltip-2"
        place="top"
        style={{
          background: 'black',
          color: 'white',
          fontSize: window.innerWidth < 768 ? '10px' : '12px',
        }}
        content={'Micro Irrigation'}
      />
    </>
  );
};

export default SavedScenario;
