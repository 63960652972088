import React, { useContext, useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
} from 'recharts';
import Loader from '../../Loader/ring';
import { Get } from '../../../services/apiCall';
import translator from '../../../assets/json/translator.json';
import { Link, useParams } from 'react-router-dom';
import roundOff from '../../../services/round';
import { VscTriangleDown, VscTriangleUp } from 'react-icons/vsc';
import months from '../../../shared/constants/months';
import { sortDatesByYearAndMonth } from '../../../services/genericSortMonthYear';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';
import Styles from './GroundwaterAlert.module.css';
import getUnitValue from '../../../services/getUnitValueFunction';
import useProcessedChartData from '../../../services/hooks/useProcessedChartData';

const CustomTooltip = ({ active, payload, message, selectedUnit }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          boxShadow: '0px 3px 6px #00000029',
          width: '130%',
          padding: '5%',
        }}
      >
        {payload.length > 0 && payload[0].payload.name ? (
          <p
            className="label"
            style={{ margin: '5%', fontWeight: '600' }}
          >{`${payload[0].payload.name}`}</p>
        ) : (
          <></>
        )}
        {payload.length > 0 && payload[0].payload.Value ? (
          <p className="label" style={{ margin: '5%', color: '#5e97f1' }}>{`${
            translator[message.language]['Value']
          } : ${roundOff(payload[0].payload.Value)} ${
            translator[message.language][selectedUnit]
          }`}</p>
        ) : (
          <p className="label" style={{ margin: '5%', color: '#5e97f1' }}>{`${
            translator[message.language]['Value']
          } : 0 ${translator[message.language][selectedUnit]}`}</p>
        )}
      </div>
    );
  }

  return null;
};

/**
 *
 * @component GroundwaterAlert
 * @states
 * isLoading --> If true show animation
 * previousGWLevel --> Stores the groundwater level of the previous month
 * currentGWLevel --> Stores the groundwater level of the current month
 * @description
 * This component represents the expanded version of the AlertHeader on the Analytic Overview
 * page and it contains an overview of the Groundwater data for a particular village
 */

const GroundwaterAlert = (props) => {
  const { villageId, clusterId } = useParams();
  const { month, year, pageHead, handlePageHead } = useContext(TimelineContext);
  const { language, programId, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [previousGWLevel, setPreviousGWLevel] = useState(0);
  const [currentGWLevel, setCurrentGWLevel] = useState(0);
  const [data, setData] = useState([]);

  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    const initialFunction = async () => {
      setIsLoading(true);
      const params = {
        villageId: villageId,
        month: month + 1,
        year: year,
        programId: programId,
        clusterId: clusterId,
      };
      const response = await Get('/datapoint/groundwater', token, params);
      let payload = response.data;

      const sortedData = sortDatesByYearAndMonth(payload);
      payload = sortedData;

      const finalData = [];
      if (payload && payload.length > 0) {
        payload.sort((a, b) => {
          if (a.year === b.year) return a.month - b.month;
          return a.year - b.year;
        });
        payload.map((d) => {
          return finalData.push({
            name: `${months[d.month - 1].substring(0, 3)} ${String(
              d.year,
            ).slice(-2)}`,
            Value: d.groundwater_level_tcm ? d.groundwater_level_tcm : 0,
          });
        });
        if (payload.length >= 2) {
          setCurrentGWLevel(
            payload[payload.length - 1].groundwater_level_tcm
              ? payload[payload.length - 1].groundwater_level_tcm
              : 0,
          );

          setPreviousGWLevel(
            payload[payload.length - 2].groundwater_level_tcm
              ? payload[payload.length - 2].groundwater_level_tcm
              : 0,
          );
        } else {
          setCurrentGWLevel(
            payload[payload.length - 1].groundwater_level_tcm
              ? payload[payload.length - 1].groundwater_level_tcm
              : 0,
          );
        }

        setData(finalData);
      }

      // new
      const params2 = {
        villageId: villageId,
        programId: programId,
        clusterId: clusterId,
      };
      let avg_well_depth;
      const response2 = await Get('/info/wells', token, params2);
      const payload2 = response2.data;
      if (payload2 && payload2.length > 0) {
        // setWells(payload2);
        let depthTotal = payload2?.reduce((initial, value) => {
          return initial + value?.depth;
        }, 0);

        avg_well_depth = depthTotal / payload2?.length;

        // call
        const paramsGw = {
          villageId: villageId,
          month: month + 1,
          year: year,
          language: language,
          programId: programId,
          clusterId: clusterId,
        };
        const response = await Get(
          '/analytics/village-detailed',
          token,
          paramsGw,
        );

        if (response && response.data) {
          let specific_yield = response.data.specific_yield;
          let area = response.data.area;

          const finalValue = avg_well_depth * specific_yield * area * 10;

          const finalMax = Number(roundOff(finalValue + 0.1));
          setMaxValue(finalMax);
        }
        // call
      }

      setIsLoading(false);
    };

    initialFunction();

    return () => {
      setData([]);
      setCurrentGWLevel(0);
      setPreviousGWLevel(0);
      setMaxValue(0);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, pageHead, villageId, programId, clusterId, token]);

  /**
   *
   * @returns different styling based on the difference between previous and
   * current groundwater level
   */
  const colorGWPrecentage = () => {
    if (previousGWLevel === 0) return { color: '#b1b0b7' };
    let val = ((currentGWLevel - previousGWLevel) / previousGWLevel) * 100;
    if (val > 0) return { color: '#12cb30' };
    if (val < 0) return { color: '#ec561b' };
    return { color: '#b1b0b7' };
  };
  /**
   *
   * @returns the percentage of current groundwater level with respect to the
   * previous groundwater level
   */
  const calculateGWPercentage = () => {
    if (previousGWLevel === 0) return 0.0;
    return ((currentGWLevel - previousGWLevel) / previousGWLevel) * 100;
  };

  let message = {
    language: language,
  };

  let chartValues = JSON.stringify(['Value']);

  const processedData = useProcessedChartData(
    data,
    selectedUnit,
    selectedVillageTankerValue,
    month,
    year,
    villageId,
    chartValues,
  );

  const getColorBasedOnPercentage = (value, maxValue) => {
    const finalMaxValue = getUnitValue(
      maxValue,
      selectedUnit,
      selectedVillageTankerValue,
    );
    const percentage = (value / finalMaxValue) * 100;

    if (percentage <= 30) {
      return 'url(#redGradient)';
    } else if (percentage <= 60) {
      return 'url(#orangeGradient)';
    } else {
      return 'url(#greenGradient)';
    }
  };

  return (
    <div
      className={`groundAlertContent ${Styles.groundAlertContentParent}`}
      style={{ marginTop: '10px' }}
    >
      <div className={Styles.order2}>
        <div
          className={Styles.groundAlertContentKey}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className={Styles.dotStyling}></div>
          <div className={Styles.totalGroundwater}>
            {translator[language]['Total']}{' '}
            {translator[language]['Groundwater']}:
          </div>
          {/* <br /> */}
        </div>
        <div className={Styles.groundAlertContentValue}>
          {getUnitValue(
            props.totalGroundWater,
            selectedUnit,
            selectedVillageTankerValue,
          )}{' '}
          {translator[language][selectedUnit]}
          {/* Adding the percentage here  */}
          <span style={colorGWPrecentage()}>
            {calculateGWPercentage() >= 0 ? (
              calculateGWPercentage() > 0 ? (
                <VscTriangleUp
                  style={{
                    marginRight: '4px',
                    fontSize: '0.8rem',
                    marginLeft: '8px',
                  }}
                />
              ) : (
                <></>
              )
            ) : (
              <VscTriangleDown
                style={{
                  marginRight: '4px',
                  marginLeft: '8px',
                  fontSize: '0.8rem',
                }}
              />
            )}
            <span style={{ fontSize: '12px' }}>
              {roundOff(Math.abs(calculateGWPercentage()))} %
            </span>
          </span>
          {/*  Adding the percentage here */}
        </div>
      </div>

      {/* NEW ONE -> Adding Soil Moisture */}

      <div className={Styles.groundAlertStatus}>
        <div className={Styles.groundAlertStatusHead}>
          {/* adding absolute values here  starts*/}
          <div
            className={`${Styles.groundAlertContentKey} ${Styles.groundAlertContentKeyOne}`}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div className={Styles.groundwaterLevel}>
              {translator[language]['Your groundwater level has']}{' '}
              <span style={{ fontWeight: 'bold' }}>
                {calculateGWPercentage() > 0
                  ? translator[language]['Increased']
                  : translator[language]['Reduced']}
              </span>{' '}
              {translator[language]['compared to Previous month.']}
            </div>
            <br />
          </div>

          <div className={Styles['yield-heading']}>
            {translator[language]['Specific Yield']}:
            <br />
            <span
              className={Styles['yield-unit']}
              style={{
                marginTop: '3%',
                fontWeight: 'bold',
                display: 'inline-block',
              }}
            >
              {props.specific_yield || 'NA'}
            </span>
          </div>
          {/* adding absolute values here ends */}

          <Link
            onClick={() => handlePageHead('Analytics Groundwater')}
            to={`/analytics-groundwater/programId/${programId}/clusterId/${clusterId}/villageId/${villageId}`}
            className={Styles.groundwaterAlertButton}
          >
            {translator[language]['Full report']}
            {' >'}
          </Link>
        </div>
        <div className={Styles.groundAlertStatusGraph}>
          {!isLoading ? (
            <ResponsiveContainer
              width={window.innerWidth < 768 ? '95%' : '98%'}
              height="90%"
            >
              <BarChart
                width={250}
                height={150}
                barSize={30}
                data={processedData}
                margin={{ top: -8 }}
                className={Styles.groundAlertStatusGraphContent}
              >
                <defs>
                  <linearGradient
                    id="redGradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: '#992626', stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{
                        stopColor: 'rgba(237, 163, 163, 0.48)',
                        stopOpacity: 0,
                      }}
                    />
                  </linearGradient>
                  <linearGradient
                    id="orangeGradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: '#B5911A', stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{
                        stopColor: 'rgba(255, 232, 154, 0.48)',
                        stopOpacity: 0,
                      }}
                    />
                  </linearGradient>
                  <linearGradient
                    id="greenGradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: '#057137', stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{
                        stopColor: 'rgba(141, 211, 174, 0.48)',
                        stopOpacity: 0,
                      }}
                    />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="name"
                  tick={{ fontSize: 9, fill: '#707070' }}
                  orientation="top"
                />
                <YAxis
                  dataKey="Value"
                  domain={[
                    0,
                    (dataMax) =>
                      getUnitValue(
                        maxValue,
                        selectedUnit,
                        selectedVillageTankerValue,
                      )
                        ? getUnitValue(
                            maxValue,
                            selectedUnit,
                            selectedVillageTankerValue,
                          )
                        : (dataMax * 1.5).toFixed(2),
                  ]}
                  tick={{ fontSize: 9, fill: '#707070' }}
                  label={{
                    value: translator[language][selectedUnit],
                    angle: -90,
                    position: 'insideLeft',
                    // dx: 20,
                    dx: 5,
                    dy: 15,
                    scaleToFit: true,
                    fontSize: 10,
                    fill: '#707070',
                  }}
                />
                <Tooltip
                  wrapperStyle={{ fontSize: '12px' }}
                  formatter={(value) =>
                    new Intl.NumberFormat('en').format(value) +
                    ' ' +
                    translator[language][selectedUnit]
                  }
                  content={
                    <CustomTooltip
                      message={message}
                      selectedUnit={selectedUnit}
                    />
                  }
                />

                <Bar
                  dataKey="Value"
                  background={{ fill: '#EFDFC0' }}
                  // radius={[15, 15, 0, 0]}
                >
                  {processedData.map((d, index) => (
                    <Cell
                      key={d.name}
                      fill={getColorBasedOnPercentage(d.Value, maxValue)}
                    />
                  ))}
                </Bar>

                {/* <Bar dataKey="Value" fill="blue" background={{ fill: '#eee' }}>
                  {data.map((d) => {
                    if (
                      `${months[month].substring(0, 3)} ${String(year).slice(
                        -2,
                      )}` === d.name
                    )
                      return <Cell key={d.name} fill={'#5e97f1'} />;
                    return <Cell key={d.name} fill={'#9A9A9A'} />;
                  })}
                </Bar> */}
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Loader
              style={{
                position: 'relative',
                top: '50%',
                left: '50%',
              }}
            />
          )}
        </div>
      </div>

      <div className={Styles.groundwaterAlertButtonMobileParentDiv}>
        <Link
          onClick={() => handlePageHead('Analytics Groundwater')}
          to={`/analytics-groundwater/programId/${programId}/clusterId/${clusterId}/villageId/${villageId}`}
          className={` ${Styles.groundwaterAlertButtonMobile}`}
        >
          {translator[language]['Full report']}
          {' >'}
        </Link>
      </div>
    </div>
  );
};
export default GroundwaterAlert;
