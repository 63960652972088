import { PropagateLoader } from 'react-spinners';

const index = (props) => {
  return (
    <div style={props.style}>
      <PropagateLoader color={'#a7b9ed'} size={10} />
    </div>
  );
};

export default index;
