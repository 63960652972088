import { RingLoader } from 'react-spinners';

const ring = (props) => {
  return (
    <div style={props.style}>
      <RingLoader color={'#a7b9ed'} size={25} />
    </div>
  );
};

export default ring;
