import React, { useContext, useEffect } from 'react';
import PlanChart from './PlanChart';
import grey from '../../assets/images/Grey.svg';
import red from '../../assets/images/Red.svg';
import blue from '../../assets/images/Blue.svg';
import green from '../../assets/images/Green.svg';
import translator from '../../assets/json/translator.json';
import { GeneralContext } from '../../services/context/context';
import Styles from './LeftSide.module.css';
import getUnitValue from '../../services/getUnitValueFunction';

const LeftSide = (props) => {
  const { language, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);

  const {
    currentDemand,
    setCurrentDemand,
    currentWaterAvailable,
    setCurrentWaterAvailable,
    currentWaterBalance,
    setCurrentWaterBalance,
    totalMonthsRemaining,
    setTotalMonthsRemaining,
    totalWaterSaved,
    setTotalWaterSaved,
    predefinedCropsDetail,
    checkRunoffAvailable,

    firstRenderTotalCwr,
    staticCurrentWaterAvailable,
  } = props;

  // SETTING VARIABLES COMING FROM THE BACKEND

  let cultivation_land_evaporation =
    predefinedCropsDetail && predefinedCropsDetail.cultivation_land_evaporation;

  let total_domestic_requirement =
    predefinedCropsDetail && predefinedCropsDetail.total_domestic_requirement;

  //   // --------------------------SUPPLY FUNCTIONS STARTS ---------------------------

  useEffect(() => {
    let increase_in_water_available =
      // (predefinedCropsDetail.current_rainfall_tcm_cumulative *
      (staticCurrentWaterAvailable * props.supplyCurrentRange) / 100;

    // NEW
    let final_water_availablity_changes =
      // predefinedCropsDetail.current_rainfall_tcm_cumulative +
      staticCurrentWaterAvailable + increase_in_water_available;
    // NEW

    // -----------------------------------------------
    // Maximum limit of increase_in_water_available= run-off available/(supply efficiency +1)
    const max_limit_of_increase_in_water_available =
      checkRunoffAvailable / (predefinedCropsDetail.supply_efficiency + 1);
    const get_maximum_percentage =
      (max_limit_of_increase_in_water_available / staticCurrentWaterAvailable) *
      100;

    props.setMaxPercentageOfSupply(Math.floor(get_maximum_percentage));

    // Now to determine the maximum percentage:
    // (Increase in water available/total water available ) * 100
    // const maximum_percentage =

    // ----------------------------------------------------

    if (
      increase_in_water_available *
        (predefinedCropsDetail.supply_efficiency + 1) >
      checkRunoffAvailable
    ) {
      props.setIsRunoffAvailable(false);
    } else {
      props.setIsRunoffAvailable(true);

      setCurrentWaterAvailable(final_water_availablity_changes);

      props.setWaterMadeAvailable(
        (
          (staticCurrentWaterAvailable * props.supplyCurrentRange) /
          100
        ).toFixed(2),
      );

      props.setRunoffToBeArrestedStagant(
        increase_in_water_available *
          (predefinedCropsDetail.supply_efficiency + 1),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.supplyCurrentRange, props.currentCrop]);

  // NEW ADDED FOR IRRIGATION
  useEffect(() => {
    if (props?.currentCrop === 'Irrigation Side') {
      const filteredCrops = props?.allCrops;

      let finalSumCropWaterRequirement = filteredCrops.reduce(
        (initial, vals) => {
          const idealValue = vals?.hasOwnProperty('newIdealValue')
            ? vals?.newIdealValue
            : (
                (vals?.per_ha_cwr * vals?.crop_area * 1000 -
                  vals?.per_ha_cwr *
                    (vals?.drip_area * vals?.drip_efficiency * 1000)) /
                (vals?.hours_per_irrigation_cycle_per_hectare *
                  vals?.water_pumped_per_hour_in_litres *
                  0.001 *
                  vals?.crop_area)
              )?.toFixed(0);

          return (
            initial +
            idealValue *
              vals.hours_per_irrigation_cycle_per_hectare *
              vals.water_pumped_per_hour_in_litres *
              vals.crop_area *
              0.001 *
              0.001
          );
        },
        0,
      );

      // // AVERAGE DRIP
      let average_drip = filteredCrops.reduce((initialValue, val) => {
        return (
          initialValue +
          (val.fixed_drip_area * 100) / val.crop_area / filteredCrops.length
        );
      }, 0);

      // //NEW EVAPORATION
      let new_evaporation_vegetation =
        (average_drip *
          (1 - predefinedCropsDetail.avg_drip_efficiency) *
          cultivation_land_evaporation) /
          100 +
        cultivation_land_evaporation * (1 - average_drip / 100);

      // let final_total_water_saved = total_water_lost - total_new_evaporation;

      // let total_existing_crop_water_requirement = 95.015789;
      let total_existing_crop_water_requirement = firstRenderTotalCwr;

      // let total_new_crop_water_requirement =
      //   finalSumCropWaterRequirement + 47.5 + 2.87035;
      let total_new_crop_water_requirement = finalSumCropWaterRequirement;

      // Finally the new total water saved wii be:-
      // new total water saved = old total CWR - new total CWR + old total evaporation -
      //                         new total evaporation

      // NEWLY ADDED
      let old_total_evaporation =
        predefinedCropsDetail.cultivation_land_evaporation +
        predefinedCropsDetail.barren_land_evaporation +
        predefinedCropsDetail.water_body_evaporation +
        predefinedCropsDetail.forest_land_evaporation;

      let new_total_evaporation =
        new_evaporation_vegetation +
        predefinedCropsDetail.barren_land_evaporation +
        predefinedCropsDetail.water_body_evaporation +
        predefinedCropsDetail.forest_land_evaporation;

      let final_water_saved_total =
        total_existing_crop_water_requirement -
        total_new_crop_water_requirement +
        (old_total_evaporation - new_total_evaporation);

      // NEWLY ADDED

      let water_saved = Math.round(final_water_saved_total);

      setTotalWaterSaved(water_saved);

      let final_demand = Math.round(
        Math.round(total_new_crop_water_requirement) +
          new_total_evaporation +
          total_domestic_requirement,
      );

      setCurrentDemand(final_demand);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.actualIrrigation, props?.finalRangeValue, props.cropListMode]);

  // ENDS

  useEffect(() => {
    setCurrentWaterBalance((currentWaterAvailable - currentDemand).toFixed(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWaterAvailable]);

  // --------------------------SUPPLY FUNCTIONS ENDS ---------------------------

  useEffect(() => {
    if (props?.currentCrop !== 'Irrigation Side') {
      let finalSumCropWaterRequirement = props.allCrops.reduce(
        (initial, vals) => {
          return (
            initial +
            vals.per_ha_cwr * vals.crop_area -
            vals.per_ha_cwr * vals.drip_area * vals.drip_efficiency
          );
        },
        0,
      );

      // // AVERAGE DRIP
      let average_drip = props.allCrops.reduce((initialValue, val) => {
        return (
          initialValue +
          (val.drip_area * 100) / val.crop_area / props.allCrops.length
          // (val.drip_area * 100) / val.crop_area / 4
        );
      }, 0);

      // //NEW EVAPORATION
      let new_evaporation_vegetation =
        (average_drip *
          (1 - predefinedCropsDetail.avg_drip_efficiency) *
          cultivation_land_evaporation) /
          100 +
        cultivation_land_evaporation * (1 - average_drip / 100);

      // let final_total_water_saved = total_water_lost - total_new_evaporation;

      // let total_existing_crop_water_requirement = 95.015789;
      let total_existing_crop_water_requirement = firstRenderTotalCwr;

      // let total_new_crop_water_requirement =
      //   finalSumCropWaterRequirement + 47.5 + 2.87035;
      let total_new_crop_water_requirement = finalSumCropWaterRequirement;

      // Finally the new total water saved wii be:-
      // new total water saved = old total CWR - new total CWR + old total evaporation -
      //                         new total evaporation

      // NEWLY ADDED
      let old_total_evaporation =
        predefinedCropsDetail.cultivation_land_evaporation +
        predefinedCropsDetail.barren_land_evaporation +
        predefinedCropsDetail.water_body_evaporation +
        predefinedCropsDetail.forest_land_evaporation;

      let new_total_evaporation =
        new_evaporation_vegetation +
        predefinedCropsDetail.barren_land_evaporation +
        predefinedCropsDetail.water_body_evaporation +
        predefinedCropsDetail.forest_land_evaporation;

      let final_water_saved_total =
        total_existing_crop_water_requirement -
        total_new_crop_water_requirement +
        (old_total_evaporation - new_total_evaporation);

      // NEWLY ADDED

      let water_saved = Math.round(final_water_saved_total);

      setTotalWaterSaved(water_saved);

      let final_demand = Math.round(
        Math.round(total_new_crop_water_requirement) +
          new_total_evaporation +
          total_domestic_requirement,
      );

      setCurrentDemand(final_demand);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.currentRange,
    props.finalRangeValue,
    props.cropListMode,
    props.currentCrop,
  ]);

  useEffect(() => {
    setCurrentWaterBalance((currentWaterAvailable - currentDemand).toFixed(1));

    // new Groundwater last = (groundwaterLevel/(new Demand/(total number of month between may and base month)))
    setTotalMonthsRemaining(
      predefinedCropsDetail.groundwater_level_tcm /
        (currentDemand / props.baseMonth),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDemand, currentWaterAvailable]);

  // DATA FOR PLAN CHART
  const data = [
    {
      name: 'Water Balance',
      uv: props.currentWaterBalance,
      pv: +1,
    },
    {
      name: 'Water Available',
      uv: props.currentWaterAvailable,
      pv: 0,
    },
    {
      name: 'Water Demand',
      uv: props.currentDemand,
      pv: -1,
    },
  ];

  return (
    <>
      <div className={Styles['left-side-parent-wrapper']}>
        <div className={Styles['first-and-second-item-container']}>
          <div className={`${Styles['plan-grid-item']} ${Styles.item1}`}>
            <div className={Styles['left-toppest']}>
              <div
                className={`${Styles['toppest-threes']} ${Styles['toppest-threes-one']}`}
              >
                <p>{translator[language]['Demand']}</p>

                <p>
                  {getUnitValue(
                    currentDemand,
                    selectedUnit,
                    selectedVillageTankerValue,
                  )}{' '}
                  {translator[language][selectedUnit]}
                </p>
              </div>
              <div
                className={`${Styles['toppest-threes']} ${Styles['toppest-threes-two']}`}
              >
                <p>{translator[language]['Available']}</p>
                <p>
                  {getUnitValue(
                    currentWaterAvailable.toFixed(1),
                    selectedUnit,
                    selectedVillageTankerValue,
                  )}{' '}
                  {translator[language][selectedUnit]}
                </p>
              </div>

              <div
                className={`${Styles['toppest-threes']} ${Styles['toppest-threes-three']}`}
                style={{
                  background:
                    currentWaterBalance >= 0
                      ? 'rgba(18, 203, 48, .3)'
                      : 'rgba(216, 54, 54, .3)',
                }}
              >
                <p>{translator[language]['Water Balance']}</p>
                <p
                  style={{
                    color:
                      currentWaterBalance >= 0
                        ? 'rgba(18, 203, 48, 1)'
                        : 'rgba(216, 54, 54, 1)',
                  }}
                >
                  {getUnitValue(
                    currentWaterBalance,
                    selectedUnit,
                    selectedVillageTankerValue,
                  )}{' '}
                  {translator[language][selectedUnit]}
                </p>
              </div>
            </div>

            <div
              className={Styles['left-middle']}
              style={{ margin: '15px 0', padding: '0px 22px 7px 22px' }}
            >
              <div className={Styles['graph-heading']}>
                <p style={{}}>{translator[language]['Water Balance']}:</p>
                <p
                  className={Styles['left-current-text']}
                  style={{ color: '#A4A4A4' }}
                >
                  ({translator[language]['Current Availability - Demand']})
                </p>
              </div>
              <div className={Styles.planChart}>
                {/* THOSE 3 SVGS FOR %  */}
                <div
                  style={{
                    position: 'absolute',

                    left: currentWaterBalance >= 0 ? 'unset' : -10,
                    right: currentWaterBalance >= 0 ? -10 : 'unset',
                    zIndex: 33,
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                  className={Styles.percentageIncreaseBalance}
                >
                  <img
                    src={currentWaterBalance >= 0 ? green : red}
                    alt="red"
                    className={Styles.percentageLegends}
                    style={{
                      transform: `${
                        currentWaterBalance >= 0
                          ? Math.round(
                              // ((-95 - currentWaterBalance) / -95) * 100
                              ((currentWaterBalance -
                                props.staticCurrentWaterBalance) /
                                currentWaterBalance) *
                                100,
                            ).toFixed(1) < 0
                            ? 'rotate(180deg)'
                            : 'rotate(360deg)'
                          : Math.round(
                              // ((-95 - currentWaterBalance) / -95) * 100
                              ((props.staticCurrentWaterBalance -
                                currentWaterBalance) /
                                props.staticCurrentWaterBalance) *
                                100,
                            ).toFixed(1) < 0
                          ? 'rotate(180deg)'
                          : 'rotate(360deg)'
                      } `,
                    }}
                  />
                  <p
                    style={{
                      marginLeft: '5px',
                      fontSize: '12px',
                      color:
                        currentWaterBalance >= 0
                          ? 'rgb(18, 203, 48)'
                          : 'rgb(216, 54, 54)',
                    }}
                  >
                    {currentWaterBalance >= 0
                      ? Math.round(
                          /* ((-95 - currentWaterBalance) / -95) * 100 */
                          ((currentWaterBalance -
                            parseFloat(props.staticCurrentWaterBalance)) /
                            currentWaterBalance) *
                            100,
                        ).toFixed(1)
                      : Math.round(
                          /* ((-95 - currentWaterBalance) / -95) * 100 */
                          ((props.staticCurrentWaterBalance -
                            parseFloat(currentWaterBalance)) /
                            props.staticCurrentWaterBalance) *
                            100,
                        ).toFixed(1)}
                    {/* {}{" "} */}%
                  </p>
                </div>

                <div
                  className={Styles.percentageIncreaseAvailable}
                  style={{
                    position: 'absolute',
                    right: -10,

                    zIndex: 33,
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                >
                  <img
                    className={Styles.percentageLegends}
                    src={blue}
                    alt="blue"
                    style={{
                      transform: `${
                        Math.round(
                          // ((currentWaterAvailable - 67) / 67) * 100
                          ((currentWaterAvailable -
                            props.staticCurrentWaterAvailable) /
                            props.staticCurrentWaterAvailable) *
                            100,
                        ).toFixed(1) < 0
                          ? 'rotate(180deg)'
                          : 'rotate(360deg)'
                      } `,
                    }}
                  />
                  <p
                    style={{
                      marginLeft: '5px',
                      fontSize: '12px',
                      color: 'rgb(56, 131, 230)',
                    }}
                  >
                    {/* 0% */}
                    {Math.round(
                      /* ((currentWaterAvailable - 67) / 67) * 100 */
                      ((currentWaterAvailable -
                        props.staticCurrentWaterAvailable) /
                        props.staticCurrentWaterAvailable) *
                        100,
                    ).toFixed(1)}{' '}
                    %
                  </p>
                </div>

                <div
                  className={Styles.percentageIncreaseDemand}
                  style={{
                    position: 'absolute',
                    right: -10,

                    zIndex: 33,
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                >
                  <img
                    className={Styles.percentageLegends}
                    src={grey}
                    alt="grey"
                    style={{
                      transform: `${
                        // Math.round(((currentDemand - 162) / 162) * 100).toFixed(
                        Math.round(
                          ((currentDemand - props.staticCurrentDemand) /
                            props.staticCurrentDemand) *
                            100,
                        ).toFixed(1) < 0
                          ? 'rotate(180deg)'
                          : 'rotate(360deg)'
                      } `,
                    }}
                  />
                  <p
                    style={{
                      marginLeft: '5px',
                      fontSize: '12px',
                      color: 'rgb(141, 141, 141)',
                    }}
                  >
                    {/* 0% */}
                    {
                      /* Math.round(((currentDemand - 162) / 162) * 100).toFixed( */
                      Math.round(
                        ((currentDemand - props.staticCurrentDemand) /
                          props.staticCurrentDemand) *
                          100,
                      ).toFixed(1)
                    }{' '}
                    %
                  </p>
                </div>

                {/* THOSE 3 SVGS FOR %  */}

                <PlanChart
                  currentDemand={currentDemand}
                  currentWaterAvailable={currentWaterAvailable}
                  currentWaterBalance={currentWaterBalance}
                  data={data}
                />
              </div>

              <div className={Styles['plan-chart-legends']}>
                <span className={Styles['plan-legends-span']} />
                <p style={{ marginRight: '24px' }}>
                  {translator[language]['Water Demand']}
                </p>
                <span
                  className={Styles['plan-legends-span']}
                  style={{
                    backgroundColor: 'rgba(56, 131, 230, 1)',
                  }}
                />
                <p style={{ marginRight: '24px' }}>
                  {translator[language]['Water Available']}
                </p>
                <span
                  className={Styles['plan-legends-span']}
                  style={{
                    backgroundColor:
                      currentWaterBalance >= 0
                        ? 'rgb(18, 203, 48)'
                        : 'rgba(216, 54, 54, 1)',
                  }}
                />
                <p>{translator[language]['Water Balance']}</p>
              </div>
            </div>

            {/* ends  */}
          </div>
          <div className={`${Styles['plan-grid-item']} ${Styles.item2}`}>
            <div className={Styles['left-last']}>
              <div className={Styles['last-container']}>
                <div className={Styles['last-container-one']}>
                  <p style={{ height: '50%' }}>
                    {
                      translator[language][
                        'With the current situation Groundwater will last'
                      ]
                    }
                    :
                  </p>
                  <p
                    className={Styles['months-remaining-text']}
                    style={{
                      fontWeight: 'bold',

                      marginBottom: '20px',
                      marginTop: '20px',
                    }}
                  >
                    {totalMonthsRemaining.toFixed(1)}{' '}
                    {translator[language]['Months']}
                  </p>
                </div>
                <div className={Styles['last-container-two']}>
                  <p style={{ height: '50%' }}>
                    {translator[language][`Total Water Saved`]}
                  </p>
                  <p
                    className={Styles['months-remaining-text']}
                    style={{
                      fontWeight: 'bold',

                      marginBottom: '20px',
                      marginTop: '20px',
                    }}
                  >
                    {getUnitValue(
                      totalWaterSaved.toFixed(1),
                      selectedUnit,
                      selectedVillageTankerValue,
                    )}{' '}
                    {translator[language][selectedUnit]}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSide;
