import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Label,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import roundOff from '../../../services/round';
import translator from '../../../assets/json/translator.json';
import { useContext } from 'react';
import { GeneralContext } from '../../../services/context/context';

/**
 *
 * @param {*} viewBox - represents the box inside pie chart resides
 * @param {*} value1 - Stores the number which we want to display in the middle(inside) of pie chart
 * @returns text tag with the styled format
 */
function CustomLabel({ viewBox, value1 }) {
  const { cx, cy } = viewBox;
  return (
    <text
      x={cx}
      y={cy}
      fill="#3d405c"
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
    >
      <tspan
        alignmentBaseline="middle"
        fontSize={window.innerWidth > 767 ? '1.2vw' : '.55rem'}
        fill={'#000'}
      >
        {roundOff(value1)}
      </tspan>
    </text>
  );
}

/**
 *
 * @component GroundwaterChart
 * @parameter
 * Direct Recharge due to rainfall --> groundWaterLevel - groundWaterRecharge
 * Seepage through water structures --> groundWaterRecharge
 * @description
 * This component represents the Pie chart on the upper right side of the groundwater page
 */

const GroundwaterChart = (props) => {
  const { language } = useContext(GeneralContext);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width="5vw" height="7vw">
        <Pie
          data={[
            {
              name: translator[language]['Direct Recharge due to rainfall'],
              value: Number(props.groundWaterLevel - props.groundWaterRecharge),
            },
            {
              name: translator[language]['Seepage through water structures'],
              value: Number(props.groundWaterRecharge),
            },
          ]}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={450}
          innerRadius="70%"
          outerRadius="85%"
          fill="#82ca9d"
        >
          <Cell key={`cell-1`} fill="#86665a" />
          <Cell key={`cell-2`} fill="#5776a2" />
          <Label
            width={30}
            position="center"
            content={
              <CustomLabel value1={props.groundWaterLevel} color="#86b7f7" />
            }
          ></Label>
        </Pie>
        <Tooltip wrapperStyle={{ fontSize: '10px' }} />
      </PieChart>
    </ResponsiveContainer>
  );
};
export default GroundwaterChart;
