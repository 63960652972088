import React, { useState, useEffect, useContext } from 'react';
import greenDown from '../../assets/images/greenDown.svg';
import greenUp from '../../assets/images/greenUp.svg';
import red from '../../assets/images/Red.svg';
import redDown from '../../assets/images/redDown.svg';
import LoginLoader from '../../components/Loader/climb';
import base64 from 'base-64';
import translator from '../../assets/json/translator.json';
import { styled as stt } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AnalyticsNavbar from '../../components/Navbar/PageNav';
import Dialog from '@mui/material/Dialog';
import cropDivWarning from '../../assets/images/cropDivWarning.svg';
import savedScenario from '../../assets/images/savedScenario.png';
import ToolTipIcon from '../../assets/images/tooltipIcon.png';
import { Link, useParams } from 'react-router-dom';
import roundTillOne from '../../services/roundTillOne';
import { Get, Patch } from '../../services/apiCall';
import AdvisoryCropDiversification from './AdvisoryCropDiversification';
import NoAdvisoryAvailable from './NoAdvisoryAvailable';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import QMarkHover from '../../assets/images/QMarkHover.svg';

import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../services/context/context';
import Styles from './AdvisoryPreview.module.css';
import getUnitValue from '../../services/getUnitValueFunction';
import AdvisoryIrrigation from './AdvisoryIrrigation';

const StyledTableCell = stt(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#DBDBDB',
    padding: '4px 16px 4px 16px',
    color: '#000000',

    '@media screen and (max-width:768px)': {
      fontSize: 11,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '8px 16px 8px 16px',

    '@media screen and (max-width:1250px)': {
      fontSize: 12,
    },
  },
}));

const StyledTableRow = stt(TableRow)(({ theme }) => ({
  '&:nth-of-type(1)': {
    borderTop: '1px solid #707070',
  },

  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    backgroundColor: '#B6CFF0',
    fontWeight: 'bold',
  },
}));

const AdvisoryPreview = (props) => {
  const { villageId, clusterId } = useParams();
  const { year, selectedSeason, currentPlanYear, pageHead } =
    useContext(TimelineContext);
  const {
    unmount,
    unmountNS,
    language,
    programId,
    selectedUnit,
    selectedVillageTankerValue,
  } = useContext(GeneralContext);
  const { token, accountType } = useContext(AuthContext);

  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [isAdvisoryPublished, setIsAdvisoryPublished] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [advisoryType, setAdvisoryType] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [ownerDetails, setOwnerDetails] = useState('');

  // FOR RENDER
  useEffect(() => {
    if (unmountNS) unmount(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, unmountNS]);

  const checkIsAdvisoryPublished = async () => {
    if (!isAdvisoryPublished) {
      const payload = {
        plan: {
          published_scenario: props.scenarioId,
        },
      };
      const saveAdvisory = await Patch(
        `/simulation/plan?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&yearSession=${currentPlanYear}&season=${props.season}`,
        token,
        payload,
      );
      if (saveAdvisory.status === 'success') {
        setIsAdvisoryPublished(true);
      }
    }

    setIsSaved(true);
  };

  const [advisoryData, setAdvisoryData] = useState({});

  //FOR PAGE TO RENDER:-
  const [isPublishedScenarioAvailable, setIsPublishedScenarioAvailable] =
    useState('');

  let id;
  let getData;

  const showAdvisory = async () => {
    setIsLoading(true);

    // ------FOR PAGE TO RENDER-------------
    if (props.scenarioId === undefined) {
      const payload = {
        programId: programId,
        clusterId: clusterId,
        villageId: villageId,
        yearSession: currentPlanYear,
        season: selectedSeason,
      };

      const response = await Get('/simulation/plan/is-open', token, payload);

      if (response.status === 'success') {
        const checkIsAdvisoryIsThereFromBackend = await Get(
          `/simulation/plan?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&yearSession=${currentPlanYear}&season=${selectedSeason}`,
          token,
        );

        if (checkIsAdvisoryIsThereFromBackend.status === 'success') {
          if (
            checkIsAdvisoryIsThereFromBackend.data.published_scenario !== null
          ) {
            id = checkIsAdvisoryIsThereFromBackend.data.published_scenario;
            getData = checkIsAdvisoryIsThereFromBackend;
            setIsPublishedScenarioAvailable(
              checkIsAdvisoryIsThereFromBackend.data.published_scenario,
            );
          }
        }
      }

      // setIsLoading(false);
      // ------FOR PAGE TO RENDER-------------
    }

    if (getData === undefined) {
      getData = await Get(`/simulation/scenario?id=${props.scenarioId}`, token);
    } else {
      getData = await Get(`/simulation/scenario?id=${id}`, token);
    }

    if (getData.status === 'success') {
      setIsLoading(true);

      setAdvisoryType(getData.data.Type.name);

      setOwnerDetails(getData.data?.creator);

      //TO GET THE NAME OF ADVISORY OWNER
      const role =
        getData.data.creator.role === 'cluster_coordinator'
          ? 'clusterCoordinator'
          : getData.data.creator.role === 'admin'
          ? 'admin'
          : getData.data.creator.role === 'field_facilitator'
          ? 'fieldFacilitator'
          : null;
      const getNameOfScenarioUser = await Get(
        `/info/user/search?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&role=${role}&term=${getData.data.creator.id}`,
        token,
      );

      if (getNameOfScenarioUser.status === 'success') {
        setOwnerName(getNameOfScenarioUser.data[0]?.name);
      }
      //TO GET THE NAME OF ADVISORY OWNER

      const data = getData.data.data;

      const total_fixed_drip_area = data.crops.reduce((initialValue, value) => {
        return initialValue + value.fixed_drip_area;
      }, 0);

      const total_drip_area = data.crops.reduce((initialValue, value) => {
        return initialValue + value.drip_area;
      }, 0);
      const total_crop_area = data.crops.reduce((initialValue, value) => {
        return initialValue + value.crop_area;
      }, 0);

      const finalChangeToGetGreen = total_drip_area - total_fixed_drip_area;

      const changeInDripBlue = (total_fixed_drip_area / total_crop_area) * 100;
      const changeInDripGreen = (finalChangeToGetGreen / total_crop_area) * 100;

      setAdvisoryData((prev) => {
        return {
          staticCurrentDemand: data.staticCurrentDemand,
          staticCurrentWaterAvailable: data.staticCurrentWaterAvailable,
          staticCurrentWaterBalance: data.staticCurrentWaterBalance,

          crops: data.crops,

          changeInTCM: data.staticCurrentDemand - data.waterDemand,
          changeInPercentage:
            ((data.staticCurrentDemand - data.waterDemand) /
              data.staticCurrentDemand) *
            100,
          waterDemand: data.waterDemand,
          waterAvailable: data.waterAvailable,
          waterBalance: data.waterBalance,
          totalWaterSaved: data.totalWaterSaved,
          supplyChangeTCM:
            data.staticCurrentWaterAvailable - data.waterAvailable,
          supplyChangeInPercentage:
            ((data.staticCurrentWaterAvailable - data.waterAvailable) /
              data.staticCurrentWaterAvailable) *
            100,
          waterBalanceChangeTCM:
            data.staticCurrentWaterBalance - data.waterBalance,
          waterBalanceChangeInPercentage:
            ((data.staticCurrentWaterBalance - data.waterBalance) /
              data.staticCurrentWaterBalance) *
            100,

          aquiferChangeTCM:
            data.groundwaterLastDuration - data.staticGroundWaterLast,
          aquiferChangeInPercentage:
            ((data.staticGroundWaterLast - data.groundwaterLastDuration) /
              data.staticGroundWaterLast) *
            100,

          changeInDripBlue: changeInDripBlue,
          changeInDripGreen: changeInDripGreen,
          overAllChangeInDripPercMiddleofPie:
            changeInDripBlue + changeInDripGreen,
          overAllChangeInDripPercRightOfPie: changeInDripGreen,
          overallChangeInDripAreainHA: total_drip_area - total_fixed_drip_area,

          oldDripInPercentage: changeInDripBlue,
          newDripInPercentage: (total_drip_area / total_crop_area) * 100,

          //supply
          percIncreaseInRunoff: data.supplyCurrentRange,
          currentRunoffArrested: data.runoffAvailable,
          moreRunoffNeedsToBeArrested: data.runoffToBeArrested,

          groundwaterLastDuration: data.groundwaterLastDuration,
          staticGroundWaterLast: data.staticGroundWaterLast,
          ctc: data?.ctc,
        };
      });
    } else {
      setAdvisoryType('none');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    showAdvisory();

    return () => {
      setOpenSaveModal(false);
      setIsAdvisoryPublished(false);
      setIsSaved(false);
      setAdvisoryType('');
      setOwnerName('');
      setOwnerDetails('');
      setAdvisoryData({});
      setIsPublishedScenarioAvailable('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeason, year, pageHead, currentPlanYear]);

  const advisoryOnClose = () => {
    if (isAdvisoryPublished) {
      props.setCurrentCrop('Demand Side');
      props.checkPlanIsOpen();
    }

    setOpenSaveModal(false);
    setIsAdvisoryPublished(false);
  };

  const backToAnalytics = () => {
    setOpenSaveModal(false);
    setIsAdvisoryPublished(false);
    if (isAdvisoryPublished) {
      props.setCurrentCrop('Demand Side');
      props.checkPlanIsOpen();
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
          }}
        >
          <LoginLoader />
        </div>
      ) : advisoryType === 'demand-and-supply' ? (
        <>
          <div className={Styles['advisory-preview-wrapper']}>
            <ReactTooltip
              id="my-tooltip-1"
              place="bottom"
              style={{
                background: 'black',
                color: 'white',
                fontSize: window.innerWidth < 768 ? '10px' : '12px',
              }}
              content={
                <div>
                  {translator[language]['Published']} -{' '}
                  {props.ownerName ? props.ownerName : ownerName}
                  <br />
                </div>
              }
            />

            {openSaveModal && (
              <div style={{ width: '' }}>
                <Dialog
                  Paper
                  PaperProps={{
                    sx: { width: window.innerWidth < 768 ? '100%' : '28%' },
                  }}
                  disableEscapeKeyDown
                  open={openSaveModal}
                  onClose={() => {
                    advisoryOnClose();
                  }}
                >
                  <div
                    style={{
                      margin: '25px 0 10px 0',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: '18px',
                    }}
                  >
                    {!isAdvisoryPublished && translator[language]['Alert']}
                  </div>
                  <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <img
                      src={
                        !isAdvisoryPublished ? cropDivWarning : savedScenario
                      }
                      alt="Warn"
                      width={50}
                      style={{ textAlign: 'center' }}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: '15px',
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <span
                      className={Styles.advisoryPublished}
                      style={{ width: '180px' }}
                    >
                      {!isAdvisoryPublished
                        ? translator[language][
                            'You cannot edit the Advisory once published'
                          ]
                        : translator[language][
                            'Advisory published Successfully'
                          ]}
                      !
                    </span>
                  </div>{' '}
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '20px',
                      justifyContent: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    <button
                      onClick={() => {
                        backToAnalytics();
                      }}
                      style={{
                        background: '#FFFFFF',
                        border: '1px solid #5F5F5F',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        borderRadius: '10px',
                        padding: '6px 20px',
                        marginRight: '15px',
                        cursor: 'pointer',
                      }}
                    >
                      {!isAdvisoryPublished
                        ? translator[language]['Cancel']
                        : translator[language]['Back to Plan']}
                    </button>
                    <button
                      onClick={() => {
                        checkIsAdvisoryPublished();
                      }}
                      style={{
                        background: '#448AE7',

                        color: '#ffffff',
                        border: '1px solid #448AE7',

                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        borderRadius: '10px',
                        padding: '6px 20px',
                        cursor: 'pointer',
                      }}
                    >
                      {!isAdvisoryPublished ? (
                        translator[language]['Continue to Publish']
                      ) : (
                        <Link
                          style={{ textDecoration: 'none', color: '#fff' }}
                          to={`/advisory/programId/${programId}/clusterId/${clusterId}/villageId/${villageId}`}
                        >
                          {translator[language]['View Advisory']}
                        </Link>
                      )}
                    </button>
                  </div>
                </Dialog>
              </div>
            )}

            {!props.isFromSavedScenario ? (
              <AnalyticsNavbar
                {...props}
                active3={true}
                setCurrentCrop={props.setCurrentCrop}
                isAdvisory={true}
                ownerName={ownerName}
              />
            ) : (
              <div className={Styles.backToScenario}>
                <div
                  className={Styles.backToScenarioText}
                  style={{ textDecoration: 'none', color: 'unset' }}
                  onClick={() => {
                    props.setPreviewAdvisory && props.setPreviewAdvisory(false);
                  }}
                >
                  {'<'} {translator[language]['Back to Scenario']}
                </div>

                {!isSaved ? (
                  <div
                    style={{
                      position: 'absolute',
                      background: '',
                      right: '125px',
                      display: 'flex',
                      top: -7,
                    }}
                  ></div>
                ) : null}

                {/* COPIED FROM CROP DIV  */}

                {props?.ownerData?.creator.id ===
                localStorage.getItem(base64.encode('emailId')) ? (
                  accountType === 'clusterCoordinator' &&
                  props.scenarioId !== props.globalPublishedScenarioId &&
                  !isSaved ? (
                    <div className={Styles.publishCropDiv}>
                      <div
                        className={Styles['resetsave']}
                        onClick={() => setOpenSaveModal(true)}
                      >
                        <button>{translator[language]['Publish']}</button>
                      </div>
                    </div>
                  ) : (
                    props.scenarioId === props.globalPublishedScenarioId && (
                      <div className={Styles.advisoryCropDivTooltip}>
                        <img
                          data-tooltip-id="my-tooltip-1"
                          onClick={() => navigator.vibrate(100)}
                          src={ToolTipIcon}
                          className={Styles.toolTipIconAd}
                          alt="tooltip"
                        />
                      </div>
                    )
                  )
                ) : null}

                {/* COPIED FROM CROP DIV  */}
              </div>
            )}

            <div className={Styles['advisory-preview-container']}>
              <div
                className={`${Styles['advisoryitems']}  ${Styles['advisory-item-1']}`}
              >
                <div className={Styles.advisoryOnHeading}>
                  {translator[language]['Advisory On']} :-
                </div>

                <div className={Styles['top-section-demand']}>
                  <div className={Styles['demand-heading']}>
                    <p>{translator[language]['Demand']}</p>
                  </div>
                  <div className={Styles['demand-tcm']}>
                    <div>
                      {' '}
                      <p className={Styles['tcm-amount']}>
                        <span>
                          {' '}
                          <img
                            alt="increase"
                            src={
                              advisoryData?.waterDemand >
                              advisoryData?.staticCurrentDemand
                                ? red
                                : greenDown
                            }
                          />
                        </span>
                        {getUnitValue(
                          roundTillOne(advisoryData?.waterDemand),
                          selectedUnit,
                          selectedVillageTankerValue,
                        )}{' '}
                        {translator[language][selectedUnit]}
                      </p>
                      <p
                        className={Styles['tcm-amount-percent']}
                        style={{
                          color:
                            advisoryData?.waterDemand >
                            advisoryData?.staticCurrentDemand
                              ? '#D83636'
                              : '#079F4E',
                        }}
                      >
                        {getUnitValue(
                          advisoryData?.changeInTCM?.toFixed(1),
                          selectedUnit,
                          selectedVillageTankerValue,
                        )}{' '}
                        {translator[language][selectedUnit]} ({' '}
                        {advisoryData?.changeInPercentage?.toFixed(1)}%)
                      </p>
                    </div>
                  </div>
                </div>

                <div className={Styles['second-section-overall-change']}>
                  <div className={Styles['drip-percent']}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        // justifyContent: 'flex-end',
                      }}
                    >
                      <p>{translator[language]['Overall Change in']} MI %</p>

                      <img
                        data-tooltip-id="my-tooltip-2"
                        onClick={() => navigator.vibrate(100)}
                        src={QMarkHover}
                        height="12px"
                        width="12px"
                        alt="tooltip"
                        style={{
                          cursor: 'pointer',
                          marginLeft: '3px',
                        }}
                      />
                    </div>

                    <div
                      style={{
                        height: '75px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {/* <GenericAdvisoryChart
                        name1={"Older Drip Area"}
                        name2={"Change in Drip"}
                        green={advisoryData?.changeInDripGreen}
                        blue={advisoryData?.changeInDripBlue}
                        middle={
                          advisoryData?.overAllChangeInDripPercMiddleofPie
                        }
                      /> */}
                      <p
                        className={Styles['current-drip-percent']}
                        style={{
                          color:
                            advisoryData?.overAllChangeInDripPercRightOfPie >= 0
                              ? '#079F4E'
                              : '#D83636',
                        }}
                      >
                        {advisoryData?.overAllChangeInDripPercRightOfPie?.toFixed(
                          2,
                        )}
                        %
                      </p>
                    </div>
                  </div>

                  <div className={Styles['change-in-drip']}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        // justifyContent: 'flex-end',
                      }}
                    >
                      <p className={Styles['overall-change-para']}>
                        {' '}
                        {translator[language]['Overall Change in']} MI
                      </p>

                      <img
                        data-tooltip-id="my-tooltip-2"
                        onClick={() => navigator.vibrate(100)}
                        src={QMarkHover}
                        height="12px"
                        width="12px"
                        alt="tooltip"
                        style={{
                          cursor: 'pointer',
                          marginLeft: '3px',
                          marginBottom: '4px',
                          // marginTop: '3px',
                        }}
                      />
                    </div>

                    <p
                      className={Styles['overall-amount-para']}
                      style={{
                        color:
                          advisoryData?.overallChangeInDripAreainHA >= 0
                            ? '#079F4E'
                            : '#D83636',
                      }}
                    >
                      {advisoryData?.overallChangeInDripAreainHA?.toFixed(1)}{' '}
                      {translator[language]['Ha']}
                    </p>
                  </div>
                </div>

                <div className={Styles['third-section-drip-irrigation']}>
                  <div className={Styles['drip-irrigation-para']}>
                    <p>
                      {
                        translator[language][
                          'Increase in Drip Irrigation (Areawise) for the crops'
                        ]
                      }
                      .
                    </p>
                  </div>

                  <div className={Styles['crop-table']}>
                    <TableContainer
                      component={Paper}
                      sx={{ maxHeight: '200px' }}
                    >
                      <Table
                        stickyHeader
                        sx={{ minWidth: 200 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{
                                borderRight: '1px solid #707070',
                                minWidth: '65px',
                                fontWeight: 'bold',
                              }}
                            >
                              {translator[language]['Crop Name']}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              {translator[language]['Sown Area']}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  // justifyContent: 'flex-end',
                                }}
                              >
                                <p style={{ fontWeight: '600' }}>Old MI</p>
                                <img
                                  data-tooltip-id="my-tooltip-2"
                                  onClick={() => navigator.vibrate(100)}
                                  src={QMarkHover}
                                  height="12px"
                                  width="12px"
                                  alt="tooltip"
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: '3px',
                                    // marginTop: '3px',
                                  }}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  // justifyContent: 'flex-end',
                                }}
                              >
                                <p style={{ fontWeight: '600' }}>New MI</p>
                                <img
                                  data-tooltip-id="my-tooltip-2"
                                  onClick={() => navigator.vibrate(100)}
                                  src={QMarkHover}
                                  height="12px"
                                  width="12px"
                                  alt="tooltip"
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: '3px',
                                    // marginTop: '3px',
                                  }}
                                />
                              </div>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        {/* <div> */}
                        <TableBody>
                          {advisoryData.crops?.map((row) => (
                            <StyledTableRow key={row.uuid}>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{
                                  borderRight: '1px solid #707070',
                                }}
                              >
                                {row.name}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {roundTillOne(row.crop_area)}{' '}
                                {translator[language]['Ha']}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {roundTillOne(row.fixed_drip_area)}{' '}
                                {translator[language]['Ha']}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{
                                  color:
                                    row.drip_area >= row.fixed_drip_area
                                      ? '#079F4E'
                                      : '#D83636',
                                }}
                              >
                                {roundTillOne(row.drip_area)}{' '}
                                {translator[language]['Ha']}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}

                          <StyledTableRow
                            style={{ position: 'sticky', bottom: 0, top: 0 }}
                          >
                            <StyledTableCell align="left">
                              {advisoryData.crops?.length}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {roundTillOne(
                                advisoryData.crops?.reduce(
                                  (initialValue, value) => {
                                    return initialValue + value.crop_area;
                                  },
                                  0,
                                ),
                              )}{' '}
                              {translator[language]['Ha']}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {roundTillOne(
                                advisoryData.crops?.reduce(
                                  (initialValue, value) => {
                                    return initialValue + value.fixed_drip_area;
                                  },
                                  0,
                                ),
                              )}{' '}
                              {translator[language]['Ha']}
                            </StyledTableCell>
                            <StyledTableCell
                              align="right"
                              style={{
                                color:
                                  advisoryData.crops?.reduce(
                                    (initialValue, value) => {
                                      return (
                                        initialValue + value.fixed_drip_area
                                      );
                                    },
                                    0,
                                  ) <=
                                  advisoryData.crops?.reduce(
                                    (initialValue, value) => {
                                      return initialValue + value.drip_area;
                                    },
                                    0,
                                  )
                                    ? '#079F4E'
                                    : '#D83636',
                              }}
                            >
                              {roundTillOne(
                                advisoryData.crops?.reduce(
                                  (initialValue, value) => {
                                    return initialValue + value.drip_area;
                                  },
                                  0,
                                ),
                              )}{' '}
                              {translator[language]['Ha']}
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                        {/* </div> */}
                      </Table>
                    </TableContainer>

                    <div className={Styles['left-bottom']}>
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'flex-end',
                          }}
                        >
                          <p>Old MI in %</p>
                          <img
                            data-tooltip-id="my-tooltip-2"
                            onClick={() => navigator.vibrate(100)}
                            src={QMarkHover}
                            height="12px"
                            width="12px"
                            alt="tooltip"
                            style={{
                              cursor: 'pointer',
                              marginLeft: '3px',
                              marginBottom: '2px',
                            }}
                          />
                        </div>

                        <p className={Styles['old-percent-bottom']}>
                          {advisoryData?.oldDripInPercentage?.toFixed(1)}%
                        </p>
                      </div>
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'flex-end',
                          }}
                        >
                          <p>New MI in %</p>
                          <img
                            data-tooltip-id="my-tooltip-2"
                            onClick={() => navigator.vibrate(100)}
                            src={QMarkHover}
                            height="12px"
                            width="12px"
                            alt="tooltip"
                            style={{
                              cursor: 'pointer',
                              marginLeft: '3px',
                              marginBottom: '2px',
                            }}
                          />
                        </div>

                        <p
                          className={Styles['new-percent-bottom']}
                          style={{
                            color:
                              advisoryData?.newDripInPercentage >= 0
                                ? '#079F4E'
                                : '#D83636',
                          }}
                        >
                          {advisoryData?.newDripInPercentage?.toFixed(1)}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${Styles['advisoryitems']} ${Styles['advisory-item-2']}`}
              >
                <div>
                  <p>{translator[language]['Advisory On']} :-</p>

                  <div className={Styles['top-section-demand']}>
                    <div
                      className={`${Styles['demand-heading']} ${Styles['supply']}`}
                    >
                      <p>{translator[language]['Supply']}</p>
                    </div>
                    <div
                      className={`${Styles['demand-tcm']} ${Styles['supply']}`}
                    >
                      <div>
                        {' '}
                        <p className={Styles['tcm-amount']}>
                          <span>
                            {' '}
                            <img
                              alt="increase"
                              src={
                                advisoryData?.waterAvailable >=
                                advisoryData?.staticCurrentWaterAvailable
                                  ? greenUp
                                  : redDown
                              }
                            />
                          </span>
                          {getUnitValue(
                            roundTillOne(advisoryData?.waterAvailable),
                            selectedUnit,
                            selectedVillageTankerValue,
                          )}{' '}
                          {translator[language][selectedUnit]}
                        </p>
                        <p
                          className={Styles['tcm-amount-percent']}
                          style={{
                            color:
                              advisoryData?.waterAvailable >=
                              advisoryData?.staticCurrentWaterAvailable
                                ? '#079F4E'
                                : '#D83636',
                          }}
                        >
                          {getUnitValue(
                            advisoryData?.supplyChangeTCM?.toFixed(1),
                            selectedUnit,
                            selectedVillageTankerValue,
                          )}{' '}
                          {translator[language][selectedUnit]} (
                          {advisoryData?.supplyChangeInPercentage?.toFixed(1)}%)
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className={Styles['second-section-overall-change']}>
                    <div className={Styles['drip-percent']}>
                      <p>{translator[language]['% Increase in Water']}</p>
                      <div
                        style={{
                          height: '75px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {/* <GenericAdvisoryChart /> */}
                        <p className={Styles['current-drip-percent']}>
                          {advisoryData?.percIncreaseInRunoff}%
                        </p>
                      </div>
                    </div>

                    <div className={Styles['change-in-drip']}>
                      <p className={Styles['overall-change-para']}>
                        {' '}
                        {translator[language]['Possible Contour trench']}
                        <br />
                        {translator[language]['construction']}
                      </p>
                      <p
                        className={`${Styles['overall-amount-para']} ${Styles['supply']}`}
                      >
                        {advisoryData?.ctc
                          ? advisoryData?.ctc?.toFixed(0) + ' M'
                          : 'NA'}
                      </p>
                    </div>
                  </div>

                  <div className={Styles['current-runoff-supply']}>
                    <p>
                      {
                        translator[language][
                          'Current Runoff Arrested arrested in the village'
                        ]
                      }
                    </p>
                    <p>
                      {getUnitValue(
                        advisoryData?.currentRunoffArrested?.toFixed(1),
                        selectedUnit,
                        selectedVillageTankerValue,
                      )}{' '}
                      {translator[language][selectedUnit]}
                    </p>
                  </div>

                  <div
                    className={`${Styles['current-runoff-supply']} ${Styles['more-runoff']}`}
                  >
                    <p>
                      {
                        translator[language][
                          'More Runoff that needs to be arrested'
                        ]
                      }
                    </p>
                    <p>
                      {getUnitValue(
                        advisoryData?.moreRunoffNeedsToBeArrested?.toFixed(1),
                        selectedUnit,
                        selectedVillageTankerValue,
                      )}{' '}
                      {translator[language][selectedUnit]}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`${Styles['advisoryitems']} ${Styles['advisory-item-3']}`}
              >
                <div>
                  <p>{translator[language]['Resulting Water Balance']}</p>
                  <div className={Styles['top-section-demand']}>
                    <div
                      className={`${Styles['demand-tcm']} ${Styles['centered']}`}
                    >
                      <div>
                        {' '}
                        <p className={Styles['tcm-amount']}>
                          <span>
                            {' '}
                            <img
                              alt="increase"
                              src={
                                advisoryData?.waterBalance >=
                                advisoryData?.staticCurrentWaterBalance
                                  ? greenUp
                                  : redDown
                              }
                            />
                          </span>
                          {getUnitValue(
                            roundTillOne(advisoryData?.waterBalance),
                            selectedUnit,
                            selectedVillageTankerValue,
                          )}{' '}
                          {translator[language][selectedUnit]}
                        </p>
                        <p
                          className={Styles['tcm-amount-percent']}
                          style={{
                            color:
                              advisoryData?.waterBalance >=
                              advisoryData?.staticCurrentWaterBalance
                                ? '#079F4E'
                                : '#D83636',
                          }}
                        >
                          {getUnitValue(
                            roundTillOne(advisoryData?.waterBalanceChangeTCM),
                            selectedUnit,
                            selectedVillageTankerValue,
                          )}{' '}
                          {translator[language][selectedUnit]} (
                          {roundTillOne(
                            advisoryData?.waterBalanceChangeInPercentage,
                          )}
                          %)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${Styles['advisoryitems']} ${Styles['advisory-item-4']}`}
              >
                <div>
                  <p>{translator[language]['Groundwater Aquifer will Last']}</p>
                  <div className={Styles['top-section-demand']}>
                    <div
                      className={`${Styles['demand-tcm']} ${Styles['centered']}`}
                    >
                      <div>
                        {' '}
                        <p className={Styles['tcm-amount']}>
                          <span>
                            {' '}
                            <img
                              alt="increase"
                              src={
                                advisoryData?.groundwaterLastDuration >=
                                advisoryData?.staticGroundWaterLast
                                  ? greenUp
                                  : redDown
                              }
                            />
                          </span>
                          {advisoryData.groundwaterLastDuration.toFixed(1)}{' '}
                          {translator[language]['Months']}
                        </p>
                        <p
                          className={Styles['tcm-amount-percent']}
                          style={{
                            color:
                              advisoryData?.aquiferChangeTCM < 0
                                ? '#D83636'
                                : '#079F4E',
                          }}
                        >
                          {roundTillOne(advisoryData?.aquiferChangeTCM)}{' '}
                          {translator[language]['Months']}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${Styles['advisoryitems']} ${Styles['advisory-item-5']}`}
              >
                <div>
                  <p>{translator[language]['Water Saved From the Plan']}</p>
                  <div className={Styles['top-section-demand']}>
                    <div
                      className={`${Styles['demand-tcm']} ${Styles['centered']}`}
                    >
                      <div>
                        {' '}
                        <p
                          className={Styles['tcm-amount']}
                          style={{
                            color:
                              advisoryData?.totalWaterSaved < 0
                                ? '#D83636'
                                : '',
                          }}
                        >
                          {getUnitValue(
                            advisoryData?.totalWaterSaved,
                            selectedUnit,
                            selectedVillageTankerValue,
                          )}{' '}
                          {translator[language][selectedUnit]}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : advisoryType === 'crop-diversification' ? (
        <AdvisoryCropDiversification
          language={language}
          programId={programId}
          setCurrentCrop={props.setCurrentCrop}
          token={token}
          // scenarioId={"bac667f7-a117-4e98-9aad-83d582714ab0"}
          scenarioId={isPublishedScenarioAvailable}
          isFromAdvisoryPage={true}
          ownerName={ownerName}
          accountType={accountType}
          ownerDetails={ownerDetails}
        />
      ) : advisoryType === 'irrigation-cycle' ? (
        <AdvisoryIrrigation
          language={language}
          programId={programId}
          setCurrentCrop={props.setCurrentCrop}
          token={token}
          // scenarioId={"bac667f7-a117-4e98-9aad-83d582714ab0"}
          scenarioId={isPublishedScenarioAvailable}
          isFromAdvisoryPage={true}
          ownerName={ownerName}
          accountType={accountType}
          ownerDetails={ownerDetails}
        />
      ) : props.scenarioId === undefined &&
        isPublishedScenarioAvailable === '' &&
        advisoryType === 'none' ? (
        <>
          <div className={Styles.noAdvisoryWrapper}>
            <AnalyticsNavbar
              {...props}
              active3={true}
              setCurrentCrop={props.setCurrentCrop}
              NoAdvisoryAvailable={true}
            />
            <NoAdvisoryAvailable />
          </div>
        </>
      ) : null}

      <ReactTooltip
        id="my-tooltip-2"
        place="top"
        style={{
          background: 'black',
          color: 'white',
          fontSize: window.innerWidth < 768 ? '10px' : '12px',
          zIndex: 888,
        }}
        content={'Micro Irrigation'}
      />
    </>
  );
};

export default AdvisoryPreview;
