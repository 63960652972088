import React, { useContext } from 'react';
import { TimelineContext } from '../../services/context/context';
import Styles from './Year.module.css';

/**
 *
 * @component Year
 * @description
 * This component shows the year dropdown on the navbar
 */

const Year = () => {
  /**
   *
   * @param {*} e - year event click of the dropdown
   * @description
   * changes the prop selectedYear on the component App to the year clicked
   */

  const { handleYear, defaultYear, year } = useContext(TimelineContext);

  const handleYearClick = (e) => {
    const year = e.target.value;
    handleYear(year);
  };

  const getYears = (endYear) => {
    const startYear = 2020;
    const years = [];
    for (let i = endYear; i >= startYear; i--) {
      years.push(i);
    }
    return years;
  };

  return (
      <div className={Styles.year}>
        <select
          name="year"
          style={{ cursor: 'pointer' }}
          onChange={handleYearClick}
          value={Number(year)}
        >
          {getYears(defaultYear).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
  );
};

export default Year;
