import { useEffect, useState } from 'react';
import SurfacewaterPie from '../components/Analytics/Surfacewater/Chart';
import SurfacewaterTable from '../components/Analytics/Surfacewater/Table';
import { Get } from '../services/apiCall';
import SurfacePieLoader from '../components/Loader/hash';
import SurfaceTableLoader from '../components/Loader/hash';
import { Link, useParams } from 'react-router-dom';
import roundOff from '../services/round';
import translator from '../assets/json/translator.json';
import Mixpanel from '../services/mixpanel.service';
import WaterStructure from '../shared/enums/water-structure';
import { useContext } from 'react';
import Styles from './AnalyticsSurfacewater.module.css';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../services/context/context';
import getUnitValue from '../services/getUnitValueFunction';
import SurfaceWaterMap from '../components/Analytics/Surfacewater/Map';

/**
 *
 * @component Surfacewater
 * @states
 * isLoading --> If true show animation
 * totalCheckDams --> Stores total number of checkdams(filled or unfilled)
 * grossCapacityCheckDam --> Stores the summation of current volume of all the check dams this month(filled)
 * totalCheckDamsFilled --> Stores total number of checkdams(filled) this month
 * totalStorageCheckDam --> Stores the summation of capacity of all the check dams(filled or unfilled)
 * @description
 * This component represents the Surfacewater page
 */

const Surfacewater = () => {
  const { clusterId, villageId } = useParams();

  const { month, year, showSeason, handleSeason, pageHead, handlePageHead } =
    useContext(TimelineContext);
  const {
    unmount,
    unmountNS,
    language,
    programId,
    selectedUnit,
    selectedVillageTankerValue,
  } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [totalCountData, setTotalCountData] = useState({
    totalCheckDams: 0,
    totalFarmPonds: 0,
    totalPercolationTanks: 0,
    totalVillagePonds: 0,
    totalOthers: 0,
  });
  const [grossCapacityData, setGrossCapacityData] = useState({
    grossCapacityCheckDam: 0,
    grossCapacityFarmPond: 0,
    grossCapacityPercolationTank: 0,
    grossCapacityVillagePond: 0,
  });
  const [totalStorageData, setTotalStorageData] = useState({
    totalStorageCheckDam: 0,
    totalStorageFarmPond: 0,
    totalStoragePercolationTank: 0,
    totalStorageVillagePond: 0,
  });
  const [totalFilledData, setTotalFilledData] = useState({
    totalCheckDamsFilled: 0,
    totalFarmPondsFilled: 0,
    totalPercolationTanksFilled: 0,
    totalVillagePondsFilled: 0,
    totalOthersFilled: 0,
  });
  const [totalSurfaceWaterStorage, setTotalSurfaceWaterStorage] = useState(0);

  useEffect(() => {
    Mixpanel.time_event('AnalyticsSurfacewaterPage');
  }, []);

  useEffect(() => {
    if (unmountNS) unmount(false);
    if (showSeason) handleSeason(false);

    const initialFunction = async () => {
      setIsLoading(true);
      const params = {
        villageId: villageId,
        year: year,
        month: month + 1,
        language: language,
        programId: programId,
        clusterId: clusterId,
      };

      try {
        const response = await Get('/analytics/surfacewater', token, params);
        const payload = response.data;

        if (payload && payload.VillageMonthlyStats1.length > 0) {
          const data = payload.VillageMonthlyStats1[0];
          let otherStructuresData = data.total_water_structures;

          setTotalFilledData({
            totalCheckDamsFilled: data.total_check_dam,
            totalFarmPondsFilled: data.total_farm_pond,
            totalPercolationTanksFilled: data.total_percolation_tank,
            totalVillagePondsFilled: data.total_village_pond,
            totalOthersFilled:
              otherStructuresData -
              (data.total_check_dam +
                data.total_farm_pond +
                data.total_percolation_tank +
                data.total_village_pond),
          });
          setGrossCapacityData({
            grossCapacityCheckDam: data.current_surface_water_check_dam,
            grossCapacityFarmPond: data.current_surface_water_farm_pond,
            grossCapacityPercolationTank:
              data.current_surface_water_percolation_tank,
            grossCapacityVillagePond: data.current_surface_water_village_pond,
          });
          setTotalSurfaceWaterStorage(data.total_surface_water_storage);
        }

        let checkDams = 0,
          farmPonds = 0,
          percolationTanks = 0,
          villagePonds = 0,
          others = 0;
        let totalStorageCheckDam = 0,
          totalStorageFarmPond = 0,
          totalStoragePercolationTank = 0,
          totalStorageVillagePond = 0;

        if (payload && payload.VillageWaterStructureYearlyStats.length > 0) {
          const data = payload.VillageWaterStructureYearlyStats;
          // anything which is not Farm pond, Percolation tank, MI tank and Village pond
          // in the database is a Check dam

          // Now check dam, farm pond, village pond, Percolation tank/ MI Tank will
          // fall in seperate categories and rest will come into the
          // others category

          data.map((d) => {
            const structure = d.structure
              ? d.structure.toLowerCase()?.trim()
              : '';
            if (
              structure === WaterStructure.CHECK_DAM.toLowerCase()?.trim() ||
              structure === WaterStructure.CNB.toLowerCase()?.trim() ||
              structure === WaterStructure.ENB.toLowerCase()?.trim() ||
              structure === WaterStructure.KT_WEIR.toLowerCase()?.trim() ||
              structure === WaterStructure.NALA.toLowerCase()?.trim()
            ) {
              checkDams++;
              totalStorageCheckDam +=
                parseFloat(d.depth) * parseFloat(d.area) * 10;
            } else if (
              structure === WaterStructure.FARM_POND.toLowerCase()?.trim()
            ) {
              farmPonds++;
              totalStorageFarmPond +=
                parseFloat(d.depth) * parseFloat(d.area) * 10;
            } else if (
              structure ===
                WaterStructure.PERCOLATION_TANK.toLowerCase()?.trim() ||
              structure === WaterStructure.MI_TANK.toLowerCase()?.trim()
            ) {
              percolationTanks++;
              totalStoragePercolationTank +=
                parseFloat(d.depth) * parseFloat(d.area) * 10;
            } else if (
              structure === WaterStructure.VILLAGE_POND.toLowerCase()?.trim()
            ) {
              villagePonds++;
              totalStorageVillagePond +=
                parseFloat(d.depth) * parseFloat(d.area) * 10;
            } else {
              others++;
            }
            return '';
          });

          setTotalCountData((prev) => ({
            ...prev,
            totalCheckDams: checkDams,
            totalFarmPonds: farmPonds,
            totalPercolationTanks: percolationTanks,
            totalVillagePonds: villagePonds,
            totalOthers: others,
          }));
          setTotalStorageData((prev) => ({
            ...prev,
            totalStorageCheckDam: totalStorageCheckDam,
            totalStorageFarmPond: totalStorageFarmPond,
            totalStoragePercolationTank: totalStoragePercolationTank,
            totalStorageVillagePond: totalStorageVillagePond,
          }));
        }
      } catch (err) {
        console.error(err);
      }

      setIsLoading(false);
    };

    initialFunction();

    return () => {
      Mixpanel.track('AnalyticsSurfacewaterPage', {
        month: month + 1,
        year: year,
        villageId: villageId,
        programId: programId,
        clusterId: clusterId,
      });

      setTotalCountData({
        totalCheckDams: 0,
        totalFarmPonds: 0,
        totalPercolationTanks: 0,
        totalVillagePonds: 0,
        totalOthers: 0,
      });
      setGrossCapacityData({
        grossCapacityCheckDam: 0,
        grossCapacityFarmPond: 0,
        grossCapacityPercolationTank: 0,
        grossCapacityVillagePond: 0,
      });
      setTotalStorageData({
        totalStorageCheckDam: 0,
        totalStorageFarmPond: 0,
        totalStoragePercolationTank: 0,
        totalStorageVillagePond: 0,
      });
      setTotalFilledData({
        totalCheckDamsFilled: 0,
        totalFarmPondsFilled: 0,
        totalPercolationTanksFilled: 0,
        totalVillagePondsFilled: 0,
        totalOthersFilled: 0,
      });
      setTotalSurfaceWaterStorage(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, pageHead]);

  return (
    <>
      <div className={Styles.surfacewater}>
        <div className={Styles.surfacewaterNavbar}>
          <div className={Styles.surfacewaterNavbarBack}>
            <Link
              onClick={() => handlePageHead('Analytics Overview')}
              to={{
                pathname: `/analytics-overview/programId/${programId}/clusterId/${clusterId}/villageId/${villageId}`,
                state: {
                  selectedMonth: month,
                  selectedYear: year,
                  pageHead: 'Analytics Overview',
                  language: language,
                },
              }}
              className={Styles.landuseNavbarBack}
            >
              &#60;&nbsp;&nbsp;&nbsp;&nbsp;
              {translator[language]['Back to Analytics']}
            </Link>
          </div>
        </div>
        <div className={Styles.surfacewaterContent}>
          <SurfaceWaterMap />
          <div className={Styles.surfacewaterContentRight}>
            <div className={Styles.surfacewaterContentRightTop}>
              {!isLoading ? (
                <>
                  <div className={Styles.surfacewaterContentRightTopHead}>
                    <div className={Styles.surfacewaterContentRightTopHeadKey}>
                      {translator[language]['Total']}{' '}
                      {translator[language]['Surfacewater']}:
                    </div>
                    <div
                      className={Styles.surfacewaterContentRightTopHeadValue1}
                    >
                      {getUnitValue(
                        roundOff(totalSurfaceWaterStorage),
                        selectedUnit,
                        selectedVillageTankerValue,
                      )}{' '}
                      {translator[language][selectedUnit]}
                    </div>
                    <div
                      className={Styles.surfacewaterContentRightTopHeadValue2}
                    >
                      {
                        translator[language][
                          'The ratio of the number of these structures filled to the total water structures present'
                        ]
                      }
                    </div>
                  </div>
                  <div className={Styles.surfacewaterContentRightTopBottom}>
                    <SurfacewaterPie
                      head={'Farm Pond'}
                      total={totalCountData.totalFarmPonds}
                      filled={totalFilledData.totalFarmPondsFilled}
                      color={'#5776a2'}
                    />

                    <SurfacewaterPie
                      head={'Village Pond'}
                      total={totalCountData.totalVillagePonds}
                      filled={totalFilledData.totalVillagePondsFilled}
                      color={'#84b5f7'}
                    />

                    <SurfacewaterPie
                      head={'Percolation Tank'}
                      total={totalCountData.totalPercolationTanks}
                      filled={totalFilledData.totalPercolationTanksFilled}
                      color={'#0c69e3'}
                    />
                    <SurfacewaterPie
                      head={'Check Dam'}
                      total={totalCountData.totalCheckDams}
                      filled={totalFilledData.totalCheckDamsFilled}
                      color={'#579df6'}
                    />
                    {/* Adding new pie chart for showing other structures except Farm Pond, Village Pond,
                      Percolation Tank, Check Dam */}
                    <SurfacewaterPie
                      head={'Other Structures'}
                      total={totalCountData.totalOthers}
                      filled={totalFilledData.totalOthersFilled}
                      color={'#579df7'}
                    />
                  </div>
                </>
              ) : (
                <SurfacePieLoader
                  color="#a7b9ed"
                  size={window.innerWidth < 768 ? 20 : 30}
                  style={{
                    position: 'relative',
                    marginTop: '20%',
                    marginLeft: '50%',
                  }}
                />
              )}
            </div>
            <div className={Styles.surfacewaterContentRightBottom}>
              {!isLoading ? (
                <SurfacewaterTable
                  totalCheckDamsFilled={totalCountData.totalCheckDams}
                  totalFarmPondsFilled={totalCountData.totalFarmPonds}
                  totalPercolationTanksFilled={
                    totalCountData.totalPercolationTanks
                  }
                  totalVillagePondsFilled={totalCountData.totalVillagePonds}
                  totalOtherStructuresFilled={totalCountData.totalOthers}
                  grossCapacityCheckDam={
                    grossCapacityData.grossCapacityCheckDam
                  }
                  grossCapacityFarmPond={
                    grossCapacityData.grossCapacityFarmPond
                  }
                  grossCapacityPercolationTank={
                    grossCapacityData.grossCapacityPercolationTank
                  }
                  grossCapacityVillagePond={
                    grossCapacityData.grossCapacityVillagePond
                  }
                  totalStorageCheckDam={totalStorageData.totalStorageCheckDam}
                  totalStorageFarmPond={totalStorageData.totalStorageFarmPond}
                  totalStoragePercolationTank={
                    totalStorageData.totalStoragePercolationTank
                  }
                  totalStorageVillagePond={
                    totalStorageData.totalStorageVillagePond
                  }
                />
              ) : (
                <SurfaceTableLoader
                  size={window.innerWidth < 768 && 20}
                  style={{
                    position: 'relative',
                    marginTop: '20%',
                    marginLeft: '50%',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Surfacewater;
