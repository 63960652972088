import React from 'react';
import { VscTriangleLeft, VscTriangleRight } from 'react-icons/vsc';
import Styles from './Bar.module.css';
import $ from 'jquery';
/**
 *
 * @component Bar
 * @description
 * This component represent the navigation bar on the bottom box component of the Taluka
 * page. It has list of all the villages under the taluka, which help us to navigate between
 * different villages stats
 */

const Bar = (props) => {
  let bar = React.createRef();
  /**
   *
   * @description
   * animates the left and right arrow on the navbar
   */
  const handleRightArrow = () => {
    const width = bar.current.offsetWidth;
    $('#villageBar').animate({ scrollLeft: `+=${Math.abs(width)}vw` }, 500);
  };
  const handleLeftArrow = () => {
    const width = bar.current.offsetWidth;
    $('#villageBar').animate({ scrollLeft: `-=${Math.abs(width)}vw` }, 500);
  };
  /**
   *
   * @param {*} id - id of the active village
   * @returns styling for the active village
   */
  const styling = (id) => {
    if (id === props.activeVillageId) return { color: '#3883E6' };
  };
  return (
    <>
      <div className={Styles.villageBarContainer}>
        <div className={Styles.villageTriangleLeftIcon}>
          <VscTriangleLeft
            onClick={() => {
              handleLeftArrow();
            }}
          />
        </div>
        <div ref={bar} className={Styles.villageBar} id="villageBar">
          {props.villages.map((village) => {
            return (
              <div
                className={Styles.villageBarCell}
                key={village.id}
                style={styling(village.id)}
                onClick={() => props.handleBarCell(village.id, village.name)}
              >
                {village.name.split(' ')[0]}
                &nbsp;
                {village.name.split(' ')[1]}
              </div>
            );
          })}
        </div>
        <div className={Styles.villageTriangleRightIcon}>
          <VscTriangleRight
            onClick={() => {
              handleRightArrow();
            }}
          />
        </div>
      </div>
    </>
  );
};
export default Bar;
