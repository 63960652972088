import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Get } from '../../../services/apiCall';
import VillageChart from './VillageChart';
import VillageLoader from '../../Loader/hash';
import translator from '../../../assets/json/translator.json';
import roundOff from '../../../services/round';
import Styles from './Village.module.css';
import { sortDatesByYearAndMonth } from '../../../services/genericSortMonthYear';
import { useContext } from 'react';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';

/**
 *
 * @component Village
 * @description
 * This component represents the village's stats that comes under that active village on the
 * bottom part of the Taluka page.
 */

const Village = (props) => {
  const { month, year, handlePageHead } = useContext(TimelineContext);
  const { language, programId } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);
  const { clusterId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [area, setArea] = useState('');
  const [watershedNumber, setWatershedNumber] = useState('');
  const [groundwaterDevelopment, setGroundwaterDevelopment] = useState(0.0);
  const [maxTemperature, setMaxTemperature] = useState(0.0);
  const [minTemperatue, setMinTemperature] = useState(0.0);
  const [windSpeed, setWindSpeed] = useState(0.0);
  const [humanPopulation, setHumanPopulation] = useState(0);
  const [sheepPopulation, setSheepPopulation] = useState(0);
  const [poultryPopulation, setPoultryPopulation] = useState(0);
  const [cattlePopulation, setCattlePopulation] = useState(0);
  const [currentRainfall, setCurrentRainfall] = useState(0.0);
  const [averageRainfall, setAverageRainfall] = useState(0.0);
  const [waterAvailable, setWaterAvailable] = useState(0.0);

  useEffect(() => {
    const initialFunction = async () => {
      setIsLoading(true);
      if (year && props.activeVillageId) {
        const params = {
          year: year,
          month: month + 1,
          villageId: props.activeVillageId,
          language: language,
          programId: programId,
          clusterId: clusterId,
        };
        const response = await Get(
          '/analytics/village-overview',
          token,
          params,
        );
        let area = '',
          watershedNumber = '',
          groundwaterDevelopment = 0.0,
          maxTemperature = 0.0,
          minTemperatue = 0.0,
          windspeed = 0.0,
          humanPopulation = 0,
          sheepPopulation = 0,
          poultryPopulation = 0,
          cattlePopulation = 0,
          currentRainfall = 0.0,
          averageRainfall = 0.0,
          waterAvailable = 0.0;

        if (response && response.data) {
          const data = response.data;
          area = data.area ? data.area : '';
          watershedNumber = data.watershed_number ? data.watershed_number : '';

          if (
            data.VillageMonthlyStats1.length > 0 &&
            data.VillageRainfall.length > 0
          ) {
            const VillageMonthlyStats1sData = data.VillageMonthlyStats1[0];
            const VillageRainfallData = data.VillageRainfall[0];

            averageRainfall = VillageRainfallData.average_rainfall_mm_cumulative
              ? VillageRainfallData.average_rainfall_mm_cumulative
              : 0.0;
            currentRainfall = VillageRainfallData.current_rainfall_mm_cumulative
              ? VillageRainfallData.current_rainfall_mm_cumulative
              : 0.0;
            waterAvailable = VillageMonthlyStats1sData.total_water_available
              ? VillageMonthlyStats1sData.total_water_available
              : 0.0;
          }
          if (data.VillageMetData.length > 0) {
            let sortedData = sortDatesByYearAndMonth(data.VillageMetData);

            let currentMonthMetData = sortedData?.filter((vals) => {
              return vals.month === month + 1 && vals.year === Number(year);
            });

            let VillageMetDataData = currentMonthMetData[0];

            maxTemperature = VillageMetDataData?.temperature_max
              ? VillageMetDataData?.temperature_max
              : 0.0;
            minTemperatue = VillageMetDataData?.temperature_min
              ? VillageMetDataData?.temperature_min
              : 0.0;
            windspeed = VillageMetDataData?.wind_speed
              ? VillageMetDataData?.wind_speed
              : 0.0;
          }
          if (data.VillageYearlyStats.length > 0) {
            const VillageYearlyStatsData = data.VillageYearlyStats[0];
            humanPopulation = VillageYearlyStatsData.total_human
              ? VillageYearlyStatsData.total_human
              : 0.0;
            sheepPopulation = VillageYearlyStatsData.total_sheep
              ? VillageYearlyStatsData.total_sheep
              : 0.0;
            poultryPopulation = VillageYearlyStatsData.total_poultry
              ? VillageYearlyStatsData.total_poultry
              : 0.0;
            cattlePopulation = VillageYearlyStatsData.total_cattle
              ? VillageYearlyStatsData.total_cattle
              : 0.0;
          }
          const groundwater =
            (data?.PreviousMonthVillageMonthlyStats2?.[0]
              ?.groundwater_level_tcm ?? 0) -
            (data?.PreviousMonthVillageMonthlyStats2?.[0]
              ?.groundwater_residual ?? 0);
          const currentMonthDraft =
            (data?.VillageMonthlyStats2?.[0]?.total_human_water_consumption ??
              0) +
            (data?.VillageMonthlyStats2?.[0]?.total_poultry_water_consumption ??
              0) +
            (data?.VillageMonthlyStats2?.[0]?.total_cattle_water_consumption ??
              0) +
            (data?.VillageMonthlyStats2?.[0]?.total_sheep_water_consumption ??
              0) +
            (data?.TotalCropWaterRequirement ?? 0);
          groundwaterDevelopment =
            groundwater === 0
              ? Number.MAX_SAFE_INTEGER
              : (currentMonthDraft / groundwater) * 100;
        }
        setArea(area);
        setWatershedNumber(watershedNumber);
        setGroundwaterDevelopment(groundwaterDevelopment);
        setMaxTemperature(maxTemperature);
        setMinTemperature(minTemperatue);
        setWindSpeed(windspeed);
        setHumanPopulation(humanPopulation);
        setSheepPopulation(sheepPopulation);
        setPoultryPopulation(poultryPopulation);
        setCattlePopulation(cattlePopulation);
        setCurrentRainfall(currentRainfall);
        setAverageRainfall(averageRainfall);
        setWaterAvailable(waterAvailable);
      }
      setIsLoading(false);
    };
    initialFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeVillageId]);

  const chooseColor = (level) => {
    if (level >= 0 && level <= 70) return { color: '#50B432' };
    if (level > 70 && level <= 100) return { color: '#DDDF00' };
    return { color: '#ED561B' };
  };
  const groundwaterCondition = (level) => {
    if (level >= 0 && level <= 70) return 'Safe';
    if (level > 70 && level <= 100) return 'Critical';
    return 'Over Exploited';
  };
  const percentageDeviation = (currentRainfall, averageRainfall) => {
    if (averageRainfall && averageRainfall === 0) return 0;
    return Math.abs(
      ((currentRainfall - averageRainfall) / averageRainfall) * 100,
    );
  };
  const colorPercentageDeviation = (currentRainfall, averageRainfall) => {
    let val = 0;
    if (averageRainfall && averageRainfall === 0) val = 0;
    val = ((currentRainfall - averageRainfall) / averageRainfall) * 100;
    if (val < 0) return { color: '#ED561B' };
    return { color: '#50B432' };
  };

  return !isLoading ? (
    <>
      <div className={Styles.villageTalukaContainer}>
        <div className={Styles.villageTalukaLeftBox}>
          <div className={Styles.villageTalukaHead}>
            {translator[language]['Village details']}
          </div>
          <div className={Styles.villageTalukaBox}>
            <table>
              <tbody>
                <tr>
                  <td className={Styles.col1}>
                    {translator[language]['Area']}
                  </td>
                  <td className={Styles.col2}>
                    {roundOff(area)} {translator[language]['Ha']}
                  </td>
                </tr>
                <tr>
                  <td className={Styles.col1}>
                    {translator[language]['Watershed Number']}
                  </td>
                  <td className={Styles.col2}>{watershedNumber}</td>
                </tr>
                <tr>
                  <td className={Styles.col1}>
                    {translator[language]['Groundwater Development']}
                  </td>
                  <td
                    className={Styles.col2}
                    style={chooseColor(groundwaterDevelopment)}
                  >
                    {roundOff(groundwaterDevelopment)}
                    {'%'}
                  </td>
                </tr>
                <tr>
                  <td className={Styles.col1}>
                    {translator[language]['Condition']}
                  </td>
                  <td
                    className={Styles.col2}
                    style={chooseColor(groundwaterDevelopment)}
                  >
                    {
                      translator[language][
                        groundwaterCondition(groundwaterDevelopment)
                      ]
                    }
                  </td>
                </tr>
                <tr>
                  <td className={Styles.col1} style={{ paddingTop: '3%' }}>
                    {translator[language]['Maximum']}{' '}
                    {translator[language]['Temperature']}
                  </td>
                  <td className={Styles.col2} style={{ paddingTop: '3%' }}>
                    {roundOff(maxTemperature)} &#176;C
                  </td>
                </tr>
                <tr>
                  <td className={Styles.col1} style={{ paddingBottom: '3%' }}>
                    {translator[language]['Minimum']}{' '}
                    {translator[language]['Temperature']}
                  </td>
                  <td className={Styles.col2} style={{ paddingBottom: '3%' }}>
                    {roundOff(minTemperatue)} &#176;C
                  </td>
                </tr>
                <tr>
                  <td className={Styles.col1}>
                    {translator[language]['Windspeed']}
                  </td>
                  <td className={Styles.col2}>
                    {roundOff(windSpeed)} {translator[language]['km/h']}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={Styles.villageTalukaMiddleBox}>
          <div className={Styles.villagePopulationHead}>
            {translator[language]['Village']}{' '}
            {translator[language]['Population']}
          </div>

          <div className={Styles.villageProgramGraphContainer}>
            <div className={Styles.villagePopulationHeadMobile}>
              {translator[language]['Village']}{' '}
              {translator[language]['Population']}
            </div>

            <div
              className={Styles.villageChartGraph}
              // style={{ height: '120px' }}
            >
              <VillageChart
                humanPopulation={humanPopulation}
                cattlePopulation={cattlePopulation}
                sheepPopulation={sheepPopulation}
                poultryPopulation={poultryPopulation}
                language={language}
              />
            </div>
          </div>

          <div className={Styles.villagePopulationLegend}>
            <div className={Styles.villagePopulationLegendHumans}>
              <div
                style={{ color: '#84b5f7' }}
                className={Styles.legendHeading}
              >
                <span
                  className={Styles.dotPopulation}
                  style={{ backgroundColor: '#84b5f7' }}
                />
                &nbsp; {translator[language]['Humans']}
              </div>
              <div className={Styles.legendValue}>{humanPopulation}</div>
            </div>
            <div className={Styles.villagePopulationLegendCattles}>
              <div
                style={{ color: '#073f88' }}
                className={Styles.legendHeading}
              >
                <span
                  className={Styles.dotPopulation}
                  style={{ backgroundColor: '#073f88' }}
                />
                &nbsp; {translator[language]['Cattles']}
              </div>
              <div className={Styles.legendValue}>{cattlePopulation}</div>
            </div>
            <div className={Styles.villagePopulationLegendSheeps}>
              <div
                style={{ color: '#0c69e3' }}
                className={Styles.legendHeading}
              >
                <span
                  className={Styles.dotPopulation}
                  style={{ backgroundColor: '#0c69e3' }}
                />
                &nbsp; {translator[language]['Sheeps']}
              </div>
              <div className={Styles.legendValue}>{sheepPopulation}</div>
            </div>
            <div className={Styles.villagePopulationLegendPoultries}>
              <div
                style={{ color: '#5776a2' }}
                className={Styles.legendHeading}
              >
                <span
                  className={Styles.dotPopulation}
                  style={{ backgroundColor: '#5776a2' }}
                />
                &nbsp; {translator[language]['Poultries']}
              </div>
              <div className={Styles.legendValue}>{poultryPopulation}</div>
            </div>
          </div>
        </div>
        <div className={Styles.villageTalukaRightBox}>
          <div
            className={`${Styles.mobileRainfallHeading} ${Styles.villagePopulationHead}`}
            style={{ textAlign: 'left' }}
          >
            Rainfall Details
          </div>
          <div className={Styles.rowsParentMobile}>
            <div className={Styles.sameRow}>
              <div>
                {translator[language]['Current']} {translator[language]['Year']}{' '}
                {translator[language]['Rainfall']}
              </div>
              <div className={Styles.value}>
                {roundOff(currentRainfall)} {translator[language]['mm']}
              </div>
            </div>
            <div className={Styles.sameRow}>
              <div>
                {translator[language]['Average']}{' '}
                {translator[language]['Rainfall']}
              </div>
              <div className={Styles.value}>
                {roundOff(averageRainfall)} {translator[language]['mm']}
              </div>
            </div>
            <div className={Styles.sameRow}>
              <div>
                {translator[language]['Percentage deviation to average']}
              </div>
              <div
                className={Styles.value}
                style={colorPercentageDeviation(
                  currentRainfall,
                  averageRainfall,
                )}
              >
                {roundOff(
                  percentageDeviation(currentRainfall, averageRainfall),
                )}
                {'%'}
              </div>
            </div>
            <div className={Styles.mobileWaterHeadFlex}>
              <div className={Styles.waterHead}>
                {translator[language]['Current']}{' '}
                {translator[language]['Water']}{' '}
                {translator[language]['Availability']}
              </div>
              <div
                className={`${Styles.water} ${Styles.value} ${Styles.color}`}
              >
                {roundOff(waterAvailable)} {translator[language]['TCM']}
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.villageDetailsButton}>
          <Link
            onClick={() => handlePageHead('Analytics Overview')}
            to={`/analytics-overview/programId/${programId}/clusterId/${clusterId}/villageId/${props.activeVillageId}`}
            className={Styles.villageUnderTalukaButton}
          >
            {translator[language]['View Village Details']}
            &nbsp;&nbsp;&nbsp;&nbsp;&#62;
          </Link>
        </div>
      </div>
    </>
  ) : (
    <VillageLoader
      style={{
        position: 'relative',
        marginLeft: '50%',
        marginTop: '10%',
      }}
    />
  );
};
export default Village;
