import styles from './index.module.css';
import SegmentChart from './SegmentChart';

const GroundwaterSource = ({ gis }) => {
  return (
    <div>
      <div className={styles.header}>Groundwater Source</div>
      <div className={styles.content}>
        {Object.keys(gis).map((village, index) => (
          <div key={village} className={styles.villageSection}>
            <div className={styles.villageName}>
              <b>{village}</b>: Groundwater well distribution
            </div>
            <div className={styles.chartContainer}>
              <SegmentChart
                india_mark_hand_pumps={gis[village].india_mark_hand_pumps}
                shallow_hand_pumps={gis[village].shallow_hand_pumps}
                submersible_hand_pumps={gis[village].submersible_hand_pumps}
              />
            </div>
            <div className={styles.chartIndex}>
              <div className={styles.indexItem}>
                <div className={styles.indiaMarkColor}></div>
                <div>
                  India mark Hand Pumps:{' '}
                  <b>{gis[village].india_mark_hand_pumps}</b>
                </div>
              </div>
              <div className={styles.indexItem}>
                <div className={styles.shallowMarkColor}></div>
                <div>
                  Shallow mark Hand Pumps:{' '}
                  <b>{gis[village].shallow_hand_pumps}</b>
                </div>
              </div>
              <div className={styles.indexItem}>
                <div className={styles.submersibleColor}></div>
                <div>
                  Submersible Hand Pumps:{' '}
                  <b>{gis[village].submersible_hand_pumps}</b>
                </div>
              </div>
            </div>
            {index < Object.keys(gis).length - 1 && (
              <hr className={styles.divider} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroundwaterSource;
