import React from 'react';
import { Slider, Box, SliderThumb } from '@mui/material';
import styledComponents from 'styled-components';
import { styled } from '@mui/material/styles';

// import { withStyles, makeStyles } from "@material-ui/core/styles";

const RsuiteBarSupply = (props) => {
  const marks = [
    {
      value: 0,
      label: '0',
    },

    {
      value: 50,
      label: '50',
    },

    {
      value: 100,
      label: '100',
    },
  ];

  const PrettoSlider = styled(Slider)({
    color:
      (props.cropListMode === 'Recommended' ||
        props.cropListMode === 'Manual') &&
      'rgba(56, 131, 230, 1)!important',
    height: 10,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      display: props.currentCrop === 'Current Situation' && 'none',
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      // border: "2px solid currentColor",
      border: '2px solid rgba(255, 255, 255, 1)',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },

      '& .airbnb-bar': {
        height: 14,
        width: 14,
        backgroundColor:
          props.cropListMode === 'Manual'
            ? 'currentColor'
            : 'rgba(32, 88, 161, 1)',
        marginLeft: 1,
        marginRight: 1,
        color: 'rgba(56, 131, 230, 1)',
        borderRadius: '100%',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      // backgroundColor: "#52af77",
      backgroundColor: 'rgba(32, 88, 161, 1)',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },

    '& .css-1tfve6q-MuiSlider-mark': {
      position: 'unset',
    },

    '& .css-6cwnna-MuiSlider-mark': {
      position: 'unset',
    },

    '& .css-14pt78w-MuiSlider-rail': {
      background:
        props.cropListMode === 'Manual'
          ? 'rgba(216, 216, 216, 1)'
          : props.currentCrop === 'Demand Side'
          ? 'linear-gradient( to right, rgba(55, 129, 227, 1) 0, rgba(55, 129, 227, 1) 80%, rgba(216, 216, 216, 1) 80%, rgba(216, 216, 216, 1) 100% )'
          : 'rgba(216, 216, 216, 1)',
    },

    '&.Mui-disabled': {
      color: 'rgba(32, 88, 161, 1);',
    },

    '& .css-yafthl-MuiSlider-markLabel': {
      '@media screen and (max-width:600px)': {
        fontSize: '.6rem',
      },
    },

    '& .css-1eoe787-MuiSlider-markLabel': {
      '@media screen and (max-width:600px)': {
        fontSize: '.6rem',
      },
    },
  });

  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <span className="airbnb-bar" />
      </SliderThumb>
    );
  }

  return (
    <Wrapper>
      <Box className="supply-rangebarbox">
        <PrettoSlider
          onChangeCommitted={(event, value) => props.changeSupplyRange(value)}
          valueLabelDisplay="auto"
          aria-label="pretto slider"
          marks={marks}
          defaultValue={props.supplyCurrentRange}
          step={1}
          disabled={
            (props.currentCrop === 'Current Situation' ||
              props.cropListMode === 'Recommended') &&
            true
          }
          // defaultValue={60}
          components={{ Thumb: AirbnbThumbComponent }}
        />
      </Box>
    </Wrapper>
  );
};

export default RsuiteBarSupply;

const Wrapper = styledComponents.div`

@media screen and (max-width:600px){
  width:90%;
}

  .supply-rangebarbox{
    width: 250px;

    @media screen and (max-width:600px){
      width:100%;
    }

  }

    @media screen and (min-width: 1700px) {
      .supply-rangebarbox{
        width: unset;
      }

      width:36%;
    }
`;
