// utility file to deal with nested tree objects with structure like
/**
 *{
 *  id: ...,
 *  parentId: ...,
 *  children: [
 *      {id: ..., parentId: ..., children: []}
 *  ]
 * }
 */

export const GIS_LAYERS = [
  {
    id: 1,
    name: 'Layers',
    parentId: null, // Top-level item
    children: [
      {
        id: 2,
        name: 'Groundwater Source',
        parentId: 1,
        lightThemeHexCode: '#FFEBE8',
        darkThemeHexCode: '#FB7962',
        dropdownHexCode: '#F36046',
        checkboxHexCode: '#7F3721',
        children: [
          {
            id: 3,
            name: 'Drinking water sources',
            parentId: 2,
            dropdownHexCode: '#F36046',
            checkboxHexCode: '#7F3721',
            children: [],
          },
          {
            id: 4,
            name: 'Non-drinking water sources',
            parentId: 2,
            dropdownHexCode: '#F36046',
            checkboxHexCode: '#7F3721',
            children: [],
          },
        ],
      },
      {
        id: 5,
        name: 'Surfacewater Source',
        parentId: 1,
        lightThemeHexCode: '#FFF3D3',
        darkThemeHexCode: '#FFD562',
        dropdownHexCode: '#E9AA2E',
        checkboxHexCode: '#7F6621',
        children: [
          {
            id: 6,
            name: 'Village Pond',
            parentId: 5,
            dropdownHexCode: '#E9AA2E',
            checkboxHexCode: '#7F6621',
            children: [],
          },
          {
            id: 7,
            name: 'Farm pond',
            parentId: 5,
            dropdownHexCode: '#E9AA2E',
            checkboxHexCode: '#7F6621',
            children: [],
          },
          {
            id: 8,
            name: 'Percolation Tank',
            parentId: 5,
            dropdownHexCode: '#E9AA2E',
            checkboxHexCode: '#7F6621',
            children: [],
          },
          {
            id: 9,
            name: 'Check Dams',
            parentId: 5,
            dropdownHexCode: '#E9AA2E',
            checkboxHexCode: '#7F6621',
            children: [],
          },
        ],
      },
      {
        id: 11,
        name: 'Water Distribution Network',
        parentId: 1,
        lightThemeHexCode: '#E2FAFF',
        darkThemeHexCode: '#7AE2FA',
        dropdownHexCode: '#10CDE6',
        checkboxHexCode: '#216C7F',
        children: [
          {
            id: 12,
            name: 'Water Treatment Plant',
            parentId: 11,
            dropdownHexCode: '#10CDE6',
            checkboxHexCode: '#216C7F',
            children: [],
          },
          {
            id: 13,
            name: 'Elevated Storage Reservoir',
            parentId: 11,
            dropdownHexCode: '#10CDE6',
            checkboxHexCode: '#216C7F',
            children: [],
          },
          {
            id: 14,
            name: 'Piped Distribution Network to Households',
            parentId: 11,
            dropdownHexCode: '#10CDE6',
            checkboxHexCode: '#216C7F',
            children: [],
          },
          {
            id: 15,
            name: 'Grey water Infrastructure',
            parentId: 11,
            dropdownHexCode: '#10CDE6',
            checkboxHexCode: '#216C7F',
            children: [],
          },
        ],
      },
      {
        id: 16,
        name: 'Village water budget',
        parentId: 1,
        lightThemeHexCode: '#F1EBFF',
        darkThemeHexCode: '#9C79EF',
        dropdownHexCode: '#7B49F2',
        checkboxHexCode: '#39217F',
        children: [
          {
            id: 17,
            name: 'All drinking water sources',
            parentId: 16,
            dropdownHexCode: '#7B49F2',
            checkboxHexCode: '#39217F',
            children: [
              {
                id: 18,
                name: 'Groundwater sources',
                parentId: 17,
                dropdownHexCode: '#7B49F2',
                checkboxHexCode: '#39217F',
                children: [],
              },
              {
                id: 19,
                name: 'Surface Water structures',
                parentId: 17,
                isDisabled: true,
                dropdownHexCode: '#7B49F2',
                checkboxHexCode: '#39217F',
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 20,
        name: 'Population density',
        parentId: 1,
        lightThemeHexCode: '#F4FFE4',
        darkThemeHexCode: '#B9EB73',
        dropdownHexCode: '#87D020',
        checkboxHexCode: '#496B17',
        children: [
          {
            id: 21,
            name: 'Land use patterns',
            parentId: 20,
            dropdownHexCode: '#87D020',
            checkboxHexCode: '#496B17',
            children: [
              {
                id: 22,
                name: 'Residential area',
                parentId: 21,
                dropdownHexCode: '#87D020',
                checkboxHexCode: '#496B17',
                children: [],
              },
              {
                id: 23,
                name: 'Agriculture area ',
                parentId: 21,
                dropdownHexCode: '#87D020',
                checkboxHexCode: '#496B17',
                children: [],
              },
              {
                id: 24,
                name: 'Public area',
                parentId: 21,
                dropdownHexCode: '#87D020',
                checkboxHexCode: '#496B17',
                children: [],
              },
              {
                id: 25,
                name: 'No man area',
                parentId: 21,
                dropdownHexCode: '#87D020',
                checkboxHexCode: '#496B17',
                children: [],
              },
            ],
          },
          {
            id: 26,
            name: 'Institutions',
            parentId: 20,
            dropdownHexCode: '#87D020',
            checkboxHexCode: '#496B17',
            children: [
              {
                id: 27,
                name: 'Community Standposts',
                parentId: 26,
                dropdownHexCode: '#87D020',
                checkboxHexCode: '#496B17',
                children: [],
              },
              {
                id: 28,
                name: 'Schools',
                parentId: 26,
                dropdownHexCode: '#87D020',
                checkboxHexCode: '#496B17',
                children: [],
              },
              {
                id: 29,
                name: 'Temples',
                parentId: 26,
                dropdownHexCode: '#87D020',
                checkboxHexCode: '#496B17',
                children: [],
              },
              {
                id: 30,
                name: 'Hospitals',
                parentId: 26,
                dropdownHexCode: '#87D020',
                checkboxHexCode: '#496B17',
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const GW_ID = 2;
export const SW_ID = 5;
export const WDN_ID = 11;
export const VWB_ID = 16;
export const PD_ID = 20;
export const DW_ID = 3;
export const NDW_ID = 4;
export const WTP_ID = 12;
export const ESR_ID = 13;
export const PDN_ID = 14;
export const GWI_ID = 15;
export const CS_ID = 27;
export const SC_ID = 28;
export const TE_ID = 29;
export const HO_ID = 30;
export const RA_ID = 22;
export const AA_ID = 23;
export const PA_ID = 24;
export const AMA_ID = 25;
export const VP_ID = 6;
export const FP_ID = 7;
export const PT_ID = 8;
export const CD_ID = 9;

export const LAYERS = {
  GW: 'Groundwater source',
  SW: 'Surfacewater source',
  WDN: 'Water distribution network',
  VWB: 'Village water budget',
  PD: 'Population density',
};

export const POINT_TYPES = {
  WELL: 'Well',
  WATER_STRUCTURE: 'Water structure',
  WTP: 'Water treatment plant',
  ESR: 'Elevated storage reservoir',
  PDN: 'Pipe distribution network',
  GWI: 'Grey water infrastructure',
  INSTITUTION: 'Institution',
  GIS_LAND_USE: 'Land use',
};

export const pointLayerMapping = {
  [POINT_TYPES.WELL]: LAYERS.GW,
  [POINT_TYPES.WATER_STRUCTURE]: LAYERS.SW,
  [POINT_TYPES.WTP]: LAYERS.WDN,
  [POINT_TYPES.ESR]: LAYERS.WDN,
  [POINT_TYPES.PDN]: LAYERS.WDN,
  [POINT_TYPES.GWI]: LAYERS.WDN,
  [POINT_TYPES.INSTITUTION]: LAYERS.PD,
};

export function getAllIds(data) {
  let ids = [];

  function traverse(node) {
    ids.push(node.id);
    if (node.children && node.children.length > 0) {
      for (let child of node.children) {
        traverse(child);
      }
    }
  }

  for (let item of data) {
    traverse(item);
  }

  return ids;
}

export function getRelatedIds(data, id) {
  let targetNode = null;

  function getAllChildIds(node) {
    let ids = [];
    function traverse(children) {
      for (let child of children) {
        ids.push(child.id);
        if (child.children && child.children.length > 0) {
          traverse(child.children);
        }
      }
    }
    traverse(node.children);
    return ids;
  }

  function getAllParentIds(data, targetId) {
    let parents = [];
    function findParents(node, parentIds = []) {
      if (node.id === targetId) {
        parents = parentIds;
        return true;
      }
      if (node.children && node.children.length > 0) {
        for (let child of node.children) {
          if (findParents(child, [...parentIds, node.id])) {
            return true;
          }
        }
      }
      return false;
    }

    for (let item of data) {
      if (findParents(item)) {
        break;
      }
    }
    return parents;
  }

  function findNode(node) {
    if (node.id === id) {
      targetNode = node;
      return true;
    }
    if (node.children && node.children.length > 0) {
      for (let child of node.children) {
        if (findNode(child)) {
          return true;
        }
      }
    }
    return false;
  }

  for (let item of data) {
    if (findNode(item)) {
      break;
    }
  }

  if (!targetNode) {
    return { childIds: [], parentIds: [] };
  }

  const childIds = getAllChildIds(targetNode);
  const parentIds = getAllParentIds(data, id);

  return { childIds, parentIds };
}

export const findNodeById = (items, id) => {
  for (const item of items) {
    if (item.id === id) {
      return item;
    }
    if (item.children) {
      const found = findNodeById(item.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

export function updateParentIfChildrenUnchecked(data, id, newCheckedItems) {
  if (id) {
    const node = findNodeById(data, id);
    const childrenIds = node?.children.map((child) => child.id) || [];
    if (childrenIds.length === 0) {
      return;
    }
    const anyChildChecked = childrenIds.some((key) => {
      return newCheckedItems[key];
    });
    newCheckedItems[id] = anyChildChecked;
    node &&
      updateParentIfChildrenUnchecked(data, node.parentId, newCheckedItems);
  }
}
