import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TalukaLoader from '../../Loader/hash';
import { Get } from '../../../services/apiCall';
import TalukaChart from './TalukaChart';
import translator from '../../../assets/json/translator.json';
import HomeStyles from './Taluka.module.css';
import Box from '@mui/material/Box';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';

const exploitedThreshold = 100;
const criticalThreshold = 70;

/**
 *
 * @component Taluka
 * @states
 * safeVillage --> An array which stores all the village which are in safe stage i.e, their
 * ground water level is less that critical threshold
 * criticalVillage --> An array which stores all the village which are in critical stage i.e, their
 * ground water level is less that exploited threshold
 * exploitedVillage --> An array which stores all the village which are in exploited stage i.e, their
 * ground water level is more that exploited threshold
 * @description
 * This component represents the villages that comes under that active taluka on the
 * bottom part of the Home page.
 */

const Taluka = (props) => {
  const { token } = useContext(AuthContext);
  const { language, programId } = useContext(GeneralContext);
  const { month, year, handlePageHead } = useContext(TimelineContext);

  const [isLoading, setIsLoading] = useState(true);
  const [safeVillage, setSafeVillage] = useState([]);
  const [criticalVillage, setCriticalVillage] = useState([]);
  const [exploitedVillage, setExploitedVillage] = useState([]);

  useEffect(() => {
    const initialFunction = async () => {
      if (props.activeTalukaId !== '') {
        setIsLoading(true);

        const params = {
          talukaId: props.activeTalukaId,
          year: year,
          month: month + 1,
          language: language,
          programId: programId,
          clusterId: props.activeTalukaId,
        };
        const response = await Get(
          '/info/villages-under-cluster',
          token,
          params,
        );
        if (response.status === 'success') {
          const stats = response.data;
          const safeVillageStats = [],
            criticalVillageStats = [],
            exploitedVillageStats = [];
          if (stats && stats.length > 0) {
            stats.forEach((element) => {
              const groundwater =
                (element.VillageMonthlyStats2?.[0]?.groundwater_level_tcm ??
                  0) -
                (element.VillageMonthlyStats2?.[0]?.groundwater_residual ?? 0);
              const currentMonthDraft =
                (element?.VillageMonthlyStats2?.[0]
                  ?.total_human_water_consumption ?? 0) +
                (element?.VillageMonthlyStats2?.[0]
                  ?.total_poultry_water_consumption ?? 0) +
                (element?.VillageMonthlyStats2?.[0]
                  ?.total_cattle_water_consumption ?? 0) +
                (element?.VillageMonthlyStats2?.[0]
                  ?.total_sheep_water_consumption ?? 0) +
                (element?.TotalCropWaterRequirement ?? 0);
              const stageOfGWDevelopment =
                groundwater === 0
                  ? Number.MAX_SAFE_INTEGER
                  : (currentMonthDraft / groundwater) * 100;
              if (
                stageOfGWDevelopment >= 0 &&
                stageOfGWDevelopment <= criticalThreshold
              )
                safeVillageStats.push(element);
              else if (
                stageOfGWDevelopment > criticalThreshold &&
                stageOfGWDevelopment <= exploitedThreshold
              )
                criticalVillageStats.push(element);
              else exploitedVillageStats.push(element);
            });

            setSafeVillage(safeVillageStats);
            setCriticalVillage(criticalVillageStats);
            setExploitedVillage(exploitedVillageStats);
          }
        }

        setIsLoading(false);
      }
    };
    initialFunction();

    return () => {
      setSafeVillage([]);
      setCriticalVillage([]);
      setExploitedVillage([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTalukaId]);

  return !isLoading ? (
    <>
      <Box style={{ display: 'flex' }} className={HomeStyles['bottom-box']}>
        <div className={HomeStyles.villageProgramContainerLeft}>
          <div className={HomeStyles.villageProgramHead}>
            {translator[language]['Villages under program']}
          </div>
          <div className={HomeStyles.villageProgramBox}>
            <div className={HomeStyles.villageProgramSafe}>
              {safeVillage.map((village) => {
                return <div key={village.id}>{village.name}</div>;
              })}
            </div>
            <div className={HomeStyles.villageProgramCritical}>
              {criticalVillage.map((village) => {
                return <div key={village.id}>{village.name}</div>;
              })}
            </div>
            <div className={HomeStyles.villageProgramExploited}>
              {exploitedVillage.map((village) => {
                return <div key={village.id}>{village.name}</div>;
              })}
            </div>
          </div>
        </div>
        <div className={HomeStyles.villageProgramContainerRight}>
          <div className={HomeStyles.villageProgramGraphHead}>
            {translator[language]['Stage of Groundwater Development']}
          </div>
          <div className={HomeStyles.villageProgramGraphContainer}>
            <div
              className={HomeStyles.villageChartGraph}
              // style={{ height: '120px' }}
            >
              <TalukaChart
                safeVillage={safeVillage}
                criticalVillage={criticalVillage}
                exploitedVillage={exploitedVillage}
              />
            </div>
          </div>
        </div>

        {/* ADDED      */}
        <div className={HomeStyles.villageProgramContainerRightTwo}>
          <div className={HomeStyles.villageChartStats}>
            <p className={HomeStyles['safe-critical-exp']}>
              {safeVillage.length} {translator[language][`Villages are`]}
              <span style={{ color: '#50B432', textTransform: 'uppercase' }}>
                {' '}
                {translator[language][`Safe`]}{' '}
              </span>
              <span className={HomeStyles.fullStop}>.</span>
            </p>{' '}
            <p className={HomeStyles['safe-critical-exp']}>
              {criticalVillage.length} {translator[language]['Villages are']}
              <span
                style={{
                  color: 'rgb(221, 223, 0)',
                  textTransform: 'uppercase',
                }}
              >
                {' '}
                {translator[language][`Critical`]}{' '}
              </span>
              <span className={HomeStyles.fullStop}>.</span>
            </p>{' '}
            <p className={HomeStyles['safe-critical-exp']}>
              {exploitedVillage.length} {translator[language]['Villages are']}
              <span
                style={{
                  textTransform: 'uppercase',
                  color: 'rgb(237, 86, 27)',
                }}
              >
                {' '}
                {translator[language][`Over Exploited`]}{' '}
              </span>
              <span className={HomeStyles.fullStop}>.</span>
            </p>
          </div>
        </div>
        {/* ADDED  */}
        <div className={HomeStyles.talukaDetailsButton}>
          <Link
            onClick={() => handlePageHead(`Taluka ${props.activeTalukaName}`)}
            to={`/cluster/programId/${programId}/clusterId/${props.activeTalukaId}`}
            className={HomeStyles.talukaUnderProgramButton}
          >
            {translator[language]['View Taluka details']}
            &nbsp;&nbsp;&nbsp;&nbsp;&#62;
          </Link>
        </div>
      </Box>
    </>
  ) : (
    <TalukaLoader
      style={{
        position: 'relative',
        marginLeft: '50%',
        marginTop: '10%',
      }}
    />
  );
};
export default Taluka;
