import { GiPlainSquare } from 'react-icons/gi';
import styles from './Institution.module.css';

const Institution = ({ data }) => {
  return (
    <div>
      <div className={styles.header}>{data?.name}</div>
      <div className={styles.content}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-evenly',
            marginTop: '5%',
          }}
        >
          <div>
            <div style={{ fontSize: '22px', fontWeight: 700 }}>
              {Number(data?.lat).toFixed(2)}
            </div>
            <div style={{ height: '5px' }} />
            <div style={{ textAlign: 'center', fontSize: '12px' }}>
              Latitude
            </div>
          </div>
          <div>
            <div style={{ fontSize: '22px', fontWeight: 700 }}>
              {Number(data?.lng).toFixed(2)}
            </div>
            <div style={{ height: '5px' }} />
            <div style={{ textAlign: 'center', fontSize: '12px' }}>
              Longitude
            </div>
          </div>
        </div>
        <hr className={styles.divider} />
        <div
          style={{ display: 'flex', alignItems: 'center', padding: '0 10px' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <div
              style={{ display: 'flex', width: '70%', alignItems: 'center' }}
            >
              <div style={{ marginRight: '10px' }}>
                <GiPlainSquare color="#D6FB8C" fontSize={'22px'} />
              </div>
              <div style={{ fontSize: '12px' }}>Name of Institution</div>
            </div>
            <div style={{ width: '30%', textAlign: 'right' }}>{data?.name}</div>
          </div>
        </div>
        <hr className={styles.divider} />
        <div
          style={{ display: 'flex', alignItems: 'center', padding: '0 10px' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <div
              style={{ display: 'flex', width: '70%', alignItems: 'center' }}
            >
              <div style={{ marginRight: '10px' }}>
                <GiPlainSquare color="#D6FB8C" fontSize={'22px'} />
              </div>
              <div style={{ fontSize: '12px' }}>Name of village</div>
            </div>
            <div style={{ width: '30%', textAlign: 'right' }}>
              {data?.village_name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Institution;
