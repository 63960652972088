import React, { useContext } from 'react';
import { TimelineContext } from '../../services/context/context';
import Styles from './YearSession.module.css';

/**
 *
 * @component Year Session
 * @description
 * This component shows the year dropdown on the navbar for the plan page
 */

const YearSession = () => {
  const {
    currentPlanYear,
    organizationOnboardedDate,
    organizationEndDate,
    handlePlanYear,
  } = useContext(TimelineContext);

  const getYears = () => {
    let orgEndDateMonth = Number(organizationEndDate.split('-')[1]);
    let orgEndDateYear = Number(organizationEndDate.split('-')[0]);

    let organizationStartMonth = organizationOnboardedDate.split('-')[1];
    let organizationStartYear;

    if (organizationStartMonth < 6 && orgEndDateMonth > 6) {
      organizationStartYear = organizationStartYear =
        organizationOnboardedDate.split('-')[0] - 1;
    } else {
      organizationStartYear = organizationOnboardedDate.split('-')[0];
    }

    const yearSessions = [];

    for (let i = orgEndDateYear; i >= organizationStartYear; i--) {
      if (organizationStartMonth >= 6) {
        if (orgEndDateMonth >= 6) {
          yearSessions.push(`${i}-${i + 1}`);
        } else if (Number(i) !== Number(organizationStartYear)) {
          yearSessions.push(`${i - 1}-${i}`);
        }
      } else {
        if (orgEndDateMonth >= 6) {
          yearSessions.push(`${i}-${i + 1}`);
        } else {
          yearSessions.push(`${i - 1}-${i}`);
        }
      }
    }

    return yearSessions;
  };

  const handleYearClick = (e) => {
    const year = e.target.value;
    handlePlanYear(year);
  };

  return (
    <div className={Styles.year}>
      <select
        name="currentPlanYear"
        style={{ cursor: 'pointer' }}
        onChange={handleYearClick}
        value={currentPlanYear}
      >
        {getYears().map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

export default YearSession;
