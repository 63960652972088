import React, { useState } from 'react';
// FOR MODAL
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// import { TextField } from "@material-ui/core";
import Checkbox from '@mui/material/Checkbox';
import { createStyles, fade, makeStyles } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';
import translator from '../../assets/json/translator.json';
import { InputBase, Pagination, Stack } from '@mui/material';
import Box from '@material-ui/core/Box';
import { Get } from '../../services/apiCall';
import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext, GeneralContext } from '../../services/context/context';
import Styles from './ScenarioSummaryFilter.module.css';

const useStyles = makeStyles((theme) =>
  createStyles({
    search: {
      position: 'relative',
      border: '1px solid #000000',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),

      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
  }),
);

const ScenarioSummaryFilter = (props) => {
  const { language } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [pageNo, setPageNo] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const {
    globalArrayCount,
    setGlobalArrayCount,
    currentMappedData,
    setCurrentMappedData,
    referAnotherArray,
    setReferAnotherArray,
    duplicateArray,
    setDuplicateArray,

    currentFilterType,
    setCurrentFilterType,

    recommendedFilterArray,
    setRecommendedFilterArray,
  } = props;

  //   let scenarios = props.scenarios;

  //   console.log(scenarios, "PROPSAARHE");

  const [searchValue, setSearchValue] = useState('');

  const getBaseScenarios = async () => {
    const request = await Get(
      `/simulation/scenario/search?own=true&page=1&limit=5&programVillagePlanId=${props.globalPlanId}`,
      token,
    );
    if (request.status === 'success') {
      setCurrentMappedData(request.data.scenarios);
      setDuplicateArray(request.data.scenarios);
      setReferAnotherArray(request.data.scenarios);

      setGlobalArrayCount(request.data.scenarios);

      setTotalPageCount(request.data.totalCount);
    }
  };
  useEffect(() => {
    if (globalArrayCount.length === 0) {
      getBaseScenarios();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  const { openSaveModal, setOpenSaveModal } = props;

  const selectScenario = (item) => {
    // const mappedVals = duplicateArray.some((vals) => {
    const mappedVals = referAnotherArray.some((vals) => {
      if (vals.id === item.id) {
        return true;
      } else {
        return false;
      }
    });

    if (mappedVals) {
      //   const filtered = duplicateArray.filter((value) => value.id !== item.id);
      const filtered = referAnotherArray.filter(
        (value) => value.id !== item.id,
      );

      //   setDuplicateArray(filtered);
      setReferAnotherArray(filtered);
    } else {
      //   setDuplicateArray([...duplicateArray, { id: item.id }]);
      setReferAnotherArray([
        ...referAnotherArray,
        {
          id: item.id,
        },
      ]);
    }
  };

  const applyFunction = () => {
    // FOR RESETTING THE SCENARIOS

    // FAIZ
    let ff = referAnotherArray.filter((vals) => {
      return recommendedFilterArray.some((val) => {
        return vals.id === val.id;
      });
    });
    // console.log(ff, "FFFEATHEFRR");
    // FAIZ

    let arrOfObj = globalArrayCount.map((values) => {
      let finalVals = {
        id: values.id,
        name: values.name,
        owner: values.creator.name,
        'Water saved': values.data.totalWaterSaved,
        Aquifer: values.data.groundwaterLastDuration.toFixed(2),
        'Water balance': values.data.waterBalance,
        Type: values.Type.name,
      };

      return finalVals;
    });

    // ---------------
    const finalV = arrOfObj.map((vals, index) => {
      for (let i = 0; i <= ff.length; i++) {
        if (ff[i]?.id === vals.id) {
          return {
            ...vals,
            isRecommended: true,
          };
        }
      }

      return vals;
    });

    // console.log(finalV, "FINALVIVEK");
    // ---------------

    setOpenSaveModal(false);

    // props.getFilteredValues(referAnotherArray, arrOfObj);
    props.getFilteredValues(referAnotherArray, finalV);

    const machli = globalArrayCount.filter((vals) => {
      return referAnotherArray.some((val) => {
        return vals.id === val.id;
      });
    });

    const finalFilteredCase = machli.filter((elem, index) => {
      return (
        machli.findIndex((obj) => {
          return obj.id === elem.id;
        }) === index
      );
    });

    setReferAnotherArray(finalFilteredCase);
    setDuplicateArray(finalFilteredCase);
  };

  const newPageNo = async () => {
    let setOwnPayload;

    if (currentFilterType === 'Recommended') {
      const request = await Get(
        `/simulation/scenario/search?recommended=true&page=${pageNo}&limit=5&programVillagePlanId=${props.globalPlanId}`,
        token,
      );
      if (request.status === 'success') {
        setCurrentMappedData(request.data.scenarios);
        const finalData = [...globalArrayCount, ...request.data.scenarios];
        // const finaale = [...new Set(finalData)];
        // FOR REMOVING DUPLICATES
        const finalFilteredCase = finalData.filter((elem, index) => {
          return (
            finalData.findIndex((obj) => {
              return obj.id === elem.id;
            }) === index
          );
        });
        // FOR REMOVING DUPLICATES
        setGlobalArrayCount(finalFilteredCase);

        setTotalPageCount(request.data.totalCount);
      }
    } else {
      if (currentFilterType === 'Shared with me') {
        setOwnPayload = false;
      } else {
        setOwnPayload = true;
      }

      const request = await Get(
        `/simulation/scenario/search?own=${setOwnPayload}&page=${pageNo}&limit=5&programVillagePlanId=${props.globalPlanId}`,
        token,
      );

      if (request.status === 'success') {
        setCurrentMappedData(request.data.scenarios);

        const finalData = [...globalArrayCount, ...request.data.scenarios];

        // const finaale = [...new Set(finalData)];
        // FOR REMOVING DUPLICATES
        const finalFilteredCase = finalData.filter((elem, index) => {
          return (
            finalData.findIndex((obj) => {
              return obj.id === elem.id;
            }) === index
          );
        });
        // FOR REMOVING DUPLICATES

        setGlobalArrayCount(finalFilteredCase);

        setTotalPageCount(request.data.totalCount);
      }
    }
  };

  useEffect(() => {
    newPageNo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  //   new
  const changeFilterTypes = async (type) => {
    setPageNo(1);

    setSearchValue('');
    if (currentFilterType !== type) {
      if (type === 'Recommended') {
        setCurrentFilterType('Recommended');
        const request = await Get(
          `/simulation/scenario/search?recommended=true&page=1&limit=5&programVillagePlanId=${props.globalPlanId}`,
          token,
        );
        if (request.status === 'success') {
          setRecommendedFilterArray(request.data.scenarios);

          setCurrentMappedData(request.data.scenarios);
          const finalData = [...globalArrayCount, ...request.data.scenarios];
          // const finaale = [...new Set(finalData)];
          // FOR REMOVING DUPLICATES
          const finalFilteredCase = finalData.filter((elem, index) => {
            return (
              finalData.findIndex((obj) => {
                return obj.id === elem.id;
              }) === index
            );
          });
          // FOR REMOVING DUPLICATES
          setGlobalArrayCount(finalFilteredCase);

          setTotalPageCount(request.data.totalCount);
        }
      } else {
        let setOwnPayload;
        if (type === 'Shared with me') {
          setCurrentFilterType('Shared with me');
          setOwnPayload = false;
        } else if (type === 'Saved Scenarios') {
          setCurrentFilterType('Saved Scenarios');
          setOwnPayload = true;
        }
        const request = await Get(
          `/simulation/scenario/search?own=${setOwnPayload}&page=1&limit=5&programVillagePlanId=${props.globalPlanId}`,
          token,
        );
        if (request.status === 'success') {
          setCurrentMappedData(request.data.scenarios);

          const finalData = [...globalArrayCount, ...request.data.scenarios];
          // const finaale = [...new Set(finalData)];
          // FOR REMOVING DUPLICATES
          const finalFilteredCase = finalData.filter((elem, index) => {
            return (
              finalData.findIndex((obj) => {
                return obj.id === elem.id;
              }) === index
            );
          });
          // FOR REMOVING DUPLICATES

          setGlobalArrayCount(finalFilteredCase);

          setTotalPageCount(request.data.totalCount);
        }
      }
    }
  };

  const searchFunctionFIlter = async (e) => {
    setSearchValue(e.target.value);

    let setOwnPayload;

    if (currentFilterType === 'Shared with me') {
      setOwnPayload = false;
    } else if (currentFilterType === 'Saved Scenarios') {
      setOwnPayload = true;
    }

    const request = await Get(
      `/simulation/scenario/search?own=${setOwnPayload}&page=1&limit=5&programVillagePlanId=${props.globalPlanId}&term=${e.target.value}`,
      token,
    );

    if (request.status === 'success') {
      setCurrentMappedData(request.data.scenarios);
    }
  };

  const cancelFunction = () => {
    setRecommendedFilterArray([]);

    setReferAnotherArray(duplicateArray);
    setOpenSaveModal(false);
  };

  const checkedAllFunction = () => {
    const finalAllTrueChecked = referAnotherArray.filter((vals) => {
      return currentMappedData.some((val) => {
        return vals.id === val.id;
      });
    });
    return finalAllTrueChecked;
  };

  const selectAllFunction = () => {
    // LOGIC TRYING HERE

    const saad = [...referAnotherArray, ...currentMappedData];

    //TEMP STARTS

    let referIds = referAnotherArray.map((vals) => {
      return vals.id;
    });

    let currIds = currentMappedData.map((vals) => {
      return vals.id;
    });

    var ejguli = referIds.filter(function (obj) {
      return currIds.indexOf(obj) === -1;
    });

    let bilkulFar = ejguli.map((elems) => {
      return {
        id: elems,
      };
    });

    //TEMP ENDS

    // FOR REMOVING DUPLICATES (THE IF CASE IT IS NOW)
    const finalFilteredCase = saad.filter((elem, index) => {
      return (
        saad.findIndex((obj) => {
          return obj.id === elem.id;
        }) === index
      );
    });
    // FOR REMOVING DUPLICATES

    // LOGIC TRYING HERE

    if (checkedAllFunction().length !== currentMappedData.length) {
      setReferAnotherArray(finalFilteredCase);
    } else {
      if (referAnotherArray.length === currentMappedData.length) {
        let currentLength = referAnotherArray.length;
        let filterIds = referAnotherArray.filter((vals) => {
          return currentMappedData.some((val) => {
            return val.id === vals.id;
          });
        });

        if (currentLength === filterIds.length) {
          setReferAnotherArray([]);
        } else {
        }
      } else {
        setReferAnotherArray(bilkulFar);
      }
    }
  };

  // console.log(recommendedFilterArray, "RECOMFILTER");

  return (
    openSaveModal && (
      <Box component="form">
        <div style={{}}>
          <Dialog
            PaperProps={{ sx: { minWidth: '45%' } }}
            disableEscapeKeyDown
            open={openSaveModal}
            onClose={(n, reason) =>
              reason !== 'backdropClick' && setOpenSaveModal(false)
            }
          >
            <DialogTitle style={{ marginBottom: '10px', textAlign: 'center' }}>
              {translator[language]['Filters']}
            </DialogTitle>
            <DialogContent>
              <div className={Styles.filterParentDiv}>
                <div
                  className={Styles['left-side-filter-modal']}
                  style={{
                    borderRight: '1px solid black',
                    padding: '0 12px',
                  }}
                >
                  <p>{translator[language]['Categories']}</p>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        // background: "red",
                        width: '200px',
                        // marginBottom: "15px",
                        padding: '11px 5px',
                        background:
                          currentFilterType === 'Saved Scenarios'
                            ? 'rgba(68, 138, 231, 0.4)'
                            : 'unset',
                        cursor: 'pointer',
                      }}
                      onClick={() => changeFilterTypes('Saved Scenarios')}
                    >
                      <div style={{ fontWeight: 'bold' }}>
                        {translator[language]['Saved Scenarios']}
                      </div>
                      {/* <div>2</div> */}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        // background: "red",
                        width: '200px',
                        // marginBottom: "15px",
                        padding: '11px 5px',
                        cursor: 'pointer',
                        background:
                          currentFilterType === 'Shared with me'
                            ? 'rgba(68, 138, 231, 0.4)'
                            : 'unset',
                        // background: "rgba(68, 138, 231, 0.4)",
                        // fontSize: "18px",
                      }}
                      onClick={() => changeFilterTypes('Shared with me')}
                    >
                      <div style={{ fontWeight: 'bold' }}>
                        {translator[language]['Shared with me']}
                      </div>
                      {/* <div>4</div> */}
                    </div>{' '}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        // background: "red",
                        width: '200px',
                        padding: '11px 5px',
                        cursor: 'pointer',
                        // marginBottom: "15px",
                        background:
                          currentFilterType === 'Recommended'
                            ? 'rgba(68, 138, 231, 0.4)'
                            : 'unset',
                      }}
                      onClick={() => changeFilterTypes('Recommended')}
                    >
                      <div style={{ fontWeight: 'bold' }}>
                        {translator[language]['Recommendation']}
                      </div>
                      <div></div>
                    </div>
                    <div className={Styles.scenariosTab}>
                      {/* Selected - {checkedAllFunction().length} out of{" "}
                     
                      {currentMappedData.length} */}
                      {translator[language]['Selected']} -{' '}
                      <span
                        style={{
                          color: referAnotherArray.length > 5 ? 'red' : '',
                        }}
                      >
                        {referAnotherArray.length}{' '}
                      </span>{' '}
                      {translator[language]['Out of']} 5
                    </div>
                  </div>
                </div>
                {/* //LEFT SIDE ENDS HERE  */}

                <div className={Styles['right-side-filter-modal']}>
                  {/* <div> */}
                  <Box
                    component="form"
                    // sx={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                      <InputBase
                        value={searchValue}
                        onChange={(e) => searchFunctionFIlter(e)}
                        placeholder={translator[language]['Search']}
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search ' }}
                      />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <Checkbox
                          checked={
                            // duplicateArray.length === currentMappedData.length

                            checkedAllFunction().length ===
                            currentMappedData.length
                          }
                          onClick={() => selectAllFunction()}
                        />
                      </div>
                      <div>
                        <p>
                          {translator[language]['Select All']}(
                          {currentMappedData.length})
                        </p>
                      </div>
                    </div>

                    {/* <div>Admin</div> */}

                    <div
                      className={Styles['scenario-names']}
                      style={{
                        height: '170px',
                        overflow: 'scroll',
                      }}
                    >
                      {/* {scenarios.map((item) => { */}
                      {currentMappedData.map((item) => {
                        return (
                          <div
                            key={item.id}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <div>
                              <Checkbox
                                // onClick={() => selectScenario(item.id)}
                                // checked={duplicateArray.some((vals) => {
                                checked={referAnotherArray.some((vals) => {
                                  return vals.id === item.id;
                                })}
                                onClick={() => selectScenario(item)}
                              />
                            </div>
                            <div>
                              <p>
                                {item.name ? item.name : item.Type.name} -{' '}
                                {item.creator.role} ({item.creator.id}){' '}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* <div>CC- Dilip Jhala</div> */}

                    {/* PAGINATION   */}

                    <div
                      className={Styles.paginationFilter}
                      style={{ marginTop: '15px' }}
                    >
                      <Stack spacing={2}>
                        <Pagination
                          count={Math.ceil(totalPageCount / 5)}
                          // count={pageNo}
                          page={pageNo}
                          onChange={(e, val) => setPageNo(val)}
                        />
                      </Stack>
                    </div>

                    {/* PAGINATION   */}
                  </Box>
                  {/* </div> */}
                </div>
              </div>

              <div
                className="filter-bottom-buttons"
                style={{ textAlign: 'center', marginTop: '25px' }}
              >
                <div>
                  <button
                    onClick={() => cancelFunction()}
                    style={{
                      cursor: 'pointer',
                      padding: '6px 36px',
                      border: '1px solid #000000',
                      background: '#ffffff',
                      marginRight: '16px',
                    }}
                  >
                    {translator[language]['Cancel']}
                  </button>
                  <button
                    disabled={referAnotherArray.length > 5 ? true : false}
                    onClick={() => {
                      applyFunction();
                    }}
                    style={{
                      cursor: referAnotherArray.length > 5 ? '' : 'pointer',
                      padding: '6px 36px',
                      border:
                        referAnotherArray.length > 5
                          ? '1px solid #f3f3f3'
                          : '1px solid #448AE7',
                      background:
                        referAnotherArray.length > 5 ? '#f3f3f3' : '#448AE7',
                      color: referAnotherArray.length > 5 ? '' : '#ffffff',
                    }}
                  >
                    {translator[language]['Apply']}
                  </button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </Box>
    )
  );
};

export default ScenarioSummaryFilter;
