import { useEffect, useState } from 'react';
import Select from 'react-select';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import LoginLoader from '../components/Loader/climb';
import { Get, Post, getIPv4 } from '../services/apiCall';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { InputAdornment } from '@material-ui/core';
import base64 from 'base-64';
import Mixpanel from '../services/mixpanel.service';
import Styles from './Login.module.css';

/**
 *
 * @component Login
 * @states
 * loading ---> If true show loading animation
 * showPrograms ---> If true show a dropdown in login form to choose between different programs
 * currentProgram ---> Stores the current programId for which we are seeing the dashboard
 * errorMessage ---> Stores any error message, if there is an error while logging into the system
 */

// MATERIAL UI THEME
const theme = createTheme();

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  /**
   *
   * @param {event} e - Button Click event
   * @description takes the email, password and programId
   * from the event and sends an /auth/login post request
   * to the backend to login. If the login is successful we
   * get the authToken, userId and username of the user and we
   * store it in the local storage of the browser else if the login fails
   * we store the error in the errorMessage state
   */

  useEffect(() => {
    return () => {
      setAccountType(null);
      setErrorMessage(null);
      setIsPasswordVisible(false);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);

    const email = data.get('email');
    const password = data.get('password');

    // check if all the fields have been filled
    if (accountType) {
      if (!email || !password) {
        let msg = '*';
        if (!email) msg += ' Email,';
        if (!password) msg += ' Password,';
        setErrorMessage(`${msg} missing!`);
      } else login(email, password);
    } else {
      let msg = '*';
      if (!email) msg += ' Email,';
      if (!password) msg += ' Password,';
      setErrorMessage(`${msg} Account Type missing!`);
    }
  };

  const login = async (email, password) => {
    setLoading(true);

    const loginBody = {
      username: email,
      password: password,
      accountType: accountType,
      language: 'en',
    };

    const data = JSON.stringify(loginBody);

    const loginResult = await Post('/auth/login', null, data);
    if (loginResult.status === 'success') {
      const userProgramsParams = {
        language:
          loginResult.data.language_preference !== null
            ? loginResult.data.language_preference
            : 'en',
      };
      const userProgramResult = await Get(
        '/info/programs-under-user',
        loginResult.data.token,
        userProgramsParams,
      );
      if (
        userProgramResult &&
        userProgramResult.status === 'success' &&
        userProgramResult.data &&
        userProgramResult.data.length > 0
      ) {
        // process the logged-in user
        localStorage.setItem(
          base64.encode('defaultProgramId'),
          userProgramResult.data[0].id,
        );
        localStorage.setItem(
          base64.encode('defaultProgramName'),
          userProgramResult.data[0].name,
        );

        if (window.location.pathname === '/')
          props.handleProgramIdChange(userProgramResult.data[0].id);

        accountType !== 'fieldFacilitator'
          ? props.handleUser(
              loginResult.data.token,
              loginResult.data.email_id,
              loginResult.data.name,
              loginResult.data.email_id,
              accountType,
            )
          : props.handleUser(
              loginResult.data.token,
              loginResult.data.contact_number,
              loginResult.data.name,
              loginResult.data.email_id,
              accountType,
            );

        localStorage.setItem(
          base64.encode('authToken'),
          loginResult.data.token,
        );
        accountType !== 'fieldFacilitator'
          ? localStorage.setItem(
              base64.encode('username'),
              loginResult.data.email_id,
            )
          : localStorage.setItem(
              base64.encode('username'),
              loginResult.data.contact_number,
            );
        localStorage.setItem(
          base64.encode('emailId'),
          loginResult.data.email_id,
        );
        localStorage.setItem(base64.encode('name'), loginResult.data.name);
        localStorage.setItem(base64.encode('accountType'), accountType);
        localStorage.setItem(
          base64.encode('language'),
          loginResult.data.language_preference !== null
            ? loginResult.data.language_preference
            : 'en',
        );

        props.changeLangauge(
          loginResult.data.language_preference !== null
            ? loginResult.data.language_preference
            : 'en',
        );
        props.handleUrlManuallyChanges(userProgramResult.data[0].id);

        // MIXPANEL SETUP -> WHEN USER LOGS IN
        const getTokenJwt = (token) => {
          var base64Url = token.split('.')[1];
          var base64 = base64Url.replace('-', '+').replace('_', '/');
          return JSON.parse(window.atob(base64));
        };
        const tokenResponseData = getTokenJwt(
          localStorage.getItem(base64.encode('authToken')),
        );
        const getUsername = localStorage.getItem(base64.encode('name'));

        const mixpanelProps = {
          $name: getUsername,
          $email: tokenResponseData.emailId,
          Role: tokenResponseData.accountType,
          Username: tokenResponseData.username,
          $session_start: new Date(tokenResponseData.iat * 1000),
          $session_end: new Date(tokenResponseData.exp * 1000),
        };

        const IPv4 = await getIPv4();
        mixpanelProps.IPv4 = IPv4;

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              mixpanelProps.latitude = position.coords.latitude;
              mixpanelProps.longitude = position.coords.longitude;
            },
            function (error) {
              mixpanelProps.latitude = error.message;
              mixpanelProps.longitude = error.message;
            },
          );
        }
        // MIXPANEL SETUP -> WHEN USER LOGS IN
        Mixpanel.identify(tokenResponseData.username);
        Mixpanel.people.set(mixpanelProps);
      } else {
        setErrorMessage(`* Error while login!`);
      }
    } else {
      setErrorMessage(`* Wrong credentials!`);
    }
    setLoading(false);
  };

  /**
   *
   * @param {event} e - Click event for the programs dropdown
   * @description set the state to the current choosen program
   */

  const handleAccountTypeChange = (e) => {
    setAccountType(e.value);
  };

  const handlePasswordVisibility = () => {
    const isItVisible = isPasswordVisible;
    setIsPasswordVisible(!isItVisible);
  };

  if (loading) {
    return (
      <LoginLoader
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );
  } else {
    return (
      // MATERIAL UI LOGIN FORM
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={Styles.loginForm}>
            <Box
              sx={{
                // marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img
                src="https://assets.ekatvam-midas.com/images/logo.png"
                alt="company logo"
                className={Styles.companyLogo}
                style={{ margin: '0' }}
              />
              <div className={Styles.tagLine}>
                An <b>Ekatvam Innovations</b> Product
              </div>

              <div className={Styles.message} style={{ margin: '12px 0' }}>
                Sign in to your account.
              </div>
              <div className={Styles.errorMessage}>{errorMessage}</div>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id={'email'}
                  label="Username"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  size="small"
                />

                <IconTextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  id={'password'}
                  autoComplete="current-password"
                  size="small"
                  iconEnd={
                    isPasswordVisible ? (
                      <FiEyeOff
                        className={Styles['eye-icon']}
                        onClick={handlePasswordVisibility}
                      />
                    ) : (
                      <FiEye
                        className={Styles['eye-icon']}
                        onClick={handlePasswordVisibility}
                      />
                    )
                  }
                />

                <label className={Styles.loginLabel}>Account Type</label>
                <Select
                  name="program"
                  options={[
                    { label: 'Admin', value: 'admin' },
                    { label: 'Program Manager', value: 'programManager' },
                    {
                      label: 'Cluster Coordinator',
                      value: 'clusterCoordinator',
                    },
                    {
                      label: 'Village Coordinator',
                      value: 'fieldFacilitator',
                    },
                  ]}
                  onChange={handleAccountTypeChange}
                />
                <div className={Styles.forgotPassword}>
                  Forget your Password ?
                </div>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
              </Box>
            </Box>
          </div>
        </Container>
      </ThemeProvider>
      // MATERIAL UI LOGIN FORM
    );
  }
};

const IconTextField = ({ iconStart, iconEnd, InputProps, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: iconStart ? (
          <InputAdornment position="start">{iconStart}</InputAdornment>
        ) : null,
        endAdornment: iconEnd ? (
          <InputAdornment position="end">{iconEnd}</InputAdornment>
        ) : null,
      }}
    />
  );
};

export default Login;
