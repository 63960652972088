import translator from '../assets/json/translator.json';
import React from 'react';
import { Get } from './apiCall';
import Swal from 'sweetalert2';
import Clip from '../components/Loader/clip';
import ReactCanvasConfetti from 'react-canvas-confetti';
import styledComponents from 'styled-components';
import { useState } from 'react';
import { useContext } from 'react';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from './context/context';
import { useParams } from 'react-router-dom';

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
};

const Download = (props) => {
  const { villageId, clusterId } = useParams();

  const { month, year } = useContext(TimelineContext);
  const { language, programId } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  let animationInstance = null;
  const [loading, setLoading] = useState(false);
  // const [showConfetti, setShowConfetti] = useState(false);

  const makeShot = (particleRatio, opts) => {
    animationInstance &&
      animationInstance({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  };

  const fire = () => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  const getInstance = (instance) => {
    animationInstance = instance;
  };

  async function handleDownload() {
    setLoading(true);

    const params = {
      programId: programId,
      clusterId: clusterId,
      villageId: villageId,
      year: year,
      month: month + 1,
    };
    const response = await Get('/info/pdf', token, params);
    if (response.statusCode === 200) {
      try {
        setLoading(false);
        window.open(response.data.url);
      } catch (err) {
        setLoading(false);

        Swal.fire({
          icon: 'error',
          title: `${translator[language]['Something went wrong']}!`,
          text: translator[language][
            'Please try again later or contact us at boond.disq@gmail.com'
          ],
        });
      }
    } else if (response.statusCode === 202) {
      setLoading(false);

      Swal.fire(
        translator[language]['We are working on your report'],
        translator[language][
          'Within 5 minutes, it will be delivered to your registered email address'
        ],
        'success',
      );
      fire();
    } else {
      setLoading(false);

      Swal.fire({
        icon: 'error',
        title: `${translator[language]['Something went wrong']}!`,
        text: translator[language][
          'Please try again later or contact us at boond.disq@gmail.com'
        ],
      });
    }
  }

  return (
    <Wrapper>
      <>
        <div className="navbarDownload">
          <button onClick={() => handleDownload()}>
            {loading ? <Clip /> : translator[language]['Download']}
          </button>
          <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
        </div>
      </>
    </Wrapper>
  );
};
export default Download;

const Wrapper = styledComponents.div`
  .navbarDownload button {
    padding: 3%;
    width: 100%;
    height: 30px;
    background-color: #3883e6;
    border: none;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
  }

  .navbarDownload button:hover {
    background-color: #3068f4;
  }

  @media screen and (max-width: 1250px) {
    .navbarDownload button {
      font-size: 0.7rem;
      height: 25px;
    }
  }

  @media screen and (max-width: 1450px) and (min-width: 1250px) {
    .navbarDownload button {
      font-size: 0.8rem;
      height: 28px;
    }
  }

  @media screen and (min-width: 1700px) {
    .navbarDownload button {
      font-size: 1rem;
      height: 33px;
    }
  }

  @media screen and (min-width: 1900px) {
    .navbarDownload button {
      font-size: 1.1rem;
      height: 36px;
    }
  }


  @media screen and (max-width:768px){
    .navbarDownload button {
      // height: 23px;
      height: max-content;
      padding:4px 2px;
      font-size:.65rem;
    }
  }
`;
