import React, { useContext } from 'react';
import Village from './Village';
import { FaUser } from 'react-icons/fa';
import { VscTriangleDown } from 'react-icons/vsc';
import { VscTriangleUp } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import {
  GeneralContext,
  TimelineContext,
} from '../../services/context/context';
import Styles from './Taluka.module.css';

/**
 *
 * @component SidebarTaluka
 * @description This component represents the taluka on the sidebar
 */
const SidebarTaluka = (props) => {
  /**
   * @state
   * isDropdownOpen --> If true that taluka is expanded (it's villages are visible) on the sidebar
   */
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const handleDropdown = () => {
    let flag = isDropdownOpen;
    setIsDropdownOpen(!flag);
  };

  const { handlePageHead } = useContext(TimelineContext);
  const { programId } = useContext(GeneralContext);

  /**
   * @description if the surfacewater page is open for a village and we click on another village
   * then surfacewater page of the click will open
   * @example If 'Untiya/analytics/surfacewater' was open then clicking on Kundaliyala opens
   *  the same page for that village 'Kundaliyala/analytics/surfacewater'
   */

  return (
    <div
      key={props.taluka.id}
      className={Styles.sidenavTalukaList}
      style={isDropdownOpen ? { backgroundColor: '#1b1e25' } : {}}
    >
      <div className={Styles.sidenavTaluka}>
        <FaUser className={Styles.userIcon} />
        <Link
          onClick={() => {
            handlePageHead(`${props.taluka.name} Cluster`);
            props.handleCloseClick();
          }}
          to={`/cluster/programId/${programId}/clusterId/${props.taluka.id}`}
          className={`${Styles.link} ${
            props.activeBar() === `clusterId/${props.taluka.id}`
              ? Styles.activeLink
              : ''
          }`}
        >
          {props.taluka.name}
        </Link>
        {isDropdownOpen ? (
          <VscTriangleUp
            onClick={() => {
              handleDropdown();
            }}
            style={{ float: 'right', cursor: 'pointer' }}
          />
        ) : (
          <VscTriangleDown
            onClick={() => {
              handleDropdown();
            }}
            style={{ float: 'right', cursor: 'pointer' }}
            // className={Styles.triangleIcon}
          />
        )}
      </div>
      <Village
        isDropdownOpen={isDropdownOpen}
        taluka={props.taluka || []}
        activeBar={props.activeBar}
        location={props.location}
        handleCloseClick={props.handleCloseClick}
      />
    </div>
  );
};
export default SidebarTaluka;
