import React, { useState, useEffect, useRef, useContext } from 'react';
import LeftSide from '../components/Plan/LeftSide';
import RightSide from '../components/Plan/RightSide';
import AnalyticsNavbar from '../components/Navbar/PageNav';
import CropDiversification from '../components/Plan/CropDiversification';
import { useParams } from 'react-router-dom';
import Styles from './Plan.module.css';

import back from '../assets/images/back.png';
import forward from '../assets/images/forward.png';
import backDark from '../assets/images/backDark.png';
import forwardLight from '../assets/images/forwardLight.png';

import { Get, Patch, Post } from '../services/apiCall';

import moment from 'moment';
import LoginLoader from '../components/Loader/climb';

// FOR MODAL
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import savedScenario from '../../src/assets/images/savedScenario.png';
import savedScenarioFailed from '../../src/assets/images/savedScenarioFailed.png';
import SavedScenario from './SavedScenario';
// import AdvisoryPreview from "./AdvisoryPreview";

// FOR MODAL
import translator from '../assets/json/translator.json';

import SavedScenarioSummary from './SavedScenarioSummary';
import { TextField } from '@mui/material';
import SavedScenarioCropDiversification from './SavedScenarioCropDiversification';
import NoPlanAvailable from '../components/Plan/NoPlanAvailable';
import months from '../shared/constants/months';
import getNonDuplicateArray from '../services/genericRemoveDuplicate';
import {
  AuthContext,
  TimelineContext,
  GeneralContext,
} from '../services/context/context';
import getUnitValue from '../services/getUnitValueFunction';
import getEndDate from '../services/getEndDate';
import filterUnique from '../services/filterUnique';
import IrrigationSide from '../components/Plan/IrrigationSide';
import SavedScenarioIrrigation from './SavedScenarioIrrigation';

const Plan = (props) => {
  const { clusterId, villageId } = useParams();
  const initialRender = useRef(true);

  const {
    unmountNS,
    unmount,
    programId,
    language,
    selectedUnit,
    selectedVillageTankerValue,
    handleVillageAreaChange,
  } = useContext(GeneralContext);
  let {
    pageHead,
    selectedSeason,
    currentPlanYear,
    handlePageHead,
    handleBaseSelectedPlanMonth,
  } = useContext(TimelineContext);
  const { token, accountType } = useContext(AuthContext);

  //calling this api to set the global village area
  useEffect(() => {
    const fetchVillageArea = async () => {
      const params = {
        villageId: villageId,
        language: language,
      };
      const response = await Get('/info/village', token, params);

      if (response?.status === 'success') {
        handleVillageAreaChange(response?.data?.area);
      }
    };
    fetchVillageArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [villageId]);

  // FOR RENDER
  useEffect(() => {
    if (unmountNS) unmount(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, unmountNS]);
  const [currentRange, setCurrentRange] = useState(0);

  // STATE FOR CHECKING IF PLAN PAGE IS OPEN OR NOT
  const [isPlanPageOpen, setIsPlanPageOpen] = useState(false);

  //STATE FOR SETTING THE BASE MONTH FOR ADMIN IF THERE IS NO PLAN PAGE OPEN
  // const [adminBaseMonth, setAdminBaseMonth] = useState("");

  // STATE FOR SUBMITTING THE DATE SET BY ADMIN
  const [dateByAdmin, setDateByAdmin] = useState('');

  //COMPLETE PAGE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE TO MAINTAIN THE CHANGE OF DRIP SOWN ONE
  const [currentDripArea, setCurrentDripArea] = useState(0);

  // LEFT SIDE STATES
  const [currentDemand, setCurrentDemand] = useState(0);
  const [currentWaterAvailable, setCurrentWaterAvailable] = useState(0);
  // FOR STATIC VALUES (FIRST RENDER-> STARTS)
  const [staticCurrentDemand, setStaticCurrentDemand] = useState(0);
  const [staticCurrentWaterAvailable, setStaticCurrentWaterAvailable] =
    useState(0);
  const [staticCurrentWaterBalance, setStaticCurrentWaterBalance] = useState(0);
  const [staticGroundWaterLast, setStaticGroundWaterLast] = useState(0);
  // FOR STATIC VALUES (FIRST RENDER-> ENDS)
  const [currentWaterBalance, setCurrentWaterBalance] = useState(0);
  const [totalMonthsRemaining, setTotalMonthsRemaining] = useState(3.5);
  const [totalWaterSaved, setTotalWaterSaved] = useState(0);

  //STATE TO SET THE BASE MONTH
  const [baseMonth, setBaseMonth] = useState(0);

  //STATE TO CHECK THE CURRENT SIDE -> DEMAND/SUPPLY/CROP DIVERSIFICATION/and others...
  const [currentCrop, setCurrentCrop] = useState('Demand Side');

  // --------------------------SUPPLY FUNCTIONS ---------------------------

  //RANGE BAR FOR SUPPLY SIDE (To track the current range of supply side):-
  const [supplyCurrentRange, setSupplyCurrentRange] = useState(0);
  const [isRunoffAvailable, setIsRunoffAvailable] = useState(true);

  // CROP LIST MODES -> (Manual or Recommended)
  const [cropListMode, setCropListMode] = useState('Manual');

  //RAINY DAYS
  const [rainyDays, setRainyDays] = useState(0);

  // CALLING TO GET ALL CROPS DATA FROM BACKEND -> To set crops on initial render (Demand Side) [TYPE -1, Changable]
  const [allCrops, setAllCrops] = useState([]);

  // CALLING TO GET ALL CROPS DATA FROM BACKEND -> To set crops on initial render (Demand Side) [TYPE -2, Static]
  const [allCropsForReset, setAllCropsForReset] = useState([]);
  // CALLING TO GET ALL CROPS DATA FROM BACKEND -> To set crops on initial render (Demand Side) [TYPE -3, Manual/Recommended]

  const [
    allCropsForResetUpdateModeIrrigation,
    setAllCropsForResetUpdateModeIrrigation,
  ] = useState([]);

  const [
    allCropsForResetModeIrrigationWithoutUpdate,
    setAllCropsForResetModeIrrigationWithoutUpdate,
  ] = useState([]);

  // CROP DIVERSIFICATION INITIAL ARRAY
  const [arrOfCropLists, setArrayOfCropLists] = useState([]);
  // CROP DIVERSIFICATION INITIAL ARRAY

  //CROP DIVERSIFICATION BACKUP ARRAY
  const [backupArrayForOldCropArea, setBackupArrayForOldCropArea] = useState(
    [],
  );
  //CROP DIVERSIFICATION BACKUP ARRAY

  // IRRIGATION SIDE CROP ARRAY
  const [irrigationCrops, setIrrigationCrops] = useState([]);
  // IRRIGATION SIDE CROP ARRAY

  // STATE FOR GETTING PREDEFINED EVAPORATION DETAILS
  const [predefinedCropsDetail, setPredefinedCropsDetail] = useState();

  // STATE FOR RUNOFF AVAILABILITY CHECK -> A particular number will be set as a base.
  const [checkRunoffAvailable, setCheckRunoffAvailable] = useState();

  //STATE TO GET SUPPLY SIDE DATA TO BE PASSED ON:-
  const [supplySideData, setSupplySideData] = useState({});

  // CALCULATING CWR AFTER FIRST API CALL (IT WILL REMAIN CONSTANT)
  const [firstRenderTotalCwr, setFirstRenderTotalCwr] = useState(0);

  // const [baseConditionDataForEdit, setBaseConditionDataForEdit] = useState();

  const [resetSupplyRange, setResetSupplyRange] = useState(0);

  const [buttonName, setButtonName] = useState('Save');

  const [scenarioId, setScenarioId] = useState('');

  const [isUpdateModeActiveScenarioId, setIsUpdateModeScenarioId] =
    useState('');
  const [isUpdateModeActiveScenarioType, setIsEditModeScenarioType] =
    useState('');

  const [isUpdateModeActiveScenarioName, setIsUpdateModeActiveScenarioName] =
    useState('');

  const [isUpdateModeActive, setIsUpdateModeActive] = useState(false);

  //TRYING
  const [updatedCropList, setUpdatedCropList] = useState([]);

  //TRYING

  // TO SAVE THE SCENARIO MODAL
  const [isScenarioSaved, setIsScenarioSaved] = useState(false);
  const [scenarioName, setScenarioName] = useState('');
  // TO SAVE THE SCENARIO MODAL

  //RECOMMENDED SCENARIO ID STATE
  const [recommendedScenarioId, setRecommendedScenarioId] = useState('');
  //RECOMMENDED SCENARIO ID STATE

  //PUBLISHED SCENARIO STATE
  const [globalPublishedScenarioId, setGlobalPublishedScenarioId] =
    useState('');
  //PUBLISHED SCENARIO STATE

  //GLOBAL PLAN ID TO PASS TO SHOW THE SCENARIO GRAPH
  const [globalPlanId, setGlobalPlanId] = useState('');
  //GLOBAL PLAN ID TO PASS TO SHOW THE SCENARIO GRAPH

  //  Case when crop diversfication array is in update mode and want to reset it on current
  const [
    updateModeCropDiversificationReset,
    setUpdateModeCropDiversificationReset,
  ] = useState('');
  //  Case when crop diversfication array is in update mode and want to reset it on current

  // TO GET NUMBER OF PLANS SAVED
  const [numberOfPlansSaved, setNumberOfPlansSaved] = useState(3);
  // TO GET NUMBER OF PLANS SAVED

  //TO SAVE MAXIMUM PERCENTAGE IN SUPPLY SIDE
  const [maxPercentageOfSuppy, setMaxPercentageOfSupply] = useState(0);
  //TO SAVE MAXIMUM PERCENTAGE IN SUPPLY SIDE

  // state to know the update modal success or not
  const [isUpdateSuccess, setIsUpdateSuccess] = useState('updateSuccess');
  // state to know the update modal success or not

  // STATES TO MANAGE THE TOTAL NUMBER OF SUMMARIES FOR GRAPH AND FILTER
  const [scenarios, setScenarios] = useState([]);
  const [trackFilteredValues, setTrackFilteredValues] = useState([]);

  const [globalArrayCount, setGlobalArrayCount] = useState([]);
  const [currentMappedData, setCurrentMappedData] = useState([]);
  const [referAnotherArray, setReferAnotherArray] = useState([]);
  const [duplicateArray, setDuplicateArray] = useState([]);

  const [currentFilterType, setCurrentFilterType] = useState('Saved Scenarios');
  const [recommendedFilterArray, setRecommendedFilterArray] = useState([]);

  const [currentCountOfOwnPlansSaved, setCurrentCountOfOwnPlansSaved] =
    useState(0);

  const [actualIrrigaton, setActualIrrigation] = useState('');

  // STATES TO MANAGE THE TOTAL NUMBER OF SUMMARIES FOR GRAPH AND FILTER

  // INITIAL RENDER

  useEffect(() => {
    checkPlanIsOpen();

    return () => {
      setCurrentRange(0);
      setIsPlanPageOpen(false);
      setDateByAdmin('');
      setIsLoading(true);
      setCurrentDripArea(0);
      setCurrentDemand(0);
      setCurrentWaterAvailable(0);
      setStaticCurrentDemand(0);
      setStaticCurrentWaterAvailable(0);
      setStaticCurrentWaterBalance(0);
      setStaticGroundWaterLast(0);
      setCurrentWaterBalance(0);
      setTotalMonthsRemaining(0);
      setTotalWaterSaved(0);
      setBaseMonth(0);
      setCurrentCrop('Demand Side');
      setSupplyCurrentRange(0);
      setIsRunoffAvailable(true);
      setCropListMode('Manual');
      setRainyDays(0);
      setAllCrops([]);
      setAllCropsForReset([]);
      setArrayOfCropLists([]);
      setIrrigationCrops([]);
      setAllCropsForResetUpdateModeIrrigation([]);
      setAllCropsForResetModeIrrigationWithoutUpdate([]);
      setBackupArrayForOldCropArea([]);
      setPredefinedCropsDetail();
      setCheckRunoffAvailable();
      setSupplySideData({});
      setFirstRenderTotalCwr(0);
      setResetSupplyRange(0);
      setButtonName('Save');
      setScenarioId('');
      setIsUpdateModeScenarioId('');
      setIsEditModeScenarioType('');
      setIsUpdateModeActiveScenarioName('');
      setIsUpdateModeActive(false);
      setUpdatedCropList([]);
      setIsScenarioSaved(false);
      setScenarioName('');
      setRecommendedScenarioId('');
      setGlobalPublishedScenarioId('');
      setGlobalPlanId('');
      setUpdateModeCropDiversificationReset('');
      setNumberOfPlansSaved(3);
      setMaxPercentageOfSupply(0);
      setIsUpdateSuccess('updateSuccess');
      setScenarios([]);
      setTrackFilteredValues([]);
      setGlobalArrayCount([]);
      setCurrentMappedData([]);
      setReferAnotherArray([]);
      setDuplicateArray([]);
      setCurrentFilterType('Saved Scenarios');
      setRecommendedFilterArray([]);
      setCurrentCountOfOwnPlansSaved(0);
      setCurrentCropWaterRequirementValue();
      setFinalRangeValue();
      setWaterMadeAvailable(0);
      setRecommendedCrops([]);
      setRecommendedSupplyCurrentRange(0);
      setCounter(false);
      setOpenSaveModal(false);
      setOpenUpdateModal(false);
      setNewCropsAddedCount(0);
      setIsNameValid(true);
      setIsPlanScheduleSubmitted(false);
      setErrorLog('');
      handleBaseSelectedPlanMonth('');
      setActualIrrigation('');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageHead, selectedSeason, currentPlanYear]);

  // PARENT TO CHILD CALL FOR EDITING THE BASE CONDITION
  const callBaseConditionForEdit = (id) => {
    checkPlanIsOpen(id);
    setIsUpdateModeScenarioId(id);
  };

  const checkPlanIsOpen = async (idForEdit) => {
    setIsLoading(true);

    setCropListMode('Manual');

    // Calling programs-under-user api to set the plan session initially:- STARTS
    // const callApi = async()=>{
    const programParams = {
      language: language,
    };
    const getPrograms = await Get(
      '/info/programs-under-user',
      token,
      programParams,
    );

    const filterCurrentOrganizationId = getPrograms.data.filter((program) => {
      return Number(program.id) === Number(programId);
    });

    let currentSelectedOrganizationStartDate =
      filterCurrentOrganizationId[0].hasOwnProperty('Organizations')
        ? filterCurrentOrganizationId[0]?.Organizations[0]?.organization_program
            ?.start_date
        : '';

    let duration = filterCurrentOrganizationId[0].hasOwnProperty(
      'Organizations',
    )
      ? filterCurrentOrganizationId[0]?.Organizations[0]?.organization_program
          ?.duration
      : '';

    let endDate = getEndDate(currentSelectedOrganizationStartDate, duration);

    // getting the month of organization end date, to set the initial plan session
    let endDateMonth = Number(endDate.split('-')[1]);
    let endDateYear = Number(endDate.split('-')[0]);

    let currentYear = new Date().getFullYear();

    if (endDateYear > currentYear) {
      currentPlanYear =
        currentPlanYear === ''
          ? `${currentYear}-${currentYear + 1}`
          : currentPlanYear;
    } else {
      currentPlanYear =
        currentPlanYear === ''
          ? endDateMonth >= 6
            ? `${endDateYear}-${endDateYear + 1}`
            : `${endDateYear - 1}-${endDateYear}`
          : currentPlanYear;
    }

    // if(endDateMonth >= 6){
    //   handlePlanYear(`${endDateYear}-${endDateYear+1}`);
    // }else{
    //   handlePlanYear(`${endDateYear-1}-${endDateYear}`);
    // }

    // }

    // Calling programs-under-user api to set the plan session initially:- ENDS

    const payload = {
      programId: programId,
      clusterId: clusterId,
      villageId: villageId,
      yearSession: currentPlanYear,
      season: selectedSeason,
    };

    const response = await Get('/simulation/plan/is-open', token, payload);

    if (response.data === false) {
      handleBaseSelectedPlanMonth('');
      const fetchMonth = await Get(
        `/simulation/plan?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&yearSession=${currentPlanYear}&season=${selectedSeason}`,
        token,
      );

      if (fetchMonth.status === 'success') {
        if (fetchMonth.data && fetchMonth.data.base_month >= 0) {
          const currentYear = new Date().getFullYear();
          const nextYear = new Date().getFullYear() + 1;

          const baseMonth = fetchMonth.data.base_month;
          const getMonth = months[baseMonth];

          const baseMonthForCheck = baseMonth + 1;

          if (baseMonthForCheck > 5 && baseMonthForCheck <= 12) {
            let finalBaseMonth = getMonth + ' ' + currentYear;
            setDateByAdmin(finalBaseMonth);
          } else {
            let finalBaseMonthF = getMonth + ' ' + nextYear;
            setDateByAdmin(finalBaseMonthF);
          }
        }
      }
      setIsPlanPageOpen(false);
    } else {
      setIsPlanPageOpen(true);

      // CALLING API AGAIN TO GET THE BASE_MONTH
      const fetchMonth = await Get(
        `/simulation/plan?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&yearSession=${currentPlanYear}&season=${selectedSeason}`,
        token,
      );

      if (fetchMonth.status === 'success') {
        let actualMonth = fetchMonth.data.base_month + 1;
        const monthsBwMayandBaseMonth = Math.abs(actualMonth - 5);
        setBaseMonth(monthsBwMayandBaseMonth);
        handleBaseSelectedPlanMonth(fetchMonth.data.base_month);

        //Setting recommended scenario id if exists
        setRecommendedScenarioId(fetchMonth.data.recommended_scenario);

        //Setting global published scenario id if exists
        setGlobalPublishedScenarioId(fetchMonth.data.published_scenario);

        //Setting global plan id
        setGlobalPlanId(fetchMonth.data.id);
      }

      // CALLING API AGAIN TO GET THE BASE_MONTH

      // IMP-> ACTUAL PREVIOUS ----------------------------
      // SETTING PLAN BASE CONDITION
      // await PlanApi.getAllPredefinedCropsDetail().then((data) => {

      let getBaseCondition;

      if (idForEdit === undefined) {
        getBaseCondition = await Get(
          `/simulation/plan/criterion?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&yearSession=${currentPlanYear}&season=${selectedSeason}&condition=Base&language=${language}`,
          token,
        );

        setRainyDays(getBaseCondition.data.total_rainy_days);

        // setBaseConditionDataForEdit(getBaseCondition.data);
        setButtonName('Save');
        setSupplyCurrentRange(0);
        setResetSupplyRange(0);
        setIsEditModeScenarioType('');
        setIsUpdateModeActive(false);
        setBackupArrayForOldCropArea(getBaseCondition.data.crops);
        setArrayOfCropLists(getBaseCondition.data.crops);
      } else {
        let callForEdit = await Get(
          `/simulation/scenario?id=${idForEdit}`,
          token,
        );

        let finalCall = callForEdit.data;
        getBaseCondition = finalCall;
        setButtonName('Update');
        setSupplyCurrentRange(getBaseCondition.data.supplyCurrentRange);
        setResetSupplyRange(getBaseCondition.data.supplyCurrentRange);
        // setScenarioName(getBaseCondition.name);
        setIsEditModeScenarioType(getBaseCondition.Type.name);
        setIsUpdateModeActiveScenarioName(getBaseCondition.name);
        setIsUpdateModeActive(true);

        setUpdateModeCropDiversificationReset(
          getBaseCondition.data.cropDiversificationCrops,
        );

        setBackupArrayForOldCropArea(
          getBaseCondition.data.backupArrayForOldCropArea,
        );
        setArrayOfCropLists(getBaseCondition.data.cropDiversificationCrops);

        //new condition adde for irrigation:-
        setAllCropsForResetUpdateModeIrrigation(getBaseCondition?.data?.crops);
      }

      const data = getBaseCondition.data;

      //Adding a fixed drip area only on the base condition (Also handled the EDIT case)
      const handlingUpdate =
        updatedCropList.length > 0 ? updatedCropList : data.crops;

      let finalCropsData = handlingUpdate.map((values) => {
        // let finalCropsData = data.crops.map((values) => {
        let fixedDripArea = values.hasOwnProperty('fixed_drip_area');

        return {
          ...values,
          fixed_drip_area: fixedDripArea
            ? values.fixed_drip_area
            : values.drip_area,
        };
      });

      //Adding a fixed drip area only on the base condition (Also handled the EDIT case)

      const currentCrops = finalCropsData?.map((crop) => {
        if (!crop?.hasOwnProperty('newIdealValue')) {
          return {
            ...crop,
            newIdealValue: Number(
              (crop?.per_ha_cwr * crop?.crop_area * 1000 -
                crop?.per_ha_cwr *
                  (crop?.drip_area * crop?.drip_efficiency * 1000)) /
                (crop?.hours_per_irrigation_cycle_per_hectare *
                  crop?.water_pumped_per_hour_in_litres *
                  0.001 *
                  crop?.crop_area),
            ),
          };
        }
        return crop;
      });

      const irrigationFinalCropsData = currentCrops?.filter((crop) => {
        // Criteria for filtering
        const isIdealIrrigationCycleZero = crop?.newIdealValue === 0;
        const isActualIrrigationCycleZero = crop.irrigation_cycle === 0;
        const isIdealIrrigationCycleNaN = isNaN(crop?.newIdealValue);

        // Filtering logic
        return (
          !(isIdealIrrigationCycleZero && isActualIrrigationCycleZero) && // Condition 1
          // !(isIdealIrrigationCycleZero && !isActualIrrigationCycleZero) && // Condition 2
          // !(!isIdealIrrigationCycleZero && isActualIrrigationCycleZero) && // Condition 3
          !(isIdealIrrigationCycleNaN && isActualIrrigationCycleZero) && // Condition 4
          !(isIdealIrrigationCycleNaN && !isActualIrrigationCycleZero) // Condition 5
        );
      });

      // setAllCrops(data.crops);
      setAllCrops(finalCropsData);
      setIrrigationCrops(irrigationFinalCropsData);
      setCurrentWaterAvailable(data.total_water_balance);
      setStaticCurrentWaterAvailable(data.total_water_balance);
      setPredefinedCropsDetail(data);
      // setAllCropsForReset(data.crops);
      setAllCropsForReset(finalCropsData);
      setAllCropsForResetUpdateModeIrrigation(irrigationFinalCropsData);
      setAllCropsForResetModeIrrigationWithoutUpdate(irrigationFinalCropsData);

      // CALCULATING DEMAND FOR THE BASE
      // let finalSumCropWaterRequirement = data.crops.reduce((initial, vals) => {
      let finalSumCropWaterRequirement = finalCropsData.reduce(
        (initial, vals) => {
          return (
            initial +
            vals.per_ha_cwr * vals.crop_area -
            vals.per_ha_cwr * vals.drip_area * vals.drip_efficiency
          );
        },
        0,
      );

      const baseDemand =
        finalSumCropWaterRequirement +
        data.total_domestic_requirement +
        data.cultivation_land_evaporation +
        data.barren_land_evaporation +
        data.water_body_evaporation +
        data.forest_land_evaporation;

      setStaticCurrentDemand(baseDemand);
      setStaticCurrentWaterBalance(data.total_water_balance - baseDemand);

      // FOR BASE GROUNDWATER LAST STARTS
      let actualMonthh = fetchMonth.data.base_month + 1;
      const monthsBwMayandBaseMonthh = Math.abs(actualMonthh - 5);
      const baseGroundWater =
        data.groundwater_level_tcm / (baseDemand / monthsBwMayandBaseMonthh);

      setStaticGroundWaterLast(baseGroundWater.toFixed(1));
      // FOR BASE GROUNDWATER LAST ENDS

      // CALCULATING DEMAND FOR THE BASE

      // SUPPLY SIDE STARTS
      setSupplySideData(data);

      let runoff_available_for_arrest =
        data.runoff_limit * data.total_runoff_tcm -
        data.total_surface_water_storage;

      let final_runoff_available_for_arrest =
        runoff_available_for_arrest < 0 ? 0 : runoff_available_for_arrest;

      setCheckRunoffAvailable(final_runoff_available_for_arrest);

      //NEW

      let first_render_total_cwr = data.crops.reduce((initial, vals) => {
        let dripArea;
        if (vals.hasOwnProperty('fixed_drip_area')) {
          dripArea = vals.fixed_drip_area;
        } else {
          dripArea = vals.drip_area;
        }

        return (
          initial +
          vals.per_ha_cwr * vals.crop_area -
          vals.per_ha_cwr * dripArea * vals.drip_efficiency
        );
      }, 0);

      setFirstRenderTotalCwr(first_render_total_cwr);

      // });
      // SETTING PLAN BASE CONDITION
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (isUpdateModeActive === true) {
      if (isUpdateModeActiveScenarioType === 'demand-and-supply') {
        if (currentCrop === 'Demand Side' || currentCrop === 'Supply Side') {
        } else {
          checkPlanIsOpen();
        }
      } else if (isUpdateModeActiveScenarioType === 'crop-diversification') {
        if (currentCrop === 'Crop Diversification') {
        } else {
          checkPlanIsOpen();
        }
      } else if (isUpdateModeActiveScenarioType === 'irrigation-cycle') {
        if (currentCrop === 'Irrigation Side') {
        } else {
          checkPlanIsOpen();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCrop]);

  const [
    currentCropWaterRequirementValue,
    setCurrentCropWaterRequirementValue,
  ] = useState();

  // CALCULATING THE FINAL CROP LISTS SUM AND SETTING THE STATE OF FINAL VALUE
  const [finalRangeValue, setFinalRangeValue] = useState();
  useEffect(() => {
    if (currentCrop === 'Demand Side') {
      setFinalRangeValue(allCrops);
    } else if (currentCrop === 'Irrigation Side') {
      setFinalRangeValue(irrigationCrops);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCrops, irrigationCrops]);

  // STATES TO MAKE THE CHANGES STAGANT SUPPLY SIDE
  const [waterMadeAvailable, setWaterMadeAvailable] = useState(0);
  const [runoffToBeArrestedStagant, setRunoffToBeArrestedStagant] = useState(0);

  // RECOMMENDED STATES -> FOR BOTH DEMAND AND SUPPLY
  const [recommendedCrops, setRecommendedCrops] = useState([]);
  const [recommendedSupplyCurrentRange, setRecommendedSupplyCurrentRange] =
    useState(0);

  // (CROP DIV BASE) STARTS------------------------------------------------------------------------------------

  // getting base condition for demand

  const getBaseConditionCropDiversification = () => {
    // FINAL BASE CONDITION MAYBE---------------------------STARTS

    const finalizingArrayPre = arrOfCropLists.map((values) => {
      return {
        ...values,
        // fixedDripArea: values.drip_area,
        // fixedArea: values.crop_area,
        dripOnCrop: values.hasOwnProperty('dripOnCrop')
          ? values.dripOnCrop
          : values.drip_area, //c
        // dripOnCrop: values.dripOnCrop ? values.dripOnCrop : values.drip_area, //c
        fixedDripOnCrop: values.drip_area, //new
        fixedCropDripArea: values.crop_area,

        // NEW
        cropId: values.uuid,
        cropName: values.name,
        cropDripArea: values.cropDripArea
          ? values.cropDripArea
          : values.crop_area, //c
      };
    });

    const finalizingArray = finalizingArrayPre.map((values) => {
      const oldCropWaterRequirement =
        values.per_ha_cwr * values.fixedCropDripArea -
        values.per_ha_cwr * values.fixedDripOnCrop * values.drip_efficiency;

      const newCropCropWaterRequirement = values.oldCropCwrAfterReduction
        ? values.oldCropCwrAfterReduction
        : values.per_ha_cwr * values.crop_area -
          values.per_ha_cwr * values.drip_area * values.drip_efficiency;

      return {
        ...values,

        oldCropWaterRequirement: oldCropWaterRequirement,
        newCropCropWaterRequirement: newCropCropWaterRequirement,
      };
    });
    setArrayOfCropLists(finalizingArray);

    // ----------------------------------------------------------------------STARTS OCT 27
    const results = backupArrayForOldCropArea.filter(
      ({ uuid: id1 }) => !finalizingArray.some(({ uuid: id2 }) => id2 === id1),
    );

    // COPY
    const finalizingArrayPreTwo = results.map((values) => {
      return {
        ...values,

        dripOnCrop: 0, //c
        fixedDripOnCrop: values.drip_area, //new
        fixedCropDripArea: values.crop_area,

        drip_area: 0,
        crop_area: 0,
        oldCropCwrAfterReduction: 0,

        // NEW
        cropId: values.uuid,
        cropName: values.name,
        cropDripArea: 0, //c
      };
    });

    const finalizingArrayTwo = finalizingArrayPreTwo.map((values) => {
      const oldCropWaterRequirement =
        values.per_ha_cwr * values.fixedCropDripArea -
        values.per_ha_cwr * values.fixedDripOnCrop * values.drip_efficiency;

      const newCropCropWaterRequirement = values.oldCropCwrAfterReduction
        ? values.oldCropCwrAfterReduction
        : values.per_ha_cwr * values.crop_area -
          values.per_ha_cwr * values.drip_area * values.drip_efficiency;

      return {
        ...values,

        oldCropWaterRequirement: oldCropWaterRequirement,
        newCropCropWaterRequirement: newCropCropWaterRequirement,
      };
    });

    const merging = [...finalizingArrayTwo, ...finalizingArray];

    // COPY

    // ------------------------------------------------------------------------ ENDS OCT 27

    //const ah20 = ah4 + ap17 + ay12 + ah21 + ah22 (FINAL INITIAL DEMAND)

    // const ah4 = props.predefinedCropsDetail.total_domestic_requirement; //domestic requirement
    const ah4 = predefinedCropsDetail.total_domestic_requirement; //domestic requirement

    // now everything for ap17
    // const ap17 = finalizingArray.reduce((initial, val) => {
    const ap17 = merging.reduce((initial, val) => {
      return initial + val.newCropCropWaterRequirement;
    }, 0);
    // now everything for ap17

    // now everything for ay12
    // const aj17 = 0; //avg drip (abhi taking 0 for base)

    const getAverage = merging.filter((vals) => {
      return vals.cropDripArea !== 0;
    });

    // const aj17 = finalizingArray.reduce((initialValue, value) => {
    const aj17 = getAverage.reduce((initialValue, value) => {
      return (
        initialValue +
        // value.dripOnCrop / value.cropDripArea / finalizingArray.length
        value.dripOnCrop / value.cropDripArea / getAverage.length
      );
    }, 0);

    const ax9 = predefinedCropsDetail.cultivation_land_evaporation;

    // const ah17 = 32; //it will be a  sum of area (will calculate abhi)
    // const ah17 = finalizingArray.reduce((initialValue, value) => {

    const PREah17 = merging.reduce((initialValue, value) => {
      return initialValue + value.cropDripArea;
    }, 0);
    const ah17 = PREah17 === 0 ? 1 : PREah17;

    // const an17 = 32; // old crop initial sum area ---doubtfull
    // const an17 = finalizingArray.reduce((initialValue, value) => {

    const PREan17 = merging.reduce((initialValue, value) => {
      return initialValue + value.fixedCropDripArea;
    }, 0);
    const an17 = PREan17 === 0 ? 1 : PREan17;

    // const an17 = merging.reduce((initialValue, value) => {
    //   return initialValue + value.fixedCropDripArea;
    // }, 0);

    const newEvaporationForVegetation = (1 - 0.3 * aj17) * ax9 * (ah17 / an17);

    //Calculating az12 (Total water saved) => ax12-ay12
    const ax12 =
      predefinedCropsDetail.barren_land_evaporation +
      predefinedCropsDetail.cultivation_land_evaporation +
      predefinedCropsDetail.forest_land_evaporation +
      predefinedCropsDetail.water_body_evaporation;

    const ay12 =
      predefinedCropsDetail.barren_land_evaporation +
      newEvaporationForVegetation +
      predefinedCropsDetail.forest_land_evaporation +
      predefinedCropsDetail.water_body_evaporation;
    // now everything for ay12

    // now everything for ah21
    const ah21 = 0;
    // now everything for ah21

    // now everything for ah22
    const ah22 = 0;
    // now everything for ah22

    const ah20 = ah4 + ap17 + ay12 + ah21 + ah22;

    setTotalWaterSaved(Math.round(ax12 - ay12));
    setCurrentDemand(Math.round(ah20));
    setCurrentWaterAvailable(staticCurrentWaterAvailable);

    // FINAL BASE CONDITION MAYBE---------------------------ENDS
  };

  //(CROP DIV BASE)ENDS-------------------------------------------------------------------------------------

  const [counter, setCounter] = useState(false);
  useEffect(() => {
    if (predefinedCropsDetail !== undefined) {
      getBaseConditionCropDiversification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  const onReset = () => {
    if (currentCrop === 'Demand Side' || currentCrop === 'Supply Side') {
      setAllCrops(allCropsForReset);
      setSupplyCurrentRange(resetSupplyRange);
    } else if (currentCrop === 'Crop Diversification') {
      if (isUpdateModeActive) {
        setArrayOfCropLists(updateModeCropDiversificationReset);
      } else {
        setArrayOfCropLists(backupArrayForOldCropArea);
      }

      setCounter(!counter);
    } else if (currentCrop === 'Irrigation Side') {
      if (isUpdateModeActive) {
        setIrrigationCrops(allCropsForResetUpdateModeIrrigation);
      } else {
        setIrrigationCrops(allCropsForResetModeIrrigationWithoutUpdate);
      }
    }
  };

  // AFTER CLICKING THE SAVE BUTTON -> FOR SUPPLY AND DEMAND ONLY
  const [openSaveModal, setOpenSaveModal] = useState(false);
  // AFTER CLICKING THE SAVE BUTTON -> FOR SUPPLY AND DEMAND ONLY

  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  // CROP DIVERSIFICATION STATES STARTS HERE---------------------------------

  const [newCropsAddedCount, setNewCropsAddedCount] = useState(0);

  // CROP DIVERSIFICATION STATES ENDS HERE---------------------------------

  const [isNameValid, setIsNameValid] = useState(true);

  const validateSave = (e) => {
    setScenarioName(e.target.value);

    if (
      // /^[a-zA-Z0-9 ]*$/.test(e.target.value) &&
      /^(?=.*?[a-zA-Z])[a-zA-Z\d ]+$/.test(e.target.value) &&
      e.target.value.length > 3 &&
      e.target.value.trim().length > 3
    ) {
      // setIsScenarioSaved(true);
      setIsNameValid(false);
    } else {
      setIsNameValid(true);
    }
  };

  const validateSaveFunc = async () => {
    const current =
      currentCrop === 'Crop Diversification'
        ? 'crop-diversification'
        : currentCrop === 'Irrigation Side'
        ? 'irrigation-cycle'
        : 'demand-and-supply';

    const cropCultivationArea = arrOfCropLists.reduce((initial, values) => {
      if (values.hasOwnProperty('cropDripArea')) {
        return initial + values.cropDripArea;
      } else {
        return initial + values.crop_area;
      }
    }, 0);

    const totalDripArea = arrOfCropLists.reduce((initial, values) => {
      if (values.hasOwnProperty('dripOnCrop')) {
        return initial + values.dripOnCrop;
      } else {
        return initial + values.drip_area;
      }
    }, 0);

    //FOR GETTING AREA REDUCED ON OLD CROPS STARTS

    // ee
    const filterOldCrops = arrOfCropLists.filter((values) => {
      return (
        !values.isNewCropAddedInArray === true ||
        !values.hasOwnProperty('isNewCropAddedInArray')
      );
    });

    // const finalCalculationOfArrayOfOldCropsFixedTotal = filterOldCrops.reduce(
    const finalCalculationOfArrayOfOldCropsFixedTotal =
      backupArrayForOldCropArea.reduce((initialValue, value) => {
        // return initialValue + value.fixedCropDripArea;
        return initialValue + value.crop_area;
      }, 0);

    const finalCalculationOfArrayOfOldCrops = filterOldCrops.reduce(
      (initialValue, value) => {
        return initialValue + value.cropDripArea;
      },
      0,
    );

    const areaReducedUnderOldCrops =
      finalCalculationOfArrayOfOldCropsFixedTotal -
        finalCalculationOfArrayOfOldCrops >
      0
        ? (
            finalCalculationOfArrayOfOldCropsFixedTotal -
            finalCalculationOfArrayOfOldCrops
          ).toFixed(2)
        : 0;
    // ee

    //FOR GETTING AREA REDUCED ON OLD CROPS ENDS

    //FOR GETTING THE DELETED ARRAY FROM OLD CROPS (Crop DIversification) STARTS

    const getDeletedCropList = backupArrayForOldCropArea.filter((item) => {
      return !arrOfCropLists.some((elem) => {
        return item.uuid === elem.uuid;
      });
    });

    //FOR GETTING THE DELETED ARRAY FROM OLD CROPS (Crop DIversification) ENDS

    const payload = {
      scenario: {
        name: scenarioName,
        data: {
          waterDemand: currentDemand,
          waterAvailable: currentWaterAvailable,
          waterBalance: currentWaterBalance,
          groundwaterLastDuration: totalMonthsRemaining,
          totalWaterSaved: totalWaterSaved,

          // crops:
          //   currentCrop === "Crop Diversification" ? arrOfCropLists : allCrops,

          crops:
            cropListMode === 'Manual'
              ? currentCrop === 'Irrigation Side'
                ? irrigationCrops
                : allCrops
              : recommendedCrops,

          cropDiversificationCrops: arrOfCropLists,

          backupArrayForOldCropArea: backupArrayForOldCropArea,
          waterAvailableAfterMonsoon:
            currentCrop === 'Crop Diversification'
              ? null
              : predefinedCropsDetail.current_rainfall_tcm_cumulative,
          runoffAvailable:
            currentCrop === 'Crop Diversification'
              ? null
              : checkRunoffAvailable,
          pctIncInWater:
            currentCrop === 'Crop Diversification' ? null : supplyCurrentRange,
          waterMadeAvailable:
            currentCrop === 'Crop Diversification' ? null : waterMadeAvailable,
          runoffToBeArrested:
            currentCrop === 'Crop Diversification'
              ? null
              : runoffToBeArrestedStagant,

          cropCultivationArea:
            currentCrop === 'Crop Diversification' ? cropCultivationArea : null,
          totalDripArea:
            currentCrop === 'Crop Diversification' ? totalDripArea : null,
          totalCultivationArea:
            currentCrop === 'Crop Diversification'
              ? predefinedCropsDetail.total_cultivation_area
              : null,
          areaReducedUnderOldCrops:
            currentCrop === 'Crop Diversification'
              ? areaReducedUnderOldCrops
              : null,
          totalNewCrops:
            currentCrop === 'Crop Diversification' ? newCropsAddedCount : null,

          getDeletedCropList:
            currentCrop === 'Crop Diversification' ? getDeletedCropList : null,

          // ALL THE OTHER VALUES, (To be used in Edit Case)
          total_water_balance: predefinedCropsDetail.total_water_balance,
          total_surface_water_storage:
            predefinedCropsDetail.total_surface_water_storage,
          groundwater_level_tcm: predefinedCropsDetail.groundwater_level_tcm,
          current_rainfall_tcm_cumulative:
            predefinedCropsDetail.current_rainfall_tcm_cumulative,
          total_domestic_requirement:
            predefinedCropsDetail.total_domestic_requirement,
          total_runoff_tcm: predefinedCropsDetail.total_runoff_tcm,
          avg_drip_efficiency: predefinedCropsDetail.avg_drip_efficiency,
          runoff_limit: predefinedCropsDetail.runoff_limit,
          supply_efficiency: predefinedCropsDetail.supply_efficiency,
          barren_land_evaporation:
            predefinedCropsDetail.barren_land_evaporation,
          water_body_evaporation: predefinedCropsDetail.water_body_evaporation,
          cultivation_land_evaporation:
            predefinedCropsDetail.cultivation_land_evaporation,
          habitation_land_evaporation:
            predefinedCropsDetail.habitation_land_evaporation,
          forest_land_evaporation:
            predefinedCropsDetail.forest_land_evaporation,
          total_cultivation_area: predefinedCropsDetail.total_cultivation_area,

          supplyCurrentRange:
            cropListMode === 'Manual'
              ? supplyCurrentRange
              : recommendedSupplyCurrentRange,
          staticCurrentDemand: staticCurrentDemand,
          staticCurrentWaterAvailable: staticCurrentWaterAvailable,
          staticCurrentWaterBalance: staticCurrentWaterBalance,
          staticGroundWaterLast: staticGroundWaterLast,

          ctc:
            currentCrop === 'Crop Diversification'
              ? null
              : (runoffToBeArrestedStagant * 1000) / (0.25 * rainyDays),
        },
      },
    };

    const finalResponse = await Post(
      `/simulation/scenario?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&yearSession=${currentPlanYear}&season=${selectedSeason}&type=${current}`,
      token,
      payload,
    );

    if (finalResponse.status === 'success') {
      setScenarioId(finalResponse.data.id);

      //CALLING AN API TO GET NUMBER OF PLANs SAVED
      const fetchScenarios = await Get(
        `/simulation/scenario/search?own=true&page=1&limit=5&programVillagePlanId=${globalPlanId}`,
        token,
      );
      if (fetchScenarios.status === 'success') {
        setNumberOfPlansSaved(fetchScenarios.data?.totalCount);

        // ADDED LATER STARTS -----------------------------------------------------------------

        setCurrentCountOfOwnPlansSaved(fetchScenarios.data?.totalCount);
        let arrOfObj = fetchScenarios.data.scenarios.map((values) => {
          let finalVals = {
            id: values.id,
            name: values.name,
            owner: values.creator.name,
            'Water saved': values.data.totalWaterSaved,
            Aquifer: values.data.groundwaterLastDuration.toFixed(2),
            'Water balance': values.data.waterBalance,
            Type: values.Type.name,
          };

          return finalVals;
        });

        const filterSingleForMapped = arrOfObj.filter((vals) => {
          return vals.id === finalResponse.data.id;
        });

        // FOR REMOVING DUPLICATES

        const isFilteredOrTrackedAlready =
          trackFilteredValues.length > 0 ? trackFilteredValues : arrOfObj;
        let trackONE = [
          ...isFilteredOrTrackedAlready,
          ...filterSingleForMapped,
        ];
        const trackFINAL = getNonDuplicateArray(trackONE);

        let referONE = [...referAnotherArray, ...filterSingleForMapped];
        const referFINAL = getNonDuplicateArray(referONE);

        // FOR REMOVING DUPLICATES

        setScenarios(arrOfObj);
        setTrackFilteredValues(trackFINAL);

        setReferAnotherArray(referFINAL);

        setDuplicateArray(trackFINAL);

        // ADDED LATER ENDS--------------------------------------------------------------
      }
      //CALLING AN API TO GET NUMBER OF PLANS SAVED

      // setAllCrops(allCropsForReset);
    } else {
      setScenarioId('');
      // alert("Scenario Saving Failed");
    }
    setIsScenarioSaved(true);
    setIsNameValid(true);
  };

  //STATE FOR CHECKING IS PLAN SUBMITTED
  const [isPlanScheduleSubmitted, setIsPlanScheduleSubmitted] = useState(false);

  // STATE FOR SHOWING THE ERROR IF (IS-OPEN or PLAN (POST API FAILS ))
  const [errorLog, setErrorLog] = useState('');

  //FORM SUBMISSION BY ADMIN
  const submitFormByAdmin = async () => {
    setIsPlanScheduleSubmitted(true);

    const presetDate = '01';

    const finalDate = presetDate + ' ' + dateByAdmin;
    const createFinalDate = moment(finalDate).format('YYYY-MM-DD');

    const payload = {
      yearSession: currentPlanYear,
      season: selectedSeason,
      openOn: createFinalDate,
    };

    const finalResponse = await Post(
      `/simulation/plan?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}`,
      token,
      payload,
    );

    if (finalResponse && finalResponse.status === 'success') {
      setErrorLog('');
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();

      const responseMonth = finalResponse.data.base_month;
      const responseYear = finalResponse.data.year;

      if (
        (currentMonth >= responseMonth && currentYear === responseYear) ||
        currentYear > responseYear
      ) {
        const payload = {
          programId: programId,
          clusterId: clusterId,
          villageId: villageId,
          yearSession: currentPlanYear,
          season: selectedSeason,
        };

        const callIsOpenApi = await Get(
          '/simulation/plan/is-open',
          token,
          payload,
        );

        if (callIsOpenApi.status === 'success' && callIsOpenApi.data === true) {
          // LITERALLY HERE STARTS--------------------------------------------------------------------
          setErrorLog('');
          setIsLoading(true);

          // CALLING API AGAIN TO GET THE BASE_MONTH
          const fetchMonth = await Get(
            `/simulation/plan?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&yearSession=${currentPlanYear}&season=${selectedSeason}`,
            token,
          );
          if (fetchMonth.status === 'success') {
            let actualMonth = fetchMonth.data.base_month + 1;
            const monthsBwMayandBaseMonth = Math.abs(actualMonth - 5);
            setBaseMonth(monthsBwMayandBaseMonth);
            handleBaseSelectedPlanMonth(fetchMonth.data.base_month);

            //Setting recommended scenario id if exists
            setRecommendedScenarioId(fetchMonth.data.recommended_scenario);

            //Setting global published scenario id if exists
            setGlobalPublishedScenarioId(fetchMonth.data.published_scenario);

            //Setting global plan id
            setGlobalPlanId(fetchMonth.data.id);
          }
          // CALLING API AGAIN TO GET THE BASE_MONTH

          const getBaseCondition = await Get(
            `/simulation/plan/criterion?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&yearSession=${currentPlanYear}&season=${selectedSeason}&condition=Base&language=${language}`,
            token,
          );

          if (getBaseCondition.status === 'success' && getBaseCondition.data) {
            setIsPlanPageOpen(true);

            const data = getBaseCondition.data;

            //Adding a fixed drip area only on the base condition
            let finalCropsData = data.crops.map((values) => {
              return {
                ...values,
                fixed_drip_area: values.drip_area,
              };
            });

            //Adding a fixed drip area only on the base condition

            const currentCrops = finalCropsData?.map((crop) => {
              if (!crop?.hasOwnProperty('newIdealValue')) {
                return {
                  ...crop,
                  newIdealValue: Number(
                    (crop?.per_ha_cwr * crop?.crop_area * 1000 -
                      crop?.per_ha_cwr *
                        (crop?.drip_area * crop?.drip_efficiency * 1000)) /
                      (crop?.hours_per_irrigation_cycle_per_hectare *
                        crop?.water_pumped_per_hour_in_litres *
                        0.001 *
                        crop?.crop_area),
                  ),
                };
              }
              return crop;
            });

            const irrigationFinalCropsData = currentCrops?.filter((crop) => {
              // Criteria for filtering
              const isIdealIrrigationCycleZero = crop?.newIdealValue === 0;
              const isActualIrrigationCycleZero = crop.irrigation_cycle === 0;
              const isIdealIrrigationCycleNaN = isNaN(crop?.newIdealValue);

              // Filtering logic
              return (
                !(isIdealIrrigationCycleZero && isActualIrrigationCycleZero) && // Condition 1
                // !(isIdealIrrigationCycleZero && !isActualIrrigationCycleZero) && // Condition 2
                // !(!isIdealIrrigationCycleZero && isActualIrrigationCycleZero) && // Condition 3
                !(isIdealIrrigationCycleNaN && isActualIrrigationCycleZero) && // Condition 4
                !(isIdealIrrigationCycleNaN && !isActualIrrigationCycleZero) // Condition 5
              );
            });

            setRainyDays(data?.total_rainy_days);

            setAllCrops(finalCropsData);
            setIrrigationCrops(irrigationFinalCropsData);
            setCurrentWaterAvailable(data.total_water_balance);
            setStaticCurrentWaterAvailable(data.total_water_balance);
            setPredefinedCropsDetail(data);

            setAllCropsForReset(finalCropsData);
            setAllCropsForResetUpdateModeIrrigation(irrigationFinalCropsData);
            setAllCropsForResetModeIrrigationWithoutUpdate(
              irrigationFinalCropsData,
            );

            // FOR CROP DIV
            setArrayOfCropLists(data.crops);
            setBackupArrayForOldCropArea(data.crops);

            // CALCULATING DEMAND FOR THE BASE

            let finalSumCropWaterRequirement = finalCropsData.reduce(
              (initial, vals) => {
                return (
                  initial +
                  vals.per_ha_cwr * vals.crop_area -
                  vals.per_ha_cwr * vals.drip_area * vals.drip_efficiency
                );
              },
              0,
            );

            const baseDemand =
              finalSumCropWaterRequirement +
              data.total_domestic_requirement +
              data.cultivation_land_evaporation +
              data.barren_land_evaporation +
              data.water_body_evaporation +
              data.forest_land_evaporation;

            setStaticCurrentDemand(baseDemand);
            setStaticCurrentWaterBalance(data.total_water_balance - baseDemand);

            // FOR BASE GROUNDWATER LAST STARTS
            let actualMonthh = fetchMonth.data.base_month + 1;
            const monthsBwMayandBaseMonthh = Math.abs(actualMonthh - 5);
            const baseGroundWater =
              data.groundwater_level_tcm /
              (baseDemand / monthsBwMayandBaseMonthh);

            setStaticGroundWaterLast(baseGroundWater.toFixed(1));
            // FOR BASE GROUNDWATER LAST ENDS

            // CALCULATING DEMAND FOR THE BASE

            // SUPPLY SIDE STARTS
            setSupplySideData(data);

            let runoff_available_for_arrest =
              data.runoff_limit * data.total_runoff_tcm -
              data.total_surface_water_storage;

            let final_runoff_available_for_arrest =
              runoff_available_for_arrest < 0 ? 0 : runoff_available_for_arrest;

            setCheckRunoffAvailable(final_runoff_available_for_arrest);

            let first_render_total_cwr = data.crops.reduce((initial, vals) => {
              return (
                initial +
                vals.per_ha_cwr * vals.crop_area -
                vals.per_ha_cwr * vals.drip_area * vals.drip_efficiency
              );
            }, 0);

            setFirstRenderTotalCwr(first_render_total_cwr);
          }
          setIsLoading(false);

          // LITERALLY HERE ENDS  --------------------------------------------------------------
        } else {
          // SHOW IS OPEN FALSE ERROR HERE
          setErrorLog(callIsOpenApi.message);
        }
      }
    } else {
      setErrorLog(finalResponse.error);
    }

    setTimeout(() => {
      setIsPlanScheduleSubmitted(false);
    }, 4000);
  };

  const viewScenarioFunction = () => {
    if (currentCrop === 'Demand Side' || currentCrop === 'Supply Side') {
      setCurrentCrop('savedScenario');
    } else if (currentCrop === 'Irrigation Side') {
      setCurrentCrop('savedScenarioIrrigation');
    } else {
      setCurrentCrop('savedScenarioCropDiversification');
    }

    setIsScenarioSaved(false);
    setScenarioName('');
    setOpenSaveModal(false);
    checkPlanIsOpen();
  };

  const updateScenario = async () => {
    //COPYING THE FUNCTION AS OF NOW STARTS

    const cropCultivationArea = arrOfCropLists.reduce((initial, values) => {
      if (values.hasOwnProperty('cropDripArea')) {
        return initial + values.cropDripArea;
      } else {
        return initial + values.crop_area;
      }
    }, 0);

    const totalDripArea = arrOfCropLists.reduce((initial, values) => {
      if (values.hasOwnProperty('dripOnCrop')) {
        return initial + values.dripOnCrop;
      } else {
        return initial + values.drip_area;
      }
    }, 0);

    //FOR GETTING AREA REDUCED ON OLD CROPS STARTS

    // ee
    const filterOldCrops = arrOfCropLists.filter((values) => {
      return (
        !values.isNewCropAddedInArray === true ||
        !values.hasOwnProperty('isNewCropAddedInArray')
      );
    });

    // const finalCalculationOfArrayOfOldCropsFixedTotal = filterOldCrops.reduce(
    const finalCalculationOfArrayOfOldCropsFixedTotal =
      backupArrayForOldCropArea.reduce((initialValue, value) => {
        // return initialValue + value.fixedCropDripArea;
        return initialValue + value.crop_area;
      }, 0);

    const finalCalculationOfArrayOfOldCrops = filterOldCrops.reduce(
      (initialValue, value) => {
        return initialValue + value.cropDripArea;
      },
      0,
    );

    const areaReducedUnderOldCrops =
      finalCalculationOfArrayOfOldCropsFixedTotal -
        finalCalculationOfArrayOfOldCrops >
      0
        ? (
            finalCalculationOfArrayOfOldCropsFixedTotal -
            finalCalculationOfArrayOfOldCrops
          ).toFixed(2)
        : 0;
    // ee

    //FOR GETTING AREA REDUCED ON OLD CROPS ENDS

    //FOR GETTING THE DELETED ARRAY FROM OLD CROPS (Crop DIversification) STARTS
    const getDeletedCropList = backupArrayForOldCropArea.filter((item) => {
      return !arrOfCropLists.some((elem) => {
        return item.uuid === elem.uuid;
      });
    });

    //FOR GETTING THE DELETED ARRAY FROM OLD CROPS (Crop DIversification) ENDS

    const payload = {
      scenario: {
        name: isUpdateModeActiveScenarioName,
        data: {
          waterDemand: currentDemand,
          waterAvailable: currentWaterAvailable,
          waterBalance: currentWaterBalance,
          groundwaterLastDuration: totalMonthsRemaining,
          totalWaterSaved: totalWaterSaved,

          // crops:
          //   currentCrop === "Crop Diversification" ? arrOfCropLists : allCrops,

          crops:
            cropListMode === 'Manual'
              ? currentCrop === 'Irrigation Side'
                ? irrigationCrops
                : allCrops
              : recommendedCrops,

          cropDiversificationCrops: arrOfCropLists,

          backupArrayForOldCropArea: backupArrayForOldCropArea,
          waterAvailableAfterMonsoon:
            currentCrop === 'Crop Diversification'
              ? null
              : predefinedCropsDetail.current_rainfall_tcm_cumulative,
          runoffAvailable:
            currentCrop === 'Crop Diversification'
              ? null
              : checkRunoffAvailable,
          pctIncInWater:
            currentCrop === 'Crop Diversification' ? null : supplyCurrentRange,
          waterMadeAvailable:
            currentCrop === 'Crop Diversification' ? null : waterMadeAvailable,
          runoffToBeArrested:
            currentCrop === 'Crop Diversification'
              ? null
              : runoffToBeArrestedStagant,

          cropCultivationArea:
            currentCrop === 'Crop Diversification' ? cropCultivationArea : null,
          totalDripArea:
            currentCrop === 'Crop Diversification' ? totalDripArea : null,
          totalCultivationArea:
            currentCrop === 'Crop Diversification'
              ? predefinedCropsDetail.total_cultivation_area
              : null,
          areaReducedUnderOldCrops:
            currentCrop === 'Crop Diversification'
              ? areaReducedUnderOldCrops
              : null,
          totalNewCrops:
            currentCrop === 'Crop Diversification' ? newCropsAddedCount : null,

          getDeletedCropList:
            currentCrop === 'Crop Diversification' ? getDeletedCropList : null,

          // ALL THE OTHER VALUES, (To be used in Edit Case)
          total_water_balance: predefinedCropsDetail.total_water_balance,
          total_surface_water_storage:
            predefinedCropsDetail.total_surface_water_storage,
          groundwater_level_tcm: predefinedCropsDetail.groundwater_level_tcm,
          current_rainfall_tcm_cumulative:
            predefinedCropsDetail.current_rainfall_tcm_cumulative,
          total_domestic_requirement:
            predefinedCropsDetail.total_domestic_requirement,
          total_runoff_tcm: predefinedCropsDetail.total_runoff_tcm,
          avg_drip_efficiency: predefinedCropsDetail.avg_drip_efficiency,
          runoff_limit: predefinedCropsDetail.runoff_limit,
          supply_efficiency: predefinedCropsDetail.supply_efficiency,
          barren_land_evaporation:
            predefinedCropsDetail.barren_land_evaporation,
          water_body_evaporation: predefinedCropsDetail.water_body_evaporation,
          cultivation_land_evaporation:
            predefinedCropsDetail.cultivation_land_evaporation,
          habitation_land_evaporation:
            predefinedCropsDetail.habitation_land_evaporation,
          forest_land_evaporation:
            predefinedCropsDetail.forest_land_evaporation,
          total_cultivation_area: predefinedCropsDetail.total_cultivation_area,

          supplyCurrentRange: supplyCurrentRange,
          staticCurrentDemand: staticCurrentDemand,
          staticCurrentWaterAvailable: staticCurrentWaterAvailable,
          staticCurrentWaterBalance: staticCurrentWaterBalance,
          staticGroundWaterLast: staticGroundWaterLast,

          ctc:
            currentCrop === 'Crop Diversification'
              ? null
              : (runoffToBeArrestedStagant * 1000) / (0.25 * rainyDays),

          // baseConditionDataForEdit: baseConditionDataForEdit,
        },
      },
    };

    //COPYING THE FUNCTION AS OF NOW ENDS

    const updateScenario = await Patch(
      `/simulation/scenario?id=${isUpdateModeActiveScenarioId}`,
      token,
      payload,
    );

    if (updateScenario.status === 'success') {
      setIsUpdateSuccess('updateSuccess');
      setOpenUpdateModal(true);
    } else {
      setOpenUpdateModal(true);
      setIsUpdateSuccess('updateFailed');
    }
  };

  const uniqueManualCrops = filterUnique(allCrops);
  const uniqueRecommendedCrops = filterUnique(recommendedCrops);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
          }}
        >
          <LoginLoader />
        </div>
      ) : (
        <>
          {currentCrop !== 'savedScenario' &&
          currentCrop !== 'SavedScenarioSummary' &&
          currentCrop !== 'savedScenarioCropDiversification' &&
          currentCrop !== 'savedScenarioIrrigation' ? (
            <div className={Styles['plan-parent-wrapper']}>
              {openUpdateModal && (
                <Dialog
                  Paper
                  PaperProps={{ sx: { minWidth: '30%' } }}
                  disableEscapeKeyDown
                  open={openUpdateModal}
                  onClose={() => {
                    setOpenUpdateModal(false);
                  }}
                >
                  <DialogContent>
                    <div style={{ textAlign: 'center' }}>
                      <img
                        src={
                          isUpdateSuccess === 'updateSuccess'
                            ? savedScenario
                            : savedScenarioFailed
                        }
                        alt="Saved Scenario"
                        width={50}
                        style={{ textAlign: 'center' }}
                      />
                      <div style={{ marginTop: '15px' }}>
                        {isUpdateSuccess === 'updateSuccess'
                          ? translator[language][
                              'Scenario Updated Successfully'
                            ]
                          : 'Failed to update the scenario'}
                      </div>

                      <div
                        style={{
                          marginTop: '15px',
                          display: 'flex',
                          textAlign: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {/* COPYING BUTTONS  */}
                        <button
                          onClick={() => {
                            setOpenUpdateModal(false);
                            setIsUpdateModeScenarioId('');
                            setScenarioName('');
                            checkPlanIsOpen();
                            setCurrentCrop('SavedScenarioSummary');
                          }}
                          style={{
                            background: '#FFFFFF',
                            border: ' 1px solid #5F5F5F',
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            borderRadius: '10px',
                            padding: '6px 26px',
                            marginRight: '15px',
                            cursor: 'pointer',
                          }}
                        >
                          {translator[language]['Back to Plan']}
                        </button>
                        {isUpdateSuccess === 'updateSuccess' && (
                          <button
                            onClick={() => {
                              setOpenUpdateModal(false);
                            }}
                            style={{
                              background: '#448AE7',
                              color: '#ffffff',
                              border: '1px solid #448AE7',
                              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                              borderRadius: '10px',
                              padding: '6px 26px',
                              cursor: 'pointer',
                            }}
                          >
                            {translator[language]['Update Again']}
                          </button>
                        )}

                        {/* COPYING BUTTONS  */}
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              )}

              {openSaveModal && (
                <div>
                  <Dialog
                    Paper
                    PaperProps={{
                      sx: {
                        minWidth: '30%',
                        width: window.innerWidth < 600 ? '100%' : 'unset',
                      },
                    }}
                    disableEscapeKeyDown
                    open={openSaveModal}
                    onClose={() => {
                      setOpenSaveModal(false);
                      setIsScenarioSaved(false);
                      setScenarioName('');
                      setIsNameValid(true);
                    }}
                  >
                    <>
                      <div className={Styles.saveScenarioHeading}>
                        {translator[language]['Save Scenario']}
                      </div>

                      {isScenarioSaved && (
                        <div
                          style={{
                            margin: '5px 0 10px 0',
                            textAlign: 'center',
                          }}
                        >
                          {translator[language]['Plan']} {numberOfPlansSaved}{' '}
                          {translator[language]['of']} 3
                        </div>
                      )}
                      <DialogContent>
                        {isScenarioSaved ? (
                          <>
                            <div
                              style={{
                                textAlign: 'center',
                                marginBottom: '10px',
                              }}
                            >
                              {scenarioId !== '' ? (
                                <img
                                  src={savedScenario}
                                  alt="Saved Scenario"
                                  width={50}
                                  style={{ textAlign: 'center' }}
                                />
                              ) : (
                                <>
                                  <img
                                    src={savedScenarioFailed}
                                    alt="Saved Scenario"
                                    width={50}
                                    style={{ textAlign: 'center' }}
                                  />
                                </>
                              )}
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              {/* "{translator[props.language]["Scenario"]} 1" */}
                              "{scenarioName}"
                            </div>
                            {scenarioId !== '' ? (
                              <div style={{ textAlign: 'center' }}>
                                {translator[language]['Saved Successfully']}
                              </div>
                            ) : (
                              <div style={{ textAlign: 'center' }}>
                                {' '}
                                {translator[language]['failed to save']}
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              // justifyContent: "center",
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ marginBottom: '25px' }}>
                              <div
                                className={Styles.scenarioTypeModal}
                                style={{ marginBottom: '8px' }}
                              >
                                {translator[language]['Scenario Type']}
                              </div>
                              <TextField
                                className={Styles.modalTextField}
                                sx={{
                                  background: '#EBEBEB',
                                  // width: 'auto',

                                  minWidth:
                                    window.innerWidth < 768 ? '250px' : '320px',
                                  '& .MuiInputBase-root': {
                                    '@media (max-width:600px)': {
                                      fontSize: '.75rem',
                                    },
                                  },
                                }}
                                size="small"
                                value={
                                  currentCrop === 'Crop Diversification'
                                    ? 'Crop-Diversification'
                                    : currentCrop === 'Irrigation Side'
                                    ? 'Demand Irrigation Cycle'
                                    : 'Demand-Supply'
                                }
                                disabled
                              />
                            </div>

                            <div>
                              <div style={{ marginBottom: '8px' }}>
                                <span className={Styles.modalName}>
                                  {translator[language]['Name']}{' '}
                                </span>
                                <div
                                  className={Styles.nameValidation}
                                  style={{ fontSize: '11px', color: 'red' }}
                                >
                                  {isNameValid && scenarioName !== '' ? (
                                    <>
                                      <div>
                                        {' '}
                                        {
                                          translator[language][
                                            'Name cannot have less than 3 characters and special characters'
                                          ]
                                        }
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                              <TextField
                                type="text"
                                size="small"
                                sx={{
                                  minWidth:
                                    window.innerWidth < 768 ? '250px' : '320px',
                                  // width: 'auto',
                                }}
                                required
                                value={scenarioName}
                                onChange={(e) => validateSave(e)}
                              />
                            </div>
                          </div>
                        )}

                        <div
                          style={{
                            display: 'flex',
                            marginTop: '20px',
                            justifyContent: 'center',
                          }}
                        >
                          {!isScenarioSaved ? (
                            <>
                              <button
                                onClick={() => {
                                  setScenarioName('');
                                  setOpenSaveModal(false);
                                  setIsNameValid(true);
                                }}
                                style={{
                                  background: '#FFFFFF',
                                  border: '1px solid #5F5F5F',
                                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                  borderRadius: '10px',
                                  padding: '6px 26px',
                                  marginRight: '15px',
                                  cursor: 'pointer',
                                }}
                              >
                                {translator[language]['Cancel']}
                              </button>

                              <button
                                onClick={() =>
                                  !isNameValid && validateSaveFunc()
                                }
                                style={{
                                  background: !isNameValid
                                    ? '#448AE7'
                                    : '#EBEBEB',

                                  color: '#ffffff',
                                  border: !isNameValid
                                    ? '1px solid #448AE7'
                                    : '1px solid #EBEBEB',
                                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                  borderRadius: '10px',
                                  padding: '6px 26px',
                                  cursor: 'pointer',
                                }}
                              >
                                {translator[language]['Save']}
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => {
                                  setOpenSaveModal(false);
                                  setIsScenarioSaved(false);
                                  setScenarioName('');
                                  checkPlanIsOpen();
                                }}
                                style={{
                                  background: '#FFFFFF',
                                  border: ' 1px solid #5F5F5F',
                                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                  borderRadius: '10px',
                                  padding: '6px 26px',
                                  marginRight: '15px',
                                  cursor: 'pointer',
                                }}
                              >
                                {translator[language]['Back to Plan']}
                              </button>
                              <button
                                disabled={scenarioId !== '' ? false : true}
                                onClick={() => {
                                  viewScenarioFunction();
                                }}
                                style={{
                                  background:
                                    scenarioId !== ''
                                      ? '#448AE7'
                                      : 'rgb(164, 164, 164)',
                                  color: '#ffffff',
                                  border:
                                    scenarioId !== ''
                                      ? '1px solid #448AE7'
                                      : '1px solid rgb(164, 164, 164)',
                                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                  borderRadius: '10px',
                                  padding: '6px 26px',
                                  cursor:
                                    scenarioId !== '' ? 'pointer' : 'unset',
                                }}
                              >
                                {translator[language]['View Scenario']}
                              </button>
                            </>
                          )}
                        </div>
                      </DialogContent>
                    </>
                  </Dialog>
                </div>
              )}

              <AnalyticsNavbar
                {...props}
                active2={true}
                currentCrop={currentCrop}
                setCurrentCrop={setCurrentCrop}
                onReset={onReset}
                cropListMode={cropListMode}
                setCropListMode={setCropListMode}
                // MODAL
                openSaveModal={openSaveModal}
                setOpenSaveModal={setOpenSaveModal}
                setArrayOfCropLists={setArrayOfCropLists}
                //CROP DIV (REMOVE LATER)
                setCurrentDemand={setCurrentDemand}
                setCurrentWaterAvailable={setCurrentWaterAvailable}
                setCurrentWaterBalance={setCurrentWaterBalance}
                setNewCropsAddedCount={setNewCropsAddedCount}
                isPlanPageOpen={isPlanPageOpen}
                supplyCurrentRange={supplyCurrentRange}
                isRunoffAvailable={isRunoffAvailable}
                buttonName={buttonName}
                updateScenario={updateScenario}
                isUpdateModeActiveScenarioType={isUpdateModeActiveScenarioType}
                handlePageHead={handlePageHead}
              />

              {isPlanPageOpen && accountType !== 'programManager' ? (
                <>
                  <div className={Styles['parent-container-main']}>
                    <div className={Styles['left-side-container']}>
                      <LeftSide
                        currentRange={currentRange}
                        setCurrentRange={setCurrentRange}
                        supplyCurrentRange={
                          cropListMode === 'Manual'
                            ? supplyCurrentRange
                            : recommendedSupplyCurrentRange
                        }
                        setSupplyCurrentRange={setSupplyCurrentRange}
                        isRunoffAvailable={isRunoffAvailable}
                        setIsRunoffAvailable={setIsRunoffAvailable}
                        currentDripArea={currentDripArea}
                        setCurrentDripArea={setCurrentDripArea}
                        allCrops={
                          // allCrops
                          cropListMode === 'Manual'
                            ? currentCrop === 'Irrigation Side'
                              ? irrigationCrops
                              : allCrops
                            : recommendedCrops
                        }
                        setAllCrops={
                          // setAllCrops
                          cropListMode === 'Manual'
                            ? currentCrop === 'Irrigation Side'
                              ? setIrrigationCrops
                              : setAllCrops
                            : recommendedCrops
                        }
                        finalRangeValue={finalRangeValue}
                        currentCropWaterRequirementValue={
                          currentCropWaterRequirementValue
                        }
                        waterMadeAvailable={waterMadeAvailable}
                        setWaterMadeAvailable={setWaterMadeAvailable}
                        runoffToBeArrestedStagant={runoffToBeArrestedStagant}
                        setRunoffToBeArrestedStagant={
                          setRunoffToBeArrestedStagant
                        }
                        currentCrop={currentCrop}
                        cropListMode={cropListMode}
                        // ADDED
                        currentDemand={currentDemand}
                        setCurrentDemand={setCurrentDemand}
                        currentWaterAvailable={currentWaterAvailable}
                        setCurrentWaterAvailable={setCurrentWaterAvailable}
                        currentWaterBalance={currentWaterBalance}
                        setCurrentWaterBalance={setCurrentWaterBalance}
                        totalMonthsRemaining={totalMonthsRemaining}
                        setTotalMonthsRemaining={setTotalMonthsRemaining}
                        totalWaterSaved={totalWaterSaved}
                        setTotalWaterSaved={setTotalWaterSaved}
                        recommendedCrops={recommendedCrops}
                        predefinedCropsDetail={predefinedCropsDetail}
                        checkRunoffAvailable={checkRunoffAvailable}
                        supplySideData={supplySideData}
                        firstRenderTotalCwr={firstRenderTotalCwr}
                        staticCurrentWaterAvailable={
                          staticCurrentWaterAvailable
                        }
                        staticCurrentDemand={staticCurrentDemand}
                        staticCurrentWaterBalance={staticCurrentWaterBalance}
                        baseMonth={baseMonth}
                        initialRender={initialRender}
                        maxPercentageOfSuppy={maxPercentageOfSuppy}
                        setMaxPercentageOfSupply={setMaxPercentageOfSupply}
                        actualIrrigaton={actualIrrigaton}
                        setActualIrrigation={setActualIrrigation}
                      />
                    </div>

                    <div className={Styles['right-side-container']}>
                      {currentCrop !== 'Crop Diversification' &&
                        currentCrop !== 'Irrigation Side' && (
                          <>
                            <div
                              className={Styles['for-the-arrows']}
                              onClick={() => setCurrentCrop('Demand Side')}
                            >
                              <img
                                alt="arrows"
                                src={
                                  currentCrop === 'Demand Side'
                                    ? back
                                    : backDark
                                }
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                }}
                              />
                            </div>

                            <div
                              className={`${Styles['for-the-arrows']}  ${Styles['for-the-arrows-right']}`}
                              onClick={() => setCurrentCrop('Supply Side')}
                            >
                              <img
                                alt="arrows"
                                src={
                                  currentCrop === 'Supply Side'
                                    ? forwardLight
                                    : forward
                                }
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                }}
                              />
                            </div>
                          </>
                        )}

                      <div
                        className={`${Styles['plan-grid-item']} ${Styles.item3}`}
                      >
                        {currentCrop === 'Demand Side' ? (
                          <RightSide
                            title="Demand Side"
                            currentRange={currentRange}
                            setCurrentRange={setCurrentRange}
                            currentCrop={currentCrop}
                            setCurrentCrop={setCurrentCrop}
                            currentDripArea={currentDripArea}
                            uniqueCrops={
                              cropListMode === 'Manual'
                                ? uniqueManualCrops
                                : uniqueRecommendedCrops
                            }
                            allCrops={
                              cropListMode === 'Manual'
                                ? allCrops
                                : recommendedCrops
                            }
                            setAllCrops={
                              cropListMode === 'Manual'
                                ? setAllCrops
                                : setRecommendedCrops
                            }
                            setCurrentCropWaterRequirementValue={
                              setCurrentCropWaterRequirementValue
                            }
                            cropListMode={cropListMode}
                            setCropListMode={setCropListMode}
                            // allCropsNewState={allCropsNewState}
                            recommendedCrops={recommendedCrops}
                            setRecommendedCrops={setRecommendedCrops}
                            // isReset={isReset}
                            supplySideData={supplySideData}
                            checkRunoffAvailable={checkRunoffAvailable}
                            predefinedCropsDetail={predefinedCropsDetail}
                            staticCurrentWaterAvailable={
                              staticCurrentWaterAvailable
                            }
                            // FOR UNMOUNT USEFFECT
                            isUpdateModeActive={isUpdateModeActive}
                            setIsUpdateModeActive={setIsUpdateModeActive}
                            checkPlanIsOpen={checkPlanIsOpen}
                            setUpdatedCropList={setUpdatedCropList}
                            // setCurrentCrop={setCurrentCrop}
                            isUpdateModeActiveScenarioId={
                              isUpdateModeActiveScenarioId
                            }
                            // callTheMain={callTheMain}
                            scenarioId={scenarioId}
                            setSupplyCurrentRange={setSupplyCurrentRange}
                            setRecommendedSupplyCurrentRange={
                              setRecommendedSupplyCurrentRange
                            }
                            recommendedScenarioId={recommendedScenarioId}
                            maxPercentageOfSuppy={maxPercentageOfSuppy}
                            setMaxPercentageOfSupply={setMaxPercentageOfSupply}
                            actualIrrigaton={actualIrrigaton}
                            setActualIrrigation={setActualIrrigation}
                          />
                        ) : currentCrop === 'Crop Diversification' ? (
                          <CropDiversification
                            allCrops={allCrops}
                            setAllCrops={setAllCrops}
                            arrOfCropLists={arrOfCropLists}
                            setArrayOfCropLists={setArrayOfCropLists}
                            // getNewValue={getNewValue}

                            currentDemand={currentDemand}
                            setCurrentDemand={setCurrentDemand}
                            currentWaterAvailable={currentWaterAvailable}
                            setCurrentWaterAvailable={setCurrentWaterAvailable}
                            currentWaterBalance={currentWaterBalance}
                            setCurrentWaterBalance={setCurrentWaterBalance}
                            newCropsAddedCount={newCropsAddedCount}
                            setNewCropsAddedCount={setNewCropsAddedCount}
                            predefinedCropsDetail={predefinedCropsDetail}
                            yearSession={currentPlanYear}
                            season={selectedSeason}
                            onReset={onReset}
                            getBaseConditionCropDiversification={
                              getBaseConditionCropDiversification
                            }
                            backupArrayForOldCropArea={
                              backupArrayForOldCropArea
                            }
                            scenarioId={scenarioId}
                            setTotalWaterSaved={setTotalWaterSaved}
                          />
                        ) : currentCrop === 'Irrigation Side' ? (
                          <IrrigationSide
                            title="Demand-Irrigation Cycle"
                            currentRange={currentRange}
                            setCurrentRange={setCurrentRange}
                            currentCrop={currentCrop}
                            setCurrentCrop={setCurrentCrop}
                            currentDripArea={currentDripArea}
                            uniqueCrops={
                              cropListMode === 'Manual'
                                ? uniqueManualCrops
                                : uniqueRecommendedCrops
                            }
                            allCrops={
                              cropListMode === 'Manual'
                                ? irrigationCrops
                                : recommendedCrops
                            }
                            setAllCrops={
                              cropListMode === 'Manual'
                                ? setIrrigationCrops
                                : setRecommendedCrops
                            }
                            // allCrops={
                            //   cropListMode === 'Manual'
                            //     ? allCrops
                            //     : recommendedCrops
                            // }
                            // setAllCrops={
                            //   cropListMode === 'Manual'
                            //     ? setAllCrops
                            //     : setRecommendedCrops
                            // }

                            setCurrentCropWaterRequirementValue={
                              setCurrentCropWaterRequirementValue
                            }
                            cropListMode={cropListMode}
                            setCropListMode={setCropListMode}
                            // allCropsNewState={allCropsNewState}
                            recommendedCrops={recommendedCrops}
                            setRecommendedCrops={setRecommendedCrops}
                            // isReset={isReset}
                            supplySideData={supplySideData}
                            checkRunoffAvailable={checkRunoffAvailable}
                            predefinedCropsDetail={predefinedCropsDetail}
                            staticCurrentWaterAvailable={
                              staticCurrentWaterAvailable
                            }
                            // FOR UNMOUNT USEFFECT
                            isUpdateModeActive={isUpdateModeActive}
                            setIsUpdateModeActive={setIsUpdateModeActive}
                            checkPlanIsOpen={checkPlanIsOpen}
                            setUpdatedCropList={setUpdatedCropList}
                            // setCurrentCrop={setCurrentCrop}
                            isUpdateModeActiveScenarioId={
                              isUpdateModeActiveScenarioId
                            }
                            // callTheMain={callTheMain}
                            scenarioId={scenarioId}
                            setSupplyCurrentRange={setSupplyCurrentRange}
                            setRecommendedSupplyCurrentRange={
                              setRecommendedSupplyCurrentRange
                            }
                            recommendedScenarioId={recommendedScenarioId}
                            maxPercentageOfSuppy={maxPercentageOfSuppy}
                            setMaxPercentageOfSupply={setMaxPercentageOfSupply}
                            actualIrrigaton={actualIrrigaton}
                            setActualIrrigation={setActualIrrigation}
                            allCropsForReset={
                              allCropsForResetUpdateModeIrrigation
                            }
                            setAllCropsForReset={
                              setAllCropsForResetUpdateModeIrrigation
                            }
                          />
                        ) : currentCrop === 'Supply Side' ? (
                          <RightSide
                            title="Supply Side"
                            currentRange={currentRange}
                            setCurrentRange={setCurrentRange}
                            currentCrop={currentCrop}
                            setCurrentCrop={setCurrentCrop}
                            currentDripArea={currentDripArea}
                            supplyCurrentRange={
                              cropListMode === 'Manual'
                                ? supplyCurrentRange
                                : recommendedSupplyCurrentRange
                            }
                            setSupplyCurrentRange={setSupplyCurrentRange}
                            isRunoffAvailable={isRunoffAvailable}
                            setIsRunoffAvailable={setIsRunoffAvailable}
                            waterMadeAvailable={
                              waterMadeAvailable > 0 ? waterMadeAvailable : 0
                            }
                            setWaterMadeAvailable={setWaterMadeAvailable}
                            runoffToBeArrestedStagant={
                              runoffToBeArrestedStagant > 0
                                ? runoffToBeArrestedStagant
                                : 0
                            }
                            setRunoffToBeArrestedStagant={
                              setRunoffToBeArrestedStagant
                            }
                            cropListMode={cropListMode}
                            setCropListMode={setCropListMode}
                            recommendedSupplyCurrentRange={
                              recommendedSupplyCurrentRange
                            }
                            setRecommendedSupplyCurrentRange={
                              setRecommendedSupplyCurrentRange
                            }
                            // isReset={isReset}
                            supplySideData={supplySideData}
                            checkRunoffAvailable={checkRunoffAvailable}
                            predefinedCropsDetail={predefinedCropsDetail}
                            staticCurrentWaterAvailable={
                              staticCurrentWaterAvailable
                            }
                            // FOR UNMOUNT USEFFECT
                            isUpdateModeActive={isUpdateModeActive}
                            setIsUpdateModeActive={setIsUpdateModeActive}
                            checkPlanIsOpen={checkPlanIsOpen}
                            setUpdatedCropList={setUpdatedCropList}
                            //set current crop already passed
                            isUpdateModeActiveScenarioId={
                              isUpdateModeActiveScenarioId
                            }
                            scenarioId={scenarioId}
                            // callTheMain={callTheMain}

                            setRecommendedCrops={setRecommendedCrops}
                            recommendedScenarioId={recommendedScenarioId}
                            maxPercentageOfSuppy={maxPercentageOfSuppy}
                            setMaxPercentageOfSupply={setMaxPercentageOfSupply}
                          />
                        ) : null}
                      </div>

                      {/* HIDDEN TILL 1176px HITS   */}
                      <div
                        className={`${Styles['plan-grid-item']} ${Styles.item2} ${Styles['small-screen-item2']}`}
                      >
                        <div className={Styles['left-last']}>
                          <div className={Styles['last-container']}>
                            <div className={Styles['last-container-one']}>
                              <p
                                className={Styles.groundwaterLastDurationText}
                                style={{ height: '50%' }}
                              >
                                {
                                  translator[language][
                                    'With the current situation Groundwater will last'
                                  ]
                                }
                              </p>
                              <p
                                className={Styles['months-remaining-text']}
                                style={{
                                  fontWeight: 'bold',

                                  marginBottom: '20px',
                                  marginTop: '20px',
                                }}
                              >
                                {totalMonthsRemaining.toFixed(1)}{' '}
                                {translator[language][`Months`]}
                              </p>
                            </div>
                            <div className={Styles['last-container-two']}>
                              <p
                                className={Styles.waterSavedText}
                                style={{ height: '50%' }}
                              >
                                {translator[language][`Total Water Saved`]}
                              </p>
                              <p
                                className={Styles['months-remaining-text']}
                                style={{
                                  fontWeight: 'bold',

                                  marginBottom: '20px',
                                  marginTop: '20px',
                                }}
                              >
                                {getUnitValue(
                                  totalWaterSaved.toFixed(1),
                                  selectedUnit,
                                  selectedVillageTankerValue,
                                )}{' '}
                                {translator[language][selectedUnit]}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* HIDDEN TILL 1176px HITS   */}
                    </div>
                  </div>
                </>
              ) : accountType === 'programManager' ? (
                <SavedScenarioSummary
                  {...props}
                  season={selectedSeason}
                  currentCrop={currentCrop}
                  setCurrentCrop={setCurrentCrop}
                  allCrops={allCrops}
                  scenarioId={scenarioId}
                  setScenarioId={setScenarioId}
                  globalPlanId={globalPlanId}
                  // FILTER
                  scenarios={scenarios}
                  setScenarios={setScenarios}
                  trackFilteredValues={trackFilteredValues}
                  setTrackFilteredValues={setTrackFilteredValues}
                  globalArrayCount={globalArrayCount}
                  setGlobalArrayCount={setGlobalArrayCount}
                  currentMappedData={currentMappedData}
                  setCurrentMappedData={setCurrentMappedData}
                  referAnotherArray={referAnotherArray}
                  setReferAnotherArray={setReferAnotherArray}
                  duplicateArray={duplicateArray}
                  setDuplicateArray={setDuplicateArray}
                  currentFilterType={currentFilterType}
                  setCurrentFilterType={setCurrentFilterType}
                  recommendedFilterArray={recommendedFilterArray}
                  setRecommendedFilterArray={setRecommendedFilterArray}
                  currentCountOfOwnPlansSaved={currentCountOfOwnPlansSaved}
                  setCurrentCountOfOwnPlansSaved={
                    setCurrentCountOfOwnPlansSaved
                  }
                  setCropListMode={setCropListMode}
                />
              ) : (
                <NoPlanAvailable
                  submitFormByAdmin={submitFormByAdmin}
                  isPlanScheduleSubmitted={isPlanScheduleSubmitted}
                  dateByAdmin={dateByAdmin}
                  setDateByAdmin={setDateByAdmin}
                  checkPlanIsOpen={checkPlanIsOpen}
                  errorLog={errorLog}
                />
              )}
            </div>
          ) : currentCrop === 'savedScenario' ? (
            <SavedScenario
              setCurrentCrop={setCurrentCrop}
              setOpenSaveModal={setOpenSaveModal}
              season={selectedSeason}
              onReset={onReset}
              setCropListMode={setCropListMode}
              scenarioId={scenarioId}
              setScenarioId={setScenarioId}
              currentCrop={currentCrop}
              callBaseConditionForEdit={callBaseConditionForEdit}
              isUpdateModeActiveScenarioType={isUpdateModeActiveScenarioType}
              globalPublishedScenarioId={globalPublishedScenarioId}
              checkPlanIsOpen={checkPlanIsOpen}
              // FILTER ARRAYS
              scenarios={scenarios}
              setScenarios={setScenarios}
              trackFilteredValues={trackFilteredValues}
              setTrackFilteredValues={setTrackFilteredValues}
              referAnotherArray={referAnotherArray}
              setReferAnotherArray={setReferAnotherArray}
              currentCountOfOwnPlansSaved={currentCountOfOwnPlansSaved}
              setCurrentCountOfOwnPlansSaved={setCurrentCountOfOwnPlansSaved}
              duplicateArray={duplicateArray}
              setDuplicateArray={setDuplicateArray}
            />
          ) : currentCrop === 'savedScenarioIrrigation' ? (
            <SavedScenarioIrrigation
              setCurrentCrop={setCurrentCrop}
              setOpenSaveModal={setOpenSaveModal}
              season={selectedSeason}
              onReset={onReset}
              setCropListMode={setCropListMode}
              scenarioId={scenarioId}
              setScenarioId={setScenarioId}
              currentCrop={currentCrop}
              callBaseConditionForEdit={callBaseConditionForEdit}
              isUpdateModeActiveScenarioType={isUpdateModeActiveScenarioType}
              globalPublishedScenarioId={globalPublishedScenarioId}
              checkPlanIsOpen={checkPlanIsOpen}
              // FILTER ARRAYS
              scenarios={scenarios}
              setScenarios={setScenarios}
              trackFilteredValues={trackFilteredValues}
              setTrackFilteredValues={setTrackFilteredValues}
              referAnotherArray={referAnotherArray}
              setReferAnotherArray={setReferAnotherArray}
              currentCountOfOwnPlansSaved={currentCountOfOwnPlansSaved}
              setCurrentCountOfOwnPlansSaved={setCurrentCountOfOwnPlansSaved}
              duplicateArray={duplicateArray}
              setDuplicateArray={setDuplicateArray}
            />
          ) : currentCrop === 'savedScenarioCropDiversification' ? (
            <SavedScenarioCropDiversification
              setCurrentCrop={setCurrentCrop}
              season={selectedSeason}
              scenarioId={scenarioId}
              setScenarioId={setScenarioId}
              currentCrop={currentCrop}
              callBaseConditionForEdit={callBaseConditionForEdit}
              isUpdateModeActiveScenarioType={isUpdateModeActiveScenarioType}
              globalPublishedScenarioId={globalPublishedScenarioId}
              checkPlanIsOpen={checkPlanIsOpen}
              // FILTER ARRAYS
              scenarios={scenarios}
              setScenarios={setScenarios}
              trackFilteredValues={trackFilteredValues}
              setTrackFilteredValues={setTrackFilteredValues}
              referAnotherArray={referAnotherArray}
              setReferAnotherArray={setReferAnotherArray}
              currentCountOfOwnPlansSaved={currentCountOfOwnPlansSaved}
              setCurrentCountOfOwnPlansSaved={setCurrentCountOfOwnPlansSaved}
              duplicateArray={duplicateArray}
              setDuplicateArray={setDuplicateArray}
            />
          ) : currentCrop === 'SavedScenarioSummary' ? (
            <SavedScenarioSummary
              {...props}
              season={selectedSeason}
              currentCrop={currentCrop}
              setCurrentCrop={setCurrentCrop}
              allCrops={allCrops}
              scenarioId={scenarioId}
              setScenarioId={setScenarioId}
              globalPlanId={globalPlanId}
              // FILTER
              scenarios={scenarios}
              setScenarios={setScenarios}
              trackFilteredValues={trackFilteredValues}
              setTrackFilteredValues={setTrackFilteredValues}
              globalArrayCount={globalArrayCount}
              setGlobalArrayCount={setGlobalArrayCount}
              currentMappedData={currentMappedData}
              setCurrentMappedData={setCurrentMappedData}
              referAnotherArray={referAnotherArray}
              setReferAnotherArray={setReferAnotherArray}
              duplicateArray={duplicateArray}
              setDuplicateArray={setDuplicateArray}
              currentFilterType={currentFilterType}
              setCurrentFilterType={setCurrentFilterType}
              recommendedFilterArray={recommendedFilterArray}
              setRecommendedFilterArray={setRecommendedFilterArray}
              currentCountOfOwnPlansSaved={currentCountOfOwnPlansSaved}
              setCurrentCountOfOwnPlansSaved={setCurrentCountOfOwnPlansSaved}
              setCropListMode={setCropListMode}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default Plan;
