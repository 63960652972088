import React, { useContext, useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import WaterAvailabilityLoader from '../../Loader/hash';
import { Get } from '../../../services/apiCall';
import roundOff from '../../../services/round';
import translator from '../../../assets/json/translator.json';
import { sortDatesByYearAndMonth } from '../../../services/genericSortMonthYear';
import months from '../../../shared/constants/months';
import { useParams } from 'react-router-dom';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';
import Styles from './WaterAvailability.module.css';
import getUnitValue from '../../../services/getUnitValueFunction';
import useProcessedChartData from '../../../services/hooks/useProcessedChartData';

const CustomTooltip = ({ active, payload, message, selectedUnit }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          boxShadow: '0px 3px 6px #00000029',
          width: '130%',
          padding: '5%',
        }}
      >
        {payload.length > 0 && payload[0].payload.name ? (
          <p
            className={Styles.label}
            style={{ margin: '5%', fontWeight: '600' }}
          >{`${payload[0].payload.name}`}</p>
        ) : (
          <></>
        )}
        {payload.length > 0 && payload[0].payload.Supply ? (
          <p
            className={Styles.label}
            style={{ margin: '5%', color: '#3883E6' }}
          >{`${translator[message.language]['Supply']} : ${roundOff(
            payload[0].payload.Supply,
          )} ${translator[message.language][selectedUnit]}`}</p>
        ) : (
          <p
            className={Styles.label}
            style={{ margin: '5%', color: '#3883E6' }}
          >{`${translator[message.language]['Supply']} : 0 ${
            translator[message.language][selectedUnit]
          }`}</p>
        )}
        {payload.length > 0 && payload[0].payload.Demand ? (
          <p
            className={Styles.label}
            style={{ margin: '5%', color: '#ED561B' }}
          >{`${translator[message.language]['Demand']} : ${roundOff(
            payload[0].payload.Demand,
          )} ${translator[message.language][selectedUnit]}`}</p>
        ) : (
          <p
            className={Styles.label}
            style={{ margin: '5%', color: '#ED561B' }}
          >{`${translator[message.language]['Demand']} : 0 ${
            translator[message.language][selectedUnit]
          }`}</p>
        )}
        {payload.length > 0 && payload[0].payload.Balance ? (
          <p
            className={Styles.label}
            style={{ margin: '5%', color: '#707070' }}
          >{`${translator[message.language]['Balance']} : ${roundOff(
            payload[0].payload.Balance,
          )} ${translator[message.language][selectedUnit]}`}</p>
        ) : (
          <p
            className={Styles.label}
            style={{ margin: '5%', color: '#707070' }}
          >{`${translator[message.language]['Balance']} : 0 ${
            translator[message.language][selectedUnit]
          }`}</p>
        )}
      </div>
    );
  }

  return null;
};

/**
 *
 * @component WaterAvailability
 * @states
 * isLoading --> If true show animation
 * data --> An array that stores the previous 6 months(max) supply-demand data
 * currentRainfallTCM --> Stores the water availability of the village of that particular month
 * @description
 * This component represents the current water availability and a supply-demand
 * graph of that village
 */
const WaterAvailability = () => {
  const { villageId, clusterId } = useParams();
  const { month, year, pageHead } = useContext(TimelineContext);
  const {
    language,
    programId,
    selectedUnit,
    selectedVillageTankerValue,
    handleVillageAreaChange,
  } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  // const [currentRainfallTCM, setCurrentRainfallTCM] = useState(0);
  const [villageDetails, setVillageDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const params = {
          year: year,
          month: month + 1,
          villageId: villageId,
          programId: programId,
          clusterId: clusterId,
        };
        const response = await Get(
          '/datapoint/water-availability',
          token,
          params,
        );
        let payload = response.data;

        const sortedData = sortDatesByYearAndMonth(payload);
        payload = sortedData;

        const finalData = [];
        // let finalCurrentRainfallTCM = 0;
        if (payload && payload.length > 0) {
          /**
           * sorts the data as per the month name
           * @todo sort as per the year data too
           */
          payload.sort((a, b) => {
            if (a.year === b.year) return a.month - b.month;
            return a.year - b.year;
          });

          payload.map((idx) => {
            // if (props.month + 1 === idx.month) {
            //   finalCurrentRainfallTCM = idx.current_rainfall_tcm;
            // }
            return finalData.push({
              name: `${months[idx.month - 1].substring(0, 3)} ${String(
                idx.year,
              ).slice(-2)}`,
              Supply: idx.total_water_available,
              Demand: idx.total_water_demand,
              Balance: idx.total_water_balance,
            });
          });
          setData(finalData);
          // setCurrentRainfallTCM(finalCurrentRainfallTCM);
        }

        setIsLoading(false);

        // CALLING VILLAGE DETAILED API HERE TO GET THE DATA OF SUPPY/COSUMED AND WATER BALANCE

        const paramsVillage = {
          villageId: villageId,
          month: month + 1,
          year: year,
          language: language,
          programId: programId,
          clusterId: clusterId,
        };

        const responseVillage = await Get(
          '/analytics/village-detailed',
          token,
          paramsVillage,
        );

        if (responseVillage?.data) {
          handleVillageAreaChange(responseVillage?.data?.area);
        }

        let villageDetailed = responseVillage?.data?.VillageMonthlyStats1[0];
        if (villageDetailed) {
          setVillageDetails(villageDetailed);
        }

        // CALLING VILLAGE DETAILED API HERE TO GET THE DATA OF SUPPY/COSUMED AND WATER BALANCE
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();

    return () => {
      setData([]);
      setVillageDetails([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, pageHead]);

  /**
   *
   * @param {*} props - props of the recharts graph
   * @returns legend for the prop
   */

  let message = {
    language: language,
  };

  const chartValues = JSON.stringify(['Supply', 'Demand', 'Balance']);

  const processedData = useProcessedChartData(
    data,
    selectedUnit,
    selectedVillageTankerValue,
    month,
    year,
    villageId,
    chartValues,
  );

  return (
    <div className={Styles.analyticsWaterAvailability}>
      {!isLoading ? (
        <>
          {/* ADDING SUPPLY WATER BALANCE  */}
          <div
            className="parent-div-container"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '20px',
              padding: '0 18px',
            }}
          >
            <div>
              <div
                className={Styles['heading-txt']}
                style={{ color: '#3883E6' }}
              >
                {translator[language]['Water Supply']}
              </div>
              <div
                className={Styles['value-txt']}
                style={{
                  color: '#3883E6',
                  fontWeight: 'bold',
                  marginTop: '7px',
                }}
              >
                {getUnitValue(
                  roundOff(villageDetails?.total_water_available),
                  selectedUnit,
                  selectedVillageTankerValue,
                )}{' '}
                {translator[language][selectedUnit]}
              </div>
            </div>
            <div>
              <div
                className={Styles['heading-txt']}
                style={{ color: '#ED561B' }}
              >
                {translator[language]['Water Consumed']}
              </div>
              <div
                className={Styles['value-txt']}
                style={{
                  color: '#ED561B',
                  fontWeight: 'bold',
                  marginTop: '7px',
                }}
              >
                {getUnitValue(
                  roundOff(villageDetails?.total_water_demand),
                  selectedUnit,
                  selectedVillageTankerValue,
                )}{' '}
                {translator[language][selectedUnit]}
              </div>
            </div>
            <div>
              <div
                className={Styles['heading-txt']}
                style={{ color: '#8C8C8C' }}
              >
                {translator[language]['Water Balance']}
              </div>
              <div
                className={Styles['value-txt']}
                style={{
                  color: '#8C8C8C',
                  fontWeight: 'bold',
                  marginTop: '7px',
                }}
              >
                {getUnitValue(
                  roundOff(villageDetails?.total_water_balance),
                  selectedUnit,
                  selectedVillageTankerValue,
                )}{' '}
                {translator[language][selectedUnit]}
              </div>
            </div>
          </div>

          {/* ADDING SUPPLY WATER BALANCE  */}
          <div
            style={{
              marginTop: '20px',
            }}
            className={Styles['waterAvailabilityChartHeight']}
          >
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={350}
                data={processedData?.length > 0 ? processedData : null}
                margin={{
                  // left: -10,
                  left: 0,
                  right: 5,
                }}
                className={Styles.analyticsWaterAvailabilityChart}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  label={{
                    value: translator[language][selectedUnit],
                    angle: -90,
                    position: 'insideLeft',
                    dx: 0,
                    dy: 12,
                    scaleToFit: true,
                    fontSize: window.innerWidth < 768 ? 10 : 12,
                    fill: '#707070',
                  }}
                />
                <Tooltip
                  formatter={(value) =>
                    new Intl.NumberFormat('en').format(value) +
                    ' ' +
                    translator[language][selectedUnit]
                  }
                  content={
                    <CustomTooltip
                      message={message}
                      selectedUnit={selectedUnit}
                    />
                  }
                />

                <Line
                  type="monotone"
                  dataKey="Demand"
                  stroke="#ED561B"
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="Supply"
                  stroke="#3883E6"
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="Balance"
                  stroke="#707070"
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </>
      ) : (
        <WaterAvailabilityLoader
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        />
      )}
    </div>
  );
};

export default WaterAvailability;
