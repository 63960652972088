import { useState, useEffect } from 'react';
import { Get } from '../../../services/apiCall';
import AlertHeader from './AlertHeader';
import GroundwaterAlert from './GroundwaterAlert';
import SurfacewaterAlert from './SurfacewaterAlert';
import $ from 'jquery';
import translator from '../../../assets/json/translator.json';
import roundOff from '../../../services/round';
import RunoffGenerated from './RunoffGenerated';
import SoilMoisture from './SoilMoisture';
import Evapotranspiration from './Evapotranspiration';
import months from '../../../shared/constants/months';
import { sortDatesByYearAndMonth } from '../../../services/genericSortMonthYear';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';
import Styles from './Alert.module.css';
import wellIcon from '../../../assets/images/well_icon_gw.svg';
import soilMoistureIcon from '../../../assets/images/soil_moisture_icon.svg';
import surfacewaterIcon from '../../../assets/images/surfacewater_icon.svg';
import evapotranspirationIcon from '../../../assets/images/evapotranspiration_icon.svg';
import runoffGeneratedIcon from '../../../assets/images/runoff_generated_icon.svg';
import domesticIcon from '../../../assets/images/domestic_icon.svg';
import ConsumptionAlert from './ConsumptionAlert';

/**
 *
 * @component Alert
 * @description
 * This component represents the right part of the Analytics Overview page and it consists
 * of data overview on groundwater, surfacewater, landuse and domestic/industrial consumption
 */
const Alert = () => {
  const { villageId, clusterId } = useParams();
  const { month, year, pageHead } = useContext(TimelineContext);
  const { language, programId } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  // states

  const [runoffTcm, setRunoffTcm] = useState(0);
  const [totalSurfaceWaterStorage, setTotalSurfaceWaterStorage] = useState(0);

  const [groundwaterLevelTcm, setGroundwaterLevelTcm] = useState(0);
  const [totalCattleWaterConsumption, setTotalCattleWaterConsumption] =
    useState(0);
  const [totalHospitalWaterConsumption, setTotalHospitalWaterConsumption] =
    useState(0);
  const [totalHumanWaterConsumption, setTotalHumanWaterConsumption] =
    useState(0);
  const [totalIndustryWaterConsumption, setTotalIndustryWaterConsumption] =
    useState(0);
  const [totalPoultryWaterConsumption, setTotalPoultryWaterConsumption] =
    useState(0);
  const [totalSchoolWaterConsumption, setTotalSchoolWaterConsumption] =
    useState(0);
  const [totalSheepWaterConsumption, setTotalSheepWaterConsumption] =
    useState(0);
  const [groundWaterStage, setGroundWaterStage] = useState(0);
  const [humanPopulation, setHumanPopulation] = useState(0);
  const [sheepPopulation, setSheepPopulation] = useState(0);
  const [poultryPopulation, setPoultryPopulation] = useState(0);
  const [cattlePopulation, setCattlePopulation] = useState(0);
  const [hospitalPopulation, setHospitalPopulation] = useState(0);
  const [schoolPopulation, setSchoolPopulation] = useState(0);
  const [industryPopulation, setIndustryPopulation] = useState(0);

  const [specific_yield, setSpecificYield] = useState('');
  const [total_soil_moisture, setTotalSoilMoisture] = useState('');
  const [runoffTillNow, setRunoffTillNow] = useState('');
  const [soil_type, setSoilType] = useState('NA');
  const [soil_depth, setSoilDepth] = useState(0);

  // EVAPO STATES
  const [data, setData] = useState([]);
  const [previousGWLevel, setPreviousGWLevel] = useState(0);
  const [currentGWLevel, setCurrentGWLevel] = useState(0);
  const [currentMonthData, setCurrentMonthData] = useState([]);

  const [populationData, setPopulationData] = useState([]);

  const [villageArea, setVillageArea] = useState('');

  const [maxValue, setMaxValue] = useState(0);
  const [totalPopulation, setTotalPopulation] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const id = document.getElementById('analyticsAlertBarGroundwater');
    id.style.display = 'none';
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const params = {
        villageId: villageId,
        month: month + 1,
        year: year,
        language: language,
        programId: programId,
        clusterId: clusterId,
      };

      const response = await Get('/analytics/village-detailed', token, params);

      if (response && response.data) {
        setVillageArea(response.data.area);
        setSpecificYield(response.data.specific_yield);
        setSoilType(response.data.VillageGis[0].soil_type);
        setSoilDepth(response.data.VillageGis[0].soil_depth);
        setTotalSoilMoisture(
          response.data.VillageMonthlyStats1[0]?.total_soil_moisture,
        );
        setRunoffTillNow(response.data.total_runoff);

        const payload = response.data;
        if (payload.VillageMonthlyStats1.length > 0) {
          const resData = payload.VillageMonthlyStats1[0];
          setRunoffTcm(resData.runoff_tcm);
          setTotalSurfaceWaterStorage(resData.total_surface_water_storage);
        }

        if (payload.VillageMonthlyStats2.length > 0) {
          const resData = payload.VillageMonthlyStats2[0];
          const humanPopulation = resData.total_human
            ? resData.total_human
            : 0.0;
          const sheepPopulation = resData.total_sheep
            ? resData.total_sheep
            : 0.0;
          const poultryPopulation = resData.total_poultry
            ? resData.total_poultry
            : 0.0;
          const cattlePopulation = resData.total_cattle
            ? resData.total_cattle
            : 0.0;
          const hospitalPopulation = resData.total_hospital
            ? resData.total_hospital
            : 0.0;
          const schoolPopulation = resData.total_school
            ? resData.total_school
            : 0.0;
          const industryPopulation = resData.total_industry
            ? resData.total_industry
            : 0.0;

          const overallTotalPopulation =
            humanPopulation +
            sheepPopulation +
            poultryPopulation +
            cattlePopulation +
            hospitalPopulation +
            schoolPopulation +
            industryPopulation;
          setTotalPopulation(overallTotalPopulation);

          setGroundwaterLevelTcm(resData.groundwater_level_tcm);
          setTotalCattleWaterConsumption(
            resData.total_cattle_water_consumption,
          );
          setTotalHospitalWaterConsumption(
            resData.total_hospital_water_consumption,
          );
          setTotalHumanWaterConsumption(resData.total_human_water_consumption);
          setTotalIndustryWaterConsumption(
            resData.total_industry_water_consumption,
          );
          setTotalPoultryWaterConsumption(
            resData.total_poultry_water_consumption,
          );
          setTotalSchoolWaterConsumption(
            resData.total_school_water_consumption,
          );
          setTotalSheepWaterConsumption(resData.total_sheep_water_consumption);
          setHumanPopulation(humanPopulation);
          setSheepPopulation(sheepPopulation);
          setPoultryPopulation(poultryPopulation);
          setCattlePopulation(cattlePopulation);
          setHospitalPopulation(hospitalPopulation);
          setSchoolPopulation(schoolPopulation);
          setIndustryPopulation(industryPopulation);
        }

        if (payload.VillageYearlyStats.length > 0) {
          const resData = payload.VillageYearlyStats[0];
          setGroundWaterStage(resData.groundwater_stage);
        }
      }

      // water consumption:-
      const populationResponse = await Get(
        `/datapoint/population?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&year=${year}&month=${
          month + 1
        }`,
        token,
      );

      if (populationResponse.status === 'success') {
        setPopulationData(sortDatesByYearAndMonth(populationResponse.data));
      }

      // ------------------------------------------------------- MAIN STARTS HERE
      // CALLING EVAPOTRANSPIRATION API HERE
      // Starts
      const payload = {
        year: year,
        month: month + 1,
        villageId: villageId,
        language: language,
        programId: programId,
        clusterId: clusterId,
      };

      const finalResponse = await Get(
        `/datapoint/water-consumption`,
        token,
        payload,
      );
      if (finalResponse.status === 'success') {
        const sortedData = sortDatesByYearAndMonth(finalResponse.data);
        finalResponse.data = sortedData;

        setCurrentMonthData(
          finalResponse.data
            ? finalResponse.data[finalResponse.data.length - 1]
            : [],
        );

        let finalData = [];
        finalResponse.data.map((vals) => {
          return finalData.push({
            month: `${months[vals.month - 1].substring(0, 3)} ${String(
              vals.year,
            ).slice(-2)}`,
            Vegetative: vals.cultivation_water_lost_tcm,

            Others:
              vals.barren_water_lost_tcm +
              vals.forest_water_lost_tcm +
              vals.habitation_water_lost_tcm +
              vals.water_body_lost_tcm,
          });
        });

        if (finalResponse.data.length >= 2) {
          setCurrentGWLevel(
            finalResponse.data[finalResponse.data.length - 1]
              .cultivation_water_lost_tcm +
              finalResponse.data[finalResponse.data.length - 1]
                .barren_water_lost_tcm +
              finalResponse.data[finalResponse.data.length - 1]
                .forest_water_lost_tcm +
              finalResponse.data[finalResponse.data.length - 1]
                .habitation_water_lost_tcm +
              finalResponse.data[finalResponse.data.length - 1]
                .water_body_lost_tcm,
          );

          setPreviousGWLevel(
            finalResponse.data[finalResponse.data.length - 2]
              .cultivation_water_lost_tcm +
              finalResponse.data[finalResponse.data.length - 2]
                .barren_water_lost_tcm +
              finalResponse.data[finalResponse.data.length - 2]
                .forest_water_lost_tcm +
              finalResponse.data[finalResponse.data.length - 2]
                .habitation_water_lost_tcm +
              finalResponse.data[finalResponse.data.length - 2]
                .water_body_lost_tcm,
          );
        } else {
          setCurrentGWLevel(
            finalResponse.data[finalResponse.data.length - 1]
              .cultivation_water_lost_tcm +
              finalResponse.data[finalResponse.data.length - 1]
                .barren_water_lost_tcm +
              finalResponse.data[finalResponse.data.length - 1]
                .forest_water_lost_tcm +
              finalResponse.data[finalResponse.data.length - 1]
                .habitation_water_lost_tcm +
              finalResponse.data[finalResponse.data.length - 1]
                .water_body_lost_tcm,
          );
        }

        setData(finalData);
      }

      // WATER CONSUMPTION FOR MAX VALUE:-
      const payloadforMax = {
        year: month + 1 >= 6 ? year : year - 1,
        month: 5,
        villageId: villageId,
        language: language,
        programId: programId,
        clusterId: clusterId,
        duration: 12,
      };

      const finalResponseforMax = await Get(
        `/datapoint/water-consumption`,
        token,
        payloadforMax,
      );

      if (finalResponseforMax?.status === 'success') {
        const maxAll = (() => {
          const valuesArray = finalResponseforMax?.data?.map(
            (entry) =>
              entry.cultivation_water_lost_tcm +
              entry.barren_water_lost_tcm +
              entry.forest_water_lost_tcm +
              entry.habitation_water_lost_tcm +
              entry.water_body_lost_tcm,
          );

          // Check if valuesArray is not empty
          if (valuesArray && valuesArray.length > 0) {
            return Math.max(...valuesArray);
          } else {
            // Decide what to return for an empty array
            return 0; // You can choose another default value
          }
        })();

        const finalMax = Number(maxAll.toFixed(2));
        // const finalMaxOthers = Number(maxOthers.toFixed(2));

        const maxBoth = parseFloat((finalMax + 0.5 * finalMax).toFixed(2));

        setMaxValue(maxBoth);
      }

      setIsLoading(false);

      // Ends
      // ------------------------------------------------------- MAIN ENDS HERE
    };

    fetchData();

    return () => {
      setSpecificYield('');
      setSoilType('NA');
      setSoilDepth(0);
      setTotalSoilMoisture('');
      setRunoffTillNow('');

      setRunoffTcm(0);
      setTotalSurfaceWaterStorage(0);

      setGroundwaterLevelTcm(0);
      setTotalCattleWaterConsumption(0);
      setTotalHospitalWaterConsumption(0);
      setTotalHumanWaterConsumption(0);
      setTotalIndustryWaterConsumption(0);
      setTotalPoultryWaterConsumption(0);
      setTotalSchoolWaterConsumption(0);
      setTotalSheepWaterConsumption(0);
      setHumanPopulation(0);
      setSheepPopulation(0);
      setPoultryPopulation(0);
      setCattlePopulation(0);
      setHospitalPopulation(0);
      setSchoolPopulation(0);
      setIndustryPopulation(0);
      setGroundWaterStage(0);
      setCurrentMonthData([]);
      setData([]);
      setCurrentGWLevel(0);
      setPreviousGWLevel(0);
      setMaxValue(0);
      setPopulationData([]);
      setTotalPopulation(0);
      setIsLoading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, pageHead]);

  /**
   *
   * @param {*} id - id of the alert bar
   * @description
   * This function is responsible for the animation of the alert bars
   */
  const showContent = (id) => {
    if (id === 'analyticsAlertBarGroundwater') {
      $('#analyticsAlertBarGroundwater').hide();
      $('#analyticsAlertBarSoilMoisture').show(400);
      $('#analyticsAlertBarSurfacewater').show(400);
      $('#analyticsAlertBarEvapotranspiration').show(400);
      $('#analyticsAlertBarRunoffGenerated').show(400);
      $('#analyticsAlertBarConsumption').show(400);

      $('.groundAlertContent').show(400);
      $('.soilMoistureContent').hide();
      $('.surfaceAlertContent').hide();
      $('.waterConsumptionContent').hide();
      $('.runoffGenerated').hide();
      $('.consumptionAlertContent').hide();
    } else if (id === 'analyticsAlertBarSoilMoisture') {
      $('#analyticsAlertBarGroundwater').show(400);
      $('#analyticsAlertBarSoilMoisture').hide();
      $('#analyticsAlertBarSurfacewater').show(400);
      $('#analyticsAlertBarEvapotranspiration').show(400);
      $('#analyticsAlertBarRunoffGenerated').show(400);
      $('#analyticsAlertBarConsumption').show(400);

      $('.groundAlertContent').hide();
      $('.soilMoistureContent').show(400);
      $('.surfaceAlertContent').hide();
      $('.waterConsumptionContent').hide();
      $('.runoffGenerated').hide();
      $('.consumptionAlertContent').hide();
    } else if (id === 'analyticsAlertBarSurfacewater') {
      $('#analyticsAlertBarGroundwater').show(400);
      $('#analyticsAlertBarSoilMoisture').show(400);
      $('#analyticsAlertBarSurfacewater').hide();
      $('#analyticsAlertBarEvapotranspiration').show(400);
      $('#analyticsAlertBarRunoffGenerated').show(400);
      $('#analyticsAlertBarConsumption').show(400);

      $('.groundAlertContent').hide();
      $('.soilMoistureContent').hide();
      $('.surfaceAlertContent').show(400);
      $('.waterConsumptionContent').hide();
      $('.runoffGenerated').hide();
      $('.consumptionAlertContent').hide();
    } else if (id === 'analyticsAlertBarEvapotranspiration') {
      $('#analyticsAlertBarGroundwater').show(400);
      $('#analyticsAlertBarSoilMoisture').show(400);
      $('#analyticsAlertBarSurfacewater').show(400);
      $('#analyticsAlertBarEvapotranspiration').hide();
      $('#analyticsAlertBarRunoffGenerated').show(400);
      $('#analyticsAlertBarConsumption').show(400);

      $('.groundAlertContent').hide();
      $('.soilMoistureContent').hide();
      $('.surfaceAlertContent').hide();
      $('.waterConsumptionContent').show(400);
      $('.runoffGenerated').hide();
      $('.consumptionAlertContent').hide();
    } else if (id === 'analyticsAlertBarRunoffGenerated') {
      $('#analyticsAlertBarGroundwater').show(400);
      $('#analyticsAlertBarSoilMoisture').show(400);
      $('#analyticsAlertBarSurfacewater').show(400);
      $('#analyticsAlertBarEvapotranspiration').show(400);
      $('#analyticsAlertBarRunoffGenerated').hide();
      $('#analyticsAlertBarConsumption').show(400);

      $('.groundAlertContent').hide();
      $('.soilMoistureContent').hide();
      $('.surfaceAlertContent').hide();
      $('.waterConsumptionContent').hide();
      $('.runoffGenerated').show(400);
      $('.consumptionAlertContent').hide();
    } else if (id === 'analyticsAlertBarConsumption') {
      $('#analyticsAlertBarGroundwater').show(400);
      $('#analyticsAlertBarSoilMoisture').show(400);
      $('#analyticsAlertBarSurfacewater').show(400);
      $('#analyticsAlertBarEvapotranspiration').show(400);
      $('#analyticsAlertBarRunoffGenerated').show(400);
      $('#analyticsAlertBarConsumption').hide();

      $('.groundAlertContent').hide();
      $('.soilMoistureContent').hide();
      $('.surfaceAlertContent').hide();
      $('.waterConsumptionContent').hide();
      $('.runoffGenerated').hide();
      $('.consumptionAlertContent').show(400);
    }
  };

  return (
    <div className={Styles.analyticsAlert}>
      <div
        className={Styles['new-header-values']}
        style={{ fontWeight: 'bold' }}
      >
        {translator[language]['Water Balance']}
      </div>

      <div className="analyticsAlertBars">
        <AlertHeader
          id={'analyticsAlertBarGroundwater'}
          icon={wellIcon}
          headKey={
            translator[language]['Total'] +
            ' ' +
            translator[language]['Groundwater'] +
            ':'
          }
          headValue={roundOff(groundwaterLevelTcm)}
          showContent={showContent}
        />
        <GroundwaterAlert
          totalGroundWater={roundOff(groundwaterLevelTcm)}
          groundWaterStage={groundWaterStage}
          specific_yield={specific_yield}
          villageArea={villageArea}
        />

        <AlertHeader
          id={'analyticsAlertBarSoilMoisture'}
          icon={soilMoistureIcon}
          headKey={translator[language]['Total Soil Moisture']}
          headValue={roundOff(total_soil_moisture)}
          showContent={showContent}
        />
        <SoilMoisture
          soil_type={soil_type}
          soil_depth={soil_depth}
          total_soil_moisture={total_soil_moisture}
        />

        <AlertHeader
          id={'analyticsAlertBarSurfacewater'}
          icon={surfacewaterIcon}
          headKey={
            translator[language]['Total'] +
            ' ' +
            translator[language]['Surfacewater'] +
            ':'
          }
          headValue={roundOff(totalSurfaceWaterStorage)}
          showContent={showContent}
        />
        <SurfacewaterAlert
          totalSurfaceWater={roundOff(totalSurfaceWaterStorage)}
        />

        <div
          className={Styles['new-header-values']}
          style={{
            marginTop: '12px',
            marginBottom: '4px',
            fontWeight: 'bold',
          }}
        >
          {translator[language]['Water Consumed']}
        </div>

        <AlertHeader
          id={'analyticsAlertBarEvapotranspiration'}
          icon={evapotranspirationIcon}
          headKey={
            translator[language]['Evapotranspiration from various land types'] +
            ':'
          }
          headValue={
            currentMonthData.cultivation_water_lost_tcm +
            currentMonthData.barren_water_lost_tcm +
            currentMonthData.forest_water_lost_tcm +
            currentMonthData.habitation_water_lost_tcm +
            currentMonthData.water_body_lost_tcm
          }
          showContent={showContent}
        />

        <Evapotranspiration
          isLoading={isLoading}
          // Values per month -> vegetative,other land types
          data={data}
          previousGWLevel={previousGWLevel}
          currentGWLevel={currentGWLevel}
          currentMonthData={currentMonthData}
          // TEMP
          totalVegetation={
            currentMonthData.cultivation_water_lost_tcm +
            currentMonthData.barren_water_lost_tcm +
            currentMonthData.forest_water_lost_tcm +
            currentMonthData.habitation_water_lost_tcm +
            currentMonthData.water_body_lost_tcm
          }
          fromVegetation={currentMonthData.cultivation_water_lost_tcm}
          fromOtherLandTypes={
            currentMonthData.barren_water_lost_tcm +
            currentMonthData.forest_water_lost_tcm +
            currentMonthData.habitation_water_lost_tcm +
            currentMonthData.water_body_lost_tcm
          }
          maxValue={maxValue}
        />

        <AlertHeader
          id={'analyticsAlertBarRunoffGenerated'}
          icon={runoffGeneratedIcon}
          headKey={translator[language]['Runoff Generated'] + ':'}
          headValue={roundOff(runoffTcm)}
          showContent={showContent}
        />
        <RunoffGenerated
          runoffTcm={runoffTcm}
          totalSurfaceWater={roundOff(totalSurfaceWaterStorage)}
          runoffTillNow={runoffTillNow}
        />

        <AlertHeader
          id={'analyticsAlertBarConsumption'}
          icon={domesticIcon}
          headKey={
            translator[language]['Total'] +
            ' ' +
            translator[language]['Domestic'] +
            '/' +
            translator[language]['Industrial'] +
            ' ' +
            translator[language]['Consumption'] +
            ':'
          }
          headValue={roundOff(
            totalCattleWaterConsumption +
              totalHospitalWaterConsumption +
              totalHumanWaterConsumption +
              totalIndustryWaterConsumption +
              totalPoultryWaterConsumption +
              totalSchoolWaterConsumption +
              totalSheepWaterConsumption,
          )}
          showContent={showContent}
        />
        {/* consumption alert */}
        <ConsumptionAlert
          isLoading={isLoading}
          totalCattleWaterConsumption={totalCattleWaterConsumption}
          totalHospitalWaterConsumption={totalHospitalWaterConsumption}
          totalHumanWaterConsumption={totalHumanWaterConsumption}
          totalIndustryWaterConsumption={totalIndustryWaterConsumption}
          totalPoultryWaterConsumption={totalPoultryWaterConsumption}
          totalSchoolWaterConsumption={totalSchoolWaterConsumption}
          totalSheepWaterConsumption={totalSheepWaterConsumption}
          humanPopulation={humanPopulation}
          sheepPopulation={sheepPopulation}
          poultryPopulation={poultryPopulation}
          cattlePopulation={cattlePopulation}
          hospitalPopulation={hospitalPopulation}
          schoolPopulation={schoolPopulation}
          industryPopulation={industryPopulation}
          populationData={populationData}
          totalPopulation={totalPopulation}
        />
      </div>
    </div>
  );
};

export default Alert;
