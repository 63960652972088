import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Styles from './Message.module.css';

/**
 *
 * @component Message
 * @description message page
 */

const Message = (props) => {
  const { unmount } = props;
  useEffect(() => {
    unmount(true);
  }, [unmount]);

  /**
   *
   * @description call the prop method unmount which sets
   * the state unmountNS to true and it unmounts Navbar and Sidebar
   */
  return (
    <div className={Styles.container}>
        <div className="gif">
          <img src={props.gif} alt="gif_ing" />
        </div>
        <div className="content">
          <h1 className="main-heading">{props.head}</h1>
          <p className="errorP">{props.description}</p>
          {props.showHome ? (
            <Link
            to={{
              pathname: '/',
              state: { unmountNS: false },
            }}
            className={Styles.errorButton}
            >
              Back to home <i class="far fa-hand-point-right"></i>
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
  );
};
export default Message;