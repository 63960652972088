import { useEffect, useState } from 'react';
import getUnitValue from '../getUnitValueFunction';

/* This is a custom hook that returns the converted array values according to the 
   selectedUnit, because we need to change the data array for the recharts in
   order to dynamically change the Y axis domain values.
*/
const useProcessedChartData = (
  staticChartData,
  selectedUnit,
  selectedVillageTankerValue,
  month,
  year,
  villageId,
  chartValues,
) => {
  const [processedData, setProcessedData] = useState([]);

  useEffect(() => {
    const processData = () => {
      const finalProcessedData = staticChartData.map((dataPoint) => {
        const processedValues = {};
        const finalChart = JSON.parse(chartValues);
        finalChart?.forEach((chartValue) => {
          processedValues[chartValue] = parseFloat(
            getUnitValue(dataPoint[chartValue], selectedUnit, selectedVillageTankerValue),
          );
        });

        return {
          ...dataPoint,
          ...processedValues,
        };
      });

      setProcessedData(finalProcessedData);
    };

    processData();
  }, [
    staticChartData,
    selectedUnit,
    selectedVillageTankerValue,
    month,
    year,
    villageId,
    chartValues,
  ]);

  return processedData;
};

export default useProcessedChartData;
