import React, { useState, useEffect } from 'react';
import Bar from './Bar';
import Village from './Village';
import { Get } from '../../../services/apiCall';
import translator from '../../../assets/json/translator.json';
import { GoIssueOpened } from 'react-icons/go';
import { useContext } from 'react';
import Styles from './index.module.css';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';
import { useParams } from 'react-router-dom';

/**
 *
 * @component VillageUnderTaluka
 * @states
 * villages --> An array that stores all the villages with their stats to show
 * activeVillageId --> Stores the id of the village which is being shown on this component
 * activeVillageName --> Stores the name of the village which is being shown on this component
 * @description
 * This component represents the bottom part of the Taluka page which has analytics overview
 * of all the villages that comes under that taluka
 */

const VillagesUnderTaluka = () => {
  const { month, year, pageHead } = useContext(TimelineContext);
  const { language, programId } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);
  const { clusterId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [villages, setVillages] = useState([]);
  const [activeVillageId, setActiveVillageId] = useState('');
  const [activeVillageName, setActiveVillageName] = useState('');

  // expects a parameter as current array of list of villages,
  // and basically checks if the user is changing the cluster from sidebar
  const filterVillage = (value) => {
    const finalValue = value?.filter((village) => {
      return village.name.includes(activeVillageName);
    });
    return finalValue;
  };

  useEffect(() => {
    setIsLoading(true);
    const initialFunction = async () => {
      const params = {
        year: year,
        month: month + 1,
        language: language,
        programId: programId,
        clusterId: clusterId,
      };
      const VillageList = await Get(
        '/info/villages-under-cluster',
        token,
        params,
      );

      if (VillageList.status === 'success') {
        if (VillageList.data && VillageList.data.length > 0) {
          // remove villages which are controlled
          VillageList.data = VillageList.data.filter(
            (village) => village.is_controlled === false,
          );

          let currentActiveVillageId =
            activeVillageId === '' ||
            filterVillage(VillageList.data).length === 0
              ? VillageList.data[0]?.id
              : activeVillageId;
          let currentActiveVillageName =
            activeVillageName === '' ||
            filterVillage(VillageList.data).length === 0
              ? VillageList.data[0]?.name
              : activeVillageName;

          setVillages(VillageList.data);
          setActiveVillageId(currentActiveVillageId);
          setActiveVillageName(currentActiveVillageName);
          setIsLoading(false);
        }
      }
    };

    initialFunction();

    return () => {
      setVillages([]);
      setActiveVillageId('');
      setActiveVillageName('');
      setIsLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, pageHead, language]);

  /**
   *
   * @param {*} id - id of the village which we clicked on
   * @param {*} name - id of the village which we clicked on
   * @description
   * Changes the active village for which we have to show stats on this component
   */

  const handleBarCell = (id, name) => {
    setActiveVillageId(id);
    setActiveVillageName(name);
  };

  return (
    <>
      <div className={Styles.talukaPageTalukaHead}>
        {translator[language]['Villages under Cluster']}
      </div>
      <div className={Styles.villageTalukaStats}>
        {villages.length > 0 && !isLoading ? (
          <>
            <Bar
              villages={villages}
              activeVillageId={activeVillageId}
              activeVillageName={activeVillageName}
              isLoading={isLoading}
              handleBarCell={handleBarCell}
            />

            <Village
              activeVillageId={activeVillageId}
              activeVillageName={activeVillageName}
            />
          </>
        ) : (
          <div className={Styles.noVillage}>
            <div
              style={{
                fontSize: '0.8rem',
                display: 'flex',
                color: '#5b5e61',
                alignItems: 'center',
              }}
            >
              <GoIssueOpened className={Styles.issueIcon} />
              {translator[language]['No data available']}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default VillagesUnderTaluka;
