import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Label,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import roundOff from '../../../services/round';
import translator from '../../../assets/json/translator.json';
import Loader from '../../Loader/ring';
import { useContext } from 'react';
import { GeneralContext } from '../../../services/context/context';
import Styles from './ConsumptionAlert.module.css';
import getUnitValue from '../../../services/getUnitValueFunction';

function CustomLabel({
  viewBox,
  value1,
  selectedUnit,
  selectedVillageTankerValue,
}) {
  const { cx, cy } = viewBox;
  return (
    <text
      x={cx}
      y={cy}
      fill="#3d405c"
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
    >
      <tspan alignmentBaseline="middle" fontSize="1.5vw" fill="#3883E6">
        {getUnitValue(
          roundOff(value1),
          selectedUnit,
          selectedVillageTankerValue,
        )}
      </tspan>
    </text>
  );
}
/**
 *
 * @component ConsumptionAlert
 * @description
 * This component represents the expanded version of the AlertHeader on the Analytic Overview
 * page and it contains an overview of the Consumption data for a particular village
 */

const ConsumptionAlert = (props) => {
  const { language, selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);

  const customDots = (color) => {
    return {
      height: '8px',
      width: '8px',
      backgroundColor: color,
      borderRadius: '50%',
      display: 'inline-block',
    };
  };
  const total_consumption =
    props.totalCattleWaterConsumption +
    props.totalHospitalWaterConsumption +
    props.totalHumanWaterConsumption +
    props.totalIndustryWaterConsumption +
    props.totalPoultryWaterConsumption +
    props.totalSchoolWaterConsumption +
    props.totalSheepWaterConsumption;

  return (
    <div
      className={`${Styles.consumptionAlertContent} consumptionAlertContent`}
    >
      <div className={Styles.consumptionAlertContentKey}>
        {translator[language]['Total']} {translator[language]['Domestic']}
        {'/'}
        {translator[language]['Industrial']}{' '}
        {translator[language]['Consumption']}
      </div>
      <div className={Styles.consumptionAlertContentValue}>
        {getUnitValue(
          roundOff(total_consumption),
          selectedUnit,
          selectedVillageTankerValue,
        ) +
          ' ' +
          translator[language][selectedUnit]}
      </div>

      {/* adding  */}
      <div className={Styles.consumptionAlertContentSatsLeftMobile}>
        <div className={Styles.consumptionAlertContentSatsLeftBox}>
          <div>
            {translator[language]['Water']}
            <br /> {translator[language]['Quality']}
          </div>
        </div>
      </div>
      {/* adding  */}

      <div className={Styles.consumptionAlertContentSats}>
        <div className={Styles.consumptionAlertContentSatsLeft}>
          <div className={Styles.consumptionAlertContentSatsLeftBox}>
            <div>
              {translator[language]['Water']}
              <br /> {translator[language]['Quality']}
            </div>
          </div>
          <button className={Styles.consumptionAlertButton}>
            {translator[language]['Full report']}
            {' >'}
          </button>
        </div>
        <div className={Styles.consumptionAlertContentSatsMiddle}>
          {!props.isLoading ? (
            <ResponsiveContainer
              className={Styles.consumptionAlertContentSatsMiddleContainer}
              width="95%"
              height="95%"
            >
              <PieChart
                width={100}
                height={90}
                className={Styles.consumptionAlertContentSatsChart}
              >
                <Pie
                  data={[
                    {
                      name: translator[language]['Humans'],
                      value:
                        props.totalHumanWaterConsumption >= 0 &&
                        total_consumption > 0
                          ? (props.totalHumanWaterConsumption * 100) /
                            total_consumption
                          : 0,
                    },
                    {
                      name: translator[language]['Cattles'],
                      value:
                        props.totalCattleWaterConsumption >= 0 &&
                        total_consumption > 0
                          ? (props.totalCattleWaterConsumption * 100) /
                            total_consumption
                          : 0,
                    },
                    {
                      name: translator[language]['Sheeps'],
                      value:
                        props.totalSheepWaterConsumption >= 0 &&
                        total_consumption > 0
                          ? (props.totalSheepWaterConsumption * 100) /
                            total_consumption
                          : 0,
                    },
                    {
                      name: translator[language]['Poultries'],
                      value:
                        props.totalPoultryWaterConsumption >= 0 &&
                        total_consumption > 0
                          ? (props.totalPoultryWaterConsumption * 100) /
                            total_consumption
                          : 0,
                    },
                    {
                      name: translator[language]['Industries'],
                      value:
                        props.totalIndustryWaterConsumption >= 0 &&
                        total_consumption > 0
                          ? (props.totalIndustryWaterConsumption * 100) /
                            total_consumption
                          : 0,
                    },
                    {
                      name: translator[language]['Hospitals'],
                      value:
                        props.totalHospitalWaterConsumption >= 0 &&
                        total_consumption > 0
                          ? (props.totalHospitalWaterConsumption * 100) /
                            total_consumption
                          : 0,
                    },
                    {
                      name: translator[language]['Schools'],
                      value:
                        props.totalSchoolWaterConsumption >= 0 &&
                        total_consumption > 0
                          ? (props.totalSchoolWaterConsumption * 100) /
                            total_consumption
                          : 0,
                    },
                  ]}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  startAngle={90}
                  endAngle={450}
                  innerRadius="70%"
                  outerRadius="90%"
                >
                  <Cell key={`cell-1`} fill="#83b5f7" />
                  <Cell key={`cell-2`} fill="#0c69e3" />
                  <Cell key={`cell-3`} fill="#5776a2" />
                  <Cell key={`cell-4`} fill="#589cf7" />
                  <Cell key={`cell-5`} fill="#e1ebf7" />
                  <Cell key={`cell-6`} fill="#C9559F" />
                  <Cell key={`cell-7`} fill="#FF637D" />
                  <Label
                    width={30}
                    position="center"
                    content={
                      <CustomLabel
                        value1={roundOff(
                          props.totalCattleWaterConsumption +
                            props.totalHospitalWaterConsumption +
                            props.totalHumanWaterConsumption +
                            props.totalIndustryWaterConsumption +
                            props.totalPoultryWaterConsumption +
                            props.totalSchoolWaterConsumption +
                            props.totalSheepWaterConsumption,
                        )}
                        selectedUnit={selectedUnit}
                        selectedVillageTankerValue={selectedVillageTankerValue}
                      />
                    }
                  ></Label>
                </Pie>
                <Tooltip
                  formatter={(value) =>
                    new Intl.NumberFormat('en').format(roundOff(value)) + '%'
                  }
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <Loader
              style={{
                position: 'relative',
                top: '50%',
                left: '50%',
              }}
            />
          )}
        </div>
        <div className={Styles.consumptionAlertContentSatsRight}>
          <div>
            <div>
              <span style={customDots('#83b5f7')} />{' '}
              {translator[language]['Humans']} ({translator[language]['Pop']}:{' '}
              {props.humanPopulation})
            </div>
            <div>
              <span style={customDots('#0c69e3')} />{' '}
              {translator[language]['Cattles']} ({translator[language]['Pop']}:{' '}
              {props.cattlePopulation})
            </div>
            <div>
              <span style={customDots('#5776a2')} />{' '}
              {translator[language]['Sheeps']} ({translator[language]['Pop']}:{' '}
              {props.sheepPopulation})
            </div>
            <div>
              <span style={customDots('#589cf7')} />{' '}
              {translator[language]['Poultries']} ({translator[language]['Pop']}
              : {props.poultryPopulation})
            </div>
          </div>
          <div>
            <div>
              <span style={customDots('#e1ebf7')} />{' '}
              {translator[language]['Industries']} (
              {translator[language]['Pop']}: {props.industryPopulation})
            </div>
            <div>
              <span style={customDots('#FF637D')} />{' '}
              {translator[language]['Schools']} ({translator[language]['Pop']}:{' '}
              {props.schoolPopulation})
            </div>
            <div>
              <span style={customDots('#C9559F')} />{' '}
              {translator[language]['Hospitals']} ({translator[language]['Pop']}
              : {props.hospitalPopulation})
            </div>
          </div>
        </div>
      </div>

      <div className={Styles.mobileConsumptionAlertParentDiv}>
        <button
          className={`${Styles.consumptionAlertButton} ${Styles.mobileConsumptionAlertButton}`}
        >
          {translator[language]['Full report']}
          {' >'}
        </button>
      </div>
    </div>
  );
};
export default ConsumptionAlert;
