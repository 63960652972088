import { useEffect, useState } from 'react';
import LandUseMap from '../components/Analytics/LandUse/Map';
import LandUseChart from '../components/Analytics/LandUse/Chart';
import LandUseTable from '../components/Analytics/LandUse/Table';
// import Download from '../services/download';
import { Get } from '../services/apiCall';
// import "../css/LandUse.css";
import { Link, useParams } from 'react-router-dom';
import translator from '../assets/json/translator.json';
import Mixpanel from '../services/mixpanel.service';
import { useContext } from 'react';
import Styles from './AnalyticsLandUse.module.css';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../services/context/context';

/**
 *
 * @component LandUse
 * @description
 * This component represents the LandUse stats page for the given village
 */
const LandUse = () => {
  const { villageId, clusterId } = useParams();
  const { month, year, showSeason, handleSeason, handlePageHead } =
    useContext(TimelineContext);
  const { unmount, unmountNS, language, programId } =
    useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  // states
  const [isLoading, setIsLoading] = useState(true);
  const [barrenData, setBarrenData] = useState({
    barrenArea: 0,
    barrenWaterLostTcm: 0,
  });
  const [cultivationData, setCultivationData] = useState({
    cultivationArea: 0,
    cultivationWaterLostTcm: 0,
  });
  const [forestData, setForestData] = useState({
    forestArea: 0,
    forestWaterLostTcm: 0,
  });
  const [habitatData, setHabitatData] = useState({
    habitationArea: 0,
    habitationWaterLostTcm: 0,
  });
  const [surfaceWaterArea, setSurfaceWaterArea] = useState(0);
  const [waterBodyLostTcm, setWaterBodyLostTcm] = useState(0);
  const [crops, setCrops] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    Mixpanel.time_event('AnalyticsLandusePage');
  }, []);

  useEffect(() => {
    if (unmountNS) unmount(false);
    if (showSeason) handleSeason(false);

    const initialFunction = async () => {
      setIsLoading(true);
      const params = {
        villageId: villageId,
        year: year,
        month: month + 1,
        language: language,
        programId: programId,
        clusterId: clusterId,
      };

      try {
        const response = await Get('/analytics/landuse', token, params);
        const payload = response.data;

        if (payload && payload.VillageMonthlyStats1.length > 0) {
          const data = payload.VillageMonthlyStats1[0];
          setBarrenData({
            barrenArea: data.barren_area,
            barrenWaterLostTcm: data.barren_water_lost_tcm,
          });
          setCultivationData({
            cultivationArea: data.cultivation_area,
            cultivationWaterLostTcm: data.cultivation_water_lost_tcm,
          });
          setForestData({
            forestArea: data.forest_area,
            forestWaterLostTcm: data.forest_water_lost_tcm,
          });
          setHabitatData({
            habitationArea: data.habitation_area,
            habitationWaterLostTcm: data.habitation_water_lost_tcm,
          });
          setSurfaceWaterArea(data.surface_water_area);
          setWaterBodyLostTcm(data.water_body_lost_tcm);
        }

        if (payload && payload.VillageCropMonthlyStats.length > 0) {
          const data = payload.VillageCropMonthlyStats;
          const cropArray = [];
          data.map((crop) => {
            let cropIdx = {
              cropName: crop.Crop && crop.Crop.name,
              cropArea: crop.crop_area,
              cropDripArea: crop.crop_drip_area,
              cropWaterRequirement: crop.crop_water_requirement,
              cropSeason: crop.season,
            };
            return cropArray.push(cropIdx);
          });
          setCrops(cropArray);
        }
      } catch (err) {
        console.error(err);
      }

      setIsLoading(false);
    };

    initialFunction();
    return () => {
      Mixpanel.track('AnalyticsLandusePage', {
        month: month + 1,
        year: year,
        villageId: villageId,
        programId: programId,
        clusterId: clusterId,
      });

      setBarrenData({
        barrenArea: 0,
        barrenWaterLostTcm: 0,
      });
      setCultivationData({
        cultivationArea: 0,
        cultivationWaterLostTcm: 0,
      });
      setForestData({
        forestArea: 0,
        forestWaterLostTcm: 0,
      });
      setHabitatData({
        habitationArea: 0,
        habitationWaterLostTcm: 0,
      });
      setSurfaceWaterArea(0);
      setWaterBodyLostTcm(0);
      setCrops([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [villageId, month, year]);

  const data = [
    {
      name: translator[language]['Bare Land'],
      Area: barrenData.barrenArea,
      'Water lost': barrenData.barrenWaterLostTcm,
    },
    {
      name: translator[language]['Habitation'],
      Area: habitatData.habitationArea,
      'Water lost': habitatData.habitationWaterLostTcm,
    },
    {
      name: translator[language]['Dense Vegetation'],
      Area: forestData.forestArea,
      'Water lost': forestData.forestWaterLostTcm,
    },
    {
      name: translator[language]['Water body'],
      Area: surfaceWaterArea,
      'Water lost': waterBodyLostTcm,
    },
    {
      name: translator[language]['Vegetation'],
      Area: cultivationData.cultivationArea,
      'Water lost': cultivationData.cultivationWaterLostTcm,
    },
  ];

  return (
    <>
      <div className={Styles.landuse}>
        <div className={Styles.landuseNavbar}>
          <div className={Styles.landuseNavbarBack}>
            <Link
              onClick={() => handlePageHead('Analytics Overview')}
              to={{
                pathname: `/analytics-overview/programId/${programId}/clusterId/${clusterId}/villageId/${villageId}`,
                state: {
                  selectedMonth: month,
                  selectedYear: year,
                  villageId: villageId,
                },
              }}
              className={Styles.landuseNavbarBack}
            >
              &#60;&nbsp;&nbsp;&nbsp;&nbsp;
              {translator[language]['Back to Analytics']}
            </Link>
          </div>
        </div>

        <div className={Styles.landuseContent}>
          <LandUseMap />
          <div className={Styles.landuseContentRight}>
            <LandUseChart
              barrenArea={barrenData.barrenArea}
              barrenWaterLostTcm={barrenData.barrenWaterLostTcm}
              vegetationArea={cultivationData.cultivationArea}
              vegetationWaterLostTcm={cultivationData.cultivationWaterLostTcm}
              denseVegetationArea={forestData.forestArea}
              denseVegetationWaterLostTcm={forestData.forestWaterLostTcm}
              habitationArea={habitatData.habitationArea}
              habitationWaterLostTcm={habitatData.habitationWaterLostTcm}
              surfacewaterArea={surfaceWaterArea}
              surfacewaterLostTcm={waterBodyLostTcm}
              crops={crops}
              data={data}
            />
            <div className={Styles.landuseContentRightBottom}>
              <LandUseTable crops={crops} isLoading={isLoading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandUse;
