import React, { useState, useEffect } from 'react';
import translator from '../assets/json/translator.json';
import { styled as stt } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import GenericPlanNav from '../components/Plan/GenericPlanNav';
import AdvisoryCropDiversification from '../components/Plan/AdvisoryCropDiversification';
import { Get } from '../services/apiCall';
import LoginLoader from '../components/Loader/climb';
import roundOff from '../services/round';
import { useContext } from 'react';
import { AuthContext, GeneralContext } from '../services/context/context';
import { useParams } from 'react-router-dom';
import Styles from './SavedScenarioCropDiversification.module.css';
import getUnitValue from '../services/getUnitValueFunction';
import QMarkHover from '../assets/images/QMarkHover.svg';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const StyledTableCell = stt(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#DBDBDB',
    padding: '4px 16px 4px 16px',
    color: '#000000',

    '@media screen and (max-width:1250px)': {
      fontSize: 11,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '8px 16px 8px 16px',

    '@media screen and (max-width:1250px)': {
      fontSize: 12,
    },
  },
}));

const StyledTableRow = stt(TableRow)(({ theme, isSecondTable }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    // backgroundColor: '#B6CFF0',
    backgroundColor: isSecondTable ? '#f3c2c2' : '#B6CFF0',
    fontWeight: 'bold',

    '@media screen and (max-width:1250px)': {
      fontSize: 11,
    },
  },
}));

const SavedScenarioCropDiversification = (props) => {
  const { villageId, clusterId } = useParams();
  const {
    programId,
    language,
    selectedUnit,
    selectedVillageTankerValue,
    villageArea,
  } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [
    previewAdvisoryCropDiversification,
    setPreviewAdvisoryCropDiversification,
  ] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [scenarioData, setScenarioData] = useState([]);

  const [ownerDetails, setOwnerDetails] = useState('');

  // CROP DATA (Crop Diversification)
  const [actualCropData, setActualCropData] = useState([]);
  const [deletedCropData, setDeletedCropData] = useState([]);
  // CROP DATA (Crop Diversification)
  const [ownerData, setOwnerData] = useState([]);
  const [ownerName, setOwnerName] = useState('');

  const getScenarioById = async () => {
    const getData = await Get(
      `/simulation/scenario?id=${props.scenarioId}`,
      token,
    );
    if (getData.status === 'success') {
      setOwnerDetails(getData.data?.creator);

      setScenarioData(getData.data.data);
      setOwnerData(getData.data);

      setActualCropData(getData.data.data.cropDiversificationCrops);
      setDeletedCropData(getData.data.data.getDeletedCropList);

      setIsLoading(false);
    }

    const emailId = getData.data.creator.id;
    // const role = getData.data.creator.role;

    const role =
      getData.data.creator.role === 'cluster_coordinator'
        ? 'clusterCoordinator'
        : getData.data.creator.role === 'admin'
        ? 'admin'
        : getData.data.creator.role === 'field_facilitator'
        ? 'fieldFacilitator'
        : null;

    const getNameOfScenarioUser = await Get(
      `/info/user/search?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&role=${role}&term=${emailId}`,
      token,
    );

    if (getNameOfScenarioUser.status === 'success') {
      setOwnerName(getNameOfScenarioUser.data[0].name);
    }
  };

  useEffect(() => {
    getScenarioById(props.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
          }}
        >
          <LoginLoader />
        </div>
      ) : (
        <>
          {previewAdvisoryCropDiversification ? (
            <AdvisoryCropDiversification
              setPreviewAdvisoryCropDiversification={
                setPreviewAdvisoryCropDiversification
              }
              setCurrentCrop={props.setCurrentCrop}
              scenarioId={props.scenarioId}
              season={props.season}
              globalPublishedScenarioId={props.globalPublishedScenarioId}
              checkPlanIsOpen={props.checkPlanIsOpen}
              ownerDetails={ownerDetails}
              ownerData={ownerData}
            />
          ) : (
            <div className={Styles['savedScenarioCropDiversification-wrapper']}>
              <GenericPlanNav
                setCurrentCrop={props.setCurrentCrop}
                setRenderCropDiversificationSummary={
                  props.setRenderCropDiversificationSummary
                }
                setPreviewAdvisoryCropDiversification={
                  setPreviewAdvisoryCropDiversification
                }
                isFromCropDiversification={true}
                ownerData={ownerData}
                scenarioId={props.scenarioId}
                callBaseConditionForEdit={props.callBaseConditionForEdit}
                season={props.season}
                isUpdateModeActiveScenarioType={
                  props.isUpdateModeActiveScenarioType
                }
                ownerName={ownerName}
                checkPlanIsOpen={props.checkPlanIsOpen}
                // FILTER ARRAYS
                scenarios={props.scenarios}
                setScenarios={props.setScenarios}
                trackFilteredValues={props.trackFilteredValues}
                setTrackFilteredValues={props.setTrackFilteredValues}
                referAnotherArray={props.referAnotherArray}
                setReferAnotherArray={props.setReferAnotherArray}
                currentCountOfOwnPlansSaved={props.currentCountOfOwnPlansSaved}
                setCurrentCountOfOwnPlansSaved={
                  props.setCurrentCountOfOwnPlansSaved
                }
                duplicateArray={props.duplicateArray}
                setDuplicateArray={props.setDuplicateArray}
              />
              <div className={Styles['grid-container']}>
                <div className={`${Styles['item']} ${Styles['item1']}`}>
                  <div
                    style={{
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        height: '45%',
                      }}
                    >
                      <div
                        style={{
                          width: '50%',
                          background: '#D1D1D1',
                          padding: '18px 0 0 12px',
                          borderTopLeftRadius: '16px',
                        }}
                      >
                        <div
                          className={Styles['first-box-demand']}
                          style={{ marginBottom: '5px' }}
                        >
                          {translator[language]['Demand']}
                        </div>
                        <div
                          className={Styles['first-box-demand-value']}
                          style={{ fontWeight: 'bold' }}
                        >
                          {getUnitValue(
                            scenarioData.waterDemand,
                            selectedUnit,
                            selectedVillageTankerValue,
                          )}{' '}
                          {translator[language][selectedUnit]}
                        </div>
                      </div>
                      <div
                        style={{
                          width: '50%',
                          background: '#C1D8F7',
                          padding: '18px 0 0 12px',
                          borderTopRightRadius: '16px',
                        }}
                      >
                        <div
                          className={Styles['first-box-available']}
                          style={{ marginBottom: '5px' }}
                        >
                          {translator[language]['Available']}
                        </div>
                        <div
                          className={Styles['first-box-available-value']}
                          style={{ fontWeight: 'bold' }}
                        >
                          {getUnitValue(
                            scenarioData.waterAvailable.toFixed(2),
                            selectedUnit,
                            selectedVillageTankerValue,
                          )}{' '}
                          {translator[language][selectedUnit]}
                        </div>
                      </div>
                    </div>
                    {/* ENDS  */}

                    <div
                      style={{
                        marginTop: '',
                        height: '55%',
                        background:
                          scenarioData.waterBalance > 0
                            ? '#B1E9BA'
                            : 'rgba(216, 54, 54, 0.3)',
                        borderBottomLeftRadius: '16px',
                        borderBottomRightRadius: '16px',
                      }}
                    >
                      <div
                        className={Styles['first-box-balance']}
                        style={{
                          padding: '12px 0 0 12px',
                          fontWeight: '500',
                        }}
                      >
                        {translator[language]['Water Balance']}
                      </div>
                      <div
                        className={Styles['first-box-balance-text']}
                        style={{ padding: '0px 0 0 12px' }}
                      >
                        ({translator[language]['Availability - Demand']})
                      </div>

                      <div
                        className={Styles['first-box-balance-value']}
                        style={{
                          textAlign: 'center',
                          marginTop: '10px',

                          color:
                            scenarioData.waterBalance > 0
                              ? '#12CB30'
                              : 'rgb(216, 54, 54)',
                          fontWeight: 'bold',
                        }}
                      >
                        {getUnitValue(
                          scenarioData.waterBalance,
                          selectedUnit,
                          selectedVillageTankerValue,
                        )}{' '}
                        {translator[language][selectedUnit]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${Styles['item']} ${Styles['item2']}`}>
                  <div className={Styles.groundwaterLastText}>
                    {translator[language]['With the current situation']}
                    <br />
                    {translator[language]['Groundwater will last']}:
                  </div>
                  <div
                    className={Styles['months-remaining']}
                    style={{ fontWeight: 'bold' }}
                  >
                    {scenarioData.groundwaterLastDuration.toFixed(1)}{' '}
                    {translator[language]['Months']}
                  </div>
                </div>
                <div className={`${Styles['item']} ${Styles['item3']}`}>
                  <div
                    className={Styles.totalWaterSavedText}
                    style={{ marginBottom: '5px' }}
                  >
                    {translator[language]['Total Water Saved']}
                  </div>
                  <div
                    className={Styles['total-water-saved']}
                    style={{ fontWeight: 'bold' }}
                  >
                    {getUnitValue(
                      scenarioData.totalWaterSaved,
                      selectedUnit,
                      selectedVillageTankerValue,
                    )}{' '}
                    {translator[language][selectedUnit]}
                  </div>
                </div>
                <div className={`${Styles['item']} ${Styles['item4']}`}>
                  <div className={Styles['supply-advisory-box']}>
                    <div style={{ fontWeight: 'bold' }}>
                      {translator[language]['Crop Diversification']}
                    </div>

                    <div
                      className={Styles.tablesParentDiv}
                      style={{ display: 'flex' }}
                    >
                      <div className={Styles.table1CropDiv}>
                        <div
                          style={{ marginBottom: '30px', fontWeight: 'bold' }}
                        >
                          {translator[language]['Crop List']}
                          <br />
                        </div>

                        <div style={{ width: '', position: 'relative' }}>
                          <div
                            className={Styles['new-crops-here']}
                            style={{
                              position: 'absolute',
                              top: '-38px',
                              right: '20px',
                              fontWeight: 'lighter',

                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                height: '15px',
                                width: '15px',
                                backgroundColor: 'rgba(56, 131, 230, 1)',
                                marginRight: '5px',
                              }}
                            />
                            <p>{translator[language]['New Crops']}</p>
                          </div>
                          <TableContainer
                            component={Paper}
                            sx={{ maxHeight: '250px' }}
                          >
                            <Table
                              sx={{ minWidth: 200 }}
                              aria-label="customized table"
                              stickyHeader
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    style={{
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {translator[language]['Crop Name']}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {translator[language]['Crop Area']}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      MI
                                      <img
                                        data-tooltip-id="my-tooltip-2"
                                        onClick={() => navigator.vibrate(100)}
                                        src={QMarkHover}
                                        height="12px"
                                        width="12px"
                                        alt="tooltip"
                                        style={{
                                          cursor: 'pointer',
                                          marginLeft: '3px',
                                          // marginTop: '3px',
                                        }}
                                      />
                                    </div>
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              {/* <div> */}
                              <TableBody>
                                {actualCropData.map((row) => (
                                  <StyledTableRow key={row.uuid}>
                                    <StyledTableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        color:
                                          row.hasOwnProperty(
                                            'isNewCropAddedInArray',
                                          ) &&
                                          row.isNewCropAddedInArray === true
                                            ? '#3883E6'
                                            : 'unset',
                                      }}
                                    >
                                      {row.name}
                                      {/* {translator[language][row.name]} */}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      style={{
                                        color:
                                          row.hasOwnProperty(
                                            'isNewCropAddedInArray',
                                          ) &&
                                          row.isNewCropAddedInArray === true
                                            ? '#3883E6'
                                            : 'unset',
                                      }}
                                    >
                                      {row.cropDripArea?.toFixed(2)}
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="center"
                                      style={{
                                        color:
                                          row.hasOwnProperty(
                                            'isNewCropAddedInArray',
                                          ) &&
                                          row.isNewCropAddedInArray === true
                                            ? '#3883E6'
                                            : 'unset',
                                      }}
                                    >
                                      {row.dripOnCrop?.toFixed(2)}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}

                                <StyledTableRow
                                  style={{
                                    position: 'sticky',
                                    bottom: 0,
                                    top: 0,
                                  }}
                                >
                                  <StyledTableCell component="th" scope="row">
                                    {actualCropData.length}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                  >
                                    {actualCropData
                                      .reduce((initial, vals) => {
                                        return initial + vals.cropDripArea;
                                      }, 0)
                                      .toFixed(2)}{' '}
                                    {translator[language]['Ha']}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                  >
                                    {actualCropData
                                      .reduce((initial, vals) => {
                                        return initial + vals.dripOnCrop;
                                      }, 0)
                                      .toFixed(2)}{' '}
                                    {translator[language]['Ha']}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>

                      {/* REPEAT STARTS/ */}

                      <div className={Styles.table2CropDiv}>
                        <div
                          style={{ marginBottom: '30px', fontWeight: 'bold' }}
                        >
                          {translator[language]['Crops Removed']}

                          <br />
                        </div>

                        <div style={{ width: '' }}>
                          <TableContainer
                            component={Paper}
                            sx={{ maxHeight: '250px' }}
                          >
                            <Table
                              sx={{ minWidth: 200 }}
                              aria-label="customized table"
                              stickyHeader
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    style={{
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {translator[language]['Crop Name']}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {translator[language]['Crop Area']}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      MI
                                      <img
                                        data-tooltip-id="my-tooltip-2"
                                        onClick={() => navigator.vibrate(100)}
                                        src={QMarkHover}
                                        height="12px"
                                        width="12px"
                                        alt="tooltip"
                                        style={{
                                          cursor: 'pointer',
                                          marginLeft: '3px',
                                          // marginTop: '3px',
                                        }}
                                      />
                                    </div>
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              {/* <div> */}
                              <TableBody>
                                {deletedCropData.map((row) => (
                                  <StyledTableRow
                                    key={row.uuid}
                                    className={Styles['second-styletable']}
                                  >
                                    <StyledTableCell component="th" scope="row">
                                      {row.name}
                                    </StyledTableCell>

                                    <StyledTableCell align="center">
                                      {row.crop_area?.toFixed(1)}
                                    </StyledTableCell>

                                    <StyledTableCell align="center">
                                      {row.drip_area?.toFixed(1)}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                                <StyledTableRow
                                  style={{
                                    position: 'sticky',
                                    bottom: 0,
                                    top: 0,
                                  }}
                                  className={Styles['second-styletable']}
                                  isSecondTable={true}
                                >
                                  <StyledTableCell>
                                    {deletedCropData.length}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {deletedCropData
                                      .reduce((initial, vals) => {
                                        return initial + vals.crop_area;
                                      }, 0)
                                      .toFixed(1)}{' '}
                                    {translator[language]['Ha']}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {deletedCropData
                                      .reduce((initial, vals) => {
                                        return initial + vals.drip_area;
                                      }, 0)
                                      .toFixed(1)}{' '}
                                    {translator[language]['Ha']}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </TableBody>
                              {/* </div> */}
                            </Table>
                          </TableContainer>
                        </div>
                      </div>

                      {/* REPEAT ENDS/ */}
                    </div>

                    <div
                      className={Styles['total-cultivation-area']}
                      style={{ marginTop: '50px' }}
                    >
                      <p>
                        {translator[language]['Total cultivation area']}:{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          <span style={{ color: '#3883E6' }}>
                            {scenarioData.cropCultivationArea.toFixed(2)} &nbsp;
                            &nbsp;
                          </span>
                          /{roundOff(villageArea)} {translator[language]['Ha']}
                        </span>
                      </p>
                    </div>

                    <div style={{ display: 'flex' }}>
                      <p className={Styles.areaReducedText}>
                        {translator[language]['Area reduced under old crops']}
                        :&nbsp; &nbsp;{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          <span style={{ color: '#3883E6' }}>
                            {scenarioData.areaReducedUnderOldCrops}
                          </span>{' '}
                          {translator[language]['Ha']}
                        </span>
                      </p>
                      <p>
                        {translator[language]['New Crops Added']}: &nbsp;
                        <span style={{ fontWeight: 'bold', color: '#3883E6' }}>
                          {scenarioData.totalNewCrops}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <ReactTooltip
        id="my-tooltip-2"
        place="top"
        style={{
          background: 'black',
          color: 'white',
          fontSize: window.innerWidth < 768 ? '10px' : '12px',
        }}
        content={'Micro Irrigation'}
      />
    </>
  );
};

export default SavedScenarioCropDiversification;
