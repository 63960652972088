import React, { useContext, useEffect } from 'react';
import AnalyticsNavbar from '../components/Navbar/PageNav';
import WaterAvailability from '../components/Analytics/Overview/WaterAvailability';
import Rainfall from '../components/Analytics/Overview/Rainfall';
import Alert from '../components/Analytics/Overview/Alert';
import { useParams } from 'react-router-dom';
import Mixpanel from '../services/mixpanel.service';
import { GeneralContext, TimelineContext } from '../services/context/context';
import Styles from './AnalyticsOverview.module.css';

/**
 *
 * @component AnalyticsOverview
 * @description
 * This component represents Analytics overview page of the village which has analytics
 * of water availability graph, rainfall graph and data overview of groundwater, surfacewater
 * and landuse
 */

const AnalyticsOverview = (props) => {
  const { villageId, clusterId } = useParams();
  const { month, year, showSeason, handleSeason, pageHead } =
    useContext(TimelineContext);
  const { unmount, unmountNS, programId } = useContext(GeneralContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    Mixpanel.time_event('AnalyticsOverviewPage');
  }, []);

  useEffect(() => {
    if (unmountNS) unmount(false);
    if (showSeason) handleSeason(false);
    return () => {
      Mixpanel.track('AnalyticsOverviewPage', {
        month: month + 1,
        year: year,
        programId: programId,
        clusterId: clusterId,
        villageId: villageId,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, pageHead, year]);

  return (
    <div className={Styles.analytics}>
      <AnalyticsNavbar {...props} active1={true} />

      <div className={Styles.analyticsOverviewContainer}>
        <div className={Styles.analyticsOverviewContainerLeft}>
          <WaterAvailability />
          <Rainfall />
        </div>
        <div className={Styles.analyticsOverviewContainerRight}>
          <Alert />
        </div>
      </div>
    </div>
  );
};

export default AnalyticsOverview;
