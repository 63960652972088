import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Label,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import WaterStructure from '../../../../../shared/enums/water-structure';

function CustomLabel({ viewBox, value }) {
  const { cx, cy } = viewBox;
  return (
    <text
      x={cx}
      y={cy}
      fill="#3d405c"
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
    >
      <tspan
        alignmentBaseline="middle"
        fontSize=".55rem"
        fill={'#000'}
        dy="-1.5em"
      >
        {'Total No.'}
      </tspan>
      <tspan
        x={cx}
        dy="1.2em"
        fontSize="1.1rem"
        fontWeight="bold"
        fill={'#000'}
      >
        {value}
      </tspan>
    </text>
  );
}

const SegmentChart = ({
  village_ponds,
  farm_ponds,
  percolation_tanks,
  check_dams,
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width="5vw" height="7vw">
        <Pie
          data={[
            {
              name: WaterStructure.VILLAGE_POND,
              value: Number(village_ponds),
            },
            {
              name: WaterStructure.FARM_POND,
              value: Number(farm_ponds),
            },
            {
              name: WaterStructure.PERCOLATION_TANK,
              value: Number(percolation_tanks),
            },
            {
              name: WaterStructure.CHECK_DAM,
              value: Number(check_dams),
            },
          ]}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={450}
          innerRadius="65%"
          outerRadius="85%"
        >
          <Cell key={`cell-1`} fill="#EFD07F" />
          <Cell key={`cell-2`} fill="#FFD86E" />
          <Cell key={`cell-3`} fill="#C8B277" />
          <Cell key={`cell-3`} fill="#F0BE39" />
          <Label
            width={30}
            position="center"
            content={
              <CustomLabel
                value={
                  Number(village_ponds) +
                  Number(farm_ponds) +
                  Number(percolation_tanks) +
                  Number(check_dams)
                }
                color="#86b7f7"
              />
            }
          ></Label>
        </Pie>
        <Tooltip wrapperStyle={{ fontSize: '10px' }} />
      </PieChart>
    </ResponsiveContainer>
  );
};
export default SegmentChart;
