import WaterLevelStackedBarChart from './WaterLevelStackedBarChart ';
import styles from './WaterStructure.module.css';

const WaterStructure = ({ data }) => {
  return (
    <div>
      <div className={styles.header}>{data?.type}</div>
      <div className={styles.content}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-evenly',
            marginTop: '5%',
          }}
        >
          <div>
            <div style={{ fontSize: '22px', fontWeight: 700 }}>
              {Number(data?.lat).toFixed(2)}
            </div>
            <div style={{ height: '5px' }} />
            <div style={{ textAlign: 'center', fontSize: '12px' }}>
              Latitude
            </div>
          </div>
          <div>
            <div style={{ fontSize: '22px', fontWeight: 700 }}>
              {Number(data?.lng).toFixed(2)}
            </div>
            <div style={{ height: '5px' }} />
            <div style={{ textAlign: 'center', fontSize: '12px' }}>
              Longitude
            </div>
          </div>
        </div>
        <hr className={styles.divider} />
        <div style={{ marginLeft: '10px' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%', margin: 'auto' }}>
              <div style={{ fontSize: '12px' }}>
                Total Capacity of this structure:
              </div>
              <div style={{ height: '8px' }} />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    height: '30px',
                    width: '10px',
                    background: '#FFFDFD',
                    marginRight: '5%',
                  }}
                />
                <div style={{ fontSize: '18px', fontWeight: 700 }}>
                  {Number(data?.capacity).toFixed(1)} TCM
                </div>
              </div>
              <div style={{ height: '16px' }} />
              <div style={{ fontSize: '12px' }}>
                {`Total volume of water present in all ${data?.type}s:`}
              </div>
              <div style={{ height: '8px' }} />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    height: '30px',
                    width: '10px',
                    background:
                      'linear-gradient(180deg, #F2BA37 0%, #EFDFAB 100%)',
                    marginRight: '5%',
                  }}
                />
                <div style={{ fontSize: '18px', fontWeight: 700 }}>
                  {Number(data?.total_storage).toFixed(1)} TCM
                </div>
              </div>
            </div>
            <div style={{ width: '50%', margin: 'auto' }}>
              <WaterLevelStackedBarChart
                type={data?.type}
                totalVolume={data?.total_storage}
                capacity={data?.capacity}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaterStructure;
