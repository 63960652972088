import translator from '../../assets/json/translator.json';
import { Link } from 'react-router-dom';
import { FormControl, Select, MenuItem } from '@mui/material';
import styledComponents from 'styled-components';
import Styles from './PageNav.module.css';
import Download from '../../services/download';
import ToolTipIcon from '../../assets/images/tooltipIcon.png';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../services/context/context';

// @description
// This is the Navbar which is shown on the Analytics | Plan | Advisory pages
// based on the props active1, active2, active3 active page in decided and style is applied

const PageNav = (props) => {
  const { clusterId, villageId } = useParams();
  const { handlePageHead } = useContext(TimelineContext);
  const { language, programId } = useContext(GeneralContext);
  const { accountType } = useContext(AuthContext);

  // const classes = useStyles();

  const handleChange = (event) => {
    props?.setCropListMode('Manual');
    switch (event.target.value) {
      case 'Demand Side':
        props.setCurrentCrop(event.target.value);
        break;

      case 'Supply Side':
        props.setCurrentCrop(event.target.value);
        break;

      case 'Irrigation Side':
        props.setCurrentCrop(event.target.value);
        break;

      case 'Crop Diversification':
        props.setCurrentCrop(event.target.value);
        break;

      case 'SavedScenarioSummary':
        props.setCurrentCrop(event.target.value);
        break;

      default:
        return {};
    }
  };

  return (
    <Wrapper>
      <div className={Styles.pageNavParentDiv} style={{ display: 'flex' }}>
        <div
          className={`${Styles.analyticsHeader} ${
            (window.location.pathname.startsWith('/plan') ||
              (window.location.pathname.startsWith('/advisory') &&
                props.NoAdvisoryAvailable)) &&
            Styles.analyticsFullWidthMobile
          }`}
        >
          <div className={Styles.analyticsNavbar}>
            <div>
              <Link
                onClick={() => handlePageHead('Analytics Overview')}
                to={`/analytics-overview/programId/${programId}/clusterId/${clusterId}/villageId/${villageId}`}
                className={props.active1 ? Styles.on : Styles.off}
              >
                {translator[language]['Analytics']}
              </Link>
            </div>
            <div className={Styles['plan-dropdown']}>
              <Link
                onClick={() => handlePageHead('Plan')}
                to={`/plan/programId/${programId}/clusterId/${clusterId}/villageId/${villageId}`}
                className={props.active2 ? Styles.on : Styles.off}
                // state={{ from: props.currentCrop }}
              >
                {accountType !== 'programManager'
                  ? translator[language]['Plan']
                  : 'Scenario'}
              </Link>

              {window.location.pathname.startsWith('/plan') &&
                props.isPlanPageOpen &&
                accountType !== 'programManager' && (
                  <FormControl sx={{}} size="small">
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center',
                        },
                      }}
                      sx={{ height: '17px', border: 'none!important' }}
                      size="small"
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={''}
                      onChange={(e) => handleChange(e)}
                    >
                      <MenuItem
                        value="Demand Side"
                        style={{
                          background:
                            props.currentCrop === 'Demand Side' ||
                            props.currentCrop === 'Supply Side'
                              ? 'rgba(0, 0, 0, 0.04'
                              : '',
                          minHeight: window.innerWidth < 768 && '22px',
                          fontSize: window.innerWidth < 768 && '12px',
                        }}
                      >
                        {translator[language]['Demand & Supply']}
                      </MenuItem>
                      <MenuItem
                        value="Irrigation Side"
                        style={{
                          background:
                            props.currentCrop === 'Irrigation Side'
                              ? 'rgba(0, 0, 0, 0.04'
                              : '',
                          minHeight: window.innerWidth < 768 && '22px',
                          fontSize: window.innerWidth < 768 && '12px',
                        }}
                      >
                        {translator[language]['Demand-Irrigation Cycle']}
                      </MenuItem>
                      <MenuItem
                        value="Crop Diversification"
                        style={{
                          background:
                            props.currentCrop === 'Crop Diversification'
                              ? 'rgba(0, 0, 0, 0.04'
                              : '',
                          minHeight: window.innerWidth < 768 && '22px',
                          fontSize: window.innerWidth < 768 && '12px',
                        }}
                      >
                        {translator[language]['Crop Diversification']}
                      </MenuItem>
                      <MenuItem
                        value="SavedScenarioSummary"
                        style={{
                          background:
                            props.currentCrop === 'SavedScenarioSummary'
                              ? 'rgba(0, 0, 0, 0.04'
                              : '',
                          minHeight: window.innerWidth < 768 && '22px',
                          fontSize: window.innerWidth < 768 && '12px',
                        }}
                      >
                        {translator[language]['Saved Scenarios']}
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
            </div>
            <div className={Styles.inactive}>
              <Link
                onClick={() => handlePageHead('Advisory')}
                to={`/advisory/programId/${programId}/clusterId/${clusterId}/villageId/${villageId}`}
                className={props.active3 ? Styles.on : Styles.off}
              >
                {translator[language]['Advisory']}
              </Link>
            </div>
            <div>
              <Link
                onClick={() => handlePageHead('Web Gis')}
                to={`/web-gis/programId/${programId}/clusterId/${clusterId}/villageId/${villageId}`}
                className={props.active4 ? Styles.on : Styles.off}
              >
                {translator[language]['Web Gis']}
              </Link>
            </div>
            <div className={Styles.inactive}>
              {translator[language]['Archives']}
            </div>
          </div>

          {/* INFO FOR ADVISORY PAGE  */}
          {window.location.pathname.startsWith('/advisory') &&
            !props.NoAdvisoryAvailable && (
              <div className={Styles.advisoryPublishedBy}>
                <img
                  data-tooltip-id="my-tooltip-1"
                  onClick={() => navigator.vibrate(100)}
                  src={ToolTipIcon}
                  className={Styles.tooltipIcon}
                  alt="tooltip"
                />
              </div>
            )}
          {/* INFO FOR ADVISORY PAGE  */}

          {!window.location.pathname.startsWith('/plan') &&
            !window.location.pathname.startsWith('/advisory') && (
              <div
                className={Styles.analyticsDownload}
                style={{ position: 'absolute', right: '0%' }}
              >
                <Download />
              </div>
            )}
        </div>

        {window.location.pathname.startsWith('/plan') &&
        props.isPlanPageOpen &&
        accountType !== 'programManager' ? (
          <div className={Styles['reset-and-save-buttons-wrapper']}>
            {props.cropListMode === 'Manual' && (
              <div
                className={`${Styles['reset-button']} ${Styles.resetsave}`}
                onClick={() =>
                  props.currentCrop === 'Demand Side' ||
                  props.currentCrop === 'Supply Side'
                    ? props.onReset()
                    : // : props.saveCropDivTemp()
                      props.onReset()
                }
              >
                <button>{translator[language]['Reset']}</button>
              </div>
            )}

            {props.savedScenarioSummary !== true ? (
              <div className={`${'save-button'} ${Styles.resetsave}`}>
                <button
                  style={{
                    background:
                      (props.currentCrop === 'Demand Side' ||
                        (props.currentCrop === 'Supply Side' &&
                          props.supplyCurrentRange > 0)) &&
                      !props.isRunoffAvailable &&
                      '#a4a4a4',
                    border:
                      (props.currentCrop === 'Demand Side' ||
                        (props.currentCrop === 'Supply Side' &&
                          props.supplyCurrentRange > 0)) &&
                      !props.isRunoffAvailable &&
                      '1px solid #a4a4a4',
                    opacity:
                      (props.currentCrop === 'Demand Side' ||
                        (props.currentCrop === 'Supply Side' &&
                          props.supplyCurrentRange > 0)) &&
                      !props.isRunoffAvailable &&
                      0.9,
                  }}
                  disabled={
                    (props.currentCrop === 'Demand Side' ||
                      (props.currentCrop === 'Supply Side' &&
                        props.supplyCurrentRange > 0)) &&
                    !props.isRunoffAvailable
                      ? true
                      : false
                  }
                  onClick={() =>
                    ((props.currentCrop === 'Demand Side' ||
                      props.currentCrop === 'Supply Side') &&
                      props.isUpdateModeActiveScenarioType) ===
                    'demand-and-supply'
                      ? props.updateScenario()
                      : props.currentCrop === 'Crop Diversification' &&
                        props.isUpdateModeActiveScenarioType ===
                          'crop-diversification'
                      ? props.updateScenario()
                      : props.currentCrop === 'Irrigation Side' &&
                        props.isUpdateModeActiveScenarioType ===
                          'irrigation-cycle'
                      ? props.updateScenario()
                      : props.setOpenSaveModal(true)
                  }
                >
                  {(props.currentCrop === 'Demand Side' ||
                    props.currentCrop === 'Supply Side') &&
                  props.isUpdateModeActiveScenarioType === 'demand-and-supply'
                    ? translator[language]['Update']
                    : props.currentCrop === 'Crop Diversification' &&
                      props.isUpdateModeActiveScenarioType ===
                        'crop-diversification'
                    ? translator[language]['Update']
                    : props.currentCrop === 'Irrigation Side' &&
                      props.isUpdateModeActiveScenarioType ===
                        'irrigation-cycle'
                    ? translator[language]['Update']
                    : translator[language]['Save']}
                </button>
              </div>
            ) : (
              accountType !== 'clusterCoordinator' ||
              (accountType !== 'villageCoordinator' && (
                <div className={`${'save-button'} ${Styles.resetsave}`}>
                  {/* <button>Access to Publish</button> */}
                </div>
              ))
            )}
          </div>
        ) : // USER IS A PROGRAM MANAGER (NO)

        null}
      </div>
      <ReactTooltip
        id="my-tooltip-1"
        place="bottom"
        style={{
          background: 'black',
          color: 'white',
          fontSize: window.innerWidth < 768 ? '10px' : '12px',
        }}
        content={
          <div>
            {translator[language]['Published']}-{' '}
            {props.ownerName ? props.ownerName : ''}
            <br />
          </div>
        }
      />
    </Wrapper>
  );
};

export default PageNav;

const Wrapper = styledComponents.div`

.MuiOutlinedInput-notchedOutline{
  border:none;
}

.MuiSvgIcon-root{
  color:#3883E6;
}
`;
