import React, { useState, useEffect, useContext } from 'react';
import ProgramStats from '../components/Home/ProgramStats/index';
import TalukaStats from '../components/Home/TalukasUnderProgram/index';
import { Get } from '../services/apiCall';
// import mixpanel from "mixpanel-browser";
import Mixpanel from '../services/mixpanel.service';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../services/context/context';
import HomeStyles from './Home.module.css';

/**
 *
 * @component Home
 * @states
 * isLoading --> If true show animation
 * totalArea --> Stores sum of area of villages that comes under that program
 * totalWaterSaved --> Stores the sum of water saved in each village that comes under that program
 * totalCropArea --> Stores the sum of crop area in each village that comes under that program
 * totalCropDripArea --> Stores the sum of crop's drip area in each village that comes under that program
 * totalWaterStructure --> Stores the sum of total water structure governed or found in each village that comes under that program
 * @description Program head's home page which contains analytics of that program head
 */

const Home = (props) => {
  const [totalArea, setTotalArea] = useState(0.0);
  const [totalWaterSaved, setTotalWaterSaved] = useState(0.0);
  const [totalCropArea, setTotalCropArea] = useState(0.0);
  const [totalCropDripArea, setTotalCropDripArea] = useState(0.0);
  const [totalWaterStructure, setTotalWaterStructure] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // const programId = props.programId;

  const { month, year, showSeason, handleSeason } = useContext(TimelineContext);
  const { unmount, unmountNS, language, programId } =
    useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  /**
   *
   * @description
   * Calls the "/analytics/program" api, does certain calculations and
   * store in the states
   */

  useEffect(() => {
    Mixpanel.time_event('HomePage');
  }, []);

  useEffect(() => {
    const initialFunction = async () => {
      setIsLoading(true);

      /**
       *
       * @reason
       * when ever this page is rendering we want month calendar on Navbar instead of
       * season calendar, therefore we call the prop function handleSeason(false) to
       * make the state showSeason false in App component
       */
      if (unmountNS) unmount(false);
      if (showSeason) handleSeason(false);
      /**
       *
       * @reason
       * when ever we render this page we want to show "Home" as heading on the navbar component
       * therefore we check if the prop pageHead which store heading of the
       * page is "Home" if it is not then we runs the prop function handleActiveVillage to make the
       * state pageHead in component App to be "Home", So that the heading in the navbar will be "Home"
       */

      const params = {
        year: year,
        month: month + 1,
        language: language,
        programId: programId,
      };
      const result = await Get('/analytics/program', token, params);

      if (
        result &&
        result.data &&
        result.data.ProgramVillageStats
        // && result.data.ProgramVillageStats.length > 0
      ) {
        let totalArea = 0.0;
        let totalWaterSaved = 0.0;
        let totalCropArea = 0.0;
        let totalCropDripArea = 0.0;
        let totalWaterStructure = 0;

        totalArea = result.data.ProgramVillageStats.reduce(
          (a, b) =>
            b.area ? parseFloat(a) + parseFloat(b.area) : parseFloat(a),
          0,
        );

        totalWaterSaved = result.data.ProgramVillageStats.reduce((a, b) => {
          return b.VillageWaterSaveds
            ? a + b.VillageWaterSaveds[0].water_saved_tcm
            : a;
        }, 0);

        if (
          result.data.ProgramVillageStats[0]?.VillageCropMonthlyStats &&
          result.data.ProgramVillageStats[0]?.VillageCropMonthlyStats.length > 0
        ) {
          var n = result.data.ProgramVillageStats.length;
          for (var i = 0; i < n; i++) {
            var m =
              result.data.ProgramVillageStats[i].VillageCropMonthlyStats.length;
            for (var j = 0; j < m; j++) {
              const crop_area =
                result.data.ProgramVillageStats[i].VillageCropMonthlyStats[j]
                  .crop_area;
              totalCropArea += parseFloat(crop_area);
              const crop_drip_area =
                result.data.ProgramVillageStats[i].VillageCropMonthlyStats[j]
                  .crop_drip_area;
              totalCropDripArea += parseFloat(crop_drip_area);
            }
          }
        }

        if (
          result.data.ProgramVillageStats[0]?.VillageMonthlyStats1.length > 0
        ) {
          n = result.data.ProgramVillageStats.length;
          for (i = 0; i < n; i++) {
            m = result.data.ProgramVillageStats[i].VillageMonthlyStats1.length;
            for (j = 0; j < m; j++) {
              const water_saved =
                result.data.ProgramVillageStats[i].VillageMonthlyStats1[j]
                  .water_saved_cummulative;
              totalWaterSaved += parseFloat(water_saved);
            }
          }
        }
        totalWaterStructure = result.data.WaterStructureMonitored
          ? result.data.WaterStructureMonitored
          : 0;

        setTotalArea(totalArea);
        setTotalWaterSaved(totalWaterSaved);
        setTotalCropArea(totalCropArea);
        setTotalCropDripArea(totalCropDripArea);
        setTotalWaterStructure(totalWaterStructure);
        setIsLoading(false);
      }
    };

    initialFunction();

    return () => {
      Mixpanel.track('HomePage', {
        month: month + 1,
        year: year,
        programId: programId,
      });

      setIsLoading(false);
      setTotalArea(0.0);
      setTotalWaterSaved(0.0);
      setTotalCropArea(0.0);
      setTotalCropDripArea(0.0);
      setTotalWaterStructure(0);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, programId]);

  return (
    <div className={HomeStyles.homePage}>
      <ProgramStats
        totalArea={totalArea}
        totalWaterSaved={totalWaterSaved}
        totalCropArea={totalCropArea}
        totalCropDripArea={totalCropDripArea}
        totalWaterStructure={totalWaterStructure}
        isLoading={isLoading}
      />

      <TalukaStats />
    </div>
  );
};
export default Home;
