import React from 'react';
import { useContext } from 'react';
import { AuthContext, GeneralContext } from '../../services/context/context';
import translator from '../../assets/json/translator.json';
import Styles from './Irrigation.module.css';

import roundOffTillOne from '../../services/roundTillOne';
import RsuiteBarIrrigation from './RsuiteBarIrrigation';
import { Get } from '../../services/apiCall';

const IrrigationSide = (props) => {
  const { language } = useContext(GeneralContext);

  const { accountType, token } = useContext(AuthContext);

  const setRecommendedFunctionCall = async () => {
    const isRecommendedAvailable = await Get(
      `/simulation/scenario?id=${props.recommendedScenarioId}`,
      token,
    );
    if (isRecommendedAvailable.status === 'success') {
      const currentCrops = isRecommendedAvailable.data.data.crops?.map(
        (crop) => {
          if (!crop?.hasOwnProperty('newIdealValue')) {
            return {
              ...crop,
              newIdealValue: Number(
                (crop?.per_ha_cwr * crop?.crop_area * 1000 -
                  crop?.per_ha_cwr *
                    (crop?.drip_area * crop?.drip_efficiency * 1000)) /
                  (crop?.hours_per_irrigation_cycle_per_hectare *
                    crop?.water_pumped_per_hour_in_litres *
                    0.001 *
                    crop?.crop_area),
              ),
            };
          }
          return crop;
        },
      );

      const irrigationFinalCropsData = currentCrops?.filter((crop) => {
        // Criteria for filtering
        const isIdealIrrigationCycleZero = crop?.newIdealValue === 0;
        const isActualIrrigationCycleZero = crop.irrigation_cycle === 0;
        const isIdealIrrigationCycleNaN = isNaN(crop?.newIdealValue);

        // Filtering logic
        return (
          !(isIdealIrrigationCycleZero && isActualIrrigationCycleZero) && // Condition 1
          // !(isIdealIrrigationCycleZero && !isActualIrrigationCycleZero) && // Condition 2
          // !(!isIdealIrrigationCycleZero && isActualIrrigationCycleZero) && // Condition 3
          !(isIdealIrrigationCycleNaN && isActualIrrigationCycleZero) && // Condition 4
          !(isIdealIrrigationCycleNaN && !isActualIrrigationCycleZero) // Condition 5
        );
      });

      props.setRecommendedCrops(irrigationFinalCropsData);
      // props.setRecommendedCrops(isRecommendedAvailable.data.data.crops);
      props.setRecommendedSupplyCurrentRange(
        isRecommendedAvailable.data.data.supplyCurrentRange,
      );
      props.setCropListMode('Recommended');
    } else {
      alert('No recommendation is available for this village!');
    }
  };

  return (
    <>
      <div className={Styles['third-item-top']}>
        <div className={Styles['curr-situation']}>
          <p>{translator[language][props.title]}</p>
        </div>

        {accountType !== 'admin' && (
          <div className={Styles['right-buttons']}>
            {props.currentCrop === 'Irrigation Side' ? (
              <>
                <div
                  className={`
                    ${Styles.btn1} ${
                    props.cropListMode === 'Manual'
                      ? Styles['active-crop-mode1']
                      : ''
                  }
                      `}
                  onClick={() => {
                    props.setCropListMode('Manual');
                  }}
                >
                  <p>{translator[language]['Manual']}</p>
                </div>
                <div
                  className={`
                    ${Styles.btn2} ${
                    props.cropListMode === 'Recommended'
                      ? Styles['active-crop-mode2']
                      : ''
                  }
                  `}
                  onClick={() => {
                    setRecommendedFunctionCall();
                  }}
                >
                  <p>{translator[language]['Recommended']}</p>
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${Styles.btn1} ${Styles['active-crop-mode1']}`}
                >
                  <p>{translator[language]['Most']}</p>
                </div>
                <div className={Styles.btn2}>
                  <p>{translator[language]['Least']}</p>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <div className={`${Styles['third-item-top-two']}`}>
        <div className={Styles['third-two-left']} style={{ marginTop: '10px' }}>
          {props.currentCrop === 'Irrigation Side' && (
            <>
              <p>{translator[language]['Crop List']}</p>
              {props.currentCrop === 'Irrigation Side' && (
                <p
                  className={Styles['order-most-least']}
                  style={{ color: '#A4A4A4' }}
                >
                  {
                    translator[language][
                      'In order of MOST -> LEAST water intensive'
                    ]
                  }
                </p>
              )}
            </>
          )}
        </div>
        <div className={Styles['third-two-right']}>
          {props.currentCrop === 'Current Situation' ? (
            <p>Order of Water Intensive Crops</p>
          ) : props.cropListMode === 'Manual' ? (
            <></>
          ) : (
            <p>{translator[language]['Suggested By Ekatvam']}</p>
          )}
        </div>
      </div>

      <div className={Styles['third-item-three']}>
        <div className={Styles['third-less-padding']}>
          <div className={Styles['less-padding-top-left']}>
            <p style={{ fontWeight: '600' }}>
              {translator[language]['Crop Name']}
            </p>
          </div>

          <div
            className={Styles['less-padding-top-right']}
            style={{
              justifyContent: 'space-between',
            }}
          >
            <div className={Styles['less-padding-right-one']}>
              <p style={{ fontWeight: '600', textAlign: 'center' }}>
                {translator[language]['Ideal Irrigation Cycle']}
              </p>
            </div>
            <div className={Styles['less-padding-right-two']}>
              <p style={{ fontWeight: '600', textAlign: 'center' }}>
                {translator[language]['Actual Irrigation Cycle']}
              </p>
            </div>
          </div>
        </div>

        {/* LOOPING THROUGH RANGE BAR */}
        <div
          className={Styles['supply-demand-right-scroller']}
          style={{
            // maxHeight: "350px",

            overflowY: 'scroll',
            padding: '2px 15px',
          }}
        >
          {props.allCrops?.map((elem) => {
            return (
              <div key={elem.uuid} className={Styles['bar-div-container']}>
                <div
                  className={Styles['rsuitebar-header-div']}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      className={Styles.cropImages}
                      // src={`https://assets.ekatvam-midas.com/crops/icons-v2/${elem.id}.svg`}
                      src={`https://assets.ekatvam-midas.com/crops/icons-contrast/${elem.id}.svg`}
                      alt="crop images"
                      height={24}
                      width={24}
                      style={{
                        marginRight: '7px',
                        fill: 'red',
                      }}
                    />

                    <p
                      className={Styles['crop-name-map']}
                      style={{ marginLeft: '3px' }}
                    >
                      {/* {translator[language][elem.name]} */}
                      {elem.name}

                      <span className={Styles.cropTypePlan}>
                        {' '}
                        ({elem.type}){/* ({translator[language][elem.type]}) */}
                      </span>
                    </p>
                  </div>

                  <div
                    className={Styles.percentageValuePlan}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                    }}
                  >
                    <span
                      style={{
                        color: 'rgba(56, 131, 230, 1)',
                        fontWeight: 'bold',
                        // fontSize: "14px",
                        marginRight: '2px',
                      }}
                    >
                      {elem?.hasOwnProperty('newIdealValue')
                        ? Number(elem?.newIdealValue)?.toFixed(0)
                        : (
                            (elem?.per_ha_cwr * elem?.crop_area * 1000 -
                              elem?.per_ha_cwr *
                                (elem?.drip_area *
                                  elem?.drip_efficiency *
                                  1000)) /
                            (elem?.hours_per_irrigation_cycle_per_hectare *
                              elem?.water_pumped_per_hour_in_litres *
                              0.001 *
                              elem?.crop_area)
                          ).toFixed(0)}
                    </span>
                  </div>
                </div>
                <div className={Styles['bar-div']}>
                  <div className={Styles['bar-div-suitebar']}>
                    <RsuiteBarIrrigation
                      currentRange={props.currentRange}
                      changeRange={props.changeRange}
                      cropListMode={props.cropListMode}
                      currentCrop={props.currentCrop}
                      id={elem}
                      value={
                        elem?.hasOwnProperty('newIdealValue')
                          ? elem?.newIdealValue
                          : (elem?.per_ha_cwr * elem?.crop_area * 1000 -
                              elem?.per_ha_cwr *
                                (elem?.drip_area *
                                  elem?.drip_efficiency *
                                  1000)) /
                            (elem?.hours_per_irrigation_cycle_per_hectare *
                              elem?.water_pumped_per_hour_in_litres *
                              0.001 *
                              elem?.crop_area)
                      }
                      allCrops={props.allCrops}
                      allCropsForReset={props?.allCropsForReset}
                      setAllCrops={props.setAllCrops}
                      setAllCropsForReset={props.setAllCropsForReset}
                      predefinedCropsDetail={props.predefinedCropsDetail}
                      // CWR of the crop = [(CWR of the crop for 1 Hectare) * (total area of the crop)]
                      //  - [(CWR of the crop for 1 Hectare) * (drip area of the crop)* (drip efficiency of the crop)]

                      cropWaterRequirement={
                        elem.per_ha_cwr * elem.crop_area -
                        elem.per_ha_cwr * elem.drip_area * elem.drip_efficiency
                      }
                      // new
                      // maxActualIrrigation={elem?.irrigation_cycle}
                      maxActualIrrigation={
                        (elem?.per_ha_cwr * elem?.crop_area * 1000 -
                          elem?.per_ha_cwr *
                            (elem?.drip_area * elem?.drip_efficiency * 1000)) /
                        (elem?.hours_per_irrigation_cycle_per_hectare *
                          elem?.water_pumped_per_hour_in_litres *
                          0.001 *
                          elem?.crop_area)
                      }
                      actualIrrigaton={props.actualIrrigaton}
                      setActualIrrigation={props.setActualIrrigation}
                    />
                  </div>
                  <div className={Styles['right-two-ones']}>
                    <div className={Styles['twelve']}>
                      <p className={Styles.mobileTableHeadingsPlan}>
                        {translator[language]['Ideal Irrigation Cycle']}
                      </p>
                      <p>
                        {' '}
                        <span
                          style={{
                            color: 'rgba(56, 131, 230, 1',
                            marginRight: '10px',
                          }}
                        >
                          {(
                            (elem?.per_ha_cwr * elem?.crop_area * 1000 -
                              elem?.per_ha_cwr *
                                (elem?.drip_area *
                                  elem?.drip_efficiency *
                                  1000)) /
                            (elem?.hours_per_irrigation_cycle_per_hectare *
                              elem?.water_pumped_per_hour_in_litres *
                              0.001 *
                              elem?.crop_area)
                          ).toFixed(0)}
                        </span>
                        {''}
                      </p>
                    </div>
                    <div
                      className={Styles['twelve-two']}
                      style={{ fontWeight: 'bold' }}
                    >
                      <p className={Styles.mobileTableHeadingsPlan}>
                        {translator[language]['Actual Irrigation Cycle']}
                      </p>
                      <p>
                        {' '}
                        <span
                          style={{
                            // color: "rgba(56, 131, 230, 1",
                            marginRight: '10px',
                          }}
                        >
                          {elem?.irrigation_cycle?.toFixed(0)}
                        </span>
                        {''}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ borderTop: '1px solid rgba(216,216,216,1)' }}></div>
        {/* LOOPING THROUGH RANGE BAR       */}

        <div className={Styles['bottom-last']}>
          <div className={Styles.tableLastdivMobile}>
            <p className={Styles['total-text']}>
              {translator[language]['Total']}
            </p>
          </div>
          <div className={Styles['total-items-calculation-text']}>
            <p
              style={{
                fontWeight: 'bold',
                width: '42%',
                marginLeft: '11px',
                // background: 'red',
              }}
            >
              <span className={Styles.mobileTotalHeadingsTable}>
                {' '}
                {translator[language]['Ideal Irrigation Cycle']} -
              </span>
              <span
                style={{
                  color: 'rgba(56, 131, 230, 1',
                  marginRight: '10px',
                }}
              >
                {roundOffTillOne(
                  props?.allCrops.reduce((initialValue, val) => {
                    const currentIdeal = val?.hasOwnProperty('newIdealValue')
                      ? val?.newIdealValue
                      : (val?.per_ha_cwr * val?.crop_area * 1000 -
                          val?.per_ha_cwr *
                            (val?.drip_area * val?.drip_efficiency * 1000)) /
                        (val?.hours_per_irrigation_cycle_per_hectare *
                          val?.water_pumped_per_hour_in_litres *
                          0.001 *
                          val?.crop_area);

                    return initialValue + currentIdeal;
                  }, 0),
                )}
              </span>
            </p>
            <p
              style={{
                fontWeight: 'bold',
                width: '50%',
                textAlign: 'center',
              }}
            >
              <span className={Styles.mobileTotalHeadingsTable}>
                {translator[language]['Actual Irrigation Cycle']} -
              </span>
              <span
                style={{
                  marginRight: '10px',
                }}
              >
                {props.allCrops
                  .reduce((initialValue, val) => {
                    return initialValue + val.irrigation_cycle;
                  }, 0)
                  ?.toFixed(0)}
                {/* ---- */}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default IrrigationSide;
