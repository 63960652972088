import React from 'react';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import styled from 'styled-components';
import { compressString } from '../../shared/utils/format';
import base64 from 'base-64';
import useProcessedChartData from '../../services/hooks/useProcessedChartData';
import { useContext } from 'react';
import {
  GeneralContext,
  TimelineContext,
} from '../../services/context/context';

var createReactClass = require('create-react-class');

const ScenarioSummaryChart = ({
  scenarios,
  setRenderScenarioSummary,
  setScenarioId,
}) => {
  const { month, year, pageHead } = useContext(TimelineContext);
  const { selectedUnit, selectedVillageTankerValue } =
    useContext(GeneralContext);

  let chartValues = JSON.stringify(['Aquifer', 'Water balance', 'Water saved']);
  const processedScenarios = useProcessedChartData(
    scenarios,
    selectedUnit,
    selectedVillageTankerValue,
    month,
    year,
    pageHead,
    chartValues,
  );

  const parameters = ['Water saved', 'Aquifer', 'Water balance'];
  const scenarioNames = processedScenarios.map((scenario) => scenario.name);
  const colors = ['#6495ED', '#FFBF00', '#FF7F50'];

  const data = parameters.map((parameter) => {
    const ranking = { name: parameter };
    for (let scenario of processedScenarios) {
      ranking[scenario.name] = scenario[parameter];
    }
    return ranking;
  });
  const payload = data.map((d) => {
    const keys = Object.keys(d).sort(function (a, b) {
      return d[a] - d[b];
    });
    const ranking = {};
    let idx = 0;
    for (let key of keys) {
      ranking[key] = idx++;
    }
    Object.assign(ranking, {
      name: d.name,
    });
    return ranking;
  });
  const styles = {
    width:
      window.innerWidth < 600 && window.innerWidth < 380
        ? '320px'
        : window.innerWidth < 600 && window.innerWidth > 380
        ? '350px'
        : '85%',
    fontFamily: 'sans-serif',
    textAlign: 'center',
  };

  const CustomizedLabel = createReactClass({
    render() {
      const { x, y, stroke, scenarioName, index } = this.props;
      const scenario = processedScenarios.find(
        (scenario) => scenario.name === scenarioName,
      );
      const value = scenario[parameters[index]];
      return (
        <text
          x={x}
          y={y}
          dy={-4}
          fill={stroke}
          fontSize={10}
          textAnchor="middle"
        >
          {value}
        </text>
      );
    },
  });

  function getKeyByValue(rank, index) {
    return Object.keys(rank).find((key) => rank[key] === index + 1);
  }

  function CustomizedTick(props) {
    const { x, y, index } = props;
    const rank = payload[0];
    const key = getKeyByValue(rank, index);
    const scenario = processedScenarios.find(
      (scenario) => scenario.name === key,
    );

    const word = key;
    const splitIndex = 9; // Index where you want to split the word

    const firstPart = word?.substring(0, splitIndex);
    const secondPart = word?.substring(splitIndex);

    return (
      <>
        <g transform={`translate(${x},${y})`}>
          <input type="checkbox" id="C" name="C" value="C" />
          <label>C</label>
          {key && (
            <text
              x={0}
              y={0}
              dy={16}
              fill="#666"
              onClick={() => {
                setScenarioId(scenario.id, scenario.Type);
              }}
            >
              <tspan>
                <tspan
                  textAnchor="middle"
                  x={window.innerWidth < 768 ? '-30' : '-70'}
                  y="-10"
                  fill={
                    scenario?.isRecommended === true
                      ? '#3883E6'
                      : scenario.Type === 'demand-and-supply'
                      ? '#1EB250'
                      : '#008960'
                  }
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    fontSize: window.innerWidth < 768 && '10px',
                  }}
                >
                  {window.innerWidth < 768
                    ? compressString(secondPart, 20)
                    : compressString(key, 20)}
                </tspan>
                {window.innerWidth < 768 && (
                  <tspan
                    textAnchor="middle"
                    x={window.innerWidth < 768 ? '-30' : '-70'}
                    y="-10"
                    fill={
                      scenario?.isRecommended === true
                        ? '#3883E6'
                        : scenario.Type === 'demand-and-supply'
                        ? '#1EB250'
                        : '#008960'
                    }
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      fontSize: window.innerWidth < 768 && '10px',
                    }}
                  >
                    {compressString(firstPart, 20)}
                  </tspan>
                )}

                <tspan
                  textAnchor="middle"
                  x={window.innerWidth < 768 ? '-30' : '-70'}
                  dy={
                    window.innerWidth < 768
                      ? secondPart !== ''
                        ? '35'
                        : '20'
                      : '20'
                  }
                  style={{
                    fontSize: window.innerWidth < 768 ? '9px' : '11px',
                    color: '#747474',
                    fontStyle: 'italic',
                  }}
                >
                  {scenario.owner ===
                  localStorage.getItem(base64.encode('name'))
                    ? 'By You'
                    : 'By ' + compressString(scenario.owner, 20)}
                </tspan>
              </tspan>
            </text>
          )}
        </g>
      </>
    );
  }

  return (
    <Wrapper>
      <div style={styles} className="parent-chart-div">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={payload}
            margin={{
              top: 5,
              left: window.innerWidth < 768 ? 5 : 90,
              right: window.innerWidth < 768 && 30,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              orientation="top"
              padding={{ left: 30, right: 30 }}
              fontSize={window.innerWidth < 768 && 11}
            />
            <YAxis
              interval="preserveStartEnd"
              type="category"
              padding={{ top: 50, bottom: 50 }}
              tick={<CustomizedTick tickPayload={payload} />}
              tickSize={4}
            />

            {/* <Legend /> */}
            {scenarioNames.map((scenarioName, index) => (
              <Line
                isAnimationActive={false}
                type="monotone"
                key={scenarioName}
                dataKey={scenarioName}
                stroke={colors[index]}
                activeDot={{ r: 8 }}
                label={<CustomizedLabel scenarioName={scenarioName} />}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Wrapper>
  );
};

export default ScenarioSummaryChart;

const Wrapper = styled.div`
  .parent-chart-div {
    height: 450px;

    @media screen and (max-width: 600px) {
      height: 280px;
    }

    @media screen and (min-width: 1700px) {
      height: 600px;
    }
  }
`;
