import L from 'leaflet';
import wellSvg from '../assets/images/well.svg';
import tankSvg from '../assets/images/tank.svg';
import farmPondSvg from '../assets/images/farmPond.svg';
import villagePondSvg from '../assets/images/VillagePondNew.svg';
import damSvg from '../assets/images/dam.svg';
import lbs from '../assets/images/LBS.svg';
import gabion from '../assets/images/Gabion.svg';
import rechargeShaft from '../assets/images/Rechargeshaft.svg';
import rechargeTrench from '../assets/images/Rechargetrench.svg';
import miTank from '../assets/images/MITank.svg';

const wellIcon = new L.Icon({
  iconUrl: wellSvg,
  iconRetinaUrl: wellSvg,
  popupAnchor: [-0, -0],
  iconSize: [20, 28],
});

const damIcon = new L.Icon({
  iconUrl: damSvg,
  iconRetinaUrl: damSvg,
  popupAnchor: [-0, -0],
  iconSize: [20, 28],
});

const tankIcon = new L.Icon({
  iconUrl: tankSvg,
  iconRetinaUrl: tankSvg,
  popupAnchor: [-0, -0],
  iconSize: [20, 28],
});

const miTankIcon = new L.Icon({
  iconUrl: miTank,
  iconRetinaUrl: miTank,
  popupAnchor: [-0, -0],
  iconSize: [20, 28],
});

const villagePondIcon = new L.Icon({
  iconUrl: villagePondSvg,
  iconRetinaUrl: villagePondSvg,
  popupAnchor: [-0, -0],
  iconSize: [20, 28],
});

const farmPondIcon = new L.Icon({
  iconUrl: farmPondSvg,
  iconRetinaUrl: farmPondSvg,
  popupAnchor: [-0, -0],
  iconSize: [20, 28],
});

const lbsIcon = new L.Icon({
  iconUrl: lbs,
  iconRetinaUrl: lbs,
  popupAnchor: [-0, -0],
  iconSize: [20, 28],
});

const gabionIcon = new L.Icon({
  iconUrl: gabion,
  iconRetinaUrl: gabion,
  popupAnchor: [-0, -0],
  iconSize: [20, 28],
});

const rechargeShaftIcon = new L.Icon({
  iconUrl: rechargeShaft,
  iconRetinaUrl: rechargeShaft,
  popupAnchor: [-0, -0],
  iconSize: [20, 28],
});

const rechargeTrenchIcon = new L.Icon({
  iconUrl: rechargeTrench,
  iconRetinaUrl: rechargeTrench,
  popupAnchor: [-0, -0],
  iconSize: [20, 28],
});

export {
  wellIcon,
  damIcon,
  tankIcon,
  villagePondIcon,
  farmPondIcon,
  lbsIcon,
  gabionIcon,
  rechargeShaftIcon,
  rechargeTrenchIcon,
  miTankIcon,
};
