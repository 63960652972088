import React, { useContext, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polygon, Marker, Popup } from 'react-leaflet';
// import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';
import { Get } from '../../../services/apiCall';
import 'leaflet/dist/leaflet.css';
import { wellIcon } from '../../../services/icon';
import MapLoader from '../../Loader/hash';
import roundOff from '../../../services/round';
import translator from '../../../assets/json/translator.json';
import { useParams } from 'react-router-dom';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../../services/context/context';
import Styles from './Map.module.css';

// leaflet-simple-map-screenshoter options
// const snapshotOptions = {
//   hideElementsWithSelectors: [
//     '.leaflet-control-container',
//     '.leaflet-dont-include-pane',
//     '#snapshot-button',
//   ],
//   hidden: true,
// };

// const screenshotter = new SimpleMapScreenshoter(snapshotOptions);

/**
 *
 * @component GroundwaterMap
 * @states
 * isLoading --> If true show animation
 * isFetched --> To keep track of async ops
 * villageGis --> An array which stores the gis data of village boundary
 * wells --> An array which stores gis data of all the wells in the village for that month
 * center --> Stores the center coordinates of the map
 * @description
 * This component represents the map on the left side of the groundwater page
 */

const GroundwaterMap = () => {
  const { clusterId, villageId } = useParams();
  const { month, year } = useContext(TimelineContext);
  const { language, programId } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [villageGis, setVillageGis] = useState([]);
  const [wells, setWells] = useState([]);
  const [center, setCenter] = useState([20.5937, 78.9629]);

  useEffect(() => {
    const initialFunction = async () => {
      setIsLoading(true);
      const params1 = {
        villageId: villageId,
        programId: programId,
        clusterId: clusterId,
      };
      const response1 = await Get('/geo/village-gis', token, params1);
      const payload1 = response1.data;
      if (
        payload1 &&
        payload1.length > 0 &&
        payload1[0].geom &&
        payload1[0].geom.coordinates &&
        payload1[0].geom.coordinates.length > 0
      ) {
        let villageGisData = payload1[0].geom.coordinates[0][0];
        villageGisData = villageGisData.map((d) => {
          return [d[1], d[0]];
        });
        const center = [
          parseFloat(payload1[0].latitude),
          parseFloat(payload1[0].longitude),
        ];
        setVillageGis(villageGisData);
        setCenter(center);
      }
      const params2 = {
        villageId: villageId,
        programId: programId,
        clusterId: clusterId,
      };

      const response2 = await Get('/info/wells', token, params2);
      const payload2 = response2.data;
      if (payload2 && payload2.length > 0) {
        setWells(payload2);
      }

      setIsLoading(false);
    };

    initialFunction();

    return () => {
      setVillageGis([]);
      setWells([]);
      setCenter([20.5937, 78.9629]);
    };
  }, [month, year, villageId, programId, clusterId, token]);

  return (
    <div className={Styles.groundWaterContentMapWrapper}>
      <div className={Styles.groundwaterContentLeft}>
        {!isLoading ? (
          <>
            <div className={Styles.groundwaterContentLeftHead}>
              {translator[language]['Well Location Map']}
            </div>
            <div className={Styles.groundwaterContentLeftMap}>
              <MapContainer
                center={center}
                zoom={window.innerWidth < 768 ? 13 : 14}
                scrollWheelZoom={false}
                className={Styles.groundwaterContentLeftMapMap}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Polygon color={'#b2b2b2'} positions={villageGis} />
                {wells.map((well) => {
                  return (
                    <Marker
                      key={well.id}
                      icon={wellIcon}
                      position={[well.latitude, well.longitude]}
                    >
                      <Popup>
                        <b>{well.name}</b>
                        <br />
                        {translator[language]['Depth']}: {roundOff(well.depth)}{' '}
                        {translator[language]['m']}
                      </Popup>
                    </Marker>
                  );
                })}
              </MapContainer>
            </div>
          </>
        ) : (
          <MapLoader
            style={{
              position: 'relative',
              marginTop: '45%',
              marginLeft: '50%',
            }}
          />
        )}
      </div>
    </div>
  );
};

export default GroundwaterMap;
