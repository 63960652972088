import { VIEW_TYPE } from '../../../../../shared/enums/view-type';
import {
  CONTOUR,
  GEOLOGY,
  GEOMORPHOLOGY,
  HYDROLOGY,
  PATTERN,
} from '../../../Views';
import styles from './index.module.css';

const getData = (view) => {
  switch (view) {
    case VIEW_TYPE.GEOMORPHOLOGY:
      return { header: 'Geomorphology View', data: GEOMORPHOLOGY };
    case VIEW_TYPE.DRAINAGE_PATTERN:
      return { header: 'Drainage Pattern View', data: PATTERN };
    case VIEW_TYPE.GEOLOGY:
      return { header: 'Geology View', data: GEOLOGY };
    case VIEW_TYPE.CONTOUR_N_SLOPE:
      return { header: 'Contour & Slope View', data: CONTOUR };
    case VIEW_TYPE.HYDROLOGY:
      return { header: 'Hydrology', data: HYDROLOGY };
    default:
      return { header: 'View', data: [] };
  }
};

const Label = ({ activeViews }) => {
  const views = Array.from(activeViews);

  return views.map((view) => {
    return (
      <div>
        <div className={styles.header}>{getData(view).header}</div>
        <div className={styles.content}>
          <div className={styles.index}>
            <div>
              {getData(view).data.map((label, index) => (
                <div
                  key={label.type}
                  className={styles.label}
                  style={{
                    marginBottom:
                      index < getData(view).data.length - 1 ? '5%' : undefined,
                  }}
                >
                  <div
                    className={styles.color}
                    style={{ backgroundColor: label.fillColor }}
                  />
                  <div
                    className={styles.text}
                    title={label.label.length > 23 ? label.label : ''}
                  >
                    {label.label.length > 23
                      ? `${label.label.substring(0, 23)}...`
                      : label.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default Label;
