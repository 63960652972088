export const Users = [
  {
    name: 'Admin',
    value: 'admin',
  },
  {
    name: 'Program Manager',
    value: 'programManager',
  },
  {
    name: 'Cluster Coordinator',
    value: 'clusterCoordinator',
  },
  {
    name: 'Village Coordinator',
    value: 'fieldFacilitator',
  },
];
