import React, { useContext } from 'react';
import { VscTriangleLeft, VscTriangleRight } from 'react-icons/vsc';
import $ from 'jquery';
import translator from '../../assets/json/translator.json';
import AccountType from '../../shared/enums/account-type';
import { useEffect } from 'react';
import {
  AuthContext,
  TimelineContext,
  GeneralContext,
} from '../../services/context/context';
import Styles from './Month.module.css';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

/**
 *
 * @component Month
 * @description
 * This component shows the months on the navbar
 */

const Month = () => {
  let monthRef = React.createRef();

  const { handleMonth, month, year } = useContext(TimelineContext);
  const { accountType } = useContext(AuthContext);
  const { language } = useContext(GeneralContext);

  const months = [
    {
      month: 'January',
      id: 0,
    },
    {
      month: 'February',
      id: 1,
    },
    {
      month: 'March',
      id: 2,
    },
    {
      month: 'April',
      id: 3,
    },
    {
      month: 'May',
      id: 4,
    },
    {
      month: 'June',
      id: 5,
    },
    {
      month: 'July',
      id: 6,
    },
    {
      month: 'August',
      id: 7,
    },
    {
      month: 'September',
      id: 8,
    },
    {
      month: 'October',
      id: 9,
    },
    {
      month: 'November',
      id: 10,
    },
    {
      month: 'December',
      id: 11,
    },
  ];

  /**
   * @description
   * animates and scrolls to the current month on the div
   */

  useEffect(() => {
    const _phase = Math.floor(month / 4);
    const width = monthRef.current?.offsetWidth;
    let scroll;
    switch (_phase) {
      case 0:
        scroll = 0;
        break;
      case 1:
        scroll = width;
        break;
      case 2:
        scroll = 2 * width;
        break;
      default:
        scroll = 0;
    }
    $('.monthScrollAnimation').animate(
      { scrollLeft: '+=' + scroll + 'vw' },
      500,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description
   * animates the right scroll button
   */
  const handleRightArrow = (e) => {
    const width = monthRef.current.offsetWidth;
    $('.monthScrollAnimation').animate(
      { scrollLeft: `+=${Math.abs(width)}vw` },
      500,
    );
  };
  /**
   * @description
   * animates the left scroll button
   */
  const handleLeftArrow = () => {
    const width = monthRef.current.offsetWidth;
    $('.monthScrollAnimation').animate(
      { scrollLeft: `-=${Math.abs(width)}vw` },
      500,
    );
  };
  /**
   *
   * @param {*} month
   * @description
   * checks if the certain month is lesser than the present month
   * to make those month clickable else future months will be unclickable
   * @returns true if month is less than current month else false
   */
  const isActiveMonth = (month) => {
    if (accountType === AccountType.ADMIN) {
      return true;
    } else {
      let currentMonth = new Date().getMonth();
      let currentYear = new Date().getFullYear();
      if (year === currentYear && month >= currentMonth) {
        return false;
      }
      return true;
    }
  };
  /**
   *
   * @param {*} month
   * @description
   * apply different style based on the current month to different months on calendar
   * @returns different styles based on the month
   */
  const activeMonthStyle = (monthParam) => {
    if (accountType === AccountType.ADMIN) {
      if (month === monthParam) {
        return { color: '#3883E6', cursor: 'pointer' };
      } else {
        return { cursor: 'pointer' };
      }
    } else {
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
      if (year === currentYear && monthParam >= currentMonth) {
        return { color: '#9A9A9A' };
      }
      if (month === monthParam) {
        return { color: '#3883E6', cursor: 'pointer' };
      }
      return { cursor: 'pointer' };
    }
  };

  /**
   *
   * @param {*} month - monthId on button click
   * @description
   * changes the active month
   */
  const handleMonthClick = (month) => {
    if (isActiveMonth(month)) handleMonth(month);
  };

  return (
    <>
      {!window.location.pathname.startsWith('/plan') && (
        <div className={Styles['calendar-month']}>
          {window.innerWidth >= 768 && (
            <div className={Styles.calendarTriangleLeftIcon}>
              <VscTriangleLeft
                id={Styles.calendarLeftIcon}
                onClick={() => {
                  handleLeftArrow();
                }}
              />
            </div>
          )}
          {window.innerWidth >= 768 ? (
            <div
              ref={monthRef}
              className="monthScrollAnimation"
              id={Styles.month}
            >
              {months.map((e) => {
                return (
                  <div
                    className={Styles['month-cell']}
                    style={activeMonthStyle(e.id)}
                    onClick={() => handleMonthClick(e.id)}
                    key={e.id}
                  >
                    {translator[language][e.month]}
                  </div>
                );
              })}
            </div>
          ) : (
            <div ref={monthRef} className={Styles.monthMobile}>
              <FormControl>
                <Select
                  className={Styles.monthMobileOptions}
                  defaultValue={months
                    .map((e) => e.id === month && e.month)
                    .filter(Boolean)}
                >
                  {months.map((e) => {
                    return (
                      <MenuItem
                        className={Styles.mobileMenuItems}
                        value={e.month}
                        style={{
                          ...activeMonthStyle(e.id),
                          minHeight: '22px',
                          fontSize: '12px',
                        }}
                        onClick={() => handleMonthClick(e.id)}
                        key={e.id}
                      >
                        {translator[language][e.month]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}

          {window.innerWidth >= 768 && (
            <div className={Styles.calendarTriangleRightIcon}>
              <VscTriangleRight
                id={Styles.calendarRightIcon}
                onClick={(e) => {
                  handleRightArrow(e);
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Month;
