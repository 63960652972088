import React, { useEffect, useState } from 'react';
import { Slider, Box, SliderThumb } from '@mui/material';
import styledComponents from 'styled-components';
import { styled } from '@mui/material/styles';

const RsuiteBarIrrigation = (props) => {
  const [currentRsuiteRange, setCurrentRsuiteRange] = useState(props.value);

  useEffect(() => {
    setCurrentRsuiteRange(props.value);
  }, [props.cropListMode, props.value]);

  useEffect(() => {
    props.setAllCrops(
      props.allCrops.map((data) => {
        if (data.uuid === props.id.uuid) {
          return {
            ...data,
            newIdealValue: currentRsuiteRange,
          };
        }
        return data;
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRsuiteRange]);

  const marks = [
    {
      value: 0,
      label: '0',
    },

    {
      value: Number(props?.maxActualIrrigation),
      label: `${props?.maxActualIrrigation?.toFixed(0)}`,
      // value: Math.max(props?.maxActualIrrigation, props?.value),
      // label: Math.max(props?.maxActualIrrigation, props?.value),
    },
  ];

  const PrettoSlider = styled(Slider)({
    color: 'rgba(32, 88, 161, 1)',
    height: 10,
    '& .MuiSlider-track': {
      border: 'none',
    },

    '& .MuiSlider-thumb': {
      display: props.currentCrop === 'Current Situation' && 'none',
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      // border: "2px solid currentColor",
      border: '2px solid rgba(255, 255, 255, 1)',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },

      '& .airbnb-bar': {
        height: 14,
        width: 14,
        backgroundColor:
          props.cropListMode === 'Manual'
            ? 'currentColor'
            : 'rgba(32, 88, 161, 1)',
        marginLeft: 1,
        marginRight: 1,
        color: 'rgba(56, 131, 230, 1)',
        borderRadius: '100%',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: 'rgba(32, 88, 161, 1)',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },

    '& .css-1tfve6q-MuiSlider-mark': {
      position: 'unset',
    },

    '& .css-6cwnna-MuiSlider-mark': {
      position: 'unset',
    },

    '& .MuiSlider-rail': {
      background:
        props.cropListMode === 'Manual'
          ? 'rgba(216, 216, 216, 1)'
          : props.currentCrop === 'Demand Side'
          ? 'rgba(216, 216, 216, 1)'
          : 'rgba(216, 216, 216, 1)',
    },

    '&.Mui-disabled': {
      color: 'rgba(32, 88, 161, 1);',
    },

    '& .css-yafthl-MuiSlider-markLabel': {
      fontSize: '12px',
      '@media screen and (max-width:600px)': {
        fontSize: '.6rem',
      },
    },

    '& .css-1eoe787-MuiSlider-markLabel': {
      fontSize: '12px',
      '@media screen and (max-width:600px)': {
        fontSize: '.6rem',
      },
    },
  });

  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <span className="airbnb-bar" />
      </SliderThumb>
    );
  }

  return (
    <Wrapper key={props?.id?.uuid}>
      <Box sx={{ width: 210 }} className="main-box-rsuitebar">
        <PrettoSlider
          key={props?.id?.uuid}
          onChangeCommitted={(event, value) => setCurrentRsuiteRange(value)}
          disableSwap
          valueLabelDisplay="auto"
          aria-label="pretto slider"
          marks={marks}
          defaultValue={Number(currentRsuiteRange)}
          step={1}
          max={Math.max(
            props?.maxActualIrrigation,
            props?.id?.irrigation_cycle,
          )}
          // max={props?.maxActualIrrigation}
          disabled={
            (props.currentCrop === 'Current Situation' ||
              props.cropListMode === 'Recommended') &&
            true
          }
          components={{ Thumb: AirbnbThumbComponent }}
        />
      </Box>
    </Wrapper>
  );
};

export default RsuiteBarIrrigation;

const Wrapper = styledComponents.div`
  .main-box-rsuitebar{
    @media screen and (max-width:1360px){
      width:200px;
    }

    @media screen and (max-width:1175px){
      width:62%;
    }

    @media screen and (min-width:1700px){
      width:62%;
    }
    @media screen and (max-width:600px){
      width:100%;
    }

  }

`;
