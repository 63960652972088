import React, { useContext } from 'react';

// FOR MODAL
import { Select, MenuItem, Button } from '@mui/material';
import { BiAlarm } from 'react-icons/bi';
import months from '../../shared/constants/months';
import translator from '../../assets/json/translator.json';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../services/context/context';
import Styles from './NoPlanAvailable.module.css';

// FOR MODAL

const NoPlanAvailable = (props) => {
  const { language } = useContext(GeneralContext);
  const { accountType } = useContext(AuthContext);
  const { selectedSeason, currentPlanYear, organizationEndDate } =
    useContext(TimelineContext);

  const {
    submitFormByAdmin,
    dateByAdmin,
    setDateByAdmin,
    isPlanScheduleSubmitted,
    errorLog,
  } = props;

  let currentPlanYearStart = currentPlanYear.split('-')[0];
  let currentPlanYearEnd = currentPlanYear.split('-')[1];

  const currentMonth = organizationEndDate.split('-')[1];
  const currentYear = organizationEndDate.split('-')[0];
  // const currentMonth = new Date().getMonth();
  // const currentYear = new Date().getFullYear();

  const dropdownMonths =
    selectedSeason === 'Rabi' ? months.slice(5, 12) : months.slice(0, 5);

  const year =
    selectedSeason === 'Rabi'
      ? currentPlanYearStart
      : selectedSeason === 'Summer'
      ? currentPlanYearEnd
      : '';

  // THIS LOGIC IS FOR GETTING THE MONTH AND YEAR (individually) SET BY ADMIN
  const adminData = dateByAdmin.split(' ');
  const adminMonth = adminData[0];
  const adminYear = parseInt(adminData[1]);

  const validateCurrentMonthandYear = () => {
    if (
      currentYear > adminYear ||
      (currentMonth + 1 >= months.indexOf(adminMonth) + 1 &&
        (currentYear === adminYear || currentYear > adminYear))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const currentYearSessionYear = currentPlanYear.split('-')[0];
  const actualCurrentyear = new Date().getFullYear();

  return (
    <>
      <div style={{ position: 'relative', height: '100%' }}>
        <div className={Styles.noPlanAvailableParent}>
          <div className={Styles.biAlarm}>
            <BiAlarm />
          </div>

          {/* FOR SHOWING ERRORS  */}
          {errorLog !== '' && Array.isArray(errorLog) ? (
            <div
              style={{ border: '1px solid red', padding: '10px', display: '' }}
            >
              {errorLog.map((errors) => {
                return <div style={{ fontSize: '12px' }}>{errors}</div>;
              })}
            </div>
          ) : (
            errorLog !== '' && (
              <div
                style={{
                  border: '1px solid red',
                  padding: '10px',
                  display: '',
                }}
              >
                <div style={{ fontSize: '12px' }}>{errorLog}</div>
              </div>
            )
          )}
          {/* FOR SHOWING ERRORS  */}

          <div>
            {/* STARTS  */}

            {accountType === 'admin' ? (
              <div style={{ marginTop: '10px' }}>
                <>
                  {isPlanScheduleSubmitted ? (
                    <div
                      style={{
                        color: 'green',
                        fontSize: '11px',
                        fontStyle: 'italic',
                      }}
                    >
                      {translator[language]['Submitted']}
                    </div>
                  ) : (
                    ''
                  )}
                  <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        className={Styles.scheduledFor}
                        style={{ marginRight: '15px', fontWeight: 600 }}
                      >
                        {translator[language]['Scheduled for']}:
                      </div>

                      <Select
                        className={Styles.scheduleSelect}
                        onChange={(e) => setDateByAdmin(e.target.value)}
                        value={dateByAdmin}
                        defaultValue={dateByAdmin}
                        style={{ height: '25px' }}
                        displayEmpty
                        renderValue={
                          dateByAdmin !== ''
                            ? undefined
                            : () => (
                                <span
                                  style={{
                                    fontSize: window.innerWidth < 768 && '12px',
                                  }}
                                >
                                  {translator[language]['Select Schedule']}
                                </span>
                              )
                        }
                      >
                        {dropdownMonths.map((month) => {
                          const key = `${translator[language][month]} ${year}`;
                          return (
                            <MenuItem
                              className={Styles.noPlanDropdown}
                              value={key}
                              key={key}
                              style={{
                                minHeight: window.innerWidth < 768 && '22px',
                                fontSize: window.innerWidth < 768 && '12px',
                              }}
                            >
                              {key}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        marginTop: '25px',
                        justifyContent: 'center',
                      }}
                    >
                      <>
                        <Button
                          className={Styles.submitPlanBtn}
                          size="small"
                          onClick={(e) => {
                            submitFormByAdmin(e);
                          }}
                          style={{
                            background: '#347ddc',
                            border: ' 1px solid #347ddc',
                            borderRadius: '10px',
                            padding: '4px 22px',
                            marginRight: '15px',
                            cursor: 'pointer',
                            color: '#fff',
                            textTransform: 'capitalize',
                          }}
                          disabled={
                            currentYearSessionYear > actualCurrentyear
                              ? true
                              : false
                          }
                        >
                          {validateCurrentMonthandYear()
                            ? translator[language]['Open Plan']
                            : translator[language]['Submit']}
                        </Button>
                      </>
                    </div>
                  </>
                </>
              </div>
            ) : (
              <div
                className={Styles.notYetAvailable}
                style={{
                  textAlign: 'center',
                }}
              >
                {' '}
                <b>{selectedSeason}'s Plan</b> for the year session{' '}
                <b>{currentPlanYear}</b> is <br /> not yet available.
              </div>
            )}

            {/* ENDS  */}
          </div>
        </div>
      </div>
    </>
    // </Dialog>
  );
};

export default NoPlanAvailable;
