import axios from 'axios';

//http://midasbackend-env.eba-up8udqyj.ap-south-1.elasticbeanstalk.com/api
//https://api.ekatvam-midas.com/api

const Get = async (url, token, params) => {
  try {
    let baseUrl =
      process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development'
        ? 'https://api.ekatvamdevspace.org/api/v3'
        : process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'local'
        ? 'http://localhost:5000/api/v3'
        : 'https://api.ekatvam-midas.com/api/v3';

    let queryUrl = baseUrl + url;

    const response = await axios.get(queryUrl, {
      headers: {
        authorization: token ? `Bearer ${token}` : null,
      },
      params,
    });
    return { ...response.data, statusCode: response.status };
  } catch (err) {
    if (err.response)
      return {
        error: err.response.data.error
          ? err.response.data.error
          : err.response.data.errors,
      };
    return {};
  }
};
const Post = async (url, token, body) => {
  try {
    let baseUrl =
      process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development'
        ? 'https://api.ekatvamdevspace.org/api/v3'
        : process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'local'
        ? 'http://localhost:5000/api/v3'
        : 'https://api.ekatvam-midas.com/api/v3';

    let queryUrl = baseUrl + url;
    const response = await axios.post(queryUrl, body, {
      headers: {
        authorization: token ? `Bearer ${token}` : null,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    if (err.response)
      return {
        error: err.response.data.error
          ? err.response.data.error
          : err.response.data.errors,
      };
    return {};
  }
};
const Patch = async (url, token, body) => {
  try {
    let baseUrl =
      process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development'
        ? 'https://api.ekatvamdevspace.org/api/v3'
        : process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'local'
        ? 'http://localhost:5000/api/v3'
        : 'https://api.ekatvam-midas.com/api/v3';

    let queryUrl = baseUrl + url;

    let bodyJson = JSON.stringify(body);
    const response = await axios.patch(queryUrl, bodyJson, {
      headers: {
        authorization: token ? `Bearer ${token}` : null,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    if (err.response)
      return {
        error: err.response.data.error
          ? err.response.data.error
          : err.response.data.errors,
      };
    return {};
  }
};

const Delete = async (url, token, params) => {
  try {
    let baseUrl =
      process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development'
        ? 'https://api.ekatvamdevspace.org/api/v3'
        : process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'local'
        ? 'http://localhost:5000/api/v3'
        : 'https://api.ekatvam-midas.com/api/v3';

    let queryUrl = baseUrl + url;

    const response = await axios.delete(queryUrl, {
      headers: {
        authorization: token ? `Bearer ${token}` : null,
      },
      params,
    });
    return { ...response.data, statusCode: response.status };
  } catch (err) {
    if (err.response)
      return {
        error: err.response.data.error
          ? err.response.data.error
          : err.response.data.errors,
      };
    return {};
  }
};

const getIPv4 = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (err) {
    return err.message;
  }
};

export { Get, Post, Patch, Delete, getIPv4 };
