import { ClimbingBoxLoader } from 'react-spinners';

const climb = (props) => {
  return (
    <div style={props.style}>
      <ClimbingBoxLoader color={'#123abc'} />
    </div>
  );
};

export default climb;
