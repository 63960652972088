import { BounceLoader } from 'react-spinners';

const bounce = (props) => {
  return (
    <div style={props.style}>
      <BounceLoader color={'#646766'} size={30} />
    </div>
  );
};

export default bounce;
