import roundOff from './round';
/* This is a generic function that returns the updated value according to the
Unit provided by the user. */
const getUnitValue = (
  value,
  selectedUnit,
  selectedVillageTankerValue,
  selectedVillage,
) => {
  if (selectedUnit === 'Cr Litre') {
    let currentValue = roundOff(value / 10);
    let finalValue = Number(currentValue);

    return finalValue;
    // return value / 10;
  } else if (selectedUnit === 'Tankers') {
    let tcmInLitresValue = value * 1000000;
    // let tankersValue = tcmInLitresValue / 5000;
    let tankersValue = tcmInLitresValue / selectedVillageTankerValue;
    let finalTankersValue = tankersValue?.toFixed(0);
    let numberedTankerValue = Number(finalTankersValue);
    return numberedTankerValue;
  } else {
    let numberedValue = Number(value);
    return Number(numberedValue);
  }
};

export default getUnitValue;
