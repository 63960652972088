import React, { useState, useEffect, useRef } from 'react';
import savedScenario from '../../assets/images/savedScenario.png';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import translator from '../../assets/json/translator.json';
import ToolTipIcon from '../../assets/images/tooltipIcon.png';
import moment from 'moment';
import { Delete, Get, Patch } from '../../services/apiCall';
import savedScenarioFailed from '../../assets/images/savedScenarioFailed.png';
import base64 from 'base-64';
import {
  Autocomplete,
  Box,
  Checkbox,
  createFilterOptions,
  TextField,
} from '@mui/material';
import { useContext } from 'react';
import {
  AuthContext,
  GeneralContext,
  TimelineContext,
} from '../../services/context/context';
import { useParams } from 'react-router-dom';
import Styles from './GenericPlanNav.module.css';
import { Users } from '../../services/users';

const GenericPlanNav = (props) => {
  const { villageId, clusterId } = useParams();
  const { programId, language } = useContext(GeneralContext);
  const { currentPlanYear } = useContext(TimelineContext);
  const { token, accountType } = useContext(AuthContext);

  // for checkbox
  const [isChecked, setIsChecked] = useState(false);

  // selecting user role
  const [userRole, setUserRole] = useState('admin');

  //for event target of searching the user
  const [searchUser, setSearchUser] = useState('');

  const [currOpt, setCurrOpt] = useState('');

  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [isScenarioSaved, setIsScenarioSaved] = useState(false);

  //  STATE FOR SHARE TO
  const [getAllEmails, setGetAllEmails] = useState([]);
  const [shareTo, setShareTo] = useState([]);
  const [permissions, setPermissions] = useState('Viewer');

  // const [currentOption, setCurrentOption] = useState("");
  let currentOption = '';

  const [apiErrors, setApiErrors] = useState('');

  const handleChange = (event) => {
    setCurrOpt(event.target.value);
  };

  const Placeholder = ({ children }) => {
    // const classes = usePlaceholderStyles();
    return <div>{children}</div>;
  };

  // USEFFECT TO COUNTER THE FILTERED ARRAY CHANGE
  const [counter, setCounter] = useState(false);
  const didMount = useRef(false);
  useEffect(() => {
    if (!didMount.current) {
      return (didMount.current = true);
    } else {
      props.setCurrentCrop('SavedScenarioSummary');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);
  // USEFFECT TO COUNTER THE FILTERED ARRAY CHANGE

  const deleteScenario = async () => {
    const deleteRequest = await Delete(
      `/simulation/scenario?id=${props.scenarioId}`,
      token,
    );
    if (deleteRequest.status === 'success') {
      props.setScenarios(
        props.scenarios.filter((vals) => {
          return vals?.id !== props.scenarioId;
        }),
      );

      props.setTrackFilteredValues(
        props.trackFilteredValues.filter((vals) => {
          return vals?.id !== props.scenarioId;
        }),
      );

      // ADDED LATER STARTS -----------------------------------------------------------------

      //only for here
      let scenarios =
        props.trackFilteredValues.length > 0
          ? props.trackFilteredValues
          : props.scenarios;
      //only for here

      props.setCurrentCountOfOwnPlansSaved(
        props.currentCountOfOwnPlansSaved - 1,
      );

      const filterSingleForMapped = scenarios.filter((vals) => {
        return vals.id !== props.scenarioId;
      });

      props.setScenarios(filterSingleForMapped);
      props.setTrackFilteredValues(filterSingleForMapped);

      props.setReferAnotherArray(filterSingleForMapped);
      props.setDuplicateArray(filterSingleForMapped);

      // ADDED LATER ENDS--------------------------------------------------------------

      setCounter(!counter);

      // props.setCurrentCrop("SavedScenarioSummary");
    } else {
      setOpenSaveModal(true);
      setCurrOpt('Delete Fail');
      const errmsg = deleteRequest.error.map((vals) => vals.message);
      setApiErrors(errmsg);
    }
  };

  const setRecommendationFunction = async () => {
    const payload = {
      plan: {
        recommended_scenario: props.scenarioId,
      },
    };
    const setRecommendation = await Patch(
      `/simulation/plan?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&yearSession=${currentPlanYear}&season=${props.season}`,
      token,
      payload,
    );

    if (setRecommendation.status === 'success') {
      setCurrOpt('Set Recommendation Success');
      setOpenSaveModal(true);
    } else {
      setCurrOpt('Set Recommendation Fail');
      const errmsg = setRecommendation.error.map((vals) => vals.message);
      setApiErrors(errmsg);
      setOpenSaveModal(true);
    }
  };

  const OPTIONS_LIMIT = 6;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const setUserRoleFunction = (e) => {
    setUserRole(e);
    setSearchUser('');

    if (checkedTrueOrFalseFunction(e) === true) {
      setIsChecked(true);
    } else if (checkedTrueOrFalseFunction(e) === false) {
      setIsChecked(false);
    }
  };

  const fetchUserFunction = async (e) => {
    if (e.length < 3) {
      setSearchUser('');
      setShareTo([]);
    } else if (e.length >= 3) {
      const finalUser = await Get(
        `/info/user/search?programId=${programId}&clusterId=${clusterId}&villageId=${villageId}&role=${userRole}&term=${e}`,
        token,
      );

      if (finalUser.status === 'success') {
        let arr = finalUser.data.map((item) => {
          // return item.name + "(" + item.email_id + ")";
          return item;
        });

        let finalArr = arr.filter((vals) => {
          return (
            vals.email_id !== localStorage.getItem(base64.encode('emailId'))
          );
        });

        setShareTo(finalArr);
      }
    }
  };

  const shareFunction = async () => {
    let scenarioData =
      permissions === 'Viewer'
        ? props.ownerData.viewer
        : props.ownerData.editor;

    let finalData = !Array.isArray(getAllEmails)
      ? permissions === 'Viewer'
        ? getAllEmails.viewer
        : getAllEmails.editor
      : scenarioData;

    // let uniqueEmails = [...new Set([...finalData.admin, searchUser.email_id])];

    // console.log("FINAALEEEE", ...uniqueEmails);

    let payload =
      permissions === 'Viewer'
        ? {
            scenario: {
              viewer: {
                admin:
                  userRole === 'admin'
                    ? isChecked === true
                      ? ['*']
                      : [
                          ...new Set([
                            ...finalData.admin.filter((item) => item !== '*'),
                            searchUser.email_id,
                          ]),
                        ]
                    : [...finalData?.admin],
                program_manager:
                  userRole === 'programManager'
                    ? isChecked === true
                      ? ['*']
                      : [
                          ...new Set([
                            ...finalData.program_manager.filter(
                              (item) => item !== '*',
                            ),
                            searchUser.email_id,
                          ]),
                        ]
                    : [...finalData?.program_manager],

                cluster_coordinator:
                  userRole === 'clusterCoordinator'
                    ? isChecked === true
                      ? ['*']
                      : [
                          ...new Set([
                            ...finalData.cluster_coordinator.filter(
                              (item) => item !== '*',
                            ),
                            searchUser.email_id,
                          ]),
                        ]
                    : [...finalData?.cluster_coordinator],
                field_facilitator:
                  userRole === 'fieldFacilitator'
                    ? isChecked === true
                      ? ['*']
                      : [
                          ...new Set([
                            ...finalData.field_facilitator.filter(
                              (item) => item !== '*',
                            ),
                            searchUser.contact_number,
                          ]),
                        ]
                    : [...finalData?.field_facilitator],
              },
            },
          }
        : {
            scenario: {
              editor: {
                admin:
                  userRole === 'admin'
                    ? isChecked === true
                      ? ['*']
                      : [
                          ...new Set([
                            ...finalData.admin.filter((item) => item !== '*'),
                            searchUser.email_id,
                          ]),
                        ]
                    : [...finalData?.admin],
                program_manager:
                  userRole === 'programManager'
                    ? isChecked === true
                      ? ['*']
                      : [
                          ...new Set([
                            ...finalData.program_manager.filter(
                              (item) => item !== '*',
                            ),
                            searchUser.email_id,
                          ]),
                        ]
                    : [...finalData?.program_manager],

                cluster_coordinator:
                  userRole === 'clusterCoordinator'
                    ? isChecked === true
                      ? ['*']
                      : [
                          ...new Set([
                            ...finalData.cluster_coordinator.filter(
                              (item) => item !== '*',
                            ),
                            searchUser.email_id,
                          ]),
                        ]
                    : [...finalData?.cluster_coordinator],
                field_facilitator:
                  userRole === 'fieldFacilitator'
                    ? isChecked === true
                      ? ['*']
                      : [
                          ...new Set([
                            ...finalData.field_facilitator.filter(
                              (item) => item !== '*',
                            ),
                            searchUser.contact_number,
                          ]),
                        ]
                    : [...finalData?.field_facilitator],
              },
            },
          };

    let finalRequest = await Patch(
      `/simulation/scenario?id=${props.scenarioId}`,
      token,
      payload,
    );

    if (finalRequest.status === 'success') {
      setGetAllEmails(finalRequest.data);
      setCurrOpt('Share Success');
      setIsScenarioSaved(true);
    } else {
      setCurrOpt('Share Failed');
      setIsScenarioSaved(true);
    }
  };

  useEffect(() => {
    if (openSaveModal === false) {
      setIsScenarioSaved(false);
      setOpenSaveModal(false);
      setShareTo([]);
      setUserRole('admin');
      setSearchUser('');
      setPermissions('Viewer');
      setIsChecked(false);
    } else if (openSaveModal === true) {
      checkedTrueOrFalseFunction() === true && setIsChecked(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSaveModal]);

  const editAccess = () => {
    if (
      props.ownerData.creator.id ===
        localStorage.getItem(base64.encode('emailId')) ||
      props.ownerData.creator.id ===
        localStorage.getItem(base64.encode('username'))
    ) {
      return true;
    } else if (localStorage.getItem(base64.encode('accountType')) === 'admin') {
      if (
        props.ownerData.editor.admin === '*' ||
        props.ownerData.editor.admin.includes(
          localStorage.getItem(base64.encode('emailId')),
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      localStorage.getItem(base64.encode('accountType')) ===
      'clusterCoordinator'
    ) {
      if (
        props.ownerData.editor.cluster_coordinator === '*' ||
        props.ownerData.editor.cluster_coordinator.includes(
          localStorage.getItem(base64.encode('emailId')),
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      localStorage.getItem(base64.encode('accountType')) === 'programManager'
    ) {
      if (
        props.ownerData.editor.program_manager === '*' ||
        props.ownerData.editor.program_manager.includes(
          localStorage.getItem(base64.encode('emailId')),
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      localStorage.getItem(base64.encode('accountType')) === 'fieldFacilitator'
    ) {
      if (
        props.ownerData.editor.field_facilitator === '*' ||
        props.ownerData.editor.field_facilitator.includes(
          localStorage.getItem(base64.encode('username')),
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const alreadyCheckedFromApi = () => {
    if (
      props.ownerData.creator.id ===
        localStorage.getItem(base64.encode('emailId')) ||
      props.ownerData.creator.id ===
        localStorage.getItem(base64.encode('username'))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkedTrueOrFalseFunction = (e, f) => {
    let scenarioData = props.ownerData;
    let finalData = !Array.isArray(getAllEmails) ? getAllEmails : scenarioData;

    let finalUserRole = e ? e : userRole;
    let finalPermissions = f ? f : permissions;

    if (finalUserRole === 'admin') {
      if (finalPermissions === 'Viewer') {
        if (finalData.viewer.admin.includes('*')) {
          return true;
        } else {
          return false;
        }
      } else if (finalPermissions === 'Editor') {
        if (finalData.editor.admin.includes('*')) {
          return true;
        } else {
          return false;
        }
      }
    } else if (finalUserRole === 'programManager') {
      if (finalPermissions === 'Viewer') {
        if (finalData.viewer.program_manager.includes('*')) {
          return true;
        } else {
          return false;
        }
      } else if (finalPermissions === 'Editor') {
        if (finalData.editor.program_manager.includes('*')) {
          return true;
        } else {
          return false;
        }
      }
    } else if (finalUserRole === 'clusterCoordinator') {
      if (finalPermissions === 'Viewer') {
        if (finalData.viewer.cluster_coordinator.includes('*')) {
          return true;
        } else {
          return false;
        }
      } else if (finalPermissions === 'Editor') {
        if (finalData.editor.cluster_coordinator.includes('*')) {
          return true;
        } else {
          return false;
        }
      }
    } else if (finalUserRole === 'fieldFacilitator') {
      if (finalPermissions === 'Viewer') {
        if (finalData.viewer.field_facilitator.includes('*')) {
          return true;
        } else {
          return false;
        }
      } else if (finalPermissions === 'Editor') {
        if (finalData.editor.field_facilitator.includes('*')) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const setPermissionsFunction = (e) => {
    if (checkedTrueOrFalseFunction(null, e) === true) {
      setIsChecked(true);
    } else if (checkedTrueOrFalseFunction(null, e) === false) {
      setIsChecked(false);
    }

    setPermissions(e);
  };

  return (
    /* GENERIC MODAL FOR THIS PAGE  */
    <>
      {openSaveModal && (
        <div style={{ width: '' }}>
          <Dialog
            Paper
            PaperProps={{
              sx: {
                minWidth: '30%',
                width: window.innerWidth < 768 ? '100%' : 'unset',
              },
            }}
            disableEscapeKeyDown
            open={openSaveModal}
            onClose={() => {
              setIsScenarioSaved(false);
              setOpenSaveModal(false);
              setShareTo([]);
              setUserRole('admin');
              setSearchUser('');
              setPermissions('Viewer');
            }}
          >
            <div
              className={Styles.shareScenarioHeading}
              style={{
                margin: '25px 0 10px 0',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              {currOpt === 'Share' ||
              currOpt === 'Share Success' ||
              currOpt === 'Share Failed'
                ? translator[language]['Share Scenario']
                : currOpt === 'Delete Fail'
                ? 'Delete Scenario'
                : (currOpt === 'Set Recommendation Success' ||
                    currOpt === 'Set Recommendation Fail') &&
                  'Save Recommendation'}
            </div>

            <DialogContent>
              {currOpt !== 'Share' ? (
                <>
                  <div
                    style={{
                      textAlign: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    {currOpt === 'Set Recommendation Fail' ||
                    currOpt === 'Delete Fail' ? (
                      <img
                        src={savedScenarioFailed}
                        alt="Saved Scenario"
                        width={50}
                        style={{ textAlign: 'center' }}
                      />
                    ) : (
                      currOpt === 'Set Recommendation Success' && (
                        <img
                          src={savedScenario}
                          alt="Saved Scenario"
                          width={50}
                          style={{ textAlign: 'center' }}
                        />
                      )
                    )}
                  </div>

                  {currOpt === 'Set Recommendation Fail' ||
                  currOpt === 'Delete Fail' ? (
                    <div style={{ textAlign: 'center' }}>
                      {apiErrors[0]?.replace(/(?<=\S)\s\S+/, '')}
                    </div>
                  ) : (
                    currOpt === 'Set Recommendation Success' && (
                      <>
                        <div
                          style={{ textAlign: 'center', fontWeight: 'bold' }}
                        >
                          {props?.isFromIrrigation
                            ? 'Demand Irrigation Cycle'
                            : translator[language]['Demand & Supply']}
                        </div>
                        <div style={{ marginTop: '5px', textAlign: 'center' }}>
                          {translator[language]['Saved Successfully']}
                        </div>{' '}
                      </>
                    )
                  )}
                </>
              ) : null}

              {isScenarioSaved && currOpt === 'Share Failed' && (
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                  <img
                    src={savedScenarioFailed}
                    alt="Saved Scenario"
                    width={50}
                    style={{ textAlign: 'center' }}
                  />
                  <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    {translator[language]['Failed to Share']}
                  </div>
                </div>
              )}

              {isScenarioSaved && currOpt === 'Share Success' ? (
                <>
                  <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <img
                      src={savedScenario}
                      alt="Saved Scenario"
                      width={50}
                      style={{ textAlign: 'center' }}
                    />
                  </div>
                  <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    {translator[language]['Scenario has been shared']}
                  </div>
                  <div style={{ marginTop: '5px', textAlign: 'center' }}>
                    {translator[language]['Sent to']} {userRole} -{' '}
                    {searchUser.email_id
                      ? searchUser.email_id
                      : searchUser?.contact_number
                      ? searchUser?.contact_number
                      : translator[language]['All']}
                  </div>
                </>
              ) : (
                currOpt === 'Share' && (
                  <div className={Styles.shareCompleteDiv}>
                    <div
                      className={Styles.firstSectionContainer}
                      style={{ marginBottom: '25px' }}
                    >
                      <div
                        className={Styles.shareToHeading}
                        style={{ marginBottom: '8px' }}
                      >
                        {translator[language]['Share to']}
                      </div>

                      <div
                        className={Styles.shareToDivs}
                        style={{ display: 'flex' }}
                      >
                        <div
                          className={Styles.shareToParentDiv}
                          style={{ marginRight: '5px' }}
                        >
                          <FormControl className={Styles.shareToForm}>
                            <Autocomplete
                              ListboxProps={{
                                style: { maxHeight: 120, overflow: 'scroll' },
                              }}
                              disabled={
                                userRole !== '' && isChecked === false
                                  ? false
                                  : true
                              }
                              onMouseDownCapture={(e) => e.stopPropagation()}
                              filterOptions={filterOptions}
                              value={searchUser}
                              onKeyUp={(e, newVal) =>
                                fetchUserFunction(e.target.value, newVal)
                              }
                              onChange={(e, newVal) => setSearchUser(newVal)}
                              freeSolo
                              disablePortal
                              disableClearable
                              id="combo-box-demo"
                              className={Styles.shareUser}
                              options={shareTo}
                              getOptionLabel={(option) =>
                                option.name
                                  ? option?.name + ' (' + option.email_id + ')'
                                  : ''
                              }
                              renderOption={(props, option) => {
                                return (
                                  <Box
                                    component="li"
                                    sx={{
                                      fontSize: 12,
                                      overflowX: 'scroll',
                                      height: 'auto',
                                    }}
                                    {...props}
                                  >
                                    <span style={{}}>
                                      {option.name} ({option.email_id})
                                    </span>
                                  </Box>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{
                                    '& .MuiInputBase-root': {
                                      fontSize: '13px',

                                      '@media (max-width:1300px)': {
                                        fontSize: '13px',
                                      },
                                    },
                                  }}
                                  size="small"
                                  {...params}
                                  placeholder={
                                    translator[language]['Search User']
                                  }
                                />
                              )}
                            />
                          </FormControl>
                        </div>
                        {/* -------------ends-------------- */}
                        <div>
                          <FormControl className={Styles.userRoleForm}>
                            <Select
                              className={Styles.userRole}
                              style={{
                                fontSize: '13px',
                              }}
                              labelId="Something"
                              id="abcd"
                              value={userRole}
                              onChange={(e) =>
                                setUserRoleFunction(e.target.value)
                              }
                              displayEmpty
                              renderValue={
                                userRole !== ''
                                  ? undefined
                                  : () => (
                                      <Placeholder>
                                        Select User Role
                                      </Placeholder>
                                    )
                              }
                            >
                              {Users.map((user) => {
                                return (
                                  <MenuItem
                                    style={{
                                      minHeight:
                                        window.innerWidth < 768 && '22px',
                                      fontSize:
                                        window.innerWidth < 768 && '12px',
                                    }}
                                    key={user.value}
                                    value={user.value}
                                  >
                                    {user.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {/* FOR CHECKBOX  */}
                      <div
                        className={Styles.sharetoAllParent}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Checkbox
                          checked={
                            isChecked
                            // ||(userRole !== "" && alreadyCheckedFromApi())
                          }
                          onClick={() => setIsChecked(!isChecked)}
                          disabled={
                            // (shareTo?.every((item) => item !== searchUser)
                            userRole === '' || searchUser !== '' ? true : false
                          }
                          style={{ padding: '0' }}
                        />
                        <div className={Styles.sharetoAll}>
                          {translator[language]['Share to all']}
                        </div>
                      </div>
                      {/* FOR CHECKBOX  */}
                    </div>

                    <div className={Styles.permsDiv}>
                      <div
                        className={Styles.permissionsHeading}
                        style={{ marginBottom: '8px' }}
                      >
                        {translator[language]['Permissions']}
                      </div>

                      <FormControl className={Styles.sharePermissionsForm}>
                        <Select
                          className={Styles.sharePermissions}
                          style={{
                            fontSize: '13px',
                          }}
                          labelId="Something"
                          id="abcd"
                          value={permissions}
                          onChange={(e) =>
                            setPermissionsFunction(e.target.value)
                          }
                          displayEmpty
                          renderValue={
                            shareTo !== ''
                              ? undefined
                              : () => (
                                  <Placeholder>
                                    {'<'} Select {'>'}
                                  </Placeholder>
                                )
                          }
                        >
                          <MenuItem
                            value={'Viewer'}
                            style={{
                              minHeight: window.innerWidth < 768 && '22px',
                              fontSize: window.innerWidth < 768 && '12px',
                            }}
                          >
                            {translator[language]['Viewer']}
                          </MenuItem>
                          <MenuItem
                            value={'Editor'}
                            style={{
                              minHeight: window.innerWidth < 768 && '22px',
                              fontSize: window.innerWidth < 768 && '12px',
                            }}
                          >
                            {translator[language]['Editor']}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                )
              )}

              <div
                style={{
                  display: 'flex',
                  marginTop: '20px',
                  justifyContent: 'center',
                }}
              >
                {!isScenarioSaved && currOpt === 'Share' ? (
                  <>
                    <button
                      onClick={() => setOpenSaveModal(false)}
                      style={{
                        background: '#FFFFFF',
                        border: '1px solid #5F5F5F',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        borderRadius: '10px',
                        padding: '6px 26px',
                        marginRight: '15px',
                        cursor: 'pointer',
                      }}
                    >
                      {translator[language]['Cancel']}
                    </button>

                    <button
                      disabled={
                        (shareTo?.every((item) => item !== searchUser) &&
                          isChecked === false) ||
                        (shareTo?.length === 0 && isChecked === false) ||
                        permissions === '' ||
                        userRole === ''
                          ? true
                          : false
                      }
                      onClick={() => shareFunction()}
                      style={{
                        background:
                          (shareTo?.every((item) => item !== searchUser) &&
                            isChecked === false) ||
                          (shareTo?.length === 0 && isChecked === false) ||
                          permissions === '' ||
                          userRole === ''
                            ? '#EBEBEB'
                            : '#448AE7',

                        color: '#ffffff',
                        border:
                          (shareTo?.every((item) => item !== searchUser) &&
                            isChecked === false) ||
                          (shareTo?.length === 0 && isChecked === false) ||
                          permissions === '' ||
                          userRole === ''
                            ? '#EBEBEB'
                            : '#448AE7',

                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        borderRadius: '10px',
                        padding: '6px 26px',
                        cursor: 'pointer',
                      }}
                    >
                      {translator[language]['Share']}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        setIsScenarioSaved(false);
                        setOpenSaveModal(false);
                        setShareTo('');
                        setPermissions('Viewer');
                      }}
                      style={{
                        background: '#448AE7',
                        color: '#ffffff',
                        border: ' 1px solid #448AE7',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        borderRadius: '10px',
                        padding: '6px 26px',
                        cursor: 'pointer',
                      }}
                    >
                      {translator[language]['Okay']}
                    </button>
                  </>
                )}
              </div>
            </DialogContent>
          </Dialog>
        </div>
        /* GENERIC MODAL FOR THIS PAGE */
      )}

      <div className={Styles.parentDivGenericPlanNav}>
        <div
          className={Styles.backToScenarioText}
          style={{ textDecoration: 'none', color: 'unset' }}
          onClick={() => {
            setOpenSaveModal(false);
            props.setCurrentCrop('SavedScenarioSummary');
          }}
        >
          {'<'}

          {translator[language]['Back to Scenario Summary']}
        </div>

        <div className={Styles.optionsParentdiv}>
          <div
            className={Styles.tooltipPosition}
            style={{
              right:
                window.innerWidth > 768 && accountType !== 'programManager'
                  ? '160px'
                  : '20px',
            }}
          >
            <img
              data-tooltip-id="my-tooltip-1"
              onClick={() => navigator.vibrate(100)}
              src={ToolTipIcon}
              className={Styles.toolTipImg}
              alt="tooltip"
            />
            {/* </Tooltip> */}
          </div>

          {accountType !== 'programManager' && (
            <div className={Styles.optionsForm}>
              <div style={{ marginLeft: '', marginRight: '12px' }}>
                <FormControl
                  sx={{ minWidth: window.innerWidth < 768 ? 80 : 120 }}
                  size="small"
                >
                  <Select
                    className={Styles.optionsSelect}
                    style={{
                      background: '#357DDC',
                      color: '#ffffff',
                      fontSize: '13px',
                    }}
                    labelId="Season Plan"
                    id="language"
                    value={currentOption}
                    onChange={(e) => handleChange(e)}
                    displayEmpty
                    renderValue={
                      currentOption !== ''
                        ? undefined
                        : () => (
                            <Placeholder>
                              {' '}
                              {translator[language]['Options']}
                            </Placeholder>
                          )
                    }
                  >
                    {editAccess() === true && (
                      <MenuItem
                        style={{
                          minHeight: window.innerWidth < 768 && '22px',
                          fontSize: window.innerWidth < 768 && '12px',
                        }}
                        onClick={() => {
                          props.isFromCropDiversification
                            ? props.setCurrentCrop('Crop Diversification')
                            : props.isFromIrrigation
                            ? props.setCurrentCrop('Irrigation Side')
                            : props.setCurrentCrop('Demand Side');

                          props.callBaseConditionForEdit(props.scenarioId);
                        }}
                        value={'Edit'}
                      >
                        {translator[language]['Edit']}
                      </MenuItem>
                    )}

                    {alreadyCheckedFromApi() === true && (
                      <MenuItem
                        style={{
                          minHeight: window.innerWidth < 768 && '22px',
                          fontSize: window.innerWidth < 768 && '12px',
                        }}
                        onClick={() => deleteScenario()}
                        value={'Delete'}
                      >
                        {translator[language]['Delete']}
                      </MenuItem>
                    )}

                    {alreadyCheckedFromApi() === true && (
                      <MenuItem
                        style={{
                          minHeight: window.innerWidth < 768 && '22px',
                          fontSize: window.innerWidth < 768 && '12px',
                        }}
                        onClick={() => setOpenSaveModal(true)}
                        value={'Share'}
                      >
                        {translator[language]['Share']}
                      </MenuItem>
                    )}
                    {accountType === 'admin' &&
                      alreadyCheckedFromApi() === true &&
                      !props.isFromCropDiversification && (
                        <MenuItem
                          style={{
                            minHeight: window.innerWidth < 768 && '22px',
                            fontSize: window.innerWidth < 768 && '12px',
                          }}
                          value={'Set Recommendation'}
                          // onClick={() => setOpenSaveModal(true)}
                          onClick={() => setRecommendationFunction()}
                        >
                          {translator[language]['Set Recommendation']}
                        </MenuItem>
                      )}

                    <MenuItem
                      style={{
                        minHeight: window.innerWidth < 768 && '22px',
                        fontSize: window.innerWidth < 768 && '12px',
                      }}
                      value={'Preview Advisory'}
                      onClick={() => {
                        props.setPreviewAdvisory &&
                          props.setPreviewAdvisory(true);
                        props.setPreviewAdvisoryCropDiversification &&
                          props.setPreviewAdvisoryCropDiversification(true);
                      }}
                    >
                      {translator[language]['Preview Advisory']}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
        </div>
      </div>
      <ReactTooltip
        id="my-tooltip-1"
        place="bottom"
        style={{
          background: 'black',
          color: 'white',
          fontSize: window.innerWidth < 768 ? '10px' : '12px',
        }}
        content={
          <div className={Styles.tooltipText}>
            {translator[language]['Owner']} - {props.ownerName}
            <br />
            {translator[language]['Date']} -{' '}
            {moment(props.ownerData.createdAt).format('DD-MM-YYYY')}
            <br />
            {translator[language]['Last Edited']} -{' '}
            {moment(props.ownerData.updatedAt).format('DD-MM-YYYY')}
            <br />
          </div>
        }
      />
    </>
  );
};

export default GenericPlanNav;
